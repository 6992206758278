import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  subtitle?: string;
  title: string;
}

class MyInformationSection extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>{this.props.title.toUpperCase()}</Text>
        <Text style={styles.subtitle}>{this.props.subtitle}</Text>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  subtitle: TextStyle;
  title: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 2 * theme.margins.cardUnit,
  },
  subtitle: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.placeholder,
  },
  title: {
    ...theme.fonts.cardText,
    color: theme.colors.textGray,
    marginBottom: theme.margins.cardUnit,
  },
});

export default MyInformationSection;
