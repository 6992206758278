import { IImageConfig } from './imagesMapper.d';

const images: IImageConfig = {
  animationBlue: require('../images/animation_blue.png'),
  animationGreen: require('../images/animation_green.png'),
  animationRed: require('../images/animation_red.png'),
  ArrowLeftGreenSVG: require('../images/arrow_green_left.svg').default,
  ArrowRightGreenSVG: require('../images/arrow_green_right.svg').default,
  bonAppetit: require('../images/bon-appetit@3x.png'),
  brandBlackBienvenue: require('../images/brand/bienvenue/black/brandBlack-bienvenue@3x.png'),
  cat: require('../images/cat@web.jpg'),
  catTitle: require('../images/cat-title.png'),
  checkCircle: require('../images/checkCircle.svg'),
  circleAddSVG: require('../images/circle-add.svg'),
  close: require('../images/close@3x.png'),
  creditCardType: {
    cb: require('../images/creditCardType/debit-card.png'),
    masterCard: require('../images/creditCardType/master-card.png'),
    visa: require('../images/creditCardType/visa-card.png'),
  },
  cookieSVG: require('../images/cookie.svg'),
  cup: require('../images/tasse@3x.png'),
  cupBlack: require('../images/tasse2@3x.png'),
  cutlery: require('../images/cutlery@3x.png'),
  deleteSVG: require('../images/delete.svg'),
  dessert: require('../images/dessert@3x.png'),
  dish: require('../images/plat@3x.png'),
  dishBig: require('../images/dishBig@3x.png'),
  dishBlack: require('../images/dishBlack@3x.png'),
  dishBooking: require('../images/dishBooking.png'),
  dishGray: require('../images/dishGray@3x.png'),
  emptyBasket: require('../images/emptyBasket@3x.png'),
  euro: require('../images/euro@3x.png'),
  france: require('../images/france@3x.png'),
  geolocation: require('../images/geolocation@web.svg'),
  greatBritain: require('../images/great-britain@3x.png'),
  homeAfternoon: require('../images/home-afternoon@web.png'),
  homeLunch: require('../images/home-lunch@web.png'),
  horizontalLogo: require('../images/foodi-horizontal.svg'),
  horizontalLogoEurest: require('../images/foodi-horizontal-eurest.svg'),
  horizontalLogoExalt: require('../images/foodi-horizontal-exalt.svg'),
  horizontalLogoMedirest: require('../images/foodi-horizontal-medirest.svg'),
  horizontalLogoScolarest: require('../images/foodi-horizontal-scolarest.svg'),
  iconBag: require('../images/icon-bag.svg'),
  iconCard: require('../images/icon-card.svg'),
  iconChicken: require('../images/icon-chicken.svg'),
  iconCup: require('../images/icon-cup.svg'),
  iconGuy: require('../images/icon-guy.svg'),
  iconHeart: require('../images/icon-heart.svg'),
  landing: require('../images/landing@web.jpg'),
  link: require('../images/link.svg'),
  logoBlack: require('../images/FoodiBlack@2x.png'),
  logoBrasserie: require('../images/logoBrasserie@3x.png'),
  logoFullBlack: require('../images/foodi-centre-black@3x.png'),
  logoFullWhite: require('../images/foodi-centre-white@3x.png'),
  logoWhite: require('../images/Foodi@2x.png'),
  mail: require('../images/mail@web.svg'),
  mobile: require('../images/mobile.svg'),
  modifySVG: require('../images/modify.svg'),
  orderState: {
    default: {
      KO: require('../images/orderState/default/KO@3x.png'),
      OK: require('../images/orderState/default/OK@3x.png'),
      onHold: require('../images/orderState/default/onHold@3x.png'),
    },
    extra: {
      KO: require('../images/orderState/extra/KO@3x.png'),
      OK: require('../images/orderState/extra/OK@3x.png'),
      onHold: require('../images/orderState/extra/onHold@3x.png'),
    },
  },
  restaurantShort: require('../images/restaurant-short@3x.png'),
  starter: require('../images/starter@3x.png'),
  thumb: require('../images/thumb@web.svg'),
  tomorrowMenu: require('../images/tomorrowMenu.png'),
  verticalLogo: require('../images/foodi-vertical.svg'),
  verticalLogoEurest: require('../images/foodi-vertical-eurest.svg'),
  verticalLogoExalt: require('../images/foodi-vertical-exalt.svg'),
  verticalLogoMedirest: require('../images/foodi-vertical-medirest.svg'),
  verticalLogoScolarest: require('../images/foodi-vertical-scolarest.svg'),
  visibilitySVG: require('../images/visibility_black.svg'),
  visibilityOnSVG: require('../images/visibility_on.svg'),
  visibilityOffSVG: require('../images/visibility_off.svg'),
  warningRedSVG: require('../images/warningRed.svg'),
};

export default images;
