import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withProps, withHandlers } from 'recompose';
import Client from '../../../../apollo/client';

import { POLL_INTERVAL } from '../../../../apollo/constants';
import withBalanceDetails from '../../../../hoc/withBalanceDetails';
import withNavigation from '../../../../hoc/withNavigation';
import withPointsOfSale from '../../../../hoc/withPointsOfSale';
import { isWeb } from '../../../../lib/responsive';
import { selectAuthenticationToken } from '../../../../redux/authentication/selectors';
import { IAppState } from '../../../../redux/reducer';
import {
  IMarketingCardElement,
  INode,
  IMarketingCard,
  IExternalService,
} from '../../../../types/common';
import { IPos } from '../../../../types/getPointsOfSaleWithMenu';
import { IPollable } from '../../../../types/pollable';

import CardCollection from './CardCollection.component';
import { IProps } from './shared';
import withOffers from '../../../../hoc/withOffers';
import withGuestPaymentProfile, {
  IWithGuestPaymentProfile,
} from '../../../../hoc/withGuestPaymentProfile';
import { IHoldingInfo, setHoldingInfo } from '../../../../../src/redux/holding/actions';
import { Dispatch } from 'react';

const getBrand = loader('../../../../queries/getBrand.gql');
const getMarketingCards = loader('../../../../queries/getMarketingCards.gql');
const getPublishedMarketingCardsNew = loader(
  '../../../../queries/getPublishedMarketingCardsNew.gql'
);
const getUserProfile = loader('../../../../queries/getUserProfile.gql');
const migrateToHoldingViewQuery = loader('../../../../queries/migrateToHoldingView.gql');

export type IMigrateToHoldingViewMutation = () => Promise<any>;
export interface IWithPos {
  pointOfSales: IPos[];
}

const setPosProps = (ownProps: IContainerProps & IConnectedProps): IWithPos => {
  return { pointOfSales: ownProps.data.map((pos: INode<IPos>) => pos.node) };
};

export interface IWithPointsOfSale {
  data: Array<INode<IPos>>;
  isLoading: boolean;
}

export interface IWithMarketingCard {
  isMarketingCardLoading: boolean;
  marketingCards: IMarketingCardElement[];
}

export interface IWithMarketingCardsNew {
  isLoading: boolean;
  marketingCardsNew: IMarketingCardElement[];
}

export interface IWithMigrateToHoldingView {
  migrateToHoldingView: () => Promise<any>;
}

export type IConnectedProps = IWithPointsOfSale & IWithMarketingCard & IWithPos & IWithIsLoading;
export type IContainerProps = Omit<IProps & IPollable, keyof IConnectedProps>;

interface IWithIsLoading {
  isLoading: boolean;
}

interface IMapStateToProps {
  token: string;
  hasTableService?: boolean;
  offerTemplateTypes?: string;
  marketingCardsNew: IMarketingCard[];
}

export interface IMapDispatchToProps {
  setHoldingInfo: (data: IHoldingInfo) => void;
}

export interface IGraphQLProps {
  brand?: string;
  isBadgeRequired?: boolean;
  externalUrl: string;
  idHolding?: string;
  badgeNumber?: string;
  isHoldingLoading?: boolean;
  externalServices?: IExternalService[];
  refetchBrand?: () => Promise<void>;
}

export interface IProfileInfo {
  firstName: string;
  hardLinked: boolean;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
  hasTableService: true,
  marketingCardsNew: [],
});

const mapDispatchToProps = (dispatch: Dispatch<any>): IMapDispatchToProps => {
  return {
    setHoldingInfo: ({
      idHolding,
      brandName,
      badgeNumber,
      isBadgeRequired,
      isBalanceDisabled,
      externalRefillUrl,
    }: IHoldingInfo) =>
      dispatch(
        setHoldingInfo({
          idHolding,
          brandName,
          badgeNumber,
          isBadgeRequired,
          isBalanceDisabled,
          externalRefillUrl,
        })
      ),
  };
};

// tslint:disable-next-line:typedef
const withMarketingCardsNew = () => {
  return graphql(getPublishedMarketingCardsNew, {
    options: (props: IProps): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        idHolding: props.idHolding,
        type: 'ARTICLE,IMPORTANT_MESSAGE',
      },
    }),
    props: (props: OptionProps<IProps>): IWithMarketingCardsNew => {
      return {
        isLoading: get(props, 'data.loading'),
        marketingCardsNew: get(props, 'data.getPublishedMarketingCardsNew') || [],
      };
    },
    skip: (props: IProps): boolean => !props.idHolding,
  });
};

const migrateToHoldingView = (): IMigrateToHoldingViewMutation => async (): Promise<any> => {
  return await Client.getApolloClient().mutate({
    mutation: migrateToHoldingViewQuery,
  });
};

export default compose<
  IProps & NavigationInjectedProps & IWithGuestPaymentProfile,
  IContainerProps & IWithMigrateToHoldingView
>(
  connect(mapStateToProps, mapDispatchToProps),
  withNavigation,
  withBalanceDetails,
  withPointsOfSale,
  withOffers,
  withGuestPaymentProfile,
  withProps(setPosProps),
  graphql(getMarketingCards, {
    props: (props: OptionProps<IProps>): IWithMarketingCard => ({
      isMarketingCardLoading: get(props, 'data.loading'),
      marketingCards: get(props, 'data.getUser.guests.edges[0].node.holding.marketingCards') || [],
    }),
  }),
  graphql(getBrand, {
    options: (props: IPollable): QueryOpts => ({
      fetchPolicy: 'network-only',
      pollInterval: props.isPollable ? POLL_INTERVAL : undefined,
    }),
    props: (props: OptionProps<IMapStateToProps & IPollable>): IGraphQLProps => {
      return {
        isHoldingLoading: get(props, 'data.loading'),
        brand: get(props, 'data.getUser.currentHoldingView.holding.brand'),
        isBadgeRequired: get(props, 'data.getUser.currentHoldingView.holding.isBadgeRequired'),
        externalUrl: get(props, 'data.getUser.currentHoldingView.holding.externalUrl') || '',
        externalServices:
          get(props, 'data.getUser.currentHoldingView.holding.externalServices') || [],
        idHolding: get(props, 'data.getUser.currentHoldingView.holding.id'),
        badgeNumber:
          get(props, 'data.getUser.guests.edges[0].node.supportSerialNumber') ||
          get(props, 'data.getUser.guests.edges[0].node.serialNumber'),
        refetchBrand: get(props, 'data.refetch'),
      };
    },
    skip: (props: IMapStateToProps & IPollable): boolean => isWeb() || !props.token,
  }),
  graphql(getUserProfile, {
    props: (props: OptionProps<{}>): IProfileInfo => ({
      firstName: get(props, 'data.getUser.firstName'),
      hardLinked: get(props, 'data.getUser.hardLinked'),
    }),
  }),
  withMarketingCardsNew(),
  withHandlers({ migrateToHoldingView })
)(CardCollection);
