import React, { CSSProperties } from 'react';

import './fontello/css/foodi_cleaned.css';

interface IConfig {
  name: string;
  style: CSSProperties;
}

export const createIconSetFromFontello = (): ((config: IConfig) => HTMLSpanElement | null) => {
  return ({ name, style }: IConfig): HTMLSpanElement | null => {
    if (!name) {
      return null;
    }

    return <span className={`icon-${name.replace(/@3x/g, '')}`} style={style} />;
  };
};
