import { compose, withProps, withHandlers, withState } from 'recompose';
import { loader } from 'graphql.macro';
import { ApolloError } from 'apollo-client';
import { NavigationInjectedProps } from 'react-navigation';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';

import withNavigation from '../../../../../hoc/withNavigation';
import { useTemporaryCode as IUserTemporaryCode } from '../../../../../types/tableService/useTemporaryCode';
import TemporaryCode, { IProps } from './TemporaryCode.component';
import { ErrorType } from '../../../../../apollo/errorHandler';
import { updateOrderToken } from '../../../../../apollo/links/refreshLink';
import { resetCart } from '../../../../../redux/clickAndCollect/actions';
import store from '../../../../../redux/store';

const useTemporaryCode = loader('../../../../../queries/tableservice/useTemporaryCode.gql');

interface IErrorProps {
  error?: string;
  setError: (error?: string) => void;
}

interface ILoadingProps {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

export interface IWithExtraProps {
  value: string;
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  value: ownProps.navigation.getParam('code'),
});

type IUseTemporaryCodeMutation = (value: string) => Promise<boolean>;

type IConnectedProps = NavigationInjectedProps &
  WithApolloClient<{}> & {
    useTemporaryCode: IUseTemporaryCodeMutation;
  };

const handledErrors = [
  ErrorType.TEMPORARY_CODE_INVALID.toString(),
  ErrorType.TEMPORARY_CODE_EXPIRED.toString(),
  ErrorType.TEMPORARY_CODE_OFFER_INVALID.toString(),
  ErrorType.TEMPORARY_CODE_OFFER_TEMPLATE_INVALID.toString(),
];

const errorData = ({ graphQLErrors }: ApolloError) => {
  return (graphQLErrors || []).map(({ message }) => message).find(message => handledErrors.includes(message));
};

const useTemporaryCodeHandler = ({
  client,
  setError,
  setLoading,
  navigation: { navigate },
}: IConnectedProps & IErrorProps & ILoadingProps): IUseTemporaryCodeMutation => async (value: string) => {
  setLoading(true);
  setError(undefined);

  try {
    const { data } = await client.mutate<IUserTemporaryCode>({
      mutation: useTemporaryCode,
      variables: {
        value,
      },
    });
    setLoading(false);
    const {idOffer, idTable, token} = data!.useTemporaryCode;
    updateOrderToken(token);
    store.dispatch(resetCart());
    return navigate('tableServiceTableNumberConfirm',{offerId: idOffer, tableId: idTable});
  }
  catch (err) {
    setLoading(false);
    setError(errorData(err));
    return false;
  }
};

export default compose<IProps, NavigationInjectedProps>(
  withState('isLoading', 'setLoading', false),
  withState('error', 'setError', null),
  withNavigation,
  withProps(withExtraProps),
  withApollo,
  withHandlers({ useTemporaryCode: useTemporaryCodeHandler }),
)(TemporaryCode);
