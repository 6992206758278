import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { ActionCreator } from 'redux';

import withNavigation from '../../hoc/withNavigation';
import {
  ISetOptinsAction,
  ISetUserEmailAction,
  setOptins,
  setUserEmail,
} from '../../redux/signUp/actions';
import { ISignUpCheckEmailResult } from '../../types/signUpCheckEmail';

import SignUpEmailForm, { IProps } from './SignUpEmailForm.component';

const signUpCheckEmail = loader('../../queries/signUpCheckEmail.gql');

interface IDispatchProps {
  setOptins: ActionCreator<ISetOptinsAction>;
  setUserEmail: ActionCreator<ISetUserEmailAction>;
}

const mapDispatchToProps: IDispatchProps = {
  setOptins,
  setUserEmail,
};

interface IEmailProps {
  email?: string;
}

const WithCredentialProps = (props: NavigationInjectedProps): IEmailProps => ({
  email: props.navigation.getParam('email'),
});

type ICheckEmailQuery = (email: string) => Promise<FetchResult>;

const checkEmailHandler = (props: WithApolloClient<{}>): ICheckEmailQuery => async (
  email: string
): Promise<{}> =>
  props.client.query<ISignUpCheckEmailResult>({
    fetchPolicy: 'network-only',
    query: signUpCheckEmail,
    variables: { email },
  });

export type IConnectedProps = NavigationInjectedProps &
  IDispatchProps & { checkEmail: ICheckEmailQuery } & WithApolloClient<{}>;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    undefined,
    mapDispatchToProps
  ),
  withNavigation,
  withApollo,
  withHandlers({ checkEmail: checkEmailHandler }),
  withProps(WithCredentialProps)
)(SignUpEmailForm);
