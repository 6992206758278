import {
  getTableServiceTables_tables_Offer_offerTemplate_TableServiceOfferTemplate_zone as TableGroup,
  getTableServiceTables_tables_Offer_offerTemplate_TableServiceOfferTemplate_zone_tables as Tables,
} from '../types/tableService/getTableServiceTables';

export const checkTableNumber = (tableNumber: string, tables: number[], skipTableGroupValidation?: boolean): boolean => {

  const isValidNumber = /^0?[\d]{1,3}$/.test(tableNumber);

  if (skipTableGroupValidation) {
    return isValidNumber;
  }

  return (
    isValidNumber &&
    tables.some(idHuman => idHuman === Number.parseInt(tableNumber, 10))
  );
};

export const checkIncompleteTableNumber = (tableNumber = '0', tables: number[], skipTableGroupValidation?: boolean): boolean => {
  if (!tableNumber || tableNumber === '0') {
    return true;
  }

  const isValidNumber = /^0?[\d]{0,3}$/.test(tableNumber);

  if (skipTableGroupValidation) {
    return isValidNumber;
  }

  return (
    isValidNumber &&
    tables.some(
      idHuman =>
        Number.parseInt(tableNumber, 10).toString() ===
        idHuman.toString().substr(0, tableNumber.length)
    )
  );
};

export const tablesToKey = (tableGroups: TableGroup[], key: string = 'idHuman'): number[] =>
  tableGroups.reduce<number[]>(
    (acc1, tableGroup) => [
      ...acc1,
      ...tableGroup.tables.reduce<number[]>((acc2, table) => [...acc2, table[key]], []),
    ],
    []
  );

export const getTables = (tableGroups: TableGroup[]): Tables[] =>
  tableGroups.reduce<Tables[]>(
    (acc1, tableGroup) => [
      ...acc1,
      ...tableGroup.tables.reduce<Tables[]>((acc2, table) => [...acc2, table], []),
    ],
    []
  );
