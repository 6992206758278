import { ApolloQueryResult } from 'apollo-client';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { graphql } from 'react-apollo/graphql';
import { OperationVariables, OptionProps, QueryOpts } from 'react-apollo/types';
import { compose, withProps } from 'recompose';

import { formatOfferTemplates } from '../../services/offersFormatter';

import { getOfferTemplates as IResult } from '../../types/clickandcollect/getOfferTemplates';
import { IOfferTemplate } from '../../types/common';

const getOffers = loader('../../queries/clickandcollect/getOfferTemplates.gql');

interface IComponentProps {
  isLoading?: boolean;
  hasTableService?: boolean;
  withdrawalType?: string;
  offerTemplateTypes?: string
}

export interface IGraphQLProps {
  hasError: boolean;
  isOffersLoading: boolean;
  offerTemplates: IOfferTemplate[];
  refetchOffers: (variables?: OperationVariables) => Promise<ApolloQueryResult<IResult>>;
}

export type IConnectedProps = IGraphQLProps;
export type IContainerProps = Omit<{}, keyof IConnectedProps>;

interface IWithIsLoading {
  isLoading: boolean;
}

const withIsLoading = (props: IGraphQLProps & IComponentProps): IWithIsLoading => ({
  isLoading: props.isLoading || props.isOffersLoading,
});

export interface IWithSkipProps {
  skip?: boolean;
}

const withOffers = <T extends IComponentProps = {}>(
  Component: React.ComponentType<T>
): React.ComponentType<T> => {
  class ComponentWithOffers extends PureComponent<T> {
    public render(): ReactNode {
      return <Component {...this.props} />;
    }
  }

  return compose<T, T & IComponentProps>(
    graphql(getOffers, {
      options: (props: IComponentProps): QueryOpts => ({
        fetchPolicy: 'cache-and-network',
        variables: {
          type: props.offerTemplateTypes ? props.offerTemplateTypes : props.hasTableService ? 'CLICK_COLLECT,TABLE_SERVICE' : 'CLICK_COLLECT',
          withdrawalType: !!props.withdrawalType ? props.withdrawalType : 
          !!props.hasTableService ? "POS_TAKE_AWAY,CONNECTED_LOCKERS,TABLE_SERVICE,CLICK_AND_PICK_UP" :
           "POS_TAKE_AWAY,CONNECTED_LOCKERS,CLICK_AND_PICK_UP"
        },
      }),
      props: (props: OptionProps<{}>): IGraphQLProps => ({
        hasError: !!get(props, 'data.error'),
        isOffersLoading: get(props, 'data.loading'),
        offerTemplates: formatOfferTemplates(get(props, 'data.list.edges') || []),
        refetchOffers: get(props, 'data.refetch'),
      }),
      skip: (props: IWithSkipProps) => !!props.skip,
    }),
    withProps(withIsLoading)
  )(ComponentWithOffers);
};

export default withOffers;
