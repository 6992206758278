import { Action } from 'redux';

export type IActionTypes =
  | 'AUTHENTICATE'
  | 'AUTHENTICATION_SET_ACCESS_TOKEN'
  | 'AUTHENTICATION_SET_TOKEN'
  | 'AUTHENTICATION_LOG_OUT'
  | 'RESET_SIGNIN_REDIRECTION';

export const actionTypes: { [key in IActionTypes]: key } = {
  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATION_LOG_OUT: 'AUTHENTICATION_LOG_OUT',
  AUTHENTICATION_SET_ACCESS_TOKEN: 'AUTHENTICATION_SET_ACCESS_TOKEN',
  AUTHENTICATION_SET_TOKEN: 'AUTHENTICATION_SET_TOKEN',
  RESET_SIGNIN_REDIRECTION: 'RESET_SIGNIN_REDIRECTION',
};

export interface IAuthenticationTokenPayload {
  emissionDate?: string;
  expirationDate?: string;
  expiresIn: number;
  refreshToken?: string;
  token: string;
  signInRedirection?: string;
}

export interface IAuthenticationTokenAction extends Action {
  payload: IAuthenticationTokenPayload;
}

export const setAuthenticationToken = (
  payload: IAuthenticationTokenPayload
): IAuthenticationTokenAction => ({
  payload,
  type: actionTypes.AUTHENTICATION_SET_TOKEN,
});

export interface ISetAccessTokenPayload {
  emissionDate: string;
  expirationDate: string;
  expiresIn: number;
  token: string;
}

export interface ISetAccessTokenAction extends Action {
  payload: ISetAccessTokenPayload;
}

export const setAccessToken = (payload: ISetAccessTokenPayload): ISetAccessTokenAction => ({
  payload,
  type: actionTypes.AUTHENTICATION_SET_ACCESS_TOKEN,
});

export const logOut = (): Action => ({
  type: actionTypes.AUTHENTICATION_LOG_OUT,
});

export const resetSigninRedirection = (): Action => ({
  type: actionTypes.RESET_SIGNIN_REDIRECTION,
});

export interface IAuthenticatePayload {
  emissionDate?: string;
  expirationDate?: string;
  expiresIn: number;
  refreshToken?: string;
  signInRedirection?: string;
  token: string;
  redirect?: string;
}

export interface IAuthenticateAction extends Action {
  payload: IAuthenticatePayload;
}

export const authenticate = (payload: IAuthenticatePayload): IAuthenticateAction => ({
  payload,
  type: actionTypes.AUTHENTICATE,
});
