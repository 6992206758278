import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import { compose } from 'recompose';

const getHoldingWithIsRecipeDetailsEnabled = loader(
  './queries/getHoldingWithIsRecipeDetailsEnabled.gql'
);

export interface IWithIsRecipeDetailsEnabledProps {
  hasError: boolean;
  isRecipeDetailsEnabled?: boolean;
  isRecipeDetailsEnabledLoading: boolean;
}

export type IComposeProps = IWithIsRecipeDetailsEnabledProps;
export type IHocProps<T> = Omit<T, keyof IComposeProps>;

const withIsRecipeDetailsEnabled = <T extends object>(
  Component: React.ComponentType<T>
): React.ComponentType<IHocProps<T>> => {
  return compose<T, IHocProps<T>>(
    graphql(getHoldingWithIsRecipeDetailsEnabled, {
      props: (
        props: OptionProps<IWithIsRecipeDetailsEnabledProps>
      ): IWithIsRecipeDetailsEnabledProps => {
        return {
          hasError: !!get(props, 'data.error'),
          isRecipeDetailsEnabled: get(
            props,
            'data.getUser.guests.edges[0].node.holding.isRecipeDetailsEnabled',
            false
          ),
          isRecipeDetailsEnabledLoading: get(props, 'data.loading'),
        };
      },
    })
  )(Component);
};

export default withIsRecipeDetailsEnabled;
