import React, { PureComponent, ReactNode } from 'react';
import { NavigationScreenProps } from 'react-navigation';

import HoldingsList from '../../../../src/components/HoldingsList';
import Paragraph from '../../../../src/components/Paragraph';
import I18n from '../../../../src/lib/i18n';

import SignupLayout from '../../components/SignupLayout';
import { idToUuid, Entity } from '../../../../src/services/base64Id';

export type IProps = NavigationScreenProps;

class HoldingChoicePage extends PureComponent<IProps> {
  public componentWillMount(): void {
    const holdingId = this.props.navigation.getParam('holding');
    if (holdingId) {
      this.props.navigation.navigate('signUpIdentity', {
        holdingId: idToUuid(holdingId, Entity.HOLDING),
      });
    }
  }
  public render(): ReactNode {
    return (
      <SignupLayout
        title={I18n.t('signUp.headerTitle')}
        subtitle={I18n.t('signUp.geolocation.title')}
        currentStepNumber={1}
        currentStepStatus="ACTIVE"
      >
        <Paragraph text={I18n.t('signUp.geolocation.paragraph')} />
        <HoldingsList
          contentContainerStyle={{ maxHeight: 350 }}
          onHoldingPress={this.onHoldingPress}
        />
      </SignupLayout>
    );
  }

  private onHoldingPress = (isHoldingAvailable: boolean, holdingId: string): void => {
    const latitude = this.props.navigation.getParam('latitude');
    const longitude = this.props.navigation.getParam('longitude');

    isHoldingAvailable
      ? this.props.navigation.navigate('signUpIdentity', { holdingId, latitude, longitude })
      : this.props.navigation.navigate('signUpCloseHolding', { holdingId });
  };
}

export default HoldingChoicePage;
