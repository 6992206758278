import React, { PureComponent, ReactNode } from 'react';
import { Dimensions, ScaledSize, View } from 'react-native';

export interface IState {
  width: number;
}

class ResponsiveProvider extends PureComponent<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = { width: Dimensions.get('window').width };
  }

  public componentDidMount(): void {
    Dimensions.addEventListener('change', this.onChange);
  }

  public componentWillUnmount(): void {
    Dimensions.removeEventListener('change', this.onChange);
  }

  public render(): ReactNode {
    return <View key={this.state.width}>{this.props.children}</View>;
  }

  private onChange = ({ window }: { window: ScaledSize }): void => {
    this.setState({ width: window.width });
  };
}

export default ResponsiveProvider;
