import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withMarketingCard from '../../../hoc/withMarketingCard';
import {
  IGraphQLProps,
  IWithMarketingCardId,
} from '../../../hoc/withMarketingCard/withMarketingCard';
import withNavigation from '../../../hoc/withNavigation';

import ModalMarketingCard, { IProps } from './ModalMarketingCard.component';

export type IConnectedProps = NavigationInjectedProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

const withMarketingCardId = (ownProps: NavigationInjectedProps): IWithMarketingCardId => ({
  marketingCardId: ownProps.navigation.getParam('marketingCardId'),
});

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withMarketingCardId),
  withMarketingCard
)(ModalMarketingCard);
