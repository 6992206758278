import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { FlatList } from 'react-native';

import ProfileSection, { IProps as IProfileSectionProps } from '../ProfileSection';
import Separator from '../Separator';

import {
  CookieCategory,
  getAndStoreConsentStatusForCategory,
  startListenForConsentChanges,
} from '../../services/oneTrust';
import { NavigationEvents } from 'react-navigation';
import { isWeb } from '../../lib/responsive';

export interface IProps {
  profileSections: IProfileSectionProps[];
  testID?: string;
}

class ProfileSections extends PureComponent<IProps> {
  public render(): ReactNode {
    const { profileSections, testID } = this.props;

    return (
      <>
        <Separator margin={20} />
        <FlatList
          testID={testID}
          data={profileSections}
          keyExtractor={(item: IProfileSectionProps): string => item.title}
          renderItem={this.renderItem}
          ItemSeparatorComponent={(): ReactElement<Separator> => (
            <Separator marginLeft={60} marginRight={20} />
          )}
        />
        {isWeb() ? null : <NavigationEvents onDidBlur={this.onScreenUnfocused} />}
      </>
    );
  }

  public renderItem = ({ item }: { item: IProfileSectionProps }): ReactElement<ProfileSection> => {
    return (
      <ProfileSection
        testID={`${this.props.testID}_profileSection_${item.iconName}`}
        onPress={item.onPress}
        iconName={item.iconName}
        title={item.title}
        subtitle={item.subtitle}
      />
    );
  };

  /**
   * When component loses focus (user navigates to another screen), it will get and store the consent changes
   *
   */
  private onScreenUnfocused = (): void => {
    getAndStoreConsentStatusForCategory(CookieCategory.PERFORMANCE_COOKIES);
  };
}

export default ProfileSections;
