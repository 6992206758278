import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withNavigation from '../../hoc/withNavigation';

import PageTitle, { IProps } from './PageTitle.component';

interface IWithGoBack {
  goBack: () => void;
}

export type IConnectedProps = NavigationInjectedProps & IWithGoBack;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

const withGoBack = (ownProps: IProps & NavigationInjectedProps): IWithGoBack => {
  const pageName = ownProps.goBackPageName;

  return {
    goBack: pageName
      ? (): boolean => ownProps.navigation.navigate(pageName, ownProps.goBackParams)
      : ownProps.navigation.goBack,
  };
};

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withGoBack)
)(PageTitle);
