import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { ActionCreator } from 'redux';

import withMenuElementDetailsNavigation from '../../hoc/withMenuElementDetailsNavigation';
import { IWithElementDetailsParams } from '../../hoc/withMenuElementDetailsNavigation/withMenuElementDetailsNavigation.d';
import withNavigation from '../../hoc/withNavigation';
import { IToggleRecipeDisclaimerAction, toggleRecipeDisclaimer } from '../../redux/menu/actions';
import { IOptinCode } from '../../types/common';
import { IUpdateUserResult } from '../../types/updateOptins';

import InfoRecipeDisclaimerForm, { IProps } from './InfoRecipeDisclaimerForm.component';

const updateUserOptin = loader('../../queries/updateUserOptin.gql');

interface IDispatchProps {
  toggleRecipeDisclaimer: ActionCreator<IToggleRecipeDisclaimerAction>;
}

type IUpdateOptinsMutation = (valueName: IOptinCode, value: boolean) => Promise<FetchResult>;

const updateOptinHandler = (props: IConnectedProps): IUpdateOptinsMutation => async (
  valueName: IOptinCode,
  value: boolean
): Promise<FetchResult> => {
  return props.client.mutate<IUpdateUserResult>({
    mutation: updateUserOptin,
    variables: {
      input: { optins: [{ code: valueName, active: value }] },
    },
  });
};

const mapDispatchToProps: IDispatchProps = {
  toggleRecipeDisclaimer,
};

export type IConnectedProps = NavigationInjectedProps &
  IDispatchProps &
  WithApolloClient<{}> & {
    navigateToMenuElementDetails: (params: IWithElementDetailsParams) => boolean;
    updateOptin: (optinName: IOptinCode, value: boolean) => Promise<FetchResult>;
  };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    undefined,
    mapDispatchToProps
  ),
  withApollo,
  withHandlers({ updateOptin: updateOptinHandler }),
  withNavigation,
  withMenuElementDetailsNavigation
)(InfoRecipeDisclaimerForm);
