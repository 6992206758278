import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  quantity: number;
}

const QUANTITY_HEIGHT = 40;
const QUANTITY_WIDTH = 40;

class Quantity extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{this.props.quantity}</Text>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundGray,
    borderRadius: 4,
    height: QUANTITY_HEIGHT,
    justifyContent: 'center',
    marginHorizontal: 2 * theme.margins.cardUnit,
    padding: theme.margins.cardUnit,
    width: QUANTITY_WIDTH,
  },
  text: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.textGray,
  },
});

export default Quantity;
