import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { DataValue, OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../hoc/withNavigation/withNavigation';
import { ITicketResult } from '../../types/getTicket';

import TransactionDetailsContent, { IProps } from './TransactionDetailsContent.component';

const getTicket = loader('../../queries/getTicket.gql');

export type IGraphQLProps = Pick<IProps, 'hasError' | 'isLoading' | 'ticket'>;

export type IConnectedProps = IGraphQLProps & NavigationInjectedProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  graphql(getTicket, {
    options: (props: NavigationInjectedProps): QueryOpts => ({
      variables: {
        transactionId: props.navigation.getParam('transactionId'),
      },
    }),
    props: (
      props: OptionProps<NavigationInjectedProps, DataValue<ITicketResult>>
    ): IGraphQLProps => ({
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      ticket: get(props, 'data.getTicket'),
    }),
  })
)(TransactionDetailsContent);
