import { StyleSheet } from 'react-native';
import theme from '../../../theme';
import { IPlatformStyle } from './ConfirmOrderButton.component';

const BORDER_RADIUS = 4;

export const platformStyles = StyleSheet.create<IPlatformStyle>({
  container: {
    ...theme.shadows.card,
    borderRadius: BORDER_RADIUS,
    overflow: 'hidden',
  },
  leftButton: {
    backgroundColor: theme.colors.white,
  },
});
