import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import { IAmount } from '../../types/common';
import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';
import TransactionAmount from '../TransactionAmount';
import TransactionAmountDateTime from '../TransactionAmountDateTime';

export interface IProps {
  amount?: IAmount;
  amountDateTime?: string;
  isLoading?: boolean;
  posName?: string;
}

class TransactionAmountCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { amount, amountDateTime, posName, isLoading } = this.props;

    return (
      <LoadingCard style={styles.card} isLoading={isLoading}>
        <View style={styles.titleContainer}>
          <CardTitle text={posName} />
          <TransactionAmountDateTime dateTime={amountDateTime} />
        </View>
        <View style={styles.amountContainer}>
          <TransactionAmount style={styles.amount} amount={amount} />
        </View>
      </LoadingCard>
    );
  }
}

export interface IStyle {
  amount: TextStyle;
  amountContainer: ViewStyle;
  card: ViewStyle;
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    ...theme.fonts.bigAmount,
  },
  amountContainer: {
    justifyContent: 'center',
    marginLeft: theme.margins.cardUnit,
  },
  card: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'column',
    flexShrink: 1,
  },
});

export default TransactionAmountCard;
