import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, Text, TextInput, ImageBackground, Image } from 'react-native';
import { FetchResult } from 'react-apollo';
import { NavigationInjectedProps } from 'react-navigation';
import Icon from 'react-native-vector-icons/FontAwesome';
import LargeButton from '../../../../../components/LargeButton';
import { LARGE_BUTTON_THEME } from '../../../../../components/LargeButton/LargeButton.component';
import { ErrorType } from '../../../../../apollo/errorHandler';
import theme from '../../../../../theme';
import I18n from '../../../../../lib/i18n';
import { isWeb, isDesktop } from '../../../../../lib/responsive';
import { getConfig } from '../../../../../environment';
import { WebPage } from '../../../../../componentsWeb';

export interface IProps extends NavigationInjectedProps {
  isLoading: boolean;
  value: string;
  useTemporaryCode: (value: string) => Promise<FetchResult>;
  error?: any;
}

interface IState {
  codes: string[];
  isFocused: boolean;
}

const errorKey = {
  [ErrorType.TEMPORARY_CODE_INVALID]: 'dashboard.eat.error.temporaryCode.invalid',
  [ErrorType.TEMPORARY_CODE_EXPIRED]: 'dashboard.eat.error.temporaryCode.expired',
  [ErrorType.TEMPORARY_CODE_OFFER_INVALID]: 'dashboard.eat.error.temporaryCode.offer.invalid',
  [ErrorType.TEMPORARY_CODE_OFFER_TEMPLATE_INVALID]: 'dashboard.eat.error.temporaryCode.offerTemplate.invalid',
};

class TemporaryCode extends PureComponent<IProps> {
  public state: IState = {
    codes: [],
    isFocused: false
  };

  private textInputs: Array<React.RefObject<TextInput>> = [];

  constructor(props: IProps) {
    super(props);
    this.textInputs = [
      React.createRef<TextInput>(),
      React.createRef<TextInput>(),
      React.createRef<TextInput>(),
      React.createRef<TextInput>()
    ];
  }

  public componentDidMount(): void {
    if (this.textInputs[0] && this.textInputs[0].current) {
      this.textInputs[0].current.focus();
    }
    const { value } = this.props;
    this.setState({
      codes: (value || ','.repeat(3)).split(',')
    });
  }

  public render(): ReactNode {
    const { isLoading, error, useTemporaryCode, navigation:{navigate} } = this.props;
    const { codes } = this.state;
    const inputStyles = [styles.valueInput, ...(isWeb() ? [{ outline: 'none' }] : [])];

    return (
      <WebPage hideLogo padding={0} hideNavigationMenu tabBarHeight={0}>
          <ImageBackground
            source={theme.images.cat}
            style={{flex: 1, backgroundColor: '#0A1614'}}
            fadeDuration={500}
          >
            <View style={styles.container}>
              <View style={styles.header}>
                <Image style={styles.foodiLogo} resizeMode="contain" source={theme.images.logoFullWhite} />
              </View>
              
                <View style={styles.bodyContainer}>
                  <View style={[styles.bodyChild, styles.titleContainer]}>
                    <Image style={styles.catLogo} resizeMode="contain" source={theme.images.catTitle} />
                  </View>

                  <View style={[styles.bodyChild, styles.bottomBoxContainer]}>
                    <Text style={styles.line1}>-&nbsp;{I18n.t('dashboard.eat.tableService.temporaryCode.title')}&nbsp;-</Text>

                    {error ? (
                      <View style={styles.errorContainer}>
                        <Icon name="exclamation-circle" size={22} color="#FFCA27" />
                        <Text style={styles.line2}>{I18n.t(errorKey[error])}</Text>
                      </View>
                    ) : (
                      <Text style={styles.line2}>{I18n.t('dashboard.eat.tableService.temporaryCode.subTitle')}</Text>
                    )}

                    <View style={styles.valueContainer}>
                      {codes.map((code, index) => (
                        <TextInput
                          ref={this.textInputs[index]}
                          key={index}
                          maxLength={1}
                          autoFocus={index === 0}
                          keyboardType="numeric"
                          returnKeyType="next"
                          //@ts-ignores
                          style={inputStyles}
                          value={code}
                          onKeyPress={(e)=>{
                            if(index==0) return;
                            
                            if(e.nativeEvent.key === "Backspace") {
                              const newCodes = codes.slice();
                                newCodes[index] = '';
                                this.setState({
                                  codes: newCodes,
                                });
                                
                              if (code == '' ) {
                              const prevInput = this.textInputs[index - 1];
                              return prevInput ?
                                prevInput.current?.focus() :
                                this.textInputs[index].current?.blur();
                              }
                                
                            }
                          }}
                          onChangeText={(newValue: string): void => {
                            const newCodes = codes.slice();
                            newCodes[index] = newValue;
                            this.setState({
                              codes: newCodes,
                            });
                            if (newValue) {
                              const nextInput = this.textInputs[index + 1];
                              return nextInput ?
                                nextInput.current?.focus() :
                                this.textInputs[index].current?.blur();
                            }
                          }}
                        ></TextInput>
                      ))}
                    </View>
                    <View style={styles.largeButton}>
                      <LargeButton
                        style={theme.buttons.LargeButton}
                        text={I18n.t('dashboard.eat.tableService.validate')}
                        theme={LARGE_BUTTON_THEME.success}
                        onPress={() => useTemporaryCode(codes.join(''))}
                        isLoading={isLoading}
                        disabled={codes.filter(val => !!val.trim()).length !== 4}
                      />
                    </View>
                      {! isDesktop() && 
                      <>
                        <Text style={[styles.line1,{margin:0}]}>-&nbsp;{I18n.t('dashboard.eat.tableService.temporaryCode.or')}&nbsp;-</Text>
                        <View style={styles.largeButton}>
                          <LargeButton
                            style={theme.buttons.LargeButton}
                            textStyle={{textAlign:'center'}}
                            text={I18n.t('dashboard.eat.tableService.temporaryCode.foodi')}
                            theme={LARGE_BUTTON_THEME.disabled}
                            onPress={() =>{
                              const env = getConfig();
                              navigate(`https://${env.BRANCH_IO.BRANCH_IO_LINK_DOMAIN}/kYkfwAueO0`)}}
                            isLoading={false}
                          />
                        </View>
                      </>}
                    </View>
                </View>
                
            </View>
          </ImageBackground>
          </WebPage>
    );
  }
}

const styles = StyleSheet.create({
  foodiLogo: {
    height: 23,
    width: 106,
  },
  catLogo: {
    height: 100,
    width: 183,
  },
  container: {
    flexDirection: 'column',
    flex:1,
  },
  header: {
    alignItems: 'center',
    height: theme.config.TABLET_MENU_HEADER_HEIGHT,
    padding: theme.margins.unit * 3,
  },
  bodyContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    flex:1,
  },
  bodyChild: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.margins.unit * 2,
  },
  titleContainer: {
    flex: 1,
  },
  bottomBoxContainer: {
    backgroundColor: '#00000050',
  },
  errorContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  line1: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: theme.colors.white,
    margin: theme.margins.unit,
  },
  line2: {
    fontSize: 14,
    lineHeight: 19,
    color: theme.colors.white,
    margin: theme.margins.unit,
  },
  valueContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.margins.unit,
  },
  valueInput: {
    fontSize: 34,
    fontFamily: theme.fonts.notificationTitle.fontFamily,
    lineHeight: 46,
    width: 52,
    height: 68,
    borderWidth: 2,
    borderColor: theme.colors.white,
    borderRadius: 7,
    color: theme.colors.white,
    textAlign: 'center',
    margin: 'auto',
    position: 'relative',
    marginLeft: theme.margins.unit,
    marginRight: theme.margins.unit,
  },
  largeButton: {
    padding: 8,
    width: 374,
    maxWidth: '100%',
  },
});

export default TemporaryCode;
