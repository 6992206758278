import get from 'lodash/get';
import { ApolloError } from 'apollo-client';
import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers, withState, withProps } from 'recompose';
import { ActionCreator } from 'redux';
import withNavigation from '../../../hoc/withNavigation';
import { selectAuthenticationToken } from '../../../redux/authentication/selectors';
import {
  IElementQuantity,
  IRemoveOutOfStockItemsFromCartAction,
  IResetQuantityForOfferAction,
  IResetSlotForOfferAction,
  removeOutOfStockItemsFromCart,
  resetQuantityForOffer,
  resetSlotForOffer,
} from '../../../redux/clickAndCollect/actions';
import {
  getElementsQuantityByOfferId,
  getElementsTotalPriceByOfferId,
  getElementsTotalQuantityByOfferId,
  getSlotByOfferId,
} from '../../../redux/clickAndCollect/selectors';
import { IAppState } from '../../../redux/reducer';
import store from '../../../redux/store';
import { IAmount } from '../../../types/common';
import QuantityFooter, { IProps as IComponentProps } from '../QuantityFooter.component';
import I18n from '../../../lib/i18n';
import { ErrorType } from '../../../apollo/errorHandler';
import { OfferTemplateWithdrawalType, PaymentMethod } from '../../../types/clickandcollect/globalTypes';
import { getOffer_offer_Offer as IOffer } from '../../../types/clickandcollect/getOffer';
import { getBadgeNumber } from '../../../../src/redux/holding/selectors';

// @see https://github.com/kentcdodds/babel-plugin-macros#caveats
const getOffer = loader('../../../queries/clickandcollect/getOffer.gql');
const upsertOrder = loader('../../../queries/clickandcollect/upsertOrder.gql');
const getUserInfo = loader('../../../queries/getUserInfo.gql');

export enum FooterType {
  CART = 'CART',
  SLOT = 'SLOT',
}

interface IProps {
  footerType: string;
  idOffer: string;
  setItems: (items: []) => void;
  noReachedMinQuantityForCategory: boolean;
  hasArticleWithContainer?: boolean;
  testID?: string;
  withdrawalType?: string | null;
  tableNumber?: string;
  offer?: IOffer;
  idGuest?: string;
  idPickupPoint?: string;
  fontSize?: number | string;
}

interface IMapStateToProps {
  idSlot: string | null;
  token: string;
  totalPrice: IAmount | null;
  totalQuantity: number;
  badgeNumber: string | null;
  enabled?: boolean;
  elementsQuantity?: IElementQuantity[] | null;
  idGuest?: string;
}

interface IMapDispatchToProps {
  resetQuantityForOffer: ActionCreator<IResetQuantityForOfferAction>;
  resetSlotForOffer: ActionCreator<IResetSlotForOfferAction>;
  removeOutOfStockItemsFromCart: ActionCreator<IRemoveOutOfStockItemsFromCartAction>;
}

interface ILoadingProps {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

interface IErrorProps {
  error: {} | null;
  setError: (error: {} | null) => void;
}

interface IWithExtraProps {
  title: string;
  enabled: boolean;
  onPress: () => void;
  goToProfile: () => void;
  fontSize?: number | string;
}

type IUpsertOrderMutation = (
  idOffer: string,
  idSlot?: string,
  tableNumber?: string,
  idPickupPoint?: string
) => Promise<FetchResult>;

type IConnectedProps = IMapStateToProps &
  IMapDispatchToProps &
  NavigationInjectedProps &
  WithApolloClient<{}> & {
    upsertOrder: IUpsertOrderMutation;
  };

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => ({
  elementsQuantity: getElementsQuantityByOfferId(state, ownProps.idOffer),
  idSlot: getSlotByOfferId(state, ownProps.idOffer),
  token: selectAuthenticationToken(state),
  totalPrice: getElementsTotalPriceByOfferId(state, ownProps.idOffer),
  totalQuantity: getElementsTotalQuantityByOfferId(state, ownProps.idOffer),
  enabled: ownProps.noReachedMinQuantityForCategory,
  badgeNumber: getBadgeNumber(state),
});

const mapDispatchToProps: IMapDispatchToProps = {
  resetQuantityForOffer,
  removeOutOfStockItemsFromCart,
  resetSlotForOffer,
};

const handledErrors = [ErrorType.ORDER_INVALID.toString()];

const errorData = ({ graphQLErrors }: ApolloError) => {
  const errors = (graphQLErrors || []).filter(({ message }) => handledErrors.includes(message));
  return errors.length && errors[0].extensions ? (errors[0].extensions.original || {}).data : null;
};

const upsertOrderHandler = ({
  elementsQuantity,
  client,
  idGuest,
  setError,
}: IConnectedProps & IErrorProps): IUpsertOrderMutation => async (
  idOffer: string,
  idSlot?: string,
  tableNumber?: string,
  idPickupPoint?: string
): Promise<{}> => {
  const items =
    elementsQuantity &&
    elementsQuantity.map((element: IElementQuantity) => ({
      idOfferItem: element.elementId,
      quantity: element.quantity,
      chosenBaking: element.chosenBaking,
    }));
  setError(null);
  return client
    .mutate({
      mutation: upsertOrder,
      variables: {
        input: {
          idGuest,
          idOffer,
          items,
          withdrawRange: idSlot,
          tableNumber: tableNumber && Number.parseInt(tableNumber, 10),
          idPickupPoint
        },
      },
    })
    .catch((error: ApolloError) => {
      client.query({ query: getOffer, variables: { idOffer }, fetchPolicy: 'network-only' });
      const outOfStockError = error.graphQLErrors.find(e => e.message === 'OUT_OF_STOCK');
      if (outOfStockError) {
        store.dispatch(
          removeOutOfStockItemsFromCart({
            offerId: idOffer,
            items: outOfStockError?.extensions.exception.items || [],
          })
        );
      } else {
        store.dispatch(
          resetSlotForOffer({
            offerId: idOffer,
          })
        );
      }

      setError(errorData(error));
      throw error;
    });
};

const withExtraProps = ({
  withdrawalType,
  idOffer,
  footerType,
  totalQuantity,
  idSlot,
  navigation: { navigate },
  setLoading,
  upsertOrder: upsertOrderMutation,
  setItems,
  noReachedMinQuantityForCategory,
  tableNumber,
  offer,
  idGuest,
  idPickupPoint,
  fontSize
}: IProps & ILoadingProps & IConnectedProps): IWithExtraProps => {
  const enabled =
    (!noReachedMinQuantityForCategory &&
    !!(
      (footerType === FooterType.CART && totalQuantity > 0) ||
      (footerType === FooterType.SLOT && idSlot) ||
      (footerType === FooterType.SLOT && withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP && idPickupPoint)
    ));

  const goToProfile = () => {
    navigate('myInformations');
  };

  const onPress = async (): Promise<void> => {
    if (footerType === FooterType.CART) {
      try {
        const result = await upsertOrderMutation(idOffer, undefined, tableNumber);
        const orderId = get(result, 'data.order.id');

        if (withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP) {
          navigate('selectPickupPoint', {
            orderId,
            offer,
            idGuest
          });
          return;
        }
        if (
          withdrawalType !== OfferTemplateWithdrawalType.POS_CLICK_SERVE &&
          withdrawalType !== OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT &&
          withdrawalType !== OfferTemplateWithdrawalType.TABLE_SERVICE
          ) {
          navigate('offerSlots', {
            offerId: idOffer,
            orderWithdrawRange: get(result, 'data.order.withdrawRange'),
          });
          return;
        }

        // on seat click and serve, skip the slots screen
        navigate('cartSummary', {
          orderId,
          offerId: idOffer,
        });
      } catch (e) {
        if (e.graphQLErrors[0].message === 'OUT_OF_STOCK') {
          setItems(e.graphQLErrors[0].extensions.exception.items);
        }
      }
    } else if (footerType === FooterType.SLOT) {
      if (idPickupPoint) {
        try {
          setLoading(true);
          try {
            const res = await upsertOrderMutation(idOffer, undefined, undefined, idPickupPoint);
            navigate('cartSummary', {
              orderId: get(res, 'data.order.id'),
              offerId: idOffer,
            });
          } catch (e) {
            if (e.graphQLErrors[0].message === 'OUT_OF_STOCK') {
              setItems(e.graphQLErrors[0].extensions.exception.items);
            }
          }
        } finally {
          setLoading(false);
        }
        return;
      }
      if (idSlot) {
        try {
          setLoading(true);
          try {
            const res = await upsertOrderMutation(idOffer, idSlot);
            navigate('cartSummary', {
              orderId: get(res, 'data.order.id'),
              offerId: idOffer,
            });
          } catch (e) {
            if (e.graphQLErrors[0].message === 'OUT_OF_STOCK') {
              setItems(e.graphQLErrors[0].extensions.exception.items);
            }
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };
  return {
    title: enabled || (withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP)
    ? I18n.t('dashboard.eat.clickAndCollect.orderButton')
    : footerType === FooterType.SLOT
    ? I18n.t('dashboard.eat.clickAndCollect.slotChoice.slotButton')
    : I18n.t('dashboard.eat.clickAndCollect.cartButton'),
    onPress,
    enabled,
    goToProfile,
    fontSize
  };
};

export interface IGraphQLProps {
  idGuest?: string;
  isBadgeRequired?: boolean;
  hasPayOnSite?: boolean;
}

export default compose<IComponentProps, IProps>(
  withState('isLoading', 'setLoading', false),
  withState('error', 'setError', null),
  withNavigation,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getUserInfo, {
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      idGuest: get(props, 'data.getUser.guests.edges[0].node.id') || '',
      isBadgeRequired: get(props, 'data.getUser.currentHoldingView.holding.isBadgeRequired' || true),
    }),
  }),
  graphql(getOffer, {
    options: (props: IProps): QueryOpts => {
      return {
        variables: {
          idOffer: props.idOffer,
        },
      };
    },
    props: (props: OptionProps<IProps>): IGraphQLProps => {
      const offerPaymentTypes = get(props, 'data.offer.offerTemplate.paymentMethod.paymentTypes');
      const hasPayOnSite = offerPaymentTypes && offerPaymentTypes.includes(PaymentMethod.ON_SITE);
      return {
        hasPayOnSite: hasPayOnSite ? hasPayOnSite : false,
      };
    },
  }),
  graphql(getOffer, {
    options: (props: any): any => {
      return {
        variables: {
          idOffer: props.idOffer,
        },
      };
    },
    props: (props: any): any => {
      const offerTemplatePaymentTypes = get(props, 'data.offer.offerTemplate.paymentMethod.paymentTypes');
      return {
        offerTemplatePaymentTypes
      };
    },
  }),
  withApollo,
  withHandlers({ upsertOrder: upsertOrderHandler }),
  withProps(withExtraProps)
)(QuantityFooter);
