import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import ContentPage, { IProps } from './ContentPage.component';

const getOnePos = loader('../../../queries/getOnePos.gql');

export type IGraphQLProps = Pick<IProps, 'isLoading' | 'hasError' | 'pointOfSale'>;
export type IConnectedProps = NavigationInjectedProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  graphql(getOnePos, {
    options: (props: IConnectedProps): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        id: props.navigation.getParam('posId'),
      },
    }),
    props: (props: OptionProps<IConnectedProps>): IGraphQLProps => ({
      hasError: !!props.data!.error,
      isLoading: props.data!.loading,
      pointOfSale: get(props, 'data.getOnePos'),
    }),
  })
)(ContentPage);
