import { compose, withProps } from 'recompose';

import { isCancellable } from '../../services/orderStateTransformer';
import withOrder from '../../hoc/withOrder';
import withGuestPaymentProfile from '../../hoc/withGuestPaymentProfile';
import withNavigation from '../../hoc/withNavigation';

import OrderContent, { IProps } from './OrderContent.component';

interface IWithIsCancellableProps {
  isCancellable: boolean;
}

interface IWithOrderId {
  orderId: string;
}

interface IWithPaymentMethod {
  paymentMethod: string;
}

const withOrderContentProps = (ownProps: IProps): IWithIsCancellableProps => ({
  isCancellable: isCancellable(ownProps.order),
});

const withOrderId = (ownProps: IProps): IWithOrderId => ({
  orderId: ownProps.order.id,
});


const withPaymentMethod = (ownProps: IWithPaymentMethod): IWithPaymentMethod => ({
    paymentMethod: ownProps.paymentMethod
});

export type IConnectedProps = IWithIsCancellableProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withGuestPaymentProfile,
  withProps(withOrderId),
  withProps(withPaymentMethod),
  withOrder,
  withProps(withOrderContentProps)
)(OrderContent);
