import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withGuestPaymentProfile, {
  IWithGuestPaymentProfile,
} from '../../../../../hoc/withGuestPaymentProfile';
import withNavigation from '../../../../../hoc/withNavigation';
import { Currency, PaymentMethod } from '../../../../../types/clickandcollect/globalTypes';
import { IAppState } from '../../../../../redux/reducer';
import { getPaymentMethodByOfferId } from '../../../../../redux/clickAndCollect/selectors';

import CartSummary, { IComponentProps } from './CartSummary.component';

const cartSummaryDataQuery = loader('../../../../../queries/clickandcollect/cartSummaryData.gql');
const cartSummaryAdmissionAndPriceQuery = loader(
  '../../../../../queries/clickandcollect/cartSummaryAdmissionAndPrice.gql'
);

interface IMapStateToProps {
  paymentMethod: PaymentMethod;
}

const mapStateToProps = (state: IAppState, ownProps: IComponentProps): IMapStateToProps => {
  return {
    paymentMethod: getPaymentMethodByOfferId(
      state,
      ownProps.order?.offer?.id,
      ownProps.order?.offer?.offerTemplate?.paymentMethod?.paymentTypes,
      ownProps.haveBadge
    ),
  };
};

export default compose<IComponentProps, IMapStateToProps>(
  withNavigation,
  withGuestPaymentProfile,
  graphql(cartSummaryDataQuery, {
    options: (
      props: NavigationInjectedProps & IWithGuestPaymentProfile & IMapStateToProps
    ): QueryOpts => {
      return {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
          idOrder: props.navigation.getParam('orderId'),
          anonymGuest: props.isGuestAnomyn,
        },
      };
    },

    props: props => {
      const isLoading = get(props, 'data.loading');
      const error = isLoading ? undefined : get(props, 'data.error');
      const order = isLoading ? undefined : get(props, 'data.order');
      const quotationError = error
        ? error.graphQLErrors.find(
            (e: { extensions?: { original: { data?: { totalPrice: string } } } }) =>
              e.extensions &&
              e.extensions.original &&
              e.extensions.original.data &&
              e.extensions.original.data.totalPrice
          )
        : undefined;
      const fallbackTotalPrice = quotationError
        ? quotationError.extensions.original.data.totalPrice
        : undefined;
      return {
        isLoading,
        order:
          error && fallbackTotalPrice
            ? {
                ...order,
                // @todo Should be changed when adding new currencies
                totalPrice: { amount: fallbackTotalPrice, currency: Currency.EUR },
              }
            : order,
        quotationError: !!quotationError,
        refetch: get(props, 'data.refetch'),
      };
    },
  }),
  connect(mapStateToProps),
  graphql(cartSummaryAdmissionAndPriceQuery, {
    options: (
      props: NavigationInjectedProps & IWithGuestPaymentProfile & IMapStateToProps
    ): QueryOpts => {
      return {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          idOrder: props.navigation.getParam('orderId'),
          paymentMethod: props.paymentMethod,
        },
      };
    },

    props: props => {
      const isAdmissionLoading = get(props, 'data.loading');
      const error = isAdmissionLoading ? undefined : get(props, 'data.error');
      const admissionAndPrice = isAdmissionLoading
        ? undefined
        : get(props, 'data.orderAdmissionAndPrice.quotationComplete');

      const quotationSecondError = error
        ? error.graphQLErrors.find(
            (e: { extensions?: { original: { data?: { totalPrice: string } } } }) =>
              e.extensions &&
              e.extensions.original &&
              e.extensions.original.data &&
              e.extensions.original.data.totalPrice
          )
        : undefined;
      const fallbackTotalPrice = quotationSecondError
        ? quotationSecondError.extensions.original.data.totalPrice
        : undefined;
      return {
        isAdmissionLoading,
        admissionAndPrice:
          error && fallbackTotalPrice
            ? {
                ...admissionAndPrice,
                // @todo Should be changed when adding new currencies
                totalPrice: { amount: fallbackTotalPrice, currency: Currency.EUR },
              }
            : admissionAndPrice,
        quotationSecondError: !!quotationSecondError,
        refetchAdmission: get(props, 'data.refetch'),
      };
    },
  })
)(CartSummary);
