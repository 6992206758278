import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import EmailOrderButton from '../../../components/EmailOrderButton';
import LoadingCard from '../../../components/LoadingCard';
import Touchable from '../../../components/Touchable';
import theme from '../../../theme';
import { getOrder2_order_Order as IOrder } from '../../../types/clickandcollect/getOrder2';



export interface IProps {
  isLoading: boolean;
  onEmailButtonPress?: () => void;
  order?: IOrder;
  testID?: string;
}

class OrderCancelCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, order, onEmailButtonPress, testID } = this.props;
    return (
      <Touchable onPress={onEmailButtonPress} testID={testID}>
        <LoadingCard isLoading={isLoading} style={styles.card} big>
          <EmailOrderButton onPress={onEmailButtonPress} order={order} />
        </LoadingCard>
      </Touchable>
    );
  }
}

export interface IStyle {
  card: ViewStyle;
  text: TextStyle;
}

const BORDER_RADIUS = 8;

const styles = StyleSheet.create({
  card: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    marginBottom: theme.margins.cardUnit * 2,
    overflow: 'hidden',
  },
  text: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.textBlack,
    marginBottom: theme.margins.cardUnit * 2,
    textAlign: 'center',
  },
});

export default OrderCancelCard;
