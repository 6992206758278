import React, { PureComponent, ReactNode } from 'react';
import { ImageURISource, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import Touchable from '../Touchable';

export interface IProps {
  color: string;
  onPress?: () => void;
  sourceIcon: ImageURISource;
  style?: StyleProp<ViewStyle>;
  posId: string;
}

class PointOfSaleIconStripe extends PureComponent<IProps> {
  public render(): ReactNode {
    const { posId, onPress, style } = this.props;
    const InSiteIcon = theme.images.inSite;

    const Wrapper: typeof Touchable | typeof View = onPress ? Touchable : View;
    const containerStyle = StyleSheet.flatten([styles.container, style]);

    return (
      <Wrapper testID={`cardNamePos_${posId}`} onPress={onPress} style={containerStyle}>
        <InSiteIcon style={styles.iconMargin} />
        <View style={styles.titleContainer}>{this.props.children}</View>
      </Wrapper>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  titleContainer: ViewStyle;
  iconMargin: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: theme.margins.cardUnit * 2,
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  iconMargin: {
    marginRight: 10,
  },
});

export default PointOfSaleIconStripe;
