import { ImageURISource } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withNavigation from '../../../../../hoc/withNavigation';
import withOrder from '../../../../../hoc/withOrder';
import { orderStateLogo } from '../../../../../services/orderStateTransformer';
import { getOrder2_order_Order as IOrder } from '../../../../../types/clickandcollect/getOrder2';

import OrderDetails, { IProps } from './OrderDetails.component';
import withGuestPaymentProfile from '../../../../../hoc/withGuestPaymentProfile';

export interface IWithOrderProps {
  hasError: boolean;
  isLoading: boolean;
  order: IOrder | undefined;
}

export interface IWithOrderId {
  orderId: string;
}

export interface IWithSubsidies {
  isCreditCard: boolean;
}

export interface IWithPaymentMethod {
  paymentMethod: string;
}

interface IWithOrderStateImage {
  image?: ImageURISource;
}

const withOrderStateImage = (ownProps: IWithOrderProps): IWithOrderStateImage => ({
  image: ownProps.order ? orderStateLogo(ownProps.order.state) : undefined,
});

const withOrderId = (ownProps: NavigationInjectedProps): IWithOrderId => ({
  orderId: ownProps.navigation.getParam('orderId'),
});

const isCreditCard = (ownProps: NavigationInjectedProps): IWithSubsidies => ({
  isCreditCard: ownProps.navigation.getParam('isCreditCard'),
});

const paymentMethod = (ownProps: NavigationInjectedProps): IWithPaymentMethod => ({
  paymentMethod: ownProps.navigation.getParam('paymentMethod'),
});

export type IConnectedProps = NavigationInjectedProps & IWithOrderProps & IWithOrderStateImage;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withOrderId),
  withProps(paymentMethod),
  withOrder,
  withGuestPaymentProfile,
  withProps(withOrderStateImage),
  withProps(isCreditCard),
)(OrderDetails);
