import { IAppState } from '../reducer';

export const getHoldingId = (state: IAppState): string => {
  return state.holding.idHolding;
};

export const getBrandName = (state: IAppState): string => {
  return state.holding.brandName;
};

export const getBadgeNumber = (state: IAppState): string => {
  return state.holding.badgeNumber;
};

export const getIsBadgeRequired = (state: IAppState): boolean => {
  return state.holding.isBadgeRequired;
};

export const getIsBalanceDisabled = (state: IAppState): boolean => {
  return state.holding.isBalanceDisabled;
};

export const getExternalRefillUrl = (state: IAppState): string => {
  return state.holding.externalRefillUrl;
};
