import { routerMiddleware } from 'connected-react-router';
import { Middleware } from 'redux';

import sagaMiddleware from './sagas';

import { history } from './router.web';

const middlewares: Middleware[] = [sagaMiddleware, routerMiddleware(history)];

export default middlewares;
