import React, { PureComponent, ReactNode } from 'react';

import BalanceCard from '../../../../components/BalanceCard';
import TopUpCard from '../../../../components/TopUpCard';
import TransactionsCard from '../../../../components/TransactionsCard';
import { WebPage } from '../../../../componentsWeb';

import { IAmount } from '../../../../types/common';

export interface IProps {
  balance?: IAmount;
  balanceDateTime?: string;
  isLoading?: boolean;
}

class Balance extends PureComponent<IProps> {
  public render(): ReactNode {
    const { balance, balanceDateTime, isLoading } = this.props;

    return (
      <WebPage>
        <BalanceCard isLoading={isLoading} balance={balance} balanceDateTime={balanceDateTime} />
        <TopUpCard />
        <TransactionsCard contentContainerStyle={{ maxHeight: 500 }} />
      </WebPage>
    );
  } // TODO: TopUpCard is already include in TransactionsCard. Need to remove it from TransactionsCard>TransactionsList and fix on mobile
}

export default Balance;
