import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import { ArticleBaking } from '../../../types/tableService/globalTypes';
import { getOffer_offer_Offer_offerItems as IOfferItem } from '../../../types/clickandcollect/getOffer';
import LargeButton from '../../LargeButton';
import { LARGE_BUTTON_THEME } from '../../LargeButton/LargeButton.component';
import { styles as platformStyle } from './styles';
import Tag from '../../Tag/';
import { isWeb } from '../../../lib/responsive';

export interface IProps extends NavigationInjectedProps {
  onButtonPress: (chosenBaking: ArticleBaking | null) => void;
  article: IOfferItem | null;
}

export interface IState {
  isLoading: boolean;
  selectedBaking: ArticleBaking | null;
}

class BakingModalContent extends PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: false,
    selectedBaking: null,
  };

  public render(): ReactNode {
    const { onButtonPress, article } = this.props;
    const titleStyle = StyleSheet.flatten([styles.title, platformStyle.title]);
    if (!article) {
      return null;
    }
    return (
      <>
        <View style={styles.textContainer}>
          <Text style={styles.text}>{article.inheritedLabel}</Text>
          <Text style={titleStyle}>{I18n.t('dashboard.eat.tableService.bakingModal.title')}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: 1,
            width: '100%',
          }}
        >
          {(article.baking || []).map((baking: ArticleBaking) => {
            const tintColor =
              this.state.selectedBaking === baking ? theme.colors.active : theme.colors.gray;

            return (
              <Tag
                key={baking}
                onClick={(): void => this.setState({ selectedBaking: baking })}
                tintColor={tintColor}
                style={{
                  borderRadius: 4,
                  flexDirection: 'row',
                  marginLeft: theme.margins.cardUnit,
                  marginRight: theme.margins.cardUnit,
                  backgroundColor:
                    this.state.selectedBaking === baking ? theme.colors.blueLight : 'transparent',
                }}
                tagStyle={{
                  ...theme.fonts.cardText,
                  fontWeight: '300',
                  height: 17,
                  color: tintColor,
                }}
                iconSize={10}
                iconName="plus"
                label={I18n.t(`dashboard.eat.tableService.ArticleBaking.${baking}`)}
              />
            );
          })}
        </View>
        <View style={styles.largeButton}>
          <LargeButton
            style={[theme.buttons.LargeButton, { height: isWeb() ? 52 : 74 }]}
            text={I18n.t('dashboard.eat.tableService.validate')}
            theme={LARGE_BUTTON_THEME.success}
            onPress={(): void => onButtonPress(this.state.selectedBaking)}
            isLoading={this.state.isLoading}
            disabled={!this.state.selectedBaking}
          />
        </View>
      </>
    );
  }
}

export interface IPlatformStyle {
  title: TextStyle;
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.textBlack,
    fontStyle: 'italic',
    textAlign: 'center',
    minHeight: 32,
    marginBottom: 8
  },
  textContainer: {
    padding: 2 * theme.margins.cardUnit,
    height: 'auto',
  },
  title: {
    color: theme.colors.textBlack,
    paddingBottom: 2 * theme.margins.cardUnit,
    textAlign: 'center',
  },
  largeButton: {
    padding: isWeb() ? 8 : 0,
  },
});

export default BakingModalContent;
