import { StyleSheet } from 'react-native';

import theme from '../../theme';

import { IStyle } from './LegalInfoSections.component';

const CARD_BORDER_RADIUS = 4;

const styles = StyleSheet.create<IStyle>({
  card: {
    ...theme.shadows.card,
    borderRadius: CARD_BORDER_RADIUS,
    paddingVertical: 0,
  },
});

export default styles;
