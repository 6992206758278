import { withClientState } from 'apollo-link-state';

import { IPosStatus } from '../../constants';
import { getPosStatusFromSchedules } from '../../services/schedulesFormatter';
import { IPos } from '../../types/common';
import { cache } from '../client';

export const posLink = withClientState({
  cache,
  resolvers: {
    Pos: {
      status: (pos: IPos): IPosStatus => getPosStatusFromSchedules(pos.schedules),
    },
  },
});
