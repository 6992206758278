/* eslint-disable quotes */
export default {
  appName: 'Foodi',
  auth0: {
    error: 'Une erreur est survenue. Veuillez essayer à nouveau.',
  },
  balance: {
    cardTitle: 'Mon solde',
    cardTitleExternal: 'Solde | Recharge',
    dateTime: 'Le {{dayOfWeek}} {{dayOfMonth}} {{month}} à {{hour}}',
    emptyMessage: 'Solde indisponible',
  },
  codepush: {
    title: 'Veuillez patienter',
  },
  dashboard: {
    balance: {
      title: 'SOLDE',
      topUp: {
        other: 'autre',
        format: 'Veuillez saisir un montant au format 15,20 €',
        minamount: 'Vous ne pouvez pas recharger moins de {{minAmount}} €',
        maxamount: 'Vous ne pouvez pas recharger plus de {{maxAmount}} € à la fois',
        callbackModal: {
          defaultTitle:
            'Une erreur est survenue, nous vous invitons à vérifier sous 10 minutes si votre solde a été mis à jour, et sinon à réessayer.',
          networkErrorTitle:
            'Une erreur de connexion est survenue, nous vous invitons à vérifier sous 10 minutes si votre solde a été mis à jour, et sinon à réessayer.',
          delayTitle:
            'Votre paiement a bien été enregistré. Attention, exceptionnellement un délai supplémentaire sera nécessaire pour que votre solde soit mis à jour, nous sommes désolés pour la gêne occasionnée.',
          potentiaDelaylTitle:
            'Votre paiement a bien été enregistré ! Attention, votre solde peut potentiellement mettre plus de temps à être actualisé.',
          failTitle:
            "Désolé, votre rechargement a été interrompu ou n'a pas pu aboutir. Merci de réessayer.",
          successTitle:
            "Rechargement effectué ! Votre solde est bien rechargé, mais peut mettre jusqu’à 10 minutes à s'actualiser dans Foodi.",
          goBack: 'Retour',
          validate: 'Valider',
        },
        payment: {
          title: 'Paiement',
        },
        successModal: {
          title: `Votre solde peut mettre jusqu’à 10 minutes à s'actualiser.`,
          validate: 'Valider',
        },
      },
      receipt: {
        title: 'Duplicata',
        error:
          'Le reçu est indisponible. Nous vous prions de nous excuser pour la gêne occasionnée. Nos équipes sont mobilisées pour résoudre cette anomalie au plus vite.',
      },
    },
    cateringBusiness: {
      title: 'BUSINESS TRAITEUR',
    },
    eat: {
      orderState: {
        clickAndCollect: {
          Cart: 'Votre commande n’a pas été finalisée',
          Accepted: 'Votre commande a été acceptée.\n\nNos équipes la préparent !',
          Preparing: 'Votre commande a été acceptée.\n\nNos équipes la préparent !',
          Ready:
            'Votre commande est prête !\n\nVous pourrez la retirer au lieu et aux horaires de retrait indiqués.',
          Completed: 'Votre commande a bien été retirée.\n\nBon appétit !',
          Cancelled:
            'Vous avez annulé votre commande.\n\nLe montant intégral de votre commande va vous être remboursé.',
          Refused: `Nous sommes désolés, nous n'avons pas pu accepter votre commande. \n\nLe montant intégral de votre commande va vous être remboursé.`,
          Abandoned:
            'Nous sommes désolés, vous n’êtes pas venu retirer votre commande.\n\nLa prochaine fois, en cas d’indisponibilité, pensez à annuler votre commande dans les délais impartis.',
        },
        tableService: {
          Cart: 'Votre commande n’a pas été finalisée',
          EmployeeCart: 'La commande du convive n’a pas été finalisée',
          Accepted: 'Votre commande a été acceptée.\n\nLaissez-vous servir et bon appétit !',
          Completed: `Votre repas est fini ou sur le point de l'être. \n\nMerci d’avoir utilisé notre service !`,
          Cancelled:
            'Vous avez annulé votre commande. \n\nLe montant intégral de votre commande va vous être remboursé.',
          Refused: `Nous sommes désolés, nous n'avons pas pu accepter votre commande.\n\nLe montant intégral de votre commande va vous être remboursé.`,
          Processed: '',
        },
        seatClickAndServe: {
          Ready: 'Votre commande est prête!',
        },
      },
      error: {
        expired: 'Votre panier a expiré',
        quotation: "Le montant du panier n'a pas pu être calculé",
        validation: {
          title: 'Il y a un petit problème !',
          min: {
            one: 'Veuillez sélectionner au moins un article parmi les catégories suivantes :',
            other:
              'Veuillez sélectionner au moins {{count}} articles parmi les catégories suivantes :',
          },
          max: {
            one: 'Veuillez sélectionner un seul article parmi les catégories suivantes : ',
            other:
              'Veuillez sélectionner au plus {{count}} articles parmi les catégories suivantes :',
          },
        },
        temporaryCode: {
          invalid: 'Code invalide. Merci de le resaisir.',
          expired: "Le code saisi n'est plus valide.",
          offer: {
            invalid: "L'offre n'est pas ouverte actuellement.",
          },
          offerTemplate: {
            invalid: 'Vous ne pouvez pas commander.',
          },
        },
      },
      bookingService: {
        orderDetails: {
          cancelModalTitle: 'Souhaitez-vous annuler votre réservation ?',
          cancelModalSubtitle: 'Vous pourrez toujours revenir et réserver de nouveau !',
        },
        full: 'Complet',
        reserve: 'Réserver',
        modal: {
          title: 'Réservation pour :',
          subtitle: 'Choisissez votre horaire',
          cancelReservation: 'Annuler',
          changeReservation: 'Modifier',
          reservationMessageTitle: 'Merci de votre réservation.',
          reservationMessageBody: 'Nous vous attendons avec impatience !',
          fullyBookedTitle: 'Nous sommes complets pour le moment.',
          fullyBookedDescription: 'Vous pouvez tout de même venir en fin de service.',
          inactiveOfferTitle: 'La réservation n’est pas disponible à cette date. ',
          inactiveOfferDescription: 'N’hésitez pas à réserver un autre jour.',
          closedOfferTitle: 'La réservation n’est plus disponible aujourd’hui.',
          closedOfferDescription: 'N’hésitez pas à réserver pour les jours suivants.',
          bookedOtherPosTitle: 'Il semblerait que vous ayez une réservation ailleurs.',
          bookedOtherPosDescription: 'Cliquez ici pour voir votre réservation :',
          bookedOtherPosButton: 'Voir ma réservation',
        },
        orderButton: 'Confirmer ma réservation',
        slotChoice: {
          slotButton: 'Sélectionnez un horaire',
          remainingSeatsSingular: 'place restante',
          remainingSeatsPlural: 'places restantes',
          noRemainingSeats: 'Pas de places restantes',
        },
        slotFullErrorTitle: 'Navré, il semblerait que ce créneau soit déjà complet...',
        slotFullErrorDescription:
          'Merci de sélectionner un nouveau créneau parmis ceux qui sont disponibles.',
        goBack: 'Retour',
      },
      bookingOrderCard: {
        reservations: 'Réservations',
        cardTitle: 'Choisissez votre restaurant',
        title: 'Mes réservations de la semaine',
        today: "aujourd'hui",
        tomorrow: 'Aucune réservation ',
      },
      tableService: {
        table: {
          title: 'Votre table',
          caption: 'Saisissez le numéro de votre table.',
          error: 'Ce numéro de table est invalide',
          edit: 'Modifier mon numéro',
          valid: 'Suivant',
        },
        bakingModal: {
          title: 'Choisissez votre cuisson',
        },
        ArticleBaking: {
          ROSE: 'Rosé',
          RAW: 'Cru',
          RARE: 'Bleu',
          MEDIUMRARE: 'Saignant',
          MEDIUM: 'Bien cuit',
          WELLDONE: 'À point',
          null: '',
        },
        temporaryCode: {
          title: 'Je commande',
          subTitle: 'Saisissez le code fourni par votre serveur.',
          or: 'ou',
          foodi: "J'ai un compte Foodi",
        },
        title: 'Sélection de produits',
        validate: 'Valider',
      },
      clickAndCollect: {
        articles: {
          type: {
            beverage: 'Boissons',
            dessert: 'Desserts',
            dish: 'Plats',
            salad: 'Salades',
            sandwich: 'Sandwiches',
            snacking: 'Snacks',
            soup: 'Soupes',
            starter: 'Entrées',
            bread: 'Pains',
            dairy: 'Yaourts',
            fruit: 'Fruits',
            miscellaneous: 'Divers',
            pastry: 'Pâtisseries',
            side_dish: 'Accompagnements',
            unsorted: 'Autres',
          },
          portion: 'ajouté(s)',
        },
        bundle: {
          title: {
            one: 'Choisissez votre',
            other: 'Choisissez vos',
          },
        },
        cartButton: 'Sélectionnez un produit',
        goBack: 'Retour',
        noticeText:
          'Dans le cadre de cette offre, veuillez sélectionner un article parmi les catégories suivantes : Plats, Salades ou Sandwichs.',
        noStock: {
          title: 'Désolé, il n’y en a plus ! ',
          subtitle:
            'Aie, il semble que nous soyons à court de {{products}}.\n\n Veuillez sélectionner un autre produit.',
          button: 'Retour',
        },
        cartSummary: {
          admission: 'Admission',
          admissionWarning:
            "Attention, le total indiqué ne prend pas en compte l'admission, ni les subventions de votre employeur",
          balance: 'Votre solde',
          button: 'Valider et payer',
          cancelButton: 'Modifier',
          confirmButton: 'Commander',
          loading: 'Chargement...',
          dateTime: '{{dayOfWeek}} {{dayOfMonth}}/{{month}}/{{year}}',
          dateTimeRange:
            '{{timeStartHours}}h{{timeStartMinutes}} - {{timeEndHours}}h{{timeEndMinutes}}',
          deduction: 'Déduction demi-pension / subvention',
          items: 'Articles',
          receptionDate: 'date de retrait',
          receptionTime: 'Heure de retrait',
          pickupPoint: 'Point de retrait',
          receptionPlace: 'lieu de retrait',
          requireTopUp: 'Votre solde est insuffisant',
          refillLater: 'Pensez à recharger.',
          subsidies: 'Subventions',
          fee: 'Perçu pour compte',
          title: 'Récapitulatif',
          tooltipMessage:
            'En fonction du restaurant, les moyens de paiement peuvent varier entre : carte restaurant, titre restaurant papier, chèque, espèces et badge.',
          container: 'Consigne',
          payButton: 'Valider et payer',
          payOnSiteInfo:
            'Le montant total de votre panier est donné à titre indicatif. Il représente le coût maximal encaissable pour votre commande. Le montant de l’admission pourra être réduit selon les conditions du contrat d’exploitation de votre restaurant. Également, si vous réglez par badge, vous pourrez bénéficier d’une subvention accordée par votre employeur et le montant du « Perçu pour compte » sera déduit.',
          payOnSiteInfoIsSchool:
            'Votre forfait demi-pension ou votre subvention sera calculé et déduit lors du retrait de votre commande.',
          edenredInfo: 'Subventions non applicables',
          payWhenPickUpOrder: 'À régler au retrait de votre commande',
          topUpButton: 'Recharger',
          totalAmount: 'Total du panier',
          reloadQuotation: 'Calculer le montant à payer',
          termsOfSaleValidation0: 'En passant commande, je reconnais avoir lu et accepté les',
          termsOfSaleValidation1: 'Conditions Générales de Vente',
          selectPayment: 'Sélectionner le moyen de paiement',
          seeMore: 'En savoir plus',
          addBadge: 'Ajouter votre badge',
          otherCards: 'Voir plus',
          warningMessage:
            'Attention, le choix d’un type de paiement autre que le badge n’inclurera pas la subvention.',
          cardBalance: 'Solde',
          dailyBalance: 'Solde du jour',
          cardType: {
            BADGE: 'Badge',
            CREDIT_CARD: 'Carte bancaire',
            EDENRED: 'MyEdenred | Titre-restaurant',
            ON_SITE: 'Tous les moyens de paiement acceptés',
          },
          creditCardNotCancelable: 'Annulation non disponible',
          advanceTotalWarning:
            'Ce montant est indicatif et pourrait être ajusté lors de la récupération de votre commande.',
          comment: 'Commentaire',
          commentHint:
            'Saisissez un commentaire pour le gérant. Nous ferons de notre mieux pour vous servir',
          commentLengthError: 'La longueur maximale de 250 caractères est atteinte.',
          quotationInfo: {
            admissionTitle: 'Admission',
            admissionDescription:
              'Le montant de l’admission correspond à la part de frais fixes liés à la préparation de votre repas. En cas de paiement en caisse, ce montant qui vous sera facturé peut être inférieur au montant indiqué sur le panier, selon votre type de paiement, au retrait de votre commande.',
            subsidyTitle: 'Subvention',
            subsidyDescription:
              'La Subvention correspond à la part du prix de repas pris en charge par votre employeur. En cas de paiement en caisse, le montant de la Subvention versée par votre employeur sera automatiquement déduite de votre ticket lors du paiement par badge au retrait de votre commande.',
            collectedTitle: 'Perçu pour compte',
            collectedDescription:
              'Le montant du perçu pour compte correspond à la redevance versée au propriétaire du local pour l’utilisation de l’énergie, de l’eau et autres frais liés à l’activité de restauration. Il peut s’agir d’un montant fixe par transaction ou d’un % sur le montant de la nourriture. En cas de paiement en caisse, le montant qui vous sera facturé peut être inférieur ou nul au montant indiqué sur le panier, selon votre type de paiement, au retrait de votre commande.',
            deductionTitle: 'Demi-pension / Subvention',
            deductionDescription:
              'Le montant de votre déduction correspond à votre forfait demi-pension, ou à votre subvention qui représente la part du prix de repas pris en charge par l’établissement. Ce montant sera calculé et déduit lors du retrait de votre commande.',
          },
        },
        confirmCancelledOrder: {
          button: 'Retour',
          subtitle:
            'Votre commande a bien été annulée.\nVotre solde sera recrédité du montant intégral de la commande dans les prochaines minutes.',
          title: 'Commande annulée',
        },
        orderDetails: {
          articles: 'ARTICLES',
          comment: 'COMMENTAIRE',
          commentInfo: 'Nous ferons de notre mieux pour votre demande.',
          cancel: 'ANNULER',
          cancelMessageClickAndPickup: 'Votre commande peut être annulée tant que la période de commande n’est pas terminée',
          cancelErrorCancelled: 'Votre commande est déjà annulée.',
          cancelErrorCompleted: `Votre commande a été retirée et n'est plus annulable.`,
          cancelErrorNotCancelable: 'Votre commande ne peut plus être annulée.',
          cancelErrorNotConfirmed: `Votre commande n'a pas encore été passée.`,
          cancelModalSubtitle: 'Le montant intégral de la commande sera recrédité sur votre compte',
          cancelModalTitle: 'Voulez-vous annuler la commande ?',
          cancelSubtitle: `Vous pouvez annuler votre commande jusqu’au {{dayOfMonth}}/{{month}}/{{year}} à {{hours}}:{{minutes}} ou avant qu’elle soit en statut "Prête"`,
          cancelSubtitleAfterReadyStatus: `Votre commande peut être annulée tant que son statut n'est pas "Prête"`,
          emailButton: 'Envoyer mon ticket sur mon adresse mail',
          emailModalTitle: 'Pour recevoir le reçu',
          emailModalError: 'Votre email semble invalide',
          emailModalInput: 'Saisissez votre adresse email',
          emailModalConfirm: 'Envoyer le reçu',
          deleteButton: 'Annuler',
          errorMessage: 'Malheureusement, votre commande n’a pas pu aboutir.',
          order: 'Commande',
          orderArticleOutOfStock: `Nous sommes désolés, un des articles n'est plus disponible.`,
          orderNumber: 'NUMÉRO DE COMMANDE',
          receptionDate: 'CRÉNEAU DE RETRAIT',
          receptionPlace: 'LIEU DE RETRAIT',
          saveButton: 'Conserver',
          status: 'STATUS',
          title: 'Votre commande',
          totalPrice: 'Total de la commande',
          totalPricePayOnSite: 'Total - Paiement sur place',
          withdrawalMethodOrderNotReady:
            'Retrouver ici votre bon de retrait une fois la commande prête',
          withdrawalMethodOrderReadyTitle: 'Retirer votre commande',
          withdrawalMethodOrderReadySubTitle: 'Afficher le QR code',
          titleQRCode: 'Présentez le QR code',
          subtitleQRCode: 'Passez le QR code de disposition.',
          descriptionQRCode:
            'Pour une bonne lecture, pensez à augmenter la luminiosite de votre mobile.',
          locker: 'Casier nº',
          code: 'Code',
          qrCodeBrightness:
            'Modification momentanée du niveau de luminosité uniquement pour faciliter la lecture du QR code',
          qrCodeError: `Votre commande est prete mais pas encore disponible au retrait. Nos équipes font le necessaire pour que cela soit fait rapidement, n'hésitez pas à rafraichir cette page régulièrement et vous rapprocher des équipes du restaurant si le situation de change pas.`,
          warningAdvanceOrder:
            'Pour les commandes à l’avance, votre ticket sera disponible le jour du retrait de la commande.',
          seatClickAndServe: {
            table: 'Table',
          },
          container: 'Consigne :',
        },
        dateTime: '{{dayOfWeek}} {{dayOfMonth}}/{{month}}',
        description: 'Des légumes frais directement du producteur au consommateur.',
        error: 'Certains produits sélectionnés ne sont plus disponibles',
        order: 'Commander',
        info: "Plus d'info",
        orderButton: 'Suivant',
        offerUnavailable: 'Offre indisponible',
        withdraw:
          'De {{timeStartHours}}:{{timeStartMinutes}} à {{timeEndHours}}:{{timeEndMinutes}}',
        orderState: {
          abandoned: 'Expirée',
          accepted: 'Validée',
          cancelled: 'Annulée',
          cart: 'Panier',
          completed: 'Retirée',
          onHold: 'En attente d’acceptation',
          preparing: 'En préparation',
          ready: 'Prête',
          refused: 'Refusée',
        },
        outOfStock: 'Plus de stock',
        slotChoice: {
          dateTime: '{{dayOfWeek}} {{dayOfMonth}} {{month}}',
          none: 'Plus de créneau disponible',
          slotButton: 'Sélectionnez un créneau',
          subtitle: 'Choisissez l’horaire de retrait',
          title: 'Créneaux de retrait',
        },
        selectPickupPoint: {
          title: 'Point de retrait',
          emptyList: 'Pas de résultat.',
          lastPointSelected: 'Dernier point de retrait choisi',
          searchInputPlaceHolder: 'Rechercher un point de retrait',
          noChosenPointAlert: 'Vous devez choisir un Point de retrait',
          chosenPointAlert: 'Vous avez choisi',
          footerButton: 'Sélectionner un point de retrait',
        },
        stock: '{{stock}} en stock',
        subtitle: 'Commandez avant {{startDate}} et soyez livré {{endDate}}',
        title: 'Sélection de produits',
        titleBundle: 'Composez votre formule',
        unavailableOffer: 'Cette offre n’est pas disponible actuellement.',
        offerOpeningAt: "Commandez aujourd'hui à partir de {{startHour}}",
        mealHeartRule: {
          min0_max1:
            'Vous pouvez sélectionner au maximum un produit parmi les familles {{families}}.',
          min1_max1: 'Vous devez sélectionner un seul produit parmi les familles {{families}}.',
          maxBigger1:
            'Vous pouvez sélectionner au maximum {{maxQuantity}} produit(s) parmi les familles {{families}}.',
          minBigger1:
            'Vous pouvez sélectionner entre {{minQuantity}} et {{maxQuantity}} produit(s) parmi les familles {{families}}.',
          min_equal_to_max:
            'Vous devez sélectionner {{maxQuantity}} produit(s) parmi les familles {{families}}.',
        },
        articleDetails: {
          addToCart: 'Ajouter au panier',
          label: 'Label',
          tags: 'Tags',
        },
        noOffers: {
          posClosed:
            'Cette Offre est indisponible aujourd’hui. Veuillez commander pour un autre jour.',
          noOffersForDate:
            'Cette Offre est indisponible aujourd’hui.  Veuillez commander pour un autre jour.',
          otherOffersForDate:
            'La carte est indisponible à ce jour. Veuillez découvrir nos autres offres ou commandez pour un autre jour.',
        },
      },
      container: 'consigne',
      containerInfo:
        '* La consigne sera remboursée lors du retour du contenant. Voir modalités avec l’équipe de restauration.',
      filterDropdown: {
        defaultTitle: 'Toutes les zones',
        title: 'zones sélectionnées',
        unselect: 'Désélectionner tout',
      },
      order: 'Commande',
      pageTitle: {
        brasserie: 'Les brasseries',
        cafeteria: 'Les cafétérias',
        offers: 'Les offres',
        orders: 'Mes commandes',
        seeLess: 'Voir moins',
        seeMore: 'Voir plus',
        self: 'Les restaurants',
      },
      tabBar: {
        here: 'SUR PLACE',
        takeAway: 'À EMPORTER',
      },
      title: 'MANGER',
    },
    home: {
      externalServices: {
        useful: 'Utile',
      },
      marketing: {
        title: "L'actualité",
        categoryAdvice: 'Conseil nutrition du jour',
        categoryNews: 'Actualité',
        error: "Oups, il n'y a pas de contenu ici. Merci de réessayer.",
        seeMore: 'VOIR PLUS',
        published: 'Publié le',
      },
      message: {
        afternoon: 'À très vite pour une pause !',
        morning: "C'est bientôt l'heure !",
      },
      title: 'ACCUEIL',
      takeAway: 'Nos offres à emporter',
      welcomingSentence: {
        greeting: 'Bienvenue',
        desire: 'Quelle est votre envie du jour',
        welcome: 'Bienvenue sur Foodi',
        first: 'Foodi',
        middle: 'pour vos pauses repas',
        last: 'plaisir',
        completeMyProfile: 'Compléter mon profil',
        profileIncomplete: 'Votre profil est incomplet',
        profileIncompleteDesc:
          'Pour accéder aux informations de vos restaurants et services, sélectionnez votre établissement et saisissez votre numéro badge.',
      },
    },
    menu: {
      error: 'Une erreur est survenue, merci de réessayer',
      pageTitle: 'À la carte',
    },
    menuElementDetails: {
      allergensDisclaimerPage: {
        subtext:
          'Dans notre cuisine, toutes ou partie de ces substances allergènes sont présentes. Aussi, malgré toutes nos précautions, nous ne pouvons pas exclure la contamination croisée accidentelle entre préparations.\n\nL’affichage des substances allergènes est effectuée, à date, sur la base des informations communiquées par nos fournisseurs sur leurs propres produits.\n\nLes informations sur les substances allergènes sont données à titre uniquement indicatif. ',
        text:
          'Nos informations sur les allergènes concernent exclusivement les 14 allergènes majeurs que nous avons introduits volontairement dans nos préparations. ',
        title: 'Allergènes',
      },
      infoRecipeDisclaimer: {
        cancelButton: 'Décliner',
        checkboxText:
          "En cochant cette case, j'accepte de participer à ce programme de test et je comprends que les informations indiquées sont uniquement à caractère indicatif, et qu'elles peuvent être partiellement ou totalement erronées.",
        confirmButton: 'Valider',
        subtext:
          "Il s'agit d'une fonctionnalité en phase de test.\nLes informations affichées peuvent être partiellement ou totalement erronées.",
        text:
          "Foodi évolue et souhaite désormais vous apporter plus d'informations sur les recettes de vos restaurants.\n\nNous mettons donc en place une nouvelle fonctionnalité pour vous remonter des indications sur les allergènes et sur les valeurs nutritionnelles de ces recettes.",
        title: 'Informations sur les recettes',
      },
      informationSection: {
        allergens: {
          celery: 'Céleri',
          egg: 'Oeuf',
          fish: 'Poisson',
          gluten: 'Gluten',
          lupin: 'Lupin',
          milk: 'Lait',
          mollusc: 'Mollusque',
          mustard: 'Moutarde',
          nut: 'Fruit à coque',
          peanut: 'Arachide',
          sesame: 'Sésame',
          shellfish: 'Crustacé',
          soya: 'Soja',
          sulfite: 'Sulfite',
          allergenFree: 'Sans allergène',
          infoIncomplete: 'Info. Incomplète',
          infoNotDisclosed: "Information non communiquée"
        },
        certifications: {
          aocp: 'Appellation origin controlee/protege',
          sustainableAgriculture: 'Agriculture raisonnée',
          bio: 'Agriculture Bio',
          casher: 'Casher',
          certified: 'Certifié',
          bioConversion: 'BIO en conversion',
          fairTrade: 'Equitable',
          halal: 'HALAL',
          igp: 'IGP',
          redLabel: 'Label Rouge',
          frenchProducts: 'Produits Français',
          sustainableSeafood: 'Peche responsable',
          meat: 'Race à Viande',
          regional: 'Regional',
          eu: 'Union Européenne',
          frenchBovineMeet: 'Viande Bovine Française',
          frenchMeat: 'Viande Française',
          msc: 'MSC',
          blueWhiteHearth: 'Bleu Blanc Coeur',
          ecolabel: 'ECO Label',
          hve: 'HVE',
          frenchPork: 'Porc Français',
          aop: 'AOP',
          frenchPoultry: 'Volaille Française',
          infoIncomplete: 'Info. Incomplète',
          infoNotDisclosed: "Information non communiquée"

        },
        allergensDisclaimer:
          'Les informations sur les allergènes sont données uniquement à titre indicatif.',
        allergensTitle: 'Allergènes',
        moreDetailsButton: 'En savoir plus',
        noAllergen: 'Pas d’allergène identifié dans ce plat.',
        priceTitle: 'Prix',
        title: 'Informations du plat',
      },
      calcium: 'Calcium',
      carbohydrate: 'Glucides',
      energyValue: 'Energie',
      grams: 'g',
      includingSaturatedAcids: 'Dont acides gras saturés',
      includingSugar: 'Dont sucres',
      kilocalories: 'kcal',
      lipid: 'Lipides',
      milligrams: 'mg',
      noInfo: 'Information non disponible',
      nutritionalCompositionSubtitle: 'Ces valeurs correspondent à une portion de 100g',
      nutritionalCompositionTitle: 'Valeurs nutritionnelles',
      protein: 'Protéines',
      salt: 'Sel',
      title: 'Détail du plat',
      label: 'Label',
      ingredients: 'Ingrédients',
      allergens: {
        show: "Afficher les allergènes de la recette",
        hide: "Masquer les allergènes de la recette",
        title: 'Allergènes',
        paragraphs: [
          'Nos informations sur les allergènes concernent exclusivement les 14 allergènes majeurs que nous avons introduits volontairement dans nos préparations.',
          'Dans notre cuisine, toutes ou partie de ces substances allergènes sont présentes. Aussi, malgré toutes nos précautions, nous ne pouvons pas exclure la contamination croisée accidentelle entre préparations.',
          'L’affichage des substances allergènes est effectuée, à date, sur la base des informations communiquées par nos fournisseurs sur leurs propres produits.'
        ],
        allergensWarningMessageOscar: "L’affichage des allergènes, dans cette application ou votre restaurant, ne saurait remplacer la mise en place au quotidien d’une prestation sécurisée (repas sécurisé de l’agro-alimentaire exempts des 14 allergènes et/ou panier repas).\n" +
          "\n" +
          "S’agissant de la restauration scolaire, cette prestation sécurisée est à formaliser dans un « Projet d’Accueil Individualisé », en lien avec votre établissement scolaire.\n" +
          "\n" +
          "Malgré toutes les précautions prises lors de l’élaboration de nos recettes, des allergènes, qui ne figurent pas dans nos recettes, peuvent être présents dans nos plats en tant que traces ou ingrédients.\n" +
          "\n" +
          "En effet :\n" +
          "\n" +
          "\u2022 Les informations relatives aux substances allergènes que nous communiquons sont d’une part issues des données elles-mêmes communiquées par nos fournisseurs sur les produits qu’ils nous livrent et d’autre part soumises à la disponibilité et mises à jour desdits produits dans notre base de données.\n" +
          "\n" +
          "\u2022 Les mentions d’étiquetage des industriels de type « traces » ou « peut contenir » ne sont pas reportées dans les informations que nous communiquons\n" +
          "\n" +
          "\u2022 les informations que nous communiquons sont établies de manière prévisionnelle, dans la mesure où notamment selon la disponibilité des produits, les matières premières, donc les allergènes, peuvent changer lors de la fabrication d’une recette.\n" +
          "\n" +
          "La liste des plats effectivement proposés, avec leurs allergènes, est disponible chaque jour dans le restaurant, avec les ajustements éventuels liés à la production du jour.\n" +
          "\n" +
          "Nous vous invitons en conséquence, en complément des informations présentes sur cette application, à consulter la liste mise à disposition dans votre restaurant ou à prendre contact avec le responsable de la restauration.\n" +
          "\n" +
          "Toutefois, nous attirons votre attention sur le fait que les conditions de préparation des repas dans nos cuisines ne permettent pas de prévenir les contaminations croisées d’ingrédients allergènes d’une préparation à une autre. Ces contaminations croisées lors de l’élaboration ou du service sont inhérentes à notre fonctionnement. Dans ces conditions, nous vous précisons que les informations que nous communiquons, par affichage ou via notre responsable de la restauration, sont fournies à titre indicatif uniquement. Nous déclinons en conséquence toute responsabilité en cas de présence de substances allergènes qui ne seraient pas mentionnées dans les informations que nous communiquons ou résulteraient de manipulations d’ingrédients ou de plats par des personnes étrangères à nos services.",
        allergensWarningMessageWinapro: "L’affichage des allergènes, dans cette application ou votre restaurant, ne saurait remplacer la mise en place au quotidien d’une prestation sécurisée (repas sécurisé de l’agro-alimentaire exempts des 14 allergènes et/ou panier repas).\n" +
          "\n" +
          "S’agissant de la restauration scolaire, cette prestation sécurisée est à formaliser dans un « Projet d’Accueil Individualisé », en lien avec votre établissement scolaire.\n" +
          "\n" +
          "Malgré toutes les précautions prises lors de l’élaboration de nos recettes, des allergènes, qui ne figurent pas dans nos recettes, peuvent être présents dans nos plats en tant que traces ou ingrédients.\n" +
          "\n" +
          "En effet :\n" +
          "\n" +
          "\u2022 Les informations relatives aux substances allergènes que nous communiquons sont d’une part issues des données elles-mêmes communiquées par nos fournisseurs sur les produits qu’ils nous livrent et d’autre part soumises à la disponibilité et mises à jour desdits produits dans notre base de données.\n" +
          "\n" +
          "\u2022 Les mentions d’étiquetage des industriels de type « traces » ou « peut contenir » ne sont pas reportées dans les informations que nous communiquons\n" +
          "\n" +
          "\u2022 les informations que nous communiquons sont établies de manière prévisionnelle, dans la mesure où notamment selon la disponibilité des produits, les matières premières, donc les allergènes, peuvent changer lors de la fabrication d’une recette.\n" +
          "\n" +
          "Toutefois, nous attirons votre attention sur le fait que les conditions de préparation des repas dans nos cuisines ne permettent pas de prévenir les contaminations croisées d’ingrédients allergènes d’une préparation à une autre. Ces contaminations croisées lors de l’élaboration ou du service sont inhérentes à notre fonctionnement. Dans ces conditions, nous vous précisons que les informations que nous communiquons, par affichage ou via notre responsable de la restauration, sont fournies à titre indicatif uniquement. Nous déclinons en conséquence toute responsabilité en cas de présence de substances allergènes qui ne seraient pas mentionnées dans les informations que nous communiquons ou résulteraient de manipulations d’ingrédients ou de plats par des personnes étrangères à nos services.",
        allergensWarningMessageOther: "",
      }
    },
    modalContest: {
      error:
        'Oops, nous avons des difficultés à vous afficher le contenu. Merci de réessayer ultérieurement.',
    },
    profile: {
      languages: {
        en: 'English',
        fr: 'Français',
        title: 'Changer de langue',
        validate: 'Valider',
      },
      logOut: 'Déconnexion',
      myInformations: {
        alias: 'Pseudo',
        aliasPlaceholder: 'Exemple: Toto, Grandpapa, Ma_puce…',
        aliasPlaceholderShort: 'Exemple: Toto, Grandpapa…',
        add: 'Ajouter',
        active: 'Actif',
        badge: 'Badge',
        badgeNumber: 'Numéro de badge',
        email: 'E-mail',
        establishment: 'Établissement',
        firstName: 'Prénom',
        lastName: 'Nom',
        title: 'Mes informations',
        holdingAndBadge: 'Établissements et badges',
        holdingViewDescription: 'Pour accéder aux informations des restaurants et services.',
        holdingViewInformation:
          'Pour être actif sur un autre établissement, cliquer dessus pour le sélectionner.',
        validate: 'Valider',
        wrongBadge:
          'Le nom et numéro de badge ne correspondent pas. Veuillez modifier votre saisie ou contacter le support > help@foodi.fr',
        holdingModal: {
          deleteModalTitle: 'Êtes-vous sûr de supprimer cet établissement?',
          deleteModalMessage:
            'Si vous êtes actif sur cet établissement et que vous en avez enregistré d’autres, vous basculerez sur le suivant de la liste.',
          deleteModalCta: 'Supprimer',
        },
        deleteAccount: {
          doYouWantToLeave: 'Vous souhaitez nous quitter ?',
          deleteMyAccount: 'Supprimer mon compte',
          deleteModalTitle: 'Êtes-vous sûr de vouloir supprimer votre compte Foodi ?',
          deleteModalText:
            'Vous allez perdre définitivement toutes vos informations liées à votre compte. Si votre solde n’est pas à zéro, contactez votre restaurant pour le régulariser.',
          confirm: 'Supprimer',
          cancel: 'Annuler',
          accountDeleted: 'Votre compte a été supprimé.',
        },
      },
      notifications: {
        balanceSubtitle:
          'Je souhaite recevoir une notification dès que mon solde est inférieur à 5€.',
        balanceTitle: 'Alerte solde bas',
        disabled: 'Désactivé',
        email: 'E-mail',
        soonAvailable: 'bientôt disponible',
        enabled: 'Activé',
        mobile: 'Notification mobile',
        newServicesSubtitle: `J'accepte de recevoir des communications à caractère promotionnel ou commercial.`,
        newServicesTitle: 'Astuces et nouveaux services',
        partial: 'Partiellement activé',
        title: 'Mes notifications',
      },
      pageTitle: 'Mon profil',
      section: {
        help: `Besoin d'aide ?`,
        language: 'Langue',
        login: 'Mes identifiants',
        notifications: 'Mes notifications',
        personalData: 'Utilisation des Données Personnelles',
        termsOfService: "Conditions Générales d'Utilisation",
        termsOfSales: 'Conditions Générales de Vente',
        cookiesPolicy: 'Politique des Cookies',
        virtualTicket: 'Ticket dématérialisé',
        legalInfo: 'Informations légales',
        cookiesManagement: 'Gestion des cookies',
      },
      title: 'PROFIL',
      version: 'v. {{platformPrefix}}.{{versionNumber}}',
      virtualTicket: {
        disabled: 'Désactivé',
        enabled: 'Activé',
        switchSubtitle:
          'Faites un geste pour la planète ! Limitez les impressions de tickets en consultant le détail de vos transactions directement depuis Foodi.',
        switchTitle: 'Je passe au ticket dématérialisé',
        title: 'Ticket dématérialisé',
      },
      incompleteProfile: {
        title: 'Votre profil est incomplet',
        subTitle: 'Pour recharger et faire des achats en ligne.',
        completeYourProfile: 'Compléter votre profil',
        addBadge: 'Pour réaliser un achat en ligne, ajoutez votre numéro de badge.',
      },
    },
    helpOnline: {
      title: 'Aide',
      button: 'Besoin d’aide sur votre commande?',
    },
  },
  error: {
    gql: 'Une erreur est survenue, merci de réessayer',
    network: "Vous n'êtes pas connecté à internet",
    refreshToken: 'Une erreur est survenue, merci de réessayer',
  },
  landing: {
    signIn: "Je m'identifie",
    signUp: 'Je crée mon compte Foodi',
  },
  locale: {
    name: 'Français',
  },
  pointOfSale: {
    actions: {
      moreInfo: "PLUS D'INFO",
      seeCard: 'MENU',
    },
    attendance: {
      hide: 'Fermer',
      highAnimation: 'Très animé',
      lowAnimation: 'Peu animé',
      mediumAnimation: 'Animé',
      show: 'Voir la fréquentation',
    },
    list: {
      emptyContent: 'Pas de résultat.',
    },
    menu: {
      closed: "Votre restaurant n'est pas ouvert ce jour-là.",
      dessert: 'Desserts',
      empty: "Le menu n'est pas disponible pour cette journée.",
      meal: 'Plats',
      starter: 'Entrées',
      dairies: 'Produits laitiers',
      accompaniment: "Accompagnements",
      compliments: "Compléments",
      cheese: "Fromages",
      "toTaste": "Gouter",
      warningText: 'Menu susceptible de varier selon les approvisionnements et la fréquentation.',
    },
    schedulesUnavailable: 'Les horaires ne sont pas disponibles',
    seeMore: 'Découvrez plus de restaurants',
    services: 'Services',
    status: {
      closed: 'Fermé',
      closedNow: 'Actuellement fermé',
      opened: 'Ouvert',
      unavailable: 'Horaires indisponibles',
    },
    tomorrow: {
      currentWeek: 'Et demain au menu ?',
      nextWeek: 'Et lundi au menu ?',
    },
    type: {
      brasserie: 'Brasserie',
      cafeteria: 'Cafétéria',
      other: 'Autre',
      self: 'Restaurant',
    },
  },
  signUp: {
    closeHolding: {
      paragraph: `L’application n’est pas encore disponible dans votre site de restauration.
Saisissez votre email pour être prévenu dès son ouverture !`,
      title: 'Votre site de restauration n’est pas encore ouvert dans Foodi',
    },
    confirmation: {
      home: "Retourner à l'accueil",
      paragraph:
        'Vous allez recevoir un email de confirmation, vous avez 72 heures pour confirmer votre inscription.',
      subTitle: 'Vous venez de finaliser la création de votre compte',
      title: "Validation de l'email",
    },
    email: {
      errorEmailExist: 'Un compte Foodi est déjà associé à cette adresse email',
      errorEmailNotValid: "Cette adresse email n'est pas valide",
      errorSyntax: "Cette adresse email n'est pas valide",
      paragraph: "Saisissez l'email qui vous servira d’identifiant pour votre compte.",
      placeholder: 'E-mail',
      title: 'Votre email de connexion',
      titleWithoutEmail: `Les Conditions d’Utilisation`,
      validate: 'Valider',
    },
    emailLead: {
      button: "Revenir à l'accueil",
      paragraph: 'Vous serez alerté dès que votre site de restauration sera ouvert dans Foodi.',
      title: 'A très bientot !',
    },
    emailNotActivated: {
      button: "Me renvoyer l'e-mail",
      paragraph:
        "Pour l'activer, cliquez sur le lien dans l'email que vous avez reçu à l'adresse suivante",
      subTitle: `Votre compte n'a pas été activé`,
      title: "Validation de l'email",
    },
    geolocation: {
      findHolding: 'Rechercher un restaurant',
      paragraph: 'Géolocalisez-vous pour retrouver les restaurants disponibles autour de vous.',
      title: 'Votre restaurant',
    },
    guestExist: {
      paragraph:
        "Voici l'adresse email associée à votre compte Foodi. Elle est partiellement masquée par souci de confidentialité de vos informations.",
      subTitle: 'Un compte foodi existe déjà pour ces identifiants',
      title: 'Vos identifiants',
      validate: "Je m'authentifie",
    },
    headerTitle: 'Je crée mon compte',
    identity: {
      badgeNumberPlaceholder: 'Numéro de badge',
      errorNoGuest:
        'Le nom et numéro de badge saisis ne correspondent pas aux données que nous avons. Vous pouvez contacter notre service client via help@foodi.fr',
      errorNotEnabled: "Votre statut ne permet pas actuellement la création d'un compte",
      lastNamePlaceholder: 'Nom de famille',
      paragraph: 'Retrouvez votre numéro de badge sur vos tickets de caisse.',
      title: 'Vos identifiants',
      validate: 'Valider',
    },
    nearHolding: {
      empty: 'Aucun restaurant ne correspond à vos critères',
      paragraph: 'Sélectionnez le restaurant dans lequel vous vous rendez habituellement.',
      placeholder: 'Nom du restaurant, code postal, ...',
      title: 'Votre restaurant',
    },
    password: {
      tos: {
        end: '',
        link: "Conditions Générales d'Utilisation",
        start: "J'accepte les ",
      },
      errorPasswordComplexity: 'La complexité du mot de passe est insuffisante',
      errorPasswordDifferent: 'Les mots de passe ne correspondent pas',
      newsletter: "J'accepte de recevoir des informations à but promotionnel ou commercial",
      paragraphCriteriaLength: 'au moins 8 caractères',
      paragraphCriteriaLowercase: 'une minuscule',
      paragraphCriteriaNumber: 'un chiffre',
      paragraphCriteriaUppercase: 'une majuscule',
      paragraphWarning: 'Votre mot de passe doit être composé de :',
      passwordConfirmationPlaceholder: 'Vérification de votre mot de passe',
      passwordPlaceholder: 'Mot de passe',
      title: 'Le mot de passe de votre compte',
      validate: 'Valider',
    },
    storyBookTitle: 'Storybook',
    termsOfService: {
      title: 'CGU',
    },
    welcome: {
      paragraph: 'Connectez-vous pour accéder à votre espace.',
      signIn: 'Je me connecte',
      subTitle: 'Votre compte Foodi a bien été activé',
      title: 'Bienvenue sur Foodi !',
    },
  },
  topUp: {
    other: 'autre',
    title: 'Recharger',
    validate: 'Valider',
  },
  transactions: {
    admission: {
      admissionAmount: 'Admission',
      admissionSubsidy: 'Subvention sur admission',
      royaltyAmount: 'Perçu pour compte',
      royaltySubsidy: 'Subvention sur Redevance',
      title: 'Admission',
    },
    cardTitle: 'Mes tickets',
    commoditySubsidy: 'Subvention denrée',
    date: 'Le {{transactionDay}} à {{transactionHour}}',
    details: {
      emptyContent: 'Une erreur est survenue, merci de réessayer.',
    },
    emptyMessage: 'Aucune transaction à afficher',
    errorMessage: "L'historique des transactions ne peut être chargé",
    subTotal: 'Sous-total',
    title: 'Ticket',
    totalTicket: {
      newBalanceAmount: 'Nouveau solde',
      oldBalanceAmount: 'Ancien solde',
      refillAmount: 'Montant rechargé',
      title: 'Total ticket',
      totalAmount: 'Montant prévelé sur badge',
    },
    trayTitle: 'Votre plateau',
  },
  user: {
    emailNotActivated: {
      button: "Me renvoyer l'e-mail",
      paragraph:
        "Pour l'activer, cliquez sur le lien dans l'email que vous avez reçu à l'adresse suivante",
      subTitle: 'Votre compte n`a pas été activé',
      title: "Validation de l'email",
    },
    form: {
      email: 'E-mail',
      firstname: 'Prénom',
      lastname: 'Nom',
      password: 'Mot de passe',
    },
  },
  signUpV2: {
    stepper: {
      accountCreation: 'Création de compte',
      identifiers: 'Identifiants',
      establishment: 'Restaurant',
      done: "C'est fait",
    },
    step1: {
      firstName: 'Prénom',
      lastName: 'Nom',
      connectYou: 'Pour vous connecter',
      email: 'Email de connexion',
      password: 'Créer un mot de passe',
      emailInvalid: 'Cette adresse email est invalide.',
      emailAlreadyExists: 'Un compte Foodi est déjà associé à cette adresse email.',
      passwordRules: 'Le mot de passe doit contenir au moins:',
      passwordRulesCharacters: 'caractères',
      passwordRulesUpperCase: 'majuscule',
      passwordRulesLowerCase: 'minuscule',
      passwordRulesNumbers: 'chiffre',
      receiveCommunications: 'Je souhaite recevoir les communications du Groupe Compass en France.',
      iDeclare: 'Je déclare avoir lu et accepté les',
      cgu: 'Conditions Générales d’Utilisation',
      and: 'et',
      age: 'certifie être agé(e) d’au moins 15 ans',
      continue: 'Continuer',
      require: 'champs obligatoires',
      accessTo: 'Accèdez à notre',
      privacyPolicy: 'Politique de confidentialité',
      ssoInfo:
        "Votre e-mail a été reconnu par nos systèmes. Après l'étape 1, vous allez être redirigé vers un site sécurisé pour identifier votre mot de passe.",
    },
    step2: {
      consultServices: 'Pour consulter les menus et les services.',
      registerWithoutBadge: 'S’inscrire sans badge',
      registerWithBadge: 'S’inscrire avec badge',
      badge: 'Badge',
      refillsAndOrders: 'Pour vos rechargements et commandes en ligne.',
      badgeName: 'Nom associé au badge/matricule',
      badgeNumber: 'Numéro de badge ou de matricule',
      badgeAuthorization:
        '*Dans le cas où vous saisissez  le badge d’un tiers, vous attestez avoir son autorisation.',
      dematerializedTicket:
        'Je choisis le ticket dématérialisé et participe à la préservation de la planète.',
      skipStep2: 'Passer cette étape et s’inscrire',
      toolTipMessage:
        'Retrouvez votre numéro de badge sur vos tickets de caisse.\nLe badge et le nom associé, pourront être ajoutés ou modifiés par la suite.',
    },
    step3: {
      welcome: 'Bienvenue %{firstName}',
      emailSent: 'Un e-mail vous a été envoyé pour activer votre compte.',
      seeYouSoon: 'A très vite !',
      backHome: 'Retour à l’accueil',
    },
  },
};
