import { NavigationInjectedProps, withNavigation } from 'react-navigation';
import { compose, withProps } from 'recompose';
import BookingCardGroup, { IProps } from './BookingCardGroup.component';

interface IWithExtraProps {
  ordersGroupedByPos: IOrderByPos[];
}

export interface IOrderByPos {
  id: string; 
  name: string; 
  withdrawRange: string; 
  count: number;
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  ordersGroupedByPos: ownProps.navigation.getParam('ordersGroupedByPos'),
});

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps)
)(BookingCardGroup);
