import { AnyAction, Reducer } from 'redux';

import { actionTypes, IChangeCategoryConsentPayload } from './actions';
import { CookieCategory, CookieConsent } from '../../services/oneTrust';

export type ICookiesConsent = {
  [key in CookieCategory]: CookieConsent
}

export const initialState: ICookiesConsent = {
  [CookieCategory.STRICTLY_NECESSARY_COOKIES]: CookieConsent.CONSENT_GIVEN,
  [CookieCategory.PERFORMANCE_COOKIES]: CookieConsent.CONSENT_NOT_YET_GATHERED,
  [CookieCategory.FUNCTIONAL_COOKIES]: CookieConsent.CONSENT_NOT_YET_GATHERED,
  [CookieCategory.TARGETING_COOKIES]: CookieConsent.CONSENT_NOT_YET_GATHERED,
  [CookieCategory.SOCIAL_MEDIA_COOKIES]: CookieConsent.CONSENT_NOT_YET_GATHERED,
  [CookieCategory.CAT]: CookieConsent.CONSENT_NOT_YET_GATHERED
};

const cookiesReducer: Reducer<ICookiesConsent> = (
  state: ICookiesConsent = initialState,
  action: AnyAction
): ICookiesConsent => {

  switch (action.type) {
    case actionTypes.SET_CATEGORY_CONSENT:

      const newState = { ...state };
      const payload = action.payload as IChangeCategoryConsentPayload
      newState[payload.category] = payload.consent;

      return newState;

    default:
      return state;
  }
};

export default cookiesReducer;
