import React, { PureComponent, ReactNode } from 'react';
import { Platform, ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import HideWithKeyboard from 'react-native-hide-with-keyboard';
import KeyboardSpacer from 'react-native-keyboard-spacer';

export interface IProps {
  children: ReactNode[] | ReactNode;
}

class BottomForm extends PureComponent<IProps> {
  public render(): ReactNode {
    const header = this.getHeader();
    const form = this.getForm();

    return (
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        keyboardShouldPersistTaps="handled"
        alwaysBounceVertical={false}
        scrollEnabled={false}
      >
        <HideWithKeyboard>{header}</HideWithKeyboard>
        <View style={styles.fillContainer} />
        {form}
        {Platform.OS === 'ios' && <KeyboardSpacer />}
      </ScrollView>
    );
  }

  private getForm = (): null | ReactNode | ReactNode[] => {
    const { children } = this.props;

    if (!Array.isArray(children)) {
      return children;
    }

    return children[children.length - 1];
  };

  private getHeader = (): null | ReactNode | ReactNode[] => {
    const { children } = this.props;

    if (!Array.isArray(children)) {
      return null;
    }

    return children.slice(0, -1);
  };
}

interface IStyle {
  container: ViewStyle;
  contentContainer: ViewStyle;
  fillContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    flex: 1,
    overflow: 'visible',
  },
  contentContainer: {
    flex: 1,
  },
  fillContainer: {
    flex: 1,
  },
});

export default BottomForm;
