import { Dimensions, ImageStyle, Platform, TextStyle, ViewStyle } from 'react-native';

export type ViewMode = 'DESKTOP' | 'MOBILE' | 'TABLET';

export const VIEW_MODE: { [key in ViewMode]: ViewMode } = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
};

export const MIN_WIDTH: { [key in ViewMode]: number } = {
  DESKTOP: 1024,
  MOBILE: 0,
  TABLET: 768,
};

export const getViewMode = (): ViewMode => {
  const width = Dimensions.get('window').width;

  if (width < MIN_WIDTH.TABLET) {
    return VIEW_MODE.MOBILE;
  }

  if (width < MIN_WIDTH.DESKTOP) {
    return VIEW_MODE.TABLET;
  }

  return VIEW_MODE.DESKTOP;
};

export const isDesktop = (): boolean => getViewMode() === VIEW_MODE.DESKTOP;
export const isMobile = (): boolean => getViewMode() === VIEW_MODE.MOBILE;
export const isTablet = (): boolean => getViewMode() === VIEW_MODE.TABLET;
export const isWeb = (): boolean => 'web' === Platform.OS;

type INativeStyle = ViewStyle | TextStyle | ImageStyle;
export interface IMedia<T extends INativeStyle> {
  _desktop?: T;
  _mobile?: T;
  _tablet?: T;
}

export const media = <T extends INativeStyle>(style: IMedia<T>): T | undefined => {
  if (isMobile()) {
    return style._mobile;
  }

  if (isTablet()) {
    return style._tablet;
  }

  return style._desktop;
};
