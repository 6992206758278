import { FormikProps } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import Button from '../../Button';
import FormFormik from '../../FormFormik';
import Paragraph from '../../Paragraph';
import TextInputFormik from '../../TextInputFormik';

import { PASSWORD_MIN_LENGTH } from '../../../services/form/validationSchema';
import {
  hasLowerCase,
  hasMinimalLength,
  hasNumber,
  hasUpperCase,
} from '../../../services/passwordCriteria';

export interface IValues {
  password: string;
  passwordConfirmation: string;
}

export type IProps = FormikProps<IValues> & NavigationInjectedProps;

class SignUpPasswordFormikForm extends PureComponent<IProps, {}> {
  public render(): ReactNode {
    const { errors, isSubmitting, submitForm, values } = this.props;

    const criteriaLengthStyle = hasMinimalLength(values.password, PASSWORD_MIN_LENGTH)
      ? styles.blueText
      : styles.redText;

    const paragraphCriteriaUppercaseStyle = hasUpperCase(values.password)
      ? styles.blueText
      : styles.redText;

    const paragraphCriteriaLowercaseStyle = hasLowerCase(values.password)
      ? styles.blueText
      : styles.redText;

    const paragraphNumberStyle = hasNumber(values.password) ? styles.blueText : styles.redText;

    return (
      <FormFormik testID="SignUpPassword">
        <Paragraph text={I18n.t('signUp.password.paragraphWarning')} />
        <View style={styles.criteriaContainer}>
          <View style={styles.criteriaLongSubContainer}>
            <Text style={criteriaLengthStyle}>
              {`\u2022 ${I18n.t('signUp.password.paragraphCriteriaLength')}`}
            </Text>
            <Text style={paragraphCriteriaUppercaseStyle}>
              {`\u2022 ${I18n.t('signUp.password.paragraphCriteriaUppercase')}`}
            </Text>
          </View>
          <View style={styles.criteriaSubContainer}>
            <Text style={paragraphCriteriaLowercaseStyle}>
              {`\u2022 ${I18n.t('signUp.password.paragraphCriteriaLowercase')}`}
            </Text>
            <Text style={paragraphNumberStyle}>
              {`\u2022 ${I18n.t('signUp.password.paragraphCriteriaNumber')}`}
            </Text>
          </View>
        </View>
        <TextInputFormik
          hasError={!!errors.passwordConfirmation}
          placeholder={I18n.t('signUp.password.passwordPlaceholder')}
          returnKeyType="next"
          type="password"
          testID="password"
          name="password"
          error=""
        />
        <TextInputFormik
          placeholder={I18n.t('signUp.password.passwordConfirmationPlaceholder')}
          returnKeyType="done"
          type="password"
          name="passwordConfirmation"
          testID="passwordConfirmation"
          error=""
        />
        <Button
          disabled={!this.canSubmit(values)}
          isLoading={isSubmitting}
          text={I18n.t('signUp.password.validate')}
          testID="validatePassword"
          onPress={submitForm}
        />
      </FormFormik>
    );
  }

  private canSubmit = (values: IValues): boolean => {
    return !!values.password && !!values.passwordConfirmation;
  };
}

export interface IStyle {
  blueText: TextStyle;
  criteriaContainer: ViewStyle;
  criteriaLongSubContainer: ViewStyle;
  criteriaSubContainer: ViewStyle;
  redText: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  blueText: {
    color: theme.colors.blue,
    ...theme.fonts.password,
  },
  criteriaContainer: {
    flexDirection: 'row',
    marginBottom: theme.margins.unit * 2,
  },
  criteriaLongSubContainer: {
    flex: 8,
    flexDirection: 'column',
    paddingHorizontal: theme.margins.unit,
  },
  criteriaSubContainer: {
    flex: 6,
    flexDirection: 'column',
    paddingHorizontal: theme.margins.unit,
  },
  redText: {
    color: theme.colors.red,
    ...theme.fonts.password,
  },
});

export default SignUpPasswordFormikForm;
