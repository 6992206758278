import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';

import {
  formatAmountWithCurrency,
  formatAmountWithCurrencyInBetween,
} from '../../services/amountFormatter';
import theme from '../../theme';
import { IAmount } from '../../types/common';

export interface IProps {
  amount?: IAmount | null;
  style?: StyleProp<TextStyle>;
  textAmount?: string;
  alternativeFormat?: boolean;
  textBeforeAmount?: string;
}

class Amount extends PureComponent<IProps> {
  public formatedAmount({
    amount,
    textAmount,
    alternativeFormat,
  }: {
    amount?: IAmount | null;
    textAmount?: string;
    alternativeFormat?: boolean;
  }) {
    if (amount !== undefined && amount !== null) {
      return alternativeFormat
        ? formatAmountWithCurrencyInBetween(amount)
        : formatAmountWithCurrency(amount);
    }
    return textAmount;
  }

  public render(): ReactNode {
    const { amount, style, textAmount, textBeforeAmount } = this.props;
    if (!amount && !textAmount) {
      return null;
    }
    return <Text style={[styles.amount, style]}>{textBeforeAmount? `${textBeforeAmount} ${this.formatedAmount(this.props)}` : this.formatedAmount(this.props)}</Text>;
  }
}

export interface IStyle {
  amount: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.textBlack,
  },
});

export default Amount;
