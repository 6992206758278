import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

const customizer = (objectValue: object, sourceValue: object): object | undefined => {
  if (isArray(objectValue)) {
    return objectValue.concat(sourceValue);
  }

  return undefined;
};

export const deepMerge = <T extends object>(objectValue: T, sourceValue: T): T =>
  mergeWith(cloneDeep(objectValue), sourceValue, customizer);

export const mergeObjectFromArray = (array: object[]): object => {
  let object = {};

  if (!array) {
    return object;
  }

  array.forEach((element: object) => (object = { ...object, ...element }));

  return object;
};
