import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import theme from '../../theme';
import Touchable from '../Touchable';

export interface IProps {
  isOpen: boolean;
  onPress: () => void;
}

export interface IStyle {
  flipStyle: ViewStyle;
  triangleStyle: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  flipStyle: {
    paddingBottom: 0,
    paddingTop: 0,
    transform: [{ rotate: '180deg' }],
  },
  triangleStyle: {
    backgroundColor: 'transparent',
    borderBottomColor: theme.colors.gray,
    borderBottomWidth: 5,
    borderLeftColor: 'transparent',
    borderLeftWidth: 4,
    borderRightColor: 'transparent',
    borderRightWidth: 4,
    borderStyle: 'solid',
    height: 0,
    margin: 'auto',
    paddingBottom: 0,
    paddingTop: 2,
    width: 0,
  },
});

const OpenCloseTriangle = ({ isOpen }: { isOpen: boolean }): ReactElement<{}> =>
  isOpen ? (
    <View style={styles.triangleStyle} />
  ) : (
    <View style={[styles.triangleStyle, styles.flipStyle]} />
  );

class OpenCloseButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onPress, isOpen } = this.props;
    const title = isOpen
      ? I18n.t('pointOfSale.attendance.hide')
      : I18n.t('pointOfSale.attendance.show');

    return (
      <Touchable
        style={{
          alignItems: 'center',
          backgroundColor: theme.colors.backgroundGray,
          borderRadius: 12.5,
          height: 25,
          justifyContent: 'center',
          paddingHorizontal: 10,
        }}
        useOpacity
        onPress={onPress}
      >
        <View>
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <View style={{ marginRight: 5 }}>
              <Text style={{ ...theme.fonts.openCloseButton, color: theme.colors.gray }}>
                {title}
              </Text>
            </View>
            <OpenCloseTriangle isOpen={isOpen} />
          </View>
        </View>
      </Touchable>
    );
  }
}

export default OpenCloseButton;
