import { getScreenParamsWeb } from './../screenNameMapper';
import { IWebTrackNavigationConfig } from './tracker';

export interface IUserProperties {
  app_env: string | null;
  app_version: string | null;
  holding_brand: string | null;
  holding_cp: string | null;
  holding_id: string | null;
  holding_name: string | null;
  user_id_foodi: string;
  user_language: string | null;
}

class Analytics {
  public static setUserId(userId: string): void {
    // @ts-ignore
    window.dataLayer.push({ user: { user_id_foodi: userId } });
  }

  public static setUserProperties(userProperties: IUserProperties): void {
    // @ts-ignore
    window.dataLayer.push({ user: userProperties });
  }

  public static async trackNavigation(trackNavigation: IWebTrackNavigationConfig): Promise<void> {
    // @ts-ignore
    const params = await getScreenParamsWeb(trackNavigation);

    if (params) {
      // @ts-ignore
      window.dataLayer.push({ event: 'updatePageview', page: params });
    }
  }
}

export default Analytics;
