import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { FetchResult } from 'react-apollo';
import { StyleSheet, Text, TextStyle } from 'react-native';

import I18n from '../../lib/i18n';
import { setAttributesToUa } from '../../lib/airship';
import theme from '../../theme';
import { IUpdateVirtualTicketResult } from '../../types/updateVirtualTicket';
import LoadingView from '../LoadingView';
import SwitchItem from '../SwitchItem';

export interface IState {
  isVirtualTicketSwitchActive: boolean;
  dataFetched: boolean;
}

export interface IProps {
  isLoading?: boolean;
  updateVirtualTicket: (value: boolean) => Promise<FetchResult>;
  virtualTicket: boolean;
  testID?: string;
  idGuest: boolean;
}

class VirtualTicketSwitchList extends PureComponent<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState): IState {
    if (!state.dataFetched && !props.isLoading) {
      return { isVirtualTicketSwitchActive: props.virtualTicket, dataFetched: true };
    }

    return state;
  }
  public state: IState = {
    isVirtualTicketSwitchActive: false,
    dataFetched: false,
  };

  private onValueChange: (value: boolean) => Promise<void> = debounce(
    async (value: boolean): Promise<void> => {
      try {
        const result: FetchResult<IUpdateVirtualTicketResult> = await this.props.updateVirtualTicket(
          value
        );
        setAttributesToUa();
        const updatedResult = get(result, 'data.updateUser.virtualTicket');

        if (updatedResult) {
          this.setState({
            isVirtualTicketSwitchActive: updatedResult,
          });
        }
      } catch (error) {
        this.setState({
          isVirtualTicketSwitchActive: this.props.virtualTicket,
        });
      }
    },
    500
  );

  public render(): ReactNode {
    return (
      <LoadingView isLoading={this.props.isLoading}>
        <SwitchItem
          title={I18n.t('dashboard.profile.virtualTicket.switchTitle')}
          disabled={!this.props.idGuest}
          switchValue={this.state.isVirtualTicketSwitchActive}
          onValueChange={(value: boolean): void => {
            this.setState({ isVirtualTicketSwitchActive: value });
            this.onValueChange(value);
          }}
        />
        <Text style={styles.subtitle}>
          {I18n.t('dashboard.profile.virtualTicket.switchSubtitle')}
        </Text>
      </LoadingView>
    );
  }
}

export interface IStyle {
  subtitle: TextStyle;
}

const styles: IStyle = StyleSheet.create<IStyle>({
  subtitle: {
    ...theme.fonts.cardText,
    color: theme.colors.textGray,
  },
});

export default VirtualTicketSwitchList;
