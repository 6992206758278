import capitalize from 'lodash/capitalize';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  firstName?: string;
  holdingName?: string;
  lastName?: string;
}

const NUMBER_OF_LINES_USER_NAME = 2;
const NUMBER_OF_LINES_USER_HOLDING_NAME = 1;

class GuestInfo extends PureComponent<IProps> {
  public render(): ReactNode {
    const { firstName, holdingName, lastName } = this.props;

    return (
      <View style={styles.container}>
        <Text numberOfLines={NUMBER_OF_LINES_USER_HOLDING_NAME} style={styles.textHoldingName}>
          {holdingName}
        </Text>
        <Text numberOfLines={NUMBER_OF_LINES_USER_NAME} style={styles.textUserName}>
          {capitalize(firstName)} {lastName && lastName.toUpperCase()}
        </Text>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  textHoldingName: TextStyle;
  textUserName: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    paddingHorizontal: theme.margins.unit * 2,
    paddingVertical: theme.margins.cardUnit * 2,
  },
  textHoldingName: {
    ...theme.fonts.profileSection,
    color: theme.colors.textBlack,
  },
  textUserName: {
    ...theme.fonts.profileTitle,
    color: theme.colors.textBlack,
  },
});

export default GuestInfo;
