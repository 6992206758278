import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import theme from '../../theme';
import Label from '../Label';
import Touchable from '../Touchable';

export interface IProps {
  isChecked: boolean;
  onChange: () => void;
  testID?: string;
  text?: string;
}

class CheckboxInput extends PureComponent<IProps> {
  public render(): ReactNode {
    const { children, isChecked, onChange, text, testID } = this.props;

    return (
      <View style={styles.container}>
        <Touchable testID={testID} useOpacity onPress={onChange}>
          <View style={styles.checkbox}>
            {isChecked && <Icon name="check" color={theme.colors.success} size={CHECK_SIZE} />}
          </View>
        </Touchable>
        <View style={styles.textContainer}>{text ? <Label text={text} /> : children}</View>
      </View>
    );
  }
}

export interface IStyle {
  checkbox: ViewStyle;
  container: ViewStyle;
  textContainer: ViewStyle;
}

const CHECKBOX_WIDTH = 17;
const CHECKBOX_HEIGHT = 17;
const CHECKBOX_BORDER_WIDTH = 1;
const CHECKBOX_BORDER_RADIUS = 2;
const CHECK_SIZE = 10;

const styles = StyleSheet.create<IStyle>({
  checkbox: {
    alignItems: 'center',
    borderColor: theme.colors.border,
    borderRadius: CHECKBOX_BORDER_RADIUS,
    borderWidth: CHECKBOX_BORDER_WIDTH,
    height: CHECKBOX_HEIGHT,
    justifyContent: 'center',
    marginRight: theme.margins.unit,
    width: CHECKBOX_WIDTH,
  },
  container: {
    flexDirection: 'row',
    marginBottom: theme.margins.unit,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
  },
});

export default CheckboxInput;
