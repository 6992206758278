import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewPropTypes, ViewStyle } from 'react-native';
import Amount from '../../../components/Amount';
import Tag from '../../../components/Tag';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import { IAmount } from '../../../types/common';
import { ArticleBaking } from '../../../types/tableService/globalTypes';

export interface IProps {
  amount: IAmount;
  label: string;  
  containerAmount?: IAmount | null;
  containerLabel?: string | null;
  baking?: ArticleBaking | null;
}

class OrderSummaryItem extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <View style={styles.container}>
          <Text numberOfLines={1} style={styles.label}>
            {this.props.label}
          </Text>
          <Amount amount={this.props.amount} style={styles.amount} />

        </View>
        {this.props.baking && (
          <Tag
            label={I18n.t(`dashboard.eat.tableService.ArticleBaking.${this.props.baking}`)}
            tagStyle={{ marginLeft: 0, lineHeight: 12, fontSize: 10 }}
            style={styles.bakingTag}
          />
        )}
        {this.props.containerLabel && (
          <View style={[styles.container , {marginTop: 0}]}>
            <Text numberOfLines={1} style={styles.label}>
              {I18n.t('dashboard.eat.clickAndCollect.orderDetails.container')} {this.props.containerLabel}
            </Text>
            <Amount amount={this.props.containerAmount} style={styles.amount} />
          </View>
        )}
      </View>
    );
  }
}

export interface IStyle {
  amount: TextStyle;
  container: ViewStyle;
  label: TextStyle;
}

const styles = StyleSheet.create({
  bakingTag: {
    marginLeft: 16,
    marginTop: 5,
    height: 17,
    borderRadius: 4,
    backgroundColor: theme.colors.backgroundGray,
  },
  amount: {
    ...theme.fonts.mediumAmount,
    color: theme.colors.deprecatedBlack,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.margins.cardUnit,
  },
  label: {
    ...theme.fonts.semiBoldText,
    flex: 1,
    paddingRight: 10,
  },
});

export default OrderSummaryItem;
