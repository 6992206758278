import React, { useRef, useState } from 'react';
import {
  StyleSheet,
  View,
  ViewStyle,
  FlatList,
  Dimensions,
  TouchableOpacity,
  TextStyle,
} from 'react-native';

import WeeklyTab from './WeeklyTab';
import theme from '../../theme';

type DaysWithState = {
  day: string;
  isActive: boolean;
};

export interface IProps {
  activeDayIndex: number;
  days?: string[];
  onDayChange: (index: number) => void;
  clickAndCollectStyle?: boolean;
  numberOfDaysDisplayed?: number;
  daysWithState?: DaysWithState[];
}

const WeeklyTabBar: React.FC<IProps> = ({
  activeDayIndex,
  days,
  onDayChange,
  clickAndCollectStyle,
  numberOfDaysDisplayed = 5,
  daysWithState = [],
}) => {
  const flatListRef = useRef(null);
  const daysToUse =
    daysWithState.length > 0
      ? daysWithState
      : days.map(eachDay => ({ day: eachDay, isActive: true }));
  
  const onViewRef = useRef(({ viewableItems }) => {
    if (viewableItems[0]?.index > 0) {
      setShowPreviousButton(true);
    } else {
      setShowPreviousButton(false);
    }
  
    if (viewableItems[viewableItems.length - 1]?.index === daysToUse.length - 1) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }
  });

  const [visibleDayIndex, setVisibleDayIndex] = useState(0);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const { ArrowLeftGreenSVG, ArrowRightGreenSVG } = theme.images;

  const handleListButtons = (direction: 'left' | 'right') => {
    const buttons = {
      left: {
        onPress: () => {
          if (flatListRef.current) {
            const nextVisibleDayIndex = visibleDayIndex - numberOfDaysDisplayed < 0 ? 0 : visibleDayIndex - numberOfDaysDisplayed;
            flatListRef.current.scrollToIndex({
              index: nextVisibleDayIndex < 0 ? 0 : nextVisibleDayIndex,
            });
            setVisibleDayIndex(nextVisibleDayIndex);
          }
        },
        icon: <ArrowLeftGreenSVG />,
      },
      right: {
        onPress: () => {
          if (flatListRef.current) {
            const nextVisibleDayIndex =
              visibleDayIndex + numberOfDaysDisplayed > daysToUse.length - 1 ? daysToUse.length - 1 : visibleDayIndex + numberOfDaysDisplayed;
            flatListRef.current.scrollToIndex({
              index: nextVisibleDayIndex < daysToUse.length && nextVisibleDayIndex,
            });
            setVisibleDayIndex(nextVisibleDayIndex);
          }
        },
        icon: <ArrowRightGreenSVG />,
      },
    };
    return (
      <TouchableOpacity style={styles.arrowIconContainer} onPress={buttons[direction].onPress}>
        {buttons[direction].icon}
      </TouchableOpacity>
    );
  };

  const handleRenderItem = ({ item, index }: { item: DaysWithState; index: number }) => {
    const tabStyle = { width: Dimensions.get('window').width / numberOfDaysDisplayed };

    const clickAndCollectStyles = clickAndCollectStyle
      ? {
          noDotDayOfWeek: true,
          dayOfWeekTextStyle: StyleSheet.flatten([
            styles.dayText,
            { fontWeight: '600', fontSize: 14 },
            !item.isActive && styles.disabledText,
          ]),
          dayOfMonthTextStyle: StyleSheet.flatten([
            styles.dayText,
            { fontWeight: 'bold', fontSize: 17 },
            !item.isActive && styles.disabledText,
          ]),
          activeDayTextStyle: StyleSheet.flatten([
            styles.activeDayText,
            !item.isActive && styles.disabledText,
          ]),
        }
      : null;

    return (
      <WeeklyTab
        day={item.day}
        isActive={activeDayIndex === index}
        onPress={(): void => onDayChange(index)}
        style={tabStyle}
        {...clickAndCollectStyles}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View style={[styles.arrowIconContainer, styles.buttonLeft]}>
        {showPreviousButton && handleListButtons('left')}
      </View>
      <FlatList
        ref={flatListRef}
        keyExtractor={(item: DaysWithState) => item.day}
        style={daysToUse.length > numberOfDaysDisplayed && styles.listWithButtons}
        data={daysToUse}
        renderItem={handleRenderItem}
        horizontal
        scrollEnabled={daysToUse.length > 5}
        showsHorizontalScrollIndicator={false}
        onViewableItemsChanged={onViewRef.current}
      />
      <View style={[styles.arrowIconContainer, styles.buttonRight]}>
        {daysToUse.length > numberOfDaysDisplayed && showNextButton && handleListButtons('right')}
      </View>
    </View>
  );
};

interface IStyle {
  container: ViewStyle;
  arrowIconContainer: ViewStyle;
  dayText: TextStyle;
  activeDayText: TextStyle;
  disabledText: TextStyle;
  buttonLeft: ViewStyle;
  buttonRight: ViewStyle;
  listWithButtons: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowIconContainer: {
    position: 'absolute',
    zIndex: 2,
    width: 20,
    margin: 0,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dayText: {
    color: theme.colors.textGray,
    fontFamily: 'Open Sans',
  },
  activeDayText: {
    color: theme.colors.greenLighter,
    fontWeight: '800',
  },
  disabledText: {
    color: theme.colors.grayDisabled,
  },
  buttonLeft: {
    left: 0,
  },
  buttonRight: {
    right: 0,
  },
  listWithButtons: {
    width: '97%',
  },
});

export default WeeklyTabBar;
