import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import I18n from '../../lib/i18n';
import Toaster from '../../services/toaster';

import SignIn, { IProps } from './SignIn.component.web';

interface IWithOnErrorProps {
  onError: () => void;
}

const withOnErrorProps = (ownProps: IConnectedProps): IWithOnErrorProps => ({
  onError: (): void => {
    ownProps.navigation.navigate('landing');
    Toaster.showError(I18n.t('auth0.error'));
  },
});

export type IConnectedProps = NavigationInjectedProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withOnErrorProps)
)(SignIn);
