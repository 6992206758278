import React, { PureComponent, ReactNode, RefObject } from 'react';
import { ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import theme from '../../theme';
import { getOrdersFragment_edges_node_Order as IOrder } from '../../types/clickandcollect/getOrdersFragment';
import { OfferTemplateWithdrawalType, OrderState } from '../../types/clickandcollect/globalTypes'

import ScrollContext, { IScrollContext } from '../../components/ScrollContext';
import { isWeb } from '../../lib/responsive';

import OrdersListBody from './OrdersListBody';

const CLOSE_LIST_TIMEOUT_WEB = 500;
const CLOSE_LIST_TIMEOUT_APP = 100;

export interface IProps extends NavigationInjectedProps {
  orders: IOrder[];
  testID?: string;
}

export interface IState {
  isListOpened: boolean;
}

class OrdersList extends PureComponent<IProps> {
  public state: IState = {
    isListOpened: false,
  };

  private scrollViewRef?: RefObject<ScrollView>;

  public render(): ReactNode {
    const { orders, testID } = this.props;
    return (
      <ScrollContext.Consumer>
        {(context: IScrollContext): ReactNode => {
          this.scrollViewRef = context.scrollViewRef;

          return (
            <View style={styles.container}>
              <OrdersListBody
                handleOnTogglePress={this.handleOnTogglePress}
                isOpen={this.state.isListOpened}
                onOrderPress={this.goToOrder}
                orders={orders}
                testID={testID}
              />
            </View>
          );
        }}
      </ScrollContext.Consumer>
    );
  }

  private getCloseListTimeout = (): number =>
    isWeb() ? CLOSE_LIST_TIMEOUT_WEB : CLOSE_LIST_TIMEOUT_APP;

  private isReadyAndConnectedLocker = (order: IOrder) =>
    order.state === OrderState.READY &&
    order?.offer?.offerTemplate_v2?.withdrawalType ===
      OfferTemplateWithdrawalType.CONNECTED_LOCKERS;

  private goToOrder = (order: IOrder): (() => void) => (): void => {
    this.props.navigation.navigate('order', { orderId: order.id });
    if (this.isReadyAndConnectedLocker(order)) {
      this.props.navigation.navigate('orderQRCode', { idOrder: order.id });
    }
  };

  private handleOnTogglePress = (isListOpened: boolean): void => {
    if (!this.state.isListOpened) {
      this.setState({ isListOpened: !isListOpened });
    } else {
      const scrollViewNodeWeb = this.scrollViewRef ? this.scrollViewRef.current : null;
      if (scrollViewNodeWeb) {
        scrollViewNodeWeb.scrollTo({ x: 0, y: 0, animated: true });
      }
      setTimeout(
        (): void => this.setState({ isListOpened: !isListOpened }),
        this.getCloseListTimeout()
      );
    }
  };
}

export interface IStyle {
  container: ViewStyle;
  itemContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    backgroundColor: theme.colors.backgroundGray,
  },
  itemContainer: {
    marginBottom: theme.margins.cardUnit * 2,
  },
});

export default OrdersList;
