/* eslint-disable quotes */
export default {
  appName: 'Foodi',
  auth0: {
    error: 'An error has occurred. Please try again.',
  },
  balance: {
    cardTitle: 'My balance',
    cardTitleExternal: 'Balance | Refill',
    dateTime: '{{dayOfWeek}}, {{month}} {{dayOfMonth}} at {{hour}}',
    emptyMessage: 'Balance unavailable',
  },
  codepush: {
    title: 'Please wait',
  },
  dashboard: {
    balance: {
      title: 'BALANCE',
      topUp: {
        other: 'other',
        format: 'Please enter an amount with the following format: 12.30',
        minamount: 'You cannot pay less than {{minAmount}}',
        maxamount: 'You cannot pay more than {{maxAmount}} each time',
        callbackModal: {
          defaultTitle:
            'An error occurred. Please check in 10 minutes if your balance was updated, if not proceed again.',
          networkErrorTitle:
            'A network error occurred. Please check in 10 minutes if your balance was updated, if not proceed again.',
          delayTitle:
            'Your payment was received. An additional delay will occur for your balance to be updated. We apologize for the inconvenience',
          potentiaDelaylTitle:
            'Your payment was received. An additional delay may occur for your balance to be updated.',
          failTitle:
            'Sorry, your top-up was interrupted or could not be processed. Please try again.',
          successTitle:
            'Top-up successful ! Your balance has been updated, but please note it can take up to 10 minutes to appear in Foodi.',
          goBack: 'Return',
          validate: 'Confirm',
        },
        payment: {
          title: 'Card details',
        },
        successModal: {
          title: 'Your balance can take up to 10 minutes to update.',
          validate: 'Confirm',
        },
      },
      receipt: {
        title: 'Duplicate',
        error:
          'The receipt is not available, we apologize for the inconvenience caused. Our teams are mobilized to resolve this anomaly as quickly as possible.',
      },
    },
    cateringBusiness: {
      title: 'CATERING BUSINESS',
    },
    eat: {
      orderState: {
        clickAndCollect: {
          Cart: 'Your order has not been placed yet.',
          Accepted: 'Your order has been accepted.\n\nOur teams are preparing it!',
          Preparing: 'Your order has been accepted.\n\nOur teams are preparing it!',
          Ready:
            'Your order is ready!\n\nYou can collect it at the place and at the times indicated.',
          Completed: 'You have withdrawn your order. \n \nEnjoy your meal!',
          Cancelled:
            'You have canceled your order.\n\nThe full amount of your order will be refunded to you.',
          Refused: `We are sorry, we were unable to accept your order.\n\nThe full amount of your order will be refunded to you.`,
          Abandoned:
            'We are sorry, you did not come to collect your order.\n\nThe next time, in case of unavailability, remember to cancel your order within the time limit.',
        },
        tableService: {
          Cart: 'Your order has not been placed yet.',
          EmployeeCart: 'The order of the guest has not been finalized',
          Accepted: 'Your order has been accepted.\n\nLet yourself be served and enjoy your meal!',
          Completed: `Your meal is finished or about to be.\n\nThank you for using our service!`,
          Cancelled:
            'You have canceled your order.\n\nThe full amount of your order will be refunded to you.',
          Refused: `We are sorry, we were unable to accept your order.\n\nThe full amount of your order will be refunded to you.`,
          Processed: '',
        },
        seatClickAndServe: {
          Ready: 'Your order is ready!',
        },
      },
      error: {
        expired: 'Your cart has expired',
        quotation: 'Your order amount could not be calculated',
        validation: {
          title: 'There is a little problem!',
          min: {
            one: 'Please select at least one article among the following families:',
            other: 'Please select at least {{count}} article(s) among the following families:',
          },
          max: {
            one: 'Please only select one article among the following families:',
            other: 'Please only select {{count}} article(s) among the following families:',
          },
        },
        temporaryCode: {
          invalid: 'Invalid code. Please type it again.',
          expired: 'Code is not valid anymore.',
          offer: {
            invalid: 'The offer is not currently open.',
          },
          offerTemplate: {
            invalid: 'You can not order.',
          },
        },
      },
      bookingService: {
        orderDetails: {
          cancelModalTitle: 'Do you want to cancel your booking ?',
          cancelModalSubtitle: 'You can always come back and book !',
        },
        full: 'Full',
        reserve: 'Book',
        modal: {
          title: 'Booking for :',
          subtitle: 'Choose your schedule',
          cancelReservation: 'Cancel',
          changeReservation: 'Change',
          reservationMessageTitle: 'Thank you for your booking.',
          reservationMessageBody: 'We look forward to seeing you !',
          fullyBookedTitle: "Ouch, we're full.",
          fullyBookedDescription:
            'Sorry for the inconvenience caused.\nYou can still come to the restaurant at the end of the service.',
          inactiveOfferTitle: 'Booking unavailable',
          inactiveOfferDescription:
            'We are sorry but it is not possible to book at this time. Come back later!',
          closedOfferTitle: 'Booking are no longer possible today.',
          closedOfferDescription: 'However, you can still book for the following days',
          bookedOtherPosTitle: 'It looks like you already have a booking elsewhere',
          bookedOtherPosDescription: 'Click here to view your booking :',
          bookedOtherPosButton: 'See my booking',
        },
        orderButton: 'Confirm my booking',
        slotChoice: {
          slotButton: 'Select a schedule',
          remainingSeatsSingular: 'remaining seat',
          remainingSeatsPlural: 'remaining seats',
          noRemainingSeats: 'No remaining seats',
        },
        slotFullErrorTitle: 'Sorry, it looks like this time slot is already full...',
        slotFullErrorDescription: 'Please select a new time slot among those available',
        goBack: 'Go back',
      },
      bookingOrderCard: {
        reservations: 'Bookings',
        cardTitle: 'Choose your restaurant',
        title: 'My weekly bookings',
        today: 'today',
        tomorrow: 'No bookings ',
      },
      tableService: {
        table: {
          title: 'Your table',
          caption: 'Please type your table number.',
          error: 'Invalid table number',
          edit: 'Edit my table number',
          valid: 'Next',
        },
        bakingModal: {
          title: 'Select your baking',
        },
        ArticleBaking: {
          ROSE: 'Rosé',
          RAW: 'Raw',
          RARE: 'Rare',
          MEDIUMRARE: 'Medium rare',
          MEDIUM: 'Medium',
          WELLDONE: 'Well done',
          null: '',
        },
        temporaryCode: {
          title: 'Place an order',
          subTitle: 'Enter the code given by your waiter.',
          or: 'or',
          foodi: 'I already have a Foodi account',
        },
        title: 'Product choice',
        validate: 'Validate',
      },
      clickAndCollect: {
        articles: {
          type: {
            beverage: 'Beverages',
            dessert: 'Desserts',
            dish: 'Main Courses',
            salad: 'Salads',
            sandwich: 'Sandwiches',
            snacking: 'Snacks',
            soup: 'Soups',
            starter: 'Starters',
            bread: 'Breads',
            dairy: 'Dairy products',
            fruit: 'Fruits',
            miscellaneous: 'Miscellaneous',
            pastry: 'Pastries',
            side_dish: 'Side dishes',
            unsorted: 'Others',
          },
          portion: 'added',
        },
        bundle: {
          title: {
            one: 'Select your',
            other: 'Select other',
          },
        },
        cartButton: 'Select a product',
        goBack: 'Return',
        noticeText:
          'As part of this offer, please select an item from the following categories: Dishes, Salads or Sandwiches.',
        noStock: {
          title: 'Sorry, there are no more!',
          subtitle: `Ouch, it looks like we're running out of {{products}}.\n\nPlease select another product.`,
          button: 'Return',
        },
        cartSummary: {
          admission: 'Admission',
          admissionWarning:
            'Warning, the total indicated does not take into account the admission, nor the subsidies of your employer',
          balance: 'Your balance',
          button: 'Confirm and pay',
          cancelButton: 'Modify',
          confirmButton: 'Order',
          loading: 'Loading...',
          dateTime: '{{dayOfWeek}} {{dayOfMonth}}/{{month}}/{{year}}',
          dateTimeRange:
            '{{timeStartHours}}h{{timeStartMinutes}} - {{timeEndHours}}h{{timeEndMinutes}}',
          deduction: 'Deduction half-board package / subsidy',
          items: 'Items',
          receptionDate: 'date of collection',
          receptionTime: 'Time of collection',
          pickupPoint: 'Pickup Point',
          receptionPlace: 'place of collection',
          requireTopUp: 'Insufficient balance',
          refillLater: 'Consider refilling.',
          subsidies: 'Subsidies',
          fee: 'Property fee',
          title: 'Summary',
          container: 'Container',
          tooltipMessage:
            'Depending on the restaurant, the means of payment can vary between: paper or electronic restaurant voucher, check, cash or badge.',
          topUpButton: 'Top Up',
          payButton: 'Pay',
          payOnSiteInfo:
            'The total amount of your cart is given as an indication. It represents the maximum cost for your order. The amount of admission may be reduced depending on the terms of your restaurant’s operating contract. Also, if you pay by badge, you will be able to benefit from a subsidy granted by your employer and the amount of the “Collected on account” will be deducted.',
          payOnSiteInfoIsSchool:
            'Your deduction half-board package or subsidy will be calculated and deducted at the time of retrieving your order.',
          edenredInfo: 'Subsidies not applicable',
          payWhenPickUpOrder: 'To be paid when picking-up your order',
          totalAmount: 'Total',
          reloadQuotation: 'Calculate the amount to pay',
          termsOfSaleValidation0: 'By placing an order, I acknowledge having read and accepted the',
          termsOfSaleValidation1: 'general conditions of sale.',
          selectPayment: 'Select payment method',
          seeMore: 'More information',
          addBadge: 'Add your badge',
          otherCards: 'See more',
          warningMessage:
            'Please note, choosing a type of payment other than the badge will not include the grant.',
          cardBalance: 'Balance',
          dailyBalance: 'Day balance',
          cardType: {
            BADGE: 'Badge',
            CREDIT_CARD: 'Credit card',
            EDENRED: 'MyEdenred | Restaurant voucher',
            ON_SITE: 'All types of payment accepted',
          },
          creditCardNotCancelable: 'Cancellation not available',
          comment: 'Comment',
          commentHint: 'Enter a comment for the manager. We will do our best to serve you',
          commentLengthError: 'The max length of 250 characters is reached.',
          advanceTotalWarning:
            'This amount is indicative and could be adjusted when collecting your order.',
          quotationInfo: {
            admissionTitle: 'Admission',
            admissionDescription:
              'The amount of the admission corresponds to the fixed costs related to the preparation of your meal. If the payment is done "on site", this amount could be lowered at the time of retrieving your order, depending on your means of payment.',
            subsidyTitle: 'Subsidy',
            subsidyDescription:
              'The Subsidy corresponds to the portion of the meal price covered by your employer. When the payment is done "on site", the amount of this Subsidy will be automatically deducted of your ticket when paying by badge, if applicable.',
            collectedTitle: 'Collected on account',
            collectedDescription:
              'This amount is a fee paid to the owner of the premises for energy, water use, and others cost linked to the catering activity. It can be a fix amount per transaction or a % on food amount.If the payment is done "on site", this amount could be lowered at the time of retrieving your order, depending on your means of payment.',
            deductionTitle: 'Deduction half-board package / subsidy',
            deductionDescription:
              'The amount of your deduction corresponds to your half-board package, or to your subsidy which represents the meal price covered by the establishment. This amount will be calculated and deducted when retrieving your order.',
          },
        },
        confirmCancelledOrder: {
          button: 'Return',
          subtitle:
            'Your order has been cancelled.\n Your balance will be credited the full amount of the order in the next minutes.',
          title: 'Cancelled order',
        },
        orderDetails: {
          articles: 'ARTICLES',
          cancel: 'CANCEL',
          cancelMessageClickAndPickup: 'Your order can be canceled until the order period is over',
          comment: 'COMMENT',
          commentInfo: 'We will do our best for your request.',
          cancelErrorCancelled: 'Your order is already cancelled.',
          cancelErrorCompleted: 'Your order has been collected and is no longer cancellable.',
          cancelErrorNotCancelable: 'Your order can not be cancelled.',
          cancelErrorNotConfirmed: 'Your order has not been placed yet.',
          cancelModalSubtitle: 'The full amount of the order will be credited to your account',
          cancelModalTitle: 'Do you want to cancel the order ?',
          cancelSubtitle: `You can cancel your order until {{dayOfMonth}}/{{month}}/{{year}} at {{hours}}:{{minutes}} or before it is in "Ready" status`,
          cancelSubtitleAfterReadyStatus:
            'Your order can be cancelled as long as its status is not “Ready”',
          deleteButton: 'Cancel',
          emailButton: 'Send my receipt by mail',
          emailModalTitle: '-',
          emailModalError: '-',
          emailModalInput: '--',
          emailModalConfirm: '--',
          errorMessage: 'Unfortunately, your order could not be completed.',
          order: 'Order',
          orderArticleOutOfStock: `We are sorry, one of the article is out of stock.`,
          orderNumber: 'ORDER NUMBER',
          receptionDate: 'COLLECTION DATE',
          receptionPlace: 'COLLECTION PLACE',
          saveButton: 'Save',
          status: 'STATUS',
          title: 'Your order',
          totalPrice: 'Order total amount',
          totalPricePayOnSite: 'Total - Payment on site',
          withdrawalMethodOrderNotReady: 'Find here your withdrawal slip once the order is ready',
          withdrawalMethodOrderReadyTitle: 'Collect your order',
          withdrawalMethodOrderReadySubTitle: 'Display the QR code',
          titleQRCode: 'Present the QR code',
          subtitleQRCode: 'Pass the QR code in front of the reader.',
          descriptionQRCode:
            'For a good reading, remember to increase the brightness of your phone.',
          locker: 'Locker no',
          code: 'Code',
          qrCodeBrightness:
            'Changing the brightness level momentary only to help with the qr code reading',
          qrCodeError:
            'Your order is ready but not yet available for collection. Our teams are doing what is necessary to have it done quickly, do not hesitate to refresh this page regularly and contact the restaurant teams if the situation does not change.',
          warningAdvanceOrder:
            'For advance orders, your ticket will be available on the day the order is picked up.',
          seatClickAndServe: {
            table: 'Table',
          },
          container: 'Container:',
        },
        dateTime: '{{dayOfWeek}} {{dayOfMonth}}/{{month}}',
        description: 'Fresh vegetables directly from the producer to the consumer.',
        error: 'Some selected products are no longer available',
        order: 'Order',
        info: 'More information',
        orderButton: 'Next',
        offerUnavailable: 'Offer not available',
        withdraw:
          'From {{timeStartHours}}:{{timeStartMinutes}} to {{timeEndHours}}:{{timeEndMinutes}}',
        orderState: {
          abandoned: 'Expired',
          accepted: 'Valid',
          cancelled: 'Cancelled',
          cart: 'Cart',
          completed: 'Collected',
          onHold: 'Waiting for approval',
          preparing: 'Preparing',
          ready: 'Ready',
          refused: 'Refused',
        },
        outOfStock: 'Out of stock',
        slotChoice: {
          dateTime: '{{dayOfWeek}} {{dayOfMonth}} {{month}}',
          none: 'No slot available',
          slotButton: 'Select a schedule',
          subtitle: 'Select a schedule',
          title: 'Withdraw',
        },
        selectPickupPoint: {
          title: 'Pickup Point',
          emptyList: 'No results.',
          lastPointSelected: 'Last chosen Pickup point',
          searchInputPlaceHolder: 'Rechercher un point de retrait',
          noChosenPointAlert: 'You must choose a pick up point',
          chosenPointAlert: 'You have chosen',
          footerButton: 'Select a Pick up point',
        },
        stock: '{{stock}} in stock',
        subtitle: 'Order before the {{startDate}} and receive the {{endDate}}',
        title: 'Product choice',
        titleBundle: 'Compose your menu',
        unavailableOffer: 'This offer is not available',
        offerOpeningAt: 'Order today after {{startHour}}',
        mealHeartRule: {
          min0_max1: 'You can select maximum 1 product among these family products {{families}}.',
          min1_max1: 'You must select 1 product among these family products {{families}}.',
          maxBigger1:
            'You can select maximum {{maxQuantity}} product(s) among these family products {{families}}.',
          minBigger1:
            'You must select between {{minQuantity}} and {{maxQuantity}} product(s) among these family products {{families}}.',
          min_equal_to_max:
            'You must select {{maxQuantity}} product(s) among these family products {{families}}.',
        },
        articleDetails: {
          addToCart: 'Add to cart',
          label: 'Label',
          tags: 'Tags',
        },
        noOffers: {
          posClosed: 'This offer is not available today. Please select another date to order.',
          noOffersForDate:
            'This offer is not available today. Please select another date to order.',
          otherOffersForDate:
            'This offer is not available today. Check out our other offers or select another date to order.',
        },
      },
      container: 'container',
      containerInfo:
        '* The deposit will be refunded when the container is returned. See terms and conditions with the catering team.',
      filterDropdown: {
        defaultTitle: 'All areas',
        title: 'selected areas',
        unselect: 'Unselect all',
      },
      order: 'Order',
      pageTitle: {
        brasserie: 'Brasseries',
        cafeteria: 'Cafeterias',
        offers: 'Offers',
        orders: 'Orders',
        seeLess: 'See less',
        seeMore: 'See more',
        self: 'Restaurants',
      },
      tabBar: {
        here: 'HERE',
        takeAway: 'TAKE AWAY',
      },
      title: 'EAT',
    },
    home: {
      externalServices: {
        useful: 'Useful',
      },
      marketing: {
        title: 'News',
        categoryAdvice: 'Nutrition tip of the day',
        categoryNews: 'News',
        error: 'Oops, nothing to see here. Please try again.',
        seeMore: 'SEE MORE',
        published: 'Published on',
      },
      message: {
        afternoon: 'See you soon for a short break !',
        morning: "It's almost time for lunch !",
      },
      title: 'HOME',
      takeAway: 'Our take away offers',
      welcomingSentence: {
        greeting: 'Welcome',
        desire: 'What are you up to today',
        welcome: 'Welcome to Foodi',
        first: 'Foodi',
        middle: 'for your',
        last: 'meals',
        completeMyProfile: 'Complete my profile',
        profileIncomplete: 'Your profile is incomplete',
        profileIncompleteDesc:
          'To access information about your restaurants and services, select your establishment and enter your badge number.',
      },
    },
    menu: {
      error: 'An error occurred, please try again',
      pageTitle: 'On the menu',
    },
    menuElementDetails: {
      allergensDisclaimerPage: {
        subtext:
          'In our kitchen, all or some of these allergenic substances are present. Also, despite all our precautions, we can not rule out accidental cross-contamination between preparations.\n\nThe display of allergenic substances is carried out, to date, on the basis of information provided by our suppliers on their own products.\n\nThe information on allergenic substances is given for information only.',
        text:
          'Our information on allergens only concerns the 14 major allergens that we have voluntarily introduced into our preparations.',
        title: 'Allergens',
      },
      infoRecipeDisclaimer: {
        cancelButton: 'Cancel',
        checkboxText:
          'By checking this box, I agree to participate in this test program and understand that the information provided is for informational purposes only and may be partially or totally incorrect.',
        confirmButton: 'Validate',
        subtext:
          'This is a feature in the test phase.\nThe information displayed may be partially or totally incorrect.',
        text:
          'Foodi is evolving and now wants to give you more information about the recipes of your restaurants.\n\nWe are therefore implementing a new feature to provide you with information on allergens and the nutritional values ​​of these recipes.',
        title: 'Recipe information',
      },
      informationSection: {
        allergens: {
          celery: 'Celery',
          egg: 'Egg',
          fish: 'Fish',
          gluten: 'Gluten',
          lupin: 'Lupin',
          milk: 'Milk',
          mollusc: 'Mollusc',
          mustard: 'Mustard',
          nut: 'Nut',
          peanut: 'Peanut',
          sesame: 'Sesame',
          shellfish: 'Shellfish',
          soya: 'Soya',
          sulfite: 'Sulfite',
          allergenFree: 'Allergen Free',
          infoIncomplete: 'Info. Incomplete',
          infoNotDisclosed: "Information not communicated"
        },
        certifications: {
          aocp: 'AOC',
          sustainableAgriculture: 'AR',
          bio: 'AB',
          casher: 'Casher',
          certified: 'CERT',
          bioConversion: 'BIODEGRADABLE',
          fairTrade: 'FAIRTRADE',
          halal: 'HALAL',
          igp: 'PGI',
          redLabel: 'LABEL ROUGE',
          frenchProducts: 'French products',
          sustainableSeafood: 'Responsible fishing',
          meat: 'Meat Breed',
          regional: 'Regional',
          eu: 'European Union',
          frenchBovineMeet: 'VBF',
          frenchMeat: 'French meat',
          msc: 'PECHE DURABLE',
          blueWhiteHearth: 'BBC',
          ecolabel: 'ECOCERT',
          hve: 'HVE',
          frenchPork: 'VPF',
          aop: 'PDO',
          frenchPoultry: 'VVF',
          infoIncomplete: 'Info. Incomplete',
          infoNotDisclosed: "Information not communicated"
        },
        allergensDisclaimer: 'The information on allergens is given for guidance only.',
        allergensTitle: 'Allergens',
        moreDetailsButton: 'More details',
        noAllergen: 'No allergens identified in this dish',
        priceTitle: 'Price',
        title: 'Dish information',
      },
      calcium: 'Calcium',
      carbohydrate: 'Carbohydrates',
      energyValue: 'Energy',
      grams: 'g',
      includingSaturatedAcids: 'Including saturated acids',
      includingSugar: 'Including sugar',
      kilocalories: 'kcal',
      lipid: 'Lipids',
      milligrams: 'mg',
      noInfo: 'Information not available',
      nutritionalCompositionSubtitle: 'These values correspond to a portion of 100g',
      nutritionalCompositionTitle: 'Nutritional composition',
      protein: 'Proteins',
      salt: 'Salt',
      title: 'Details of the dish',
      label: 'Label',
      ingredients: 'Ingredients',
      allergens: {
        show: "Show recipe allergens",
        hide: "Hide recipe allergens",
        title: 'Allergens',
        paragraphs: [
          'Our information on allergens relates exclusively to the 14 major allergens that we have voluntarily introduced into our preparations.',
          'In our kitchen, all or some of these allergenic substances are present. Also, despite all our precautions, we cannot exclude accidental cross-contamination between preparations.',
          'The display of allergenic substances is carried out, to date, on the basis of information communicated by our suppliers on their own products.'
        ],
        allergensWarningMessageOscar: "*The information on allergens is given for guidance only. Our information on allergens only concerns the 14 major allergens that we have voluntarily introduced into our preparations. In our kitchen, all or some of these allergenic substances are present. Also, despite all our precautions, we can not rule out accidental cross-contamination between preparations.\\n\\nThe display of allergenic substances is carried out, to date, on the basis of information provided by our suppliers on their own products.\\n\\nThe information on allergenic substances is given for information only.",
        allergensWarningMessageWinapro: "*The information on allergens is given for guidance only. Our information on allergens only concerns the 14 major allergens that we have voluntarily introduced into our preparations. In our kitchen, all or some of these allergenic substances are present. Also, despite all our precautions, we can not rule out accidental cross-contamination between preparations.\\n\\nThe display of allergenic substances is carried out, to date, on the basis of information provided by our suppliers on their own products.\\n\\nThe information on allergenic substances is given for information only.",
        allergensWarningMessageOther: "",
      }
    },
    modalContest: {
      error: 'Oops, we have trouble showing you the content. Please try again later.',
    },
    profile: {
      languages: {
        en: 'English',
        fr: 'Français',
        title: 'Change language',
        validate: 'Confirm',
      },
      logOut: 'Logout',
      myInformations: {
        add: 'Add',
        active: 'Active',
        alias: 'Alias',
        aliasPlaceholder: 'Example: Toto, Grandpapa, Ma_puce…',
        aliasPlaceholderShort: 'Example: Toto, Grandpapa…',
        badge: 'Badge',
        badgeNumber: 'Badge number',
        email: 'Email',
        establishment: 'Establishment',
        firstName: 'First name',
        lastName: 'Last name',
        title: 'My information',
        holdingAndBadge: 'Establishments and badges',
        holdingViewDescription: 'To access the information of restaurants and services.',
        holdingViewInformation: 'To be active on another establishment, click on it to select it.',
        validate: 'Validate',
        wrongBadge:
          'The name and badge number do not match. Please modify your entry or contact support > help@foodi.fr',
        holdingModal: {
          deleteModalTitle: 'Are you sure to delete this establishment?',
          deleteModalMessage:
            'If you are active on this establishment and have saved others, you will switch to the next one in the list.',
          deleteModalCta: 'Delete',
        },
        deleteAccount: {
          doYouWantToLeave: 'Do you want to leave us?',
          deleteMyAccount: 'Delete my account',
          deleteModalTitle: 'Are you sure you want to delete your Foodi account?',
          deleteModalText:
            'You will definitely lose all your information related to your account. If your balance is not zero, contact your restaurant to adjust it.',
          confirm: 'Delete',
          cancel: 'Cancel',
          accountDeleted: 'You account has been deleted.',
        },
      },
      notifications: {
        balanceSubtitle: 'I want to receive a notification each time my balance is below €5',
        balanceTitle: 'Low balance alert',
        disabled: 'Disabled',
        email: 'Email',
        soonAvailable: 'Soon available',
        enabled: 'Enabled',
        mobile: 'Mobile notification',
        newServicesSubtitle:
          'I wish to receive service information, news and offers from Foodi and its partners.',
        newServicesTitle: 'New services and tips',
        partial: 'Partially enabled',
        title: 'My notifications',
      },
      pageTitle: 'My profile',
      section: {
        help: 'Need help ?',
        language: 'Language',
        login: 'Account info',
        notifications: 'Notifications',
        personalData: 'Personal Data',
        termsOfService: 'Terms of Service',
        termsOfSales: 'Terms of Sales',
        cookiesPolicy: 'Cookies policy',
        virtualTicket: 'Dematerialized Receipts',
        legalInfo: 'Legal Information',
        cookiesManagement: 'Cookies Management',
      },
      title: 'PROFILE',
      version: 'v. {{platformPrefix}}.{{versionNumber}}',
      virtualTicket: {
        disabled: 'Disabled',
        enabled: 'Enabled',
        switchSubtitle:
          'Make a gesture for the planet! Stop printing and look your transactions and receipts up directly on Foodi',
        switchTitle: 'I choose to dematerialized my receipts',
        title: 'Dematerialized Ticket',
      },
      incompleteProfile: {
        title: 'Your profile is incomplete',
        subTitle: 'To top up and start ordering',
        completeYourProfile: 'Complete your profile',
        addBadge: 'To start ordering, add your badge number.',
      },
    },
    helpOnline: {
      title: 'Help',
      button: 'Need help for your order?',
    },
  },
  error: {
    gql: 'An error occurred, please try again',
    network: 'No network connection available',
    refreshToken: 'An error occurred, please try again',
  },
  landing: {
    signIn: 'Login',
    signUp: 'Create my Foodi account',
  },
  locale: {
    name: 'English',
  },
  pointOfSale: {
    actions: {
      moreInfo: 'MORE INFORMATION',
      seeCard: 'MENU',
    },
    attendance: {
      hide: 'Hide',
      highAnimation: 'Generally animated',
      lowAnimation: 'Not too animated',
      mediumAnimation: 'Generally quite animated',
      show: 'Show attendance',
      warningText: 'Menu likely to vary according to supplies and attendance.'
    },
    list: {
      emptyContent: 'No result.',
    },
    menu: {
      closed: 'Your restaurant is closed this day.',
      dessert: 'Desserts',
      empty: 'The menu is not available for this day.',
      meal: 'Main courses',
      starter: 'Starters',
      dairies: 'Dairies',
      accompaniment: "Side dishes",
      compliments: "Complements",
      cheese: "Cheeses",
      "toTaste": "To taste",
    },
    schedulesUnavailable: 'Schedules are not available',
    seeMore: 'See more restaurants',
    services: 'Services',
    status: {
      closed: 'Closed',
      closedNow: 'Currently closed',
      opened: 'Open',
      unavailable: 'Schedules unavailable',
    },
    tomorrow: {
      currentWeek: "What's on tomorrow's menu ?",
      nextWeek: "What's on Monday's menu ?",
    },
    type: {
      brasserie: 'Brasserie',
      cafeteria: 'Cafeteria',
      other: 'Other',
      self: 'Restaurant',
    },
  },
  signUp: {
    closeHolding: {
      paragraph: `This application is not yet available in your restaurant.
Enter your email to be warned as soon as it opens !`,
      title: 'Your restaurant is not yet available in your Foodi application',
    },
    confirmation: {
      home: 'Home page',
      paragraph:
        'You will receive a confirmation email shortly, please confirm your account within 72 hours.',
      subTitle: 'You have just confirmed your account',
      title: 'Email validation',
    },
    email: {
      errorEmailExist: 'A Foodi account is already linked to this email',
      errorEmailNotValid: 'This email address is invalid',
      errorSyntax: 'This email address is invalid',
      paragraph: 'Please enter the email address you will use as login for your Foodi account.',
      placeholder: 'Email address',
      title: 'Your login email',
      titleWithoutEmail: 'Terms of Use',
      validate: 'Validate',
    },
    emailLead: {
      button: 'Back to home page',
      paragraph: 'You will be sent an email as soon as your restaurant is available in Foodi.',
      title: 'See you soon!',
    },
    emailNotActivated: {
      button: 'Resend me the email',
      paragraph: 'To activate it, click on the link in the email you received',
      subTitle: 'Your account has not been activated',
      title: 'Email validation',
    },
    geolocation: {
      findHolding: 'Find a restaurant',
      paragraph: 'Geolocate yourself to find available restaurants around you.',
      title: 'Your restaurant',
    },
    guestExist: {
      paragraph:
        'Here is the email address linked to your Foodi account. It is partially hidden to protect your information.',
      subTitle: 'A Foodi account already exists with this information',
      title: 'Your login email',
      validate: 'Log in',
    },
    headerTitle: 'Create my account',
    identity: {
      badgeNumberPlaceholder: 'Badge number',
      errorNoGuest: 'Invalid badge number or name. Contact us at help@foodi.fr',
      errorNotEnabled: 'Your current status does not allow you to create an account',
      lastNamePlaceholder: 'Last name',
      paragraph: 'You can find your badge number on your receipts.',
      title: 'Your information',
      validate: 'Confirm',
    },
    nearHolding: {
      empty: 'No restaurant matches your criteria',
      paragraph: 'Select the restaurant where you usually go.',
      placeholder: 'Restaurant name, postal code, ...',
      title: 'Your restaurant',
    },
    password: {
      tos: {
        end: '',
        link: 'Terms of Use',
        start: 'I hereby accept the ',
      },
      errorPasswordComplexity: 'Password complexity is insufficient',
      errorPasswordDifferent: "Passwords don't match",
      newsletter: 'I agree to be informed of promotional and commercial offers',
      paragraphCriteriaLength: 'at least 8 characters',
      paragraphCriteriaLowercase: '1 lowercase',
      paragraphCriteriaNumber: '1 number',
      paragraphCriteriaUppercase: '1 uppercase',
      paragraphWarning: 'Your password must have:',
      passwordConfirmationPlaceholder: 'Password confirmation',
      passwordPlaceholder: 'Password',
      title: 'Account password',
      validate: 'Confirm',
    },
    storyBookTitle: 'Storybook',
    termsOfService: {
      title: 'TOS',
    },
    welcome: {
      paragraph: 'Sign in to access Foodi.',
      signIn: 'Sign in',
      subTitle: 'Your Foodi account was successfully activated',
      title: 'Welcome to Foodi!',
    },
  },
  topUp: {
    other: 'other',
    title: 'Top-up',
    validate: 'Confirm',
  },
  transactions: {
    admission: {
      admissionAmount: 'Admission',
      admissionSubsidy: 'Admission subsidy',
      royaltyAmount: 'Property fee',
      royaltySubsidy: 'Royalty subsidy',
      title: 'Admission',
    },
    cardTitle: 'My Receipts',
    commoditySubsidy: 'Commodity subsidy',
    date: 'On {{transactionDay}} at {{transactionHour}}',
    details: {
      emptyContent: 'An error occurred, please try again.',
    },
    emptyMessage: 'No transaction to display',
    errorMessage: 'Transaction history cannot be loaded',
    subTotal: 'Subtotal',
    title: 'Receipt',
    totalTicket: {
      newBalanceAmount: 'New balance',
      oldBalanceAmount: 'Previous balance',
      refillAmount: 'Refill amount',
      title: 'Total amount',
      totalAmount: 'Amount used on balance',
    },
    trayTitle: 'Your selection',
  },
  user: {
    emailNotActivated: {
      button: 'Send it again',
      paragraph:
        'In order to activate your account, please click on the link in the email you have received at the following address',
      subTitle: 'Votre compte n`a pas été activé',
      title: "Validation de l'email",
    },
    form: {
      email: 'Email',
      firstname: 'First name',
      lastname: 'Last name',
      password: 'Password',
    },
  },
  signUpV2: {
    stepper: {
      accountCreation: 'Account creation',
      identifiers: 'Identifiers',
      establishment: 'Restaurant',
      done: 'Done',
    },
    step1: {
      firstName: 'First Name',
      lastName: 'Last Name',
      connectYou: 'To connect you',
      email: 'Login email',
      password: 'Create a password',
      emailInvalid: 'This email address is invalid.',
      emailAlreadyExists: 'A Foodi account is already associated with this email address.',
      passwordRules: 'The password must contain at least:',
      passwordRulesCharacters: 'characters',
      passwordRulesUpperCase: 'upper case',
      passwordRulesLowerCase: 'lower case',
      passwordRulesNumbers: 'number',
      receiveCommunications: 'I would like to receive communications from Compass Group in France.',
      iDeclare: 'I declare that I have read and accepted the',
      cgu: 'Terms & Conditions Use',
      and: 'and',
      age: 'certify that I’m are at least 15 years old',
      continue: 'Continue',
      require: 'required fields',
      accessTo: 'Access our',
      privacyPolicy: 'Privacy Policy',
      ssoInfo:
        'Your email has been recognized by our systems. After step 1, you will be redirected to a secure website to identify your password.',
    },
    step2: {
      consultServices: 'To view menus and services.',
      registerWithoutBadge: 'Register without badge',
      registerWithBadge: 'Register with badge',
      badge: 'Badge',
      refillsAndOrders: 'For your refills and online purchases.',
      badgeName: 'Badge or registration name',
      badgeNumber: 'Badge or registration number',
      badgeAuthorization:
        '*In the event that you enter the badge of a third party, you certify that you have their authorization.',
      dematerializedTicket:
        'I choose the dematerialized ticket and participate in the preservation of the planet.',
      skipStep2: 'Skip this step and register',
      toolTipMessage:
        'Find your badge number on your receipts.\nThe badge and the associated name can be added or modified later.',
    },
    step3: {
      welcome: 'Welcome %{firstName}',
      emailSent: 'An email has been sent to you to activate your account.',
      seeYouSoon: 'See you soon !',
      backHome: 'Back to Home',
    },
  },
};
