import numbro from 'numbro';

import { CURRENCIES } from '../constants';
import { IAmount, ICurrency } from '../types/common';

const BALANCE_NUMBER_OF_DIGITS = 2;

export const formatAmount = (
  amount: string | number | null | undefined,
  numberOfDigits?: number
): string => {
  if (amount === null || amount === undefined) {
    return '';
  } else if (amount === '-') {
    return '-';
  }
  return numbro(String(amount)?.replace?.(',', '')).format({
    mantissa: numberOfDigits !== undefined ? numberOfDigits : BALANCE_NUMBER_OF_DIGITS,
  });
};

export const isPositiveTransaction = (transactionAmount: string | null): boolean => {
  return -Number(transactionAmount) > 0;
};

export const formatTransactionAmount = (transactionAmount: string | null): string => {
  if (!transactionAmount) {
    return '';
  }

  const positiveAmount = Math.abs(Number(String(transactionAmount).replace(',', '')));
  const formattedAmount = numbro(positiveAmount).format({
    mantissa: BALANCE_NUMBER_OF_DIGITS,
  });

  return isPositiveTransaction(transactionAmount) ? `+${formattedAmount}` : formattedAmount;
};

export const formatCurrency = (currency: ICurrency): string => {
  switch (currency) {
    case CURRENCIES.EUR:
      return '€';
    default:
      return '';
  }
};

export const formatAmountWithCurrency = (amount: IAmount, numberOfDigits?: number): string => {
  // return 00.00€
  const amountFormatted = formatAmount(amount.amount, numberOfDigits);
  const currencyFormatted = formatCurrency(amount.currency);

  return amountFormatted ? `${amountFormatted}${currencyFormatted}` : '';
};

export const formatTransactionAmountWithCurrency = (amount: IAmount): string => {
  const amountFormatted = formatTransactionAmount(amount.amount);
  const currencyFormatted = formatCurrency(amount.currency);

  return amountFormatted ? `${amountFormatted}${currencyFormatted}` : '';
};

export const toNumber = (stringNumber?: string | null): number =>
  !!Number(stringNumber) ? Number(stringNumber) : 0;

export const formatAmountWithCurrencyInBetween = (amount: IAmount, numberOfDigits?: number) => {
  // return 00€00
  const amountFormatted = formatAmount(amount.amount, numberOfDigits);
  const currencyFormatted = formatCurrency(amount.currency);
  return amountFormatted ? amountFormatted.replace(/[.,]/g, currencyFormatted) : '';
};
