export type AirshipEventLabel = 'CONNECTION';
export type AirshipCustomVariableName =
  | 'holding_name'
  | 'holding_id'
  | 'guest_id'
  | 'email'
  | 'firstname'
  | 'lastname'
  | 'low_balance_email_optin'
  | 'low_balance_push_optin'
  | 'marketing_optin'
  | 'marketing_push_optin'
  | 'marketing_email_optin'
  | 'virtual_ticket';

export const AIRSHIP_EVENT: { [key in AirshipEventLabel]: AirshipEventLabel } = {
  CONNECTION: 'CONNECTION',
};

export const AIRSHIP_CUSTOM_VARIABLE: { [key: string]: AirshipCustomVariableName } = {
  EMAIL: 'email',
  FIRSTNAME: 'firstname',
  GUEST_ID: 'guest_id',
  HOLDING_ID: 'holding_id',
  HOLDING_NAME: 'holding_name',
  LASTNAME: 'lastname',
  LOW_BALANCE_EMAIL_OPTIN: 'low_balance_email_optin',
  LOW_BALANCE_PUSH_OPTIN: 'low_balance_push_optin',
  MARKETING_EMAIL_OPTIN: 'marketing_email_optin',
  MARKETING_OPTIN: 'marketing_optin',
  MARKETING_PUSH_OPTIN: 'marketing_push_optin',
  VIRTUAL_TICKET: 'virtual_ticket',
};

export const AIRSHIP_EVENT_CODE_MAPPER: { [key in AirshipEventLabel]: number } = {
  CONNECTION: 1001,
};
