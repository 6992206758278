export const convertKilometersToMeters = (km: number): number => {
  return Math.round(km * 1000);
};

export const getDisplayValueFromDistance = (distance: number): string => {
  const displayInKm = distance >= 1;
  const distanceValue = displayInKm
    ? Number(distance).toFixed(1)
    : convertKilometersToMeters(distance);
  const unit = displayInKm ? 'km' : 'm';

  return `${distanceValue} ${unit}`;
};
