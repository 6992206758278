import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import get from 'lodash/get';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import withNavigation from '../../../../../hoc/withNavigation';
import { articleFamilyKeys, DisplayedArticleFamily } from '../../../../../components/ArticlesList';
// @ts-ignore  - we are exporting this type but it still says it doesn't exist...
import { INextOrderableOffer } from '../../../../../components/OfferArticlesPage';
import OfferArticles from './OfferArticles.component';
import { IProps } from '../../../../../components/OfferArticlesPage';
import { ClickAndCollectFooterType } from '../../../../../components/QuantityFooter';
import {
  isOrderRangeValid,
  convertDateRangeToLocale,
  convertDateRangeToUTCMiliseconds,
} from '../../../../../services/offer';

const getNextOrderableOffers = loader(
  '../../../../../queries/clickandcollect/getNextOrderableOffers.gql'
);

interface IWithExtraProps {
  footerType: ClickAndCollectFooterType;
  articleFamilyKeys: DisplayedArticleFamily[];
  tableNumber?: string;
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  articleFamilyKeys,
  footerType: ClickAndCollectFooterType.CART,
  tableNumber: ownProps.navigation.getParam('tableNumber'),
});

interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  nextOrderableOffers: INextOrderableOffer[];
  refetchNextOrderableOffers?: () => Promise<void>;
}

/*
  Our request only brings the days with offers
  so when we only have offers in the future the carrousel would only show those days
  in order to fix that we are "filling" the array with the missing days until
  the withdraw date of the last offer
*/
const fillMissingDays = (
  nextOrderableOffers: INextOrderableOffer[],
  isFirstOfferCurrentDay: boolean,
  lastPublishedOffer: INextOrderableOffer
) => {
  let filledOffers = nextOrderableOffers.filter(({ orderRange }) => isOrderRangeValid(orderRange));

  if (
    !isFirstOfferCurrentDay ||
    (lastPublishedOffer &&
      convertDateRangeToLocale({ dateRange: nextOrderableOffers[0].withdrawRange }) !==
        convertDateRangeToLocale({ dateRange: lastPublishedOffer.withdrawRange }))
  ) {
    filledOffers = [];

    for (
      const dayToCheck = new Date();
      new Date(dayToCheck.toISOString().split('T')[0]).getTime() <=
      convertDateRangeToUTCMiliseconds({
        dateRange: lastPublishedOffer.withdrawRange,
      });
      dayToCheck.setDate(dayToCheck.getDate() + 1)
    ) {
      const foundDay = nextOrderableOffers.find(
        ({ withdrawRange }) =>
          convertDateRangeToLocale({ dateRange: withdrawRange }) === dayToCheck.toLocaleDateString()
      );
      const offerToPush = foundDay
        ? {
            ...foundDay,
            published:
              foundDay.published &&
              // for published offers we also need to check if it has passed the ordering time
              // otherwise expired offers for the current day would appear as open
              isOrderRangeValid(foundDay.orderRange),
          }
        : {
            id: '',
            orderRange: dayToCheck.toISOString(),
            withdrawRange: dayToCheck.toISOString(),
            published: false,
          };

      filledOffers.push(offerToPush);
    }
  }

  return filledOffers;
};

const filterByPublished = (nextOrderableOffers: INextOrderableOffer[]) => {
  if (nextOrderableOffers.length === 0) {
    return nextOrderableOffers;
  }

  const publishedArray = nextOrderableOffers.filter(
    ({ published, orderRange }) => published && isOrderRangeValid(orderRange)
  );
  const isFirstOfferCurrentDay =
    new Date().toLocaleDateString() ===
    convertDateRangeToLocale({ dateRange: nextOrderableOffers[0].withdrawRange });
  const filledOffers = fillMissingDays(
    nextOrderableOffers,
    isFirstOfferCurrentDay,
    publishedArray[publishedArray.length - 1]
  );
  /*
    if we only have 1 published offer for the current day we return it
    by doing so we are making sure that the days carrousel will appear when we only have
    1 published offer for the future or that he won't appear when we have 1 publish offer
    for the current day
  */
  return publishedArray.length === 1 && nextOrderableOffers[0].published && isFirstOfferCurrentDay
    ? publishedArray
    : filledOffers;
};

const withNextOrderableOffers = () =>
  graphql(getNextOrderableOffers, {
    options: (props): QueryOpts => {
      return {
        variables: {
          id: props.navigation.getParam('offerTemplateId'),
        },
      };
    },
    props: (props: OptionProps<{ navigation: any; offerTemplateId: string }>): IGraphQLProps => {
      const nextOrderableOffers = get(props, 'data.offerTemplate.nextOrderableOffers', []);
      return {
        hasError: !!get(props, 'data.error'),
        isLoading: get(props, 'data.loading'),
        refetchNextOrderableOffers: get(props, 'data.refetch'),
        nextOrderableOffers: filterByPublished(nextOrderableOffers),
      };
    },
  });

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps),
  withNextOrderableOffers()
)(OfferArticles);
