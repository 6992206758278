import { StyleSheet, ViewStyle } from 'react-native';

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {},
});

export default styles;
