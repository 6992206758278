import { ActionCreator, connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { isWeb } from '../../../src/lib/responsive';
import { getBadgeNumber } from '../../../src/redux/holding/selectors';
import { OfferTemplateSalesType } from '../../../src/types/clickandcollect/globalTypes';
import {
  IElementQuantity,
  ISetElementQuantityByOfferIdAction,
  ISetSelectedItemBakingModalAction,
  setElementQuantityByOfferId,
  setSelectedItemBakingModal,
} from '../../redux/clickAndCollect/actions';
import { getElementsQuantityByOfferId } from '../../redux/clickAndCollect/selectors';
import { IAppState } from '../../redux/reducer';
import OfferElementFooter, { IProps } from './OfferElementFooter.component';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import get from 'lodash/get';
import { PaymentMethod } from '../../types/clickandcollect/globalTypes';

export interface IMapStateToProps {
  elementsQuantity: IElementQuantity[] | null;
  badgeNumber?: string | null;
  isExternalCat?: boolean;
}

export interface IDispatchProps {
  setElementQuantityByOfferId: ActionCreator<ISetElementQuantityByOfferIdAction>;
  setSelectedItemBakingModal: ActionCreator<ISetSelectedItemBakingModalAction>;
}

export interface IGraphQLProps {
  hasPayOnSite?: any;
  isBadgeRequired?: boolean;
}

const getOffer = loader('../../queries/clickandcollect/getOffer.gql');
const getUserInfo = loader('../../queries/getUserInfo.gql');

const reachedQuantitiesForSelectedCategories = (
  mealHeartRules,
  item,
  productsInCart = [],
  salesType: string
) => {
  const {
    min: minProductsPerCategory,
    max: maxProductsPerCategory,
  } = mealHeartRules.quantityRules.find(prop => prop.property === 'mealQuantity');
  const numberOfProductsInRestrictedCategory = productsInCart;

  const quantityOfProductsInRestrictedCategory = productsInCart
    .filter(p => mealHeartRules.selectedFamilies.includes(p.family))
    .reduce((total, p) => total + p.quantity, 0);

  if (salesType === OfferTemplateSalesType.BUNDLE) {
    return false;
  } else if (
    quantityOfProductsInRestrictedCategory === 0 ||
    !mealHeartRules.selectedFamilies.includes(item.inheritedFamily)
  ) {
    return false;
  }

  return quantityOfProductsInRestrictedCategory === maxProductsPerCategory;
};

const determineItemAllowedQuantities = (
  mealHeartRules = [],
  item = [],
  productsInCart = [],
  salesType: string
) => {
  const { min: minSameProduct, max: maxSameProduct } = mealHeartRules.quantityRules.find(
    prop => prop.property === 'sameProductPerOrder'
  );
  const products = productsInCart.filter(p => p.elementId === item.id);

  const quantitySum = products.reduce((total, product) => total + product.quantity, 0);

  if (salesType === OfferTemplateSalesType.BUNDLE) {
    //We want it to follow the bundleStep rules, so we don't pass a maximum here
    return {};
  } else if (quantitySum === maxSameProduct) {
    return {
      maxSameProduct: maxSameProduct,
    };
  }

  return {};
};
const reachedMaxProductsPerOrder = (
  mealHeartRules = [],
  productsInCart = [],
  salesType: string
) => {
  const { min: minProductsPerOrder, max: maxProductsPerOrder } = mealHeartRules.quantityRules.find(
    item => item.property === 'productsPerOrder'
  );
  const numberOfProductsInCart = productsInCart.reduce(
    (total, product) => total + product.quantity,
    0
  );

  if (salesType === OfferTemplateSalesType.BUNDLE) {
    return false;
  }

  return numberOfProductsInCart === maxProductsPerOrder;
};

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => {
  if (ownProps.offer.offerTemplate.__typename === 'TableServiceOfferTemplate') {
    return {
      elementsQuantity: getElementsQuantityByOfferId(state, ownProps.offer.id),
      badgeNumber: !isWeb() ? getBadgeNumber(state) : null,
      isExternalCat: isWeb(),
    };
  }

  return {
    elementsQuantity: getElementsQuantityByOfferId(state, ownProps.offer.id),
    reachedMaxProductsPerOrder: reachedMaxProductsPerOrder(
      ownProps.offer.offerTemplate.mealHeartRule,
      getElementsQuantityByOfferId(state, ownProps.offer.id),
      ownProps.offer.offerTemplate.salesType
    ),
    maxSameProduct: determineItemAllowedQuantities(
      ownProps.offer.offerTemplate.mealHeartRule,
      ownProps.article,
      getElementsQuantityByOfferId(state, ownProps.offer.id),
      ownProps.offer.offerTemplate.salesType
    ).maxSameProduct,
    reachedMaxQuantityPerCategory: reachedQuantitiesForSelectedCategories(
      ownProps.offer.offerTemplate.mealHeartRule,
      ownProps.article,
      getElementsQuantityByOfferId(state, ownProps.offer.id),
      ownProps.offer.offerTemplate.salesType
    ),
    badgeNumber: getBadgeNumber(state),
  };
};

const mapDispatchToProps: IDispatchProps = {
  setElementQuantityByOfferId,
  setSelectedItemBakingModal,
};

type IOnCounterPress = (quantity: number) => void;

const withOnCounterPress = (
  ownProps: IMapStateToProps & IDispatchProps & IProps
): IOnCounterPress => (quantity: number): void => {
  const prevQuantities = ownProps.elementsQuantity;
  // remove duplicate articles
  let filteredQuantities: IElementQuantity[] = prevQuantities
    ? prevQuantities.filter(
        (item: IElementQuantity): boolean => item.elementId !== ownProps.article.id
      )
    : [];
  const prevQuantity = ([] as IElementQuantity[])
    .concat(prevQuantities || [])
    .reduce((sum, item) => (sum += item.elementId === ownProps.article.id ? item.quantity : 0), 0);

  // handle minus for baking
  if (prevQuantity > quantity && ownProps.article.baking) {
    let index = -1;
    prevQuantities.forEach((item, idx) => {
      if (item.elementId === ownProps.article.id) {
        index = idx;
      }
    });
    if (index !== -1) {
      if (prevQuantities[index].quantity > 1) {
        prevQuantities[index].quantity = prevQuantities[index].quantity - 1;
      } else {
        filteredQuantities = [
          ...prevQuantities.slice(0, index),
          ...prevQuantities.slice(index + 1),
        ];
      }
    }
  } else if (ownProps.article.baking) {
    ownProps.setSelectedItemBakingModal(ownProps.article);
    return;
  }
  // handle quantity for normal product
  if (quantity !== 0 && !ownProps.article.baking) {
    filteredQuantities.push({
      elementId: ownProps.article.id,
      price: ownProps.article.inheritedPrice,
      quantity,
      family: ownProps.article.inheritedFamily!,
      container: ownProps.article.container,
    });
  }

  ownProps.setElementQuantityByOfferId({
    elementsQuantity: filteredQuantities,
    offerId: ownProps.offer.id,
  });
};

export type IConnectedProps = IMapStateToProps &
  IDispatchProps & {
    onCounterPress: IOnCounterPress;
  };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getOffer, {
    options: (props: IProps): QueryOpts => {
      return {
        variables: {
          idOffer: props.offer.id,
        },
      };
    },
    props: (props: OptionProps<IProps>): IGraphQLProps => {
      const offerPaymentTypes = get(props, 'data.offer.offerTemplate.paymentMethod.paymentTypes');
      const hasPayOnSite = offerPaymentTypes && offerPaymentTypes.includes(PaymentMethod.ON_SITE);
      return {
        hasPayOnSite: hasPayOnSite ? hasPayOnSite : false,
      };
    },
  }),
  graphql(getUserInfo, {
    props: (props: OptionProps<IProps>): IGraphQLProps => ({
      isBadgeRequired: get(props, 'data.getUser.currentHoldingView.holding.isBadgeRequired'),
    }),
  }),
  withHandlers({ onCounterPress: withOnCounterPress })
)(OfferElementFooter);
