import { ApolloQueryResult } from 'apollo-client';
import React, { createRef, PureComponent, ReactElement, ReactNode, RefObject } from 'react';
import { OperationVariables } from 'react-apollo';
import { RefreshControl, FlatListProps, ScrollView, StyleSheet, ViewStyle } from 'react-native';

import { INode, IPos } from '../../../../types/common';
import { IUserResult } from '../../../../types/getPointsOfSaleWithMenu';
import FilterDropdown from '../../../../components/FilterDropdown';
import { IFilter } from '../../../../components/FilterDropdown/FilterDropdown.component';
import LoadingView from '../../../../components/LoadingView';
import theme from '../../../../theme';
import Logger from '../../../../lib/logger';

import ScrollContext from '../../../../components/ScrollContext';
import { getBookingOrders as IOrdersResult } from '../../../../types/bookingservice/getBookingOrders';
import { getOfferTemplates_list_edges_node_OfferTemplate as IOfferTemplate } from '../../../../types/clickandcollect/getOfferTemplates';

import { getBookingOrdersFragment_edges_node_Order as IOrder } from '../../../../types/bookingservice/getBookingOrdersFragment';

import PointsOfSaleListContent from './PointsOfSaleListContent/PointsOfSaleListContent.component';
import { isWeb } from '../../../../lib/responsive';
import OrdersList from '../../../../components/OrdersList/OrdersList.container';
import LoadingCard from '../../../../components/LoadingCard';
import { getOrdersFragment_edges_node_Order as IClickAndCollectOrder } from '../../../../types/clickandcollect/getOrdersFragment';

export interface IProps extends Partial<FlatListProps<INode<IPos>>> {
  filters?: IFilter[];
  isLoading?: boolean;
  lastItem?: ReactElement<{}>;
  pointsOfSale: Array<INode<IPos>>;
  orders?: IOrder[];
  refetch?: (variables?: OperationVariables) => Promise<ApolloQueryResult<IUserResult>>;
  refetchOrders?: (variables?: OperationVariables) => Promise<ApolloQueryResult<IOrdersResult>>;
  refetchBookingOrders?: (variables?: OperationVariables) => Promise<ApolloQueryResult<IOrdersResult>>;
  refetchOffers?: (variables?: OperationVariables) => Promise<ApolloQueryResult<IOfferTemplate>>;
  withFilters: boolean;
  tableServiceOrders?: IClickAndCollectOrder[];
  isTableServiceOrdersLoading?: boolean;
  offerTemplates?: IOfferTemplate[];
}

export interface IState {
  isRefreshing: boolean;
  componentLoaded: boolean;
}

const sortByDate = (a: IClickAndCollectOrder, b: IClickAndCollectOrder): number =>
  new Date(b.updated).getTime() - new Date(a.updated).getTime();

class PointsOfSaleList extends PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    tableServiceOrders: [],
    isTableServiceOrdersLoading: false,
  };

  public state: IState = {
    isRefreshing: false,
    componentLoaded: false,
  };

  private scrollViewRef: RefObject<ScrollView> = createRef<ScrollView>();

  public componentDidMount(): void {
    this.setState({ componentLoaded: true });
  }

  public render(): ReactNode {
    const {
      isLoading,
      filters,
      withFilters,
      orders,
      offerTemplates,
      ...flatListProps
    } = this.props;
    return (
      <>
        {!isWeb() && (
          <ScrollContext.Provider value={{ scrollViewRef: this.scrollViewRef }}>
            <ScrollView
              style={styles.list}
              refreshControl={
                <RefreshControl refreshing={this.state.isRefreshing} onRefresh={this.onRefresh} />
              }
              ref={this.scrollViewRef}
            >
              <LoadingView isLoading={isLoading}>
                {withFilters && filters && <FilterDropdown filters={filters} />}
                <PointsOfSaleListContent
                  orders={orders}
                  offerTemplates={offerTemplates}
                  filters={filters}
                  withFilters={withFilters}
                  {...flatListProps}
                  tableServiceComponent={this.showOrders.bind(this)}
                />
              </LoadingView>
            </ScrollView>
          </ScrollContext.Provider>
        )}
        {isWeb() && (
          <LoadingView isLoading={isLoading}>
            {withFilters && filters && <FilterDropdown filters={filters} />}
            <PointsOfSaleListContent
              orders={orders}
              offerTemplates={offerTemplates}
              filters={filters}
              withFilters={withFilters}
              {...flatListProps}
            />
          </LoadingView>
        )}
      </>
    );
  }

  private onRefresh = async (): Promise<void> => {
    const { refetch, refetchOrders, refetchBookingOrders, refetchOffers } = this.props;

    if (!refetch || !refetchOrders || !refetchBookingOrders || !refetchOffers || !this.state.componentLoaded) {
      return;
    }

    this.setState({ isRefreshing: true });
    try {
      await refetchOffers();
      await refetchOrders();
      await refetchBookingOrders();
      await refetch();
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isRefreshing: false, componentLoaded: true });
    }
  };

  private showOrders(): ReactElement<LoadingCard> {
    const { tableServiceOrders, isTableServiceOrdersLoading } = this.props;

    return (
      <LoadingCard isLoading={isTableServiceOrdersLoading} style={styles.containerOrders} big>
        {Array.isArray(tableServiceOrders) && !!tableServiceOrders.length && (
          <OrdersList
            orders={tableServiceOrders.sort(sortByDate)}
            testID="tableServiceOrdersList"
          />
        )}
      </LoadingCard>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  list: ViewStyle;
  containerOrders: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    backgroundColor: theme.colors.backgroundGray,
    margin: 0,
    padding: theme.margins.cardUnit * 2,
  },
  list: {
    backgroundColor: theme.colors.backgroundGray,
    height: '100%',
  },
  containerOrders: {
    backgroundColor: theme.colors.backgroundGray,
    margin: 0,
    padding: theme.margins.cardUnit * 2,
    paddingBottom: 0,
    paddingTop: 0,
  },
});

export default PointsOfSaleList;
