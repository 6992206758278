import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import { NUMBER_OF_DAYS_DISPLAYED_IN_MENU } from '../../../components/MenuTabNavigator/utils';
import withHoldingIsRecipeDetailsEnabled from '../../../hoc/withHoldingIsRecipeDetailsEnabled';

import ModalOrder, { IProps } from './Menu.component';

const getPos = loader('./queries/getPos.gql');

export type IGraphQLProps = Pick<IProps, 'isLoading' | 'hasError' | 'pointOfSale'>;
export type IConnectedProps = NavigationInjectedProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  graphql(getPos, {
    options: (props: IConnectedProps): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        days: NUMBER_OF_DAYS_DISPLAYED_IN_MENU,
        id: props.navigation.getParam('posId'),
      },
    }),
    props: (props: OptionProps<IConnectedProps>): IGraphQLProps => ({
      hasError: !!props.data!.error,
      isLoading: props.data!.loading,
      pointOfSale: get(props, 'data.getPos'),
    }),
  }),
  withHoldingIsRecipeDetailsEnabled
)(ModalOrder);
