import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withNavigation from '../../hoc/withNavigation';

import MenuTabNavigator, { IProps } from './MenuTabNavigator.component';

interface IWithInitialActiveDayIndexProps {
  initialActiveDayIndex?: string;
}

const WithInitialActiveDayIndexProps = (
  props: NavigationInjectedProps
): IWithInitialActiveDayIndexProps => ({
  initialActiveDayIndex: props.navigation.getParam('activeDayIndex'),
});

export type IConnectedProps = NavigationInjectedProps & IWithInitialActiveDayIndexProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(WithInitialActiveDayIndexProps)
)(MenuTabNavigator);
