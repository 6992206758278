import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { NetworkStatus } from 'apollo-client';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationScreenProps } from 'react-navigation';
import { compose } from 'recompose';

import { IDistance } from '../../../types/common';
import { IHolding } from '../../../types/holdingById';

import Identity, { IProps } from './Identity.component';

const holdingById = loader('../../../queries/holdingById.gql');

export interface IGraphQLProps {
  distance?: IDistance;
  holding?: IHolding;
  isLoading?: boolean;
  networkStatus: NetworkStatus;
}
export type IConnectedProps = NavigationScreenProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  graphql(holdingById, {
    options: (props: IConnectedProps): QueryOpts => {
      const holdingId = props.navigation.getParam('holdingId');
      const latitude = props.navigation.getParam('latitude');
      const longitude = props.navigation.getParam('longitude');
      return {
        variables: {
          id: holdingId,
          latitude,
          longitude,
          withDistance: !!(latitude && longitude),
        },
      };
    },
    props: (props: OptionProps<IConnectedProps>): IGraphQLProps => ({
      distance: get(props, 'data.holding.distance'),
      holding: get(props, 'data.holding.holding'),
      isLoading: get(props, 'data.loading'),
      networkStatus: get(props, 'data.networkStatus'),
    }),
  })
)(Identity);
