import { Dictionary } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import { IPosCardTypeAction } from '../../../services/pointOfSaleMapper';
import theme from '../../../theme';
import ClearButton from '../../ClearButton';
import { INavigateToOfferPageAction } from './PosCardFooter.container';

export interface IProps {
  actions: Dictionary<IPosCardTypeAction>;
  orderAction?: INavigateToOfferPageAction;
}

class PosCardFooter extends PureComponent<IProps> {
  public render(): ReactNode {
    const { actions } = this.props;
    if (isEmpty(actions)) {
      return null;
    }

    return (
      <View style={styles.container}>
        {map<IPosCardTypeAction>(actions, (action: IPosCardTypeAction, index: number) => (
          <ClearButton
            testID={`${action.text}Button_${index}`}
            key={index}
            color={action.color}
            title={action.text}
            onPress={action.callback}
            useOpacity
          />
        ))}
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  orderButtonText: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  orderButtonText: {
    color: theme.colors.blue,
  },
});

export default PosCardFooter;
