import { default as CartSummary } from './CartSummary';
import { default as ConfirmCancelledOrder } from './ConfirmCancelledOrder';
import { default as OfferArticles } from './OfferArticles';
import { default as OfferBundleStep } from './OfferBundleStep';
import { default as OfferSlots } from './OfferSlots';
import { default as OrderDetails } from './OrderDetails';
import { default as OrderDetailsQRCode } from './OrderDetailsQRCode';
import { default as PlusInfo } from './PlusInfo';
import { default as OfferArticlesDetailsPage } from './OfferArticlesDetailsPage';
import { default as OrderSelectPickupPointPage } from './OrderSelectPickupPointPage';

export default {
  CartSummary,
  ConfirmCancelledOrder,
  OrderDetails,
  OrderDetailsQRCode,
  OfferBundleStep,
  OfferArticles,
  OfferSlots,
  PlusInfo,
  OfferArticlesDetailsPage,
  OrderSelectPickupPointPage,
};
