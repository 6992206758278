import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { FlatList, StyleProp, ViewStyle, View, StyleSheet } from 'react-native';
import theme from '../../../src/theme';

import I18n from '../../lib/i18n';
import { INode } from '../../types/common';
import { INearHolding } from '../../types/nearHoldings';
import EmptyContentMessage from '../EmptyContentMessage';
import LoadingView from '../LoadingView';
import { TextInput } from '../TextInputFormik';
import TouchableHolding from '../TouchableHolding';

export interface IProps {
  contentContainerStyle?: StyleProp<ViewStyle>;
  hasNextPage?: boolean;
  isLoading?: boolean;
  nearHoldings: Array<INode<INearHolding>>;
  searchValue?: string;
  onChangeText: (searchText: string) => void;
  onEndReached: (searchText: string) => void;
  onHoldingPress: (holdingId: string) => void;
  setIsBadgeRequired?: (isBadgeRequired: boolean | undefined) => void;
}

export interface IState {
  searchText: string;
  showList: boolean;
}

class HoldingsList extends PureComponent<IProps, IState> {
  public state: IState = {
    searchText: '',
    showList: false,
  };

  public componentDidMount(): void {
    if (this.props.searchValue) {
      this.setState({ searchText: this.props.searchValue });
    }
  }

  public render(): ReactNode {
    const { contentContainerStyle, nearHoldings, hasNextPage } = this.props;

    const filterActiveHoldings = nearHoldings.filter(
      holding => holding.node.holding.foodi === true
    );

    return (
      <View>
        <TextInput
          inputStyle={styles.inputStyle}
          type="name"
          onChangeText={this.onChangeText}
          placeholder={I18n.t('signUp.nearHolding.placeholder')}
          value={this.state.searchText}
          testID="nearHoldingPlaceholder"
          containerStyle={{ borderColor: theme.colors.darkGrey, borderRadius: 8 }}
        />
        {this.state.showList && (
          <View style={styles.holdingList}>
            <FlatList
              contentContainerStyle={contentContainerStyle}
              showsVerticalScrollIndicator={false}
              data={filterActiveHoldings}
              keyExtractor={({ node }: INode<INearHolding>): string => node.holding.id}
              renderItem={this.renderItem}
              ListEmptyComponent={this.renderEmptyContent}
              onEndReached={this.onEndReached}
              ListFooterComponent={<LoadingView isLoading={hasNextPage} />}
              testID="nearHoldings"
            />
          </View>
        )}
      </View>
    );
  }

  private onChangeText = (searchText: string): void => {
    const isMinTextLength = searchText.length > 1;
    if (isMinTextLength) this.props.onChangeText(searchText);
    this.setState({ searchText, showList: isMinTextLength });
    this.props.onHoldingPress('');
  };

  private onEndReached = (): void => {
    this.props.onEndReached(this.state.searchText);
  };

  private renderEmptyContent = (): ReactElement<LoadingView> => (
    <View testID="nearHoldingEmpty">
      <LoadingView isLoading={this.props.isLoading}>
        <EmptyContentMessage text={I18n.t('signUp.nearHolding.empty')} />
      </LoadingView>
    </View>
  );

  private onHoldingPress = (
    isHoldingAvailable: boolean,
    holdingId: string,
    holdingName: string,
    isBadgeRequired: boolean | undefined
  ): void => {
    this.setState({ searchText: holdingName, showList: false });
    this.props.onHoldingPress(holdingId);
    this.props.setIsBadgeRequired && this.props.setIsBadgeRequired(isBadgeRequired);
  };

  private renderItem = ({
    item,
    index,
  }: {
    item: INode<INearHolding>;
    index: number;
  }): ReactElement<TouchableHolding> => {
    return (
      <TouchableHolding
        distance={item.node.distance}
        holding={item.node.holding}
        onHoldingPress={this.onHoldingPress}
        key={`touchable-holding-${index}`}
      />
    );
  };
}

export default HoldingsList;

const styles = StyleSheet.create({
  holdingList: {
    borderWidth: 1,
    borderColor: 'grey',
    padding: 16,
    borderRadius: 8,
    position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    top: 54,
    maxHeight: 300,
  },
  inputStyle: {
    color: 'black',
    fontSize: 18,
    lineHeight: 24,
  },
});
