import { StyleSheet } from 'react-native';

import theme from '../../../theme';

import { IPlatformStyle } from './FilterDropdownHeader.component';

const HEADER_HEIGHT = 67;
const styles = StyleSheet.create<IPlatformStyle>({
  container: {
    backgroundColor: theme.colors.white,
    height: HEADER_HEIGHT,
    paddingHorizontal: theme.margins.cardUnit * 2,
  },
});

export default styles;
