import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator, StyleSheet, Platform, Text } from 'react-native';
import WebView from 'react-native-webview';
import I18n from '../../../../lib/i18n';
import theme from '../../../../theme';
import { IGraphQLProps } from './TransactionReceipt.container';

export interface IProps extends IGraphQLProps {
  receipt: string;
}

const TransactionReceipt: React.FC<IProps> = ({ receipt, isLoading }) => {
  const [isWebViewLoading, setIsWebViewLoading] = useState(true);

  useEffect(() => {
    if (!receipt) {
      setIsWebViewLoading(false);
    }
  }, [receipt]);

  const handleHtmlSource = () => {
    if (isWebViewLoading) {
      return '';
    }

    return `
      <html>
        <meta  name="viewport" ${
          Platform.OS === 'ios' ? 'content="initial-scale=0.8, maximum-scale=0.8"' : ''
        }>
        ${receipt}
      </html>
    `;
  };

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          {isWebViewLoading && <ActivityIndicator />}
          {receipt ? (
            <WebView
              opacity={0.99} // In some cases for android, the webview crashes when inside a View, and this somehow fixes it
              source={{ html: handleHtmlSource() }}
              scrollEnabled
              textZoom={200}
              onLoadEnd={() => {
                setIsWebViewLoading(false);
              }}
            />
          ) : (
            <Text style={styles.errorText}>{I18n.t('dashboard.balance.receipt.error')}</Text>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: theme.margins.padding * 2,
  },
  errorText: {
    marginHorizontal: 20,
    color: theme.colors.black,
    ...theme.fonts.receiptError,
    textAlign: 'left',
  },
});

export default TransactionReceipt;
