import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  noMargin?: boolean;
  text?: string;
}

type IDefaultProps = Required<Pick<IProps, 'noMargin'>>;

class ErrorMessage extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    noMargin: false,
  };

  public render(): ReactNode {
    const { text, noMargin } = this.props;
    const style = StyleSheet.flatten([
      styles.text,
      {
        marginBottom: noMargin ? 0 : theme.margins.unit,
      },
    ]);

    return (
      <>
        <Text style={style}>{text}</Text>
      </>
    );
  }
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    backgroundColor: 'transparent',
    color: theme.colors.errorMessage,
    textAlign: 'left',
    ...theme.fonts.errorMessage,
  },
});

export default ErrorMessage;
