import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import LoadingCard from '../../../../components/LoadingCard';
import LanguagesList from '../../../../components/SelectableList/LanguagesList.container';
import { WebPage } from '../../../../componentsWeb';
import I18n, { supportedLanguages } from '../../../../lib/i18n';
import theme from '../../../../theme';

class Languages extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <WebPage title={I18n.t('dashboard.profile.languages.title')} canGoBack>
        <LoadingCard style={styles.listContainer}>
          <LanguagesList
            buttonText={I18n.t('dashboard.profile.languages.validate')}
            languages={supportedLanguages}
          />
        </LoadingCard>
      </WebPage>
    );
  }
}

interface IStyle {
  listContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  listContainer: {
    paddingHorizontal: theme.margins.unit * 3,
  },
});

export default Languages;
