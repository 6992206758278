import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import FoodiLogo from '../FoodiLogo';
import { VERTICAL_FOODI_LOGO_WIDTH } from '../FoodiLogo/FoodiLogo.component';
import { IContainerProps as IFoodiLogoContainerProps } from '../FoodiLogo/FoodiLogo.container';
import { FOODI_LOGO_ORIENTATION } from '../FoodiLogo/utils';

export type IProps = Pick<IFoodiLogoContainerProps, 'brandLess'>;

class IconBand extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <Image source={theme.images.iconBag} style={styles.icon} />
        <Image source={theme.images.iconChicken} style={styles.icon} />
        <Image source={theme.images.iconGuy} style={styles.icon} />
        <View style={styles.logoContainer}>
          <FoodiLogo {...this.props} orientation={FOODI_LOGO_ORIENTATION.vertical} />
        </View>
        <Image source={theme.images.iconHeart} style={styles.icon} />
        <Image source={theme.images.iconCup} style={styles.icon} />
        <Image source={theme.images.iconCard} style={styles.icon} />
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  icon: ImageStyle;
  logoContainer: ViewStyle;
}

const ICON_SIZE = VERTICAL_FOODI_LOGO_WIDTH;

const styles = StyleSheet.create<IStyle>({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  icon: {
    height: ICON_SIZE,
    marginHorizontal: ICON_SIZE / 4,
    resizeMode: 'contain',
    width: ICON_SIZE,
  },
  logoContainer: {
    marginHorizontal: ICON_SIZE / 4,
  },
});

export default IconBand;
