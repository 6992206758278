import get from 'lodash/get';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { compose, withProps } from 'recompose';
import { NavigationInjectedProps } from 'react-navigation';
import TransactionReceipt, { IProps } from './TransactionReceipt.component';
import { decodeAndFormatBase64Receipt } from '../../../../services/transactionFormatter';

const getOrderReceipt = loader('../../../../queries/getOrderReceipt.gql');

interface IWithExtraProps {
  transactionId: string;
}

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  receipt: string | undefined;
  refetch?: () => Promise<void>;
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  transactionId: ownProps.navigation.getParam('transactionId'),
});

export default compose<IProps, NavigationInjectedProps>(
  withProps(withExtraProps),
  graphql(getOrderReceipt, {
    options: ({ transactionId }: IWithExtraProps): QueryOpts => ({
      fetchPolicy: 'cache-first',
      variables: {
        idTransaction: transactionId,
      },
    }),
    props: (props: OptionProps<IWithExtraProps>): IGraphQLProps => {
      const base64Receipt = get(props, 'data.receiptBase64');
      const receipt = base64Receipt ? decodeAndFormatBase64Receipt(base64Receipt) : '';

      return {
        hasError: !!get(props, 'data.error'),
        isLoading: get(props, 'data.loading'),
        refetch: get(props, 'data.refetch'),
        receipt: receipt,
      };
    },
  })
)(TransactionReceipt);
