import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import withNavigation from '../../../../../hoc/withNavigation';
import { articleFamilyKeys, DisplayedArticleFamily } from '../../../../../components/ArticlesList';
import { getOffer_offer_Offer_offerTemplate as IOfferTemplate } from '../../../../../types/clickandcollect/getOffer';
import OfferBundleStep from './OfferBundleStep.component';
import { IProps } from '../../../../../components/BundleArticlesPage';
import { ClickAndCollectFooterType } from '../../../../../components/QuantityFooter';

// @see https://github.com/kentcdodds/babel-plugin-macros#caveats
const getOffer = loader('../../../../../queries/clickandcollect/getOffer.gql');

interface IWithExtraProps {
  offerId: string;
  footerType: ClickAndCollectFooterType;
  articleFamilyKeys: DisplayedArticleFamily[];
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  offerId: ownProps.navigation.getParam('offerId'),
  articleFamilyKeys,
  footerType: ClickAndCollectFooterType.CART,
});

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  offer: IOfferTemplate | undefined;
}

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps),
  graphql(getOffer, {
    options: (props: IWithExtraProps): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        idOffer: props.offerId,
      },
    }),
    props: (props: OptionProps<IWithExtraProps>): IGraphQLProps => ({
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      offer: get(props, 'data.offer', undefined),
    }),
  })
)(OfferBundleStep);
