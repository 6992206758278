import AccountActivated from './AccountActivated';
import AuthenticationCallback from './AuthenticationCallback';
import ConfirmEmailLead from './ConfirmEmailLead';
import EmailToValidatePage from './EmailToValidatePage';
import GeolocationPage from './GeolocationPage';
import GuestCredentialsPage from './GuestCredentialsPage';
import HoldingChoicePage from './HoldingChoicePage';
import HoldingNotAvailablePage from './HoldingNotAvailablePage';
import SignupExistingGuestPage from './SignupExistingGuestPage';
import UserPasswordPage from './UserPasswordPage';

export default {
  AccountActivated,
  AuthenticationCallback,
  ConfirmEmailLead,
  EmailToValidatePage,
  GeolocationPage,
  GuestCredentialsPage,
  HoldingChoicePage,
  HoldingNotAvailablePage,
  SignupExistingGuestPage,
  UserPasswordPage,
};
