import React, { PureComponent, ReactNode } from 'react';
import { Text, View } from 'react-native';

import theme from '../../theme';

export interface IProps {
  backgroundColor?: string;
  defaultColor: string;
  height: number | string;
  isFirst?: boolean;
  isLast?: boolean;
  labelLeft?: string;
  labelRight?: string;
  width: number | string;
}

class Rectangle extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      height,
      width,
      backgroundColor,
      defaultColor,
      isFirst,
      labelLeft,
      labelRight,
    } = this.props;

    return (
      <View style={{ flex: 1, flexDirection: 'column', height, width }}>
        <View
          style={{
            backgroundColor: backgroundColor ? backgroundColor : defaultColor,
            borderRightColor: 'white',
            borderRightWidth: 1,
            borderStyle: 'solid',
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            height: '100%',
          }}
        />
        <View
          style={{
            borderLeftColor: defaultColor,
            borderLeftWidth: isFirst ? 1 : 0,
            borderRightColor: defaultColor,
            borderRightWidth: 1,
            height: 7,
          }}
        />
        <View style={{ position: 'relative', width: '200%' }}>
          <View style={{ position: 'absolute', left: -15 }}>
            <Text
              style={{
                ...theme.fonts.chartLabel,
                color: theme.colors.grayMedium,
              }}
            >
              {labelLeft}
            </Text>
          </View>
          <View style={{ position: 'absolute', width: '100%' }}>
            <Text
              style={{
                ...theme.fonts.chartLabel,
                color: theme.colors.grayMedium,
                textAlign: 'center',
              }}
            >
              {labelRight}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default Rectangle;
