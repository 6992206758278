import colors from './colors';
import config from './config';
import fonts from './fonts';
import images from './imagesMapper';
import margins from './margins';
import shadows from './shadows';
import buttons from './buttons';

export default {
  colors,
  config,
  fonts,
  images,
  margins,
  shadows,
  buttons,
};
