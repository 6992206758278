import { applyMiddleware, createStore, GenericStoreEnhancer, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Persistor, persistStore } from 'redux-persist';

import middlewares from './middlewares.web';

import appReducer, { IAppState } from './reducer.web';

const enhancers: GenericStoreEnhancer[] = [applyMiddleware(...middlewares)];

const store: Store<IAppState> = createStore(
  appReducer,
  composeWithDevTools(...enhancers)
);

export const persistor: Persistor = persistStore(store);

export default store;
