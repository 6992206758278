import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { compose, withProps } from 'recompose';
import OrderDetailsQRCode, { IProps } from './OrderDetailsQRCode.component';
import { NavigationInjectedProps } from 'react-navigation';
import { getOneLocker_getOneLocker as ILocker } from '../../../../../types/clickandcollect/getOneLocker';
import withOrder from '../../../../../hoc/withOrder';
import { getOrder2_order_Order as IOrder } from '../../../../../types/clickandcollect/getOrder2';

const getOneLocker = loader('../../../../../queries/clickandcollect/getOneLocker.gql');

export interface IWithOrderId {
  orderId: string;
}

export interface IWithLocker {
  isLoading: boolean;
  locker: ILocker;
}

export interface IWithOrderProps {
  hasError: boolean;
  isLoading: boolean;
  order: IOrder | undefined;
}

export type IContainerProps = Omit<IProps, keyof IWithLocker & IWithOrderProps>;

const withOrderId = (ownProps: NavigationInjectedProps): IWithOrderId => ({
  orderId: ownProps.navigation.getParam('idOrder'),
});


// tslint:disable-next-line:typedef
const withLocker = () => {
  return graphql(getOneLocker, {
    options: (props: IWithOrderId): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        idOrder: props.orderId,
      },
    }),
    props: (props: OptionProps<IWithOrderId>): IWithLocker => {
      return {
        isLoading: get(props, 'data.loading'),
        locker: get(props, 'data.getOneLocker') || {},
      };
    },
    skip: (props: IWithOrderId): boolean => {
      return !props.orderId } ,
  });
};

export default compose<IProps, IContainerProps>(
  withProps(withOrderId),
  withLocker(),
  withOrder,
)(OrderDetailsQRCode);
