import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import theme from '../../theme';

export interface IProps {
  color?: string;
  height?: number;
  margin?: number;
  marginLeft?: number;
  marginRight?: number;
}

type IDefaultProps = Required<Pick<IProps, 'color' | 'height' | 'margin'>>;

class Separator extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    color: theme.colors.border,
    height: 1,
    margin: 0,
  };

  public render(): ReactNode {
    const style = StyleSheet.flatten({
      backgroundColor: this.props.color,
      height: this.props.height,
      marginLeft: this.props.marginLeft || this.props.margin,
      marginRight: this.props.marginRight || this.props.margin,
    });

    return <View style={style} />;
  }
}

export default Separator;
