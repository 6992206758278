import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';

import { IMenuElementDetails } from '../../types/common';

const getMenuElementDetailsById = loader('../../queries/getMenuElementDetailsById.gql');

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  menuElementDetails: IMenuElementDetails | undefined;
}

export interface IWithIdMenuElementProps {
  idMenuElement?: string;
}

export interface IWithMenuElementDetails {
  menuElementDetails: IMenuElementDetails | undefined;
}

export default graphql(getMenuElementDetailsById, {
  options: (props: IWithIdMenuElementProps): QueryOpts => ({
    variables: {
      idMenuElement: props.idMenuElement,
    },
  }),
  props: (props: OptionProps<IWithIdMenuElementProps>): IGraphQLProps => {
    const menuElementDetails = get(props, 'data.getMenuElement');

    return {
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      menuElementDetails,
    };
  },
});
