import { AnyAction, Reducer } from 'redux';

import { actionTypes as authenticationActionTypes } from '../authentication/actions';

import { actionTypes } from './actions';

export interface IPointsOfSaleState {
  activeFilters: string[];
}

export const initialState: IPointsOfSaleState = {
  activeFilters: [],
};

const pointsOfSaleReducer: Reducer<IPointsOfSaleState> = (
  state: IPointsOfSaleState = initialState,
  action: AnyAction
): IPointsOfSaleState => {
  switch (action.type) {
    case actionTypes.SET_POS_FILTERS:
      return {
        ...state,
        activeFilters: action.payload.activeFilters,
      };
    case authenticationActionTypes.AUTHENTICATION_LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default pointsOfSaleReducer;
