import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { NavigationInjectedProps } from 'react-navigation';

import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { loader } from 'graphql.macro';

import { IElementQuantity } from '../../redux/clickAndCollect/actions';
import { getElementsQuantityByOfferId } from '../../redux/clickAndCollect/selectors';
import { IAppState } from '../../redux/reducer';
import OfferArticlesPage, { IProps } from './OfferArticlesPage.component';
import withNavigation from '../../hoc/withNavigation';
import withOffers from '../../hoc/withOffers';
import { getOffer_offer_Offer_offerTemplate as IOfferTemplate } from '../../types/clickandcollect/getOffer';

const getOffer = loader('../../queries/clickandcollect/getOffer.gql');
const getUserInfo = loader('../../queries/getUserInfo.gql');

export interface IMapStateToProps {
  offerId: string;
  productsInCart: IElementQuantity[] | null;
}

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => ({
  offerId: ownProps.offerId,
  productsInCart: ownProps.offerId ? getElementsQuantityByOfferId(state, ownProps.offerId) : [],
});

export type IConnectedProps = IMapStateToProps & NavigationInjectedProps;

export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  offer: IOfferTemplate | undefined;
  refetchOffer?: () => Promise<void>;
}
export interface IGraphQLPropsGuest {
  idGuest: string;
}

const withUserInfo = () =>
  graphql(getUserInfo, {
    props: (props: OptionProps<IMapStateToProps>): IGraphQLPropsGuest => ({
      idGuest: get(props, 'data.getUser.guests.edges[0].node.id') || '',
    }),
  });

const withOffer = () =>
  graphql(getOffer, {
    options: ({ offerId }: { offerId: string }): QueryOpts => {
      return {
        fetchPolicy: 'no-cache',
        variables: {
          idOffer: offerId,
        },
      };
    },
    props: (props: OptionProps<{ offerId: string }>): IGraphQLProps => {
      const offer = get(props, 'data.offer');

      return {
        hasError: !!get(props, 'data.error'),
        isLoading: get(props, 'data.loading'),
        refetchOffer: get(props, 'data.refetch'),
        offer: offer ? offer : undefined,
      };
    },
  });

interface IWithExtraProps {
  tableNumber?: string
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  tableNumber: ownProps.navigation.getParam('tableNumber')
});

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps),
  withNavigation,
  withOffers,
  withOffer(),
  withUserInfo(),
  withProps(withExtraProps)
)(OfferArticlesPage);
