import { loader } from 'graphql.macro';
import get from 'lodash/get';
import queryString from 'query-string';
import React, { PureComponent, ReactNode } from 'react';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { ImageSourcePropType } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';
import theme from '../../theme';

const getPaymentStatus = loader('../../queries/getPaymentStatus.gql');

export interface IMapStateToProps {
  token?: string;
}

export interface IGraphQLProps {
  image: ImageSourcePropType;
  isLoading: boolean;
  isSuccess: boolean;
  title: string;
}

export type IProps = IGraphQLProps;

export type IConnectedProps = IMapStateToProps &
  NavigationInjectedProps &
  IGraphQLProps &
  RouteComponentProps<NavigationInjectedProps>;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

const withPaymentStatus = <T extends object>(
  Component: React.ComponentType<T & IProps>
): React.ComponentType<T> => {
  class ComponentWithPaymentStatus extends PureComponent<T & IProps> {
    public render(): ReactNode {
      return <Component {...this.props} />;
    }
  }

  return compose<T & IProps, T>(
    connect(mapStateToProps),
    graphql(getPaymentStatus, {
      options: (props: IConnectedProps): QueryOpts => ({
        variables: {
          idRefill: props.location
            ? get(queryString.parse(props.location.search), 'idRefill')
            : props.navigation.getParam('idRefill'),
          token: props.token,
        },
      }),
      props: (props: OptionProps<IConnectedProps>): IGraphQLProps => {
        const isNetworkError = get(props, 'data.networkStatus') === 8;
        const bankSuccess = get(props, 'data.mercanetV2Status.transactionSuccess');
        const cashSystemSuccess = get(props, 'data.mercanetV2Status.refillSuccess');
        const isSuccess = !isNetworkError && bankSuccess && cashSystemSuccess;
        let title = 'dashboard.balance.topUp.callbackModal.defaultTitle';

        if (isNetworkError) {
          title = 'dashboard.balance.topUp.callbackModal.networkErrorTitle';
        } else if (bankSuccess === false) {
          title = 'dashboard.balance.topUp.callbackModal.failTitle';
        } else if (bankSuccess === null) {
          // this case should never produce
        } else if (cashSystemSuccess) {
          title = 'dashboard.balance.topUp.callbackModal.successTitle';
        } else if (cashSystemSuccess === false) {
          title = 'dashboard.balance.topUp.callbackModal.delayTitle';
        } else if (cashSystemSuccess === null) {
          title = 'dashboard.balance.topUp.callbackModal.potentiaDelaylTitle';
        }

        const image = isSuccess ? theme.images.thumb : theme.images.cutlery;

        return {
          image,
          isLoading: get(props, 'data.loading'),
          isSuccess,
          title,
        };
      },
    })
  )(ComponentWithPaymentStatus);
};

export default withPaymentStatus;
