import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withHandlers } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { ISignUpCheckEmailResult } from '../../types/signUpCheckEmail';

import AlertOpeningForm, { IProps } from './AlertOpeningForm.component';

const registerLead = loader('../../queries/registerLead.gql');

type ICheckEmailQuery = (email: string) => Promise<FetchResult>;

const registerEmailLead = (
  props: WithApolloClient<{}> & NavigationInjectedProps
): ICheckEmailQuery => async (email: string): Promise<{}> =>
  props.client.mutate<ISignUpCheckEmailResult>({
    mutation: registerLead,
    variables: {
      email,
      idHolding: props.navigation.getParam('holdingId'),
    },
  });

export type IConnectedProps = NavigationInjectedProps & {
  registerEmailLead: ICheckEmailQuery;
} & WithApolloClient<{}>;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withApollo,
  withHandlers({ registerEmailLead })
)(AlertOpeningForm);
