import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import Touchable from '../../Touchable';

export interface IProps extends NavigationInjectedProps {
  onPress: () => void;
  testID?: string;
}

class LogOutButton extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Touchable
        testID={this.props.testID}
        onPress={this.onPress}
        style={styles.container}
        useOpacity
      >
        <Text style={styles.text}>{I18n.t('dashboard.profile.logOut')}</Text>
      </Touchable>
    );
  }

  private onPress = (): void => {
    this.props.navigation.navigate('auth');
    this.props.onPress();
  };
}

interface IStyle {
  container: ViewStyle;
  text: TextStyle;
}

const LOG_OUT_BUTTON_HEIGHT = 55;

const styles = StyleSheet.create<IStyle>({
  container: {
    height: LOG_OUT_BUTTON_HEIGHT,
    justifyContent: 'center',
    paddingHorizontal: theme.margins.unit * 2,
  },
  text: {
    ...theme.fonts.strongText,
    color: theme.colors.textRed,
  },
});

export default LogOutButton;
