import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import Amount from '../../../../components/Amount';
import Tag from '../../../../components/Tag';
import TriggerModal from '../../../../components/TriggerModal';
import TriggerModalContent from '../../../../components/TriggerModal/TriggerModalContent';
import I18n from '../../../../lib/i18n';
import { allergensMapper } from '../../../../services/allergensMapper';
import { Tracker } from '../../../../services/analytics';
import theme from '../../../../theme';
import { IMenuElementDetails } from '../../../../types/common';

import AllergensDisclaimerContent from './../AllergensDisclaimer/AllergensDisclaimerContent';
import platformStyles from './styles';

export interface IState {
  isAllergenModalVisible: boolean;
}

export interface IProps {
  isLoading: boolean;
  menuElementDetails?: IMenuElementDetails;
}

const BORDER_RADIUS = 4;
const BORDER_WIDTH = 1;

class MenuElementInformationContent extends React.PureComponent<IProps> {
  public state: IState = {
    isAllergenModalVisible: false,
  };

  public render(): React.ReactNode {
    const { menuElementDetails } = this.props;

    if (!menuElementDetails) {
      return null;
    }

    return (
      <View style={platformStyles.container}>
        <Text style={titleStyle}>
          {I18n.t('dashboard.menuElementDetails.informationSection.priceTitle')}
        </Text>
        <Amount amount={menuElementDetails.price} style={styles.price} />
        <Text style={titleStyle}>
          {I18n.t('dashboard.menuElementDetails.informationSection.allergensTitle')}
        </Text>
        {!isEmpty(menuElementDetails.allergens) ? (
          <View style={styles.allergensList}>
            {menuElementDetails.allergens.map((allergen: string) => (
              <Tag
                key={allergen}
                iconName={allergensMapper[allergen]}
                label={I18n.t(
                  `dashboard.menuElementDetails.informationSection.allergens.${
                    allergensMapper[allergen]
                  }`
                )}
              />
            ))}
          </View>
        ) : (
          <Text style={styles.noAllergenText}>
            {I18n.t('dashboard.menuElementDetails.informationSection.noAllergen')}
          </Text>
        )}
        <View style={disclaimerContainerStyle}>
          <View style={platformStyles.textContainer}>
            <Text style={styles.text}>
              {I18n.t('dashboard.menuElementDetails.informationSection.allergensDisclaimer')}
            </Text>
          </View>
          <View style={buttonStyle}>
            <TriggerModal
              routeName="allergensDisclaimer"
              title={I18n.t('dashboard.menuElementDetails.allergensDisclaimerPage.title')}
              onPress={(): void => this.showModal()}
            >
              <Text style={styles.buttonText}>
                {I18n.t('dashboard.menuElementDetails.informationSection.moreDetailsButton')}
              </Text>
            </TriggerModal>
            <TriggerModalContent
              title={I18n.t('dashboard.menuElementDetails.allergensDisclaimerPage.title')}
              isModalVisible={this.state.isAllergenModalVisible}
              hideModal={(): void => this.setState({ isAllergenModalVisible: false })}
              renderContent={<AllergensDisclaimerContent style={styles.disclaimerContent} />}
            />
          </View>
        </View>
      </View>
    );
  }

  private showModal = (): void => {
    Tracker.trackNavigation({ pathname: 'allergensDisclaimer' });
    this.setState({ isAllergenModalVisible: true });
  };
}

export interface IPlatformStyle {
  button: ViewStyle;
  container: ViewStyle;
  disclaimerContainer: ViewStyle;
  textContainer: ViewStyle;
  title: TextStyle;
}

export interface IStyle {
  allergensList: ViewStyle;
  button: ViewStyle;
  buttonText: TextStyle;
  disclaimerContainer: ViewStyle;
  disclaimerContent: ViewStyle;
  noAllergenText: TextStyle;
  price: TextStyle;
  text: TextStyle;
  title: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  allergensList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  button: {
    alignSelf: 'flex-end',
    height: 'auto',
    paddingHorizontal: 0,
  },
  buttonText: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.blue,
  },
  disclaimerContainer: {
    borderColor: theme.colors.blueGray,
    borderRadius: BORDER_RADIUS,
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH,
    padding: theme.margins.cardUnit * 2,
  },
  disclaimerContent: {
    marginTop: theme.margins.cardUnit * 2,
  },
  noAllergenText: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
    marginBottom: theme.margins.cardUnit,
  },
  price: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
    marginBottom: theme.margins.cardUnit * 2,
  },
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
  },
  title: {
    ...theme.fonts.smallAmount,
    color: theme.colors.black,
  },
});

const disclaimerContainerStyle = StyleSheet.flatten([
  styles.disclaimerContainer,
  platformStyles.disclaimerContainer,
]);

const buttonStyle = StyleSheet.flatten([styles.button, platformStyles.button]);
const titleStyle = StyleSheet.flatten([styles.title, platformStyles.title]);

export default MenuElementInformationContent;
