import React, { PureComponent, ReactNode } from 'react';
import { Image } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import Button from '../../../components/Button';
import Paragraph from '../../../components/Paragraph';
import SubTitle from '../../../components/SubTitle';
import I18n from '../../../lib/i18n';
import Logger from '../../../lib/logger';
import theme from '../../../theme';

import SignupLayout from '../../../../web/src/components/SignupLayout';

const styles = {
  mailIconStyle: {
    height: 85,
    margin: 'auto',
    marginBottom: '131px',
    width: 289,
  },
  mainContent: {
    marginTop: '74px',
  },
};

export interface IProps extends NavigationInjectedProps {
  sendUserEmail: () => Promise<void>;
}

export interface IState {
  isLoading: boolean;
}
class EmailNotActivated extends PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  public render(): ReactNode {
    return (
      <form>
        <SignupLayout
          title={I18n.t('signUp.headerTitle')}
          subtitle={I18n.t('signUp.emailNotActivated.title')}
          currentStepNumber={4}
          currentStepStatus="ERROR"
        >
          <div style={styles.mainContent}>
            <Image source={theme.images.mail} style={styles.mailIconStyle} />
            <SubTitle text={I18n.t('signUp.emailNotActivated.subTitle')} />
            <Paragraph text={I18n.t('signUp.emailNotActivated.paragraph')} />
            <Button
              isLoading={this.state.isLoading}
              text={I18n.t('signUp.emailNotActivated.button')}
              onPress={this.onPress}
            />
          </div>
        </SignupLayout>
      </form>
    );
  }

  private onPress = async (): Promise<void> => {
    const { sendUserEmail } = this.props;

    try {
      this.setState({ isLoading: true });
      await sendUserEmail();
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };
}

export default EmailNotActivated;
