import { Action } from 'redux';

export type IActionTypes = 'SET_POS_FILTERS';

export const actionTypes: { [key in IActionTypes]: key } = {
  SET_POS_FILTERS: 'SET_POS_FILTERS',
};

export interface ISetPosFiltersPayload {
  activeFilters: string[];
}

export interface ISetPosFiltersAction extends Action {
  payload: ISetPosFiltersPayload;
}

export const setPosFilters = (payload: ISetPosFiltersPayload): ISetPosFiltersAction => ({
  payload,
  type: actionTypes.SET_POS_FILTERS,
});
