import { GUEST_STATUS } from '../../constants';
import { ISearchGuestResult } from '../../types/searchGuest';
import { ISignUpCheckEmailResult } from '../../types/signUpCheckEmail';

import ValidatorError from './validatorError';

export const validateSearchGuestResult = (data: ISearchGuestResult): void => {
  if (!data.searchGuest) {
    throw new ValidatorError('globalError', 'signUp.identity.errorNoGuest');
  }

  if (data.searchGuest.state !== GUEST_STATUS.ENABLED) {
    throw new ValidatorError('globalError', 'signUp.identity.errorNotEnabled');
  }
};

export const validateSignUpCheckEmail = (data: ISignUpCheckEmailResult): void => {
  if (!data.checkEmail.valid) {
    throw new ValidatorError('email', 'signUp.email.errorEmailNotValid');
  }

  if (data.checkEmail.userExist) {
    throw new ValidatorError('email', 'signUp.email.errorEmailExist');
  }
};
