import I18n from '../lib/i18n';
import { IContentCategory } from '../types/common';

const MARKETING_MAPPER: { [key in IContentCategory]: { key: string } } = {
  NEWS: {
    key: 'dashboard.home.marketing.categoryNews',
  },
  NUTRITION_ADVICE: {
    key: 'dashboard.home.marketing.categoryAdvice',
  },
};

export const getContentCategoryTranslated = (contentCategory: IContentCategory): string => {
  return I18n.t(MARKETING_MAPPER[contentCategory].key);
};
