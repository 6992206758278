import sagaMiddlewareFactory from 'redux-saga';

import { authenticationWatcher } from './authentication/sagas';

const sagaMiddleware = sagaMiddlewareFactory();

export const runSaga = (): void => {
  sagaMiddleware.run(authenticationWatcher);
};

export default sagaMiddleware;
