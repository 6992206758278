import React, { PureComponent, ReactNode } from 'react';
import { LayoutChangeEvent, StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import { isWeb } from '../../lib/responsive';
import theme from '../../theme';

import { IFilter } from './FilterDropdown.component';
import FilterDropdownBody from './FilterDropdownBody';
import FilterDropdownHeader from './FilterDropdownHeader';
import DropdownFilterCounter from './FilterDropdownHeader/DropdownFilterCounter';

export interface IProps {
  activeFilters: string[];
  filters: IFilter[];
  onClearButtonPress: () => void;
  onFilterPress: (filterId: string) => void;
}

export interface IState {
  filterDropdownHeaderHeight: number;
  showFilters: boolean;
}

class FilterDropdown extends PureComponent<IProps, IState> {
  public state: IState = {
    filterDropdownHeaderHeight: 0,
    showFilters: false,
  };

  public render(): ReactNode {
    const { filters, activeFilters, onClearButtonPress, onFilterPress } = this.props;

    if (filters.length < 2) {
      return null;
    }

    return (
      <>
        <FilterDropdownHeader
          onPress={this.onToggleFiltersPress}
          showFilters={this.state.showFilters}
          onLayout={!isWeb() ? this.onLayout : undefined}
        >
          {this.shouldDisplayFilterNumber() && (
            <DropdownFilterCounter filterNumber={activeFilters.length} />
          )}
          <Text style={styles.text}>{this.renderDropdownHeaderContent()}</Text>
        </FilterDropdownHeader>
        <FilterDropdownBody
          collapsed={!this.state.showFilters}
          filters={filters}
          fromTop={this.state.filterDropdownHeaderHeight}
          onClearButtonPress={onClearButtonPress}
          onFilterPress={onFilterPress}
          onOutsidePress={(): void => this.setState({ showFilters: false })}
          activeFilters={activeFilters}
        />
      </>
    );
  }

  private onLayout = ({ nativeEvent }: LayoutChangeEvent): void => {
    this.setState({ filterDropdownHeaderHeight: nativeEvent.layout.height + nativeEvent.layout.y });
  };

  private onToggleFiltersPress = (): void => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  private renderDropdownHeaderContent = (): string => {
    const { filters, activeFilters } = this.props;

    switch (activeFilters.length) {
      case 0:
        return I18n.t('dashboard.eat.filterDropdown.defaultTitle');
      case 1:
        const selectedFilter = filters.find(
          (filter: IFilter): boolean => filter.id === activeFilters[0]
        );

        return selectedFilter ? selectedFilter.label : '';
      case filters.length:
        return I18n.t('dashboard.eat.filterDropdown.defaultTitle');
      default:
        return I18n.t('dashboard.eat.filterDropdown.title');
    }
  };

  private shouldDisplayFilterNumber = (): boolean => {
    const { filters, activeFilters } = this.props;

    return activeFilters.length > 1 && activeFilters.length < filters.length;
  };
}

export interface IPlatformStyle {
  container: ViewStyle;
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    color: theme.colors.textBlack,
    ...theme.fonts.filterText,
  },
});

export default FilterDropdown;
