import { Action } from 'redux';
import { IAmount } from '../../types/common';
import { ArticleBaking } from '../../types/tableService/globalTypes';
import { getTableServiceOffer_offer_Offer_offerItems } from '../../types/tableService/getTableServiceOffer';
import { ArticleFamily, PaymentMethod } from '../../types/clickandcollect/globalTypes';
import { getOffer_offer_Offer_offerItems_container as IContainer } from '../../types/clickandcollect/getOffer';
import { getOfferTemplatePickupPoints_pickUpPointsWithSelected as IPickupPoint } from '../../types/clickandcollect/getOfferTemplatePickupPoints';


export type IActionTypes =
  | 'SET_ELEMENTS_QUANTITY_BY_OFFER_ID'
  | 'RESET_CART'
  | 'RESET_QUANTITY_FOR_OFFER'
  | 'SET_SLOT_BY_OFFER_ID'
  | 'RESET_SLOT_FOR_OFFER'
  | 'SET_SELECTTEDITEM_BAKING_MODAL'
  | 'REMOVE_ITEMS_OUT_OF_STOCK_FROM_CART'
  | 'SET_PAYMENT_METHOD'
  | 'SET_PICKUP_POINT';

export const actionTypes: { [key in IActionTypes]: key } = {
  RESET_CART: 'RESET_CART',
  RESET_QUANTITY_FOR_OFFER: 'RESET_QUANTITY_FOR_OFFER',
  SET_ELEMENTS_QUANTITY_BY_OFFER_ID: 'SET_ELEMENTS_QUANTITY_BY_OFFER_ID',
  SET_SLOT_BY_OFFER_ID: 'SET_SLOT_BY_OFFER_ID',
  RESET_SLOT_FOR_OFFER: 'RESET_SLOT_FOR_OFFER',
  SET_SELECTTEDITEM_BAKING_MODAL: 'SET_SELECTTEDITEM_BAKING_MODAL',
  REMOVE_ITEMS_OUT_OF_STOCK_FROM_CART: 'REMOVE_ITEMS_OUT_OF_STOCK_FROM_CART',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_PICKUP_POINT: 'SET_PICKUP_POINT',
};

export interface IElementQuantity {
  elementId: string;
  price: IAmount;
  quantity: number;
  chosenBaking?: ArticleBaking;
  family?: ArticleFamily;
  container?: IContainer | null;
}

export interface ISetElementQuantityByOfferIdPayload {
  elementsQuantity: IElementQuantity[] | null;
  offerId: string;
}

export interface ISetElementQuantityByOfferIdAction extends Action {
  payload: ISetElementQuantityByOfferIdPayload;
}

export interface ISetSlotByOfferIdPayload {
  idSlot: string;
  offerId: string;
}

export interface ISetSlotByOfferIdAction extends Action {
  payload: ISetSlotByOfferIdPayload;
}

export interface IResetQuantityForOfferPayload {
  offerId: string;
}

export interface IRemoveOutOfStockItemsFromCartPayload {
  offerId: string;
  items: Array<{ idOfferItem: string }>
}

export interface IRemoveOutOfStockItemsFromCartAction extends Action {
  payload: IRemoveOutOfStockItemsFromCartPayload;
}

export interface IResetSlotForOfferPayload {
  offerId: string;
}
export interface IResetQuantityForOfferAction extends Action {
  payload: IResetQuantityForOfferPayload;
}

export interface IResetSlotForOfferAction extends Action {
  payload: IResetSlotForOfferPayload;
}

export interface ISetPickupPointPayload {
  pickupPoint: IPickupPoint;
  offerId: string;
}
export interface ISetPickupPointAction extends Action {
  payload: ISetPickupPointPayload;
}

export const setSlotByOfferId = (payload: ISetSlotByOfferIdPayload): ISetSlotByOfferIdAction => ({
  payload,
  type: actionTypes.SET_SLOT_BY_OFFER_ID,
});

export const setElementQuantityByOfferId = (
  payload: ISetElementQuantityByOfferIdPayload
): ISetElementQuantityByOfferIdAction => ({
  payload,
  type: actionTypes.SET_ELEMENTS_QUANTITY_BY_OFFER_ID,
});

export const resetCart = (): Action => ({
  type: actionTypes.RESET_CART,
});

export const resetQuantityForOffer = (
  payload: IResetQuantityForOfferPayload
): IResetQuantityForOfferAction => ({
  payload,
  type: actionTypes.RESET_QUANTITY_FOR_OFFER,
});

export const removeOutOfStockItemsFromCart = (
  payload: IRemoveOutOfStockItemsFromCartPayload
): IRemoveOutOfStockItemsFromCartAction => ({
  payload,
  type: actionTypes.REMOVE_ITEMS_OUT_OF_STOCK_FROM_CART,
});

export const resetSlotForOffer = (
  payload: IResetSlotForOfferPayload
): IResetSlotForOfferAction => ({
  payload,
  type: actionTypes.RESET_SLOT_FOR_OFFER,
});

export interface ISetSelectedItemBakingModalAction extends Action {
  payload: getTableServiceOffer_offer_Offer_offerItems | null;
}
export const setSelectedItemBakingModal = (
  payload: getTableServiceOffer_offer_Offer_offerItems | null
): ISetSelectedItemBakingModalAction => ({
  payload,
  type: actionTypes.SET_SELECTTEDITEM_BAKING_MODAL,
});

export interface ISetPaymentMethodPayload {
  paymentMethod: PaymentMethod;
  offerId: string;
};
export interface ISetPaymentMethodAction extends Action {
  payload: ISetPaymentMethodPayload;
};
export const setPaymentMethod = (
  payload: ISetPaymentMethodPayload
): ISetPaymentMethodAction => ({
  payload,
  type: actionTypes.SET_PAYMENT_METHOD,
});

export const setPickupPoint = (payload: ISetPickupPointPayload): ISetPickupPointAction => ({
  payload,
  type: actionTypes.SET_PICKUP_POINT,
});
