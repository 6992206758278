import React, { PureComponent, ReactNode } from 'react';
import { Platform, ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import KeyboardSpacer from 'react-native-keyboard-spacer';

import AppPage from '../../../components/AppPage';
import SignUpPasswordForm from '../../../components/SignUpPasswordForm';
import Stepper from '../../../components/Stepper';
import { STATUS } from '../../../components/Stepper';
import Title from '../../../components/Title';
import I18n from '../../../lib/i18n';

class Password extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <AppPage style={styles.container} hasBlackHeader>
        <View>
          <Stepper currentStepNumber={3} currentStepStatus={STATUS.ACTIVE} />
        </View>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <Title text={I18n.t('signUp.password.title')} />
          <SignUpPasswordForm />
        </ScrollView>
        {Platform.OS === 'ios' && <KeyboardSpacer />}
      </AppPage>
    );
  }
}

export interface IStyle {
  container: ViewStyle;
  scrollContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    justifyContent: 'space-between',
  },
  scrollContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
});

export default Password;
