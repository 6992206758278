import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../../../../../theme';

export interface IProps {
  text?: string;
}

class HomeMessageTitle extends PureComponent<IProps> {
  public render(): ReactNode {
    const { text = '' } = this.props;
    const splitText = text.split(' ');
    // the last character is an exclamation point or a smiley-face so the last word is the second to last
    const lastWordIndex = splitText.length - 2;
    const firstPart = splitText.splice(0, lastWordIndex).join(' ');
    const lastPart = ` ${splitText.join(' ')}`;

    return (
      <Text style={styles.text}>
        {firstPart}
        <Text style={styles.textHighlighted}>{lastPart}</Text>
      </Text>
    );
  }
}

interface IStyle {
  text: TextStyle;
  textHighlighted: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.welcomeTitle,
    color: theme.colors.textWhite,
  },
  textHighlighted: {
    fontSize: 29,
  },
});

export default HomeMessageTitle;
