import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import { ImageURISource } from 'react-native';

import { DISH_GROUP_ID, IMPORTATION_TYPES } from '../constants';
import I18n from '../lib/i18n';
import theme from '../theme';
import {
  IAllergen,
  IAmount,
  ICertification,
  IDish,
  IHolding,
  IPosMenu,
  IPosMenuElement,
  IProduct
} from '../types/common';
import { IImportationTypes } from "../constants/ImportationTypes";
import { uniqBy } from "lodash";

export type IElementDishGroup = string;
export interface IElement {
  id: string;
  label: string;
  description: string | null;
  idElement: number;
  dish: IDish;
  price?: IAmount;
  products: IProduct[];
  allergens: IAllergen[];
  certifications: ICertification[];
}

export interface ISection {
  elementDishGroup: IElementDishGroup;
  elements: IElement[];
  icon: ImageURISource;
}

export type IMenu = ISection[];

export interface IDishGroupConfig {
  icon?: ImageURISource;
  name: string;
  order: number;
}

const DISH_ID_ORDER: { [key in IImportationTypes]: object } = {
  [IMPORTATION_TYPES.WINAPRO]: {
    [DISH_GROUP_ID.WINAPRO.MEALS]: [],
    [DISH_GROUP_ID.WINAPRO.STARTERS]: [],
    [DISH_GROUP_ID.WINAPRO.DESSERTS]: [],
    [DISH_GROUP_ID.WINAPRO.ACCOMP]: [],
    [DISH_GROUP_ID.WINAPRO.COMPLEMENT]: [],
    [DISH_GROUP_ID.WINAPRO.FROMAGE]: [],
    [DISH_GROUP_ID.WINAPRO.GOUTER]: [],
  },
  [IMPORTATION_TYPES.OSCAR]: {
    // [DISH_GROUP_ID.OSCAR.MEALS]: ['MenuElementDish:41', 'MenuElementDish:44', 'MenuElementDish:51', 'MenuElementDish:52', 'MenuElementDish:6', 'MenuElementDish:37'],
    [DISH_GROUP_ID.OSCAR.MEALS]: ['TWVudUVsZW1lbnREaXNoOjQx', 'TWVudUVsZW1lbnREaXNoOjQ0', 'TWVudUVsZW1lbnREaXNoOjUx', 'TWVudUVsZW1lbnREaXNoOjUy', 'TWVudUVsZW1lbnREaXNoOjY=', 'TWVudUVsZW1lbnREaXNoOjM3'],

    // [DISH_GROUP_ID.OSCAR.STARTERS]: ['MenuElementDish:1', 'MenuElementDish:3', 'MenuElementDish:8', 'MenuElementDish:69'],
    [DISH_GROUP_ID.OSCAR.STARTERS]: ['TWVudUVsZW1lbnREaXNoOjE=', 'TWVudUVsZW1lbnREaXNoOjM=', 'TWVudUVsZW1lbnREaXNoOjg=', 'TWVudUVsZW1lbnREaXNoOjY5'],

    [DISH_GROUP_ID.OSCAR.DESSERTS]: ['TWVudUVsZW1lbnREaXNoOjI4', 'TWVudUVsZW1lbnREaXNoOjY4', 'TWVudUVsZW1lbnREaXNoOjIxMg==', 'TWVudUVsZW1lbnREaXNoOjQ3', 'TWVudUVsZW1lbnREaXNoOjcw'],
    // [DISH_GROUP_ID.OSCAR.DESSERTS]: ['MenuElementDish:28', 'MenuElementDish:68', 'MenuElementDish:212', 'MenuElementDish:47', 'MenuElementDish:70']
    [DISH_GROUP_ID.OSCAR.ACCOMP]: [],
    [DISH_GROUP_ID.OSCAR.FROMAGE]: [],
    [DISH_GROUP_ID.OSCAR.DAIRIES]: []
  },
  [IMPORTATION_TYPES.OTHER]: {
    [DISH_GROUP_ID.WINAPRO.MEALS]: [],
    [DISH_GROUP_ID.WINAPRO.STARTERS]: [],
    [DISH_GROUP_ID.WINAPRO.DESSERTS]: []
  },
};

const DISH_GROUP_CONFIG_MAPPER: { [key in IImportationTypes]: { [key: string]: IDishGroupConfig } } = {
  [IMPORTATION_TYPES.WINAPRO]: {
    [DISH_GROUP_ID.WINAPRO.STARTERS]: {
      icon: theme.images.starter,
      name: 'pointOfSale.menu.starter',
      order: 0,
    },
    [DISH_GROUP_ID.WINAPRO.MEALS]: {
      icon: theme.images.dishBlack,
      name: 'pointOfSale.menu.meal',
      order: 1,
    },
    [DISH_GROUP_ID.WINAPRO.ACCOMP]: {
      name: 'pointOfSale.menu.accompaniment',
      order: 2,
    },
    [DISH_GROUP_ID.WINAPRO.COMPLEMENT]: {
      name: 'pointOfSale.menu.compliments',
      order: 3,
    },
    [DISH_GROUP_ID.WINAPRO.FROMAGE]: {
      name: 'pointOfSale.menu.cheese',
      order: 4,
    },
    [DISH_GROUP_ID.WINAPRO.DESSERTS]: {
      icon: theme.images.dessert,
      name: 'pointOfSale.menu.dessert',
      order: 5,
    },
    [DISH_GROUP_ID.WINAPRO.GOUTER]: {
      name: 'pointOfSale.menu.toTaste',
      order: 6,
    },
  },
  [IMPORTATION_TYPES.OSCAR]: {
    [DISH_GROUP_ID.OSCAR.STARTERS]: {
      icon: theme.images.starter,
      name: 'pointOfSale.menu.starter',
      order: 0,
    },
    [DISH_GROUP_ID.OSCAR.MEALS]: {
      icon: theme.images.dishBlack,
      name: 'pointOfSale.menu.meal',
      order: 1,
    },
    [DISH_GROUP_ID.OSCAR.ACCOMP]: {
      name: 'pointOfSale.menu.accompaniment',
      order: 2,
    },
    [DISH_GROUP_ID.OSCAR.FROMAGE]: {
      name: 'pointOfSale.menu.cheese',
      order: 3,
    },
    [DISH_GROUP_ID.OSCAR.DESSERTS]: {
      icon: theme.images.dessert,
      name: 'pointOfSale.menu.dessert',
      order: 4,
    },
    [DISH_GROUP_ID.OSCAR.DAIRIES]: {
      name: 'pointOfSale.menu.dairies',
      order: 5,
    },
  },
  [IMPORTATION_TYPES.OTHER]: {},
};

export interface IMenuElementsByDishGroupId {
  [key: string]: IElement[];
}

export const getMenuElementsByDishGroupId = (menu: IPosMenuElement[]): IMenuElementsByDishGroupId =>
  menu.reduce(
    (
      menuElementsByDishGroupId: IMenuElementsByDishGroupId,
      unformattedMenuElement: IPosMenuElement
    ) => {
      const dishGroupId = unformattedMenuElement.dish.dishGroup.id;
      const previousElements: IElement[] | undefined = menuElementsByDishGroupId[dishGroupId];
      const newElement: IElement = unformattedMenuElement;

      return {
        ...menuElementsByDishGroupId,
        [dishGroupId]: previousElements ? [...previousElements, newElement] : [newElement],
      };
    },
    {}
  );

export type ISectionAsPair = [string, IElement[]];

export const getFormattedMenu = (menu: IPosMenuElement[], hodling: IHolding): IMenu => {
  const cleanedMenus = uniqBy(menu.filter(({ id, label, products }) => {
    return !menu.some((menu) => {
      if(menu.id != id && menu.label === label && products?.length < menu?.products.length){
        return true
      }
    })
  }), 'label')

  const menuElementsByDishGroupId: IMenuElementsByDishGroupId = getMenuElementsByDishGroupId(cleanedMenus);

  const sectionsAsPair = toPairs<IElement[]>(menuElementsByDishGroupId);
  let cleanedSectionsAsPair = sectionsAsPair.filter(
    (element: ISectionAsPair) => {
      return !!DISH_GROUP_CONFIG_MAPPER[hodling.importationType][element[0]]
    }
  );

  // Filter duplicated menus
  cleanedSectionsAsPair = cleanedSectionsAsPair.map(([dishGroupId, elements]: [string, IElement[]]) => {
    const idElements: number[] = [];
    const filteredElements = elements.filter(element => {
      if (idElements.includes(element.idElement)) {
        return false;
      }

      idElements.push(element.idElement);
      return true;
    });

    const sortBy = DISH_ID_ORDER[hodling.importationType][dishGroupId];
    const sortElements = filteredElements.sort((a, b) => {
      if (!sortBy.includes(a.dish.id) && sortBy.includes(b.dish.id)) {
        return 1;
      } else if (sortBy.includes(a.dish.id) && !sortBy.includes(b.dish.id)) {
        return -1;
      } else {
        return sortBy.indexOf(a.dish.id) - sortBy.indexOf(b.dish.id);
      }
    });

    return [dishGroupId, sortElements];
  });

  const sectionsAsPairOrdered = sortBy<ISectionAsPair>(
    cleanedSectionsAsPair,
    (element: ISectionAsPair): number => DISH_GROUP_CONFIG_MAPPER[hodling.importationType][element[0]].order
  );

  return sectionsAsPairOrdered.map(
    (element: ISectionAsPair): ISection => ({
      elementDishGroup: I18n.t(DISH_GROUP_CONFIG_MAPPER[hodling.importationType][element[0]].name),
      elements: element[1],
      icon: DISH_GROUP_CONFIG_MAPPER[hodling.importationType][element[0]].icon,
    })
  );
};

export interface IMenuByDays {
  [key: string]: IPosMenu;
}

export const getMenuByDays = (menus: IPosMenu[]): IMenuByDays => {
  return keyBy<IPosMenu>(menus, 'day');
};
