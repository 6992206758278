import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationScreenProps } from 'react-navigation';

import LoadingView from '../../components/LoadingView';

class Loading extends PureComponent<NavigationScreenProps> {
  public render(): ReactNode {
    return (
      <View style={styles.page}>
        <LoadingView isLoading />
      </View>
    );
  }
}

export interface IStyle {
  page: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  page: {
    flexDirection: 'row',
    height: '100vh',
    overflow: 'hidden',
  },
});

export default Loading;
