import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';

import LoadingView from '../../../components/LoadingView';
import { WebPage } from '../../../componentsWeb';
import { IMenuElementDetails } from '../../../types/common.d';

import MenuElementDetailsContent from './MenuElementDetailsContent';

export interface IProps {
  isLoading: boolean;
  menuElementDetails?: IMenuElementDetails;
}
class MenuElementDetails extends PureComponent<IProps> {
  public render(): ReactNode {
    const label = get(this.props.menuElementDetails, 'label');

    return (
      <WebPage title={label} canGoBack>
        <LoadingView isLoading={this.props.isLoading}>
          <MenuElementDetailsContent />
        </LoadingView>
      </WebPage>
    );
  }
}

export default MenuElementDetails;
