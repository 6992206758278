import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getSlotByOfferId } from '../../redux/clickAndCollect/selectors';
import { IAppState } from '../../redux/reducer';

import SlotsList, { IProps } from './SlotsList.component';
export interface IMapStateToProps {
  slotId: string | null;
}

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => ({
  slotId: getSlotByOfferId(state, ownProps.offer.id),
});

export type IConnectedProps = IMapStateToProps 

export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    mapStateToProps,
  )
)(SlotsList);
