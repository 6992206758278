const locale = {
  arabic: 0,
  chineseSimplified: 1,
  chineseTraditional: 3,
  czech: 4,
  danish: 5,
  dutch: 6,
  english: 7,
  french: 8,
  german: 9,
  italian: 10,
  japanese: 11,
  polish: 14,
  portugueseBrazil: 16,
  russian: 17,
  spanish: 19,
  swedish: 20,
  turkish: 21,
};

const Instabug = {
  locale,
  setLocale: (): void => undefined,
};

export default Instabug;
