import { FormikProps } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { ScrollView, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { ActionCreator } from 'redux';

import LargeButton from '../../../components/LargeButton';
import { LARGE_BUTTON_THEME } from '../../../components/LargeButton/LargeButton.component';
import I18n from '../../../lib/i18n';
import { isWeb } from '../../../lib/responsive';
import { IToggleRecipeDisclaimerAction } from '../../../redux/menu/actions';
import theme from '../../../theme';
import CheckboxInput from '../../CheckboxInput';
import FormFormik from '../../FormFormik';

import { platformStyles } from './styles';

export interface IValues {
  recipeDisclaimer: boolean;
}

export type IProps = FormikProps<IValues> &
  NavigationInjectedProps & {
    toggleRecipeDisclaimer: ActionCreator<IToggleRecipeDisclaimerAction>;
  };

class InfoRecipeDisclaimerFormikForm extends PureComponent<IProps, {}> {
  public render(): ReactNode {
    const { isSubmitting, values, submitForm, toggleRecipeDisclaimer } = this.props;

    return (
      <View style={platformStyles.formContainer}>
        <FormFormik style={platformStyles.formikContainer}>
          <ScrollView contentContainerStyle={platformStyles.contentContainer}>
            <Text style={styles.text}>
              {I18n.t('dashboard.menuElementDetails.infoRecipeDisclaimer.text')}
            </Text>
            <View style={styles.subTextContainer}>
              <Text style={styles.subText}>
                {I18n.t('dashboard.menuElementDetails.infoRecipeDisclaimer.subtext')}
              </Text>
            </View>
            <View style={styles.checkboxContent}>
              <CheckboxInput
                text={I18n.t('dashboard.menuElementDetails.infoRecipeDisclaimer.checkboxText')}
                isChecked={values.recipeDisclaimer}
                onChange={this.onCheckboxInputChange}
              />
            </View>
          </ScrollView>
          <View style={styles.buttonContainer}>
            <LargeButton
              text={I18n.t('dashboard.menuElementDetails.infoRecipeDisclaimer.cancelButton')}
              theme={LARGE_BUTTON_THEME.default}
              style={leftButtonStyle}
              onPress={(): boolean | IToggleRecipeDisclaimerAction => {
                return isWeb()
                  ? toggleRecipeDisclaimer({ showRecipeDisclaimer: false })
                  : this.props.navigation.goBack();
              }}
            />
            <LargeButton
              disabled={!this.canSubmit(values)}
              text={I18n.t('dashboard.menuElementDetails.infoRecipeDisclaimer.confirmButton')}
              style={styles.rightButton}
              theme={LARGE_BUTTON_THEME.success}
              onPress={submitForm}
              isLoading={isSubmitting}
            />
          </View>
        </FormFormik>
      </View>
    );
  }

  private canSubmit = (values: IValues): boolean => {
    return values.recipeDisclaimer;
  };

  private onCheckboxInputChange = (): void => {
    this.props.setFieldValue('recipeDisclaimer', !this.props.values.recipeDisclaimer);
  };
}

interface IStyle {
  buttonContainer: ViewStyle;
  checkboxContent: ViewStyle;
  leftButton: ViewStyle;
  rightButton: ViewStyle;
  subText: TextStyle;
  subTextContainer: ViewStyle;
  text: TextStyle;
}

export interface IPlatformStyle {
  container: ViewStyle;
  contentContainer: ViewStyle;
  formContainer: ViewStyle;
  formikContainer: ViewStyle;
  leftButton: TextStyle;
}

const BORDER_RADIUS = 4;
const BORDER_WIDTH = 1;

const styles = StyleSheet.create<IStyle>({
  buttonContainer: {
    flexDirection: 'row',
  },
  checkboxContent: {
    marginTop: theme.margins.cardUnit * 2,
  },
  leftButton: {
    flex: 1,
    paddingTop: theme.margins.cardUnit * 3 - BORDER_WIDTH,
  },
  rightButton: {
    backgroundColor: theme.colors.validate,
    flex: 1,
  },
  subText: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.grayMedium,
  },
  subTextContainer: {
    borderColor: theme.colors.blueGray,
    borderRadius: BORDER_RADIUS,
    borderWidth: BORDER_WIDTH,
    padding: theme.margins.cardUnit * 2,
  },
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
    marginBottom: theme.margins.cardUnit * 2,
  },
});

const leftButtonStyle = StyleSheet.flatten([styles.leftButton, platformStyles.leftButton]);

export default InfoRecipeDisclaimerFormikForm;
