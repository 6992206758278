import { StyleSheet } from 'react-native';

import theme from '../../theme';

import { IStyle } from './Card.component';

const styles = StyleSheet.create<IStyle>({
  bigCard: {},
  card: {
    backgroundColor: theme.colors.background,
    marginBottom: theme.margins.cardUnit * 2,
    padding: theme.margins.cardUnit * 2,
  },
  smallCard: {},
});

export default styles;
