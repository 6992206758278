import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import I18n from '../../../lib/i18n';
import { IAppState } from '../../../redux/reducer';

import { selectSignUpToken } from '../../../redux/signUp/selectors';

import CheckEmail, { IProps } from './CheckEmail.component';

export interface IMapStateToProps {
  signupToken?: string;
}

interface IWithTitleProps {
  title: string;
}

const withTitleProps = (ownProps: IMapStateToProps): IWithTitleProps => ({
  title: ownProps.signupToken
    ? I18n.t('signUp.email.titleWithoutEmail')
    : I18n.t('signUp.email.title'),
});

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  signupToken: selectSignUpToken(state),
});

export default compose<IProps, {}>(
  connect(mapStateToProps),
  withProps(withTitleProps)
)(CheckEmail);
