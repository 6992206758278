import React, { PureComponent, ReactNode } from 'react';
import LegalInfoSections from '../../../../components/LegalInfoSections';

import { WebPage } from '../../../../componentsWeb';
import I18n from '../../../../lib/i18n';

class LegalInfo extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <WebPage title={I18n.t('dashboard.profile.section.legalInfo')} canGoBack>
        <LegalInfoSections />
      </WebPage>
    );
  }
}

export default LegalInfo;
