import React, { PureComponent, ReactNode } from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import theme from '../../theme';
import I18n from '../../lib/i18n';
import Touchable, { IProps as ITouchableProps } from '../Touchable';

export interface IProps extends ITouchableProps {
  disabled?: boolean;
  isLoading?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  theme: ILargeButtonTheme;
  testID?: string;
}

type IDefaultProps = Required<Pick<IProps, 'isLoading'>>;

type ILargeButtonTheme = 'success' | 'danger' | 'default' | 'disabled' | 'neutral' | 'active';

export const LARGE_BUTTON_THEME: { [key in ILargeButtonTheme]: key } = {
  danger: 'danger',
  default: 'default',
  success: 'success',
  disabled: 'disabled',
  neutral: 'neutral',
  active: 'active',
};

const TEXT_HEIGHT = 24;

class LargeButton extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    isLoading: false,
  };

  public render(): ReactNode {
    const { disabled, onPress, style, text, textStyle, testID } = this.props;

    let textareaStyle = {};
    let buttonStyle = {};

    const disabledStyle = disabled ? styles.disabled : {};
    const disabledTextStyle = disabled ? styles.disabledText : {};

    switch (this.props.theme) {
      case LARGE_BUTTON_THEME.danger:
        buttonStyle = [styles.button, styles.dangerButton, style];
        textareaStyle = [styles.text, styles.dangerText, textStyle];
        break;
      case LARGE_BUTTON_THEME.success:
        buttonStyle = [styles.button, styles.successButton, style];
        textareaStyle = [styles.text, styles.successText, textStyle];
        break;
      case LARGE_BUTTON_THEME.disabled:
        buttonStyle = [styles.button, styles.disabled, style];
        textareaStyle = [styles.text, styles.disabledText, textStyle];
        break;
      case LARGE_BUTTON_THEME.neutral:
        buttonStyle = [styles.button, styles.neutral, style];
        textareaStyle = [styles.text, styles.neutralText, textStyle];
        break;
      case LARGE_BUTTON_THEME.active:
        buttonStyle = [styles.button, styles.active, style];
        textareaStyle = [styles.text, styles.disabledText, textStyle];
        break;
      default:
        buttonStyle = [styles.button, style];
        textareaStyle = [styles.text, styles.defaultText, textStyle];
    }

    return (
      <Touchable
        testID={testID}
        style={[buttonStyle, disabledStyle]}
        useOpacity
        onPress={onPress}
        disabled={disabled}
      >
        {this.props.isLoading ? (
          <View style={styles.loading}>
            <ActivityIndicator color={theme.colors.white} style={styles.spinner} />
            <Text style={[textareaStyle, disabledTextStyle]}>{I18n.t('dashboard.eat.clickAndCollect.cartSummary.loading')}</Text>
          </View>
        ) : (
          <Text style={[textareaStyle, disabledTextStyle]}>{text}</Text>
        )}
      </Touchable>
    );
  }
}

export interface IStyle {
  button: ViewStyle;
  dangerButton: ViewStyle;
  dangerText: TextStyle;
  defaultText: TextStyle;
  disabled: ViewStyle;
  neutral: ViewStyle;
  neutralText: TextStyle;
  active: ViewStyle;
  loading: ViewStyle;
  successButton: ViewStyle;
  successText: TextStyle;
  disabledText: TextStyle;
  text: TextStyle;
  spinner: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 3 * theme.margins.cardUnit,
  },
  dangerButton: {
    backgroundColor: theme.colors.errorDanger,
  },
  dangerText: {
    color: theme.colors.textWhite,
  },
  defaultText: {
    color: theme.colors.gray,
  },
  disabled: {
    backgroundColor: theme.colors.blueGray,
  },
  neutral: {
    backgroundColor: theme.colors.grayLightMedium,
  },
  neutralText: {
    color: theme.colors.textWhite,
  },
  active: {
    backgroundColor: theme.colors.white,
  },
  loading: {
    height: TEXT_HEIGHT,
    flexDirection: 'row',
  },
  successButton: {
    backgroundColor: theme.colors.validate,
  },
  successText: {
    color: theme.colors.textWhite,
  },
  disabledText: {
    color: theme.colors.black,
  },
  text: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.white,
  },
  spinner: {
    marginRight: theme.margins.cardUnit * 2,
  },
});

export default LargeButton;
