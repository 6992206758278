import 'isomorphic-fetch';
import { IConfig } from './type';

let config: IConfig | null = null;

export async function initializeEnv(): Promise<IConfig> {
  return fetch('config.json')
    .then(async (response: Response) => response.json())
    .then((envConfig: IConfig) => (config = envConfig));
}

export const getConfig = (): IConfig | null => config;

export default config;
