import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import { compose, withProps } from 'recompose';

import I18n from '../../lib/i18n';

import { IProps as IMyInformationSectionProps } from './../MyInformationSection';
import MyInformationSections, { IProps } from './MyInformationSections.component';

const getProfileInfo = loader('../../queries/getProfileInfo.gql');

export interface IWithMyInformationSectionsProps {
  myInformationSections: IMyInformationSectionProps[];
}

const withMyInformationSectionsProps = (
  ownProps: IGraphQLProps
): IWithMyInformationSectionsProps => ({
  myInformationSections: [
    {
      subtitle: ownProps.firstName,
      title: I18n.t('dashboard.profile.myInformations.firstName'),
    },
    {
      subtitle: ownProps.lastName,
      title: I18n.t('dashboard.profile.myInformations.lastName'),
    },
    {
      subtitle: ownProps.email,
      title: I18n.t('dashboard.profile.myInformations.email'),
    },
    {
      subtitle: ownProps.badgeNumber,
      title: I18n.t('dashboard.profile.myInformations.badgeNumber'),
    },
  ],
});

export interface IGraphQLProps {
  badgeNumber?: string;
  email?: string;
  firstName?: string;
  isLoading: boolean;
  lastName?: string;
}

export default compose<IProps, {}>(
  graphql(getProfileInfo, {
    props: (props: OptionProps<IProps>): IGraphQLProps => ({
      badgeNumber: get(props, 'data.getUser.guests.edges[0].node.supportSerialNumber'),
      email: get(props, 'data.getUser.email'),
      firstName: get(props, 'data.getUser.firstName'),
      isLoading: get(props, 'data.loading'),
      lastName: get(props, 'data.getUser.lastName'),
    }),
  }),
  withProps(withMyInformationSectionsProps),
)(MyInformationSections);
