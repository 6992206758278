import React, { Component, ReactNode } from 'react';
import { StyleSheet, TextStyle, View, ViewStyle, Text } from 'react-native';
import { PaymentMethod } from '../../../src/types/clickandcollect/globalTypes';
import { IElementQuantity } from '../../redux/clickAndCollect/actions';
import theme from '../../theme';
import I18n from '../../lib/i18n';
import {
  getOffer_offer_Offer as IOffer,
  getOffer_offer_Offer_offerItems as IOfferItem,
} from '../../types/clickandcollect/getOffer';
import { getTableServiceOffer_offer_Offer as ITableOffer } from '../../types/tableService/getTableServiceOffer';
import Amount from '../Amount';
import Counter from '../Counter';

export interface IProps {
  article: IOfferItem;
  elementsQuantity: IElementQuantity[];
  offer: IOffer | ITableOffer;
  onCounterPress: (quantity: number) => () => void;
  quantityRenderer: undefined | ((quantity: number) => ReactNode);
  sizeDivider: number;
  remainingQuantity?: number;
  hidePrice?: boolean;
  quantity?: number;
  amountAlternativeFormat?: boolean;
  amountStyle?: Object;
  maxSameProduct: number;
  noPadding?: boolean;
  testID?: string;
  badgeNumber?: string | null;
  isBadgeRequired?: boolean;
  isExternalCat?: boolean;
  hasPayOnSite?: any;
}
export interface IState {
  prevQuantity: number;
  max: number;
}

const MIN_VALUE = 0;
// const MAX_VALUE = 99;

class OfferElementFooter extends Component<IProps> {
  public state: IState = {
    prevQuantity: 0,
    max:
      typeof this.props.article.quantityRemaining === 'number'
        ? this.props.article.quantityRemaining
        : MIN_VALUE,
  };

  public componentDidMount(): void {
    this.handleQuantity();
  }

  public handleQuantity() {
    if (this.props.quantity != null && this.props.quantity >= this.state.max) {
      this.setState({
        ...this.state,
        prevQuantity: this.props.quantity,
      });
    }
  }

  public shouldComponentUpdate(nextProps: IProps): boolean {
    const previousQuantity =
      nextProps.quantity != null
        ? nextProps.quantity
        : this.computeQuantity(nextProps.elementsQuantity);
    const currentQuantity =
      this.props.quantity != null
        ? this.props.quantity
        : this.computeQuantity(this.props.elementsQuantity);

    return (
      previousQuantity !== currentQuantity ||
      this.props.reachedMaxProductsPerOrder !== nextProps.reachedMaxProductsPerOrder ||
      this.props.maxSameProduct !== nextProps.maxSameProduct ||
      this.props.reachedMaxQuantityPerCategory !== nextProps.reachedMaxQuantityPerCategory
    );
  }

  public render(): ReactNode {
    const {
      article,
      onCounterPress,
      quantityRenderer,
      sizeDivider,
      hidePrice = false,
      amountStyle = {},
      amountAlternativeFormat,
      maxSameProduct,
      reachedMaxProductsPerOrder,
      reachedMaxQuantityPerCategory,
      noPadding = false,
      testID,
      badgeNumber,
      isBadgeRequired,
      hasPayOnSite,
      isExternalCat = false
    } = this.props;
    const price = article.inheritedPrice;
    const value = this.computeQuantity(this.props.elementsQuantity);
    const containerStyles = noPadding
      ? styles.container
      : [styles.container, { padding: theme.margins.cardUnit }];
    const style =
      sizeDivider === 2
        ? {
            width: 65,
            fontSize: 16,
          }
        : { width: 100 };

    const paymentOnSite = this.props.offer.offerTemplate?.paymentMethod?.paymentTypes?.includes?.(PaymentMethod.ON_SITE);

    // // To check for internal cashSystems, if user dont have badge and have payOnSite available
    const checkBadgeNeeded = isBadgeRequired && !hasPayOnSite && !badgeNumber;

    return (
      <View style={containerStyles}>
        {!hidePrice && (
          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
            {article.container ? (
              <>
                <Amount
                  amount={price}
                  style={[styles.amount, amountStyle]}
                  alternativeFormat={amountAlternativeFormat}
                />
                <Text style={{ alignItems: 'center' }}> + </Text>
                <Amount
                  amount={article.container?.price}
                  style={[styles.amount, amountStyle]}
                  alternativeFormat={amountAlternativeFormat}
                />
                <Text style={[styles.amount, amountStyle, { alignItems: 'center', marginLeft: 5 }]}>
                  {I18n.t('dashboard.eat.container')}*
                </Text>
              </>
            ) : (
              <Amount
                amount={price}
                style={[styles.amount, style, amountStyle]}
                alternativeFormat={amountAlternativeFormat}
              />
            )}
          </View>
        )}
        <Counter
          testID={testID}
          size={sizeDivider === 2 ? 'mini' : undefined}
          min={MIN_VALUE}
          max={
            maxSameProduct || this.state.prevQuantity > 0 ? this.state.prevQuantity : this.state.max
          }
          onCounterPress={onCounterPress}
          quantityRenderer={quantityRenderer}
          value={value}
          disableCounter={
            reachedMaxQuantityPerCategory ||
            reachedMaxProductsPerOrder ||
            ((paymentOnSite && !badgeNumber ? false : isBadgeRequired && !badgeNumber) && !isExternalCat)
          }
        />
      </View>
    );
  }

  private computeQuantity(elementsQuantity: IElementQuantity[]): number {
    return elementsQuantity
      ? elementsQuantity.reduce((quantity, element) => {
          if (element.elementId === this.props.article.id) {
            quantity += element.quantity;
          }
          return quantity;
        }, 0)
      : MIN_VALUE;
  }
}

interface IStyle {
  amount: TextStyle;
  containerText: TextStyle;
  container: ViewStyle;
  price: ViewStyle;
  add: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    ...theme.fonts.littleTitle,
    color: theme.colors.black,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 0,
  },
  price: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  add: {
    alignItems: 'center'
  },
  containerText: {
    alignItems: 'center',
    marginLeft: 5
  }
});

export default OfferElementFooter;
