import withApollo from 'react-apollo/withApollo';
import { connect, Dispatch } from 'react-redux';
import { compose } from 'recompose';
import { NavigationInjectedProps } from 'react-navigation';
import withNavigation from '../../hoc/withNavigation';
import {
  IElementQuantity,
  ISetElementQuantityByOfferIdPayload,
  setElementQuantityByOfferId as setElementQuantityByOfferIdAction,
} from '../../redux/clickAndCollect/actions';
import { getElementsQuantityByOfferId } from '../../redux/clickAndCollect/selectors';
import { IAppState } from '../../redux/reducer';
import { getOffer_offer_Offer_offerItems as IOfferItem } from '../../types/clickandcollect/getOffer';
import { ArticleBaking } from '../../types/clickandcollect/globalTypes';
import { setSelectedItemBakingModal as setSelectedItemBakingModalAction } from './../../redux/clickAndCollect/actions';
import BakingModal, { IProps } from './BakingModal.component';

export interface IMapStateToProps {
  article?: IOfferItem | null;
  isVisible?: boolean;
  elementsQuantity: IElementQuantity[] | null;
}

export interface IMapDispatchToProps {
  setElementQuantityByOfferId: (item: ISetElementQuantityByOfferIdPayload) => void;
  setSelectedItemBakingModal: () => void;
}

type IOnButtonPress = (chosenBaking: ArticleBaking) => void;

export interface IMergeProps extends IMapDispatchToProps, IMapStateToProps {
  onBackPress: () => void;
  onButtonPress: IOnButtonPress;
}

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => ({
  article: state.clickAndCollect.selectedBakingItem,
  isVisible: !!state.clickAndCollect.selectedBakingItem,
  elementsQuantity: getElementsQuantityByOfferId(state, ownProps.offerId),
});

const mergeProps = (
  stateProps: IMapStateToProps,
  dispatchProps: IMapDispatchToProps,
  { offerId, articleFamilies }: IProps
): IMergeProps => ({
  ...stateProps,
  ...dispatchProps,
  onButtonPress: (chosenBaking: ArticleBaking): void => {
    const { setSelectedItemBakingModal, setElementQuantityByOfferId } = dispatchProps;
    const { article, elementsQuantity } = stateProps;
    if (!article) {
      return;
    }
    setSelectedItemBakingModal();
    const prevQuantities = articleFamilies ?
      (elementsQuantity || [] as IElementQuantity[]) :
      (elementsQuantity || [] as IElementQuantity[]).slice();

    prevQuantities.push({
      elementId: article.id,
      price: article.inheritedPrice,
      quantity: 1,
      chosenBaking,
      family: article.inheritedFamily!,
      container: article.container,
    });

    setElementQuantityByOfferId({
      elementsQuantity: ([] as IElementQuantity[]).concat(prevQuantities),
      offerId,
    });
  },
  onBackPress: (): void => {
    dispatchProps.setSelectedItemBakingModal();
  },
});

export type IConnectedProps = IMapStateToProps &
  NavigationInjectedProps &
  IMapDispatchToProps & {
    onButtonPress: IOnButtonPress;
  };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    mapStateToProps,
    (dispatch: Dispatch<IMapDispatchToProps>): IMapDispatchToProps => ({
      setElementQuantityByOfferId: (item: ISetElementQuantityByOfferIdPayload): void => {
        dispatch(setElementQuantityByOfferIdAction(item));
      },
      setSelectedItemBakingModal: (): void => {
        dispatch(setSelectedItemBakingModalAction(null));
      },
    }),
    mergeProps
  ),
  withNavigation,
  withApollo
)(BakingModal);
