import { ApolloQueryResult } from 'apollo-client';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { graphql } from 'react-apollo/graphql';
import { DataProps, OperationVariables, OptionProps, QueryOpts } from 'react-apollo/types';
import { compose, withHandlers } from 'recompose';

import { deepMerge } from '../../lib/lodashHelper';
import { IPaginateWithInfo } from '../../types/common';
import { ITransaction, IUserResult } from '../../types/getTransactions';

import TransactionsCard, { IProps } from './TransactionsCard.component';

const getTransactions = loader('../../queries/getTransactions.gql');

export const TRANSACTIONS_PAGE_SIZE = 20;

type IOnEndReached = () => void;

const withOnEndReachedHandler = (ownProps: IGraphQLProps): IOnEndReached =>
  throttle((): void => {
    const transactionsData: IPaginateWithInfo<ITransaction> = get(
      ownProps,
      'data.getUser.guests.edges[0].node.transactions'
    );

    if (!transactionsData || !transactionsData.pageInfo.hasNextPage) {
      return;
    }

    // @ts-ignore
    ownProps.data.fetchMore({
      updateQuery: (
        previousResult: IUserResult,
        { fetchMoreResult }: { fetchMoreResult?: IUserResult }
      ): IUserResult => {
        const previousResultTransactions: ITransaction[] =
          get(previousResult, 'getUser.guests.edges[0].node.transactions.edges') || [];

        if (!fetchMoreResult || previousResultTransactions.length > ownProps.transactions.length) {
          return previousResult;
        }

        return {
          ...previousResult,
          getUser: {
            ...previousResult.getUser,
            guests: {
              ...previousResult.getUser.guests,
              edges: [
                deepMerge(
                  previousResult.getUser.guests.edges[0],
                  fetchMoreResult.getUser.guests.edges[0]
                ),
              ],
            },
          },
        };
      },
      variables: {
        after: transactionsData.pageInfo.endCursor,
        first: TRANSACTIONS_PAGE_SIZE,
      },
    });
  }, 500);

export interface IGraphQLProps extends DataProps<IUserResult> {
  hasNextPage?: boolean;
  isLoading?: boolean;
  refetch: (variables?: OperationVariables) => Promise<ApolloQueryResult<IUserResult>>;
  transactions: ITransaction[];
}

export type IConnectedProps = IGraphQLProps & { onEndReached: IOnEndReached };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  graphql(getTransactions, {
    options: (): QueryOpts => ({
      variables: {
        first: TRANSACTIONS_PAGE_SIZE,
      },
    }),
    props: (props: OptionProps<{}>): IGraphQLProps => ({
      data: props.data!,
      hasNextPage: get(
        props,
        'data.getUser.guests.edges[0].node.transactions.pageInfo.hasNextPage'
      ),
      isLoading: props.data!.loading,
      refetch: get(props, 'data.refetch'),
      transactions: get(props, 'data.getUser.guests.edges[0].node.transactions.edges') || [],
    }),
  }),
  withHandlers({ onEndReached: withOnEndReachedHandler })
)(TransactionsCard);
