import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { ActionCreator } from 'redux';

import withNavigation from '../../hoc/withNavigation';
import { ISetGuestTokenIdAction, setGuestTokenId } from '../../redux/signUp/actions';
import { ISearchGuestResult } from '../../types/searchGuest';

import SignUpIdentityForm, { IProps } from './SignUpIdentityForm.component';

const searchGuest = loader('../../queries/searchGuest.gql');

interface IDispatchProps {
  setGuestTokenId: ActionCreator<ISetGuestTokenIdAction>;
}

const mapDispatchToProps: IDispatchProps = {
  setGuestTokenId,
};

type ISearchGuestQuery = (
  badgeNumber: string,
  lastName: string,
  holdingId: string
) => Promise<FetchResult>;

const searchGuestHandler = (props: WithApolloClient<{}>): ISearchGuestQuery => async (
  badgeNumber: string,
  lastName: string,
  holdingId: string
): Promise<{}> =>
  props.client.query<ISearchGuestResult>({
    fetchPolicy: 'network-only',
    query: searchGuest,
    variables: { badgeNumber, idHolding: holdingId, lastName },
  });

export type IConnectedProps = NavigationInjectedProps &
  IDispatchProps & { searchGuest: ISearchGuestQuery } & WithApolloClient<{}>;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    null,
    mapDispatchToProps
  ),
  withNavigation,
  withApollo,
  withHandlers({ searchGuest: searchGuestHandler })
)(SignUpIdentityForm);
