import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View } from 'react-native';

import theme from '../../../theme';

export interface IProps {
  details?: string;
  detailsColor?: string;
  title: string;
}

type IDefaultProps = Required<Pick<IProps, 'detailsColor'>>;

class OrderDetailsSection extends PureComponent<IProps> {
  public static defaultProps: IDefaultProps = {
    detailsColor: theme.colors.deprecatedBlack,
  };

  public render(): ReactNode {
    const { details, detailsColor, title } = this.props;

    return (
      <View>
        <Text style={styles.title}>{title}</Text>
        {details && (
          <Text style={[styles.details, { color: detailsColor }]}>{details}</Text>
        )}
      </View>
    );
  }
}

export interface IStyle {
  details: TextStyle;
  title: TextStyle;
}

const styles = StyleSheet.create({
  details: {
    ...theme.fonts.semiBoldText,
    marginTop: theme.margins.cardUnit,
  },
  title: {
    ...theme.fonts.cardText,
    color: theme.colors.gray,
  },
});

export default OrderDetailsSection;
