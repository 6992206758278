import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../hoc/withNavigation/withNavigation';

import TabBar, { IProps } from './TabBar.component';

type IConnectedProps = NavigationInjectedProps;
type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withNavigation)(TabBar);
