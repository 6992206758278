import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

import theme from '../../../theme';
import Amount from '../../Amount';

export interface IProps {
  amount: string;
  isActive?: boolean;
  onPress?: () => void;
  testID?: string;
}

class TopUpAmountButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { amount, isActive, onPress, testID } = this.props;

    const containerStyle = [styles.container, isActive ? styles.containerActive : {}];

    return (
      <TouchableOpacity style={containerStyle} onPress={onPress} testID={testID}>
        <Amount textAmount={amount} style={styles.textAmount} />
      </TouchableOpacity>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  containerActive: ViewStyle;
  textAmount: TextStyle;
}

const BORDER_RADIUS = 5;
const BORDER_WIDTH = 1;
const HEIGHT = 74;

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    borderColor: theme.colors.border,
    borderRadius: BORDER_RADIUS,
    borderWidth: BORDER_WIDTH,
    flex: 1,
    height: HEIGHT,
    justifyContent: 'center',
  },
  containerActive: {
    borderColor: theme.colors.green,
  },
  textAmount: {
    ...theme.fonts.mediumAmount,
  },
});

export default TopUpAmountButton;
