import React, { PureComponent, ReactNode } from 'react';
import { View } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import LoadingView from '../../components/LoadingView';
import Auth from '../../services/authentication';
import { getUserSignUpParams } from '../../services/navigation/params';

export interface IProps extends NavigationInjectedProps {
  onError: () => void;
}

class SignIn extends PureComponent<IProps> {
  public componentDidMount(): void {
    const { email } = getUserSignUpParams(this.props.navigation);
    const loginConfig = {
      email,
      onError: this.props.onError,
    };
    Auth.login(loginConfig);
  }

  public render(): ReactNode {
    return (
      <View>
        <LoadingView isLoading />
      </View>
    );
  }
}

export default SignIn;
