import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../../theme';
import Icon from '../../Icon';
import Touchable from '../../Touchable';

const BORDER_RADIUS = 4;
const BUTTON_SIZE = 30;
const BUTTON_SIZE_MINI = 24;
const BUTTON_SIZE_BIG = 40;
const ICON_SIZE = 16;
const ICON_SIZE_MINI = 10;

export interface IProps {
  max: number;
  min: number;
  onCounterPress: (quantity: number) => () => void;
  status: 'DECREMENT' | 'INCREMENT';
  value: number;
  size?: 'mini' | 'big';
  variant?: 'GREEN' | 'BLACK';
  disableCounter?: boolean;
  alwaysShowDecrement?: boolean;
  testID?: string;
}

class CounterButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { status, size, disableCounter, variant = 'GREEN', alwaysShowDecrement, testID } = this.props;
    const disabled = this.isDisabled(this.props.value) || disableCounter;

    if (!alwaysShowDecrement && disabled && status === 'DECREMENT') {
      return <View />;
    }

    const disabledStyle = disabled
      ? {
          backgroundColor: theme.colors.blueGray,
          borderColor: theme.colors.blueGray,
        }
      : {};
    const minusButtonStyle = variant === 'GREEN' ? styles.minusButton : styles.minusButtonBlack;
    const plusButtonStyle = variant === 'GREEN' ? styles.plusButton : styles.plusButtonBlack;
    const signButtonStyle = status === 'DECREMENT' ? minusButtonStyle : plusButtonStyle;
    const iconColor = (() => {
      if (disabled) {
        return theme.colors.white;
      }
      if (variant === 'BLACK') {
        return theme.colors.black;
      }
      if (status === 'DECREMENT') {
        return theme.colors.validate;
      }
      return theme.colors.white;
    })();
    const buttonSize = size === 'mini' ? BUTTON_SIZE_MINI : size === 'big' ? BUTTON_SIZE_BIG : BUTTON_SIZE;
    return (
      <View
        style={{
          height: buttonSize,
          width: buttonSize,
          marginRight: (status === 'DECREMENT') ? 5 : 0,
          marginLeft: (status === 'DECREMENT') ? 0 : 5
        }}
      >
        <Touchable
          testID={testID}
          style={[styles.button, signButtonStyle, disabledStyle]}
          onPress={(): void => this.click(this.props.value)}
          disabled={disabled || disableCounter}
        >
          <Icon
            name={status === 'DECREMENT' ? 'minus' : 'plus'}
            tintColor={iconColor}
            size={size === 'mini' ? ICON_SIZE_MINI : ICON_SIZE}
          />
        </Touchable>
      </View>
    );
  }

  private click = (value: number): void => {
    const newValue =
      this.props.status === 'DECREMENT'
        ? Math.max(value - 1, this.props.min)
        : Math.min(value + 1, this.props.max);

    this.props.onCounterPress(newValue);
  };

  private isDisabled = (value: number): boolean =>
    this.props.status === 'DECREMENT' ? value <= this.props.min : value >= this.props.max;
}

export interface IStyle {
  button: ViewStyle;
  buttonContainer: ViewStyle;
  signButton: ViewStyle;
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: BORDER_RADIUS,
    flex: 1,
    justifyContent: 'center',
  },
  minusButton: {
    borderColor: theme.colors.validate,
    borderWidth: 1,
  },
  minusButtonBlack: {
    borderColor: theme.colors.black,
    borderWidth: 1,
  },
  plusButton: {
    backgroundColor: theme.colors.validate,
  },
  plusButtonBlack: {
    borderColor: theme.colors.black,
    borderWidth: 1,
  },
});

export default CounterButton;
