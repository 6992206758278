import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, ViewStyle, Text, RefreshControl, View } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import AppPage from '../AppPage';
import ArticlesList, { DisplayedArticleFamily } from '../ArticlesList';
import LoadingView from '../LoadingView';
import {
  TableServiceQuantityFooter,
  TableServiceFooterType,
  ClickAndCollectQuantityFooter,
  ClickAndCollectFooterType,
  BundleFooterType,
} from '../QuantityFooter';
import theme from '../../theme';
import BakingModal from '../BakingModal';
import {
  getOffer_offer_Offer as IOffer,
  getOffer_offer_Offer_offerTemplate_OfferTemplate_mealHeartRule as IMealHeartRule,
} from '../../types/clickandcollect/getOffer';
import { OfferTemplateSalesType } from '../../types/clickandcollect/globalTypes';
import { skipBeverage } from '../../services/offer';
import Logger from '../../lib/logger';
import I18n from '../../lib/i18n';
import Button from '../Button';

export interface INextOrderableOffer {
  id: string;
  published: boolean;
  orderRange: string;
  withdrawRange: string;
}

export interface IProps extends NavigationInjectedProps {
  isLoading: boolean;
  isOffersLoading: boolean;
  offer: IOffer;
  offerId: string;
  articleFamilyKeys: DisplayedArticleFamily[];
  idGuest: string;
  numColumns?: number;
  showQuantityInFooter?: boolean;
  footerType: BundleFooterType | ClickAndCollectFooterType | TableServiceFooterType;
  refetchOffers?: () => Promise<void>;
  refetchOffer?: () => Promise<void>;
  refetchNextOrderableOffers?: () => Promise<void>;
  maxSameProduct?: number;
  productsInCart: [];
  nextOrderableOffers: INextOrderableOffer[];
  tableNumber?: string;
}

export interface IState {
  isRefreshing: boolean;
  noStockItems: [] | null;
  hasStock: boolean;
  selectedOfferId: string | null;
}

class OfferArticles extends PureComponent<IProps, IState> {
  public state: IState = {
    isRefreshing: false,
    noStockItems: null,
    hasStock: true,
    selectedOfferId: null,
  };

  public componentDidMount(): void {
    this.setState({ selectedOfferId: this.props?.offer?.id });
  }

  public componentDidUpdate(): void {
    if (this.state.noStockItems && !this.state.isRefreshing && this.state.hasStock) {
      this.setState({ hasStock: false });
      this.onRefresh();
    } else if (this.state.selectedOfferId !== this.props?.offer?.id) {
      const { refetchNextOrderableOffers, offer } = this.props;
      this.setState({ selectedOfferId: offer?.id });
      refetchNextOrderableOffers && refetchNextOrderableOffers();
    }
  }

  public render(): ReactNode {
    const {
      offer,
      articleFamilyKeys,
      offerId,
      numColumns,
      showQuantityInFooter,
      footerType,
      productsInCart,
      nextOrderableOffers,
      tableNumber,
      idGuest
    } = this.props;
    const currentOffer =
      nextOrderableOffers && nextOrderableOffers.find(({ id }) => id === offerId);

    const hasArticlesWithContainerOnCart = productsInCart?.some((product) => !!product.container);

    const reachedMinAllowedCategory = (mealHeartRules: IMealHeartRule): boolean => {
      if (!mealHeartRules) {
        return false;
      }

      if (!mealHeartRules.selectedFamilies.length) {
        return false;
      }

      const { min: minProductsPerCategory } = mealHeartRules.quantityRules.find(
        prop => prop.property === 'mealQuantity'
      );

      const quantityOfProductsInRestrictedCategory = productsInCart
        .filter(p => mealHeartRules.selectedFamilies.includes(p.family))
        .reduce((total, p) => total + p.quantity, 0);

      return quantityOfProductsInRestrictedCategory < minProductsPerCategory;
    };

    return (
        <LoadingView isLoading={this.handleLoadings()}>
          <ArticlesList
            numColumns={numColumns}
            showQuantityInFooter={showQuantityInFooter}
            articleFamilyKeys={articleFamilyKeys}
            offer={offer}
            isLoading={this.handleLoadings()}
            refreshControl={
              <RefreshControl refreshing={this.handleLoadings()} onRefresh={this.onRefresh} />
            }
          />
          {offer ? (
            offer.offerTemplate.__typename === 'TableServiceOfferTemplate' ? (
              <TableServiceQuantityFooter
                testID="offerArticlesPage_tableServiceFooter"
                idOffer={offerId}
                footerType={footerType as TableServiceFooterType}
                shouldSkipBeverage={skipBeverage(offer)}
                setItems={noStockItems => {
                  this.setState({ noStockItems });
                }}
              />
            ) : (
              <ClickAndCollectQuantityFooter
                hasArticleWithContainer={hasArticlesWithContainerOnCart}
                withdrawalType={offer?.offerTemplate?.withdrawalType}
                tableNumber={tableNumber}
                testID="offerArticlesPage_clickAndCollectFooter"
                idOffer={offerId}
                offer={offer}
                idGuest={idGuest}
                footerType={footerType as ClickAndCollectFooterType}
                setItems={noStockItems => {
                  this.setState({ noStockItems });
                }}
                noReachedMinQuantityForCategory={
                  offer && productsInCart
                    ? reachedMinAllowedCategory(offer.offerTemplate.mealHeartRule)
                    : false
                }
              />
            )
          ) : null}
          {currentOffer && !currentOffer?.published ? (
            <Text style={styles.errorText}>
              {I18n.t('dashboard.eat.clickAndCollect.noOffers.posClosed')}
            </Text>
          ) : null}
          <BakingModal offerId={offerId} />
          {!this.state.hasStock ? (
            <View style={styles.boxOutOfStock}>
              <Text style={styles.boxTitle}>
                {I18n.t('dashboard.eat.clickAndCollect.noStock.title')}
              </Text>
              <Text style={styles.boxSubTitle}>
                {this.handleMessageOutOfStock(this.state.noStockItems)}
              </Text>
              <Button
                testID="offerArticlesPage_noStockButton"
                style={styles.buttonStyle}
                textStyle={styles.buttonTextStyle}
                text={I18n.t('dashboard.eat.clickAndCollect.noStock.button')}
                onPress={this.closeView}
              />
            </View>
          ) : null}
        </LoadingView>
    );
  }
  private onRefresh = async (): Promise<void> => {
    const { refetchOffers, refetchOffer, refetchNextOrderableOffers } = this.props;
    if (!refetchOffer && !refetchOffers && !refetchNextOrderableOffers) return;
    this.setState({ isRefreshing: true });

    try {
      refetchNextOrderableOffers && (await refetchNextOrderableOffers());
      refetchOffer && (await refetchOffer());
      refetchOffers && (await refetchOffers());
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isRefreshing: false });
    }
  };

  private handleMessageOutOfStock = (items): string => {
    const { offer } = this.props;
    const outOfStockProducts = [];
    items.forEach(item => {
      const newFilter = offer?.offerItems.find(offerItem => offerItem.id === item.idOfferItem);
      outOfStockProducts.push(newFilter?.inheritedLabel);
    });

    return I18n.t('dashboard.eat.clickAndCollect.noStock.subtitle', {
      products: outOfStockProducts.join(', '),
    });
  };

  private closeView = (): void => {
    const { navigation, offer } = this.props;
    this.setState({
      hasStock: true,
      noStockItems: null,
    });
    if (offer.offerTemplate.salesType === OfferTemplateSalesType.FREE_SALE) {
      if (
        offer.offerTemplate.nextOrderableOffer.mealHeartOrderAvailable &&
        !offer.offerTemplate.nextOrderableOffer.isMealHeartRuleFullfilled
      ) {
        navigation.goBack();
      }
    }
    this.onRefresh();
  };

  private handleLoadings = () => {
    return this.state.isRefreshing || this.props.isLoading || this.props.isOffersLoading;
  };
}

export interface IStyle {
  page: ViewStyle;
  card: ViewStyle;
}

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    margin: theme.margins.unit,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  cardText: {
    color: theme.colors.grayMedium,
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0,
  },
  boxOutOfStock: {
    padding: '4%',
    width: '100%',
    borderRadius: 4,
    backgroundColor: theme.colors.white,
    position: 'absolute',
    bottom: 0,
    minHeight: '20%',
  },
  boxTitle: {
    marginTop: '3%',
    color: theme.colors.black,
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 19,
  },
  boxSubTitle: {
    width: '87%',
    marginTop: '3%',
    color: theme.colors.grayMedium,
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: 21,
  },
  buttonStyle: {
    marginTop: '10%',
    width: '99%',
    borderRadius: 4,
    backgroundColor: theme.colors.grayLightMedium,
    alignSelf: 'center',
  },
  buttonTextStyle: {
    color: theme.colors.textWhite,
    fontFamily: 'Open Sans',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 24,
    textAlign: 'center',
  },
  errorText: {
    marginTop: 40,
    marginHorizontal: 20,
    color: theme.colors.black,
    fontFamily: 'Open Sans',
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'left',
  },
});

export default OfferArticles;
