import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import theme from '../../theme';

import Button, { IProps as IButtonProps } from '../Button';

type ITheme = 'black' | 'blue' | 'white' | 'gray';

export const BUTTON_THEME: { [key in ITheme]: key } = {
  black: 'black',
  blue: 'blue',
  white: 'white',
  gray: 'gray',
};

export interface IProps extends IButtonProps {
  theme?: ITheme;
}

class GhostButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { style, ...rest } = this.props;

    let color;
    switch (this.props.theme) {
      case BUTTON_THEME.black:
        color = theme.colors.textBlack;
        break;
      case BUTTON_THEME.blue:
        color = theme.colors.blue;
        break;
      case BUTTON_THEME.gray:
        color = theme.colors.grayLight;
        break;
      default:
        color = theme.colors.white;
    }

    return (
      <Button
        style={[styles.button, { borderColor: color }, style]}
        textStyle={{ color }}
        borderless
        useForeground
        {...rest}
      />
    );
  }
}

export interface IStyle {
  button: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  button: {
    backgroundColor: 'transparent',
    borderWidth: 2,
  },
});

export default GhostButton;
