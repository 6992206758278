import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';

import theme from '../../theme';
import Touchable, { IProps as ITouchableProps } from '../Touchable';

export interface IProps extends ITouchableProps {
  onPress?: () => void;
  title: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<ViewStyle>;
  useOpacity?: boolean;
  color?: string;
  testID?: string;
}

class ClearButton extends PureComponent<IProps, {}> {
  public render(): ReactNode {
    const { title, style, useOpacity, color = theme.colors.textBlack, testID } = this.props;

    return (
      <Touchable
        testID={testID}
        style={[styles.container, style]}
        onPress={this.props.onPress}
        useOpacity={useOpacity}
      >
        <Text style={[styles.text, { color }]}>{title?.toUpperCase()}</Text>
      </Touchable>
    );
  }
}

export interface IStyle {
  container: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    paddingTop: theme.margins.cardUnit * 2,
    paddingRight: theme.margins.cardUnit * 2,
    paddingBottom: theme.margins.cardUnit * 2,
  },
  text: {
    ...theme.fonts.cardAction,
  },
});

export default ClearButton;
