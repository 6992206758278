import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { IAppState } from '../../redux/reducer.web';
import { isTabActive } from '../../redux/router.web';

import TabBarIcon, { IProps } from './TabBarIcon.component';

interface IMapStateToProps {
  isActive: boolean;
}

const mapStateToProps = (state: IAppState, ownProps: IExternalProps): IMapStateToProps => ({
  isActive: isTabActive(state, ownProps),
});

type IOnPress = () => void;

const onPressHandler = (props: IExternalProps & NavigationInjectedProps): IOnPress => (): void => {
  props.navigation.navigate(props.routeName);
};

export interface IExternalProps {
  routeName: string;
}
export type IConnectedProps = IMapStateToProps & NavigationInjectedProps & { onPress: IOnPress };
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps),
  withNavigation,
  withHandlers({ onPress: onPressHandler })
)(TabBarIcon);
