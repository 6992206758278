import { ApolloQueryResult } from 'apollo-client';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OperationVariables, OptionProps, QueryOpts } from 'react-apollo/types';
import { compose } from 'recompose';

import { POLL_INTERVAL } from '../../../apollo/constants';
import { IGetGuestForBalanceContentResult } from '../../../types/getGuestForBalanceContent';
import { IPollable } from '../../../types/pollable';
import { TRANSACTIONS_PAGE_SIZE } from '../../TransactionsCard/TransactionsCard.container';

import TransactionList, { IProps } from './TransactionsList.component';

const getGuestForBalanceContent = loader('../../../queries/getGuestForBalanceContent.gql');

export interface IGraphQLProps {
  refetch?: (
    variables?: OperationVariables
  ) => Promise<ApolloQueryResult<IGetGuestForBalanceContentResult>>;
}

type IContainerProps = Omit<IProps & IPollable, keyof 'refetch'>;

export default compose<IProps, IContainerProps>(
  graphql(getGuestForBalanceContent, {
    options: (props: IPollable): QueryOpts => ({
      pollInterval: props.isPollable ? POLL_INTERVAL : undefined,
      variables: {
        first: TRANSACTIONS_PAGE_SIZE,
      },
    }),
    props: (props: OptionProps<{}>): IGraphQLProps => ({
      refetch: get(props, 'data.refetch'),
    }),
  })
)(TransactionList);
