import { compose, withProps } from 'recompose';

import withGuestPaymentProfile, {
  IWithGuestPaymentProfile,
} from '../../../hoc/withGuestPaymentProfile';
import withNavigation from '../../../hoc/withNavigation';
import { getPosTypeActions, IPosCardTypeActions } from '../../../services/pointOfSaleMapper';
import { isAllowedToOrder } from '../../../services/guest';
import { IPosType } from '../../../types/common';
import { IActiveOfferTemplate, IBookingOfferTemplate } from '../../../types/getPointsOfSale';

import PosCardFooter, { IProps } from './PosCardFooter.component';

interface IWithActionsProps {
  actions: IPosCardTypeActions;
}

export interface INavigateToOfferPageAction {
  callback: () => void;
}

const withActionsProps = (props: IExternalProps & IWithGuestPaymentProfile): IWithActionsProps => {
  return {
    actions: getPosTypeActions(props.posType, props.posId, {
      content: props.content,
      bookingOfferTemplate: props.bookingOfferTemplate,
      activeOfferTemplates: props.activeOfferTemplates,
      isGuestAllowedToOrder: isAllowedToOrder({
        managementMode: props.managementMode,
      }),
    }),
  };
};

export interface IExternalProps {
  content?: string;
  posId: string;
  posType: IPosType;
  activeOfferTemplates?: IActiveOfferTemplate[];
  bookingOfferTemplate?: IBookingOfferTemplate[];
}
export type IConnectedProps = IWithActionsProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withGuestPaymentProfile,
  withProps(withActionsProps)
)(PosCardFooter);
