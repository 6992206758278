import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { IPosStatus, POS_STATUS } from '../../../constants';

import theme from '../../../theme';
import { IPosSchedule } from '../../../types/common';
import PosSchedule from '../../PosSchedule';
import PosStatus from '../../PosStatus';

export interface IProps {
  borderless?: boolean;
  schedules: IPosSchedule[];
  status: IPosStatus;
}

class PosCardBanner extends PureComponent<IProps> {
  public render(): ReactNode {
    const { borderless, status } = this.props;

    return (
      <View style={[styles.container, borderless ? null : styles.containerBorder]}>
        <PosStatus status={status} />
        <View style={styles.detailsContainer}>{this.displayContent()}</View>
      </View>
    );
  }

  private displayContent = (): ReactNode | null => {
    const { schedules, status } = this.props;
    const shouldDisplaySchedule = status !== POS_STATUS.UNKNOWN;

    return shouldDisplaySchedule ? <PosSchedule schedules={schedules} /> : null;
  };
}

interface IStyle {
  container: ViewStyle;
  containerBorder: ViewStyle;
  detailsContainer: ViewStyle;
}

const POS_CARD_BANNER_RADIUS = 6;
const POS_CARD_BANNER_BORDER_WIDTH = 1;

const styles = StyleSheet.create<IStyle>({
  container: {
    backgroundColor: theme.colors.background,
    borderRadius: POS_CARD_BANNER_RADIUS,
    flexDirection: 'row',
    marginBottom: theme.margins.unit,
    marginHorizontal: theme.margins.cardUnit * 2,
    padding: theme.margins.unit,
  },
  containerBorder: {
    borderColor: theme.colors.border,
    borderWidth: POS_CARD_BANNER_BORDER_WIDTH,
  },
  detailsContainer: {
    alignItems: 'flex-end',
    flex: 1,
  },
});

export default PosCardBanner;
