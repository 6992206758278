import get from 'lodash/get';
import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers, withState, withProps } from 'recompose';
import { ActionCreator } from 'redux';
import withNavigation from '../../hoc/withNavigation';
import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IResetSlotForOfferAction, resetSlotForOffer } from '../../redux/clickAndCollect/actions';
import { getSlotByOfferId } from '../../redux/clickAndCollect/selectors';
import { IAppState } from '../../redux/reducer';
import BookingFooter, { IProps as IComponentProps } from './BookingFooter.component';
import I18n from '../../lib/i18n';
import { ApolloError } from 'apollo-client';

const createBookingOrder = loader('../../queries/bookingservice/createBookingOrder.gql');
const getUserInfo = loader('../../queries/getUserInfo.gql');
const updateBookingOrderSlot = loader('../../queries/bookingservice/updateBookingOrderSlot.gql');

interface IProps {
  idOffer: string;
  idOrder: string;
  posId: string;
  activeIndex: number;
  isUpdating: boolean;
}

interface IMapStateToProps {
  idGuest?: string;
  idSlot: string | null;
  token: string;
}

interface IMapDispatchToProps {
  resetSlotForOffer: ActionCreator<IResetSlotForOfferAction>;
}

interface ILoadingProps {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

interface IWithExtraProps {
  title: string;
  enabled: boolean;
  onPress: () => void;
}

interface IErrorProps {
  error: {} | null;
  setError: (error: {} | null) => void;
}

type ICreateBookingOrderMutation = (idOffer: string, idSlot?: string) => Promise<FetchResult>;
type IUpdateBookingOrderMutation = (idOrder: string, idSlot: any) => Promise<FetchResult>;

type IConnectedProps = IMapStateToProps &
  IMapDispatchToProps &
  NavigationInjectedProps &
  WithApolloClient<{}> & {
    createBookingOrder: ICreateBookingOrderMutation;
    updateBookingOrder: IUpdateBookingOrderMutation;
  };

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => ({
  idSlot: getSlotByOfferId(state, ownProps.idOffer),
  token: selectAuthenticationToken(state),
});

const mapDispatchToProps: IMapDispatchToProps = {
  resetSlotForOffer,
};

const createBookingOrderHandler = ({
  client,
  idGuest,
  setError,
}: IConnectedProps & IErrorProps): ICreateBookingOrderMutation => async (
  idOffer: string,
  idSlot?: string
): Promise<{}> => {
    return client
      .mutate({
        mutation: createBookingOrder,
        variables: {
          input: {
            idGuest,
            idOffer,
            withdrawRange: idSlot,
          },
        },
      })
      .catch((error: ApolloError) => {
        setError(error);
        throw error;
      });
  };

const updateBookingOrderHandler = ({
  client,
  setError,
}: IConnectedProps & IErrorProps): IUpdateBookingOrderMutation => async (
  idOrder?: string,
  idSlot?: string
): Promise<{}> => {
    return client
      .mutate({
        mutation: updateBookingOrderSlot,
        variables: {
          input: {
            idOrder,
            withdrawRange: idSlot,
          },
        },
      })
      .catch((error: ApolloError) => {
        setError(error);
        throw error;
      });
  };

const withExtraProps = ({
  idOffer,
  idOrder,
  idSlot,
  isUpdating,
  posId,
  activeIndex,
  navigation,
  createBookingOrder: createBookingOrderMutation,
  updateBookingOrder: updateBookingOrderMutation,
}: IProps & ILoadingProps & IConnectedProps): IWithExtraProps => {
  const enabled = !!idSlot;
  const onPress = async (): Promise<void> => {
    if (idSlot) {
      isUpdating ? await updateBookingOrderMutation(idOrder, idSlot) : await createBookingOrderMutation(idOffer, idSlot);
      navigation.replace('bookingService', { posId, activeIndex });
    }
  };
  return {
    title: enabled
      ? I18n.t('dashboard.eat.bookingService.orderButton')
      : I18n.t('dashboard.eat.bookingService.slotChoice.slotButton'),
    onPress,
    enabled,
  };
};

export interface IGraphQLProps {
  idGuest: string;
}

export default compose<IComponentProps, IProps>(
  withState('isLoading', 'setLoading', false),
  withState('error', 'setError', null),
  withNavigation,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  graphql(getUserInfo, {
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      idGuest: get(props, 'data.getUser.guests.edges[0].node.id') || '',
    }),
  }),
  withApollo,
  withHandlers({ createBookingOrder: createBookingOrderHandler, updateBookingOrder: updateBookingOrderHandler }),
  withProps(withExtraProps)
)(BookingFooter);
