import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import OrderWithdrawalLockerMethodCard, { IProps } from './OrderWithdrawalLockerMethodCard.component';
import withNavigation from '../../../hoc/withNavigation';


export interface IWithGoToLockerQRCodePage {
  goToLockerQRCodePage: () => void;
}

const withGoToLockerQRCodePage = (props: IProps & NavigationInjectedProps): IWithGoToLockerQRCodePage => ({
  goToLockerQRCodePage: (): void => {

    const { order, navigation } = props;

    navigation.navigate('orderQRCode', { idOrder: order.id });
  }
});

export type IContainerProps = Omit<IProps, keyof IWithGoToLockerQRCodePage>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withGoToLockerQRCodePage)
)(OrderWithdrawalLockerMethodCard);
