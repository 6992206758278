import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Switch, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  onValueChange: (value: boolean) => void;
  switchValue: boolean;
  title: string;
  testID?: string;
  disabled?: boolean;
}

class SwitchItem extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.switchItem}>
        <View style={styles.text}>
          <Text style={styles.title}>{this.props.title}</Text>
        </View>
        <Switch
          testID={this.props.testID}
          disabled={this.props.disabled}
          style={styles.switch}
          onValueChange={this.props.onValueChange}
          value={this.props.switchValue}
        />
      </View>
    );
  }
}

export interface IStyle {
  switch: ViewStyle;
  switchItem: ViewStyle;
  text: TextStyle;
  title: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  switch: {
    marginLeft: 4 * theme.margins.cardUnit,
  },
  switchItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginVertical: theme.margins.cardUnit,
  },
  text: {
    flex: 1,
  },
  title: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.textBlack,
  },
});

export default SwitchItem;
