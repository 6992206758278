import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import LogOutButton from './LogOutButton';

class ProfileFooter extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <LogOutButton />
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default ProfileFooter;
