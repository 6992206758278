import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import moment from 'moment';
import { SectionListData } from 'react-native';
import base64 from 'base-64';
import { decode } from 'html-entities';

import { INode } from '../types/common';
import { ITransaction } from '../types/getTransactions';

export type IMonth = string;

export const getTransactionMonth = (transactionNode: INode<ITransaction>): IMonth =>
  moment(transactionNode.node.date).format('MMMM YYYY');

export type ITransactions = Array<INode<ITransaction>>;

export interface ITransactionsByMonth {
  [key: string]: ITransactions;
}

export type ITransactionsListData = SectionListData<INode<ITransaction>>;

export const transformTransactionsByMonthToSectionListData = (
  transactionsByMonth: ITransactionsByMonth
): ITransactionsListData[] =>
  toPairs(transactionsByMonth).map(
    (transaction: [IMonth, ITransactions]): ITransactionsListData => ({
      data: transaction[1],
      title: transaction[0],
    })
  );

export const transformTransactionsToSectionListData = (
  transactions: ITransactions
): ITransactionsListData[] => {
  const transactionsByMonth: ITransactionsByMonth = groupBy(transactions, getTransactionMonth);

  return transformTransactionsByMonthToSectionListData(transactionsByMonth);
};

export const decodeAndFormatBase64Receipt = (base64Receipt: string): string => {
  const encodedReceipt = base64.decode(base64Receipt);

  return decode(encodedReceipt, { level: 'html5' });
};
