import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import StepperConnector from './StepperConnector';
import StepperItem, { IStepNumber } from './StepperItem';
import { IStepStatus, STATUS } from './utils';

export interface IProps {
  currentStepNumber: IStepNumber;
  currentStepStatus: IStepStatus;
}

class Stepper extends PureComponent<IProps> {
  public render(): ReactNode {
    const firstStatus = this.getStatus(1, this.props.currentStepNumber);
    const secondStatus = this.getStatus(2, this.props.currentStepNumber);
    const thirdStatus = this.getStatus(3, this.props.currentStepNumber);
    const fourthStatus = this.getStatus(4, this.props.currentStepNumber);

    return (
      <View style={styles.container}>
        <StepperItem stepNumber={1} status={firstStatus} />
        <StepperConnector fromStatus={firstStatus} toStatus={secondStatus} />
        <StepperItem stepNumber={2} status={secondStatus} />
        <StepperConnector fromStatus={secondStatus} toStatus={thirdStatus} />
        <StepperItem stepNumber={3} status={thirdStatus} />
        <StepperConnector fromStatus={thirdStatus} toStatus={fourthStatus} />
        <StepperItem stepNumber={4} status={fourthStatus} />
      </View>
    );
  }

  private getStatus = (step: IStepNumber, currentStepNumber: IStepNumber): IStepStatus => {
    if (step < currentStepNumber) {
      return STATUS.DONE;
    }
    if (step === currentStepNumber) {
      return this.props.currentStepStatus;
    }

    return STATUS.INACTIVE;
  };
}

const STEPPER_MARGIN_BOTTOM = 36;

export interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: STEPPER_MARGIN_BOTTOM,
  },
});

export default Stepper;
