import React, { PureComponent } from 'react';
import { Animated, Easing, ImageStyle, RegisteredStyle } from 'react-native';
import { AnimatedValue } from 'react-navigation';
import theme from '../../theme';

export interface IProps {
  style: ImageStyle | RegisteredStyle<ImageStyle>;
}

class VirtualTransactionIcon extends PureComponent<IProps> {
  animatedValue: AnimatedValue;
  constructor(props: IProps) {
    super(props);
    this.animatedValue = new Animated.Value(0);
    this.handleAnimation();
  }

  handleAnimation = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.animatedValue, {
          toValue: 1.0,
          duration: 600,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(this.animatedValue, {
          toValue: -1.0,
          duration: 1200,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(this.animatedValue, {
          toValue: 0.0,
          duration: 600,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ])
    ).start();
  };

  render() {
    const { style = {} } = this.props;
    return (
      <Animated.Image
        style={[
          style,
          {
            opacity: this.animatedValue.interpolate({
              inputRange: [-1, 0, 1],
              outputRange: [1, 0, 1],
            }),
          },
        ]}
        source={theme.images.euro}
        resizeMode="contain"
      />
    );
  }
}

export default VirtualTransactionIcon;
