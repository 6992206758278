import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  children?: ReactNode[] | ReactNode;
  style?: StyleProp<ViewStyle>;
  subtitle: string;
  title: string;
}

class Section extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={this.props.style}>
        <View style={styles.text}>
          <Text style={styles.title}>{this.props.title}</Text>
          <Text style={styles.subtitle}>{this.props.subtitle}</Text>
        </View>
        {this.props.children}
      </View>
    );
  }
}

export interface IStyle {
  subtitle: TextStyle;
  text: TextStyle;
  title: TextStyle;
}

const styles: IStyle = StyleSheet.create<IStyle>({
  subtitle: {
    ...theme.fonts.cardText,
    color: theme.colors.textGray,
    marginTop: theme.margins.cardUnit,
  },
  text: {
    marginBottom: 2 * theme.margins.cardUnit,
  },
  title: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.textBlack,
  },
});

export default Section;
