import React, { PureComponent, ReactNode } from 'react';

import AlertOpeningForm from '../../../../src/components/AlertOpeningForm';
import SubTitle from '../../../../src/components/SubTitle';
import I18n from '../../../../src/lib/i18n';
import theme from '../../../../src/theme';

import SignupLayout from '../../components/SignupLayout';

class HoldingNotAvailablePage extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <SignupLayout
        title={I18n.t('signUp.nearHolding.title')}
        currentStepNumber={1}
        currentStepStatus="ERROR"
      >
        <div style={styles.textContainer}>
          <SubTitle text={I18n.t('signUp.closeHolding.title')} />
        </div>
        <AlertOpeningForm />
      </SignupLayout>
    );
  }
}

const styles = {
  textContainer: {
    marginBottom: theme.margins.cardUnit,
  },
};

export default HoldingNotAvailablePage;
