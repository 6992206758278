import React, { PureComponent, ReactElement, ReactNode } from 'react';
import {
  Dimensions,
  FlatList,
  FlatListProps,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  Text,
  ViewStyle
} from 'react-native';

import I18n from '../../lib/i18n';
import { getFormattedMenu, ISection } from '../../services/menusFormatter';
import theme from '../../theme';
import { IPosMenuElement, IHolding, IPos } from '../../types/common';
import { POS_TYPE } from '../../constants';
import EmptyContentMessage from '../EmptyContentMessage';

import MenuSection from './MenuSection';
import { FONT_FAMILIES, FONT_WEIGHTS, fontMaker } from "../../theme/fonts";
import IconInformationBlueSVG from '../../theme/images/information-blue.svg'

export interface IProps extends Partial<FlatListProps<ISection>> {
  isOpen?: boolean;
  menuElements: IPosMenuElement[];
  holding: IHolding;
  pointOfSale?: IPos;
}

type IDefaultProps = Required<Pick<IProps, 'isOpen'>>;

class Menu extends PureComponent<IProps, {}> {
  constructor() {
    super();
    this.state = {
      currentIndexFlat: 0
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.handleOnPress = this.handleOnPress.bind(this);
  }

  public static defaultProps: IDefaultProps = {
    isOpen: true,
  };

  handleOnPress = (index: number) => {
    this.sectionListRef.scrollToIndex({
      animated: true,
      index,
    });
    this.setState({ currentIndexFlat: index })
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.menuElements != this.props.menuElements) {
      this.setState({ currentIndexFlat: 0 })
    }
  }

  renderHeader = ({ menuFormatted }) => {
    const handleRenderItem = ({ item, index }) => {
      const HeaderContainerStyle =
        index === this.state.currentIndexFlat ? styles.selectedFamily : styles.unselectedFamily;
      const HeaderTextStyle =
        index === this.state.currentIndexFlat ? styles.navigationBarEnabled : styles.navigationBarDisabled;
      return (
        <TouchableOpacity style={HeaderContainerStyle} onPress={() => this.handleOnPress(index)}>
          <Text style={[styles.navigationBar, HeaderTextStyle]}>{item}</Text>
        </TouchableOpacity>
      );
    };
    return (
      <View style={styles.headerContainer}>
        <FlatList
          horizontal
          data={menuFormatted.map(({ elementDishGroup }) => elementDishGroup)}
          renderItem={handleRenderItem}
          showsHorizontalScrollIndicator={false}
        />
      </View>
    );
  };

  public render(): ReactNode {
    const { menuElements, holding, pointOfSale } = this.props;
    const menuFormatted = getFormattedMenu(menuElements, holding);
    return (
      <View>
        <View style={styles.listContainer}>
          {menuFormatted.length === 0 ? this.renderEmptyList() : menuFormatted.map(eachMenu => this.renderItem({item: eachMenu}))}
        </View>

        {menuFormatted.length > 0 && pointOfSale?.type === POS_TYPE.SELF && (
          <View style={styles.infoContainer}>
            <IconInformationBlueSVG />
            <Text style={[styles.subTitle, styles.infoTextIcon]}>
              {I18n.t('pointOfSale.menu.warningText')}
            </Text>
          </View>
        )}
      </View>
    );
  }

  private renderEmptyList = (): ReactElement<View> => {
    const messageKey = this.props.isOpen ? 'pointOfSale.menu.empty' : 'pointOfSale.menu.closed';

    return (
      <View style={styles.emptyContentContainer}>
        <EmptyContentMessage text={I18n.t(messageKey)} />
      </View>
    );
  };

  private renderItem = ({ item }: { item: ISection }): ReactElement<View> => {
    return (
      <View style={styles.menuContainer}>
        <MenuSection section={item} holding={this.props.holding} />
      </View>
    );
  };
}

interface IStyle {
  emptyContentContainer: ViewStyle;
  contentContainer: ViewStyle;
  menuContainer: ViewStyle;
  headerContainer: ViewStyle;
  listContainer: ViewStyle;
  container: ViewStyle;
  infoContainer: ViewStyle;
  navigationBar: TextStyle;
  navigationBarEnabled: TextStyle;
  navigationBarDisabled: TextStyle;
  unselectedFamily: TextStyle;
  selectedFamily: TextStyle;
  subTitle: TextStyle;
  infoTextIcon: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  emptyContentContainer: {
    alignItems: 'center',
    minHeight: '100%',
    paddingHorizontal: theme.margins.cardUnit * 2,
    backgroundColor: theme.colors.grayLightAlternative,
  },
  menuContainer: {
    width: '100%',
  },
  infoContainer: {
    marginHorizontal: 10,
    marginVertical: 20,
    flexDirection: 'row',
  },
  contentContainer: { height: '100%' },
  headerContainer: {
    backgroundColor: theme.colors.grayLightAlternative,
    width: '100%'
  },
  navigationBar: {
    width: '100%',
    minHeight: 50,
    paddingLeft: theme.margins.padding + 4,
    paddingRight: theme.margins.padding,
    paddingTop: 16,
    paddingBottom: theme.margins.padding,
    fontSize: 14,
    lineHeight: 19,
    color: theme.colors.black,
    backgroundColor: theme.colors.grayLightAlternative,
  },
  navigationBarEnabled: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
  },
  navigationBarDisabled: {
    ...fontMaker(FONT_FAMILIES.openSans),
  },
  unselectedFamily: {
    borderBottomWidth: 7,
    borderBottomColor: '#D8D8D8',
  },
  selectedFamily: {
    borderBottomWidth: 7,
    borderBottomColor: theme.colors.validate
  },
  listContainer: {
    height: 'auto',
  },
  container: {
    height: Dimensions.get('screen').height * 0.55,
  },
  subTitle: {
    ...theme.fonts.regularText,
    fontSize: 14,
    lineHeight: 17,
    color: theme.colors.foodiBlack,
  },
  infoTextIcon: {
    marginLeft: 10,
    color: theme.colors.foodiBlack,
    width: '90%',
  },
});

export default Menu;
