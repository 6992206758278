import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import AppPage from '../../../../../components/AppPage';
import LargeButton from '../../../../../components/LargeButton';
import ModalHeader from '../../../../../components/ModalHeader';

import { CLOSE_MODAL_SIZE } from '../../../../../components/CloseModal/CloseModal.component';
import { LARGE_BUTTON_THEME } from '../../../../../components/LargeButton/LargeButton.component';
import I18n from '../../../../../lib/i18n';
import theme from '../../../../../theme';

interface IProps extends NavigationInjectedProps {
  goToEatPage: () => void;
}
class ConfirmCancelledOrder extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <AppPage
        noPadding
        headerComponent={
          <ModalHeader onClose={this.props.goToEatPage} style={styles.modalHeaderContainer}>
            <Text style={styles.title}>
              {I18n.t('dashboard.eat.clickAndCollect.confirmCancelledOrder.title')}
            </Text>
          </ModalHeader>
        }
        isModal
      >
        <View style={styles.contentContainer}>
          <View style={styles.bigContainer}>
            <View style={styles.container}>
              <Image source={theme.images.emptyBasket} style={styles.image} />
              <Text style={styles.cancelTitle}>
                {I18n.t('dashboard.eat.clickAndCollect.confirmCancelledOrder.title')}
              </Text>
              <Text style={styles.text}>
                {I18n.t('dashboard.eat.clickAndCollect.confirmCancelledOrder.subtitle')}
              </Text>
            </View>
            <LargeButton
              text={I18n.t('dashboard.eat.clickAndCollect.confirmCancelledOrder.button')}
              onPress={this.props.goToEatPage}
              theme={LARGE_BUTTON_THEME.success}
              style={styles.buttonStyle}
            />
          </View>
        </View>
      </AppPage>
    );
  }
}

interface IStyle {
  bigContainer: ViewStyle;
  buttonStyle: ViewStyle;
  cancelTitle: TextStyle;
  container: ViewStyle;
  contentContainer: ViewStyle;
  image: ImageStyle;
  modalHeaderContainer: ViewStyle;
  text: TextStyle;
  title: TextStyle;
}

const LOGO_WIDTH = 90;

const styles = StyleSheet.create<IStyle>({
  bigContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  buttonStyle: {
    width: '100%',
  },
  cancelTitle: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.textBlack,
    marginTop: 2 * theme.margins.cardUnit,
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 2 * theme.margins.cardUnit,
  },
  contentContainer: {
    backgroundColor: theme.colors.backgroundGray,
    flex: 1,
  },
  image: {
    width: LOGO_WIDTH,
  },
  modalHeaderContainer: {
    backgroundColor: theme.colors.white,
    paddingLeft: CLOSE_MODAL_SIZE,
  },
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.textGray,
    marginTop: 1.5 * theme.margins.cardUnit,
    textAlign: 'center',
  },
  title: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.deprecatedBlack,
  },
});

export default ConfirmCancelledOrder;
