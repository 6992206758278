import { StyleSheet, ViewStyle } from 'react-native';

import theme from '../../theme';

const BUTTON_MAX_WIDTH = 360;

export interface IStyle {
  button: ViewStyle;
  container: ViewStyle;
  imageContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  button: {
    alignSelf: 'center',
    maxWidth: BUTTON_MAX_WIDTH,
    width: '100%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: theme.margins.unit * 7,
  },
});

export default styles;
