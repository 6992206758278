import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import I18n from '../../lib/i18n';
import theme from '../../theme';
import { getOrder2_order_Order as IOrder } from '../../types/clickandcollect/getOrder2';

import CancelOrderModal from './../CancelOrderModal';

export interface IProps {
  onPress?: () => void;
  order?: IOrder;
}

export interface IState {
  isModalVisible: boolean;
}

class CancelOrderButton extends PureComponent<IProps, IState> {
  public state: IState = {
    isModalVisible: false,
  };

  public render(): ReactNode {
    return (
      <>
        <div style={divStyle} onClick={this.showModal}>
          <Text style={styles.text}>
            {I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancel')}
          </Text>
        </div>
        {this.props.order && (
          <CancelOrderModal
            onLeftButtonPress={this.hideModal}
            onRightButtonPress={this.hideModal}
            order={this.props.order}
            isVisible={this.state.isModalVisible}
          />
        )}
      </>
    );
  }
  private hideModal = (): void => {
    this.setState({ isModalVisible: false });
  };

  private showModal = (): void => {
    this.setState({ isModalVisible: true });
  };
}

const divStyle = {
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
};

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.errorDanger,
  },
});

export default CancelOrderButton;
