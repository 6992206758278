import React, { PureComponent, ReactNode } from 'react';
import { NavigationScreenProps } from 'react-navigation';

import AppPage from '../../../../components/AppPage';
import InfoRecipeDisclaimerForm from '../../../../components/InfoRecipeDisclaimerForm';
import theme from '../../../../theme';

class InfoRecipeDisclaimer extends PureComponent<NavigationScreenProps> {
  public render(): ReactNode {
    return (
      <AppPage backgroundColor={theme.colors.white} noPadding>
        <InfoRecipeDisclaimerForm />
      </AppPage>
    );
  }
}

export default InfoRecipeDisclaimer;
