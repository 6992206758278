import { StyleSheet } from 'react-native';

import theme from '../../../../../theme';

import { IStyle } from './PointsOfSaleListByType.component';

const styles = StyleSheet.create<IStyle>({
  contentContainer: {
    marginHorizontal: -theme.margins.unit,
  },
  emptyContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  itemContainer: {
    marginBottom: theme.margins.cardUnit * 2,
  },
  noFilterMargin: {},
});

export default styles;
