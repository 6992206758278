import React from 'react';
import { StyleSheet, Text, TextStyle, ViewStyle, View } from 'react-native';

import theme from '../../theme';

interface IWarningInfo {
  message: string;
}

export const WarningInfo: React.FC<IWarningInfo> = React.memo(({ message }) => {
  const WarningOrangeSVG = theme.images.warningOrangeSVG;

  if (!message) {
    return null;
  }

  return (
    <View style={styles.warningMessageContainer} activeOpacity={1}>
      <View>
        <WarningOrangeSVG />
      </View>
      <View style={styles.warningMessage}>
        <Text style={styles.warningMessageText}>{message}</Text>
      </View>
    </View>
  );
});

export interface IStyle {
  warningMessageContainer: ViewStyle;
  warningMessage: ViewStyle;
  warningMessageText: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  warningMessageContainer: {
    marginTop: 10,
    flexDirection: 'row',
    zIndex: -1,
    paddingRight: 5,
  },
  warningMessage: {
    marginLeft: '2%',
    marginRight: '4%',
    paddingRight: 8,
  },
  warningMessageText: {
    ...theme.fonts.regularText,
    fontSize: 13,
    color: theme.colors.black,
    lineHeight: 20,
  },
});

export default WarningInfo;
