import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import I18n from '../../../../../lib/i18n';
import theme from '../../../../../theme';

import Touchable from '../../../../../components/Touchable';

import { IPos } from '../../../../../types/common';

export interface IProps {
  onPress: () => void;
  pointOfSale: IPos;
  text: string;
}

class PointOfSaleTomorrow extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onPress, text } = this.props;

    return (
      <Touchable style={styles.container} onPress={onPress}>
        <View style={styles.imageContainer}>
            <Text style={styles.text}>{text}</Text>
          <Image
            resizeMode="contain"
            style={styles.backgroundImage}
            source={theme.images.tomorrowMenu}
          />
        </View>
        <Text style={styles.subTitle}>{I18n.t('pointOfSale.actions.seeCard')}</Text>
      </Touchable>
    );
  }
}

interface IStyle {
  backgroundImage: ImageStyle;
  container: ViewStyle;
  imageContainer: ViewStyle;
  subTitle: TextStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  backgroundImage: {
    alignSelf: 'flex-end',
    height: '100%',
    position: 'relative',
    width: '40%',
  },
  container: {
    backgroundColor: '#90E2FA',
    flex: 1,
    justifyContent: 'space-between',
    marginHorizontal: theme.margins.unit,
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subTitle: {
    alignSelf: 'flex-end',
    color: theme.colors.deprecatedBlack,
    marginRight: theme.margins.cardUnit * 3,
    marginVertical: theme.margins.cardUnit * 1,
    ...theme.fonts.buttonText,
  },
  text: {
    color: theme.colors.textWhite,
    ...theme.fonts.bigItem,
    paddingLeft: theme.margins.cardUnit * 2,
    paddingTop: theme.margins.cardUnit * 2,
  },
});

export default PointOfSaleTomorrow;
