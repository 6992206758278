import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import I18n from '../../../../../lib/i18n';
import theme from '../../../../../theme';

import Touchable from '../../../../../components/Touchable';

class PointsOfSaleSeeMore extends PureComponent<NavigationInjectedProps> {
  public render(): ReactNode {
    return (
      <Touchable style={styles.container} onPress={this.onPress}>
        <Image style={styles.backgroundImage} source={theme.images.restaurantShort} />
        <View style={styles.textContainer}>
          <Image style={styles.image} source={theme.images.dishBig} />
          <Text style={styles.text}>{I18n.t('pointOfSale.seeMore')}</Text>
        </View>
      </Touchable>
    );
  }

  private onPress = (): void => {
    this.props.navigation.navigate('eat');
  };
}

interface IStyle {
  backgroundImage: ImageStyle;
  container: ViewStyle;
  image: ImageStyle;
  text: TextStyle;
  textContainer: ViewStyle;
}

const ICON_SIZE = 80;

const styles = StyleSheet.create<IStyle>({
  backgroundImage: {
    height: '100%',
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  container: {
    backgroundColor: theme.colors.deprecatedBlack,
    flexGrow: 1,
    marginHorizontal: theme.margins.unit,
  },
  image: {
    height: ICON_SIZE,
    width: ICON_SIZE,
  },
  text: {
    color: theme.colors.textWhite,
    textAlign: 'center',
    ...theme.fonts.seeMoreTitle,
  },
  textContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    opacity: 1,
    paddingBottom: theme.margins.cardUnit * 10,
    paddingHorizontal: theme.margins.cardUnit * 6,
    paddingTop: theme.margins.cardUnit * 6,
  },
});

export default PointsOfSaleSeeMore;
