import Instabug from 'instabug-reactnative';

import { DEFAULT_LOCALE } from './i18n';

const INSTABUG_LOCALE_MAPPER: { [key: string]: string } = {
  en: 'english',
  fr: 'french',
};

const formatLocale = (i18nLocale: string): number => {
  const codeLocale = i18nLocale.substring(0, 2);
  const instabugLocaleKey = INSTABUG_LOCALE_MAPPER[codeLocale];

  if (!instabugLocaleKey) {
    return Instabug.locale[INSTABUG_LOCALE_MAPPER[DEFAULT_LOCALE]];
  }

  return Instabug.locale[instabugLocaleKey];
};

const setLocale = (i18nLocale: string): number => {
  const instabugLocale = formatLocale(i18nLocale);

  Instabug.setLocale(instabugLocale);

  return instabugLocale;
};

export default { formatLocale, setLocale };
