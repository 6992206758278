import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  text: string;
}

class EmptyContentMessage extends PureComponent<IProps> {
  public render(): ReactNode {
    return <Text style={styles.text}>{this.props.text}</Text>;
  }
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.emptyContent,
    color: theme.colors.emptyContent,
    textAlign: 'center',
  },
});

export default EmptyContentMessage;
