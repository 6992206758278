import moment from 'moment';
import { compose, withProps } from 'recompose';

import I18n from '../../../../../lib/i18n';
import { getPosTypeActions } from '../../../../../services/pointOfSaleMapper';

import PointOfSaleTomorrow, { IProps } from './PointOfSaleTomorrow.component';

const isFriday = (): boolean => moment().day() === 5;

interface IWithTextProps {
  text: string;
}

interface IWithOnPressProps {
  onPress: () => void;
}

const withTextProps = (): IWithTextProps => ({
  text: isFriday()
    ? I18n.t('pointOfSale.tomorrow.nextWeek')
    : I18n.t('pointOfSale.tomorrow.currentWeek'),
});

const withOnPressProps = (ownProps: IContainerProps & IWithTextProps): IWithOnPressProps => ({
  onPress: (): void => {
    const { id, type } = ownProps.pointOfSale;
    const navigateToMenuAction = getPosTypeActions(type, id).navigateToMenuAction;

    if (navigateToMenuAction) {
      navigateToMenuAction.callback(isFriday() ? 3 : 1);
    }
  },
});

type IConnectedProps = IWithTextProps & IWithOnPressProps;
type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withProps(withTextProps),
  withProps(withOnPressProps)
)(PointOfSaleTomorrow);
