import { loader } from 'graphql.macro';
import compact from 'lodash/compact';
import { FetchResult } from 'react-apollo';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';

import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';
import { IUpdateUserLanguageResult } from '../../types/updateLanguage';

import SelectableList, { IProps } from './SelectableList.component';
import { getLanguageItem } from './utils';

const updateLanguage = loader('../../queries/updateLanguage.gql');

export interface IMapStateToProps {
  token?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

type IUpdateLanguageMutation = (language: string) => Promise<FetchResult>;

const updateLanguageHandler = (props: IConnectedProps): IUpdateLanguageMutation => async (
  language: string
): Promise<FetchResult> => {
  return props.client.mutate<IUpdateUserLanguageResult>({
    mutation: updateLanguage,
    variables: {
      input: {
        language,
      },
    },
  });
};

type IWithChoicesProps = Pick<IProps, 'choices'>;

const withChoicesProps = (ownProps: IExternalProps): IWithChoicesProps => ({
  choices: compact(ownProps.languages.map(getLanguageItem)),
});

export interface IExternalProps {
  languages: string[];
}
export type IConnectedProps = IMapStateToProps &
  WithApolloClient<{}> & { handleChoice: IUpdateLanguageMutation } & IWithChoicesProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps),
  withApollo,
  withHandlers({ handleChoice: updateLanguageHandler }),
  withProps(withChoicesProps)
)(SelectableList);
