import capitalize from 'lodash/capitalize';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../../theme';
import CardTitle from '../../CardTitle';

export interface IProps {
  text?: string;
}

class TransactionsSectionTitle extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.titleContainer}>
        <CardTitle text={capitalize(this.props.text)} />
      </View>
    );
  }
}

interface IStyle {
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  titleContainer: {
    backgroundColor: theme.colors.white,
    padding: theme.margins.cardUnit * 2,
  },
});

export default TransactionsSectionTitle;
