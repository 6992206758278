import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import withNavigation from '../../../../../hoc/withNavigation';
import { getOfferSlots } from '../../../../../types/clickandcollect/getOfferSlots';
import OfferSlots, { IProps } from './OfferSlots.component';

const query = loader('../../../../../queries/clickandcollect/getOfferSlots.gql');

interface IWithExtraProps {
  orderWithdrawRange: string;
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  orderWithdrawRange: ownProps.navigation.getParam('orderWithdrawRange'),
});

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps),
  graphql<NavigationInjectedProps, getOfferSlots>(query, {
    options: (navigationProps: NavigationInjectedProps): QueryOpts => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          idOffer: navigationProps.navigation.getParam('offerId'),
        },
      };
    },

    // tslint:disable-next-line:no-any
    props: (props: any): any => {
      const offer = get(props, 'data.offer');

      return {
        hasError: !!get(props, 'data.error'),
        isLoading: get(props, 'data.loading'),
        offer: offer ? offer : undefined,
      };
    },
  })
)(OfferSlots);
