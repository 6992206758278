import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';
import withNavigation from '../../hoc/withNavigation';
import { IPollable } from '../../types/pollable';
import OffersList, { IProps } from './OffersList.component';

export type IConnectedProps = NavigationInjectedProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps> & IPollable;

export default compose<IProps, IContainerProps>(withNavigation)(OffersList);
