import React, { PureComponent, ReactNode } from 'react';

import ProfileCard from '../../../../components/ProfileCard';
import { WebPage } from '../../../../componentsWeb';
import I18n from '../../../../lib/i18n';

class UserProfile extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <WebPage title={I18n.t('dashboard.profile.pageTitle')}>
        <ProfileCard />
      </WebPage>
    );
  }
}

export default UserProfile;
