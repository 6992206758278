import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';

import CancelOrderButton from '../../../components/CancelOrderButton';
import LoadingCard from '../../../components/LoadingCard';
import Touchable from '../../../components/Touchable';
import { transformCancelableDate } from '../../../services/offersFormatter';
import theme from '../../../theme';
import I18n from '../../../lib/i18n';

import { getOrder2_order_Order as IOrder } from '../../../types/clickandcollect/getOrder2';
import {
  OrderState,
  PaymentMethod,
  OfferTemplateWithdrawalType,
} from '../../../types/clickandcollect/globalTypes';

export interface IProps {
  isLoading: boolean;
  onCancelButtonPress?: () => void;
  order?: IOrder;
  testID?: string;
}

class OrderCancelCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, order, onCancelButtonPress, testID } = this.props;
    const cancelableUntil = get(order, 'cancelableUntil');

    const hanldeCancelText = () => {
      const withdrawalType = get(order?.offer?.offerTemplate, 'withdrawalType', '');

      if (
        withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
        withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT
      ) {
        return I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelSubtitleAfterReadyStatus');
      }
      if (withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP) {
        return I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelMessageClickAndPickup');
      }
      if (cancelableUntil) {
        return transformCancelableDate(cancelableUntil);
      }
      return;
    };

    const orderStateKeys = [
      OrderState.READY,
      OrderState.COMPLETED,
      OrderState.CANCELLED,
      OrderState.ABANDONED,
      OrderState.REFUSED,
    ];

    return (
      <>
        {order &&
        (orderStateKeys.includes(order.state) ||
          order.paymentMethod === PaymentMethod.CREDIT_CARD) ? null : (
          <>
            {order?.offer?.published && (
              <Touchable onPress={onCancelButtonPress} testID={testID}>
                <LoadingCard isLoading={isLoading} style={styles.card} big>
                  {!!hanldeCancelText() && <Text style={styles.text}>{hanldeCancelText()}</Text>}
                  <CancelOrderButton onPress={onCancelButtonPress} order={order} />
                </LoadingCard>
              </Touchable>
            )}
          </>
        )}
      </>
    );
  }
}

export interface IStyle {
  card: ViewStyle;
  text: TextStyle;
}

const BORDER_RADIUS = 8;

const styles = StyleSheet.create({
  card: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    marginBottom: theme.margins.cardUnit * 2,
    overflow: 'hidden',
  },
  text: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.textBlack,
    marginBottom: theme.margins.cardUnit * 2,
    textAlign: 'center',
  },
});

export default OrderCancelCard;
