import { ImageURISource } from 'react-native';
import { compose, withProps } from 'recompose';

import { getPointOfSaleBackground, getPrimaryAction } from '../../../services/pointOfSaleMapper';
import { IConcept, IPosType, IPosTypeModel } from '../../../types/common';

import PosCardBody, { IProps } from './PosCardBody.component';

interface IWithBodyProps {
  onPress?: () => void;
  source?: ImageURISource;
}

const withBodyProps = (ownProps: IExternalProps): IWithBodyProps => {
  const bodyProps: IWithBodyProps = {};

  bodyProps.source = !ownProps.noBackground
    ? getPointOfSaleBackground(ownProps.image, ownProps.posTypeModel, ownProps.concept)
    : undefined;

  bodyProps.onPress = undefined;
  if (ownProps.posId) {
    const primaryAction = getPrimaryAction(ownProps.posType, ownProps.posId, {
      content: ownProps.content,
    });
    bodyProps.onPress = primaryAction && primaryAction.callback;
  }

  return bodyProps;
};

export interface IExternalProps {
  concept?: IConcept;
  content?: string;
  image: string;
  noBackground?: boolean;
  posId?: string;
  posType: IPosType;
  posTypeModel: IPosTypeModel;
}
export type IConnectedProps = IWithBodyProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withProps(withBodyProps))(PosCardBody);
