import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import I18n from '../../lib/i18n';
import theme from '../../theme';

import { platformStyles } from './styles';

export interface IProps {
  isToggledOn: boolean;
  onPress?: () => void;
  testID?: string;
}

export const ICON_SIZE = 15;
const HIT_SLOP_SIZE = 10;

class ToggleSeeMoreButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const iconToggledOffName = 'chevron-down';
    const iconToggledOnName = 'chevron-up';

    return (
      <View style={platformStyles.button}>
        <TouchableOpacity
          testID={this.props.testID}
          style={styles.container}
          onPress={this.props.onPress}
          hitSlop={{
            bottom: HIT_SLOP_SIZE,
            left: HIT_SLOP_SIZE,
            right: HIT_SLOP_SIZE,
            top: HIT_SLOP_SIZE,
          }}
        >
          <Text style={styles.text}>
            {this.props.isToggledOn
              ? I18n.t('dashboard.eat.pageTitle.seeLess')
              : I18n.t('dashboard.eat.pageTitle.seeMore')}
          </Text>
          <View style={styles.iconContainer}>
            <Icon
              name={this.props.isToggledOn ? iconToggledOnName : iconToggledOffName}
              size={ICON_SIZE}
              color={theme.colors.grayMedium}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  iconContainer: ViewStyle;
  text: TextStyle;
}

export interface IPlatformStyle {
  button: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: 25,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 2 * theme.margins.cardUnit,
    paddingVertical: theme.margins.cardUnit,
  },
  iconContainer: {
    marginLeft: theme.margins.cardUnit,
  },
  text: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.grayMedium,
  },
});

export default ToggleSeeMoreButton;
