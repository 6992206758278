import React, { PureComponent, ReactNode } from 'react';

import {
  ClickAndCollectFooterType,
  ClickAndCollectQuantityFooter,
} from '../../../../../components/QuantityFooter';
import SlotsList from '../../../../../components/SlotsList';

import { WebPage } from '../../../../../componentsWeb';
import I18n from '../../../../../lib/i18n';
import { getOfferSlots_offer_Offer as IOffer } from '../../../../../types/clickandcollect/getOfferSlots';

export interface IProps {
  isLoading: boolean;
  offer: IOffer;
  orderWithdrawRange: string;
}

class OfferSlots extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, offer, orderWithdrawRange } = this.props;

    return (
      <WebPage title={I18n.t('dashboard.eat.clickAndCollect.title')} canGoBack>
        <SlotsList offer={offer} isLoading={isLoading} orderWithdrawRange={orderWithdrawRange} />
        {offer && (
          <ClickAndCollectQuantityFooter
            idOffer={offer.id}
            footerType={ClickAndCollectFooterType.SLOT}
          />
        )}
      </WebPage>
    );
  }
}

export default OfferSlots;
