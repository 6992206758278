import React from 'react';
import get from 'lodash/get';
import BundleArticlesPage, {
  IProps as IBundleProps,
} from '../../../../../components/BundleArticlesPage';
import OfferArticlesPage from '../../../../../components/OfferArticlesPage';
import I18n from '../../../../../lib/i18n';

const OfferBundleStep = (props: IBundleProps) => {
  const { offer } = props;
  const noticeText = offer ? I18n.t('dashboard.eat.clickAndCollect.noticeText') : undefined;
  const bundle =
    offer && offer.offerTemplate.__typename === 'OfferTemplate' && offer.offerTemplate.bundle;
  const nextOrderableOffers = get(offer, 'offerTemplate.nextOrderableOffers', []);

  return bundle ? (
    <BundleArticlesPage {...props} bundleSteps={bundle.bundleSteps} />
  ) : (
    <OfferArticlesPage
      {...props}
      noticeText={noticeText}
      nextOrderableOffers={nextOrderableOffers}
    />
  );
};

export default OfferBundleStep;
