import { FormikProps } from 'formik';
import React, { PureComponent, ReactNode } from 'react';

import I18n from '../../../lib/i18n';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import FormFormik from '../../FormFormik';
import Paragraph from '../../Paragraph';
import TextInputFormik from '../../TextInputFormik';

export interface IValues {
  badgeNumber: string;
  globalError?: string;
  lastName: string;
}

class SignUpIdentityFormikForm extends PureComponent<FormikProps<IValues>, {}> {
  public render(): ReactNode {
    const { errors, isSubmitting, submitForm, values } = this.props;

    return (
      <FormFormik testID="SignUpIdentity">
        <Paragraph text={I18n.t('signUp.identity.paragraph')} />
        <TextInputFormik
          hasError={!!errors.globalError}
          placeholder={I18n.t('signUp.identity.badgeNumberPlaceholder')}
          testID="badgeNumber"
          returnKeyType="next"
          type="name"
          name="badgeNumber"
          error=""
        />
        <TextInputFormik
          hasError={!!errors.globalError}
          placeholder={I18n.t('signUp.identity.lastNamePlaceholder')}
          returnKeyType="done"
          type="name"
          testID="lastName"
          name="lastName"
          error=""
        />
        {!!errors.globalError && <ErrorMessage text={I18n.t(errors.globalError)} />}
        <Button
          disabled={!this.canSubmit(values)}
          isLoading={isSubmitting}
          text={I18n.t('signUp.identity.validate')}
          onPress={submitForm}
          testID="validateIdentity"
        />
      </FormFormik>
    );
  }

  private canSubmit = (values: IValues): boolean => {
    return !!values.badgeNumber && !!values.lastName;
  };
}

export default SignUpIdentityFormikForm;
