import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { NavigationInjectedProps } from 'react-navigation';
import OrderSelectPickupPointPage, { IProps } from './OrderSelectPickupPointPage.component';
import { getOrder2_order_Order as IOrder } from '../../../../../types/clickandcollect/getOrder2';
import { getOffer_offer_Offer as IOffer } from '../../../../../types/clickandcollect/getOffer';
import { getOfferTemplatePickupPoints_pickUpPointsWithSelected as IPickupPoint } from '../../../../../types/clickandcollect/getOfferTemplatePickupPoints';
import { IAppState } from '../../../../../redux/reducer';

const USER_LAST_PICKUP_POINTS_QUERY = loader(
  '../../../../../queries/clickandcollect/getUserLastUsedPickupPoint.gql'
);

export interface IWithOwnProps {
  offer?: IOffer;
  idGuest?: string;
}

export interface IWithLastSelectedPoint {
  isLastSelectedPointLoading: boolean;
  lastSelectedPoint: IPickupPoint | null;
}
export interface IWithPickupPoints {
  isPickupPointsLoading: boolean;
  pickupPoints: IPickupPoint[];
}

export interface IWithOrderProps {
  hasError: boolean;
  isLoading: boolean;
  order: IOrder | undefined;
}

export type IContainerProps = Omit<IProps, keyof IWithPickupPoints & IWithOrderProps>;

const mapStateToProps = (state: IAppState): { appState: IAppState } => ({
  appState: state,
});

const withOwnProps = (ownProps: NavigationInjectedProps): IWithOwnProps => ({
  offer: ownProps.navigation.getParam('offer'),
  idGuest: ownProps.navigation.getParam('idGuest'),
});


const withLastSelectedPoint = () =>
  graphql(USER_LAST_PICKUP_POINTS_QUERY, {
    options: (props: OptionProps<any>): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        idGuest: props.idGuest,
        idOfferTemplate: props.offer.offerTemplate.id,
      },
    }),
    props: (props: OptionProps<any>): IWithLastSelectedPoint => ({
      isLastSelectedPointLoading: get(props, 'data.loading'),
      lastSelectedPoint: get(props, 'data.userLastUsedPickupPoint'),
    }),
    skip: (props: IProps): boolean => !props.idGuest,
  });

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps),
  withProps(withOwnProps),
  withLastSelectedPoint()
)(OrderSelectPickupPointPage);
