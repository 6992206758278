/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ArticleBaking {
  MEDIUM = "MEDIUM",
  MEDIUMRARE = "MEDIUMRARE",
  RARE = "RARE",
  RAW = "RAW",
  ROSE = "ROSE",
  WELLDONE = "WELLDONE",
}

export enum ArticleFamily {
  BEVERAGE = "BEVERAGE",
  BREAD = "BREAD",
  DAIRY = "DAIRY",
  DESSERT = "DESSERT",
  DISH = "DISH",
  FRUIT = "FRUIT",
  MISCELLANEOUS = "MISCELLANEOUS",
  PASTRY = "PASTRY",
  SALAD = "SALAD",
  SANDWICH = "SANDWICH",
  SIDE_DISH = "SIDE_DISH",
  SNACKING = "SNACKING",
  SOUP = "SOUP",
  STARTER = "STARTER",
}

export enum Currency {
  EUR = "EUR",
}

export enum OfferTemplatePeriod {
  DAILY = "DAILY",
  WEEKLY_MON = "WEEKLY_MON",
}

export enum OfferTemplateSalesType {
  BUNDLE = "BUNDLE",
  FREE_SALE = "FREE_SALE",
}

export enum OfferTemplateType {
  BOOKING_SERVICE = "BOOKING_SERVICE",
  CLICK_COLLECT = "CLICK_COLLECT",
  TABLE_SERVICE = "TABLE_SERVICE",
}

export enum OfferTemplateWithdrawalType {
  CLICK_AND_PICK_UP = "CLICK_AND_PICK_UP",
  CONNECTED_LOCKERS = "CONNECTED_LOCKERS",
  INSTANT_CLICK_COLLECT = "INSTANT_CLICK_COLLECT",
  POS_AT_SITE = "POS_AT_SITE",
  POS_CLICK_SERVE = "POS_CLICK_SERVE",
  POS_TAKE_AWAY = "POS_TAKE_AWAY",
  TABLE_SERVICE = "TABLE_SERVICE",
}

export enum OrderState {
  ABANDONED = "ABANDONED",
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  CART = "CART",
  COMPLETED = "COMPLETED",
  EMPLOYEE_CART = "EMPLOYEE_CART",
  ON_HOLD = "ON_HOLD",
  PREPARING = "PREPARING",
  READY = "READY",
  REFUSED = "REFUSED",
}

export enum PaymentMethod {
  BADGE = "BADGE",
  CREDIT_CARD = "CREDIT_CARD",
  EDENRED = "EDENRED",
  ON_SITE = "ON_SITE",
}

export enum PaymentTypes {
  BADGE = "BADGE",
  CREDIT_CARD = "CREDIT_CARD",
  EDENRED = "EDENRED",
  ON_SITE = "ON_SITE",
}

export enum QuantityRuleType {
  mealQuantity = "mealQuantity",
  orderPerDay = "orderPerDay",
  productsPerOrder = "productsPerOrder",
  sameProductPerOrder = "sameProductPerOrder",
}

export enum AtSiteTakeAwayType {
  AT_SITE = "AT_SITE",
  TAKE_AWAY = "TAKE_AWAY",
  AT_SITE_TAKE_AWAY = "AT_SITE_TAKE_AWAY",
  NONE = "NONE"
}

export interface InputOrderItem {
  idOfferItem: string;
  quantity: number;
  chosenBaking?: ArticleBaking | null;
}

export interface InputUpsertOrder {
  idOffer: string;
  idGuest: string;
  idTable?: string | null;
  idOrder?: string | null;
  state?: OrderState | null;
  withdrawRange?: any | null;
  items?: InputOrderItem[] | null;
  tableNumber?: number | null;
  comment?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
