import { Reducer, ReducersMapObject } from 'redux';
import { persistCombineReducers, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter } from 'connected-react-router';

import orderAuthenticationReducer, {
  IOrderAuthenticationState,
  initialState as orderAuthenticationState,
} from './orderAuthentification/reducer';
import authenticationReducer, {
  IAuthenticationState,
  initialState as authenticationState,
} from './authentication/reducer';
import cookiesReducer , {
  ICookiesConsent,
  initialState as cookiesState,
} from './cookies/reducer';
import clickAndCollectReducer, {
  IClickAndCollectState,
  initialState as clickAndCollectState,
} from './clickAndCollect/reducer';
import menuReducer, { IMenuState, initialState as menuState } from './menu/reducer';
import pointsOfSaleReducer, {
  initialState as pointsOfSaleState,
  IPointsOfSaleState,
} from './pointsOfSale/reducer';
import queriesReducer, { initialState as queriesState, IQueriesState } from './queries/reducer';
import signUpReducer, { initialState as signUpState, ISignUpState } from './signUp/reducer';

import routerReducer, { initialState as routerState, IRouterState, history } from './router.web';

export interface IAppState {
  orderAuth: IOrderAuthenticationState;
  authentication: IAuthenticationState;
  clickAndCollect: IClickAndCollectState;
  menu: IMenuState;
  pointsOfSale: IPointsOfSaleState;
  queries: IQueriesState;
  router: IRouterState;
  signUp: ISignUpState;
  cookies: ICookiesConsent
}

export const initialState: IAppState = {
  orderAuth: orderAuthenticationState,
  authentication: authenticationState,
  clickAndCollect: clickAndCollectState,
  menu: menuState,
  pointsOfSale: pointsOfSaleState,
  queries: queriesState,
  router: routerState,
  signUp: signUpState,
  cookies: cookiesState
};

const persistConfig: PersistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'orderAuth',  'pointsOfSale', 'clickAndCollect', 'signIn', 'signUp'],
};

export const reducers: ReducersMapObject = {
  orderAuth: orderAuthenticationReducer,
  authentication: authenticationReducer,
  clickAndCollect: clickAndCollectReducer,
  menu: menuReducer,
  pointsOfSale: pointsOfSaleReducer,
  queries: queriesReducer,
  router: connectRouter(history),
  signUp: signUpReducer,
  cookies: cookiesReducer
};

const appReducer: Reducer<IAppState> = persistCombineReducers<IAppState>(persistConfig, reducers);

export default appReducer;
