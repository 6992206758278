import { Action } from 'redux';

export type IActionTypes = 'QUERIES_SET_DATE_TIME';

export const actionTypes: { [key in IActionTypes]: key } = {
  QUERIES_SET_DATE_TIME: 'QUERIES_SET_DATE_TIME',
};

export interface ISetQueryDateTimePayload {
  dateTime: string;
  query: string;
}

export interface ISetQueryDateTimeAction extends Action {
  payload: ISetQueryDateTimePayload;
}

export const setQueryDateTime = (payload: ISetQueryDateTimePayload): ISetQueryDateTimeAction => ({
  payload,
  type: actionTypes.QUERIES_SET_DATE_TIME,
});
