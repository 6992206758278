import get from 'lodash/get';
import { compose, withProps } from 'recompose';

import { IAmount, ISubsidyAmount } from '../../types/common';

import AdmissionPricesCard, { IProps } from './AdmissionPricesCard.component';
import { getAdmissionTotal } from './utils';

interface IWithTotalProps {
  total: IAmount;
}

const withTotalProps = (ownProps: IExternalProps): IWithTotalProps => ({
  total: {
    amount:
      ownProps.admission && ownProps.fee
        ? getAdmissionTotal(ownProps.admission, ownProps.fee).toString()
        : '0',
    currency: get(ownProps, 'admission.currency'),
  },
});

export interface IExternalProps {
  admission?: ISubsidyAmount;
  fee?: ISubsidyAmount;
}
export type IConnectedProps = IWithTotalProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withProps(withTotalProps))(AdmissionPricesCard);
