import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

export interface IProps {
  onPress?: () => void;
  text?: string;
}

class Link extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Text style={styles.text} onPress={this.props.onPress}>
        {this.props.text}
      </Text>
    );
  }
}

export interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    textDecorationLine: 'underline',
  },
});

export default Link;
