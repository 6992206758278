import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import theme from '../../../theme';
import { getStatusColor, IStepStatus, STATUS } from '../utils';

export type IStepNumber = 1 | 2 | 3 | 4;

export interface IProps {
  status: IStepStatus;
  stepNumber: IStepNumber;
}

class StepperItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { status } = this.props;
    const colorStep = getStatusColor(status);

    return (
      <View style={[styles.container, { borderColor: colorStep }]}>
        {this.renderContent(colorStep)}
      </View>
    );
  }

  private renderContent = (colorStep: string): ReactNode => {
    const { status, stepNumber } = this.props;
    if (status === STATUS.DONE) {
      return <Icon name="check" color={colorStep} size={ICON_SIZE} />;
    }

    return <Text style={[styles.text, { color: colorStep }]}>{stepNumber}</Text>;
  };
}

const ITEM_SIZE = 25;
const ICON_SIZE = 15;

export interface IStyle {
  container: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    borderRadius: ITEM_SIZE / 2,
    borderWidth: 2,
    height: ITEM_SIZE,
    justifyContent: 'center',
    width: ITEM_SIZE,
  },
  text: {
    ...theme.fonts.stepperItem,
  },
});

export default StepperItem;
