import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import theme from '../../../theme';
import Touchable from '../../Touchable';

export interface IProps {
  day: string;
  isActive: boolean;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  dayOfWeekTextStyle?: TextStyle;
  dayOfMonthTextStyle?: TextStyle;
  activeDayTextStyle?: TextStyle;
  noDotDayOfWeek?: boolean;
}

const WeeklyTab: React.FC<IProps> = ({
  day,
  isActive,
  onPress,
  style,
  dayOfWeekTextStyle,
  dayOfMonthTextStyle,
  activeDayTextStyle,
  noDotDayOfWeek = false,
}) => {
  const dayOfWeek = moment(day).format('ddd').replace('.', '');
  const dayOfMonth = moment(day).format('D');

  const WeekDay = noDotDayOfWeek ? dayOfWeek.substring(0, 3) : dayOfWeek;

  const containerStyle = StyleSheet.flatten([
    styles.container,
    isActive ? styles.containerActive : styles.containerInactive,
    style,
  ]);


  const activeStyle = activeDayTextStyle ? activeDayTextStyle : styles.textActive;

  const dayOfMonthStyle = [
    dayOfMonthTextStyle ? dayOfMonthTextStyle : styles.textDayOfMonth,
    isActive ? activeStyle : null,
  ];
  const dayOfWeekStyle = [
    dayOfWeekTextStyle ? dayOfWeekTextStyle : styles.textDayOfWeek,
    isActive ? activeStyle : null,
  ];

  return (
    <View style={containerStyle}>
      <Touchable onPress={onPress} useOpacity style={styles.touchableContainer}>
        <Text style={dayOfMonthStyle}>{dayOfMonth}</Text>
        <Text numberOfLines={1} style={dayOfWeekStyle}>
          {capitalize(WeekDay)}
        </Text>
      </Touchable>
    </View>
  );
};

interface IStyle {
  container: ViewStyle;
  containerActive: ViewStyle;
  containerInactive: ViewStyle;
  textActive: TextStyle;
  textInactive: TextStyle;
  textDayOfMonth: TextStyle;
  textDayOfWeek: TextStyle;
  touchableContainer: ViewStyle;
}

const WEEKLY_TAB_HEIGHT = 60;
const WEEKLY_WIDTH = 80;

const styles = StyleSheet.create<IStyle>({
  container: {
    flex: 1,
    height: WEEKLY_TAB_HEIGHT,
    width: WEEKLY_WIDTH,
    paddingVertical: theme.margins.unit,
  },
  containerActive: {
    backgroundColor: theme.colors.backgroundTab,
  },
  containerInactive: {
    backgroundColor: theme.colors.white,
  },
  textActive: {
    color: theme.colors.daySelected,
  },
  textInactive: {
    color: theme.colors.textGray,
  },
  textDayOfMonth: {
    ...theme.fonts.dayOfMonth,
    color: theme.colors.textBlack,
  },
  textDayOfWeek: {
    ...theme.fonts.dayOfWeek,
    color: theme.colors.textBlack,
  },
  touchableContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default WeeklyTab;
