import React, { ReactNode } from 'react';
import { NavigationScreenProps } from 'react-navigation';

import BorderedView from '../../../../src/components/BorderedView/BorderedView.component';
import Holding from '../../../../src/components/Holding/Holding.component';
import LoadingView from '../../../../src/components/LoadingView/LoadingView.component';
import SignUpIdentityForm from '../../../../src/components/SignUpIdentityForm';
import I18n from '../../../../src/lib/i18n';
import { getUserSignUpParams } from '../../../../src/services/navigation/params';
import { IDistance } from '../../../../src/types/common';
import { IHolding } from '../../../../src/types/holdingById';
import SignupLayout from '../../components/SignupLayout';

export interface IProps extends NavigationScreenProps {
  distance?: IDistance;
  holding?: IHolding;
  isLoading?: boolean;
}

const styles = {
  holdingNameContainer: {
    marginBottom: '70px',
  },
};

const GuestCredentialsPage = (props: IProps): ReactNode => {
  const { distance, holding, isLoading, navigation } = props;
  const { badgeNumber, serialNumber, lastName } = getUserSignUpParams(props.navigation);
  if (!isLoading && !holding) {
    navigation.navigate('signUpNearHoldings');
  }

  return (
    <SignupLayout
      title={I18n.t('signUp.headerTitle')}
      subtitle={I18n.t('signUp.identity.title')}
      currentStepNumber={2}
      currentStepStatus="ACTIVE"
    >
      <div style={styles.holdingNameContainer}>
        <BorderedView>
          <LoadingView isLoading={isLoading}>
            <Holding holding={holding} distance={distance} />
          </LoadingView>
        </BorderedView>
      </div>
      <SignUpIdentityForm
        holdingId={navigation.getParam('holdingId')}
        badgeNumber={badgeNumber || serialNumber}
        lastName={lastName}
      />
    </SignupLayout>
  );
};

export default GuestCredentialsPage;
