import React, { PureComponent, ReactNode } from 'react';
import { Text, View } from 'react-native';
import I18n from '../../../lib/i18n';
import styles from '../styles.shared';

interface IComponentProps {
  receptionDate: string;
  receptionHours: string;
}

export default class WithdrawRangeSection extends PureComponent<IComponentProps> {
  public render(): ReactNode {
    const { receptionDate, receptionHours } = this.props;

    return (
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {I18n.t('dashboard.eat.clickAndCollect.cartSummary.receptionTime')}
        </Text>
        <View style={styles.sectionContent}>
          <Text style={styles.sectionContentTextLeft}>{receptionDate}</Text>
          <Text style={styles.sectionContentTextRight}>{receptionHours}</Text>
        </View>
      </View>
    );
  }
}
