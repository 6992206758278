import React, { PureComponent, ReactNode } from 'react';
import { View } from 'react-native';

import { ILocale, initializeI18n, setAppLocale } from '../../lib/i18n';
import Instabug from '../../lib/instabug';
import OneTrust from '../../services/oneTrust';

export interface IProps {
  isLoading?: boolean;
  userLocale?: ILocale;
}

interface IState {
  deviceLocale?: ILocale;
}

class I18nProvider extends PureComponent<IProps, IState> {
  public state: IState = {};

  public componentDidMount(): void {
    initializeI18n().then(
      (deviceLocale: ILocale): void => {
        this.setState({ deviceLocale });
      }
    );
  }

  public render(): ReactNode {
    let locale = this.props.userLocale;

    if (!locale) {
      locale = this.state.deviceLocale;

      if (this.props.isLoading || !locale) {
        return null;
      }
    }

    setAppLocale(locale);
    Instabug.setLocale(locale);
    OneTrust.setLocale(locale);

    return (
      <View flex={1} key={locale}>
        {this.props.children}
      </View>
    );
  }
}

export default I18nProvider;
