import { OfferTemplateWithdrawalType } from '../types/clickandcollect/globalTypes';
import { GuestManagementMode } from '../hoc/withGuestPaymentProfile';
import { IAmount } from '../types/common';
import { isNil } from "lodash";

interface IGuest {
  managementMode?: GuestManagementMode;
  isGuestAnomyn?: boolean;
}

interface IGuestWithBalance extends IGuest {
  balance: IAmount | null;
}

export const isAllowedToRefill = (managementMode: GuestManagementMode, refillAllowedHolding: boolean | null): boolean => {
  return isNil(refillAllowedHolding)
  ? managementMode === GuestManagementMode.PRE
  : refillAllowedHolding;
} 

export const isAllowedToOrder = ({ managementMode }: IGuest): boolean =>
  !!managementMode &&
  (managementMode === GuestManagementMode.PRE || managementMode === GuestManagementMode.POST);

export const isRefillRequired = (
  guest: IGuestWithBalance,
  totalPrice?: IAmount,
  refillAllowedHolding?: boolean | null,
  withdrawalType?: OfferTemplateWithdrawalType,
  idCashSystem?: number | null
): boolean =>
  {
    const isLowBalance = !!totalPrice &&
      (guest?.balance?.amount && Number(totalPrice.amount) > Number(guest.balance.amount));
    
    const checkBalance = isLowBalance &&
      !(withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE && idCashSystem === 1);

    const guestHasPreManagementMode = guest.managementMode === GuestManagementMode.PRE;

    if (!guest.isGuestAnomyn && (refillAllowedHolding || refillAllowedHolding === null && guestHasPreManagementMode)) {
      return checkBalance;
    }
    return false;
  };
