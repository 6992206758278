import { loader } from 'graphql.macro';
import get from 'lodash/get';
import RNVersionNumber from 'react-native-version-number';
import { NavigationState } from 'react-navigation';
import { NavigationParams } from 'react-navigation';

import Client from '../../apollo/client';

import { getConfig } from './../../environment';
import { IUserForTracking } from './../../hoc/withUserTracking/withUserTracking.d';
import Analytics from './analytics';
import Logger from '../../lib/logger';

const getUserInfoForTracking = loader('../../hoc/withUserTracking/getUserInfoForTracking.gql');

export interface IWithUserTrackingResult {
  getUser: IUserForTracking;
}

export interface IAppTrackNavigationConfig {
  currentState: NavigationState;
  prevState: NavigationState;
}

export type IWebTrackNavigationConfig = NavigationParams;

export type ITrackNavigationConfig = IAppTrackNavigationConfig | IWebTrackNavigationConfig;

export enum Event {
  screen = 'screen',
  confirmOrder = 'confirmOrder',
}

class Tracker {

  public static resetUserTracking(): void {
    const userProperties = {
      app_env: null,
      app_version: null,
      holding_brand: null,
      holding_cp: null,
      holding_id: null,
      holding_name: null,
      user_id_foodi: '',
      user_language: null,
    };

    Analytics.setUserProperties(userProperties);
    Analytics.setUserId(userProperties.user_id_foodi);
  }

  public static track(event: Event, params?: Record<string, any>): void {
    Analytics.track(event, params);
  }

  public static trackNavigation(trackNavigation: ITrackNavigationConfig): void {
    // @ts-ignore
    Analytics.trackNavigation(trackNavigation);
  }

  public static trackUser = async (): Promise<void> => {
    const userInfo = await Client.getApolloClient().query<IWithUserTrackingResult>({
      query: getUserInfoForTracking,
    });

    if (userInfo && get(userInfo, 'data.getUser')) {
      const userInformation = get(userInfo, 'data.getUser');

      const versionNumber = RNVersionNumber.appVersion || null;
      const environment = getConfig().ENV;

      const userProperties = {
        app_env: environment,
        app_version: versionNumber,
        holding_brand: get(userInformation, 'guests.edges[0].node.holding.brand'),
        holding_cp: get(userInformation, 'guests.edges[0].node.holding.address.zip') || null,
        holding_id: get(userInformation, 'guests.edges[0].node.holding.id'),
        holding_name: get(userInformation, 'guests.edges[0].node.holding.name'),
        user_id_foodi: Tracker.userConsentedToBeTracked ? get(userInformation, 'id') : '',
        user_language: get(userInformation, 'language'),
      };
      Logger.info('Tracker - user properties -', userProperties);

      Analytics.setUserId(userProperties.user_id_foodi);
      Analytics.setUserProperties(userProperties);
    }
  };

  public static setConsentToTrackUser = (consent: boolean): void => {

    // check for null and undefined
    if (consent == null) {
      return;
    }

    Tracker.userConsentedToBeTracked = consent;
  };

  private static userConsentedToBeTracked: boolean = true;
}

export default Tracker;
