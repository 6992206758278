import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer, NavigationInjectedProps } from 'react-navigation';
import LargeButton from '../../../../../../components/LargeButton';
import TableNumber from '../../../../../../components/TableNumber';
import { WebPage } from '../../../../../../componentsWeb';
import I18n from '../../../../../../lib/i18n';
import { isMobile } from '../../../../../../lib/responsive';
import styles from '../styles';

export interface IProps extends NavigationInjectedProps {
  rootNavigator: NavigationContainer;
  offerId: string;
  idTable: string;
  tableNumber: string;
}
interface IState {
  tableNumber: string;
}
class TableNumberConfirmation extends PureComponent<IProps, IState> {
  state = { tableNumber: '' };
  public render(): ReactNode {
    const {
      navigation: { navigate },
      offerId,
      idTable,
    } = this.props;

    const next = (): void => {
      navigate('tableServiceOfferArticles', { offerId, idTable });
    };
    const prev = (): void => {
      navigate('tableService', { offerId, tableNumber: this.state.tableNumber });
    };

  
    return (
      <>
        <WebPage title={I18n.t('dashboard.eat.tableService.table.title')} tabBarHeight={72} hideLogo hideNavigationMenu>
        <View style={styles.container}>
            <TableNumber
              editable={false}
              onChange={tableNumber => this.setState({ tableNumber })}
            />

            <Text style={[styles.caption, tableStyles.caption, styles.captionEdit]} onPress={prev}>
              {I18n.t('dashboard.eat.tableService.table.edit')}
            </Text>
            {!isMobile() && (
              <LargeButton
                onPress={next}
                style={tableStyles.buttonStyle}
                theme="success"
                text={I18n.t('dashboard.eat.tableService.table.valid')}
                
              />
            )}
          </View>
        </WebPage>

        {isMobile() && (
          <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }}>
            <LargeButton
              onPress={next}
              style={tableStyles.buttonStyle}
              theme="success"
              text={I18n.t('dashboard.eat.tableService.table.valid')}
              
            />
          </div>
        )}
      </>
    );
  }
}

const tableStyles = StyleSheet.create({
  container: {
    position: 'relative',
    padding: 16,
    width: 'auto',
  },
  buttonStyle: {
    width: '100%',
  },
  caption: {
    marginBottom: 10,
  },
});

export default TableNumberConfirmation;
