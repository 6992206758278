import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';

import I18n from '../../../../lib/i18n';
import { IMenuElementDetails } from '../../../../types/common';
import MenuElementInformationContent from '../MenuElementInformationContent';
import MenuElementNutritionalContent from '../MenuElementNutritionalContent';
import MenuElementSection from '../MenuElementSection';
export interface IProps {
  isLoading: boolean;
  menuElementDetails?: IMenuElementDetails;
}
class MenuElementDetailsContent extends PureComponent<IProps> {
  public render(): ReactNode {
    const nutritionalComposition = get(this.props.menuElementDetails, 'nutritionalComposition');

    return (
      <>
        <MenuElementSection title={I18n.t('dashboard.menuElementDetails.informationSection.title')}>
          <MenuElementInformationContent />
        </MenuElementSection>
        {nutritionalComposition ? (
          <MenuElementSection
            title={I18n.t('dashboard.menuElementDetails.nutritionalCompositionTitle')}
          >
            <MenuElementNutritionalContent nutritionalComposition={nutritionalComposition} />
          </MenuElementSection>
        ) : null}
      </>
    );
  }
}

export default MenuElementDetailsContent;
