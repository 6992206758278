import { StyleSheet } from 'react-native';

import theme from '../../theme';

import { IPlatformStyle } from './ToggleSeeMoreButton.component';

const BUTTON_HEIGHT = 35;

export const platformStyles = StyleSheet.create<IPlatformStyle>({
  button: {
    alignItems: 'center',
    height: BUTTON_HEIGHT,
    marginBottom: 6 * theme.margins.cardUnit,
  },
});
