import flatten from 'lodash/flatten';
import get from 'lodash/get';
import moment from 'moment';

import I18n from '../lib/i18n';
import { INode } from '../types/common';
import { IOfferTemplate } from '../types/common';
import { OfferTemplatePeriod } from '../types/clickandcollect/globalTypes';
import { getOfferTemplates_list_edges_node_OfferTemplate } from '../types/clickandcollect/getOfferTemplates';
import { isNextOffersAvailable } from './offer';

export const transformCancelableDate = (dateTime: string): string => {
  const momentDate = moment(dateTime);

  const dayOfMonth = momentDate.format('DD');
  const month = momentDate.format('MM');
  const year = momentDate.format('YY');

  const hours = momentDate.format('HH');
  const minutes = momentDate.format('mm');

  return I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelSubtitle', {
    dayOfMonth,
    hours,
    minutes,
    month,
    year,
  });
};

export const transformDate = (dateTime: string): string => {
  const momentDate = moment(dateTime);
  const dayOfWeek = momentDate.format('dddd');
  const dayOfMonth = momentDate.format('DD');
  const month = momentDate.format('MM');

  return I18n.t('dashboard.eat.clickAndCollect.dateTime', { dayOfWeek, dayOfMonth, month });
};

export const formatCreationDate = (dateTime: string): string => {
  const momentDate = moment(dateTime);
  const dayOfMonth = momentDate.format('DD');
  const month = momentDate.format('MMM');
  const year = momentDate.format('YYYY');

  const receptionDateText = `${dayOfMonth} ${month} ${year}`;

  return receptionDateText;
};

export const formatCreationHours = (dateTime: string): string => {
  const momentDate = moment(dateTime);
  const hour = momentDate.format('HH');
  const minute = momentDate.format('mm');

  const receptionDateText = `${hour}:${minute}`;

  return receptionDateText;
};

export const transformSummaryDate = (dateTime: string): string => {
  const momentDate = moment(dateTime);
  const dayOfWeek = momentDate.format('dddd');
  const dayOfMonth = momentDate.format('DD');
  const month = momentDate.format('MM');
  const year = momentDate.format('YY');

  const receptionDateText = `${I18n.t('dashboard.eat.clickAndCollect.cartSummary.dateTime', {
    dayOfMonth,
    dayOfWeek,
    month,
    year,
  })}`;

  const capitalizedReceptionDateText =
    receptionDateText.charAt(0).toUpperCase() + receptionDateText.slice(1);

  return capitalizedReceptionDateText;
};

export const transformSummaryTime = (dateTimeStart: string, dateTimeEnd: string): string => {
  const momentDateStart = moment(dateTimeStart);
  const momentDateEnd = moment(dateTimeEnd);

  const timeStartHours = momentDateStart.format('HH');
  const timeEndHours = momentDateEnd.format('HH');
  const timeStartMinutes = momentDateStart.format('mm');
  const timeEndMinutes = momentDateEnd.format('mm');

  return I18n.t('dashboard.eat.clickAndCollect.cartSummary.dateTimeRange', {
    timeEndHours,
    timeEndMinutes,
    timeStartHours,
    timeStartMinutes,
  });
};

export const getOfferTemplateSubtitle = (offerTemplate: IOfferTemplate): string => {
  if (offerTemplate.__typename === 'TableServiceOfferTemplate') {
    return '';
  }

  const offer = offerTemplate.nextOrderableOffer;
  if (!offer || !offer.withdrawRange || offerTemplate.period === OfferTemplatePeriod.DAILY) {
    return '';
  }

  const interval = offer.orderRange.split('/');
  const endOrderDate = transformDate(interval[1]);

  const withdrawInterval = offer.withdrawRange.split('/');
  const withdrawDate = transformDate(withdrawInterval[1]);

  return I18n.t('dashboard.eat.clickAndCollect.subtitle', {
    startDate: endOrderDate,
    endDate: withdrawDate,
  });
};

export const formatOfferTemplates = (offerTemplates: Array<INode<IOfferTemplate>>) =>
  flatten(offerTemplates.map(edge => get(edge, 'node') || [])).sort((a, b) => {
    const availableA = Number(
      isNextOffersAvailable(a as getOfferTemplates_list_edges_node_OfferTemplate)
    );
    const availableB = Number(
      isNextOffersAvailable(b as getOfferTemplates_list_edges_node_OfferTemplate)
    );

    if (availableA > availableB) {
      return -1;
    }

    if (availableB > availableA) {
      return 1;
    }

    return 0;
  });
