import React, { createRef, PureComponent, ReactNode, RefObject } from 'react';
import { ScrollView, View, ViewStyle } from 'react-native';
import { NavigationParams } from 'react-navigation';

import HoldingLogo from '../../components/HoldingLogo';
import PageTitle from '../../components/PageTitle';
import ScrollContext from '../../components/ScrollContext';
import { IMedia, isMobile, media } from '../../lib/responsive';
import theme from '../../theme';
import FoodiLogo from '../FoodiLogo';
import { FOODI_LOGO_ORIENTATION } from '../FoodiLogo/utils';
import TabBar from '../TabBar';

export interface IProps {
  canGoBack?: boolean;
  goBackPageName?: string;
  goBackParams?: NavigationParams;
  title?: string;
  tabBarHeight?: number;
  hideLogo?: boolean;
  hideNavigationMenu?: boolean;
  padding?: number;
}

class WebPage extends PureComponent<IProps> {
  private scrollViewRef: RefObject<ScrollView> = createRef<ScrollView>();

  public render(): ReactNode {
    const {
      canGoBack,
      goBackPageName,
      goBackParams,
      title,
      tabBarHeight=50,
      hideNavigationMenu,
      hideLogo,
      padding = theme.margins.unit * 2,
    } = this.props;

    const mainContentStyle = media(styles.mainContent) || {};
    const bodyStyle = media(styles.body) || {};

    mainContentStyle.height = isMobile()
      ? `calc(100vh - ${(tabBarHeight) +
          theme.config.TABLET_MENU_HEADER_HEIGHT}px)`
      : mainContentStyle.height;

    bodyStyle.minHeight = isMobile()
      ? `calc(100vh - ${(tabBarHeight) +
          theme.config.TABLET_MENU_HEADER_HEIGHT +
          (padding * 2)}px)`
      : bodyStyle.minHeight;

    return (
      <>
        <ScrollContext.Provider value={{ scrollViewRef: this.scrollViewRef }}>
          <View style={media(styles.container)}>
            {!isMobile()
              ? !hideNavigationMenu && <TabBar />
              : !hideLogo && (
                  <View style={styles.header}>
                    <FoodiLogo orientation={FOODI_LOGO_ORIENTATION.horizontal} />
                    <HoldingLogo height={HOLDING_LOGO_HEIGHT} width={HOLDING_LOGO_WIDTH} />
                  </View>
                )}
            <ScrollView style={[mainContentStyle]} ref={this.scrollViewRef} >
              <View
                style={[styles.bodyContainer, padding != undefined ? { padding: padding } : {}]}
              >
                <View style={bodyStyle}>
                  {title ? (
                    <PageTitle
                      text={title}
                      canGoBack={canGoBack}
                      goBackPageName={goBackPageName}
                      goBackParams={goBackParams}
                    />
                  ) : null}
                  <View style={{flex:1}}>{this.props.children}</View>
                </View>
              </View>
            </ScrollView>
          </View>

          {/* There is a <div> here because we need position: "fixed" for safari on iOS */}
          {isMobile() && !hideNavigationMenu && (
            <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
              <TabBar />
            </div>
          )}
        </ScrollContext.Provider>
      </>
    );
  }
}

const BODY_MAX_WIDTH = 750;
const HOLDING_LOGO_WIDTH = 85;
const HOLDING_LOGO_HEIGHT = 27;

interface IStyle {
  body: IMedia<ViewStyle>;
  bodyContainer: ViewStyle;
  container: IMedia<ViewStyle>;
  header: ViewStyle;
  mainContent: IMedia<ViewStyle>;
}

const styles: IStyle = {
  body: {
    _desktop: {
      backgroundColor: theme.colors.backgroundTab,
      flexBasis: BODY_MAX_WIDTH,
      flexShrink: 1,
      minHeight: '100vh',
      paddingTop: theme.config.TABLET_MENU_HEADER_HEIGHT,
    },
    _mobile: {
      backgroundColor: theme.colors.backgroundTab,
      flexBasis: BODY_MAX_WIDTH,
      flexShrink: 1,
      minHeight: `calc(100vh - ${theme.config.MOBILE_MENU_HEIGHT +
        theme.config.TABLET_MENU_HEADER_HEIGHT +
        theme.margins.unit * 4}px)`,
      // borderWidth: 5,
      // borderColor: 'green',
    },
    _tablet: {
      backgroundColor: theme.colors.backgroundTab,
      flexBasis: BODY_MAX_WIDTH,
      flexShrink: 1,
      minHeight: `calc(100vh - ${theme.config.TABLET_MENU_HEADER_HEIGHT}px)`,
    },
  },
  bodyContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.margins.unit * 2,
  },
  container: {
    _desktop: {
      flexDirection: 'row',
      height: '100vh',
    },
    _mobile: {
      flexDirection: 'column',
      // borderWidth: 5,
      // borderColor: 'pink',
    },
    _tablet: {
      flexDirection: 'column',
    },
  },
  header: {
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    flexDirection: 'row',
    height: theme.config.TABLET_MENU_HEADER_HEIGHT,
    justifyContent: 'space-between',
    padding: theme.margins.unit * 3,
  },
  mainContent: {
    _desktop: {
      backgroundColor: theme.colors.backgroundTab,
      height: '1OOvh',
    },
    _mobile: {
      backgroundColor: theme.colors.backgroundTab,
      height: `calc(100vh - ${theme.config.MOBILE_MENU_HEIGHT +
        theme.config.TABLET_MENU_HEADER_HEIGHT}px)`,
    },
    _tablet: {
      backgroundColor: theme.colors.backgroundTab,
      height: `calc(100vh - ${theme.config.TABLET_MENU_HEADER_HEIGHT}px)`,
    },
  },
};

export default WebPage;
