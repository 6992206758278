import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';

import {
  formatTransactionAmountWithCurrency,
  isPositiveTransaction,
} from '../../services/amountFormatter';
import theme from '../../theme';
import { IAmount } from '../../types/common';

export interface IProps {
  amount?: IAmount;
  style?: StyleProp<TextStyle>;
}

class TransactionAmount extends PureComponent<IProps> {
  public render(): ReactNode {
    const { amount, style } = this.props;

    if (!amount) {
      return null;
    }

    return (
      <Text style={[this.getStyle(amount.amount), style]}>
        {formatTransactionAmountWithCurrency(amount)}
      </Text>
    );
  }

  private getStyle = (amount: string | null): TextStyle => {
    if (isPositiveTransaction(amount)) {
      return styles.positiveAmount;
    }

    return styles.amount;
  };
}

interface IStyle {
  amount: TextStyle;
  positiveAmount: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    color: theme.colors.textBlack,
  },
  positiveAmount: {
    color: theme.colors.success,
  },
});

export default TransactionAmount;
