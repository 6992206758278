import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import { compose } from 'recompose';

import { IAllergens } from '../../../../../types/common.d';

import AllergensDisclaimerContent, { IProps } from './AllergensDisclaimerContent.component';

const getAllergenList = loader('../../../../../queries/getAllergenList.gql');

export interface IGraphQLProps {
  allergensList: IAllergens;
  isLoading?: boolean;
}

export type IContainerProps = Omit<IProps, keyof IGraphQLProps>;

export default compose<IProps, IContainerProps>(
  graphql(getAllergenList, {
    props: (props: OptionProps): IGraphQLProps => ({
      allergensList: get(props, 'data.__type.enumValues'),
      isLoading: get(props, 'data.loading'),
    }),
  })
)(AllergensDisclaimerContent);
