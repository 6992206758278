import { NavigationInjectedProps, withNavigation } from 'react-navigation';
import { compose, withProps } from 'recompose';
import { IOfferTemplate } from '../../../../../types/common';
import PlusInfo, { IProps } from './PlusInfo.component';

interface IWithExtraProps {
  offerTemplate: IOfferTemplate;
  fullDescription: string;
  onOrderPress: () => void;
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  offerTemplate: ownProps.navigation.getParam('offerTemplate'),
  fullDescription: ownProps.navigation.getParam('fullDescription'),
  onOrderPress: ownProps.navigation.getParam('onOrderPress'),
});

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps)
)(PlusInfo);
