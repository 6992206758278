import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import withNavigation from '../../../../../hoc/withNavigation';
import { DisplayedArticleFamily } from '../../../../../components/ArticlesList';
import { ArticleFamily } from '../../../../../types/clickandcollect/globalTypes';
import { TableServiceFooterType } from '../../../../../components/QuantityFooter';

import { getTableServiceOffer_offer_Offer_offerTemplate as IOfferTemplate } from '../../../../../types/tableService/getTableServiceOffer';
import OfferArticles from './OfferArticles.component';
import { IProps } from '../../../../../components/OfferArticlesPage';

const getOffer = loader('../../../../../queries/tableservice/getTableServiceOffer.gql');

export interface IWithExtraProps {
  offerId: string;
  idTable: string;
  articleFamilyKeys: DisplayedArticleFamily[];
  numColumns: number;
  showQuantityInFooter: boolean;
  footerType: TableServiceFooterType;
}

const articleFamilyKeys = [
    ArticleFamily.STARTER,
    ArticleFamily.SOUP,

    ArticleFamily.DISH,
    ArticleFamily.SANDWICH,
    ArticleFamily.SALAD,
    ArticleFamily.SIDE_DISH,

    ArticleFamily.DAIRY,
    ArticleFamily.DESSERT,
    ArticleFamily.PASTRY,
    ArticleFamily.FRUIT,
    
];

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  offerId: ownProps.navigation.getParam('offerId'),
  idTable: ownProps.navigation.getParam('idTable'),
  articleFamilyKeys,
  numColumns: 1,
  showQuantityInFooter: true,
  footerType: TableServiceFooterType.FOOD,
});

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  offer: IOfferTemplate | undefined;
  refetch?: () => void;
}

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps),
  graphql(getOffer, {
    options: (props: IWithExtraProps): QueryOpts => {
      return {
        variables: {
          idOffer: props.offerId,
        },
      };
    },
    props: (props: OptionProps<IWithExtraProps>): IGraphQLProps => {
      const offer = get(props, 'data.offer');

      return {
        hasError: !!get(props, 'data.error'),
        isLoading: get(props, 'data.loading'),
        refetch: get(props, 'data.refetch'),
        offer: offer ? offer : undefined,
      };
    },
  })
)(OfferArticles);
