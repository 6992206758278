import React, { PureComponent, ReactNode } from 'react';
import { Image } from 'react-native';
import { View } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import FakeInput from '../../../../src/components/FakeInput';
import GhostButton from '../../../../src/components/GhostButton';
import { BUTTON_THEME } from '../../../../src/components/GhostButton';
import Paragraph from '../../../../src/components/Paragraph';
import SubTitle from '../../../../src/components/SubTitle';
import I18n from '../../../../src/lib/i18n';
import theme from '../../../../src/theme';

import SignupLayout from '../../components/SignupLayout';

export interface IProps extends NavigationInjectedProps {
  email?: string;
}

class EmailToValidatePage extends PureComponent<IProps> {
  public render(): ReactNode {
    const { email } = this.props;

    return (
      <form>
        <SignupLayout
          title={I18n.t('signUp.headerTitle')}
          subtitle={I18n.t('signUp.confirmation.title')}
          currentStepNumber={4}
          currentStepStatus="ACTIVE"
        >
          <div style={styles.mainContent}>
            <Image source={theme.images.mail} style={styles.mailIconStyle} />
            <View style={email ? {} : styles.container}>
              {email && <SubTitle text={I18n.t('signUp.confirmation.subTitle')} />}
              <Paragraph text={I18n.t('signUp.confirmation.paragraph')} />
              {email && <FakeInput value={email} />}
              <GhostButton
                text={I18n.t('signUp.confirmation.home')}
                onPress={(): void => {
                  this.props.navigation.navigate('landing');
                }}
                theme={BUTTON_THEME.black}
              />
            </View>
          </div>
        </SignupLayout>
      </form>
    );
  }
}

const styles = {
  container: {
    marginTop: '140px',
  },
  mailIconStyle: {
    height: 85,
    margin: 'auto',
    marginBottom: '81px',
    width: 289,
  },
  mainContent: {
    marginTop: '74px',
  },

  // tslint:disable-next-line:no-any
} as any;

export default EmailToValidatePage;
