import { StyleSheet } from 'react-native';

import theme from '../../../../../../theme';

import { IPlatformStyle } from './OrderDetailsContent.component';

const styles = StyleSheet.create<IPlatformStyle>({
  contentContainer: {
    backgroundColor: theme.colors.backgroundGray,
  },
});

export default styles;
