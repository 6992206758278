import { IOptinCode } from '../../types/common';
import { IAppState } from '../reducer';

export const selectGuestTokenId = (state: IAppState): string | undefined =>
  state.signUp.guestTokenId;

export const selectUserEmail = (state: IAppState): string | undefined => state.signUp.email;

export const selectOptins = (state: IAppState): IOptinCode[] | undefined => state.signUp.optins;

export const selectSignUpToken = (state: IAppState): string | undefined => state.signUp.token;
