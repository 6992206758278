import moment from 'moment';
import { ImageURISource } from 'react-native';
import { NavigationActions, NavigationResetAction, StackActions } from 'react-navigation';
import I18n from '../lib/i18n';
import theme from '../theme';
import { IConcept, IPosType, IPosTypeModel } from '../types/common';
import {
  IActiveOfferTemplate,
  IBookingOfferTemplate,
  INextOrderableBookingOffer,
} from '../types/getPointsOfSale';
import { isWeb } from '../lib/responsive';
import navigator from './navigation';
import { DEFAULT_OFFERS_COUNT } from '../pages/Dashboard/Eat/BookingService/BookingComponent/Booking.component';
import { OfferTemplateSalesType, OfferTemplateWithdrawalType } from '../types/clickandcollect/globalTypes';
import { isOrderRangeValid } from "./offer";

interface IActionInfo {
  content?: string;
  activeOfferTemplates?: IActiveOfferTemplate[];
  bookingOfferTemplate?: IBookingOfferTemplate[];
  isGuestAllowedToOrder?: boolean;
}

export interface IPosTypeConfig {
  color: string;
  getActionsById: (posId: string, info?: IActionInfo) => IPosCardTypeActions;
  icon: ImageURISource;
  key: string;
}

const getOrderAction = (
  activeOfferTemplates: IActiveOfferTemplate[],
  posId: string
): INavigateToOfferAction | undefined => {
  if (isWeb() || !activeOfferTemplates || activeOfferTemplates.length === 0) {
    return undefined;
  }

  const orderableOffers = activeOfferTemplates.filter(
    template => template.nextOrderableOffer && template.nextOrderableOffer.id && template.nextOrderableOffer.available && template.salesType
  ).filter(({ nextOrderableOffer: { orderRange } }) => {
    return isOrderRangeValid(orderRange);
  });

  if (orderableOffers.length === 0) {
    return undefined;
  }

  // Temperay patch shows the order button only when the pos card have only CAT offers.
  const isCATOffers = orderableOffers.some(
    offer =>
      // Check if the offer is a table service offer
      offer.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
  );

  if (!isCATOffers) {
    return undefined;
  }
  return {
    text: I18n.t('dashboard.eat.clickAndCollect.order'),
    color: theme.colors.blue,
    callback: (): void => {
      if (orderableOffers.length > 1) {
        const tableServiceOffers = orderableOffers.filter(
          offer => offer.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
        );

        //If there is a CAT order we should be redirected there
        if (tableServiceOffers.length > 0) {
          let offerId = tableServiceOffers[0].nextOrderableOffer.id;
          navigator.navigate('tableNumber', {
            offerId,
          });
        } else {
          const navigationAction: NavigationResetAction = StackActions.reset({
            actions: [
              NavigationActions.navigate({
                action: NavigationActions.navigate({
                  action: NavigationActions.navigate({
                    routeName: 'eat',
                    params: { tab: 1 },
                  }),
                  routeName: 'dashboardTabs',
                }),
                routeName: 'dashboardStack',
              }),
            ],
            index: 0,
            key: null,
          });
          navigator.dispatch(navigationAction);
        }
      } else {
        const {
          __typename: offerType,
          nextOrderableOffer: { id: offerId },
          salesType,
          withdrawalType,
        } = orderableOffers[0];
        if (offerType !== 'OfferTemplate') {
          navigator.navigate('tableNumber', {
            offerId,
          });
        } else if (
          offerType === 'OfferTemplate' &&
          (withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
            withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE)
        ) {
          navigator.navigate('tableNumber', {
            offerId,
            posId,
            skipTableGroupValidation: true,
            withCSITableValidation: withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE,
            onNextNavigateTo: ({ tableNumber }: { tableNumber: number }): void => {
              navigator.navigate('offerArticles', {
                offerId,
                tableNumber,
              });
            },
          });
        } else {
          salesType === OfferTemplateSalesType.BUNDLE
            ? navigator.navigate('offerBundleStep', {
                offerId,
                stepIndex: 0,
              })
            : navigator.navigate('offerArticles', {
                offerId,
              });
        }
      }
    },
  };
};

const isFullAllDay = (bookingOffer: INextOrderableBookingOffer) => {
  const now = moment(new Date());
  const offerRangeEnd = moment(bookingOffer.orderRange.split('/')[1]);
  return bookingOffer.isFullyBooked || now >= offerRangeEnd;
};

const isClosedAllDays = (bookingOffer: INextOrderableBookingOffer) => {
  return !bookingOffer.published;
};

const validateExpiredOffer = (publishedBookingOffers: INextOrderableBookingOffer[]): boolean => {
  const now = moment(new Date());
  return (
    publishedBookingOffers.length === 1 &&
    now >= moment(publishedBookingOffers[0].orderRange.split('/')[1])
  );
};

const getBookingServiceAction = (
  bookingOfferTemplate: IBookingOfferTemplate[],
  posId: string
): INavigateToBookingServiceAction | undefined => {
  //if no bookingOfferTemplate was retrieved(meaning there is no bookingService active), then there is nothing to be presented
  if (isWeb() || !bookingOfferTemplate || bookingOfferTemplate.length == 0) {
    return undefined;
  }
  let bookingOffers = bookingOfferTemplate[0].nextOrderableOffers.slice();
  //getting next BookingServiceOffers
  //if there are no BookingServiceOffers, or all offers are unpublished there should be no button
  if (
    !Array.isArray(bookingOffers) ||
    !bookingOffers.length ||
    !bookingOffers.some(offer => offer.published)
  ) {
    return undefined;
  }

  //Set length of offers. This will be the Default Offers Size
  if (bookingOffers.length > DEFAULT_OFFERS_COUNT) bookingOffers.length = DEFAULT_OFFERS_COUNT;

  //Get published booking offers
  const publishedBookingOffers = bookingOffers.filter(offer => offer.published);

  //Check if all days are unpublished
  const isAllDaysUnpublished = publishedBookingOffers.every(isClosedAllDays);

  //If the current day already expired and offers of the following days unpublished, button not available
  if (validateExpiredOffer(publishedBookingOffers) || isAllDaysUnpublished) {
    return undefined;
  }

  //Check if all days are full
  //Taking only to account the published offers for button state
  const isFull = publishedBookingOffers.every(isFullAllDay);

  //Checks if the offer for the current day is expired.
  const interval = bookingOffers[0].orderRange.split('/');
  const endOrderDate = new Date(interval[1]);
  const isExpired = new Date() > endOrderDate;
  if (isExpired && isFull) return;

  return {
    text: isFull
      ? I18n.t('dashboard.eat.bookingService.full')
      : I18n.t('dashboard.eat.bookingService.reserve'),
    color: isFull ? theme.colors.red : theme.colors.blue,
    callback: (): void => {
      navigator.navigate('bookingService', { posId });
    },
  };
};

// tslint:disable-next-line: one-variable-per-declaration
const POS_TYPE_MAPPER: { [key in IPosType]: IPosTypeConfig } = {
  BRASSERIE: {
    color: theme.colors.cardBrasserie,
    getActionsById: (posId: string, info?: IActionInfo): IPosCardTypeActions => {
      const actions = {
        navigateToContentPageAction: {
          callback: (): void => {
            navigator.navigate('contentPage', { posId });
          },
          text: I18n.t('pointOfSale.actions.moreInfo'),
        },
        navigateToMenuAction: {
          callback: (activeDayIndex?: number): void => {
            navigator.navigate('menu', { activeDayIndex, posId });
          },
          text: I18n.t('pointOfSale.actions.seeCard'),
        },
      };

      const actionsOfPos: any = {};
      const bookingOrderAction =
        info &&
        info.bookingOfferTemplate &&
        getBookingServiceAction(info.bookingOfferTemplate, posId);
      if (bookingOrderAction) {
        actionsOfPos.navigateToBookingServiceAction = bookingOrderAction;
      }
      return { ...actions, ...actionsOfPos };
    },
    icon: theme.images.logoBrasserie,
    key: 'pointOfSale.type.brasserie',
  },
  CAFETERIA: {
    color: theme.colors.cardCafeteria,
    getActionsById: (posId: string, info?: IActionInfo): IPosCardTypeActions => {
      // tslint:disable-next-line: one-variable-per-declaration
      const actions = {
        navigateToContentPageAction: {
          callback: (): void => {
            navigator.navigate('contentPage', { posId });
          },
          text: I18n.t('pointOfSale.actions.moreInfo'),
        },
        navigateToMenuAction: {
          callback: (activeDayIndex?: number): void => {
            navigator.navigate('menu', { activeDayIndex, posId });
          },
          text: I18n.t('pointOfSale.actions.seeCard'),
        },
      };
      const orderAction =
        info &&
        info.isGuestAllowedToOrder &&
        info.activeOfferTemplates &&
        getOrderAction(info.activeOfferTemplates, posId);

      const bookingOrderAction =
        info &&
        info.bookingOfferTemplate &&
        getBookingServiceAction(info.bookingOfferTemplate, posId);

      const actionsOfPos: any = {};
      if (orderAction) {
        actionsOfPos.navigateToOfferAction = orderAction;
      }
      if (bookingOrderAction) {
        actionsOfPos.navigateToBookingServiceAction = bookingOrderAction;
      }

      return { ...actions, ...actionsOfPos };
    },
    icon: theme.images.cup,
    key: 'pointOfSale.type.cafeteria',
  },
  OTHER: {
    color: theme.colors.background,
    getActionsById: (posId: string, info?: IActionInfo): IPosCardTypeActions => {
      const actions = {
        navigateToMenuAction: {
          callback: (activeDayIndex?: number): void => {
            navigator.navigate('menu', { activeDayIndex, posId });
          },
          text: I18n.t('pointOfSale.actions.seeCard'),
        },
      };
      const actionsOfPos: any = {};
      const bookingOrderAction =
        info &&
        info.bookingOfferTemplate &&
        getBookingServiceAction(info.bookingOfferTemplate, posId);
      if (bookingOrderAction) {
        actionsOfPos.navigateToBookingServiceAction = bookingOrderAction;
      }
      return { ...actions, ...actionsOfPos };
    },
    icon: theme.images.euro,
    key: 'pointOfSale.type.other',
  },
  SELF: {
    color: theme.colors.cardSelf,
    getActionsById: (posId: string, info?: IActionInfo): IPosCardTypeActions => {
      const actions = {
        navigateToMenuAction: {
          callback: (activeDayIndex?: number): void => {
            navigator.navigate('menu', { activeDayIndex, posId });
          },
          text: I18n.t('pointOfSale.actions.seeCard'),
        },
      };
      const orderAction =
        info &&
        info.isGuestAllowedToOrder &&
        info.activeOfferTemplates &&
        getOrderAction(info.activeOfferTemplates, posId);

      const bookingOrderAction =
        info &&
        info.bookingOfferTemplate &&
        getBookingServiceAction(info.bookingOfferTemplate, posId);

      const actionsOfPos: any = {};
      if (orderAction) {
        actionsOfPos.navigateToOfferAction = orderAction;
      }
      if (bookingOrderAction) {
        actionsOfPos.navigateToBookingServiceAction = bookingOrderAction;
      }

      return { ...actions, ...actionsOfPos };
    },
    icon: theme.images.dish,
    key: 'pointOfSale.type.self',
  },
};

export interface IPosCardTypeAction {
  callback: () => void;
  text: string;
  color?: string;
}

interface INavigateToMenuAction extends IPosCardTypeAction {
  callback: (activeIndex?: number) => void;
}

interface INavigateToContentPageAction extends IPosCardTypeAction {
  callback: (posId?: string) => void;
}

interface INavigateToOfferAction extends IPosCardTypeAction {
  callback: () => void;
  color: string;
}

interface INavigateToBookingServiceAction extends IPosCardTypeAction {
  callback: () => void;
  color: string;
}

export interface IPosCardTypeActions {
  navigateToContentPageAction?: INavigateToContentPageAction;
  navigateToMenuAction?: INavigateToMenuAction;
  navigateToOfferAction?: INavigateToOfferAction;
  navigateToBookingServiceAction?: INavigateToBookingServiceAction;
}

export const getPosTypeTranslated = (type: IPosType): string => {
  return I18n.t(POS_TYPE_MAPPER[type].key);
};

export const getPointOfSaleBackground = (
  image: string,
  typeModel: IPosTypeModel,
  concept?: IConcept
): ImageURISource | undefined => {
  if (image) {
    return { uri: image };
  }
  if (concept && concept.image) {
    return { uri: concept.image };
  }
  if (typeModel && typeModel.image) {
    return { uri: typeModel.image };
  }

  return undefined;
};

export const getPosTypeIcon = (type: IPosType): ImageURISource => {
  return POS_TYPE_MAPPER[type].icon;
};

export const getPosTypeColor = (type: IPosType): string => {
  return POS_TYPE_MAPPER[type].color;
};

export const getPosTypeActions = (
  type: IPosType,
  posId: string,
  info?: IActionInfo
): IPosCardTypeActions => {
  return POS_TYPE_MAPPER[type].getActionsById(posId, info);
};

export const getPrimaryAction = (
  type: IPosType,
  posId: string,
  info?: IActionInfo
): IPosCardTypeAction | undefined => {
  const actions = getPosTypeActions(type, posId, info);
  return actions.navigateToContentPageAction || actions.navigateToMenuAction;
};
