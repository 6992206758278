import DeviceBrightness from '@adrianso/react-native-device-brightness';

const MAX_BRIGHTNESS_VALUE = 1;

const getBrightness = async (): Promise<number> => {
  return await DeviceBrightness.getBrightnessLevel();
};

const setBrightness = async (brightnessLevel: number): Promise<void> => {
  await DeviceBrightness.setBrightnessLevel(brightnessLevel);
};

const setBrightnessToMaxValue = async (): Promise<void> => {
  await DeviceBrightness.setBrightnessLevel(MAX_BRIGHTNESS_VALUE);
};

export default {
  getBrightness,
  setBrightness,
  setBrightnessToMaxValue,
  MAX_BRIGHTNESS_VALUE
};
