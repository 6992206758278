import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { QueryOpts } from 'react-apollo/types';
import BookingOfferSlots from './BookingOfferSlots.component';

const query = loader('../../queries/clickandcollect/getOfferSlots.gql');

export default graphql(query, {
  options: (props: any): QueryOpts => ({
    fetchPolicy: 'network-only',
    variables: {
      idOffer: props.offer.id,
    },
  }),
  props: (props: any): any => ({
    hasError: !!get(props, 'data.error'),
    isLoading: get(props, 'data.loading') || get(props, 'ownProps.isLoading'),
    offer: get(props, 'data.offer') || undefined,
  }),
})(BookingOfferSlots);
