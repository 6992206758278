import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { FlatList, View, ViewStyle } from 'react-native';

import Logger from '../../lib/logger';
import Button from '../Button';
import ChoiceItem, { IProps as IChoiceItemProps } from '../ChoiceItem';
import Touchable from '../Touchable';

import styles from './styles';

export interface IChoice extends IChoiceItemProps {
  value: string;
}

export interface IProps {
  buttonText: string;
  choices: IChoice[];
  handleChoice: (choice: string) => Promise<void>;
  testID?: string;
}

export interface IState {
  activeChoiceValue: string | null;
  isLoading: boolean;
}

class SelectableList extends PureComponent<IProps> {
  public state: IState = {
    activeChoiceValue: null,
    isLoading: false,
  };

  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <FlatList
          testID={this.props.testID}
          data={this.props.choices}
          extraData={this.state}
          renderItem={this.renderItem}
          keyExtractor={(item: IChoice): string => item.value}
        />
        <Button
          testID={`${this.props.testID}_confirmButton`}
          style={styles.button}
          text={this.props.buttonText}
          onPress={this.onButtonPress(this.state.activeChoiceValue)}
          isLoading={this.state.isLoading}
          disabled={!this.state.activeChoiceValue}
        />
      </View>
    );
  }

  public renderItem = ({ item }: { item: IChoice }): ReactElement<Touchable> => {
    return (
      <Touchable
        testID={`${this.props.testID}_languageButton_${item.value}`}
        useOpacity={true}
        onPress={this.onChoicePress(item.value)}
      >
        <ChoiceItem
          label={item.label}
          image={item.image}
          isActive={item.value === this.state.activeChoiceValue}
        />
      </Touchable>
    );
  };

  private onButtonPress = (choiceValue: string | null): (() => Promise<void>) => async (): Promise<
    void
  > => {
    if (!choiceValue) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      await this.props.handleChoice(choiceValue);
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private onChoicePress = (item: string): (() => void) => (): void => {
    this.setState({ activeChoiceValue: item });
  };
}

export interface IStyle {
  button: ViewStyle;
  container: ViewStyle;
}

export default SelectableList;
