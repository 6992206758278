import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  Platform,
  SectionList,
  TouchableOpacity,
} from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import store from '../../redux/store';
import DetailedProductRow from '../DetailedProductRow';
import LoadingCard from '../LoadingCard';
import I18n from '../../lib/i18n';
import { isMobile } from '../../lib/responsive';
import theme from '../../theme';

import { FONT_FAMILIES, FONT_WEIGHTS, fontMaker } from '../../theme/fonts';

import {
  getOffer_offer_Offer as IOffer,
  getOffer_offer_Offer_offerItems as IOfferItem,
} from '../../types/clickandcollect/getOffer';
import { OfferTemplateSalesType, ArticleFamily } from '../../types/clickandcollect/globalTypes';
import {
  getTableServiceOffer_offer_Offer as ITableOffer,
  getTableServiceOffer_offer_Offer_offerItems as ITableOfferItem,
} from '../../types/tableService/getTableServiceOffer';

export enum LocalArticleFamily {
  UNSORTED = 'UNSORTED',
}

export const articleFamilyKeys = [
  ArticleFamily.STARTER,
  ArticleFamily.SOUP,
  ArticleFamily.DISH,
  ArticleFamily.SANDWICH,
  ArticleFamily.SALAD,
  ArticleFamily.SIDE_DISH,
  ArticleFamily.DAIRY,
  ArticleFamily.DESSERT,
  ArticleFamily.PASTRY,
  ArticleFamily.FRUIT,
  ArticleFamily.BREAD,
  ArticleFamily.SNACKING,
  ArticleFamily.BEVERAGE,
  ArticleFamily.MISCELLANEOUS,
  LocalArticleFamily.UNSORTED,
];

export type DisplayedArticleFamily = ArticleFamily | LocalArticleFamily;

export interface IProps extends NavigationInjectedProps {
  isLoading: boolean;
  offer: IOffer | ITableOffer;
  articleFamilyKeys: DisplayedArticleFamily[];
  numColumns?: number;
  showQuantityInFooter?: boolean;
  remainingQuantity?: number;
  hidePrice?: boolean;
  selectionMode?: 'single' | 'multiple';
  articleFamilies?: ArticleFamily[];
  refreshControl: any;
}

export interface IState {
  noticeText: string;
  availableFamilies: string[];
}

const ArticlesList = ({
  isLoading,
  offer,
  articleFamilyKeys,
  numColumns = 1,
  showQuantityInFooter = false,
  remainingQuantity,
  hidePrice = false,
  selectionMode = 'multiple',
  articleFamilies,
  refreshControl,
  navigation,
}: IProps) => {
  const sectionListRef = useRef(null);
  const flatListRef = useRef(null);

  const [offerId, setOfferId] = useState(offer?.id);
  const [noticeText, setNoticeText] = useState('');
  const [availableFamilies, setAvailableFamilies] = useState<string[]>([]);
  const [currentIndexSection, setCurrentIndexSection] = useState(0);
  const [currentIndexFlat, setCurrentIndexFlat] = useState(0);
  const [selectedFamilySection, setSelectedFamilySection] = useState(0);
  const [selectedFamilyFlat, setSelectedFamilyFlat] = useState(0);
  const [isOnPress, setIsOnPress] = useState(false);

  const DEFAULT_ELEMENTS = 20;
  const selectedFamilies = offer?.offerTemplate?.mealHeartRule?.selectedFamilies;
  const quantityRules = offer?.offerTemplate?.mealHeartRule?.quantityRules;
  const mealQuantity = quantityRules?.find(rule => rule.property === 'mealQuantity');

  useEffect(() => {
    if (offer?.id !== undefined && ((offer && !offerId) || offer?.id !== offerId)) {
      setOfferId(offer.id);
      setCurrentIndexSection(0);
      setCurrentIndexFlat(0);
      setSelectedFamilySection(0);
      setSelectedFamilyFlat(0);
      setIsOnPress(false);
    }
  }, [offer]);

  useEffect(() => {
    noticeTextHandler();
  }, [quantityRules, isLoading]);

  const noticeTextHandler = () => {
    if (!selectedFamilies && !quantityRules) return;
    let noticeText = '';
    const onlyAvailableFamilies = selectedFamilies.filter(
      (family: string) => availableFamilies.includes(family)
    );

    const families = onlyAvailableFamilies
      .map((family: string) =>
        I18n.t(`dashboard.eat.clickAndCollect.articles.type.${family.toLowerCase()}`)
      )
      .join(', ');

    if (mealQuantity?.min === 0) {
      if (mealQuantity?.max === 0) {
        setNoticeText(noticeText);
        return;
      }
      if (mealQuantity?.max === 1) {
        // if min == 0 and max == 1
        noticeText = I18n.t('dashboard.eat.clickAndCollect.mealHeartRule.min0_max1', { families });
      } else {
        // if min == 0 and max > 1
        noticeText = I18n.t('dashboard.eat.clickAndCollect.mealHeartRule.maxBigger1', {
          families,
          maxQuantity: mealQuantity?.max,
        });
      }
    } else if (mealQuantity?.min === 1 && mealQuantity?.max === 1) {
      // if min == 1 and max == 1
      noticeText = I18n.t('dashboard.eat.clickAndCollect.mealHeartRule.min1_max1', { families });
    } else if (mealQuantity?.min === mealQuantity?.max) {
      noticeText = I18n.t('dashboard.eat.clickAndCollect.mealHeartRule.min_equal_to_max', {
        families,
        maxQuantity: mealQuantity?.max,
      });
    } else {
      // if min >= 1 and max > 1
      noticeText = I18n.t('dashboard.eat.clickAndCollect.mealHeartRule.minBigger1', {
        families,
        maxQuantity: mealQuantity?.max,
        minQuantity: mealQuantity?.min,
      });
    }
    setNoticeText(noticeText);
  };

  const offerItems: Array<IOfferItem | ITableOfferItem> = offer ? offer.offerItems : [];
  const backgroundColor = isMobile() ? 'transparent' : '#F9FAFB';
  const order = offer && store.getState().clickAndCollect.elements[offer.id];
  const salesType = offer?.offerTemplate?.salesType;

  const checkForOfferItemsInCart = (item: IOfferItem) => {
    const temp = order?.filter(el => el.elementId === item.id) || [];
    return !!temp.length;
  };
  const filterOutOfStockAndCart = (item: IOfferItem) => {
    //if item is out of stock but is on the cart the item will not be filtered.
    return checkForOfferItemsInCart(item)
      ? item.quantityRemaining >= 0
      : item.quantityRemaining > 0;
  };

  const handleAvailableFamiliesForNoticeText = (family: string) => {
    if (!availableFamilies.includes(family)) {
      setAvailableFamilies([...availableFamilies, family]);
    }
  };

  const SeparatorComponent = (): ReactElement<View> => <View style={styles.separator} />;

  const renderEmptyList = (): ReactElement<View> => (
    <View style={styles.emptyContainer}>
      <Text>{I18n.t('pointOfSale.list.emptyContent')}</Text>
    </View>
  );

  const renderMealHeartQuantityRule = (noticeText: string): ReactElement<View> => {
    const IconInformation = theme.images.iconInformationSVG;
    const IconInformationDimensions = 20;

    return (
      <View style={styles.ruleInfoContainer}>
        <View style={[styles.infoIconContainer, { height: IconInformationDimensions }]}>
          <IconInformation width={IconInformationDimensions} height={IconInformationDimensions} />
        </View>
        <Text style={styles.ruleInfoText}>
          {/* the View inside the Text breaks the text alignment on iOS */}
          {Platform.OS === 'ios' ? (
            '\t'
          ) : (
            <View style={{ width: IconInformationDimensions + theme.margins.cardUnit }} />
          )}
          {noticeText}
        </Text>
      </View>
    );
  };

  const renderArticlesByType = (
    articleFamilyKeys: DisplayedArticleFamily[],
    articles: IOfferItem[],
    noticeText: string,
    selectedFamilies: string[],
    onAvailableFamily: (family: string) => void,
    salesType: OfferTemplateSalesType,
    sectionListRef: any,
    flatListRef: any,
    selectionMode?: string,
    articleFamilies?: ArticleFamily[]
  ): ReactNode | undefined => {
    const filterArticlesByType = articleFamilyKeys.map(key => {
      const articleFilter = articles.filter(article => {
        const family: ArticleFamily | null = article.inheritedFamily;
        return key !== LocalArticleFamily.UNSORTED
          ? family === key
          : !family || !articleFamilyKeys.includes(family);
      });
      if (articleFilter.length > 0) {
        articleFilter.sort((a, b) => (a.inheritedLabel > b.inheritedLabel ? 1 : -1));
      }
      const typeTitle = {
        translatedTitle: I18n.t(
          `dashboard.eat.clickAndCollect.articles.type.${key.toLowerCase()}`,
          {
            count: articleFilter.length,
          }
        ),
        type: key,
      };

      return {
        title: typeTitle,
        data: articleFilter,
        totalElements: articleFilter.length,
      };
    });

    const articlesByType = filterArticlesByType
      .filter(element => element.data.length > 0)
      .map((element, index) =>{
        onAvailableFamily(element.title.type);
        return ({ ...element, title: { ...element.title, index } })
      });

    const renderFamilyTypeTitleWithRule = ({ section }) => {
      const includes = selectedFamilies && selectedFamilies.includes(section.title.type);
      const isFreeSale = salesType === OfferTemplateSalesType.FREE_SALE;
      if (section && section.data.length === 0) {
        return null;
      }
      if (includes) {
        return (
          <>
            <Text style={styles.titleType}>{section.title.translatedTitle}</Text>
            {isFreeSale && !!noticeText && renderMealHeartQuantityRule(noticeText)}
          </>
        );
      }
      return <Text style={styles.titleType}>{section.title.translatedTitle}</Text>;
    };

    const handleOnPress = (index: number) => {
      setIsOnPress(true);
      sectionListRef.current.scrollToLocation({
        animated: true,
        itemIndex: 0,
        sectionIndex: index,
        viewOffset: -1,
      });
      setCurrentIndexFlat(index);
      setSelectedFamilySection(index);
      setSelectedFamilyFlat(index);
    };

    const handleViewableItemsChange = ({ viewableItems }) => {
      if (viewableItems.length >= 1) {
        if (viewableItems[0].section.title.index !== selectedFamilySection) {
          setCurrentIndexSection(viewableItems[0].section.title.index);
          setCurrentIndexFlat(viewableItems[0].section.title.index);
        }
      }
    };

    const handleOnScroll = (event: { nativeEvent: any }) => {
      const nativeEvent = event?.nativeEvent;
      if (
        nativeEvent &&
        (nativeEvent.contentSize.height - nativeEvent.layoutMeasurement.height).toFixed(2) ===
          nativeEvent.contentOffset.y.toFixed(2)
      ) {
        setSelectedFamilySection(articlesByType.length - 1);
      }
    };

    const handleInitialNumToRender = (data: any) => {
      let numElements = 0;
      data.forEach((element: { totalElements: number }) => {
        numElements = numElements + element.totalElements;
      });
      return DEFAULT_ELEMENTS + numElements;
    };

    const Header = () => {
      const headerTestID = 'articleFamilyList';
      const handleRenderItem = ({ item, index }) => {
        const HeaderContainerStyle =
          index === currentIndexFlat ? styles.selectedFamily : styles.unselectedFamily;
        return (
          <TouchableOpacity
            testID={`${headerTestID}_${item.type}`}
            style={HeaderContainerStyle}
            onPress={() => handleOnPress(index)}
          >
            <Text style={styles.navigationBar}>{item}</Text>
          </TouchableOpacity>
        );
      };

      return (
        <View style={styles.headerContainer}>
          <FlatList
            testID={headerTestID}
            horizontal
            ref={flatListRef}
            initialScrollIndex={selectedFamilyFlat}
            data={articlesByType.map(({ title }) => title.translatedTitle)}
            renderItem={handleRenderItem}
            showsHorizontalScrollIndicator={false}
            onScrollToIndexFailed={info => {
              setTimeout(() => {
                flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
              }, 500);
            }}
          />
        </View>
      );
    };
    return (
      <>
        {articlesByType.length > 1 && <Header />}
        <SectionList
          testID="articlesList"
          onViewableItemsChanged={!isOnPress ? handleViewableItemsChange : null}
          onScroll={handleOnScroll}
          contentContainerStyle={styles.sectionListContentContainer}
          ref={sectionListRef}
          sections={articlesByType}
          ListEmptyComponent={renderEmptyList()}
          renderItem={renderItem}
          keyExtractor={extractItemKey}
          renderSectionHeader={renderFamilyTypeTitleWithRule}
          ItemSeparatorComponent={SeparatorComponent}
          onMomentumScrollBegin={() => {
            setIsOnPress(false);
            setSelectedFamilySection(currentIndexSection);
            setSelectedFamilyFlat(currentIndexFlat);
          }}
          onMomentumScrollEnd={() => {
            setSelectedFamilySection(currentIndexSection);
            setSelectedFamilyFlat(currentIndexFlat);
          }}
          onTouchMove={() => {
            setSelectedFamilySection(currentIndexSection);
            setSelectedFamilyFlat(currentIndexFlat);
          }}
          initialNumToRender={handleInitialNumToRender(articlesByType)}
          refreshControl={refreshControl}
          onScrollToIndexFailed={info => {
            setTimeout(() => {
              sectionListRef.current?.scrollToLocation({
                animated: true,
                itemIndex: 0,
                sectionIndex: info.index,
                viewOffset: -1,
              });
            }, 500);
          }}
        />
      </>
    );

    // selection mode for c&c formula, see if still necessary
    /*   
    selectionMode ===
      'single' ?  <ArticlesListRadio
          key={type}
          articlesType={<Text style={styles.titleType}>{typeTitle}</Text>}
          articles={articlesByType}
          offer={offer}
          emptyList={renderEmptyList()}
          articleFamilies={articleFamilies!}
        /> : (
    ); */
  };

  const extractItemKey = (item: IOfferItem): string => item.id;

  const renderItem = (arg: ListRenderItemInfo<IOfferItem>): ReactElement<{}> => {
    const { item } = arg;
    return (
      <View
        style={StyleSheet.flatten([styles.itemContainer, { flexBasis: `${100 / numColumns}%` }])}
        // key={item.id}
      >
        <DetailedProductRow
          testID={`articlesList_detailedProductRow_${item.id}`}
          offer={offer}
          item={item}
          sizeDivider={numColumns}
          showQuantityInFooter={showQuantityInFooter}
          remainingQuantity={remainingQuantity}
          hidePrice={hidePrice}
          onPress={openArticleDetail(item, offer)}
          disabled={false}
        />
      </View>
    );
  };

  const openArticleDetail = (item: IOfferItem, offer: IOffer | ITableOffer) => () => {
    navigation.navigate('offerArticlesDetail', {
      item,
      offer,
    });
  };

  /**
   * Observe changes to isLoading prop.
   * Once isLoading prop changes, the values used to control the position of the selected family
   * section are reset to the initial values.
   *
   * This is required, because in the scenario when the user clicks in the button to move forward to
   * the next screen and some of the selected products are out of stock, the user automatically
   * navigates back to the list of products screen.
   * Then the app is fetching the updated list of products, but it's necessary to reset the
   * values that control the position of the selected family section, otherwise the app is
   * risking trying to scroll to an index in the flat list of families, that doesn't exist, meaning,
   * trying to access an index out of bounds, which would cause the app to crash.
   *
   * To avoid this, it's prudent to reset the position values to the initial values at every loading.
   */
  useEffect(() => {
    setCurrentIndexSection(0);
    setCurrentIndexFlat(0);
    setSelectedFamilySection(0);
    setSelectedFamilyFlat(0);
    setAvailableFamilies([]);
  }, [isLoading]);

  return isLoading ? (
    <LoadingCard
      isLoading={isLoading}
      style={[styles.contentContainer, { backgroundColor }]}
      big
      noPadding
    />
  ) : (
    <>
      {offer &&
        offerItems &&
        renderArticlesByType(
          articleFamilyKeys,
          offerItems.filter(filterOutOfStockAndCart),
          noticeText,
          selectedFamilies,
          handleAvailableFamiliesForNoticeText,
          salesType,
          sectionListRef,
          flatListRef,
          selectionMode,
          articleFamilies
        )}
    </>
  );
};

export interface IStyle {
  container: ViewStyle;
  page: ViewStyle;
}

const styles = StyleSheet.create({
  contentContainer: {
    padding: 0,
  },
  sectionListContentContainer: {
    paddingVertical: 0,
    backgroundColor: theme.colors.white,
  },
  separator: {
    backgroundColor: theme.colors.blueGray,
    alignSelf: 'center',
    width: '90%',
    height: 1,
  },
  emptyContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  itemContainer: {
    padding: 4,
    paddingLeft: theme.margins.padding,
    paddingRight: theme.margins.padding,
  },
  titleType: {
    width: '100%',
    paddingLeft: theme.margins.padding + 4,
    paddingRight: theme.margins.padding,
    paddingTop: 16,
    paddingBottom: theme.margins.padding,
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.black,
    backgroundColor: theme.colors.grayLightAlternative,
  },
  headerContainer: {
    backgroundColor: theme.colors.grayLightAlternative,
  },
  navigationBar: {
    width: '100%',
    minHeight: 50,
    paddingLeft: theme.margins.padding + 4,
    paddingRight: theme.margins.padding,
    paddingTop: 16,
    paddingBottom: theme.margins.padding,
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 14,
    lineHeight: 19,
    color: theme.colors.black,
    backgroundColor: theme.colors.grayLightAlternative,
  },
  unselectedFamily: {
    borderBottomWidth: 7,
    borderBottomColor: '#D8D8D8',
  },
  selectedFamily: {
    borderBottomWidth: 7,
    borderBottomColor: theme.colors.greenLighter,
  },
  ruleInfoContainer: {
    backgroundColor: theme.colors.greenDry,
    paddingVertical: theme.margins.cardUnit,
    paddingHorizontal: theme.margins.padding,
  },
  ruleInfoText: {
    ...theme.fonts.header,
    color: theme.colors.black,
    textAlign: 'center',
    alignSelf: 'stretch',
    width: '100%',
  },
  infoIconContainer: {
    position: 'absolute',
    top: '19%',
    left: '4%',
  },
});

export default ArticlesList;
