import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../../../../hoc/withNavigation';

import PointsOfSaleSeeMore from './PointsOfSaleSeeMore.component';

export type IConnectedProps = NavigationInjectedProps;

export default compose<IConnectedProps, {}>(withNavigation)(PointsOfSaleSeeMore);
