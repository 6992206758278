import { StyleSheet, ViewStyle, TextStyle } from 'react-native';

import theme from '../../theme';

interface IStyle {
  warningWrapper: ViewStyle;
  iconWrapper: ViewStyle;
  warningTextSmall: TextStyle;
  warningTextNormal: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  warningWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12,
    paddingRight: 16,
  },
  iconWrapper: {
    display: 'flex',
    marginRight: 7,
  },
  warningTextSmall: {
    ...theme.fonts.warningSmall,
    color: theme.colors.black,
  },
  warningTextNormal: {
    ...theme.fonts.warningNormal,
    color: theme.colors.black,
  },
});

export default styles;
