export const allergensMapper = {
  CELERY: 'celery',
  EGG: 'egg',
  FISH: 'fish',
  GLUTEN: 'gluten',
  LUPIN: 'lupin',
  MILK: 'milk',
  MOLLUSC: 'mollusc',
  MUSTARD: 'mustard',
  NUT: 'nut',
  PEANUT: 'peanut',
  SESAME: 'sesame',
  SHELLFISH: 'shellfish',
  SOYA: 'soya',
  SULFITE: 'sulfite',
  ALLERGEN_FREE: 'allergenFree',
  INFO_INCOMPLETE: 'infoIncomplete',
  INFO_NOT_DISCLOSED: 'infoNotDisclosed',
};
