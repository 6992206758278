import I18n from '../../lib/i18n';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import theme from '../../theme';
import LoadingView from '../LoadingView';
import { moderateScale } from '../../services/scaleService';
import Button from '../Button';
import { NavigationInjectedProps } from 'react-navigation';
import { transformSummaryTime } from '../../services/offersFormatter';
import { RFValue } from 'react-native-responsive-fontsize';

export interface IProps extends NavigationInjectedProps {
  isLoading: boolean;
  order: any;
  navigation: any;
  testID?: string;
}

export default class BookingExistingOrder extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, order, testID } = this.props;
    if (!isLoading) {
      const withdrawRange = order.node.withdrawRange;
      const [withdrawStart, withdrawEnd] = withdrawRange.split('/');
      const timeSlot = transformSummaryTime(withdrawStart, withdrawEnd);
      const posName = order.node.bookingOffer.bookingOfferTemplate.pos.name;
      return (
        <>
          <View style={styles.container}>
            <View style={styles.cardManger}>
              <View style={styles.cardMangerBody}>
                <Text style={styles.cardTitle}>
                  {I18n.t('dashboard.eat.bookingService.modal.bookedOtherPosTitle')}
                </Text>
                <Text style={styles.cardDescription}>
                  {I18n.t('dashboard.eat.bookingService.modal.bookedOtherPosDescription')}
                </Text>
                <Text numberOfLines={1} style={styles.name}>
                  {posName}
                </Text>
                <Text style={styles.slot}>{timeSlot}</Text>
                <Button
                  testID={testID}
                  style={styles.buttonStyle}
                  textStyle={styles.buttonTextStyle}
                  text={I18n.t('dashboard.eat.bookingService.modal.bookedOtherPosButton')}
                  onPress={this.goToBooking}
                />
              </View>
            </View>
          </View>
        </>
      );
    }
    return <LoadingView isLoading={isLoading} />;
  }

  private goToBooking = (): void => {
    const { order, navigation } = this.props;
    const posId = order.node.bookingOffer?.bookingOfferTemplate.pos.id;
    const withdrawRange = order.node.bookingOffer?.withdrawRange;
    navigation.push('bookingService', { posId, withdrawRange });
  };
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  cardManger: {
    width: '100%',
    alignItems: 'center',
  },
  cardMangerBody: {
    marginTop: 2 * theme.margins.unit,
    flexShrink: 1,
    width: '95%',
    height: '65%',
    justifyContent: 'center',
    borderRadius: moderateScale(11),
    backgroundColor: '#F0F0F0',
  },
  cardTitle: {
    ...theme.fonts.reservationTitle,
    color: theme.colors.black,
    textAlign: 'center',
    letterSpacing: 0,
    marginTop: theme.margins.cardUnit,
    marginBottom: theme.margins.cardUnit,
  },
  cardDescription: {
    ...theme.fonts.regularText,
    color: theme.colors.black,
    textAlign: 'center',
    fontStyle: 'italic',
    letterSpacing: 0,
    margin: theme.margins.cardUnit,
    paddingBottom: theme.margins.padding,
  },
  buttonStyle: {
    height: '18%',
    width: '85%',
    backgroundColor: '#3B99FC',
    alignSelf: 'center',
  },
  buttonTextStyle: {
    color: theme.colors.textWhite,
    textAlign: 'center',
    ...theme.fonts.reservationButton,
  },
  slot: {
    color: theme.colors.black,
    ...theme.fonts.reservationTitle,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: RFValue(18),
    lineHeight: RFValue(24),
    paddingBottom: theme.margins.padding,
  },
  name: {
    color: theme.colors.black,
    ...theme.fonts.reservationTitle,
    fontSize: RFValue(18),
    lineHeight: RFValue(24),
    letterSpacing: 0,
    textAlign: 'center',
    paddingLeft: theme.margins.padding * 2,
    paddingRight: theme.margins.padding * 2,
  },
});
