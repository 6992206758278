import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import IconBand from '../IconBand';

class SignUpSideContent extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Image source={theme.images.mobile} style={styles.icon} />
        </View>
        <IconBand brandLess />
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  icon: ImageStyle;
  iconContainer: ImageStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    backgroundColor: '#FFF3B1',
    overflow: 'hidden',
    width: '50%',
  },
  icon: {
    height: 255,
    width: 725,
  },
  iconContainer: {
    alignItems: 'center',
    marginBottom: theme.margins.unit * 7,
    marginTop: theme.margins.unit * 19,
  },
});

export default SignUpSideContent;
