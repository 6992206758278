import {
  NavigationParams,
  NavigationState,
  NavigationRoute,
  NavigationScreenProp,
} from 'react-navigation';
import queryString from 'query-string';

export const getUserSignUpParams = (
  navigation:
    | NavigationScreenProp<NavigationRoute<NavigationParams>, NavigationParams>
    | NavigationScreenProp<NavigationState, NavigationParams>
): {
  holding?: string;
  badgeNumber?: string;
  serialNumber?: string;
  lastName?: string;
  email?: string;
} => {
  const signInRedirection = navigation.getParam('signInRedirection');
  const { params } = parseSignInRedirection(signInRedirection || '');
  return {
    holding: navigation.getParam('holding') || params.holding,
    badgeNumber: navigation.getParam('badgeNumber') || params.badgeNumber,
    serialNumber: navigation.getParam('serialNumber') || params.serialNumber,
    lastName: navigation.getParam('lastName') || params.lastName,
    email: navigation.getParam('email') || params.email,
  };
};

export const parseSignInRedirection = (
  signInRedirection: string
): { routeName?: string; params: NavigationParams } => {
  const [routeName, querySearch] = signInRedirection.split('?');
  return {
    routeName,
    params: queryString.parse(querySearch),
  };
};
