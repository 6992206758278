import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import I18n from '../../lib/i18n';
import { IProps as ILegalInfoSectionProps } from '../LegalInfoSection';

import LegalInfoSections, { IProps } from './LegalInfoSections.component';

export interface IWithProfileSectionsProps {
  legalInfoSections: ILegalInfoSectionProps[];
}

const withLegalInfoSectionsProps = (
  ownProps: NavigationInjectedProps
): IWithProfileSectionsProps => ({
  legalInfoSections: [
    {
      id: 'termsOfSales',
      onPress: (): void => {
        ownProps.navigation.navigate('termsOfSales');
      },
      title: I18n.t('dashboard.profile.section.termsOfSales'),
    },
    {
      id: 'termsOfService',
      onPress: (): void => {
        ownProps.navigation.navigate('termsOfService');
      },
      title: I18n.t('dashboard.profile.section.termsOfService'),
    },
    {
      id: 'personalData',
      onPress: (): void => {
        ownProps.navigation.navigate('personalData');
      },
      title: I18n.t('dashboard.profile.section.personalData'),
    },
    {
      id: 'cookiesPolicy',
      onPress: (): void => {
        ownProps.navigation.navigate('cookiesPolicy');
      },
      title: I18n.t('dashboard.profile.section.cookiesPolicy'),
    },
  ],
});

export default compose<IProps, {}>(
  withNavigation,
  withProps(withLegalInfoSectionsProps)
)(LegalInfoSections);
