import I18n from '../../lib/i18n';
import theme from '../../theme';

import { IChoice } from './SelectableList.component';

export const getLanguageItem = (i18nLocale: string): IChoice | null => {
  switch (i18nLocale) {
    case 'en':
      return {
        image: theme.images.greatBritain,
        label: I18n.t('dashboard.profile.languages.en'),
        value: 'en',
      };
    case 'fr':
      return {
        image: theme.images.france,
        label: I18n.t('dashboard.profile.languages.fr'),
        value: 'fr',
      };
    default:
      return null;
  }
};
