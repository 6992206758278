import branch from 'branch-sdk';
import { ConnectedRouter } from 'connected-react-router';
import React, { PureComponent, ReactNode } from 'react';
import ApolloProvider from 'react-apollo/ApolloProvider';
import { CookiesProvider } from 'react-cookie';
import { NavigationParams } from 'react-navigation';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import Client from '../../src/apollo/client';
import I18nProvider from '../../src/components/I18nProvider';
import ResponsiveProvider from '../../src/componentsWeb/ResponsiveProvider';
import { IConfig } from '../../src/environment';
import { initializeEnv } from '../../src/environment/env.web';
import { history } from '../../src/redux/router.web';
import { runSaga } from '../../src/redux/sagas';
import store, { persistor } from '../../src/redux/store.web';
import { Tracker } from '../../src/services/analytics';
import Auth from '../../src/services/authentication/auth.web';
import navigation from '../../src/services/navigation/navigation.web';
import Scenes from './Scenes';

runSaga();

history.listen(
  async (location: NavigationParams): Promise<void> => {
    Tracker.trackNavigation(location);
  }
);

interface IState {
  isLoadingConfiguration: boolean;
}

class App extends PureComponent<{}, IState> {
  public state: IState = {
    isLoadingConfiguration: true,
  };

  public componentDidMount(): void {
    initializeEnv()
      .then((config: IConfig) => {
        Client.initializeApolloClient(config);
        Auth.initializeAuth0Client(config);
        branch.init(
          config.BRANCH_IO.BRANCH_IO_KEY,
          {},
          // tslint:disable-next-line:no-any
          (_: any, data: any): void => {
            if (!!data.data_parsed.routeName) {
              const { routeName, ...otherParams } = data.data_parsed;
              navigation.navigate(data.data_parsed.routeName, otherParams);
            }
          }
        );
        this.setState({ isLoadingConfiguration: false });
      })
      .catch(() => {
        // tslint:disable-next-line:no-console
        console.error('Error while loading conf');
      });
  }

  public render(): ReactNode {
    if (this.state.isLoadingConfiguration) {
      return null;
    }

    return (
      <CookiesProvider>
        <ApolloProvider client={Client.getApolloClient()}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                  <I18nProvider>
                    <ResponsiveProvider>
                      <ToastContainer />
                      <Scenes />
                    </ResponsiveProvider>
                  </I18nProvider>
                </ConnectedRouter>
              </PersistGate>
            </Provider>
        </ApolloProvider>
      </CookiesProvider>
    );
  }
}

export default App;
