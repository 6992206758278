import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../theme';

class BorderedView extends PureComponent<{}> {
  public render(): ReactNode {
    return <View style={styles.container}>{this.props.children}</View>;
  }
}

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    borderColor: theme.colors.blue,
    borderRadius: 5,
    // borderStyle is actually not working on Android
    // https://github.com/facebook/react-native/issues/18285
    borderStyle: 'dashed',
    borderWidth: 1,
    marginBottom: theme.margins.unit,
    padding: theme.margins.unit,
  },
});

export default BorderedView;
