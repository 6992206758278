import moment, { Moment } from 'moment';

import { IPosStatus, POS_STATUS } from '../constants';
import { IPos, IPosSchedule } from '../types/common';

export const formatScheduleHours = (hours: string[]): string[] =>
  hours.map((hour: string) => moment(hour, 'HH:mm:ss').format('H:mm'));

export const getDayOfWeek = (day: string): number => moment(day).isoWeekday();

export const getScheduleHoursOfDay = (
  schedules: IPosSchedule[],
  dayOfWeek: number = moment().isoWeekday()
): string[] => {
  for (const schedule of schedules) {
    if (schedule.days.includes(dayOfWeek)) {
      return schedule.hours;
    }
  }

  return [];
};

export const getPosStatusFromSchedules = (schedules: IPosSchedule[]): IPosStatus => {
  if (schedules.length === 0) {
    return POS_STATUS.UNKNOWN;
  }

  const scheduleHours = getScheduleHoursOfDay(schedules);
  const now: Moment = moment();

  for (let i = 0; i < scheduleHours.length; i = i + 2) {
    const start: Moment = moment(scheduleHours[i], 'HH:mm:ss');
    const end: Moment = moment(scheduleHours[i + 1], 'HH:mm:ss');

    if (now.isBetween(start, end, undefined, '[]')) {
      return POS_STATUS.OPENED;
    }
  }

  return POS_STATUS.CLOSED;
};

export const isClosedForToday = (pointOfSale: Pick<IPos, 'schedules'>): boolean => {
  if (pointOfSale.schedules.length === 0) {
    return false;
  }

  const now: Moment = moment();
  const scheduleHours = getScheduleHoursOfDay(pointOfSale.schedules);

  if (scheduleHours.length === 0) {
    return true;
  }

  return now.isAfter(moment(scheduleHours[scheduleHours.length - 1], 'HH:mm:ss'));
};
