import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import LoadingCard from '../../../../components/LoadingCard';
import OffersList from '../../../../components/OffersList';
import OrdersList from '../../../../components/OrdersList';
import PageTitle from '../../../../components/PageTitle';
import I18n from '../../../../lib/i18n';
import theme from '../../../../theme';
import { IOfferTemplate } from '../../../../types/common';

import { getOrdersFragment_edges_node_Order as IOrder } from '../../../../types/clickandcollect/getOrdersFragment';

export interface IProps {
  isLoading?: boolean;
  offerTemplates: IOfferTemplate[];
  orders: IOrder[];
}
class TakeAwayList extends PureComponent<IProps> {
  public render(): ReactNode {
    const { offerTemplates, orders } = this.props;

    return (
      <View style={styles.list}>
        <LoadingCard isLoading={this.props.isLoading} style={styles.container} big>
          {orders && orders.length > 0 && (
            <View style={styles.ordersListContainer}>
              <PageTitle text={I18n.t('dashboard.eat.pageTitle.orders')} />
              <OrdersList orders={orders} />
            </View>
          )}

          <PageTitle text={I18n.t('dashboard.eat.pageTitle.offers')} />
          {offerTemplates && <OffersList offerTemplates={offerTemplates} isPollable /> }
        </LoadingCard>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  list: ViewStyle;
  ordersListContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    backgroundColor: theme.colors.backgroundGray,
    margin: 0,
    padding: 0,
  },
  list: {
    backgroundColor: theme.colors.backgroundGray,
    height: '100%',
    paddingBottom: theme.config.MOBILE_MENU_HEIGHT,
  },
  ordersListContainer: {
    marginBottom: theme.margins.cardUnit * 4,
  },
});

export default TakeAwayList;
