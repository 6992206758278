import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../../theme';

export interface IProps {
  isActive: boolean;
  name: string;
}

class TabLabel extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isActive, name } = this.props;

    return <Text style={[styles.text, isActive ? styles.textActive : null]}>{name}</Text>;
  }
}

interface IStyle {
  text: TextStyle;
  textActive: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.grayMedium,
  },
  textActive: {
    color: theme.colors.blue,
  },
});

export default TabLabel;
