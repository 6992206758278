import { INutritionCompositionType, IUnit } from '../types/common';

export const NUTRITIONAL_UNITS: { [key in IUnit]: key } = {
  GRAMS: 'GRAMS',
  KILOCALORIES: 'KILOCALORIES',
  MILLIGRAMS: 'MILLIGRAMS',
};

export const NUTRITIONAL_KEYS: { [key: string]: INutritionCompositionType } = {
  calcium: 'CALCIUM',
  carbohydrate: 'CARBOHYDRATE',
  energyValue: 'ENERGY_VALUE',
  includingSaturatedFattyAcids: 'INCLUDING_SATURATED',
  includingSugar: 'INCLUDING_SUGAR',
  lipid: 'LIPID',
  protein: 'PROTEIN',
  salt: 'SALT',
};
