import React, { Component, ReactNode } from 'react';
import { Text, RegisteredStyle, StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import { IPosWithActiveOfferTemplates } from '../../types/getPointsOfSale';

import PosCardBanner from './PosCardBanner';
import PosCardBody from './PosCardBody';
import PosCardFooter from './PosCardFooter';
import PosCardHeader from './PosCardHeader';

export interface IProps {
  containerStyle?: RegisteredStyle<ViewStyle>;
  pointOfSale: IPosWithActiveOfferTemplates;
}

// Should be a component to re-render when we refetch data
class PointOfSaleCard extends Component<IProps> {
  public render(): ReactNode {
    const {
      name,
      type,
      id,
      content,
      activeOfferTemplates,
      bookingOfferTemplate,
    } = this.props.pointOfSale;
    const { containerStyle } = this.props;
    return (
      <View style={[containerStyle, styles.container]}>
        <PosCardHeader posType={type} posId={id} name={name} content={content} />
        {this.renderBody()}
        <PosCardFooter
          posId={id}
          posType={type}
          content={content}
          activeOfferTemplates={activeOfferTemplates}
          bookingOfferTemplate={bookingOfferTemplate}
        />
      </View>
    );
  }

  protected renderBody(): ReactNode {
    const {
      concept,
      mainMenuElements,
      type,
      id,
      schedules,
      status,
      image,
      content,
      typeModel,
    } = this.props.pointOfSale;

    if (mainMenuElements) {
      return (
        <PosCardBody
          noBackground
          posType={type}
          concept={concept}
          posId={id}
          image={image}
          content={content}
          posTypeModel={typeModel}
        >
          <PosCardBanner schedules={schedules} status={status} />
        </PosCardBody>
      );
    }

    return (
      <PosCardBody
        concept={concept}
        posType={type}
        posId={id}
        image={image}
        content={content}
        posTypeModel={typeModel}
      >
        <PosCardBanner borderless schedules={schedules} status={status} />
      </PosCardBody>
    );
  }
}

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    backgroundColor: theme.colors.background,
    flexGrow: 1,
    marginHorizontal: theme.margins.unit,
  },
});

export default PointOfSaleCard;
