import { ApolloQueryResult } from 'apollo-client';
import { Formik, FormikActions } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { Keyboard } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { ActionCreator } from 'redux';

import { OPTINS_CODE } from '../../constants';
import Logger from '../../lib/logger';
import { ISetOptinsAction, ISetUserEmailAction } from '../../redux/signUp/actions';
import Auth from '../../services/authentication';
import { validationEmailFront } from '../../services/form/validationSchema';
import { validateSignUpCheckEmail } from '../../services/form/validator';
import { VALIDATOR_ERROR_NAME } from '../../services/form/validatorError';
import { isSSOAccount } from '../../services/userAccountType';
import { IOptinCode } from '../../types/common';
import { ISignUpCheckEmailResult } from '../../types/signUpCheckEmail';

import SignUpEmailFormikForm, { IValues } from './SignUpEmailFormikForm';

export interface IProps extends NavigationInjectedProps {
  checkEmail: (email: string) => Promise<ApolloQueryResult<ISignUpCheckEmailResult>>;
  setOptins: ActionCreator<ISetOptinsAction>;
  setUserEmail: ActionCreator<ISetUserEmailAction>;
  updateLoadingStatus?: (status: boolean) => void;
  email?: string;
}

class SignUpEmailForm extends PureComponent<IProps> {
  public render(): ReactNode {
    const { email } = this.props;
    return (
      <Formik
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        initialValues={{ email: email || '', checkboxTos: false, checkboxNewsletter: false }}
        component={SignUpEmailFormikForm}
        validationSchema={validationEmailFront}
      />
    );
  }

  private handleSubmit = async (
    values: IValues,
    { setErrors, setSubmitting }: FormikActions<IValues>
  ): Promise<void> => {
    const { checkboxTos, checkboxNewsletter, email } = values;
    const { updateLoadingStatus } = this.props;

    if (!email || !checkboxTos) {
      setSubmitting(false);

      return;
    }

    setErrors({ email: undefined });

    try {
      const { data } = await this.props.checkEmail(email);
      validateSignUpCheckEmail(data);

      const optins: IOptinCode[] = [];
      if (checkboxTos) {
        optins.push(OPTINS_CODE.FOODI_TOS);
      }
      if (checkboxNewsletter) {
        optins.push(OPTINS_CODE.FOODI_MARKETING);
      }

      Keyboard.dismiss();
      this.props.setUserEmail({ email });
      this.props.setOptins({ optins });

      if (isSSOAccount(data.checkEmail)) {
        if (!!updateLoadingStatus) {
          updateLoadingStatus(true);
          const config = {
            callback: (): void => updateLoadingStatus(false),
            email,
            onError: (): void => updateLoadingStatus(false),
          };
          Auth.signUp(config);
        } else {
          const config = { email };
          Auth.signUp(config);
        }
      } else {
        this.props.navigation.navigate('signUpPassword');
      }
    } catch (error) {
      switch (error.name) {
        case VALIDATOR_ERROR_NAME:
          setErrors(error.toFormikError());
          break;
        default:
          Logger.error(error);
      }
    } finally {
      setSubmitting(false);
    }
  };
}

export default SignUpEmailForm;
