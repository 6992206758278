import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import { getGradientColors, IStepStatus } from '../utils';

export interface IProps {
  fromStatus: IStepStatus;
  toStatus: IStepStatus;
}

class StepperConnector extends PureComponent<IProps> {
  public render(): ReactNode {
    const { fromStatus, toStatus } = this.props;
    const [startHex, endHex] = getGradientColors(fromStatus, toStatus);

    if (startHex === endHex) {
      return (
        <View style={styles.container}>
          <View style={[styles.child, { backgroundColor: startHex }]} />
        </View>
      );
    } else {
      const [startRgb, endRgb] = [startHex, endHex].map(this.hexToRgb);

      const numSteps = 10;
      const steps: string[] = Array.from({ length: numSteps }).map((_, index) => {
        const p = index / (numSteps - 1);
        return this.rgbToHex({
          r: Math.round((1 - p) * startRgb.r + p * endRgb.r),
          g: Math.round((1 - p) * startRgb.g + p * endRgb.g),
          b: Math.round((1 - p) * startRgb.b + p * endRgb.b),
        });
      });

      return (
        <View style={styles.container}>
          {steps.map(color => (
            <View key={color} style={[styles.child, { backgroundColor: color }]} />
          ))}
        </View>
      );
    }
  }

  private hexToRgb(hex: string): { r: number; g: number; b: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return {
      r: parseInt(result![1], 16),
      g: parseInt(result![2], 16),
      b: parseInt(result![3], 16),
    };
  }

  private rgbToHex(color: { r: number; g: number; b: number }): string {
    // '1 << 24' and '.slice(1)' are intentional (ensure result will always be 6 hex digits).
    // tslint:disable-next-line:no-bitwise
    return '#' + ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b).toString(16).slice(1);
  }
}

const CONNECTOR_HEIGHT = 3;
const CONNECTOR_MARGIN_HORIZONTAL = 7;
const CONNECTOR_WIDTH = 50;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: CONNECTOR_HEIGHT,
    marginHorizontal: CONNECTOR_MARGIN_HORIZONTAL,
    width: CONNECTOR_WIDTH,
  },
  child: {
    height: '100%',
    flex: 1,
  },
});

export default StepperConnector;
