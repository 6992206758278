import React, { PureComponent, ReactNode } from 'react';
import {
  Image,
  ImageStyle,
  ImageURISource,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import { IElementDishGroup } from '../../../services/menusFormatter';
import theme from '../../../theme';

export interface IProps {
  elementDishGroup: IElementDishGroup;
  icon: ImageURISource;
}

class MenuElementDishGroup extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{this.props.elementDishGroup}</Text>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  image: ImageStyle;
  text: TextStyle;
}

const IMAGE_SIZE = 20;

const styles = StyleSheet.create<IStyle>({
  container: {
    flexDirection: 'row',
    paddingHorizontal: theme.margins.padding,
    backgroundColor: theme.colors.grayLightAlternative,
    height: 50,
    alignItems: 'center',
  },
  image: {
    height: IMAGE_SIZE,
    resizeMode: 'contain',
    width: IMAGE_SIZE,
    marginLeft: theme.margins.cardUnit,
  },
  text: {
    ...theme.fonts.menuSectionTitle,
    color: theme.colors.textBlack,
  },
});

export default MenuElementDishGroup;
