import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import theme from '../../theme';
import { IAmount } from '../../types/common';
import Amount from '../Amount';
import CardTitle from '../CardTitle';
import Separator from '../Separator';

export interface IProps {
  amount?: IAmount;
}

class PricesCardTotal extends PureComponent<IProps> {
  public render(): ReactNode {
    const { amount } = this.props;

    if (!amount) {
      return null;
    }

    return (
      <>
        <View style={styles.separator}>
          <Separator color={theme.colors.textGray} />
        </View>
        <View style={styles.container}>
          <CardTitle text={I18n.t('transactions.subTotal')} />
          <Amount amount={amount} style={styles.amount} />
        </View>
      </>
    );
  }
}

interface IStyle {
  amount: TextStyle;
  container: ViewStyle;
  separator: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    color: theme.colors.textBlack,
    ...theme.fonts.smallAmount,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  separator: {
    marginBottom: theme.margins.cardUnit,
  },
});

export default PricesCardTotal;
