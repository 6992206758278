import React, { PureComponent, ReactNode } from 'react';
import { Collapse } from 'react-collapse';
import { StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../../theme';
import { IFilter } from '../FilterDropdown.component';

import FilterDropdownBodyContent from './FilterDropdownBodyContent.component';

export interface IProps {
  activeFilters: string[];
  collapsed: boolean;
  filters: IFilter[];
  fromTop: number;
  onClearButtonPress: () => void;
  onFilterPress: (id: string) => void;
  onOutsidePress: () => void;
}

export interface IState {
  filterDropdownBodyHeight: number;
  isAnimationEnded: boolean;
}

class FilterDropdownBody extends PureComponent<IProps> {
  public state: IState = {
    filterDropdownBodyHeight: 0,
    isAnimationEnded: false,
  };

  public render(): ReactNode {
    const { collapsed } = this.props;

    return (
      <>
        <View style={styles.container}>
          <Collapse isOpened={!collapsed}>
            <View style={styles.buttonContainer}>
              <FilterDropdownBodyContent {...this.props} />
            </View>
          </Collapse>
        </View>
      </>
    );
  }
}

export interface IStyle {
  buttonContainer: ViewStyle;
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  buttonContainer: {
    flex: 1,
  },
  container: { backgroundColor: theme.colors.white },
});

export default FilterDropdownBody;
