import { loader } from 'graphql.macro';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withHandlers } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { isWeb } from '../../lib/responsive';
import { OrderState, PaymentMethod } from '../../types/clickandcollect/globalTypes';

import CancelOrderModal, { IProps } from './CancelOrderModal.component';
import { fetchOrRenewTokens } from '../../services/edenred';


const cancelOrder = loader('../../queries/clickandcollect/cancelOrder.gql');
const cancelBookingOrder = loader('../../queries/bookingservice/cancelBookingOrder.gql');

type ICancelOrder = (idOrder: string, isBookingOrder: boolean, paymentMethod: PaymentMethod) => Promise<void>;

const onCancelOrderHandler = (
  props: WithApolloClient<{}> & NavigationInjectedProps & IProps
): ICancelOrder => async (idOrder: string, isBookingOrder: boolean, paymentMethod: PaymentMethod): Promise<void> => {
  if (isBookingOrder) {
    await props.client.mutate<{}>({
      mutation: cancelBookingOrder,
      variables: {
        idOrder,
        newState: OrderState.CANCELLED,
      },
    });
    props.navigation.navigate('eat');
  } else {
    let accessToken = null;
    if (paymentMethod === PaymentMethod.EDENRED) {
      const tokens = await fetchOrRenewTokens();
      accessToken = tokens?.accessToken;
    }
    await props.client.mutate<{}>({
      mutation: cancelOrder,
      variables: {
        idOrder,
        newState: OrderState.CANCELLED,
        paymentMethod: paymentMethod,
        accessToken,
      },
    });
    isWeb() ? props.navigation.navigate('eat') : props.navigation.navigate('confirmCancelledOrder');
  }
};

export type IConnectedProps = NavigationInjectedProps & { onCancelOrder: ICancelOrder };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withApollo,
  withHandlers({ onCancelOrder: onCancelOrderHandler })
)(CancelOrderModal);
