import auth0JS, { Auth0DecodedHash, Auth0Error, AuthorizeOptions } from 'auth0-js';
import jwtDecode from 'jwt-decode';

import { getConfig, IConfig } from '../../environment';
import I18n from '../../lib/i18n';
import Logger from '../../lib/logger';
import { resetSignUp } from '../../redux/signUp/actions';
import store from '../../redux/store';
import { signUpWithToken } from '../../services/authentication/signUp';
import navigation from '../../services/navigation';
import Toaster from '../../services/toaster';

import { signIn as signInService } from './signIn';
import { signUp as signUpService } from './signUp';
import { ILoginConfig, ISignupConfig } from './type';
import { generateAuth0State } from './utils';

let auth0: auth0JS.WebAuth;

const initializeAuth0Client = (config: IConfig): void => {
  const redirectUri = `${getConfig().WEB_BASE_URL}/authentication-callback`;

  auth0 = new auth0JS.WebAuth({
    audience: config.AUTH0.WEB.AUDIENCE,
    clientID: config.AUTH0.WEB.CLIENT_ID,
    domain: config.AUTH0.WEB.DOMAIN,
    redirectUri,
    responseType: config.AUTH0.WEB.RESPONSE_TYPE,
  });
};

const errorHandler = (): void => {
  navigation.navigate('landing');
  Toaster.showError(I18n.t('auth0.error'));
};

const hasAuth0Account = (userId?: string): boolean => !!userId;

const handleAuthentication = (signInRedirection?: string): void => {
  const env = getConfig();

  const { USER_ID_KEY } = env.AUTH0.WEB;
  auth0.parseHash(
    async (error: null | Auth0Error, authResult: Auth0DecodedHash | null): Promise<void> => {
      if (error || !authResult || !authResult.accessToken) {
        errorHandler();
      } else {
        const clientInfo = jwtDecode(authResult.accessToken) as Record<string, string>;

        if (!hasAuth0Account(clientInfo[USER_ID_KEY])) {
          await signUpService.handleSignUp(authResult, errorHandler);
        } else {
          signInService.handleSignIn(authResult, signInRedirection);
        }
      }
    }
  );
};

const startAuth0 = (params: AuthorizeOptions, onError?: () => void): void => {
  try {
    auth0.authorize(params);
  } catch (error) {
    if (onError) {
      onError();
    }
    Logger.error(error);
  }
};

const login = (config: ILoginConfig): void => {
  const env = getConfig();
  const redirectUri = `${env.WEB_BASE_URL}/authentication-callback`;
  const { PROMPT } = env.AUTH0.WEB;
  const { email, onError, signInRedirection } = config;

  store.dispatch(resetSignUp());
  startAuth0(
    {
      language: I18n.locale,
      login_hint: email,
      prompt: PROMPT,
      redirectUri: signInRedirection
        ? `${redirectUri}?signInRedirection=${encodeURIComponent(signInRedirection)}`
        : redirectUri,
      state: generateAuth0State(),
    },
    onError
  );
};

const signUp = (config: ISignupConfig): void => {
  const env = getConfig();
  const { PROMPT } = env.AUTH0.WEB;
  const { email, onError } = config;
  startAuth0(
    {
      language: I18n.locale,
      login_hint: email,
      prompt: PROMPT,
      state: generateAuth0State(),
    },
    onError
  );
};

const refreshToken = async (): Promise<Response> => {
  return new Promise<Response>(
    (
      resolve: (value?: Response | PromiseLike<Response>) => void,
      reject: (reason?: null | Auth0Error) => void
    ): void => {
      auth0.checkSession(
        {
          state: generateAuth0State(),
        },
        (err: null | Auth0Error, result: Auth0DecodedHash): void => {
          if (err) {
            reject(err);
          }

          resolve(new Response(JSON.stringify(result)));
        }
      );
    }
  );
};

const signUpWithGuestToken = signUpWithToken;

export default {
  handleAuthentication,
  initializeAuth0Client,
  login,
  refreshToken,
  signUp,
  signUpWithGuestToken,
};
