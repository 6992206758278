import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import FilterItem from '../../FilterItem';
import Touchable from '../../Touchable';
import { IFilter } from '../FilterDropdown.component';

export interface IProps {
  activeFilters: string[];
  collapsed: boolean;
  filters: IFilter[];
  fromTop: number;
  onClearButtonPress: () => void;
  onFilterPress: (id: string) => void;
  onOutsidePress: () => void;
}

export interface IState {
  filterDropdownBodyHeight: number;
  isAnimationEnded: boolean;
}

class FilterDropdownBodyContent extends PureComponent<IProps> {
  public state: IState = {
    filterDropdownBodyHeight: 0,
    isAnimationEnded: false,
  };

  public render(): ReactNode {
    const { activeFilters, onClearButtonPress, onFilterPress, filters } = this.props;

    return (
      <>
        <View style={styles.buttonContainer}>
          <Touchable onPress={onClearButtonPress} style={styles.button}>
            <Text style={styles.text}>{I18n.t('dashboard.eat.filterDropdown.unselect')}</Text>
          </Touchable>
        </View>
        <View style={styles.filtersContent}>
          {filters.map(
            (filter: IFilter): Element => (
              <FilterItem
                key={filter.id}
                label={filter.label}
                isActivated={activeFilters && activeFilters.includes(filter.id)}
                id={filter.id}
                onPress={onFilterPress}
              />
            )
          )}
        </View>
      </>
    );
  }
}

export interface IStyle {
  button: ViewStyle;
  buttonContainer: ViewStyle;
  filtersContent: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  button: {
    paddingHorizontal: 2 * theme.margins.cardUnit,
    paddingVertical: 2 * theme.margins.cardUnit,
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
  filtersContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: theme.margins.cardUnit * 2,
  },
  text: {
    color: theme.colors.active,
    ...theme.fonts.filterCounter,
  },
});

export default FilterDropdownBodyContent;
