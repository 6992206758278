import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, ViewStyle, Text, View } from 'react-native';

import I18n from '../../lib/i18n';
import AppPage from '../AppPage';
import ArticlesList, { DisplayedArticleFamily } from '../ArticlesList';
import {
  TableServiceFooterType,
  ClickAndCollectFooterType,
  BundleQuantityFooter,
  BundleFooterType,
} from '../QuantityFooter';
import theme from '../../theme';
import BakingModal from '../BakingModal';
import {
  getOffer_offer_Offer as IOffer,
  getOffer_offer_Offer_offerTemplate_OfferTemplate_bundle_bundleSteps as IBundleStep,
} from '../../types/clickandcollect/getOffer';
import { NavigationInjectedProps } from 'react-navigation';
import { fontMaker, FONT_FAMILIES, FONT_WEIGHTS } from '../../theme/fonts';
import Button from '../Button';

export interface IWithExtraProps {
  stepIndex: number;
  tableNumber?: string
}

export interface IMapStateToProps {
  minQuantity: number;
  maxQuantity: number;
  remainingQuantity: number;
}

export interface IHandlers {
  onNextStep: () => Promise<void>;
}

export type IConnectedProps = NavigationInjectedProps &
  IMapStateToProps &
  IWithExtraProps &
  IHandlers;

export interface IProps extends IWithExtraProps, IMapStateToProps, IHandlers {
  bundleSteps: IBundleStep[];
  isLoading: boolean;
  offer: IOffer;
  offerId: string;
  articleFamilyKeys: DisplayedArticleFamily[];
  numColumns?: number;
  showQuantityInFooter?: boolean;
  footerType: BundleFooterType | ClickAndCollectFooterType | TableServiceFooterType;
}

export interface IState {
  items: [] | null;
  hasStock: boolean;
}

class BundleArticlesPage extends PureComponent<IProps> {
  public state: IState = {
    items: null,
    hasStock: true,
  };

  public componentDidUpdate(): void {
    if (this.state.items) {
      this.setState({ hasStock: false });
    }
  }

  public render(): ReactNode {
    const {
      stepIndex,
      bundleSteps,
      isLoading,
      offer,
      articleFamilyKeys,
      offerId,
      numColumns,
      showQuantityInFooter,
      minQuantity,
      maxQuantity,
      remainingQuantity,
      tableNumber
    } = this.props;

    const isLastStep = stepIndex === bundleSteps.length - 1;
    const footerType = isLastStep ? BundleFooterType.CART : BundleFooterType.STEP;
    const activeStep = bundleSteps[stepIndex];
    const { articleFamilies, max } = activeStep;
    const stepArticleFamilyKeys = articleFamilies.filter(family =>
      articleFamilyKeys.includes(family)
    );
    const categories = stepArticleFamilyKeys
      .map(family =>
        I18n.t(`dashboard.eat.clickAndCollect.articles.type.${family.toLowerCase()}`, {
          count: max,
        })
      )
      .join(' - ');
    const stepTitle = I18n.t(`dashboard.eat.clickAndCollect.bundle.title`, { count: max });

    return (
      <AppPage backgroundColor={theme.colors.backgroundTabArticles} noPadding style={styles.page}>
        <Text style={styles.title}>
          {stepTitle}&nbsp;
          <Text style={{ color: theme.colors.validate }}>{categories}</Text>
        </Text>
        <ArticlesList
          numColumns={numColumns}
          showQuantityInFooter={showQuantityInFooter}
          articleFamilyKeys={stepArticleFamilyKeys}
          offer={offer}
          isLoading={isLoading}
          remainingQuantity={remainingQuantity}
          hidePrice
          selectionMode={'multiple'}
          articleFamilies={activeStep.articleFamilies}
        />
        <BundleQuantityFooter
          withdrawalType={offer?.offerTemplate?.withdrawalType}
          tableNumber={tableNumber}
          idOffer={offerId}
          footerType={footerType}
          minQuantity={minQuantity}
          maxQuantity={maxQuantity}
          onNextStep={this.props.onNextStep}
          setItems={items => {
            this.setState({ items });
          }}
        />
        <BakingModal offerId={offerId} articleFamilies={activeStep.articleFamilies} />
        {!this.state.hasStock ? (
          <View style={styles.boxOutOfStock}>
            <Text style={styles.boxTitle}>
              {I18n.t('dashboard.eat.clickAndCollect.noStock.title')}
            </Text>
            <Text style={styles.boxSubTitle}>{this.handleMessageOutOfStock(this.state.items)}</Text>
            <Button
              style={styles.buttonStyle}
              textStyle={styles.buttonTextStyle}
              text={I18n.t('dashboard.eat.clickAndCollect.noStock.button')}
              onPress={this.closeView}
            />
          </View>
        ) : null}
      </AppPage>
    );
  }

  private handleMessageOutOfStock = (items: any[] | null): string => {
    const { offer } = this.props;
    let outOfStockProducts: (string | undefined)[] = [];
    if (items) {
      items.forEach(item => {
        const newFilter = offer.offerItems.find(offerItem => offerItem.id === item.idOfferItem);
        outOfStockProducts.push(newFilter?.inheritedLabel);
      });
    }
    return I18n.t('dashboard.eat.clickAndCollect.noStock.subtitle', {
      products: outOfStockProducts.join(', '),
    });
  };

  private closeView = (): void => {
    this.setState({ hasStock: true, items: null });
  };
}

export interface IStyle {
  page: ViewStyle;
  card: ViewStyle;
}

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    margin: theme.margins.unit,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
  },
  cardText: {
    color: theme.colors.grayMedium,
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0,
  },
  title: {
    width: '100%',
    paddingLeft: theme.margins.padding,
    paddingTop: 26,
    paddingBottom: theme.margins.padding,
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.black,
  },
  boxOutOfStock: {
    padding: '4%',
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    bottom: 0,
  },
  boxTitle: {
    marginTop: '3%',
    color: '#383838',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 19,
  },
  boxSubTitle: {
    width: '87%',
    marginTop: '3%',
    color: '#7B7B7B',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: 21,
  },
  buttonStyle: {
    marginTop: '10%',
    width: '99%',
    borderRadius: 4,
    backgroundColor: '#B9B9B9',
    alignSelf: 'center',
  },
  buttonTextStyle: {
    color: theme.colors.textWhite,
    fontFamily: 'Open Sans',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 24,
    textAlign: 'center',
  },
});

export default BundleArticlesPage;
