import React, { PureComponent, ReactNode } from 'react';
import { ScrollView } from 'react-native';
import { NavigationScreenProps } from 'react-navigation';

import AppPage from '../../components/AppPage';
import FlatButton from '../../components/FlatButton';

class Routing extends PureComponent<NavigationScreenProps> {
  public render(): ReactNode {
    return (
      <AppPage hasBlackHeader>
        <ScrollView>
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpGeolocation');
            }}
            text="Step 1 Geolocation"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpNearHoldings');
            }}
            text="Step 1 Near Holdings"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpCloseHolding');
            }}
            text="Step 1 Close Holding"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpIdentity', { holdingId: 'SG9sZGluZzoxNDI=' });
            }}
            text="Step 2 Identity"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpGuestExist', { guestEmail: 'test@test.fr' });
            }}
            text="Step 2 Guest Exist"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpEmail');
            }}
            text="Step 3 Email"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpPassword');
            }}
            text="Step 3 Password"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpConfirmation');
            }}
            text="Step 4 Confirmation"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpEmailNotActivated');
            }}
            text="Step 4 Email Not Validated"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('signUpWelcome');
            }}
            text="Step 4 Welcome"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('topUpSuccessModal');
            }}
            text="Top Up Success Modal (Need offline)"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('modalContest');
            }}
            text="Modal Contest Modal"
          />
          <FlatButton
            onPress={(): void => {
              this.props.navigation.navigate('tableServiceOfferArticles', {
                idOffer: 'Offer:4',
                tableNumber: 1,
              });
            }}
            text="Table Service"
          />
        </ScrollView>
      </AppPage>
    );
  }
}

export default Routing;
