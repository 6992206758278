import React, { PureComponent, ReactNode } from 'react';
import Modal, { Styles as IModalStyles } from 'react-modal';

import theme from '../../theme';

import EmailOrderModalContent, {
  IProps as IEmailOrderModalContentProps,
} from './EmailOrderModalContent';

export interface IProps extends IEmailOrderModalContentProps {
  isVisible: boolean;
}

export interface IState {
  isLoading: boolean;
}

class EmailOrderModal extends PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  public render(): ReactNode {
    const { isVisible, ...others } = this.props;

    return (
      <Modal
        isOpen={isVisible}
        style={styles}
        ariaHideApp={false}
        onRequestClose={this.props.onButtonPress}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <EmailOrderModalContent {...others} />
      </Modal>
    );
  }
}

const BORDER_WIDTH = 0;
const MODAL_MAX_WIDTH = 520;

const styles: IModalStyles = {
  content: {
    borderWidth: BORDER_WIDTH,
    bottom: 0,
    left: 0,
    maxWidth: MODAL_MAX_WIDTH,
    padding: 0,
    position: 'relative',
    right: 0,
    top: 0,
    width: '90vh'
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: theme.colors.overlay,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: theme.margins.cardUnit * 2,
  },
};

export default EmailOrderModal;
