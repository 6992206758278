import theme from '../theme';
import { OrderState } from '../types/clickandcollect/globalTypes';

export interface IOrderStateTools {
  color: string;
  key: string;
  logo: 'OK' | 'KO' | 'onHold';
}

export const ORDER_STATE: { [key in Exclude<OrderState, 'EMPLOYEE_CART'>]: IOrderStateTools } = {
  ABANDONED: {
    color: theme.colors.errorDanger,
    key: 'dashboard.eat.clickAndCollect.orderState.abandoned',
    logo: 'KO',
  },
  ACCEPTED: {
    color: theme.colors.validate,
    key: 'dashboard.eat.clickAndCollect.orderState.accepted',
    logo: 'OK',
  },
  CANCELLED: {
    color: theme.colors.errorDanger,
    key: 'dashboard.eat.clickAndCollect.orderState.cancelled',
    logo: 'KO',
  },
  CART: {
    color: theme.colors.warning,
    key: 'dashboard.eat.clickAndCollect.orderState.cart',
    logo: 'onHold',
  },
  COMPLETED: {
    color: theme.colors.validate,
    key: 'dashboard.eat.clickAndCollect.orderState.completed',
    logo: 'OK',
  },
  ON_HOLD: {
    color: theme.colors.warning,
    key: 'dashboard.eat.clickAndCollect.orderState.onHold',
    logo: 'onHold',
  },
  PREPARING: {
    color: theme.colors.validate,
    key: 'dashboard.eat.clickAndCollect.orderState.preparing',
    logo: 'OK',
  },
  READY: {
    color: theme.colors.validate,
    key: 'dashboard.eat.clickAndCollect.orderState.ready',
    logo: 'OK',
  },
  REFUSED: {
    color: theme.colors.errorDanger,
    key: 'dashboard.eat.clickAndCollect.orderState.refused',
    logo: 'KO',
  },
};
