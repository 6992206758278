import { compose } from 'recompose';

import withOffers from '../../../../hoc/withOffers';
import withOrders from '../../../../hoc/withOrders';
import withNavigation from '../../../../hoc/withNavigation/withNavigation';

import TakeAwayList, { IProps } from './TakeAwayList.component';
import { connect } from 'react-redux';

interface IMapStateToProps {
  withoutTableServiceOrders?: boolean;
  withdrawalType?: string;
}

const mapStateToProps = (): IMapStateToProps => ({
  withoutTableServiceOrders: true,
  withdrawalType: 'POS_TAKE_AWAY,CONNECTED_LOCKERS,CLICK_AND_PICK_UP',
});

export default compose<IProps, {}>(
  connect(mapStateToProps),
  withNavigation,
  withOffers,
  withOrders
)(TakeAwayList);
