import { loader } from 'graphql.macro';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Action, ActionCreator } from 'redux';
import withGuestPaymentProfile from '../../../hoc/withGuestPaymentProfile';
import withNavigation from '../../../hoc/withNavigation';
import { resetCart } from '../../../redux/clickAndCollect/actions';
import { cartSummaryData_order_Order as IOrder } from '../../../types/clickandcollect/cartSummaryData';
import { cartSummaryAdmissionAndPrice_orderAdmissionAndPrice_Order as IOrderAdmissionAndPrice } from '../../../types/clickandcollect/cartSummaryAdmissionAndPrice';
import { OrderState, PaymentMethod } from '../../../types/clickandcollect/globalTypes';
import ConfirmOrderButton, { IComponentProps } from './ConfirmOrderButton.component';
import { selectAuthenticationToken } from '../../../redux/authentication/selectors';
import { IAppState } from '../../../redux/reducer';
import { getConfig } from '../../../environment';
import get from 'lodash/get';
import { submitToUrl } from '../../../services/submitToUrl';
import Logger from '../../../lib/logger';
import { Tracker } from '../../../services/analytics';
import { Event } from '../../../services/analytics/tracker';
import { getPaymentMethodByOfferId } from '../../../redux/clickAndCollect/selectors';
import { fetchOrRenewTokens } from '../../../services/edenred';

const payOrderRequest = loader('../../../queries/tableservice/payOrder.gql');
const getPaymentRequest = loader('../../../queries/getPaymentRequest.gql');

const confirmOrder = loader('./confirmOrder.gql');

export interface IMapStateToProps {
  token?: string;
  paymentMethod: PaymentMethod;
  comment?: string;
}

const mapStateToProps = (state: IAppState, ownProps: IComponentProps): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
  paymentMethod: getPaymentMethodByOfferId(
    state,
    ownProps.order?.offer?.id,
    ownProps.order?.offer?.offerTemplate?.paymentMethod?.paymentTypes,
    ownProps.haveBadge
  ),
  comment: ownProps.comment,
});

// Input props of the container.
interface IContainerProps {
  quotationError: boolean;
  order: IOrder;
  admissionAndPrice: IOrderAdmissionAndPrice;
  refetch: () => void;
  disabled: boolean;
  isCreditCard: boolean;
  haveBadge: boolean | undefined;
  refillAllowedHolding: boolean | null;
}

// Type which will be provided to handlers (after composing w/ apollo and navigation).
type IHandlerProps = IContainerProps &
  IMapStateToProps &
  NavigationInjectedProps &
  WithApolloClient<{}> & { resetCart: ActionCreator<Action> };

export default compose<IComponentProps, IContainerProps>(
  connect(mapStateToProps),
  withApollo,
  withNavigation,
  withGuestPaymentProfile,
  connect(undefined, { resetCart }),

  withHandlers({
    // tslint:disable-next-line:typedef
    onConfirm: (props: IHandlerProps) => async (): Promise<void> => {
      let accessToken = null;
      if (props.paymentMethod === PaymentMethod.EDENRED) {
        const tokens = await fetchOrRenewTokens();
        accessToken = tokens?.accessToken;
      }
      await props.client.mutate<{ confirmOrder: IOrder }>({
        mutation: confirmOrder,
        refetchQueries: ['getOrders'],
        variables: {
          idOrder: props.order.id,
          newState: OrderState.ON_HOLD,
          paymentMethod: props.paymentMethod,
          accessToken,
          comment: props.comment,
        },
      });

      Tracker.track(Event.confirmOrder, {
        paymentMethod: props.paymentMethod,
      });

      props.resetCart();
      props.navigation.navigate('order', {
        orderId: props.order.id,
        paymentMethod: props.paymentMethod,
      });
    },

    // tslint:disable-next-line:typedef
    onUpdatePress: (props: IHandlerProps) => async (): Promise<void> => {
      props.navigation.pop(2);
    },

    // tslint:disable-next-line:typedef
    onTopUpPress: (props: IHandlerProps) => async (): Promise<void> => {
      // OfferId is passed for clickncollect Cart we need it
      const offerId = props.navigation.getParam('offerId');
      props.navigation.navigate('orderRefill', { orderId: props.order.id, offerId });
    },

    onTopUpCreditCard: (props: IHandlerProps) => async (): Promise<void> => {
      const env = getConfig();
      const result = await props.client.query<{}>({
        fetchPolicy: 'network-only',
        query: getPaymentRequest,
        variables: {
          amount: parseFloat(props.admissionAndPrice?.totalPrice?.amount).toFixed(2),
          token: props.token,
          userRedirectUrl: `${env.WEB_BASE_URL}${env.PAYMENT.CLOSE_URL}`,
        },
      });

      const method: string = get(result, 'data.mercanetV2.method');
      const uri: string = get(result, 'data.mercanetV2.uri');
      const body: string = get(result, 'data.mercanetV2.body');
      const headers = get(result, 'data.mercanetV2.headers');

      props.navigation.navigate('topUpPayment', {
        mercanetSource: { method, uri, headers, body },
        isCreditCard: true,
        orderId: props.order.id,
        client: props.client,
      });
    },

    onPay: (props: IHandlerProps) => async (): Promise<void> => {
      const env = getConfig();
      const result = await props.client.query<{}>({
        fetchPolicy: 'network-only',
        query: payOrderRequest,
        variables: {
          userRedirectUrl: `${env.WEB_BASE_URL}/table-service/order/${props.order.id}`,
        },
      });

      const method: string = get(result, 'data.payOrder.method');
      const url: string = get(result, 'data.payOrder.uri');
      const body: string = get(result, 'data.payOrder.body');

      try {
        submitToUrl(url, method, body);
      } catch (error) {
        Logger.error(error);
      } finally {
        // Tracker.trackNavigation({ pathname: 'topUpPayment' });
      }
    },
  })
)(ConfirmOrderButton);
