import React from 'react';
import { Text, View } from 'react-native';
import theme from '../../theme';
import styles from './styles';

type Props = {
  warningText: string;
  iconSize?: number;
  showIcon?: boolean;
  textType?: 'small' | 'normal';
};

const styleByType = {
  small: styles.warningTextSmall,
  normal: styles.warningTextNormal,
};

const WarningMessage = ({
  warningText,
  iconSize = 20,
  showIcon = true,
  textType = 'small',
}: Props) => {
  const IconInformation = theme.images.iconInformationSVG;
  const textStyle = styleByType[textType];

  return (
    <View style={styles.warningWrapper}>
      {showIcon && (
        <View style={[styles.iconWrapper, { height: iconSize }]}>
          <IconInformation width={iconSize} height={iconSize} />
        </View>
      )}
      <Text style={textStyle}>{warningText}</Text>
    </View>
  );
};

export default WarningMessage;
