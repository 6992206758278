import React, { PureComponent, ReactNode } from 'react';
import { ScrollView, StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import LoadingView from '../../../../../components/LoadingView';
import Tag from '../../../../../components/Tag';
import I18n from '../../../../../lib/i18n';
import { allergensMapper } from '../../../../../services/allergensMapper';
import theme from '../../../../../theme';
import { IAllegen, IAllergens } from '../../../../../types/common.d';

export interface IProps {
  allergensList: IAllergens;
  isLoading: boolean;
  style?: StyleProp<ViewStyle>;
}

class AllergensDisclaimerContent extends PureComponent<IProps> {
  public render(): ReactNode {
    const { allergensList } = this.props;

    return (
      <ScrollView style={this.props.style}>
        <Text style={styles.text}>
          {I18n.t('dashboard.menuElementDetails.allergensDisclaimerPage.text')}
        </Text>
        <LoadingView isLoading={this.props.isLoading}>
          <View style={styles.allergensList}>
            {allergensList &&
              allergensList.map((allergenElement: IAllegen) => (
                <Tag
                  key={allergenElement.name}
                  iconName={allergensMapper[allergenElement.name]}
                  label={I18n.t(
                    `dashboard.menuElementDetails.informationSection.allergens.${
                      allergensMapper[allergenElement.name]
                    }`
                  )}
                />
              ))}
          </View>
        </LoadingView>
        <Text style={styles.subtext}>
          {I18n.t('dashboard.menuElementDetails.allergensDisclaimerPage.subtext')}
        </Text>
      </ScrollView>
    );
  }
}

export interface IStyle {
  allergensList: ViewStyle;
  subtext: TextStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  allergensList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 2 * theme.margins.cardUnit,
  },
  subtext: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
  },
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
    marginBottom: 2 * theme.margins.cardUnit,
  },
});

export default AllergensDisclaimerContent;
