import { FormikProps, FormikValues } from 'formik';
import {
  makeReactNativeField,
  makeReactNativeFieldProps,
  setFormikInitialValue,
  setFormikInitialValueProps,
  withError,
  withErrorProps,
  withFormik,
  withInputTypeProps,
  withInputTypePropsProps,
  withNextInputAutoFocusInput,
} from 'react-native-formik';
import { compose, mapProps } from 'recompose';

import TextInputFormik, { IProps } from './TextInputFormik.component';

export const TextInput = withInputTypeProps(TextInputFormik);

interface IFormikContext {
  formik: FormikProps<FormikValues>;
}

const withResetError = compose(
  withFormik,
  mapProps(
    ({ formik: { setErrors }, ...props }: IProps & IFormikContext): IProps => ({
      onChangeText: (): void => setErrors({}),
      ...props,
    })
  )
);

type IContainerProps = IProps &
  makeReactNativeFieldProps &
  setFormikInitialValueProps &
  withInputTypePropsProps &
  withErrorProps;

export default compose<IProps, IContainerProps>(
  withResetError,
  withInputTypeProps,
  setFormikInitialValue,
  withError,
  makeReactNativeField,
  withNextInputAutoFocusInput
)(TextInputFormik);
