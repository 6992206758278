import find from 'lodash/find';
import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import { NUTRITIONAL_KEYS } from '../../../../constants/nutritionalUnits';
import I18n from '../../../../lib/i18n';
import {
  getNutritionalElementKeyWithUnit,
  getNutritionalUnit,
} from '../../../../services/nutritionalMapper';
import { getNutritionalProportion } from '../../../../services/nutritionalMapper';
import theme from '../../../../theme';
import {
  INutritionalComposition,
  INutritionalCompositionElement,
  INutritionCompositionType,
} from '../../../../types/common';
import MenuElementNutritionSection from '../MenuElementNutritionSection';

import platformStyles from './styles';

export interface IProps {
  nutritionalComposition: INutritionalComposition;
}

const NUTRITIONAL_CONFIG: INutritionCompositionType[] = [
  NUTRITIONAL_KEYS.energyValue,
  NUTRITIONAL_KEYS.protein,
  NUTRITIONAL_KEYS.carbohydrate,
  NUTRITIONAL_KEYS.lipid,
  NUTRITIONAL_KEYS.calcium,
  NUTRITIONAL_KEYS.salt,
];

const NUTRITIONAL_CONFIG_LENGTH = NUTRITIONAL_CONFIG.length;

class MenuElementNutritionalContent extends PureComponent<IProps> {
  public render(): ReactNode {
    const { nutritionalComposition } = this.props;

    return (
      <View style={platformStyles.container}>
        <Text style={styles.text}>
          {I18n.t('dashboard.menuElementDetails.nutritionalCompositionSubtitle')}
        </Text>
        {NUTRITIONAL_CONFIG.map(
          (nutritionalElementName: INutritionCompositionType, index: number): Element | null => {
            const title = get(getNutritionalElementKeyWithUnit(nutritionalElementName), 'title');

            const nutritionElement = find(
              nutritionalComposition,
              (nutritionalElement: INutritionalCompositionElement): boolean =>
                nutritionalElement.label === nutritionalElementName
            );

            const proportion = nutritionElement ? getNutritionalProportion(nutritionElement) : 0;

            const unit = nutritionElement && getNutritionalUnit(get(nutritionElement, 'unity'));
            const value = get(nutritionElement, 'value');

            const nutritionSubElement = get(nutritionElement, 'nutritionalElements[0]');
            const nutritionalSubElementName = get(nutritionSubElement, 'label');
            const subtitle =
              nutritionSubElement &&
              get(getNutritionalElementKeyWithUnit(nutritionalSubElementName), 'title');
            const subunit =
              nutritionSubElement && getNutritionalUnit(get(nutritionSubElement, 'unity'));
            const subvalue = nutritionSubElement && get(nutritionSubElement, 'value');

            const lastElementStyle =
              index === NUTRITIONAL_CONFIG_LENGTH - 1
                ? {
                    marginBottom: 0,
                  }
                : {};

            return (
              <MenuElementNutritionSection
                title={title}
                unit={unit}
                value={value}
                proportion={proportion}
                subtitle={subtitle}
                subunit={subunit}
                subvalue={subvalue}
                nutritionalElementName={nutritionalElementName}
                key={nutritionalElementName}
                style={lastElementStyle}
              />
            );
          }
        )}
      </View>
    );
  }
}

interface IStyle {
  text: TextStyle;
}

export interface IPlatformStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
    marginBottom: 2 * theme.margins.cardUnit,
  },
});

export default MenuElementNutritionalContent;
