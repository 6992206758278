import React, { Fragment, PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { IWithGuestPaymentProfile } from '../../hoc/withGuestPaymentProfile';
import I18n from '../../lib/i18n';
import Logger from '../../lib/logger';
import { isWeb } from '../../lib/responsive';
import theme from '../../theme';
import Button from '../Button';
import Card from '../Card';
import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';
import { isAllowedToRefill } from '../../services/guest';
import TopUpAmountButton from './TopUpAmountButton';

export interface IProps extends IWithGuestPaymentProfile {
  topUpButtonConfigs: ITopUpButtonConfig[];
  testID?: string;
}

export interface IState {
  activeIndexAmount: number | null;
  isLoading: boolean;
}

export interface ITopUpButtonConfig {
  label: string;
  onPress: () => void | Promise<void>;
}

class TopUpCard extends PureComponent<IProps, IState> {
  public state: IState = {
    activeIndexAmount: null,
    isLoading: false,
  };

  public render(): ReactNode {
    const {
      topUpButtonConfigs,
      refillAllowedHolding,
      managementMode,
      isLoading,
      testID,
    } = this.props;

    if (isLoading) {
      return <LoadingCard style={styles.card} big isLoading={isLoading} />;
    }
    return isAllowedToRefill(managementMode, refillAllowedHolding) ? (
      <Card style={styles.card} big>
        <View style={styles.titleContainer}>
          <CardTitle text={I18n.t('topUp.title')} />
        </View>
        <View style={styles.amountContainer}>{topUpButtonConfigs.map(this.renderElement)}</View>
        <Button
          testID={`${testID}_confirmButton`}
          text={I18n.t('topUp.validate')}
          disabled={!this.isFormValid()}
          noMargin
          onPress={this.handleSubmit}
          isLoading={this.state.isLoading}
        />
      </Card>
    ) : null;
  }

  private handleSubmit = async (): Promise<void> => {
    const { activeIndexAmount } = this.state;
    const { topUpButtonConfigs } = this.props;

    if (!this.isFormValid()) {
      return;
    }

    const activeAmountConfig: ITopUpButtonConfig = topUpButtonConfigs[activeIndexAmount!];
    if (isWeb()) {
      this.setState({ isLoading: true });
    }

    try {
      await activeAmountConfig.onPress();
    } catch (error) {
      Logger.error(error);
      this.setState({ isLoading: false });
    }
  };

  private isFormValid = (): boolean => this.state.activeIndexAmount !== null;

  private renderElement = (
    amountConfig: ITopUpButtonConfig,
    index: number,
    amounts: ITopUpButtonConfig[]
  ): ReactNode => (
    <Fragment key={amountConfig.label}>
      <TopUpAmountButton
        testID={`${this.props.testID}_topUpAmountButton_${amountConfig.label}`}
        amount={amountConfig.label}
        isActive={this.state.activeIndexAmount === index}
        onPress={(): void => {
          const selectedAmount = this.state.activeIndexAmount === index ? null : index;
          this.setState({ activeIndexAmount: selectedAmount });
        }}
      />
      {index < amounts.length - 1 ? <View style={styles.separator} /> : null}
    </Fragment>
  );
}

interface IStyle {
  amountContainer: ViewStyle;
  card: ViewStyle;
  separator: ViewStyle;
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  amountContainer: {
    flexDirection: 'row',
    marginBottom: theme.margins.cardUnit * 2,
  },
  card: {
    marginBottom: theme.margins.cardUnit * 2,
  },
  separator: {
    width: theme.margins.cardUnit * 2,
  },
  titleContainer: {
    marginBottom: theme.margins.cardUnit * 2,
  },
});

export default TopUpCard;
