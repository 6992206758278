import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import {
  ImageURISource,
  Dimensions,
  PixelRatio,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import LoadingCard from '../../../components/LoadingCard';
import MarkdownDisplay from '../../../components/MarkdownDisplay';
import Page from '../../../components/Page';
import PageTitle from '../../../components/PageTitle';
import ProgressiveImage from '../../../components/ProgressiveImage';
import { getCloudimageUrl } from '../../../services/cloudimage';
import theme from '../../../theme';
import { IMarketingCardElement } from '../../../types/common';
import MarketingCardErrorMessage from './MarketingCardErrorMessage.component';

export interface IProps extends NavigationInjectedProps {
  hasError: boolean;
  isLoading: boolean;
  marketingCard: IMarketingCardElement;
}

const MARKETING_CARD_HEIGHT = 750;
const IMAGE_RATIO = 1.5;
const THUMB_SIZE = 10;

class ModalMarketingCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { hasError, isLoading, marketingCard } = this.props;
    const thumbnailSource: ImageURISource = {};
    const imageSource: ImageURISource = {};
    const width = Dimensions.get('window').width;
    // set height to 750px if width above 750px (square image), otherwise keep ratio as on mobile
    const imageHeight = width < MARKETING_CARD_HEIGHT ? width * IMAGE_RATIO : MARKETING_CARD_HEIGHT;
    if (marketingCard && marketingCard.contentImage) {
      thumbnailSource.uri = getCloudimageUrl(
        marketingCard.contentImage,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(THUMB_SIZE))
      );
      imageSource.uri = getCloudimageUrl(
        marketingCard.contentImage,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(imageHeight))
      );
    }

    return (
      <Page noPadding isModal>
        <PageTitle text={get(marketingCard, 'title', '')} canGoBack />
        <LoadingCard
          isLoading={isLoading}
          hasError={hasError}
          renderError={(): ReactNode => <MarketingCardErrorMessage />}
          style={styles.loadingCard}
        >
          {marketingCard ? (
            <>
              {!!marketingCard.contentSubtitle && (
                <Text style={styles.subtitle}>{marketingCard.contentSubtitle}</Text>
              )}
              {marketingCard.contentImage && (
                <ProgressiveImage
                  style={[{ width: '100%', height: imageHeight }]}
                  source={imageSource}
                  thumbnailSource={thumbnailSource}
                  resizeMode="contain"
                />
              )}
              <View style={styles.contentContainer}>
                <MarkdownDisplay content={marketingCard.content} />
              </View>
            </>
          ) : null}
        </LoadingCard>
      </Page>
    );
  }
}

const BORDER_RADIUS = 4;
interface IStyle {
  contentContainer: ViewStyle;
  loadingCard: ViewStyle;
  subtitle: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  contentContainer: {
    marginVertical: theme.margins.cardUnit * 2,
  },
  loadingCard: {
    ...theme.shadows.card,
    borderRadius: BORDER_RADIUS,
    marginTop: theme.margins.cardUnit * 2,
  },
  subtitle: {
    color: theme.colors.textBlack,
    marginVertical: theme.margins.cardUnit,
    ...theme.fonts.titleText,
  },
});

export default ModalMarketingCard;
