import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withMenuElementDetails from '../../../hoc/withMenuElementDetails';
import {
  IGraphQLProps,
  IWithIdMenuElementProps,
} from '../../../hoc/withMenuElementDetails/withMenuElementDetails';

import withNavigation from '../../../hoc/withNavigation';

import MenuElementDetails, { IProps } from './MenuElementDetails.component';

const WithElementMenuIdProps = (props: NavigationInjectedProps): IWithIdMenuElementProps => ({
  idMenuElement: props.navigation.getParam('elementId'),
});

export type IConnectedProps = NavigationInjectedProps & IWithIdMenuElementProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(WithElementMenuIdProps),
  withMenuElementDetails
)(MenuElementDetails);
