import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import Touchable from '../Touchable';

export interface IProps {
  onPress?: () => void;
  subtitle?: string;
  title: string;
  testID?: string;
}

class LegalInfoSection extends PureComponent<IProps> {
  public render(): ReactNode {
    const VisibilitySVG = theme.images.visibilitySVG;
    return (
      <Touchable onPress={this.props.onPress} style={styles.container} testID={this.props.testID}>
        <View>
          <Text style={styles.title}>{this.props.title}</Text>
          {this.props.subtitle && <Text style={styles.subtitle}>{this.props.subtitle}</Text>}
        </View>
        <View style={styles.iconContainer}>
          <VisibilitySVG/>
        </View>
      </Touchable>
    );
  }
}

const SECTION_HEIGHT = 48;

interface IStyle {
  container: ViewStyle;
  iconContainer: ViewStyle;
  subtitle: TextStyle;
  title: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: SECTION_HEIGHT,
    marginHorizontal: theme.margins.unit,
  },
  iconContainer: {
    marginLeft: 10,
    top: 2,
  },
  subtitle: {
    color: theme.colors.textGray,
    ...theme.fonts.cardText,
  },
  title: {
    color: theme.colors.textBlack,
    ...theme.fonts.profileSection,
  },
});

export default LegalInfoSection;
