import { NavigationScreenProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ActionCreator } from 'redux';

import withUserTracking from '../../hoc/withUserTracking';
import {
  selectAuthenticationToken,
  selectSigninRedirection,
} from '../../redux/authentication/selectors';
import { resetSigninRedirection } from '../../redux/authentication/actions';
import { IAppState } from '../../redux/reducer';

import AuthLoading, { IProps } from './AuthLoading.component';

export interface IMapStateToProps {
  token?: string;
  signInRedirection: string;
}

interface IDispatchProps {
  resetSigninRedirection: ActionCreator<{}>;
}

const mapDispatchToProps: IDispatchProps = {
  resetSigninRedirection,
};

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
  signInRedirection: selectSigninRedirection(state),
});

export type IConnectedProps = NavigationScreenProps & IMapStateToProps & IDispatchProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withUserTracking
)(AuthLoading);
