import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import LoadingView from '../../../components/LoadingView';
import MarkdownDisplay from '../../../components/MarkdownDisplay';
import PosCardBody from '../../../components/PointOfSaleCard/PosCardBody';
// import MenuTabNavigator from '../../../components/MenuTabNavigator';
import PosCardHeader from '../../../components/PointOfSaleCard/PosCardHeader';
import { WebPage } from '../../../componentsWeb';
// import { isMobile } from '../../../lib/responsive';
import theme from '../../../theme';
import { IPos } from '../../../types/common';

export interface IProps {
  hasError?: boolean;
  isLoading?: boolean;
  pointOfSale: Pick<
    IPos,
    'menus' | 'name' | 'schedules' | 'type' | 'content' | 'image' | 'concept' | 'typeModel'
  >;
}

class ContentPage extends PureComponent<IProps> {
  public render(): ReactNode {
    const { hasError, isLoading, pointOfSale } = this.props;

    return (
      <WebPage>
        <LoadingView hasError={hasError} isLoading={isLoading}>
          <View style={styles.container}>{pointOfSale && this.renderDetails()}</View>
        </LoadingView>
      </WebPage>
    );
  }

  private renderDetails = (): ReactNode => {
    return (
      <>
        <PosCardHeader name={this.props.pointOfSale.name} posType={this.props.pointOfSale.type} />
        <PosCardBody
          concept={this.props.pointOfSale.concept}
          posType={this.props.pointOfSale.type}
          image={this.props.pointOfSale.image}
          posTypeModel={this.props.pointOfSale.typeModel}
        />
        {/*<MenuTabNavigator
          numColumns={isMobile() ? 1 : 2}
          menus={this.props.pointOfSale.menus}
          schedules={this.props.pointOfSale.schedules}
        />*/}
        {this.props.pointOfSale.content && (
          <View style={styles.contentContainer}>
            <MarkdownDisplay content={this.props.pointOfSale.content} />
          </View>
        )}
      </>
    );
  };
}

interface IStyle {
  container: ViewStyle;
  contentContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    backgroundColor: theme.colors.background,
  },
  contentContainer: {
    padding: theme.margins.cardUnit * 2,
  },
});

export default ContentPage;
