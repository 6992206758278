import { loader } from 'graphql.macro';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withHandlers } from 'recompose';
import withNavigation from '../../hoc/withNavigation';
import EmailOrderModal, { IProps } from './EmailOrderModal.component';


const EmailOrder = loader('../../queries/tableservice/emailOrderConfirmation.gql');

type IEmailOrder = (idOrder: string, email: string) => Promise<void>;

const onEmailOrderHandler = (
  props: WithApolloClient<{}> & NavigationInjectedProps & IProps
): IEmailOrder => async (idOrder: string, email: string): Promise<any> => {
  await props.client.mutate<{}>({
    mutation: EmailOrder,
    variables: {
      idOrder,
      email,
    },
  });
  // isWeb() ? props.navigation.navigate('eat') : props.navigation.navigate('confirmCancelledOrder');
};

export type IConnectedProps = NavigationInjectedProps & { onEmailOrder: IEmailOrder };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withApollo,
  withHandlers({ onEmailOrder: onEmailOrderHandler })
)(EmailOrderModal);
