import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  ImageURISource,
  PixelRatio,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import AppPage from '../../../../../components/AppPage';
import Button from '../../../../../components/Button';
import Counter from '../../../../../components/Counter';
import ProgressiveImage from '../../../../../components/ProgressiveImage';
import Tag from '../../../../../components/Tag';
import getCloudimageUrl from '../../../../../services/cloudimage';
import theme from '../../../../../theme';
import { fontMaker, FONT_FAMILIES, FONT_WEIGHTS } from '../../../../../theme/fonts';
import {
  getOffer_offer_Offer_offerItems as IOfferItem,
  getOffer_offer_Offer as IOffer,
} from '../../../../../types/clickandcollect/getOffer';
import { ArticleBaking } from '../../../../../types/tableService/globalTypes';
import { getTableServiceOffer_offer_Offer as ITableOffer } from '../../../../../types/tableService/getTableServiceOffer';
import { getOffer_offer_Offer_offerTemplate_OfferTemplate_mealHeartRule as IMealHeartRule } from '../../../../../types/clickandcollect/getOffer';
import TextCollapsible from '../../../../../components/TextCollapsible';
import { IElementQuantity } from '../../../../../redux/clickAndCollect/actions';
import I18n from '../../../../../lib/i18n';
import { formatAmountWithCurrencyInBetween } from '../../../../../services/amountFormatter';
import IconBack from '../../../../../theme/images/back.svg';
import { IIconName } from "../../../../../components/Icon";

const IMAGE_CARD_THUMB_HEIGHT = 15;
const IMAGE_CARD_HEIGHT = 245;
const NO_IMAGE_HEIGHT = theme.margins.padding * 3;
const MIN_VALUE = 1;

interface INavigationParams {
  item: IOfferItem;
  offer: IOffer | ITableOffer;
  mealHeartRule: IMealHeartRule;
  maxSameProduct: number;
  stock: number;
}

export interface IProps extends NavigationInjectedProps<INavigationParams> {
  article: IOfferItem;
  offer: IOffer | ITableOffer;
  onCounterPress: (quantity: number, bakings: string[]) => () => void;
  elementsQuantity: IElementQuantity[];
  badgeNumber: string | null;
}

const OfferArticlesDetailsPage = ({
  article,
  offer,
  onCounterPress,
  elementsQuantity,
  navigation,
  mealHeartRule,
  maxSameProduct,
  stock,
  badgeNumber,
}: IProps) => {
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedBaking, setSelectedBaking] = useState('');
  const [onMount, setOnMount] = useState({
    stock: true,
    reachedQuantitiesForSelectedCategories: false,
    reachedMaxProductsPerOrder: false,
    reachedMaxSameProductPerOrder: false,
    quantityRemainingForMaxProductsPerOrder: 0,
    quantityRemainingForSelectedCategories: 0,
  });
  const thumbnailSource: ImageURISource = {};
  const imageSource: ImageURISource = {};
  const totalQuantity = elementsQuantity
    ? elementsQuantity.reduce((acc, { quantity }) => quantity + acc, 0)
    : 0;
  const prevQuantity = elementsQuantity
    ? elementsQuantity.reduce((quantity, element) => {
      if (element.elementId === article.id) {
        quantity += element.quantity;
      }
      return quantity;
    }, 0)
    : 0;
  if (article.inheritedImage) {
    thumbnailSource.uri = getCloudimageUrl(
      article.inheritedImage,
      'height',
      String(PixelRatio.getPixelSizeForLayoutSize(IMAGE_CARD_THUMB_HEIGHT))
    );
    imageSource.uri = getCloudimageUrl(
      article.inheritedImage,
      'height',
      String(PixelRatio.getPixelSizeForLayoutSize(IMAGE_CARD_HEIGHT))
    );
  }

  useEffect(() => {
    handleOnMount();
  }, []);

  const labels = useMemo(() => {
    if (article.articleCertifications.length === 0) {
      return null;
    }

    return (
      <View style={styles.block}>
        <Text style={styles.subTitle}>
          {I18n.t('dashboard.eat.clickAndCollect.articleDetails.label')}
        </Text>
        <Text>
          {article.articleCertifications.map(ac => (
            <Tag style={styles.tagLabel} label={ac.label} />
          ))}
        </Text>
      </View>
    );
  }, [article.articleCertifications]);

  const tags = useMemo(() => {
    if (article.articleTags.length === 0) {
      return null;
    }

    return (
      <View style={styles.block}>
        <Text style={styles.subTitle}>
          {I18n.t('dashboard.eat.clickAndCollect.articleDetails.tags')}
        </Text>
        <Text>
          {article.articleTags.map(at => (
            <Tag style={styles.tagLabel} label={at.label} />
          ))}
        </Text>
      </View>
    );
  }, [article.articleTags]);

  const reachedQuantitiesForSelectedCategories = (
    selectedQuantity: number,
    returnQuantityLeft: boolean
  ) => {
    const { max: maxProductsPerCategory } = mealHeartRule.quantityRules.find(
      prop => prop.property === 'mealQuantity'
    );
    const quantityOfProductsInRestrictedCategory = elementsQuantity
      ? elementsQuantity
        .filter(item => mealHeartRule.selectedFamilies.includes(item.family))
        .reduce((total, item) => total + item.quantity, 0)
      : 0;
    if (returnQuantityLeft && mealHeartRule.selectedFamilies.includes(article.inheritedFamily)) {
      return maxProductsPerCategory - quantityOfProductsInRestrictedCategory;
    }
    if (
      quantityOfProductsInRestrictedCategory === 0 ||
      !mealHeartRule.selectedFamilies.includes(article.inheritedFamily)
    ) {
      return false;
    }

    return quantityOfProductsInRestrictedCategory + selectedQuantity === maxProductsPerCategory;
  };

  const reachedMaxProductsPerOrder = (selectedQuantity: number, returnQuantityLeft: boolean) => {
    const { max: maxProductsPerOrder } = mealHeartRule.quantityRules.find(
      item => item.property === 'productsPerOrder'
    );
    const numberOfProductsInCart = totalQuantity + selectedQuantity;
    if (returnQuantityLeft) {
      return maxProductsPerOrder - totalQuantity;
    }
    return numberOfProductsInCart === maxProductsPerOrder;
  };

  const handleOnMount = () => {
    if (offer.offerTemplate.salesType === 'FREE_SALE') {
      const { max: maxProductsEqual } = mealHeartRule.quantityRules.find(
        item => item.property === 'sameProductPerOrder'
      );
      setOnMount({
        stock: prevQuantity < stock,
        reachedQuantitiesForSelectedCategories: reachedQuantitiesForSelectedCategories(0),
        reachedMaxProductsPerOrder: reachedMaxProductsPerOrder(0),
        reachedMaxSameProductPerOrder: maxProductsEqual === prevQuantity,
        quantityRemainingForMaxProductsPerOrder: reachedMaxProductsPerOrder(selectedQuantity, true),
        quantityRemainingForSelectedCategories: reachedQuantitiesForSelectedCategories(
          selectedQuantity,
          true
        ),
      });
    } else {
      setOnMount({
        ...onMount,
        stock: prevQuantity < stock,
      });
    }
  };

  const quantityRender = (quantity: number) => (
    <View style={styles.quantity}>
      <Text style={styles.quantityText}>{quantity}</Text>
    </View>
  );

  const checkAllergen = (allergen: string) => article.articleCertifications.findIndex(ac => ac.label === allergen) > -1;

  const allergens: Array<{ label: string, icon: IIconName, active: boolean }> = [
    { label: 'Gluten', icon: 'gluten', active: checkAllergen('gluten') },
    { label: 'Crustacé', icon: 'crustacean', active: checkAllergen('crustacean') },
    { label: 'Oeufs', icon: 'egg', active: checkAllergen('egg') },
  ];

  const allergensRender = () => allergens.map(allergen => {
    const tagStyle: ViewStyle = {
      borderColor: allergen.active ? theme.colors.greenLighter : theme.colors.black,
      opacity: allergen.active ? 1 : 0.4,
    };

    return (
      <Tag
        style={[styles.tagAllergen, tagStyle]}
        tagStyle={styles.tagAllergenText}
        label={allergen.label}
        iconName={allergen.icon}
        tintColor={allergen.active ? theme.colors.white : theme.colors.black}
        active={allergen.active}
      />
    )
  });

  const expandedDetails = useMemo(() => (
    <View>
      <Text style={[styles.text, styles.paragraph]}>Nos informations sur les allergènes concernent exclusivement les 14 allergènes majeurs que nous avons introduits volontairement dans nos préparations.</Text>
      <Text style={[styles.text, styles.paragraph]}>Dans notre cuisine, toutes ou partie de ces substances allergènes sont présentes. Aussi, malgré toutes nos précautions, nous ne pouvons pas exclure la contamination croisée accidentelle entre préparations.</Text>
      <Text style={[styles.text, styles.paragraph]}>L’affichage des substances allergènes est effectuée, à date, sur la base des informations communiquées par nos fournisseurs sur leurs propres produits.</Text>
    </View>
  ), []);

  const handleBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleSubmitButtonText = () => {
    const staticText = I18n.t('dashboard.eat.clickAndCollect.articleDetails.addToCart');
    const amount = `${Number(article.inheritedPrice.amount) * selectedQuantity}`;
    const formatedAmount = formatAmountWithCurrencyInBetween({ ...article.inheritedPrice, amount });
    return `${staticText} ${formatedAmount}`;
  };

  const handleSubmitButton = () => {
    const prevBaking = elementsQuantity
      ? elementsQuantity
        .filter(({ elementId }) => elementId === article.id)
        .map(({ chosenBaking }) => chosenBaking)
      : [];
    const bakings = Array.from(Array(selectedQuantity).fill(selectedBaking));
    const value = selectedQuantity + prevQuantity;
    onCounterPress(value, [...prevBaking, ...bakings]);
    navigation.goBack();
  };

  const handleCounterDisable = () => {
    const totalQuantity = selectedQuantity + prevQuantity;
    if (offer.offerTemplate.salesType === 'FREE_SALE') {
      return (
        reachedQuantitiesForSelectedCategories(selectedQuantity) ||
        reachedMaxProductsPerOrder(selectedQuantity) ||
        totalQuantity >= stock
      );
    }
    return totalQuantity >= stock;
  };

  const handleCounterMax = () => {
    if (offer.offerTemplate.salesType === 'FREE_SALE') {
      const { max: maxProductsEqual } = mealHeartRule.quantityRules.find(
        item => item.property === 'sameProductPerOrder'
      );
      const quantityRemainingForMaxProductsEqual = maxProductsEqual - prevQuantity;
      if (mealHeartRule.selectedFamilies.includes(article.inheritedFamily)) {
        return Math.min(
          stock,
          onMount.quantityRemainingForMaxProductsPerOrder,
          onMount.quantityRemainingForSelectedCategories,
          quantityRemainingForMaxProductsEqual
        );
      }
      return Math.min(
        stock,
        onMount.quantityRemainingForMaxProductsPerOrder,
        quantityRemainingForMaxProductsEqual
      );
    }
    return stock;
  };

  return (
    <AppPage backgroundColor={theme.colors.backgroundTabArticles} noPadding>
      <TouchableOpacity onPress={handleBack} style={styles.backContainer}>
        <IconBack />
      </TouchableOpacity>
      <ScrollView>
        <View
          style={[
            styles.imageContainer,
            { height: article.inheritedImage ? IMAGE_CARD_HEIGHT : NO_IMAGE_HEIGHT },
          ]}
        >
          <ProgressiveImage
            source={imageSource}
            thumbnailSource={thumbnailSource}
            style={styles.image}
          />
        </View>
        <View
          style={[
            styles.container,
            article.inheritedImage ? {} : { marginTop: theme.margins.padding * 2 },
          ]}
        >
          <Text style={styles.title}>{article.inheritedLabel}</Text>
          <Text style={styles.text}>{article.inheritedDescription}</Text>
          {labels}
          {tags}
          {/* <View style={styles.block}>
            <Text style={styles.subTitle}>Ingrédients</Text>
            <Text style={styles.text}>Poisson, riz complet, quinoa, soja, crème de patates douces, lait de coco, coriandre, ail, persil, oignon, gingembre, citronnelle et citron vert.</Text>
          </View>
          <View style={styles.block}>
            <Text style={styles.subTitle}>Allergènes</Text>
            <Text style={styles.text}>Les allergènes contenus* dans ce plat sont indiqués en vert.</Text>
            <View style={styles.tagContainer}>
              {allergensRender()}
            </View>
            <TextCollapsible
              text={'*Les informations sur les substances allergènes sont données à titre uniquement indicatif.'}
              textStyle={styles.text}
              expandedComponent={expandedDetails}
            />
          </View> */}

        </View>
      </ScrollView>
      {onMount.stock &&
        !onMount.reachedQuantitiesForSelectedCategories &&
        !onMount.reachedMaxProductsPerOrder &&
        !onMount.reachedMaxSameProductPerOrder &&
        !!badgeNumber && (
          <View style={styles.bottomContainer}>
            {article.baking && (
              <>
                <Text style={styles.bakingTypeTitle}>
                  {I18n.t('dashboard.eat.tableService.bakingModal.title')}
                </Text>
                <View style={styles.bakingTypeContainer}>
                  {(article.baking || []).map((baking: ArticleBaking) => {
                    const tintColor =
                      selectedBaking === baking ? theme.colors.active : theme.colors.gray;

                    return (
                      <Tag
                        testID={`offerArticlesDetailsPage_bakingTag_${baking}`}
                        key={baking}
                        onClick={(): void => setSelectedBaking(baking)}
                        tintColor={tintColor}
                        style={[
                          styles.bakingTypeTag,
                          {
                            backgroundColor:
                              selectedBaking === baking ? theme.colors.blueLight : 'transparent',
                          },
                        ]}
                        tagStyle={[styles.bakingTypeTagText, { color: tintColor }]}
                        iconSize={10}
                        iconName="plus"
                        label={I18n.t(`dashboard.eat.tableService.ArticleBaking.${baking}`)}
                      />
                    );
                  })}
                </View>
              </>
            )}
            <Counter
              testID="offerArticlesDetailsPage_counter"
              min={MIN_VALUE}
              max={handleCounterMax()}
              onCounterPress={setSelectedQuantity}
              quantityRenderer={quantityRender}
              value={selectedQuantity}
              size="big"
              variant="GREEN"
              disableCounter={handleCounterDisable()}
              alwaysShowDecrement
            />
            <Button
              testID="offerArticlesDetailsPage_addToCartButton"
              text={handleSubmitButtonText()}
              style={
                article.baking && selectedBaking === ''
                  ? [styles.button, { backgroundColor: theme.colors.inactive }]
                  : styles.button
              }
              textStyle={styles.buttonText}
              onPress={handleSubmitButton}
              disabled={article.baking && selectedBaking === '' ? true : false}
            />
          </View>
        )}
    </AppPage>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
  image: {
    alignSelf: 'center',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  title: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 24,
    lineHeight: 24,
    color: theme.colors.black,
  },
  subTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 18,
    lineHeight: 24,
    color: theme.colors.black,
  },
  container: {
    margin: 18,
  },
  text: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 16,
    lineHeight: 24,
    color: theme.colors.black,
  },
  paragraph: {
    marginVertical: 8,
  },
  block: {
    marginTop: 30,
  },
  tagContainer: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
  },
  tagLabel: {
    marginTop: 8,
    backgroundColor: theme.colors.grayExtraLight,
  },
  tagAllergen: {
    marginTop: 8,
  },
  tagAllergenText: {
    marginLeft: 4,
  },
  circlesRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 11,
  },
  circlesSeparator: {
    marginTop: 23,
  },
  circlesContainer: {
    marginTop: 14,
    marginBottom: 17,
  },
  bottomContainer: {
    backgroundColor: theme.colors.white,
    alignItems: 'center',
    paddingTop: 19,
  },
  button: {
    marginHorizontal: 27,
    marginTop: 17,
    backgroundColor: theme.colors.validate,
  },
  buttonText: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 16,
    lineHeight: 22,
  },
  quantity: {
    marginHorizontal: 24,
    width: 30,
    alignItems: 'center',
  },
  quantityText: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 18,
    lineHeight: 24,
    color: theme.colors.black,
  },
  backContainer: {
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 1,
  },
  bakingTypeContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: theme.margins.unit,
    marginHorizontal: theme.margins.unit,
    justifyContent: 'center',
  },
  bakingTypeTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    color: theme.colors.textBlack,
    paddingBottom: 2 * theme.margins.cardUnit,
    textAlign: 'center',
  },
  bakingTypeTag: {
    borderRadius: 4,
    flexDirection: 'row',
    marginLeft: theme.margins.cardUnit,
    marginRight: theme.margins.cardUnit,
  },
  bakingTypeTagText: {
    ...theme.fonts.cardText,
    fontWeight: '300',
    height: 17,
  },
});

export default OfferArticlesDetailsPage;
