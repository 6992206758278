import React, { useCallback } from 'react';
import { Linking, StyleSheet, ViewStyle } from 'react-native';
import { WebView } from 'react-native-webview';
import { ShouldStartLoadRequest } from 'react-native-webview/lib/WebViewTypes';
import { NavigationInjectedProps } from 'react-navigation';
import Page from '../../../components/Page';
import { createHtmlForWebview } from '../../../services/webviewServices';

export interface IProps extends NavigationInjectedProps {}

const HelpOnline = ({ navigation }: IProps) => {
  const handleShouldStartLoadWithRequest = useCallback((event: ShouldStartLoadRequest) => {
    if (event.navigationType === 'click' && Linking.canOpenURL(event.url)) {
      Linking.openURL(event.url);
      return false;
    }
    return true;
  }, []);

  return (
    <Page noPadding>
      <WebView
        source={{ html: createHtmlForWebview(navigation.getParam('html', '')) }}
        style={styles.webView}
        onShouldStartLoadWithRequest={handleShouldStartLoadWithRequest}
      />
    </Page>
  )
};

export interface IStyle {
  webView: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  webView: {
    flex: 1,
  }
});

export default HelpOnline;
