import throttle from 'lodash/throttle';
import { toast, ToastPosition } from 'react-toastify';

let toastId: number = 0;

const showError = (message: string): void => {
  show(message, true);
};

const showSuccess = (message: string): void => {
  show(message);
};

const show: (message: string, isError?: boolean) => void = throttle(
  (message: string, isError?: boolean): void => {
    if (!toast.isActive(toastId)) {
      if (isError) {
        toastId = toast.error(message, { position: ToastPosition.TOP_RIGHT });
      } else {
        toastId = toast.success(message, { position: ToastPosition.TOP_RIGHT });
      }
    }
  },
  1000
);

export default { showError, showSuccess };
