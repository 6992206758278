import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../theme';

import FilterDropdownContent from './FilterDropdownContent.container';

export interface IFilter {
  id: string;
  label: string;
}
export interface IProps {
  filters: IFilter[];
}

class FilterDropdown extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.container}>
        <FilterDropdownContent filters={this.props.filters} />
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    borderRadius: 4,
    overflow: 'hidden',
    ...theme.shadows.card,
  },
});

export default FilterDropdown;
