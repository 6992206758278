import { NavigationScreenProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../../hoc/withNavigation';

import ConfirmEmailLead from './ConfirmEmailLead.component';

export type IConnectedProps = NavigationScreenProps;
export type IContainerProps = Omit<NavigationScreenProps, keyof IConnectedProps>;

export default compose<NavigationScreenProps, IContainerProps>(withNavigation)(ConfirmEmailLead);
