import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import { IAmount } from '../../../types/common';
import Amount from '../../Amount';

export interface IProps {
  balance?: IAmount;
}

class Balance extends PureComponent<IProps> {
  public render(): ReactNode {
    const { balance } = this.props;

    if (!balance || !balance.amount) {
      return <Text style={styles.emptyMessage}>{I18n.t('balance.emptyMessage')}</Text>;
    }

    return <Amount style={styles.amount} amount={balance} />;
  }
}

export interface IStyle {
  amount: TextStyle;
  emptyMessage: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    ...theme.fonts.bigAmount,
  },
  emptyMessage: {
    color: theme.colors.textGray,
    ...theme.fonts.cardText,
  },
});

export default Balance;
