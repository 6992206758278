import { Action } from 'redux';
import { CookieCategory, CookieConsent } from '../../services/oneTrust';

export type IActionTypes = 'SET_CATEGORY_CONSENT';

export const actionTypes: { [key in IActionTypes]: key } = {
  SET_CATEGORY_CONSENT: 'SET_CATEGORY_CONSENT'
};

export interface IChangeCategoryConsentPayload {
  category: CookieCategory;
  consent: CookieConsent;
}

export interface IChangeCategoryConsentAction extends Action {
  payload: IChangeCategoryConsentPayload;
  type: IActionTypes;
}

export const changeCategoryConsent = (
  payload: IChangeCategoryConsentPayload
): IChangeCategoryConsentAction => ({
  payload,
  type: actionTypes.SET_CATEGORY_CONSENT
});
