export function createHtmlForWebview(content: string) {
  const style = `
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

    * {
      color: #232323;
      font-family: 'Open Sans';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    p.title {
      color: #232323;
      font-family: 'Open Sans';
      font-size: 30px;
      letter-spacing: 0;
      line-height: 26px
    }
    
    p.subtitle {
      color: #383838;
      font-family: 'Open Sans';
      font-size: 20px;
      letter-spacing: 0;
      line-height: 24px
    }
    p.content {
      color: #7a7a7a;
      font-family: 'Open Sans';
      font-size: 15px;
      letter-spacing: 0;
      line-height: 23px
    }`;

  return `
    <html>
      <head>
        <meta name="viewport" content="width=device-width">
      </head>
      <body>
        ${content}
      </body>
      <style>
        ${style}
      </style>
    </html>`;
}
