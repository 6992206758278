export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Entity',
        possibleTypes: [
          {
            name: 'Holding',
          },
          {
            name: 'HoldingService',
          },
          {
            name: 'MarketingCard',
          },
          {
            name: 'Zone',
          },
          {
            name: 'Pos',
          },
          {
            name: 'Concept',
          },
          {
            name: 'PosTypeModel',
          },
          {
            name: 'MenuElement',
          },
          {
            name: 'MenuCategory',
          },
          {
            name: 'MenuElementProduct',
          },
          {
            name: 'MenuElementFamily',
          },
          {
            name: 'MenuElementFamilyParent',
          },
          {
            name: 'MenuElementDish',
          },
          {
            name: 'MenuElementDishGroup',
          },
          {
            name: 'LocalArticle',
          },
          {
            name: 'Article',
          },
          {
            name: 'ArticleConcept',
          },
          {
            name: 'OfferTemplate',
          },
          {
            name: 'Offer',
          },
          {
            name: 'Guest',
          },
          {
            name: 'User',
          },
          {
            name: 'Transaction',
          },
          {
            name: 'TicketDetail',
          },
          {
            name: 'Ticket',
          },
          {
            name: 'Order',
          },
          {
            name: 'Refill',
          },
          {
            name: 'Agreement',
          },
          {
            name: 'PermanentOfferItem',
          },
          {
            name: 'TemporaryOfferItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'HoldingCashSystemConfig',
        possibleTypes: [
          {
            name: 'HoldingCsiConfig',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PosCashSystemConfig',
        possibleTypes: [
          {
            name: 'PosCsiConfig',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PosMenuSystemConfig',
        possibleTypes: [
          {
            name: 'PosOscarConfig',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'OfferItem',
        possibleTypes: [
          {
            name: 'PermanentOfferItem',
          },
          {
            name: 'TemporaryOfferItem',
          },
        ],
      },
    ],
  },
};

export default result;
