import { AnyAction, Reducer } from 'redux';
import { actionTypes as authenticationActionTypes } from '../authentication/actions';
import { actionTypes, IElementQuantity } from './actions';
import { getOffer_offer_Offer_offerItems as IOfferItem } from '../../types/clickandcollect/getOffer';
import { PaymentMethod } from '../../types/clickandcollect/globalTypes';

export interface IClickAndCollectElements {
  [id: string]: IElementQuantity[];
}
export interface IClickAndCollectSlots {
  [id: string]: string;
}
export interface IClickAndCollectPaymentMethod {
  [id: string]: PaymentMethod;
}
export interface IClickAndCollectPickupPoint {
  [id: string]: string;
}
export interface IClickAndCollectState {
  elements: IClickAndCollectElements;
  slots: IClickAndCollectSlots;
  selectedBakingItem: IOfferItem | null;
  paymentMethods: IClickAndCollectPaymentMethod;
  pickupPoints: IClickAndCollectPickupPoint;
}
export const initialState: IClickAndCollectState = {
  elements: {},
  slots: {},
  selectedBakingItem: null,
  paymentMethods: {},
  pickupPoints: {},
};

const clickAndCollectReducer: Reducer<IClickAndCollectState> = (
  state: IClickAndCollectState = initialState,
  action: AnyAction
): IClickAndCollectState => {
  switch (action.type) {
    case actionTypes.SET_ELEMENTS_QUANTITY_BY_OFFER_ID:
      const newElements = { ...state.elements };
      newElements[action.payload.offerId] = action.payload.elementsQuantity;
      return {
        ...state,
        elements: newElements,
      };
    case actionTypes.SET_SLOT_BY_OFFER_ID:
      const newSlots = { ...state.slots };
      newSlots[action.payload.offerId] = action.payload.idSlot;

      return {
        ...state,
        slots: newSlots,
      };

    case actionTypes.RESET_CART:
      return {
        ...initialState,
      };

    case actionTypes.RESET_SLOT_FOR_OFFER:
      const newSlotsReset = { ...state.slots };

      delete newSlotsReset[action.payload.offerId];

      return {
        // @ts-ignore
        ...state,
        slots: newSlotsReset,
      };

    case actionTypes.RESET_QUANTITY_FOR_OFFER:
      const newElementsReset = { ...state.elements };
      delete newElementsReset[action.payload.offerId];

      return {
        // @ts-ignore
        ...state,
        elements: newElementsReset,
      };

    case actionTypes.REMOVE_ITEMS_OUT_OF_STOCK_FROM_CART:
      const newElementsInStock = { ...state.elements };
      const { offerId, items } = action.payload;

      const idOfferItemsOutOfStock = items.map((item) => item.idOfferItem);

      // filter will return only the elements in stock
      newElementsInStock[offerId] = newElementsInStock[offerId].filter((element) => {

        // check is out of stock
        return !idOfferItemsOutOfStock.includes(element.elementId);
      });

      return {
        ...state,
        elements: newElementsInStock,
      };

    case actionTypes.SET_SELECTTEDITEM_BAKING_MODAL: {
      return {
        ...state,
        selectedBakingItem: action.payload,
      };
    }

    case actionTypes.SET_PAYMENT_METHOD: {
      const newPaymentMethods = { ...state.paymentMethods };
      const { offerId, paymentMethod } = action.payload;

      newPaymentMethods[offerId] = paymentMethod;

      return {
        ...state,
        paymentMethods: newPaymentMethods
      };
    }

    case authenticationActionTypes.AUTHENTICATION_LOG_OUT:
      return {
        ...initialState,
      };

    case actionTypes.SET_PICKUP_POINT: {
      const pickupPoints = { ...state.pickupPoints };
      const { offerId, pickupPoint } = action.payload;

      pickupPoints[offerId] = pickupPoint;

      return {
        ...state,
        pickupPoints,
      };
    }

    default:
      return state;
  }
};

export default clickAndCollectReducer;
