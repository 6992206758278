import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import LoadingCard from '../../../components/LoadingCard';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import {
  getOrder2_order_Order as IOrder,
  getOrder2_order_Order_orderItems as IOrderItem,
} from '../../../types/clickandcollect/getOrder2';
import { IAmount } from '../../../types/common';
import { Currency, PaymentMethod } from '../../../types/clickandcollect/globalTypes';
import Separator from '../../Separator';
import OrderSummaryItem from '../OrderSummaryItem';
import OrderSummarySection from '../OrderSummarySection';
import WarningMessage from '../../WarningMessage';
import { articleFamilyKeys, DisplayedArticleFamily } from '../../../components/ArticlesList';
import WarningInfo from '../../../components/WarningInfo';
import WarningPayOnSite from '../../../components/WarningPayOnSite';
import OrderSummaryQuotation from './OrderSummaryQuotation';

export interface IProps {
  isLoading: boolean;
  order: IOrder;
  isCreditCard: boolean;
  isSchool: boolean;
  showQuotationInfo: () => void;
}

class OrderSummaryCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, order, isCreditCard, isSchool, showQuotationInfo } = this.props;

    const totalAmount = {
      __typename: 'Amount',
      amount: order?.subsidies?.amount
        ? Number(order?.totalPrice?.amount) - Number(order?.subsidies?.amount)
        : order?.totalPrice?.amount,
      currency: Currency.EUR,
    };

    let isOrderInAdvance = false;

    if (!isLoading && order.withdrawRange && order?.paymentMethod === PaymentMethod.BADGE) {
      const startDate = order!.withdrawRange.split('/')[0];

      const withdrawalStartDate = new Date(startDate);
      const todayDate = new Date();

      // is in advance when pick up day is higher than today's date
      isOrderInAdvance =
        withdrawalStartDate.toLocaleDateString() !== todayDate.toLocaleDateString() &&
        withdrawalStartDate.getTime() > todayDate.getTime();
    }

    if (!order.totalPrice) {
      order.totalPrice = {
        __typename: 'Amount',
        amount: order.orderItems.reduce((price: number, item: IOrderItem) => {
          price += parseFloat(item.priceWhenAdded.amount);
          return price;
        }, 0),
        currency: Currency.EUR,
      };
    }

    const paymentMethod = get(order, 'paymentMethod') as PaymentMethod;

    const totalPrice = isCreditCard ? totalAmount : (get(order, 'totalPrice') as IAmount);
    const handleShowQuotationInfo = () => {
      showQuotationInfo();
    };

    const payOnSiteMsg = isSchool
      ? I18n.t('dashboard.eat.clickAndCollect.cartSummary.payOnSiteInfoIsSchool')
      : I18n.t('dashboard.eat.clickAndCollect.cartSummary.payOnSiteInfo');

    const warningInfoMsg = {
      [PaymentMethod.ON_SITE]: payOnSiteMsg,
      [PaymentMethod.EDENRED]: I18n.t('dashboard.eat.clickAndCollect.cartSummary.edenredInfo'),
    };
    return (
      <LoadingCard isLoading={isLoading} style={styles.card} big>
        <OrderSummarySection title={I18n.t('dashboard.eat.clickAndCollect.orderDetails.articles')}>
          {/* Will display items, ordered by articleFaimly  */}
          {order && articleFamilyKeys.map(key => this.renderArticlesByType(key, order))}
        </OrderSummarySection>
        <View style={styles.separator}>
          <Separator />
        </View>

        <OrderSummaryQuotation
          order={order}
          isCreditCard={isCreditCard}
          isSchool={isSchool}
          showQuotationInfo={handleShowQuotationInfo}
        />

        <OrderSummaryItem
          label={
            paymentMethod && paymentMethod === PaymentMethod.ON_SITE
              ? I18n.t('dashboard.eat.clickAndCollect.orderDetails.totalPricePayOnSite')
              : I18n.t('dashboard.eat.clickAndCollect.orderDetails.totalPrice')
          }
          amount={totalPrice}
        />
        {isOrderInAdvance && (
          <WarningMessage
            warningText={I18n.t('dashboard.eat.clickAndCollect.orderDetails.warningAdvanceOrder')}
            iconSize={22}
          />
        )}
        <WarningInfo message={warningInfoMsg[paymentMethod]} />
      </LoadingCard>
    );
  }

  // Method that will render all items, that belong to articleFamily, sorting them alphabetically
  private renderArticlesByType = (
    articleFamily: DisplayedArticleFamily,
    order: IOrder
  ): ReactNode | undefined => {
    const filteredOrderItems = order.orderItems
      .filter(orderItem => orderItem.offerItem?.inheritedFamily === articleFamily)
      .sort((a, b) => (a.labelWhenAdded > b.labelWhenAdded ? 1 : -1));

    const articleFamilyTitle = I18n.t(
      `dashboard.eat.clickAndCollect.articles.type.${articleFamily.toLowerCase()}`
    );

  if (filteredOrderItems.length !== 0) {
      return (
        <View key={articleFamily}>
          <Text style={styles.titleType}>{articleFamilyTitle}</Text>
          {filteredOrderItems.map(orderItem => (
            <OrderSummaryItem
              key={get(orderItem, 'id')}
              label={`${get(orderItem, 'quantity')}x ${get(orderItem, 'labelWhenAdded')}`}
              amount={{
                amount: (
                  parseFloat(get(orderItem, 'priceWhenAdded.amount') || '0') *
                  get(orderItem, 'quantity')
                ).toString(),
                currency: get(orderItem, 'priceWhenAdded.currency'),
              }}
              containerLabel={
                orderItem.containerLabelWhenAdded ? get(orderItem, 'containerLabelWhenAdded') : null
              }
              containerAmount={{
                amount: (
                  parseFloat(get(orderItem, 'containerPriceWhenAdded.amount') || '0') *
                  get(orderItem, 'quantity')
                ).toString(),
                currency: get(orderItem, 'containerPriceWhenAdded.currency'),
              }}
              baking={orderItem.chosenBaking}
            />
          ))}
        </View>
      );
    } else {
      return undefined;
    }
  };
}

export interface IStyle {
  card: ViewStyle;
  separator: ViewStyle;
  titleType: ViewStyle;
}

const BORDER_RADIUS = 8;

const styles = StyleSheet.create<IStyle>({
  card: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    marginBottom: theme.margins.cardUnit * 2,
    overflow: 'hidden',
  },
  separator: {
    marginVertical: theme.margins.cardUnit * 2,
  },
  titleType: {
    width: '100%',
    marginTop: 20,
    marginBottom: 0,
  },
});

export default OrderSummaryCard;
