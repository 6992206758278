import React, { PureComponent, ReactNode } from 'react';
import { View, ViewStyle } from 'react-native';

import HoldingLogo from '../../components/HoldingLogo';
import { HOLDING_SERVICE_TYPES } from '../../constants/HoldingServices';
import I18n from '../../lib/i18n';
import { IMedia, isDesktop, isMobile, media } from '../../lib/responsive';
import { getServiceByType } from '../../services/holding';
import { getWebRouteName } from '../../services/navigation/navigation.web';
import theme from '../../theme';
import { IService } from '../../types/common';
import FoodiLogo from '../FoodiLogo';
import { HORIZONTAL_FOODI_LOGO_HEIGHT } from '../FoodiLogo/FoodiLogo.component';
import { FOODI_LOGO_ORIENTATION } from '../FoodiLogo/utils';
import TabBarIcon from '../TabBarIcon';

export interface IProps {
  services?: IService[];
}

class Menu extends PureComponent<IProps> {
  public render(): ReactNode {
    const { services } = this.props;

    const catererServices = services && getServiceByType(services, HOLDING_SERVICE_TYPES.CATERER);
    const url = catererServices && catererServices.length > 0 ? catererServices[0].url : undefined;

    return (
      <View style={media(styles.container)}>
        {!isMobile() && (
          <View style={media(styles.logoContainer)}>
            <FoodiLogo orientation={FOODI_LOGO_ORIENTATION.horizontal} />
          </View>
        )}
        <TabBarIcon
          routeName={getWebRouteName('home')}
          text={I18n.t('dashboard.home.title')}
          name={'home'}
        />
        <TabBarIcon
          routeName={getWebRouteName('eat')}
          text={I18n.t('dashboard.eat.title')}
          name={'eat'}
        />
        <TabBarIcon
          routeName={getWebRouteName('balance')}
          text={I18n.t('dashboard.balance.title')}
          name={'balance'}
        />
        <TabBarIcon
          routeName={getWebRouteName('profile')}
          text={I18n.t('dashboard.profile.title')}
          name={'profile'}
        />
        {isDesktop() &&
          url && (
            <TabBarIcon
              routeName={url}
              text={I18n.t('dashboard.cateringBusiness.title')}
              name={'cateringBusiness'}
            />
          )}
        {!isMobile() && (
          <View style={media(styles.holdingLogoContainer)}>
            <HoldingLogo width={HOLDING_LOGO_WIDTH} height={HOLDING_LOGO_HEIGHT} />
          </View>
        )}
      </View>
    );
  }
}

const HOLDING_LOGO_WIDTH = 85;
const HOLDING_LOGO_HEIGHT = 27;

interface IStyle {
  container: IMedia<ViewStyle>;
  holdingLogoContainer: IMedia<ViewStyle>;
  logoContainer: IMedia<ViewStyle>;
}

export const TABLET_LOGO_PADDING =
  (theme.config.TABLET_MENU_HEADER_HEIGHT - HORIZONTAL_FOODI_LOGO_HEIGHT) / 2;

const styles: IStyle = {
  container: {
    _desktop: {
      backgroundColor: theme.colors.background,
      flexDirection: 'column',
      paddingHorizontal: theme.margins.unit * 5,
      paddingVertical: theme.margins.unit * 3,
      width: theme.config.DESKTOP_MENU_WIDTH,
    },
    _mobile: {
      backgroundColor: theme.colors.background,
      borderTopColor: theme.colors.border,
      borderTopWidth: theme.config.MOBILE_MENU_BORDER,
      flexDirection: 'row',
      height: theme.config.MOBILE_MENU_HEIGHT,
      justifyContent: 'center',
      paddingVertical: theme.config.MOBILE_MENU_PADDING,
      position: 'relative',
      width: '100%',
    },
    _tablet: {
      alignItems: 'center',
      backgroundColor: theme.colors.background,
      flexDirection: 'row',
      height: theme.config.TABLET_MENU_HEADER_HEIGHT,
      justifyContent: 'center',
      paddingVertical: theme.margins.unit * 2,
      position: 'relative',
      width: '100%',
    },
  },
  holdingLogoContainer: {
    _desktop: { flex: 1, justifyContent: 'flex-end' },
    _mobile: {},
    _tablet: {
      position: 'absolute',
      right: theme.margins.unit * 3,
      top: TABLET_LOGO_PADDING,
    },
  },
  logoContainer: {
    _desktop: {
      marginBottom: theme.margins.unit * 6,
    },
    _mobile: {},
    _tablet: {
      left: theme.margins.unit * 3,
      position: 'absolute',
      top: TABLET_LOGO_PADDING,
    },
  },
};

export default Menu;
