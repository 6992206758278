import moment from 'moment';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { ListRenderItemInfo, ScrollView, StyleSheet, Text, View, ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import LoadingCard from '../../components/LoadingCard';
import I18n from '../../lib/i18n';
import { resetSlotForOffer, setSlotByOfferId } from '../../redux/clickAndCollect/actions';
import store from '../../redux/store';
import theme from '../../theme';

import {
  getOfferSlots_offer_Offer as IOffer,
  getOfferSlots_offer_Offer_slots as IOfferSlot,
} from '../../types/clickandcollect/getOfferSlots';

import { getBookingTemplateOffer_pos_Pos_bookingOfferTemplate_nextOrderableOffers as IOfferBooking } from '../../types/bookingservice/getBookingTemplateOffer';
import Button from '../Button';

export interface IProps {
  isLoading: boolean;
  offer: IOffer | IOfferBooking;
  slotId: string | null;
  order?: any;
  isBookingOffer?: boolean | null;
  orderWithdrawRange?: string;
  maxOrdersPerSlot?: number;
}

const MINIMUM_REMAINING_ORDERS_PER_SLOT = 20;

class SlotsList extends PureComponent<IProps> {
  state = {
    edited: false,
    isBookingOffer: !!this.props.isBookingOffer,
  };

  public componentWillUnmount(): void {
    const { offer } = this.props;
    store.dispatch(
      resetSlotForOffer({
        offerId: offer.id,
      })
    );
  }

  public render(): ReactNode {
    const { isLoading, offer, slotId, order, orderWithdrawRange, maxOrdersPerSlot } = this.props;
    const numColumns: number = 3;
    const slots = offer.slots || [];
    let slotIndex = -1;

    // allow access to the old slot if the user decides to change products
    if (orderWithdrawRange) {
      const slot = slots.findIndex(
        (mySlot: IOfferSlot): boolean => mySlot.withdrawRange === orderWithdrawRange
      );
      if (slot !== -1) {
        slots[slot].selectableAt = true;
      }
    }

    if (slotId) {
      const slotNumber = slots.findIndex(
        (mySlot: IOfferSlot): boolean => mySlot.withdrawRange === slotId
      );
      if (slots && slotNumber !== -1 && slots[slotNumber].selectableAt) {
        slotIndex = slotNumber;
      } else {
        // current slot is not in the slots list
        if (!this.state.isBookingOffer) {
          store.dispatch(
            resetSlotForOffer({
              offerId: offer.id,
            })
          );
          slotIndex = -1;
        }
      }
    }

    const slotsComponent = slots.lenght === 0 ? this.renderEmptyList() : (
      <ScrollView contentContainerStyle={styles.flatListContentContainer} testID="slotsList">
        {slots.map((eachSlot: IOfferSlot, index: number) => this.renderItem(eachSlot, index, numColumns, slotIndex, maxOrdersPerSlot, order))}
      </ScrollView>
    )

    return (
      <LoadingCard isLoading={isLoading} style={styles.contentContainer} big noPadding>
        {offer && slots ? slotsComponent : null}
      </LoadingCard>
    );
  }

  private onPress = (slotNumber: number): void => {
    if (this.state.isBookingOffer) {
      this.setState({ edited: true });
    }
    const idSlot = (this.props.offer.slots || [])[slotNumber].withdrawRange;
    const offerId = this.props.offer ? this.props.offer.id : undefined;

    if (idSlot && offerId) {
      store.dispatch(
        setSlotByOfferId({
          idSlot,
          offerId,
        })
      );
    }
  };

  private renderEmptyList = (): ReactElement<View> => (
    <View style={styles.emptyContainer}>
      <Text>{I18n.t('pointOfSale.list.emptyContent')}</Text>
    </View>
  );

  private renderItem = (
    arg: ListRenderItemInfo<IOfferSlot>,
    index: number,
    numColumns: number,
    slotIndex: number,
    maxOrdersPerSlot: number | undefined,
    order?: any
  ): ReactElement<{}> => {
    const item = arg;
    const interval = item.withdrawRange.split('/');
    const startDate = moment(interval[0]);
    const endDate = moment(interval[1]);

    const hour = startDate.format('HH:mm');
    const endTime = endDate.format('HH:mm');
    const isSelected = slotIndex === index;
    let selectedBookingSlot: boolean = false;

    const currentOrderSlot = order ? order.node.withdrawRange.split('/') : null;
    const currentOrderHour: any = currentOrderSlot
      ? moment(currentOrderSlot[0]).format('HH:mm')
      : null;
    const remainingSeats = maxOrdersPerSlot ? maxOrdersPerSlot - item.numOrders : 0;
    // we use the plural when its 0 or higher than 1
    const remaingSeatsLabel =
      remainingSeats !== 1
        ? I18n.t('dashboard.eat.bookingService.slotChoice.remainingSeatsPlural')
        : I18n.t('dashboard.eat.bookingService.slotChoice.remainingSeatsSingular');
    const noRemainingSeatsLabel = I18n.t(
      'dashboard.eat.bookingService.slotChoice.noRemainingSeats'
    );

    if (currentOrderHour === hour && !this.state.edited) {
      selectedBookingSlot = true;
      const idSlot = (this.props.offer.slots || [])[index].withdrawRange;
      const offerId = this.props.offer ? this.props.offer.id : undefined;
      if (this.state.isBookingOffer) {
        if (idSlot && offerId) {
          store.dispatch(
            setSlotByOfferId({
              idSlot,
              offerId,
            })
          );
        }
      }
    }

    let addStyle;
    if (!item.selectableAt && !selectedBookingSlot && this.state.isBookingOffer) {
      addStyle = styles.disabledItemContainer;
    } else if (selectedBookingSlot && this.state.isBookingOffer) {
      addStyle = styles.selectedItemContainer;
    } else if (!item.selectableAt && !selectedBookingSlot) {
      addStyle = styles.disabledItemContainer;
    } else if (isSelected) {
      addStyle = styles.selectedItemContainer;
    } else {
      addStyle = styles.notSelectedItemContainer;
    }

    return (
      <View style={{ flexBasis: `${100 / numColumns}%`, padding: 5 }}>
        <Button
          testID={`slotsList_slotButton_${index}`}
          style={StyleSheet.flatten([
            styles.commonItemContainer,
            addStyle,
            { flexBasis: `${100 / numColumns}%` },
          ])}
          onPress={(): void => this.onPress(index)}
          disabled={!item.selectableAt}
        >
          {isSelected && (
            <Icon
              name="check"
              style={{ position: 'absolute', right: 10, top: 10 }}
              size={6}
              color={'#FFFFFF'}
            />
          )}
          <Text style={addStyle}>{hour}</Text>
          <Text style={StyleSheet.flatten([addStyle, styles.endTime])}>{endTime}</Text>
          {maxOrdersPerSlot !== undefined &&
            remainingSeats < MINIMUM_REMAINING_ORDERS_PER_SLOT &&
            item.numOrders !== 0 && (
              <Text style={StyleSheet.flatten([addStyle, styles.remainingSeats])}>
                {remainingSeats === 0
                  ? noRemainingSeatsLabel
                  : remainingSeats + ' ' + remaingSeatsLabel}
              </Text>
            )}
        </Button>
      </View>
    );
  };
}

export interface IStyle {
  commonItemContainer: ViewStyle;
  container: ViewStyle;
  disabledItemContainer: ViewStyle;
  notSelectedItemContainer: ViewStyle;
  page: ViewStyle;
  selectedItemContainer: ViewStyle;
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    marginBottom: 0,
    marginTop: theme.margins.cardUnit * 2,
    padding: 0,
  },
  contentContainer: {
    padding: 0,
    paddingLeft: theme.margins.padding,
    paddingRight: theme.margins.padding,
  },
  flatListContentContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 0,
  },
  commonItemContainer: {
    ...theme.fonts.slotsItem,
    alignItems: 'center',
    height: 80,
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#F0F3F8',
    borderRadius: 4,
  },

  disabledItemContainer: {
    backgroundColor: '#EFF2F7FF',
    color: '#9EA3AB',
    fontSize: 16,
    fontWeight: 'bold',
  },
  emptyContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  notSelectedItemContainer: {
    backgroundColor: '#00000000',
    color: '#9EA3AB',
    fontSize: 16,
    fontWeight: 'bold',
  },
  selectedItemContainer: {
    backgroundColor: '#61D57BFF',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
  },
  endTime: {
    paddingTop: 2,
    fontSize: 12,
    fontWeight: 'normal',
  },
  remainingSeats: {
    paddingTop: 2,
    fontSize: 9,
    fontWeight: 'normal',
  }
});

export default SlotsList;
