import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { DEFAULT_PAGE_HORIZONTAL_PADDING } from '../AppPage';

interface IProps {
  style?: StyleProp<ViewStyle>;
}

class FullWidthView extends PureComponent<IProps> {
  public render(): ReactNode {
    const { style } = this.props;

    return <View style={[styles.container, style]}>{this.props.children}</View>;
  }
}

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    marginLeft: -DEFAULT_PAGE_HORIZONTAL_PADDING,
    marginRight: -DEFAULT_PAGE_HORIZONTAL_PADDING,
  },
});

export default FullWidthView;
