import { loader } from 'graphql.macro';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withHandlers, withProps } from 'recompose';

import withNavigation from '../../../hoc/withNavigation';
import Logger from '../../../lib/logger';

import EmailNotActivated, { IProps } from './EmailNotActivated.component';

const sendUserEmailVerification = loader('../../../queries/sendUserEmailVerification.gql');

interface IWithTokenProps {
  token?: string;
}

type ISendUserEmailMutation = () => Promise<void>;

const withTokenProps = (props: NavigationInjectedProps): IWithTokenProps => ({
  token: props.navigation.getParam('token'),
});

const sendUserEmailVerificationHandler = (
  props: NavigationInjectedProps & IWithTokenProps & WithApolloClient<{}>
): ISendUserEmailMutation => async (): Promise<void> => {
  const { navigation, token } = props;

  try {
    await props.client.mutate<boolean>({
      mutation: sendUserEmailVerification,
      variables: {
        token,
      },
    });
  } catch (error) {
    Logger.error(error);
  } finally {
    navigation.navigate('signUpConfirmation');
  }
};

export type IConnectedProps = NavigationInjectedProps &
  IWithTokenProps &
  ISendUserEmailMutation &
  WithApolloClient<{}>;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withApollo,
  withNavigation,
  withProps(withTokenProps),
  withHandlers({ sendUserEmail: sendUserEmailVerificationHandler })
)(EmailNotActivated);
