import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import LoadingView from '../../../components/LoadingView';
import MenuTabNavigator from '../../../components/MenuTabNavigator';
import PosCardBody from '../../../components/PointOfSaleCard/PosCardBody';
import PosCardHeader from '../../../components/PointOfSaleCard/PosCardHeader';
import { WebPage } from '../../../componentsWeb';
import I18n from '../../../lib/i18n';
import { isMobile } from '../../../lib/responsive';
import theme from '../../../theme';
import { IPos } from '../../../types/common';

import { AttendanceChart, AttendanceHeader } from './Attendance.component';

export interface IProps {
  hasError?: boolean;
  isLoading?: boolean;
  pointOfSale: Pick<
    IPos,
    'menus' | 'name' | 'schedules' | 'type' | 'image' | 'concept' | 'attendance' | 'typeModel'
  >;
}

interface IState {
  isAttendanceOpen: boolean;
}

class Menu extends PureComponent<IProps> {
  public state: IState = {
    isAttendanceOpen: false,
  };
  public render(): ReactNode {
    const { hasError, isLoading, pointOfSale } = this.props;

    return (
      <WebPage title={I18n.t('dashboard.menu.pageTitle')}>
        <LoadingView hasError={hasError} isLoading={isLoading}>
          <View style={styles.container}>{pointOfSale && this.renderDetails()}</View>
        </LoadingView>
      </WebPage>
    );
  }

  private onAttendancePress = (): void => {
    this.setState({ isAttendanceOpen: !this.state.isAttendanceOpen });
  };

  private renderDetails = (): ReactNode => {
    const flexDirection = isMobile() ? 'column' : 'row';

    return (
      <>
        <View style={[styles.attendanceContainer, { flexDirection }]}>
          <PosCardHeader name={this.props.pointOfSale.name} posType={this.props.pointOfSale.type} />
          {!!get(this.props, 'pointOfSale.attendance.prediction.maxWeekCount') && (
            <AttendanceHeader
              attendance={this.props.pointOfSale.attendance}
              reverseLabels={isMobile()}
              onAttendancePress={this.onAttendancePress}
              isAttendanceOpen={this.state.isAttendanceOpen}
              schedules={this.props.pointOfSale.schedules}
            />
          )}
        </View>
        {!!get(this.props, 'pointOfSale.attendance.prediction.maxWeekCount') && (
          <AttendanceChart
            attendance={this.props.pointOfSale.attendance}
            isAttendanceOpen={this.state.isAttendanceOpen}
            schedules={this.props.pointOfSale.schedules}
          />
        )}
        <PosCardBody
          concept={this.props.pointOfSale.concept}
          posType={this.props.pointOfSale.type}
          image={this.props.pointOfSale.image}
          posTypeModel={this.props.pointOfSale.typeModel}
        />
        <MenuTabNavigator
          numColumns={1}
          menus={this.props.pointOfSale.menus}
          schedules={this.props.pointOfSale.schedules}
        />
      </>
    );
  };
}

interface IStyle {
  attendanceContainer: ViewStyle;
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  attendanceContainer: { justifyContent: 'space-between', width: '100%' },
  container: {
    backgroundColor: theme.colors.background,
  },
});

export default Menu;
