import React, { PureComponent, ReactNode } from 'react';
import { FlatListProps, StyleSheet, View, ViewStyle } from 'react-native';

import { getMenuByDays, ISection } from '../../services/menusFormatter';
import { getDayOfWeek, getScheduleHoursOfDay } from '../../services/schedulesFormatter';
import { IPosMenu, IPosSchedule, IHolding, IPos } from '../../types/common';
import Menu from '../Menu';
import WeeklyTabBar from '../WeeklyTabBar';

import { getDisplayedDays, NUMBER_OF_DAYS_DISPLAYED_IN_MENU } from './utils';

export interface IProps extends Partial<FlatListProps<ISection>> {
  initialActiveDayIndex?: string;
  menus: IPosMenu[];
  schedules: IPosSchedule[];
  holding?: IHolding;
  pointOfSale?: IPos;
}

export interface IState {
  activeDayIndex: number;
}

const setInitialActiveDayIndex = (index: string | undefined): number => {
  const initialActiveDayIndex = Number(index);

  if (
    isNaN(initialActiveDayIndex) ||
    initialActiveDayIndex < 0 ||
    initialActiveDayIndex > NUMBER_OF_DAYS_DISPLAYED_IN_MENU - 1
  ) {
    return 0;
  }

  return initialActiveDayIndex;
};

class MenuTabNavigator extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activeDayIndex: setInitialActiveDayIndex(this.props.initialActiveDayIndex),
    };
  }

  public render(): ReactNode {
    const { menus, schedules, pointOfSale, ...otherProps } = this.props;
    const days = getDisplayedDays();
    const activeDay = days[this.state.activeDayIndex];
    const activeDayOfWeek = getDayOfWeek(activeDay);

    const menuByDays = getMenuByDays(menus);
    const activeMenu = menuByDays[activeDay];
    const activeHours = getScheduleHoursOfDay(schedules, activeDayOfWeek);

    return (
      <View>
        <WeeklyTabBar
          activeDayIndex={this.state.activeDayIndex}
          days={days}
          onDayChange={this.onDayChange}
        />
        <Menu
          {...otherProps}
          isOpen={!!activeHours}
          menuElements={(activeMenu && activeMenu.elements) || []}
          pointOfSale={pointOfSale}
        />
      </View>
    );
  }

  private onDayChange = (index: number): void => {
    this.setState({ activeDayIndex: index });
  };
}

export default MenuTabNavigator;
