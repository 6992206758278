import { compose, withProps, withHandlers } from 'recompose';
import withNavigation from '../../../../../hoc/withNavigation';
import OfferArticlesDetailsPage, { IProps } from './OfferArticlesDetailsPage.component';
import {
  IElementQuantity,
  ISetSelectedItemBakingModalAction,
  ISetElementQuantityByOfferIdAction,
  setElementQuantityByOfferId,
  setSelectedItemBakingModal,
} from '../../../../../redux/clickAndCollect/actions';
import { ActionCreator, connect } from 'react-redux';
import { IAppState } from '../../../../../redux/reducer';
import { getElementsQuantityByOfferId } from '../../../../../redux/clickAndCollect/selectors';
import { getOffer_offer_Offer_offerTemplate_OfferTemplate_mealHeartRule as IMealHeartRule } from '../../../../../types/clickandcollect/getOffer';
import { getBadgeNumber } from '../../../../../../src/redux/holding/selectors';

interface IMapStateToProps {
  elementsQuantity: IElementQuantity[];
  maxSameProduct?: number;
  mealHeartRule?: IMealHeartRule;
  stock?: number;
  badgeNumber: string | null;
}

export interface IDispatchProps {
  setElementQuantityByOfferId: ActionCreator<ISetElementQuantityByOfferIdAction>;
  setSelectedItemBakingModal: ActionCreator<ISetSelectedItemBakingModalAction>;
}

type IOnCounterPress = (quantity: number, bakings: string[]) => void;

const withOnCounterPress = (
  ownProps: IMapStateToProps & IDispatchProps & IProps
): IOnCounterPress => (quantity: number, bakings: string[]): void => {
  const prevQuantities = ownProps.elementsQuantity;
  // remove duplicate articles
  let filteredQuantities: IElementQuantity[] = prevQuantities
    ? prevQuantities.filter(
        (item: IElementQuantity): boolean => item.elementId !== ownProps.article.id
      )
    : [];
  const prevQuantity = ([] as IElementQuantity[])
    .concat(prevQuantities || [])
    .reduce((sum, item) => (sum += item.elementId === ownProps.article.id ? item.quantity : 0), 0);

  // handle minus for baking
  if (prevQuantity > quantity && ownProps.article.baking) {
    let index = -1;
    prevQuantities.forEach((item, idx) => {
      if (item.elementId === ownProps.article.id) {
        index = idx;
      }
    });
    if (index !== -1) {
      if (prevQuantities[index].quantity > 1) {
        prevQuantities[index].quantity = prevQuantities[index].quantity - 1;
      } else {
        filteredQuantities = [
          ...prevQuantities.slice(0, index),
          ...prevQuantities.slice(index + 1),
        ];
      }
    }
  } else if (ownProps.article.baking) {
    bakings.forEach(baking => {
      filteredQuantities.push({
        elementId: ownProps.article.id,
        price: ownProps.article.inheritedPrice,
        quantity: 1,
        family: ownProps.article.inheritedFamily!,
        chosenBaking: baking,
      });
    });
  }
  // handle quantity for normal product
  if (quantity !== 0 && !ownProps.article.baking) {
    filteredQuantities.push({
      elementId: ownProps.article.id,
      price: ownProps.article.inheritedPrice,
      quantity,
      family: ownProps.article.inheritedFamily!,
      container: ownProps.article.container,
    });
  }

  ownProps.setElementQuantityByOfferId({
    elementsQuantity: filteredQuantities,
    offerId: ownProps.offer.id,
  });
};

const withNavigationParams = (ownProps: IProps) => ({
  article: ownProps.navigation.getParam('item'),
  offer: ownProps.navigation.getParam('offer'),
});

const mapDispatchToProps: IDispatchProps = {
  setElementQuantityByOfferId,
  setSelectedItemBakingModal,
};

const determineItemAllowedQuantities = (mealHeartRules = [], item = [], productsInCart = []) => {
  const { min: minSameProduct, max: maxSameProduct } = mealHeartRules.quantityRules.find(
    prop => prop.property === 'sameProductPerOrder'
  );
  const products = productsInCart.filter(p => p.elementId === item.id);
  const quantitySum = products.reduce((total, product) => total + product.quantity, 0);
  return {
    maxSameProduct: maxSameProduct - quantitySum,
  };
};

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => {
  if (ownProps.offer.offerTemplate.__typename === 'TableServiceOfferTemplate') {
    return {
      elementsQuantity: getElementsQuantityByOfferId(state, ownProps.offer.id),
      stock: ownProps.article.quantityRemaining,
      badgeNumber: getBadgeNumber(state),
    };
  }

  return {
    elementsQuantity: getElementsQuantityByOfferId(state, ownProps.offer.id),
    maxSameProduct: determineItemAllowedQuantities(
      ownProps.offer.offerTemplate.mealHeartRule,
      ownProps.article,
      getElementsQuantityByOfferId(state, ownProps.offer.id)
    ).maxSameProduct,
    mealHeartRule: ownProps.offer.offerTemplate.mealHeartRule,
    stock: ownProps.article.quantityRemaining,
    badgeNumber: getBadgeNumber(state),
  };
};

export default compose<IProps, {}>(
  withNavigation,
  withProps(withNavigationParams),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({ onCounterPress: withOnCounterPress })
)(OfferArticlesDetailsPage);
