import React from 'react';
import { View, StyleSheet, ViewStyle, PixelRatio } from 'react-native';
import { getCloudimageUrl } from '../../../../../../services/cloudimage';
import MarketingCardContent from '../../../../../../components/MarketingCardContent';
import theme from '../../../../../../theme';
import { IMarketingCard, IMarketingCardContents } from '../../../../../../types/common';
import I18n from '../../../../../../lib/i18n';
import moment from 'moment';

const BODY_CARD_HEIGHT = 158;
interface IProps {
  marketingCard: IMarketingCard;
}
const MarketingCardArticle: React.FC<IProps> = ({ marketingCard }: IProps) => {
  let marketingCardContent: IMarketingCardContents | undefined =
    marketingCard.marketingCardContents[0];

  if (marketingCard.marketingCardContents.length > 1) {
    marketingCardContent = marketingCard.marketingCardContents.find(
      (content: IMarketingCardContents) => content.language === I18n.currentLocale()
    );
  }

  const imageUri = getCloudimageUrl(
    marketingCard.mainImage.path,
    'height',
    String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_HEIGHT))
  );
  const hotIconSvg = `
    <svg width="6px" height="9px" viewBox="0 0 6 9">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Atom/Buttons-&amp;-Tags/tags/tag-icon" transform="translate(-9.000000, -5.000000)">
          <g id="Group" transform="translate(7.020087, 5.000000)">
            <path
                d="M3.17705829,7.63020207 C3.3939209,8.2036884 3.67981583,8.53034856 4.03474309,8.61018254 C4.56713398,8.72993352 5.82751934,8.95579646 6.22705078,8.55249023 C6.62658222,8.14918401 6.96215351,7.64314153 6.86120136,6.43678166 C6.79389993,5.63254175 6.23119648,4.31721379 5.17309101,2.49079778 C4.76230876,3.95726913 4.38285945,4.78873072 4.03474309,4.98518254 C3.51256855,5.27986028 3.00481708,5.92622258 2.96160419,6.43678166 C2.9327956,6.77715438 3.00461363,7.17496119 3.17705829,7.63020207 Z"
                id="Path-6"
                fill="#FFB800"></path>
            <path
                d="M4.056836,8.625 C1.66260523,7.47115385 1.28760523,5.42307692 3.306836,2.82692308 C3.33568216,3.51923077 3.39337446,4.18269231 3.79722062,4.55769231 C3.681836,3.95192308 3.19145139,1.81730769 5.84529754,0 C5.03760523,3.77884615 10.7779898,4.84615385 6.2491437,8.56730769 C7.46068216,6.11538462 5.41260523,6.20192308 5.35491293,3.92307692 C4.431836,4.81730769 4.34529754,5.74038462 4.69145139,6.80769231 C4.31645139,6.66346154 3.94145139,6.28846154 3.71068216,5.79807692 C3.306836,6.83653846 3.681836,7.90384615 4.056836,8.625 Z"
                id="Atom/icons/tags/event"
                fill="#FF3131"></path>
          </g>
        </g>
      </g>
    </svg>`;

  const [dateOfPublication] = marketingCard.publicationRange.split('/');
  const dateOfPublicationFormatted = moment(dateOfPublication).format('DD/MM/yyyy');
  const contentTitle = marketingCardContent ? marketingCardContent.contentTitle : '';
  const htmlArticleHeader = `
    <div style="display:flex;flex-direction:column; margin-top: 15px;">
      <img src="${imageUri}" style="object-fit:cover;width:100%;height:285px;border-radius:8px;">
      <span style="display:flex; align-items: center; justify-content: center;width:fit-content;padding: 2.5px 12px;background-color:#FFDB00;border-radius:14px;position:absolute;top:2px;left:20px;z-index:99">
        ${hotIconSvg + ' '}
        <span style="font-size:11px;color:#232323;font-family:'Open Sans';margin-left:5px;">Article</span>
      </span>
      <span style="color: #232323;
      font-family: 'Open Sans';
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 33px;
      margin-top: 20px;
      ">${contentTitle}</span>

      <span style="color: #B9B9B9;
      font-family: "Open Sans";
      font-size: 16px;
      letter-spacing: 0;
      line-height: 23px;
      ">${I18n.t('dashboard.home.marketing.published')} ${dateOfPublicationFormatted}</span>
    </div>`;

  return (
    <View style={styles.wrapper}>
      <MarketingCardContent
        articleHeaderHtml={htmlArticleHeader}
        marketingCardContent={marketingCardContent && marketingCardContent.content}
      />
    </View>
  );
};

interface IStyle {
  wrapper: ViewStyle;
}
const styles = StyleSheet.create<IStyle>({
  wrapper: {
    flexGrow: 1,
    backgroundColor: theme.colors.background,
  },
});

export default MarketingCardArticle;
