import { Formik, FormikActions } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { FetchResult } from 'react-apollo';
import { NavigationInjectedProps } from 'react-navigation';

import Logger from '../../lib/logger';
import { validationEmailFront } from '../../services/form/validationSchema';
import { VALIDATOR_ERROR_NAME } from '../../services/form/validatorError';

import AlertOpeningFormikForm from './AlertOpeningFormikForm';

export interface IProps extends NavigationInjectedProps {
  registerEmailLead: (email: string) => Promise<FetchResult>;
}

interface IValues {
  email: string;
}

class AlertOpeningForm extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        initialValues={{ email: '' }}
        component={AlertOpeningFormikForm}
        validationSchema={validationEmailFront}
      />
    );
  }

  private handleSubmit = async (
    values: IValues,
    { setErrors, setSubmitting }: FormikActions<IValues>
  ): Promise<void> => {
    const { email } = values;

    if (!email) {
      setSubmitting(false);

      return;
    }

    setErrors({ email: undefined });

    try {
      await this.props.registerEmailLead(values.email);
      this.props.navigation.navigate('confirmEmailLead');
    } catch (error) {
      switch (error.name) {
        case VALIDATOR_ERROR_NAME:
          setErrors(error.toFormikError());
          break;
        default:
          Logger.error(error);
      }
    } finally {
      setSubmitting(false);
    }
  };
}

export default AlertOpeningForm;
