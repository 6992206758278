import React, { PureComponent, ReactNode } from 'react';
import { withCookies } from 'react-cookie';
import { NavigationInjectedProps, NavigationParams } from 'react-navigation';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ActionCreator } from 'redux';

import { isWeb } from '../../lib/responsive';
import {
  IAuthenticationTokenAction,
  setAuthenticationToken,
} from '../../redux/authentication/actions';
import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';
import { createCookie } from '../../services/cookiesManager';
import navigationService from '../../services/navigation/navigation';
import withNavigation from '../withNavigation';
import withUserTracking from '../withUserTracking';

interface IMapStateToProps {
  token?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

interface IDispatchProps {
  setAuthenticationToken: ActionCreator<IAuthenticationTokenAction>;
}

const mapDispatchProps: IDispatchProps = {
  setAuthenticationToken,
};

interface IProps extends NavigationInjectedProps {
  setAuthenticationToken: ActionCreator<IAuthenticationTokenAction>;
  token?: string;
}

const withAuthorization = <T extends {} = {}>(
  Component: React.ComponentType<T>
): React.ComponentType<T> => {
  class PrivateComponent extends PureComponent<T & IProps> {
    public render(): ReactNode {
      const token = this.props.navigation.getParam('token');

      if (token && token !== this.props.token) {
        this.props.setAuthenticationToken({ token });

        return null;
      }
      if (!this.props.token) {
        const navigationState = this.props.navigation.state as {
          params: NavigationParams;
          routeName?: string;
        };
        const route = navigationService.getRoute(navigationState.params, navigationState.routeName);
        this.props.navigation.navigate('landing', route ? { signInRedirection: route } : {});

        return null;
      }

      if (isWeb()) {
        createCookie();
      }

      return <Component {...this.props} />;
    }
  }

  return compose<T & IProps, T>(
    connect(
      mapStateToProps,
      mapDispatchProps
    ),
    withNavigation,
    withUserTracking,
    /* tslint:disable */
    isWeb() ? withCookies : (Component: ReactNode): ReactNode => Component
  )(PrivateComponent);
};

export default withAuthorization;
