import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import theme from '../../../theme';
import { isWeb } from '../../../lib/responsive';
import { ITransaction } from '../../../types/getTransactions';
import VirtualTransactionIcon from '../..//VirtualTransactionIcon';
import Touchable from '../../Touchable';
import TransactionAmount from '../../TransactionAmount';
import TransactionAmountDateTime from '../../TransactionAmountDateTime';
import { getIconByPosType } from './utils';

export interface IProps extends NavigationInjectedProps {
  onPress?: () => void;
  transaction: ITransaction;
  testID?: string;
}

const Transaction: React.FC<IProps> = ({ onPress, transaction, navigation, testID }) => {
  const posType = transaction.pos ? transaction.pos.type : undefined;
  const posName = transaction.pos ? transaction.pos.name : 'Foodi';

  const VisibilitySVG = theme.images.visibilitySVG;
  const handleViewReceiptOnPress = () => {
    navigation.navigate('transactionReceipt', { transactionId: transaction.id });
  };

  const handleViewReceiptButton = () => (
    <TouchableOpacity
      style={styles.viewReceiptContainer}
      onPress={handleViewReceiptOnPress}
      testID={testID}
    >
      <VisibilitySVG />
    </TouchableOpacity>
  );

  return (
    <Touchable onPress={onPress} style={styles.container}>
      <View style={styles.imageContainer}>
        {!transaction.virtual ? (
          <Image style={styles.image} source={getIconByPosType(posType)} resizeMode="contain" />
        ) : (
          <VirtualTransactionIcon style={styles.image} />
        )}
      </View>
      <View style={styles.detailsContainer}>
        <Text style={styles.posName} numberOfLines={1}>
          {posName}
        </Text>
        <View style={styles.detailsSection}>
          <TransactionAmountDateTime dateTime={transaction.date} style={styles.date} />
          {!isWeb() && handleViewReceiptButton()}
        </View>
      </View>
      <View style={styles.amountContainer}>
        <TransactionAmount
          style={styles.amount}
          amount={
            Number(transaction.total.amount) !== 0 || !transaction.refill
              ? transaction.total
              : transaction.refill
          }
        />
      </View>
    </Touchable>
  );
};

export interface IStyle {
  amount: TextStyle;
  amountContainer: ViewStyle;
  container: ViewStyle;
  date: TextStyle;
  detailsContainer: ViewStyle;
  detailsSection: ViewStyle;
  image: ImageStyle;
  imageContainer: ViewStyle;
  posName: TextStyle;
  viewReceiptContainer: ViewStyle;
}

const TRANSACTION_ICON_WIDTH = 20;

const styles = StyleSheet.create<IStyle>({
  amount: {
    ...theme.fonts.smallAmount,
  },
  amountContainer: {
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
    paddingHorizontal: theme.margins.cardUnit * 2,
    paddingVertical: theme.margins.cardUnit,
  },
  date: {
    ...theme.fonts.cardText,
  },
  detailsContainer: {
    flex: 1,
    paddingHorizontal: theme.margins.cardUnit,
  },
  detailsSection: {
    flexDirection: 'row',
  },
  image: {
    flex: 1,
    width: TRANSACTION_ICON_WIDTH,
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: TRANSACTION_ICON_WIDTH,
  },
  posName: {
    color: theme.colors.textBlack,
    ...theme.fonts.transactionPos,
  },
  viewReceiptContainer: {
    paddingHorizontal: theme.margins.unit,
  },
});

export default Transaction;
