import React, { ReactElement } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import CoverCard from '../../components/CoverCard';
import I18n from '../../lib/i18n';
import { getOfferTemplateSubtitle } from '../../services/offersFormatter';
import { isNextOffersAvailable } from '../../services/offer';
import theme from '../../theme';
import { IOfferTemplate } from '../../types/common';
import ClearButton from '../ClearButton';
import {
  OfferTemplateSalesType,
  OfferTemplateWithdrawalType,
  PaymentMethod,
} from '../../types/clickandcollect/globalTypes';

export interface IProps extends NavigationInjectedProps {
  offerTemplates: IOfferTemplate[];
  testID?: string;
}

const OffersList = ({ offerTemplates, navigation, testID }: IProps) => {
  const handleMultipleTranslations = (translationsArr = [], keys = [], locale = 'fr') => {
    const translations = {};
    keys.forEach(key => {
      if (key) translations[key] = handleTranslation(translationsArr, key, locale);
    });
    return translations;
  };

  const handleTranslation = (translationsArr = [], key = '', locale = 'fr') => {
    if (translationsArr.length === 0 || !translationsArr) {
      return null;
    } else if (translationsArr.length === 1) {
      return translationsArr[0]?.[key] ? translationsArr[0]?.[key] : null;
    }

    let tempTranslation = '';
    const selectedLanguageTranslations = translationsArr.filter(
      (translation: any) => translation.language === locale && !!translation?.[key]
    );

    if (selectedLanguageTranslations.length === 1) {
      tempTranslation = selectedLanguageTranslations[0][key];
    } else {
      const fallbackLanguage = locale === 'fr' ? 'en' : 'fr';
      // if other language than french is selected the fallback language is French if French is selected the fallback will be English
      const fallBackLanguageTranslations = translationsArr.filter(
        translation => translation.language === fallbackLanguage && !!translation?.[key]
      );
      if (fallBackLanguageTranslations.length === 1) {
        tempTranslation = fallBackLanguageTranslations[0][key];
      }
    }
    return tempTranslation ? tempTranslation : null;
  };

  const handlePlusInfoButton = (offerTemplate: IOfferTemplate, fullDescription: string) => {
    const onPressPlusInfo = (offerTemplate: IOfferTemplate, fullDescription: string): any => {
      const { navigate } = navigation;
      return navigate('plusInfo', {
        offerName: handleTranslation(offerTemplate?.translations, 'name', I18n.currentLocale()),
        fullDescription,
        offerTemplate,
        onOrderPress: (): void => {
          // order button in plus info page is following the same behavior of the order button in the offers list
          onPress(offerTemplate);
        }
      });
    };

    return fullDescription ? (
      <View style={styles.footerContainer}>
        <ClearButton
          testID={`${testID}_plusInfoButton_${offerTemplate.id}`}
          title={I18n.t('dashboard.eat.clickAndCollect.info')}
          onPress={(): void => onPressPlusInfo(offerTemplate, fullDescription)}
          color={theme.colors.black}
        />
      </View>
    ) : null;
  };

  const onPress = (offerTemplate: IOfferTemplate): any => {
    const { navigate } = navigation;
    const offerId = offerTemplate.nextOrderableOffer!.id;
    const offerTemplateId = offerTemplate.id;
    const posId = offerTemplate.pos.id;
    const havePayOnSiteMethod = offerTemplate?.paymentMethod?.paymentTypes.includes(PaymentMethod.ON_SITE);

    if (offerTemplate.__typename !== 'OfferTemplate') {
      return navigate('tableNumber', { offerId });
    }

    const isBundleOffer = offerTemplate.salesType === OfferTemplateSalesType.BUNDLE;

    if (
      offerTemplate.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
      offerTemplate.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
    ) {
      return navigate('tableNumber', {
        offerId,
        posId,
        skipTableGroupValidation: true,
        withCSITableValidation:
          offerTemplate.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE,
        onNextNavigateTo: ({ tableNumber }: { tableNumber: number }): void => {
          isBundleOffer ?
            navigate('offerBundleStep', { offerId, stepIndex: 0, offerTemplateId, tableNumber }) :
            navigate('offerArticles', { offerId, offerTemplateId, havePayOnSiteMethod, tableNumber });
        },
      });
    }

    return isBundleOffer ?
      navigate('offerBundleStep', { offerId, stepIndex: 0, offerTemplateId }) :
      navigate('offerArticles', { offerId, offerTemplateId, havePayOnSiteMethod });
  };

  return (
    <View>
      {offerTemplates.map(
        (item: IOfferTemplate, index: number): ReactElement<CoverCard> => {
          const offerTemplateTranslations = handleMultipleTranslations(
            item.translations,
            ['name', 'description', 'fullDescription'],
            I18n.currentLocale()
          );
          return (
            <View style={styles.offerContainer} key={`offer-${index}`}>
              <CoverCard
                testID={`${testID}_CoverCard_${item.id}`}
                sizeDivider={1}
                title={offerTemplateTranslations?.name}
                description={offerTemplateTranslations?.description}
                highlightedSubtitle={getOfferTemplateSubtitle(item)}
                image={item.image && item.image.path}
                disabled={!isNextOffersAvailable(item)}
                onPress={(): void => onPress(item)}
                unavailableMessage={
                  isNextOffersAvailable(item)
                    ? undefined
                    : I18n.t('dashboard.eat.clickAndCollect.unavailableOffer')
                }
                plusInfo={handlePlusInfoButton(item, offerTemplateTranslations?.fullDescription)}
                footerRenderer={
                  isNextOffersAvailable(item) ? (
                    <View style={styles.footerContainer}>
                      <ClearButton
                        testID={`${testID}_orderButton_${item.id}`}
                        title={I18n.t('dashboard.eat.clickAndCollect.order')}
                        onPress={(): void => onPress(item)}
                        color={theme.colors.blue}
                      />
                    </View>
                  ) : null
                }
              />
            </View>
          );
        }
      )}
    </View>
  );
};

export interface IStyle {
  footerContainer: ViewStyle;
  offerContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  footerContainer: {
    alignItems: 'flex-end',
  },
  offerContainer: {
    marginBottom: theme.margins.cardUnit * 2,
  },
});

export default OffersList;
