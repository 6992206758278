import jwtDecode from 'jwt-decode';

import { Auth0DecodedHash } from 'auth0-js';

import { getConfig } from '../../../environment';
import { authenticate } from '../../../redux/authentication/actions';
import store from '../../../redux/store';
import navigation from '../../../services/navigation';
import { getExpirationTokenInfoFromExpireIn } from '../utils';

export const handleSignIn = (authResult: Auth0DecodedHash, signInRedirection?: string): void => {
  const env = getConfig();

  const { EMAIL_VERIFICATION_KEY } = env.AUTH0.WEB;
  if (!authResult.accessToken || !authResult.expiresIn) {
    return;
  }

  const clientInfo = jwtDecode(authResult.accessToken) as Record<string, string>;

  if (clientInfo[EMAIL_VERIFICATION_KEY]) {
    navigation.navigate('signUpEmailNotActivated', {
      token: authResult.accessToken,
    });

    return;
  }

  const expirationTokenInfo = getExpirationTokenInfoFromExpireIn(authResult.expiresIn);

  store.dispatch(
    authenticate({
      emissionDate: expirationTokenInfo.emissionDate,
      expirationDate: expirationTokenInfo.expirationDate,
      expiresIn: Number(authResult.expiresIn),
      signInRedirection,
      token: authResult.accessToken,
    })
  );

  return;
};

export const signIn = { handleSignIn };
