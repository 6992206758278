import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import { toNumber } from '../../services/amountFormatter';
import theme from '../../theme';
import { IAmount } from '../../types/common';
import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';
import PricesCardItem from '../PricesCardItem';

export interface IProps {
  isLoading?: boolean;
  newBalance?: IAmount;
  oldBalance?: IAmount;
  refill?: IAmount;
  total?: IAmount;
}

class TotalTicketPricesCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, newBalance, oldBalance, refill, total } = this.props;

    return (
      <LoadingCard isLoading={isLoading}>
        <View style={styles.titleContainer}>
          <CardTitle text={I18n.t('transactions.totalTicket.title')} />
        </View>
        {oldBalance && (
          <PricesCardItem
            name={I18n.t('transactions.totalTicket.oldBalanceAmount')}
            amount={oldBalance}
          />
        )}
        {total && (
          <PricesCardItem
            name={I18n.t('transactions.totalTicket.totalAmount')}
            amount={{
              amount: Number(total.amount) === 0 ? total.amount : this.getTotalSpent(total.amount),
              currency: total.currency,
            }}
          />
        )}
        {total && refill && toNumber(refill.amount) !== 0 && (
          <PricesCardItem
            name={I18n.t('transactions.totalTicket.refillAmount')}
            amount={Number(total.amount) === 0 ? total : refill}
          />
        )}
        {newBalance && (
          <PricesCardItem
            name={I18n.t('transactions.totalTicket.newBalanceAmount')}
            amount={newBalance}
          />
        )}
      </LoadingCard>
    );
  }

  private getTotalSpent = (totalAmount: string | null): string | null => {
    const { refill } = this.props;

    if (totalAmount === null || !refill) {
      return totalAmount;
    }

    return (toNumber(totalAmount) + toNumber(refill.amount)).toString();
  };
}

interface IStyle {
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  titleContainer: {
    marginBottom: theme.margins.unit * 2,
  },
});

export default TotalTicketPricesCard;
