import { IHoldingServiceType } from '../constants/HoldingServices';

import { getConfig } from '../environment';
import { IService } from '../types/common';

const isDisneyHolding = (idHolding?: string): boolean => {
  const env = getConfig();

  return !!idHolding && idHolding === env.DISNEY_HOLDING_ID;
};
const getServiceByType = (services: IService[], type: IHoldingServiceType): IService[] => {
  return services.filter((service: IService): boolean => service.type === type);
};

export { isDisneyHolding, getServiceByType };
