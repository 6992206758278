import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { ActionCreator } from 'redux';

import I18n from '../../../lib/i18n';
import { IToggleRecipeDisclaimerAction } from '../../../redux/menu/actions';
import { Tracker } from '../../../services/analytics';
import { IElement, ISection } from '../../../services/menusFormatter';
import theme from '../../../theme';
import InfoRecipeDisclaimerForm from '../../InfoRecipeDisclaimerForm';
import Touchable from '../../Touchable';
import TriggerModal from '../../TriggerModal';
import TriggerModalContent from '../../TriggerModal/TriggerModalContent';
import MenuElement from '../MenuElement';
import MenuElementDishGroup from '../MenuElementDishGroup';
import { IHolding } from '../../../types/common';
import { filterAllergens, filterLabels, filterProducts, getMenuElementDescription } from '../../../services/menus';
import { IImportationTypes } from '../../../constants/ImportationTypes';

export interface IProps extends NavigationInjectedProps {
  hasUserAcceptedRecipeDisclaimer: boolean;
  isModalVisible: boolean;
  section: ISection;
  holding?: IHolding;
  onElementClick: (elementId: string, holdingSettingId?: string) => void;
  toggleRecipeDisclaimer: ActionCreator<IToggleRecipeDisclaimerAction>;
}

export interface IState {
  activeElementId: string;
}

class MenuSection extends PureComponent<IProps> {
  public state: IState = {
    activeElementId: '',
  };

  public render(): ReactNode {
    const { section } = this.props;
    const { elements, elementDishGroup } = section;
    const posId = this.props.navigation.getParam('posId');
    return (
      <View>
        <MenuElementDishGroup elementDishGroup={elementDishGroup} />
        <View style={styles.elements}>
          {elements.map((element: IElement) => {
            /*  if (!hasUserAcceptedRecipeDisclaimer) {
                return this.renderTriggerModal(element, posId);
            } */

            return this.renderTouchable(element);
          })}
        </View>
        <TriggerModalContent
          title={I18n.t('dashboard.menuElementDetails.infoRecipeDisclaimer.title')}
          isModalVisible={this.props.isModalVisible}
          hideModal={this.hideModal}
          renderContent={
            <InfoRecipeDisclaimerForm elementId={this.state.activeElementId} posId={posId} />
          }
        />
      </View>
    );
  }

  private hideModal = (): void => {
    this.props.toggleRecipeDisclaimer({ isRecipeDisclaimerOpened: false });
  };

  private renderTouchable = (element: IElement): ReactNode => {
    const preferences = this.props.holding?.settings?.productTransparencyPreferences;
    const { showDescription = false } = preferences ?? {};
    const menuType = this.props.holding?.importationType;
    const description = menuType ? getMenuElementDescription(element, menuType as IImportationTypes) : null;

    const hasContent = (
      filterAllergens(element, preferences).length > 0
      || filterLabels(element, preferences).length > 0
      || filterProducts(element, preferences).length > 1
      || (showDescription && description !== null)
    );

    return (
      <Touchable
        onPress={(): void => hasContent && this.props.onElementClick(element.id, this.props.holding?.settings?.id, this.props.holding?.importationType)}
        disabled={!hasContent}
        key={element.id}
      >
        <MenuElement key={element.id} element={element} holding={this.props.holding} />
      </Touchable>
    )
  };

  private renderTriggerModal = (element: IElement, posId: string): ReactNode => (
    <TriggerModal
      // disabled={!this.props.isRecipeDetailsEnabled}
      key={element.id}
      routeName="infoRecipeDisclaimer"
      title={I18n.t('dashboard.menuElementDetails.infoRecipeDisclaimer.title')}
      onPress={(): void => this.showModal(element.id)}
      navigationParams={{ elementId: element.id, posId }}
    >
      <MenuElement key={element.id} element={element} holding={this.props.holding} />
    </TriggerModal>
  );

  private showModal = (elementId: string): void => {
    this.setState({ activeElementId: elementId });

    Tracker.trackNavigation({ pathname: 'infoRecipeDisclaimer' });

    this.props.toggleRecipeDisclaimer({ isRecipeDisclaimerOpened: true });
  };
}

interface IStyle {
  elements: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  elements: {
    backgroundColor: theme.colors.white,
    paddingHorizontal: theme.margins.padding,
  },
});

export default MenuSection;
