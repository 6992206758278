import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import CodePush from 'react-native-code-push';
import {
  NavigationActions,
  NavigationResetAction,
  NavigationScreenProps,
  StackActions,
} from 'react-navigation';

import AppPage from '../../../components/AppPage';
import Button from '../../../components/Button';
import FakeInput from '../../../components/FakeInput';
import Paragraph from '../../../components/Paragraph';
import Stepper from '../../../components/Stepper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';

import { STATE } from '../../../components/FakeInput';
import { STATUS } from '../../../components/Stepper';
import I18n from '../../../lib/i18n';

class GuestExist extends PureComponent<NavigationScreenProps> {
  public render(): ReactNode {
    const email = this.props.navigation.getParam('guestEmail');

    return (
      <AppPage hasBlackHeader>
        <View style={styles.container}>
          <View>
            <Stepper currentStepNumber={2} currentStepStatus={STATUS.ERROR} />
            <Title text={I18n.t('signUp.guestExist.title')} />
          </View>
          <View>
            <SubTitle text={I18n.t('signUp.guestExist.subTitle')} />
            <Paragraph text={I18n.t('signUp.guestExist.paragraph')} />
            <FakeInput borderState={STATE.SUCCESS} textState={STATE.SUCCESS} value={email} />
            <Button onPress={this.goToSignIn} text={I18n.t('signUp.guestExist.validate')} />
          </View>
        </View>
      </AppPage>
    );
  }

  private goToSignIn = (): void => {
    const resetAction: NavigationResetAction = StackActions.reset({
      actions: [
        NavigationActions.navigate({ routeName: 'authStack', params: { autoLogIn: true } }),
      ],
      index: 0,
      key: null,
    });
    CodePush.allowRestart();
    this.props.navigation.dispatch(resetAction);
  };
}

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
});

export default GuestExist;
