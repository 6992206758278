import React, { PureComponent, ReactNode } from 'react';
import {
  GeolocationReturnType,
  Image,
  ImageStyle,
  Platform,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { NavigationScreenProps } from 'react-navigation';
import { request } from 'react-native-permissions';

import AppPage from '../../../components/AppPage';
import Button from '../../../components/Button';
import Paragraph from '../../../components/Paragraph';
import Stepper from '../../../components/Stepper';
import { STATUS } from '../../../components/Stepper';
import Title from '../../../components/Title';
import I18n from '../../../lib/i18n';
import getCurrentPositionWithPermissionCheck from '../../../services/geolocation';
import { idToUuid, Entity } from '../../../services/base64Id';
import { getUserSignUpParams } from '../../../services/navigation/params';
import theme from '../../../theme';

interface IState {
  isLoading: boolean;
}

class Geolocation extends PureComponent<NavigationScreenProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  public componentWillMount(): void {
    const { holding, badgeNumber, serialNumber, lastName, email } = getUserSignUpParams(
      this.props.navigation
    );

    this.requestLocationPermission();
    
    if (holding) {
      this.props.navigation.navigate('signUpIdentity', {
        holdingId: idToUuid(holding, Entity.HOLDING),
        badgeNumber,
        serialNumber,
        lastName,
        email,
      });
    }
  }

  public render(): ReactNode {
    return (
      <AppPage hasBlackHeader>
        <Stepper currentStepNumber={1} currentStepStatus={STATUS.ACTIVE} />
        <Title text={I18n.t('signUp.geolocation.title')} />
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={theme.images.geolocation} />
        </View>
        <View testID="signupGeolocation">
          <Paragraph text={I18n.t('signUp.geolocation.paragraph')} />
          <Button
            text={I18n.t('signUp.geolocation.findHolding')}
            onPress={this.handleFindHoldingsPress}
            isLoading={this.state.isLoading}
            testID="geolocationFindHolding"
          />
        </View>
      </AppPage>
    );
  }

  private requestLocationPermission = async (): Promise<void> => {

    if (Platform.OS === 'ios') {
      await request('ios.permission.LOCATION_WHEN_IN_USE');
    }
    else if (Platform.OS === 'android') {
      await request('android.permission.ACCESS_FINE_LOCATION');
    }
  };

  private handleFindHoldingsPress = async (): Promise<void> => {
    const { badgeNumber, serialNumber, lastName, email } = getUserSignUpParams(
      this.props.navigation
    );
    const onSuccess = (location: GeolocationReturnType): void => {
      const { latitude, longitude } = location.coords;
      this.setState({ isLoading: false });
      this.props.navigation.navigate('signUpNearHoldings', {
        latitude,
        longitude,
        badgeNumber,
        serialNumber,
        lastName,
        email,
      });
    };

    const onFail = (): void => {
      this.setState({ isLoading: false });
      this.props.navigation.navigate('signUpNearHoldings', {
        latitude: null,
        longitude: null,
        badgeNumber,
        serialNumber,
        lastName,
        email,
      });
    };

    this.setState({ isLoading: true });
    await getCurrentPositionWithPermissionCheck(onSuccess, onFail);
  };
}

export interface IStyle {
  image: ImageStyle;
  imageContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  image: {
    height: 111,
    resizeMode: 'contain',
    width: 71,
  },
  imageContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: theme.margins.unit * 9,
  },
});

export default Geolocation;
