import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import PointsOfSaleList from './PointsOfSaleList';
import TabBar from '../../../components/TabBar';
import { WebPage } from '../../../componentsWeb';
import { IWithGuestPaymentProfile } from '../../../hoc/withGuestPaymentProfile';
import { isAllowedToOrder } from '../../../services/guest';
import { isMobile } from '../../../lib/responsive';
import theme from '../../../theme';

import { IOfferTemplate } from '../../../types/common';
import { getOrdersFragment_edges_node_Order as IOrder } from '../../../types/clickandcollect/getOrdersFragment';

import TakeAwayList from './TakeAwayList';

interface IProps extends IWithGuestPaymentProfile {
  offerTemplates?: IOfferTemplate[];
  orders?: IOrder[];
}
class Eat extends PureComponent<IProps> {
  public render(): ReactNode {
    const { offerTemplates, orders, managementMode } = this.props;

    const showTabs =
      ((offerTemplates && offerTemplates.length > 0) || (orders && orders.length > 0)) &&
      isAllowedToOrder({ managementMode });

    return (
      <WebPage>
        {showTabs ? (
          <TabBar
            renderPointsOfSaleList={this.renderPointsOfSaleList}
            renderTakeAwayList={this.renderTakeAwayList}
          />
        ) : (
          this.renderPointsOfSaleList()
        )}
      </WebPage>
    );
  }

  private renderPointsOfSaleList = (): ReactElement<{}> => (
    <>
      <View style={styles.listContainer}>
        <PointsOfSaleList isPollable withFilters={true} numColumns={isMobile() ? 1 : 2} />
      </View>
    </>
  );

  private renderTakeAwayList = (): ReactElement<{}> => <TakeAwayList />;
}

interface IStyle {
  listContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  listContainer: {
    marginBottom: theme.margins.cardUnit * 3,
  },
});

export default Eat;
