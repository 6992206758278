import { StyleSheet } from 'react-native';

import { IStyle } from './SelectableList.component';

const BUTTON_MAX_WIDTH = 360;
const LIST_MIN_HEIGHT = 400;

const styles = StyleSheet.create<IStyle>({
  button: {
    alignSelf: 'center',
    maxWidth: BUTTON_MAX_WIDTH,
    width: '100%',
  },
  container: {
    flex: 1,
    minHeight: LIST_MIN_HEIGHT,
  },
});

export default styles;
