import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import Booking, { IProps } from './Booking.component';
import withNavigation from '../../../../../hoc/withNavigation';
import {
  getBookingTemplateOffer_pos_Pos,
  getBookingTemplateOffer_pos_Pos_bookingOfferTemplate_nextOrderableOffers,
} from '../../../../../types/bookingservice/getBookingTemplateOffer';
import { getOrder_orders_edges_node_Order as IOrder } from '../../../../../types/bookingservice/getOrder';
import moment from 'moment';

const getBookingTemplateOffer = loader(
  '../../../../../queries/bookingservice/getBookingTemplateOffer.gql'
);
const getUserInfo = loader('../../../../../queries/getUserInfo.gql');
const getBookingOrders = loader('../../../../../queries/bookingservice/getBookingOrders.gql');

interface IWithExtraProps {
  idPos: string;
  activeIndex: number;
  withdrawRange: any;
}

interface IWithError {
  hasError: boolean;
}

interface IWithOffer extends IWithError {
  isOrdersLoading?: boolean;
  nodes?: IOrder;
}

interface IWithIdGuest extends IWithError {
  idGuest?: string;
  isGuestLoading?: boolean;
}

interface IComponentProps {
  isLoading: boolean;
}

interface IWithIsLoading {
  isLoading: boolean;
}

export type IConnectedProps = IWithIdGuest & IWithOffer & IGraphQLProps & IWithIsLoading;

const withIsLoading = (props: IComponentProps & IConnectedProps): IWithIsLoading => ({
  isLoading:
    props.isLoading || props.isOrdersLoading || props.isGuestLoading || props.isOffersLoading,
});

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  idPos: ownProps.navigation.getParam('posId'),
  activeIndex: ownProps.navigation.getParam('activeIndex'),
  withdrawRange: ownProps.navigation.getParam('withdrawRange'),
});

export interface IGraphQLProps {
  hasError: boolean;
  isOffersLoading: boolean;
  offers: getBookingTemplateOffer_pos_Pos_bookingOfferTemplate_nextOrderableOffers[] | undefined;
  pos: getBookingTemplateOffer_pos_Pos | undefined;
}

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps),
  graphql(getBookingTemplateOffer, {
    options: (props: IWithExtraProps): QueryOpts => {
      return {
        variables: {
          idPos: props.idPos,
        },
        fetchPolicy: 'network-only',
      };
    },
    props: (props: OptionProps<IWithExtraProps>): IGraphQLProps => {
      const offers = get(props, 'data.pos.bookingOfferTemplate[0].nextOrderableOffers');
      const pos = get(props, 'data.pos');
      return {
        hasError: !!get(props, 'data.error'),
        isOffersLoading: get(props, 'data.loading'),
        pos: pos ? pos : undefined,
        offers: offers ? offers : [],
      };
    },
  }),
  graphql(getUserInfo, {
    props: (props: OptionProps<{}>): IWithIdGuest => ({
      hasError: !!get(props, 'data.error'),
    }),
  }),
  graphql(getBookingOrders, {
    options: (): QueryOpts => ({
      variables: {
        idGuest: '',
        fromDate: moment()
          .startOf('day')
          .toISOString(),
      },
      fetchPolicy: 'no-cache',
    }),
    props: (props: OptionProps<{}>): IWithOffer => ({
      hasError: !!get(props, 'data.error'),
      isOrdersLoading: get(props, 'data.loading'),
      nodes: get(props, 'data.active.edges'),
    }),
  }),
  withProps(withIsLoading)
)(Booking);
