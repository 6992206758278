import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { ImageURISource } from 'react-native';

import { BRAND } from '../../constants';
import { isDisneyHolding } from '../../services/holding';
import theme from '../../theme';
import { IBrand } from '../../types/common';

export type IFoodiLogoOrientation = 'horizontal' | 'vertical';

export const FOODI_LOGO_ORIENTATION: { [key in IFoodiLogoOrientation]: key } = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};

const isSupported = (brand: IBrand): boolean =>
  brand === BRAND.EUREST ||
  brand === BRAND.EXALT ||
  brand === BRAND.MEDIREST ||
  brand === BRAND.SCOLAREST;

export const selectIcon = (
  orientation: IFoodiLogoOrientation,
  brand?: IBrand,
  idHolding?: string
): ImageURISource => {
  let imageKey = '';

  if (brand && isSupported(brand) && !isDisneyHolding(idHolding)) {
    imageKey = `${FOODI_LOGO_ORIENTATION[orientation]}Logo${startCase(lowerCase(brand))}`;

    return theme.images[imageKey];
  }

  return theme.images[`${FOODI_LOGO_ORIENTATION[orientation]}Logo`];
};
