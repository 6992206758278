import { loader } from 'graphql.macro';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { graphql } from 'react-apollo/graphql';
import { DataProps, OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withHandlers } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { INode } from '../../types/common';
import { INearHoldingResult } from '../../types/nearHoldings';

import HoldingsList, { IProps } from './HoldingsList.component';

const nearHoldings = loader('../../queries/nearHoldings.gql');
const HOLDINGS_PAGE_SIZE = 20;

type IOnChangeText = (searchText: string) => void;

const withOnChangeTextHandler = (ownProps: IGraphQLProps): IOnChangeText =>
  debounce(
    async (searchText: string) =>
      // @ts-ignore
      ownProps.data.fetchMore({
        updateQuery: (
          previousResult: INearHoldingResult,
          { fetchMoreResult }: { fetchMoreResult?: INearHoldingResult }
        ): INearHoldingResult => fetchMoreResult || previousResult,
        variables: {
          searchText: `${searchText}%`,
        },
      }),
    500
  );

type IOnEndReached = (searchText: string) => void;

const withOnEndReachedHandler = (ownProps: IGraphQLProps): IOnEndReached =>
  throttle((searchText: string): void => {
    if (!ownProps.data.nearHoldings || !ownProps.data.nearHoldings.pageInfo.hasNextPage) {
      return;
    }

    // @ts-ignore
    ownProps.data.fetchMore({
      updateQuery: (
        previousResult: INearHoldingResult,
        { fetchMoreResult }: { fetchMoreResult?: INearHoldingResult }
      ): INearHoldingResult => {
        if (
          !fetchMoreResult ||
          previousResult.nearHoldings.edges.length > ownProps.nearHoldings.length
        ) {
          return previousResult;
        }

        return {
          ...previousResult,
          nearHoldings: {
            ...previousResult.nearHoldings,
            edges: [...previousResult.nearHoldings.edges, ...fetchMoreResult.nearHoldings.edges],
            pageInfo: fetchMoreResult.nearHoldings.pageInfo,
          },
        };
      },
      variables: {
        after: ownProps.data.nearHoldings.pageInfo.endCursor,
        first: HOLDINGS_PAGE_SIZE,
        searchText: `${searchText}%`,
      },
    });
  }, 500);

export interface IGraphQLProps extends DataProps<INearHoldingResult> {
  hasNextPage?: boolean;
  isLoading?: boolean;
  nearHoldings: Array<INode<INearHoldingResult>>;
}
export type IConnectedProps = NavigationInjectedProps &
  IGraphQLProps & {
    onChangeText: IOnChangeText;
    onEndReached: IOnEndReached;
  };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  graphql(nearHoldings, {
    options: (props: NavigationInjectedProps): QueryOpts => {
      const latitude = props.navigation.getParam('latitude');
      const longitude = props.navigation.getParam('longitude');

      return {
        fetchPolicy: 'network-only',
        variables: {
          first: HOLDINGS_PAGE_SIZE,
          latitude,
          longitude,
          order: !latitude || !longitude ? 'name' : undefined,
          searchText: '%',
          withDistance: !!(latitude && longitude),
        },
      };
    },
    props: (props: OptionProps<{}>): IGraphQLProps => ({
      data: props.data!,
      hasNextPage: get(props, 'data.nearHoldings.pageInfo.hasNextPage'),
      isLoading: get(props, 'data.loading'),
      nearHoldings: get(props, 'data.nearHoldings.edges') || [],
    }),
  }),
  withHandlers({ onChangeText: withOnChangeTextHandler, onEndReached: withOnEndReachedHandler })
)(HoldingsList);
