import { push } from 'connected-react-router';
import queryString from 'query-string';
import { NavigationParams } from 'react-navigation';

import { getConfig } from '../../environment';
import store from '../../redux/store';
import { Tracker } from '../analytics';

export const setTopLevelNavigator = (): void => {
  return;
};

export const isValidUrl = (urlToCheck: string): boolean => {
  const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

  return regex.test(urlToCheck);
};

const getExternalLink = (mobileRouteName: string): string | undefined => {
  const env = getConfig();
  switch (mobileRouteName) {
    case 'help':
      return `${env.ZENDESK.BASE_URL}`;
    case 'personalData':
      return env.PERSONAL_DATA_URL;
    case 'termsOfService':
    case 'modalTermsOfService':
      return env.TOS_URL;
    case 'termsOfSales':
      return env.GCOS_URL;
    case 'cookiesPolicy':
      return env.CP_URL;
    default:
      return undefined;
  }
};

const getParam = (params?: NavigationParams, paramName?: string): string => {
  if (!params || !paramName) {
    return '';
  }

  return params[paramName] || '';
};

interface IQueryParams {
  [key: string]: string;
}

const getQueryParams = (params?: NavigationParams, ...paramNames: string[]): string => {
  if (!params) {
    return '';
  }

  const queryParams = paramNames.reduce(
    (queryParamsAccumulator: IQueryParams, paramName: string): IQueryParams => {
      if (!paramName) {
        return queryParamsAccumulator;
      }

      return {
        ...queryParamsAccumulator,
        [paramName]: params[paramName],
      };
    },
    {}
  );

  return queryString.stringify(queryParams);
};

export const getWebRouteName = (mobileRouteName: string, params?: NavigationParams): string => {
  switch (mobileRouteName) {
    case 'auth':
    case 'authStack':
    case 'landing':
      const queryParameters = getQueryParams(params, 'signInRedirection');
      const signUpParams = queryParameters
        ? (queryString.parse(queryParameters).signInRedirection as string).split('?')[1]
        : '';

      return queryParameters
        ? `/landing?${queryParameters}${signUpParams ? `&${signUpParams}` : ''}`
        : `/landing`;
    case 'dashboard':
    case 'dashboardStack':
    case 'home':
      return '/home';
    case 'marketingCard': {
      const marketingCardId = getParam(params, 'marketingCardId');

      return `/home/marketing-card/${marketingCardId}`;
    }
    case 'eat':
      const queryEatParams = getQueryParams(params, 'tab');

      return queryEatParams ? `/restaurants?${queryEatParams}` : '/restaurants';
    case 'offerArticles': {
      const offerId = getParam(params, 'offerId');
      return `/restaurants/offers/${offerId}`;
    }
    case 'offerBundleStep': {
      const offerId = getParam(params, 'offerId');
      const queryParams = getQueryParams(params, 'stepIndex');
      return `/restaurants/offers/${offerId}?${queryParams}`;
    }
    case 'cartSummary': {
      const orderId = getParam(params, 'orderId');
      return `/restaurants/cartSummary/${orderId}`;
    }
    case 'tableService': {
      const offerId = getParam(params, 'offerId');
      const tableNumber = getParam(params, 'tableNumber');
      return `/table-service/${offerId}/tables?tableNumber=${tableNumber}`;
    }
    case 'tableServiceTableNumberConfirm': {
      const offerId = getParam(params, 'offerId');
      const tableId = getParam(params, 'tableId');
      return `/table-service/${offerId}/tables/${tableId}`;
    }
    case 'tableServiceBeverages': {
      const offerId = getParam(params, 'offerId');
      const idTable = getParam(params, 'idTable');
      return `/table-service/${offerId}/tables/${idTable}/beverages`;
    }
    case 'tableServiceOfferArticles': {
      const offerId = getParam(params, 'offerId');
      const idTable = getParam(params, 'idTable');
      return `/table-service/${offerId}/tables/${idTable}/offer-articles`;
    }
    case 'tableServiceCartSummary': {
      const orderId = getParam(params, 'orderId');
      return `/table-service/cart/${orderId}`;
    }
    case 'order': {
      const orderId = getParam(params, 'orderId');
      const queryParams = getQueryParams(params, 'offerId');
      return `/restaurants/orders/${orderId}?${queryParams}`;
    }
    case 'menuElementDetails': {
      const posId = getParam(params, 'posId');
      const elementId = getParam(params, 'elementId');

      return `/restaurants/${posId}/menu/${elementId}`;
    }
    case 'menu': {
      const posId = getParam(params, 'posId');
      const queryParams = getQueryParams(params, 'activeDayIndex');

      return `/restaurants/${posId}?${queryParams}`;
    }
    case 'contentPage': {
      const posId = getParam(params, 'posId');

      return `/restaurants/${posId}/contentPage/`;
    }
    case 'balance':
      return '/balance';
    case 'transactionDetails': {
      const transactionId = getParam(params, 'transactionId');

      return `/balance/${transactionId}`;
    }
    case 'profile':
      return '/profile';
    case 'myInformations':
      return '/profile/my-informations';
    case 'notifications':
      return `/profile/notifications`;
    case 'languages':
      return `/profile/languages`;
    case 'virtualTicket':
      return `/profile/virtual-ticket`;
    case 'legalInfo':
      return `/profile/legal-info`;
    case 'signUpGeolocation': {
      const queryParams = getQueryParams(
        params,
        'holding',
        'badgeNumber',
        'serialNumber',
        'lastName',
        'email'
      );
      return `/create-account/geolocation?${queryParams}`;
    }
    case 'signUpNearHoldings': {
      const queryParams = getQueryParams(
        params,
        'latitude',
        'longitude',
        'holding',
        'badgeNumber',
        'serialNumber',
        'lastName',
        'email'
      );

      return `/create-account/holdings?${queryParams}`;
    }
    case 'signUpIdentity': {
      const holdingId = getParam(params, 'holdingId');
      const queryParams = getQueryParams(
        params,
        'latitude',
        'longitude',
        'badgeNumber',
        'serialNumber',
        'lastName',
        'email'
      );

      return `/create-account/${holdingId}/guest-credentials?${queryParams}`;
    }
    case 'signUpGuestExist': {
      const holdingId = getParam(params, 'holdingId');
      const queryParams = getQueryParams(params, 'guestEmail', 'email');

      return `/create-account/${holdingId}/existing-guest?${queryParams}`;
    }
    case 'signUpEmail': {
      const holdingId = getParam(params, 'holdingId');
      const queryParams = getQueryParams(params, 'email');
      return `/create-account/${holdingId}/user-login?${queryParams}`;
    }
    case 'signUpPassword': {
      const holdingId = getParam(params, 'holdingId');

      return `/create-account/${holdingId}/user-password`;
    }
    case 'signUpConfirmation':
      return '/create-account/email-to-validate';
    case 'signUpEmailNotActivated':
      const token = getParam(params, 'token');

      return `/create-account/email-not-activated/${token}`;
    case 'signUpCloseHolding': {
      const queryParams = getQueryParams(params, 'holdingId');

      return `/create-account/holding-not-available?${queryParams}`;
    }
    case 'signUpWelcome':
      return `/create-account/account-activated`;
    default:
      return mobileRouteName;
  }
};

const navigate = (routeName: string, params?: NavigationParams): void => {
  if (routeName && isValidUrl(routeName)) {
    // @ts-ignore
    return window.open(routeName, '_blank');
  }

  const externalLink = getExternalLink(routeName);

  if (externalLink) {
    // @ts-ignore
    window.open(externalLink, '_blank');
    Tracker.trackNavigation({ pathname: routeName });
  } else {
    store.dispatch(push(getWebRouteName(routeName, params)));
  }
};

export const getRoute = (): string | undefined =>
  // @ts-ignore
  document.location.pathname + document.location.search;

export default { getRoute, navigate };
