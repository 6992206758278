import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IElementQuantity } from '../../redux/clickAndCollect/actions';
import { getElementsQuantityByOfferId } from '../../redux/clickAndCollect/selectors';
import { IAppState } from '../../redux/reducer';
import DetailedProductRow, { IProps } from './DetailedProductRow.component';

export interface IMapStateToProps {
  elementsQuantity: IElementQuantity[] | null;
}

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => ({
  elementsQuantity: getElementsQuantityByOfferId(state, ownProps.offer.id),
});

export type IConnectedProps = IMapStateToProps;

export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(connect(mapStateToProps))(DetailedProductRow);
