import React, { PureComponent, ReactNode } from 'react';
import { ViewStyle } from 'react-native';

import GuestInfo from '../GuestInfo';
import LoadingCard from '../LoadingCard';
import ProfileFooter from '../ProfileFooter';
import ProfileSections from '../ProfileSections';

import styles from './styles';

export interface IProps {
  firstName?: string;
  holdingName?: string;
  isLoading: boolean;
  lastName?: string;
  testID?: string;
}

class ProfileCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { firstName, holdingName, isLoading, lastName, testID } = this.props;

    return (
      <LoadingCard isLoading={isLoading} noPadding big style={styles.card}>
        <GuestInfo firstName={firstName} holdingName={holdingName} lastName={lastName} />
        <ProfileSections testID={`${testID}_profileSections`} />
        <ProfileFooter testID={`${testID}_profileFooter`} />
      </LoadingCard>
    );
  }
}

export interface IStyle {
  card: ViewStyle;
}

export default ProfileCard;
