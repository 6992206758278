import { NavigationInjectedProps } from 'react-navigation';
import { branch, compose, withProps } from 'recompose';

import withMenuElementDetails from '../../../../hoc/withMenuElementDetails';
import withHoldingSetting, {
  IWithIdHoldingProps,
  IWithImportationTypeProps
} from '../../../../hoc/withHoldingSetting';
import {
  IGraphQLProps,
  IWithIdMenuElementProps,
} from '../../../../hoc/withMenuElementDetails/withMenuElementDetails';

import withNavigation from '../../../../hoc/withNavigation';

import MenuElementDetailsContent, { IProps } from './MenuElementDetailsContent.component';

const WithElementMenuIdProps = (props: NavigationInjectedProps): IWithIdMenuElementProps => {
 return ({
    idMenuElement: props.navigation.getParam('elementId'),
  })
};

const WithHoldingSettingIdIdProps = (props: NavigationInjectedProps): IWithIdHoldingProps => ({
  idHoldingSetting: props.navigation.getParam('holdingSettingId'),
});

const WithImportationTypeProps = (props: NavigationInjectedProps): IWithImportationTypeProps => ({
  importationType: props.navigation.getParam('importationType'),
});

export type IConnectedProps = NavigationInjectedProps & IWithIdMenuElementProps & IWithIdHoldingProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;


export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(WithElementMenuIdProps),
  withProps(WithHoldingSettingIdIdProps),
  withProps(WithImportationTypeProps),
  withMenuElementDetails,
  branch(
    (props: IWithIdHoldingProps) => !!props.idHoldingSetting,
    compose(withHoldingSetting),
  )
)(MenuElementDetailsContent);
