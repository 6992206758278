import React, { useState, useEffect, ReactNode } from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  ImageURISource,
  PixelRatio,
  StyleSheet,
} from 'react-native';
import FastImage from 'react-native-fast-image';
import ProgressiveImage from '../ProgressiveImage';
import { IElementQuantity } from '../../redux/clickAndCollect/actions';
import { getTableServiceOffer_offer_Offer as ITableOffer } from '../../types/tableService/getTableServiceOffer';
import {
  getOffer_offer_Offer as IOffer,
  getOffer_offer_Offer_offerItems as IOfferItem,
  getOffer_offer_Offer_offerItems_container as IContainer,
} from '../../types/clickandcollect/getOffer';
import { ArticleBaking } from '../../types/tableService/globalTypes';
import { OfferTemplateType } from '../../types/clickandcollect/globalTypes';
import { getCloudimageUrl } from '../../services/cloudimage';
import I18n from '../../lib/i18n';
import theme from '../../theme';
import { fontMaker, FONT_FAMILIES, FONT_WEIGHTS } from '../../theme/fonts';
import Tag from '../Tag';
import OfferElementFooter from '../OfferElementFooter';
import { isWeb } from '../../lib/responsive';

const MIN_VALUE = 0;
const BODY_CARD_HEIGHT = 100;

const TAG_SIZE_WITHOUT_TEXT = 18;
const CHARACTER_SIZE = 9;
const MARGIN_RIGHT = theme.margins.cardUnit;

const SectionLabelAndDescription = ({
  inheritedLabel = '',
  inheritedDescription = '',
  container,
  handleChange,
  inheritedImage
}: {
  inheritedLabel: string | null;
  inheritedDescription: string | null;
  inheritedImage: string | null;
  container: IContainer | null;
  handleChange: HandleChange;
}) => {
  const withImageStyle = {
    minWidth: container ? '83%' : '85%',
    maxWidth: container ? '83%' : '85%',
  };
  const withoutImageStyle = {
    minWidth: container ? '87%' : '100%',
    maxWidth: container ? '87%' : '100%',
  };

  const handleNumberOfLines = inheritedDescription ? 2 : 3;
  const Consigne = theme.images.consigne;

  return (
    <View style={[styles.textContainer, !!inheritedImage ? withImageStyle : withoutImageStyle]}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.title} numberOfLines={handleNumberOfLines} ellipsizeMode="tail">
          {inheritedLabel}
        </Text>
        {container ? (
          <Text style={styles.containerIcon}>
            <>
              {' + '}
              <Consigne style={{ alignSelf: 'center', marginBottom: -2 }} />
            </>
          </Text>
        ) : (
          <></>
        )}
      </View>
      <Text style={styles.description} numberOfLines={2} ellipsizeMode="tail">
        {inheritedDescription}
      </Text>

      {/* [Section] */}
      {/*This section is needed since we need to know how many lines the text has if the ellipsizeMode is not being used.
       We use this information to enable the navigation to another screen */}

      <Text
        style={[styles.title, { color: 'transparent', position: 'absolute' }]}
      >
        {inheritedLabel}
      </Text>

      <Text
        style={[styles.description, { color: 'transparent', position: 'absolute' }]}
      >
        {inheritedDescription}
      </Text>
      {/* [/Section] */}
    </View>
  );
};

const SectionImage = ({ inheritedImage }: { inheritedImage: string | null }) => {
  const thumbnailSource: ImageURISource = {};
  const imageSource: ImageURISource = {};
  const multiplier = isWeb() ? 2 : 1;

  if (!inheritedImage) {
    return null;
  }

  thumbnailSource.uri = getCloudimageUrl(
    inheritedImage,
    'height',
    String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_HEIGHT * multiplier))
  );
  imageSource.uri = getCloudimageUrl(
    inheritedImage,
    'height',
    String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_HEIGHT * multiplier))
  );
  return isWeb() ? (
    <ProgressiveImage
      source={imageSource}
      thumbnailSource={thumbnailSource}
      style={styles.image}
      resizeMode="cover"
    />
  ) : (
    <FastImage
      style={styles.image}
      source={{
        uri: imageSource.uri,
        priority: FastImage.priority.normal,
      }}
      resizeMode={FastImage.resizeMode.cover}
    />
  );
};

const SectionTags = ({
  item,
  elementsQuantity,
}: {
  item: IOfferItem;
  elementsQuantity: IElementQuantity[];
}) => {
  const chosenBaking = elementsQuantity
    ? elementsQuantity.reduce((baking, element) => {
        if (element.elementId === item.id && element.chosenBaking) {
          baking.push(element.chosenBaking);
        }
        return baking;
      }, [] as ArticleBaking[])
    : [];

  const [availableWidthForTags, setAvailableWidthForTags] = useState(0);

  let quantityBakingCharacters = 0;
  let quantityBakingTags = 0;
  let totalWidthBakingTags = 0;

  let quantityCertificationCharacters = 0;
  let quantityCertificationTags = 0;
  let totalWidthCertificationTags = 0;

  let quantityArticleCharacters = 0;
  let quantityArticleTags = 0;
  let totalWidthArticleTags = 0;

  let showEllipsesCounter = 0;

  // Re-ordering the list to show the smallest tags first, then alphabetically and display more tags
  item.articleTags.sort((a, b) =>
    a.label.length > b.label.length ? 1 : -1 && a.label.localeCompare(b.label)
  );
  item.articleCertifications.sort((a, b) =>
    a.label.length > b.label.length ? 1 : -1 && a.label.localeCompare(b.label)
  );

  const calculateWidth = ({
    quantityCharacterLabel,
    quantityCharacters,
    quantityTags,
  }: {
    quantityCharacterLabel: number;
    quantityCharacters: number;
    quantityTags: number;
  }) => {
    const _quantityCharacters = quantityCharacters + quantityCharacterLabel;
    const _totalWidthTags = Math.round(
      quantityTags * TAG_SIZE_WITHOUT_TEXT +
        quantityTags * MARGIN_RIGHT +
        _quantityCharacters * CHARACTER_SIZE
    );

    return [_quantityCharacters, _totalWidthTags];
  };

  const sumWidth = (
    _totalWidthBakingTags: number,
    _totalWidthCertificationTags: number,
    _totalWidthArticleTags: number
  ) => {
    return _totalWidthBakingTags + _totalWidthCertificationTags + _totalWidthArticleTags;
  };

  return (
    <View
      style={styles.tagRow}
      onLayout={evt => {
        setAvailableWidthForTags(
          Math.round(Number(evt.nativeEvent.layout.width) - 3 * CHARACTER_SIZE)
        );
      }}
    >
      {availableWidthForTags > 0 &&
        sumWidth(totalWidthBakingTags, totalWidthCertificationTags, totalWidthArticleTags) <=
          availableWidthForTags &&
        chosenBaking.map(baking => {
          [quantityBakingCharacters, totalWidthBakingTags] = calculateWidth({
            quantityCharacterLabel: I18n.t(`dashboard.eat.tableService.ArticleBaking.${baking}`)
              .length,
            quantityCharacters: quantityBakingCharacters,
            quantityTags: quantityBakingTags + 1,
          });

          const total = sumWidth(
            totalWidthBakingTags,
            totalWidthCertificationTags,
            totalWidthArticleTags
          );

          if (total > availableWidthForTags) {
            ++showEllipsesCounter;
          }

          return total <= availableWidthForTags ? (
            <Tag
              key={baking}
              label={I18n.t(`dashboard.eat.tableService.ArticleBaking.${baking}`)}
              style={styles.bakingTag}
              tagStyle={styles.bakingTagText}
            />
          ) : showEllipsesCounter === 1 ? (
            <Text style={styles.description}>...</Text>
          ) : null;
        })}

      {availableWidthForTags > 0 &&
        sumWidth(totalWidthBakingTags, totalWidthCertificationTags, totalWidthArticleTags) <=
          availableWidthForTags &&
        item.articleCertifications.map(certification => {
          [quantityCertificationCharacters, totalWidthCertificationTags] = calculateWidth({
            quantityCharacterLabel: certification.label.length,
            quantityCharacters: quantityCertificationCharacters,
            quantityTags: quantityCertificationTags + 1,
          });

          const total = sumWidth(
            totalWidthBakingTags,
            totalWidthCertificationTags,
            totalWidthArticleTags
          );

          if (total > availableWidthForTags) {
            ++showEllipsesCounter;
          }

          return total <= availableWidthForTags ? (
            <Tag
              label={certification.label}
              style={styles.bakingTag}
              tagStyle={styles.bakingTagText}
            />
          ) : showEllipsesCounter === 1 ? (
            <Text style={styles.description}>...</Text>
          ) : null;
        })}

      {availableWidthForTags > 0 &&
        sumWidth(totalWidthBakingTags, totalWidthCertificationTags, totalWidthArticleTags) <=
          availableWidthForTags &&
        item.articleTags.map(tag => {
          [quantityArticleCharacters, totalWidthArticleTags] = calculateWidth({
            quantityCharacterLabel: tag.label.length,
            quantityCharacters: quantityArticleCharacters,
            quantityTags: quantityArticleTags + 1,
          });

          const total = sumWidth(
            totalWidthBakingTags,
            totalWidthCertificationTags,
            totalWidthArticleTags
          );

          if (total > availableWidthForTags) {
            showEllipsesCounter++;
          }

          return total <= availableWidthForTags ? (
            <Tag label={tag.label} style={styles.bakingTag} tagStyle={styles.bakingTagText} />
          ) : showEllipsesCounter === 1 ? (
            <Text style={styles.description}>...</Text>
          ) : null;
        })}
    </View>
  );
};

const SectionTableServiceTags = ({
  offer,
  item,
}: {
  offer: IOffer | ITableOffer;
  item: IOfferItem;
}) => {
  const { articleTags, articleCertifications } = item;
  const {
    offerTemplate: { withdrawalType },
  } = offer;
  return withdrawalType === OfferTemplateType.TABLE_SERVICE ? (
    <>
      <View style={styles.tagRow}>
        {articleTags.map(tag => (
          <Tag key={tag.numericId} label={tag.label} style={styles.tag} tagStyle={styles.tagText} />
        ))}
      </View>
      <View style={styles.tagRow}>
        {articleCertifications.map(certification => (
          <Tag
            key={certification.numericId}
            label={certification.label}
            style={styles.tag}
            tagStyle={styles.tagText}
          />
        ))}
      </View>
    </>
  ) : null;
};

const SectionFooter = ({
  item,
  offer,
  sizeDivider,
  value,
  remainingQuantity,
  hidePrice,
  testID,
}: {
  item: IOfferItem;
  offer: IOffer | ITableOffer;
  showQuantityInFooter?: boolean;
  sizeDivider: number;
  value: number;
  remainingQuantity?: number;
  hidePrice?: boolean;
  testID?: string;
}) => {
  return (
    <View style={styles.footerWrapper}>
      <OfferElementFooter
        testID={testID}
        article={item}
        offer={offer}
        quantityRenderer={quantityRenderer}
        sizeDivider={sizeDivider}
        remainingQuantity={remainingQuantity}
        hidePrice={hidePrice}
        quantity={value}
        amountAlternativeFormat
        amountStyle={styles.amountStyle}
        noPadding
      />
    </View>
  );
};

const quantityRenderer = (value: number): ReactNode => {
  return value ? <Text style={styles.amountStyle}>{value}</Text> : undefined;
};

export interface IProps {
  item: IOfferItem;
  showQuantityInFooter?: boolean;
  offer: IOffer | ITableOffer;
  sizeDivider: number;
  elementsQuantity: IElementQuantity[];
  remainingQuantity?: number;
  hidePrice?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  testID?: string;
}
const DetailedProductRow = (props: IProps) => {
  const { offer, item, elementsQuantity, disabled = true, onPress = () => {}, testID } = props;
  const [quantityHasChanged, setQuantityHasChanged] = useState(false);
  const noImageStyle = { minHeight: 50 };
  const value = elementsQuantity
    ? elementsQuantity.reduce((quantity, element) => {
        if (element.elementId === item.id) {
          quantity += element.quantity;
        }
        return quantity;
      }, 0)
    : MIN_VALUE;

  useEffect(() => {
    checkIfQuantityHasChanged();
  }, [quantityHasChanged, value]);

  const checkIfQuantityHasChanged = () => {
    if (value >= 1 && !quantityHasChanged) {
      setQuantityHasChanged(true);
    }
  };

  const checkItemStock = () => {
    return (
      value > 0 ||
      (quantityHasChanged && value <= 0) ||
      (!!item.quantityPurchasable && item.quantityPurchasable > 0)
    );
  };

  const [isLabelOrDescriptionLong, setIsLabelOrDescriptionLong] = useState(true);
  const [areTagsSectionLong, setAreTagsSectionLong] = useState(false);

  const isAllowedToNavigateToProductDetails = true;

  return (
    checkItemStock() && (
      <TouchableWithoutFeedback
        testID={testID}
        disabled={disabled}
        onPress={isAllowedToNavigateToProductDetails ? onPress : undefined}
      >
        <View style={styles.rowWrapper}>
          <View style={[styles.container, !item.inheritedImage && noImageStyle]}>
            <View style={styles.infoContainer}>
              <SectionLabelAndDescription {...item} handleChange={setIsLabelOrDescriptionLong} />
              <SectionTags
                item={item}
                elementsQuantity={elementsQuantity}
                handleChange={setAreTagsSectionLong}
              />
            </View>
            {item.inheritedImage && (
              <View style={styles.imageContainer}>
                <SectionImage inheritedImage={item.inheritedImage} />
              </View>
            )}
          </View>
          <SectionTableServiceTags item={item} offer={offer} />
          <SectionFooter {...props} value={value} />
        </View>
      </TouchableWithoutFeedback>
    )
  );
};

const styles = StyleSheet.create({
  rowWrapper: {
    paddingHorizontal: theme.margins.cardUnit / 2,
  },
  container: {
    flexDirection: 'row',
    paddingVertical: theme.margins.cardUnit,
    justifyContent: 'space-between',
    minHeight: 100,
  },
  infoContainer: {
    padding: 0,
    margin: 0,
    flex: 1,
  },
  imageContainer: {
    padding: 0,
    margin: 0,
    flex: 0.3,
  },
  textContainer: {
    padding: 0,
    margin: 0,
  },
  title: {
    ...theme.fonts.mediumTitle,
    fontSize: 16,
    fontWeight: '600',
    color: theme.colors.black,
    letterSpacing: 0,
  },
  description: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.grayMedium,
    fontWeight: '400',
    fontSize: 14,
    letterSpacing: 0,
  },
  image: {
    position: 'absolute',
    right: 0,
    borderRadius: 5,
    height: 80,
    width: 80,
  },
  tagRow: {
    flex: 1,
    maxHeight: 23,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginVertical: theme.margins.cardUnit,
    marginRight: theme.margins.cardUnit,
    overflow: 'hidden',
  },
  tag: {
    backgroundColor: theme.colors.blueGray,
    borderRadius: 20,
    height: 23,
  },
  tagText: {
    ...theme.fonts.cardText,
    fontSize: 14,
    fontWeight: '300',
    marginLeft: 0,
  },
  bakingTag: {
    backgroundColor: theme.colors.blueGray,
    borderRadius: 20,
    height: 23,
  },
  bakingTagText: {
    ...theme.fonts.cardText,
    fontWeight: '300',
    marginLeft: 0,
  },
  amountStyle: {
    ...theme.fonts.mediumTitle,
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 16,
    color: theme.colors.black,
    letterSpacing: 0,
  },
  footerWrapper: {
    marginTop: theme.margins.cardUnit,
    marginBottom: theme.margins.padding,
  },
  footerQuantity: {
    ...theme.fonts.littleTitle,
    color: theme.colors.black,
    textAlign: 'center',
    paddingHorizontal: 2,
  },
  containerIcon: {
    alignSelf: 'flex-end',
    marginBottom: 2,
  },
});

export default DetailedProductRow;
