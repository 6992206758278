import { StyleSheet } from 'react-native';

import theme from '../../../theme';

import { IPlatformStyle } from './BakingModalContent.component';

export const styles = StyleSheet.create<IPlatformStyle>({
  title: {
    ...theme.fonts.bigTitle,
  },
});
