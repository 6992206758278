import { ITicket, ITicketElement } from '../../types/getTicket';

export const getTrayTotal = (ticket: ITicket): number => {
  const trayTotal = ticket.elements.reduce(
    (total: number, ticketElement: ITicketElement) => total + Number(ticketElement.total.amount),
    0
  );

  return !Number(ticket.foodstuff.subsidy)
    ? trayTotal
    : trayTotal + Number(ticket.foodstuff.subsidy);
};
