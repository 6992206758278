import { NUTRITIONAL_KEYS } from '../constants/nutritionalUnits';
import I18n from '../lib/i18n';
import { INutritionalCompositionElement, INutritionCompositionType, IUnit } from '../types/common';

const DEFAULT_NUTRITIONAL_DECIMAL_VALUE = 10;

const NUTRITIONAL_MAPPER: { [key in INutritionCompositionType]: { key: string } } = {
  CALCIUM: {
    key: 'dashboard.menuElementDetails.calcium',
  },
  CARBOHYDRATE: {
    key: 'dashboard.menuElementDetails.carbohydrate',
  },
  ENERGY_VALUE: {
    key: 'dashboard.menuElementDetails.energyValue',
  },
  INCLUDING_SATURATED: {
    key: 'dashboard.menuElementDetails.includingSaturatedAcids',
  },
  INCLUDING_SUGAR: {
    key: 'dashboard.menuElementDetails.includingSugar',
  },
  LIPID: {
    key: 'dashboard.menuElementDetails.lipid',
  },
  PROTEIN: {
    key: 'dashboard.menuElementDetails.protein',
  },
  SALT: {
    key: 'dashboard.menuElementDetails.salt',
  },
};

const NUTRITIONAL_UNIT_MAPPER: { [key in IUnit]: { key: string } } = {
  GRAMS: {
    key: 'dashboard.menuElementDetails.grams',
  },
  KILOCALORIES: {
    key: 'dashboard.menuElementDetails.kilocalories',
  },
  MILLIGRAMS: {
    key: 'dashboard.menuElementDetails.milligrams',
  },
};

export const getNutritionalElementKeyWithUnit = (
  nutritionalCode: INutritionCompositionType
): { title: string } => {
  return {
    title: I18n.t(NUTRITIONAL_MAPPER[nutritionalCode].key),
  };
};

export const getNutritionalUnit = (nutritionalUnitCode: IUnit): string => {
  return nutritionalUnitCode && I18n.t(NUTRITIONAL_UNIT_MAPPER[nutritionalUnitCode].key);
};

export const getNutritionalProportion = (
  nutritionalComposition: INutritionalCompositionElement
): number => {
  const proportion = nutritionalComposition.value
    ? roundNutritionalValues(nutritionalComposition.label, nutritionalComposition.value) /
      nutritionalComposition.maxValue
    : 0;

  if (proportion && proportion > 1) {
    return 1;
  }

  return proportion && proportion <= 1 && proportion >= 0 ? proportion : 0;
};

export const roundNutritionalValues = (
  nutritionalElement: INutritionCompositionType,
  nutritionalValue: number
): number => {
  return nutritionalElement === NUTRITIONAL_KEYS.energyValue
    ? Math.round(nutritionalValue)
    : Math.floor(nutritionalValue * DEFAULT_NUTRITIONAL_DECIMAL_VALUE) /
        DEFAULT_NUTRITIONAL_DECIMAL_VALUE;
};
