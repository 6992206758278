import get from 'lodash/get';
import { compose, withProps } from 'recompose';

import { IAmount } from '../../types/common';
import { ITicket } from '../../types/getTicket';

import TrayPricesCard, { IProps } from './TrayPricesCard.component';
import { getTrayTotal } from './utils';

interface IWithTotalProps {
  total: IAmount;
}

const withTotalProps = (ownProps: IExternalProps): IWithTotalProps => ({
  total: {
    amount: ownProps.ticket ? getTrayTotal(ownProps.ticket).toString() : '0',
    currency: get(ownProps.ticket, 'total.currency'),
  },
});

export interface IExternalProps {
  ticket?: ITicket;
}
export type IConnectedProps = IWithTotalProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withProps(withTotalProps))(TrayPricesCard);
