import { Formik } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { FetchResult } from 'react-apollo';
import { NavigationInjectedProps } from 'react-navigation';
import { ActionCreator } from 'redux';

import { OPTINS_ALLERGEN_CODE } from '../../constants';
import { IWithElementDetailsParams } from '../../hoc/withMenuElementDetailsNavigation/withMenuElementDetailsNavigation.d';
import { IToggleRecipeDisclaimerAction } from '../../redux/menu/actions';
import { IOptinAllergenCode } from '../../types/common';

import InfoRecipeDisclaimerFormikForm from './InfoRecipeDisclaimerFormikForm';

export interface IProps extends NavigationInjectedProps {
  elementId?: string;
  navigateToMenuElementDetails: (params: IWithElementDetailsParams) => boolean;
  posId?: string;
  toggleRecipeDisclaimer: ActionCreator<IToggleRecipeDisclaimerAction>;
  updateOptin: (optinName: IOptinAllergenCode, value: boolean) => Promise<FetchResult>;
}

class InfoRecipeDisclaimerForm extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        initialValues={{
          recipeDisclaimer: false,
        }}
        component={InfoRecipeDisclaimerFormikForm}
      />
    );
  }

  private handleSubmit = async (values: { recipeDisclaimer: boolean }): Promise<void> => {
    await this.props.updateOptin(
      OPTINS_ALLERGEN_CODE.FOODI_ALLERGEN_DISCLAIMER,
      values.recipeDisclaimer
    );

    this.props.toggleRecipeDisclaimer({ showRecipeDisclaimer: false });

    const posId = this.props.posId || this.props.navigation.getParam('posId');
    const elementId = this.props.elementId || this.props.navigation.getParam('elementId');

    this.props.navigateToMenuElementDetails({ posId, elementId });
  };
}

export default InfoRecipeDisclaimerForm;
