import React, { PureComponent, ReactNode } from 'react';

import AppPage from '../../../../components/AppPage';

import AllergensDisclaimerContent from './AllergensDisclaimerContent/';

class AllergensDisclaimer extends PureComponent {
  public render(): ReactNode {
    return (
      <AppPage>
        <AllergensDisclaimerContent />
        {this.props.children}
      </AppPage>
    );
  }
}

export default AllergensDisclaimer;
