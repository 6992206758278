import React, { PureComponent, ReactNode } from 'react';
import { GeolocationReturnType, Image, View } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import Button from '../../../../src/components/Button';
import Paragraph from '../../../../src/components/Paragraph';
import I18n from '../../../../src/lib/i18n';
import { idToUuid, Entity } from '../../../../src/services/base64Id';
import getCurrentPositionWithPermissionCheck from '../../../../src/services/geolocation';
import theme from '../../../../src/theme';

import SignupLayout from '../../components/SignupLayout';

interface IState {
  isLoading: boolean;
}

class GeolocationPage extends PureComponent<NavigationInjectedProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  public componentWillMount(): void {
    const holdingId = this.props.navigation.getParam('holding');
    if (holdingId) {
      this.props.navigation.navigate('signUpIdentity', {
        holdingId: idToUuid(holdingId, Entity.HOLDING),
      });
    }
  }

  public render(): ReactNode {
    return (
      <SignupLayout
        title={I18n.t('signUp.headerTitle')}
        subtitle={I18n.t('signUp.geolocation.title')}
        currentStepNumber={1}
        currentStepStatus="ACTIVE"
      >
        <View style={styles.geolocationContainerStyle}>
          <Image source={theme.images.geolocation} style={styles.geolocationIconStyle} />
        </View>
        <Paragraph text={I18n.t('signUp.geolocation.paragraph')} />
        <Button
          text={I18n.t('signUp.geolocation.findHolding')}
          onPress={this.handleFindHoldingsPress}
          style={styles.button}
          isLoading={this.state.isLoading}
        />
      </SignupLayout>
    );
  }

  private handleFindHoldingsPress = async (): Promise<void> => {
    const onSuccess = (location: GeolocationReturnType): void => {
      const { latitude, longitude } = location.coords;
      this.setState({ isLoading: false });
      this.props.navigation.navigate('signUpNearHoldings', { latitude, longitude });
    };

    const onFail = (): void => {
      this.setState({ isLoading: false });
      this.props.navigation.navigate('signUpNearHoldings');
    };

    this.setState({ isLoading: true });
    await getCurrentPositionWithPermissionCheck(onSuccess, onFail);
  };
}

const styles = {
  button: {
    marginTop: '26px',
  },
  geolocationContainerStyle: {
    alignItems: 'center',
    height: '30%',
    marginBottom: '117px',
    marginTop: '68px',
  },
  geolocationIconStyle: {
    height: '121px',
    width: '482px',
  },

  // tslint:disable-next-line:no-any
} as any;

export default GeolocationPage;
