import { NavigationInjectedProps, withNavigation } from 'react-navigation';
import { compose, withProps } from 'recompose';
import { IMarketingCard } from '../../../../../../types/common';
import MarketingCardArticle, { IProps } from './MarketingCardArticle.component';

interface IWithExtraProps {
  marketingCard: IMarketingCard;
}

const withExtraProps = (ownProps: NavigationInjectedProps): IWithExtraProps => ({
  marketingCard: ownProps.navigation.getParam('marketingCard'),
});

export default compose<IProps, NavigationInjectedProps>(
  withNavigation,
  withProps(withExtraProps)
)(MarketingCardArticle);
