import React, { PureComponent, ReactNode } from 'react';

import AlertOpeningForm from '../../../components/AlertOpeningForm';
import AppPage from '../../../components/AppPage';
import BottomForm from '../../../components/BottomForm';
import Stepper from '../../../components/Stepper';
import { STATUS } from '../../../components/Stepper';
import Title from '../../../components/Title';
import I18n from '../../../lib/i18n';

class CloseHolding extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <AppPage hasBlackHeader>
        <BottomForm>
          <Stepper currentStepNumber={1} currentStepStatus={STATUS.ERROR} />
          <Title text={I18n.t('signUp.closeHolding.title')} />
          <AlertOpeningForm />
        </BottomForm>
      </AppPage>
    );
  }
}

export default CloseHolding;
