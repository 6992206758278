import { loader } from 'graphql.macro';
import get from 'lodash/get';
import React from 'react';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import { compose } from 'recompose';

import { IUser } from '../../types/getUserInfo';

const getUserInfo = loader('../../queries/getUserInfo.gql');

export interface IGraphQLProps {
  getUser: IUser;
  hasError: boolean;
  isUserInfosLoading: boolean;
}

export type IComposeProps = IGraphQLProps;
export type IHocProps<T> = Omit<T, keyof IComposeProps>;

const withUserInfos = <T extends object>(
  Component: React.ComponentType<T>
): React.ComponentType<IHocProps<T>> => {
  return compose<T, IHocProps<T>>(
    graphql(getUserInfo, {
      props: (props: OptionProps<{}>): IGraphQLProps => ({
        getUser: get(props, 'data.getUser'),
        hasError: !!get(props, 'data.error'),
        isUserInfosLoading: !!get(props, 'data.loading'),
      }),
    })
  )(Component);
};

export default withUserInfos;
