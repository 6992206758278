import { connect } from 'react-redux';
import { compose, withProps, withHandlers } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { IAppState } from '../../redux/reducer';
import { getElementsQuantityByOfferId } from '../../redux/clickAndCollect/selectors';
import BundleArticlesPage, {
  IProps, IConnectedProps, IMapStateToProps, IWithExtraProps
} from './BundleArticlesPage.component';

type IContainerProps = Omit<IProps, keyof IConnectedProps>;

const mapStateToProps = (state: IAppState, { bundleSteps, stepIndex, offer, offerId }: IProps): IMapStateToProps => {
  const elementQuantities = getElementsQuantityByOfferId(state, offerId) || [];
  const min = bundleSteps[stepIndex].min || 0;
  const max = bundleSteps[stepIndex].max || Number.MAX_VALUE;
  const articles = offer.offerItems
    .filter(({ inheritedFamily }) => bundleSteps[stepIndex].articleFamilies.includes(inheritedFamily!));

  const otherQuantity = elementQuantities
    .filter(el => !articles.find(article => article.id === el.elementId))
    .reduce((prev, { quantity }) => prev + quantity, 0);

  const selectedQuantity = elementQuantities
    .filter(el => articles.find(article => article.id === el.elementId))
    .reduce((prev, { quantity }) => prev + quantity, 0);

  return {
    minQuantity: otherQuantity + min,
    maxQuantity: otherQuantity + max,
    remainingQuantity: max - selectedQuantity
  };
};

const withExtraProps = ({ navigation }: IConnectedProps): IWithExtraProps => {
  const stepIndex = navigation.getParam('stepIndex') || 0;
  const tableNumber = navigation.getParam('tableNumber');

  return { stepIndex, tableNumber };
};

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withExtraProps),
  withHandlers({
    // tslint:disable-next-line:typedef
    onNextStep: ({ navigation, offerId, stepIndex, tableNumber }: IProps & IConnectedProps) => async () => {
      navigation.push('offerBundleStep', { offerId, stepIndex: stepIndex + 1, tableNumber })
    }
  }),
  connect(mapStateToProps)
)(BundleArticlesPage);
