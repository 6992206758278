import { AnyAction, Reducer } from 'redux';

import { actionTypes as authenticationActionTypes } from '../authentication/actions';

import { actionTypes } from './actions';

export interface IMenuState {
  isRecipeDisclaimerOpened: boolean;
}

export const initialState: IMenuState = {
  isRecipeDisclaimerOpened: false,
};

const menuReducer: Reducer<IMenuState> = (
  state: IMenuState = initialState,
  action: AnyAction
): IMenuState => {
  switch (action.type) {
    case actionTypes.TOGGLE_DISCLAIMER:
      return {
        ...state,
        isRecipeDisclaimerOpened: action.payload.isRecipeDisclaimerOpened,
      };
    case authenticationActionTypes.AUTHENTICATION_LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default menuReducer;
