import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import {
  ImageStyle,
  ImageURISource,
  PixelRatio,
  RegisteredStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import ProgressiveImage from '../../../../../components/ProgressiveImage';
import Touchable from '../../../../../components/Touchable';
import withNavigation from '../../../../../hoc/withNavigation';
import I18n from '../../../../../lib/i18n';
import { isWeb } from '../../../../../lib/responsive';
import { getCloudimageUrl } from '../../../../../services/cloudimage';
import { getContentCategoryTranslated } from '../../../../../services/marketingMapper';
import theme from '../../../../../theme';
import { IMarketingCardElement, IMarketingCardStyleDisplay } from '../../../../../types/common';

import { IMAGE_HEIGHT } from './style';

const THUMB_SIZE = 5;

export interface IProps extends NavigationInjectedProps {
  marketingCardItem: IMarketingCardElement;
}

export const MARKETING_DISPLAY: { [key in IMarketingCardStyleDisplay]: key } = {
  IMAGE_LEFT: 'IMAGE_LEFT',
  IMAGE_RIGHT: 'IMAGE_RIGHT',
};

class MarketingItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { marketingCardItem } = this.props;
    const category = get(marketingCardItem, 'marketingType');

    const styles = this.getStyle();

    const foregroundThumbnailSource: ImageURISource = {};
    const foregroundImageSource: ImageURISource = {};
    const backgroundThumbnailSource: ImageURISource = {};
    const backgroundImageSource: ImageURISource = {};

    if (marketingCardItem && marketingCardItem.imageForeground) {
      foregroundThumbnailSource.uri = this.getImage(marketingCardItem.imageForeground, THUMB_SIZE);
      foregroundImageSource.uri = this.getImage(marketingCardItem.imageForeground, IMAGE_HEIGHT);
    }
    if (marketingCardItem && marketingCardItem.imageBackground) {
      backgroundThumbnailSource.uri = this.getImage(marketingCardItem.imageBackground, THUMB_SIZE);
      backgroundImageSource.uri = this.getImage(marketingCardItem.imageBackground, IMAGE_HEIGHT);
    }

    return (
      <Touchable
        style={styles.root}
        onPress={(): void => this.goToMarketingCard(marketingCardItem)}
        key={marketingCardItem.id}
      >
        <ProgressiveImage
          resizeMode="cover"
          style={styles.imageBackground}
          source={backgroundImageSource}
          thumbnailSource={backgroundThumbnailSource}
        >
          <View style={styles.container}>
            <View style={styles.titleContainer}>
              {category && (
                <Text style={styles.title}>{getContentCategoryTranslated(category)}</Text>
              )}
            </View>
            <View style={styles.element}>
              <View style={styles.textContainer}>
                <Text style={styles.text} numberOfLines={4}>
                  {marketingCardItem.title}
                </Text>
              </View>
              <View style={styles.imageContainer}>
                <ProgressiveImage
                  style={styles.image}
                  source={foregroundImageSource}
                  thumbnailSource={foregroundThumbnailSource}
                  resizeMode="contain"
                />
              </View>
            </View>
            <View style={styles.cta}>
              <Touchable onPress={(): void => this.goToMarketingCard(marketingCardItem)}>
                <Text style={styles.buttonText}>{I18n.t('dashboard.home.marketing.seeMore')}</Text>
              </Touchable>
            </View>
          </View>
        </ProgressiveImage>
      </Touchable>
    );
  }

  private getImage = (url: string, height: number): string => {
    const multiplier = isWeb() ? 2 : 1 ;

    return getCloudimageUrl(url, 'height', String(PixelRatio.getPixelSizeForLayoutSize(height * multiplier)));
  };

  private getStyle = (): { [P in keyof IStyle]: RegisteredStyle<IStyle[P]> } => {
    const { marketingCardItem } = this.props;

    const baseStyle: IStyle = {
      buttonText: {
        color: marketingCardItem.style.color || theme.colors.textBlack,
        ...theme.fonts.buttonText,
      },
      container: { flex: 1, height: '100%' },
      cta: {
        bottom: theme.margins.cardUnit * 2,
        justifyContent: 'flex-end',
        position: 'absolute',
        right: theme.margins.cardUnit * 2,
      },
      element: { flexDirection: 'row', height: '100%' },
      image: {
        height: '100%',
        width: '100%',
      },
      imageBackground: { height: '100%', width: '100%' },
      imageContainer: {
        height: '100%',
        width: '40%',
      },
      root: {
        backgroundColor: theme.colors.white,
        flex: 1,
        marginHorizontal: theme.margins.unit,
      },
      text: {
        ...theme.fonts.seeMoreTitle,
        color: marketingCardItem.style.color || theme.colors.textBlack,
        padding: theme.margins.cardUnit * 2,
        textAlign: 'left',
      },
      textContainer: { justifyContent: 'center', width: '60%' },
      title: {
        ...theme.fonts.semiBoldText,
        color: marketingCardItem.style.color || theme.colors.textBlack,
      },
      titleContainer: {
        left: theme.margins.cardUnit * 2,
        position: 'absolute',
        top: theme.margins.cardUnit * 2,
      },
    };

    switch (marketingCardItem.style.display) {
      case MARKETING_DISPLAY.IMAGE_LEFT:
        return StyleSheet.create<IStyle>({
          ...baseStyle,
          element: { ...baseStyle.element, flexDirection: 'row-reverse' },
          text: { ...baseStyle.text, textAlign: 'right' },
        });
      default:
        return StyleSheet.create<IStyle>(baseStyle);
    }
  };

  private goToMarketingCard = (marketingCard: IMarketingCardElement): void => {
    this.props.navigation.navigate('marketingCard', {
      marketingCardId: marketingCard.id,
    });
  };
}

export interface IStyle {
  buttonText: TextStyle;
  container: ViewStyle;
  cta: ViewStyle;
  element: ViewStyle;
  image: ImageStyle;
  imageBackground: ImageStyle;
  imageContainer: ViewStyle;
  root: ViewStyle;
  text: TextStyle;
  textContainer: ViewStyle;
  title: TextStyle;
  titleContainer: ViewStyle;
}

export type IConnectedProps = NavigationInjectedProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withNavigation)(MarketingItem);
