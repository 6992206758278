import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import Button from '../../components/Button';
import FlatButton from '../../components/FlatButton';
import GhostButton from '../../components/GhostButton';
import { BUTTON_THEME } from '../../components/GhostButton/GhostButton.component';
import { FoodiLogo, SplitPageLayout } from '../../componentsWeb';
import { FOODI_LOGO_ORIENTATION } from '../../componentsWeb/FoodiLogo/utils';
import { ENVIRONMENT, getConfig } from '../../environment';
import I18n from '../../lib/i18n';
import Auth from '../../services/authentication';
import { getUserSignUpParams } from '../../services/navigation/params';
import { Entity, idToUuid } from '../../services/base64Id';

const FOODI_LOGO_HEIGHT = 190;
const FOODI_LOGO_WIDTH = 124;

class Landing extends PureComponent<NavigationInjectedProps> {
  public render(): ReactNode {
    const env = getConfig();
    const holdingId = this.props.navigation.getParam('holding');

    return (
      <SplitPageLayout>
        {env.ENV.toLowerCase() !== ENVIRONMENT.PRODUCTION.toLowerCase() && this.renderDevUtils()}
        <View style={styles.logoContainerStyle}>
          <FoodiLogo
            brandLess
            orientation={FOODI_LOGO_ORIENTATION.vertical}
            height={FOODI_LOGO_HEIGHT}
            width={FOODI_LOGO_WIDTH}
          />
        </View>
        <Button
          onPress={this.handleAuth0Authentication}
          style={styles.button}
          text={I18n.t('landing.signIn')}
        />
        <GhostButton
          onPress={(): void => {
            holdingId
              ? this.props.navigation.navigate('signUpIdentity', {
                  holdingId: idToUuid(holdingId, Entity.HOLDING),
                })
              : this.props.navigation.navigate('signUpGeolocation');
          }}
          text={I18n.t('landing.signUp')}
          theme={BUTTON_THEME.black}
        />
      </SplitPageLayout>
    );
  }

  private handleAuth0Authentication = (): void => {
    const { email } = getUserSignUpParams(this.props.navigation);

    const loginConfig = {
      email,
      signInRedirection: this.props.navigation.getParam('signInRedirection'),
    };
    Auth.login(loginConfig);
  };

  private renderDevUtils = (): ReactNode => {
    return (
      <View>
        <FlatButton
          onPress={(): void => {
            const object = {};
            // @ts-ignore
            object.function();
          }}
          text="Crash app ¯\_(ツ)_/¯ => Javascript"
        />
      </View>
    );
  };
}

interface IStyle {
  button: ViewStyle;
  logoContainerStyle: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  button: {
    marginBottom: '20px',
  },
  logoContainerStyle: {
    alignItems: 'center',
    paddingBottom: '15vh',
    paddingTop: '30vh',
  },
});

export default Landing;
