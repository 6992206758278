import { loader } from 'graphql.macro';
import difference from 'lodash/difference';
import get from 'lodash/get';
import union from 'lodash/union';
import { FetchResult } from 'react-apollo';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';
import { IUpdateUserResult } from '../../types/updateOptins';

import { IOptinCode, IUserOptin } from './../../types/common';
import NotificationSwitchList, { IProps } from './NotificationSwitchList.component';

const getProfileSectionsData = loader('../../queries/getProfileSectionsData.gql');
const updateOptins = loader('../../queries/updateOptins.gql');

export interface IMapStateToProps {
  token?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

export interface IGraphQLProps {
  isLoading?: boolean;
  optins: IUserOptin[];
}

type IUpdateOptinsMutation = (valueName: IOptinCode, value: boolean) => Promise<FetchResult>;

export type IConnectedProps = IMapStateToProps & IGraphQLProps & WithApolloClient<{}>;

const updateOptinHandler = (props: IConnectedProps): IUpdateOptinsMutation => async (
  valueName: IOptinCode,
  value: boolean
): Promise<FetchResult> => {
  let optins = props.optins.map((optin: IUserOptin): IOptinCode => optin.code);
  optins = value ? union(optins, [valueName]) : difference(optins, [valueName]);

  return props.client.mutate<IUpdateUserResult>({
    mutation: updateOptins,
    variables: {
      input: {
        optins,
      },
    },
  });
};

export default compose<IProps, {}>(
  connect(mapStateToProps),
  graphql(getProfileSectionsData, {
    options: (): QueryOpts => ({
      fetchPolicy: 'network-only',
    }),
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      isLoading: props.data!.loading,
      optins: get(props, 'data.getUser.userOptins') || [],
    }),
  }),
  withApollo,
  withHandlers({ updateOptin: updateOptinHandler }),
  withNavigation
)(NotificationSwitchList);
