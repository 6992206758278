import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  text?: string;
}

class Label extends PureComponent<IProps> {
  public render(): ReactNode {
    const { children, text } = this.props;

    return <Text style={styles.text}>{text ? text : children}</Text>;
  }
}

export interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    color: theme.colors.textGray,
    ...theme.fonts.label,
  },
});

export default Label;
