import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import withNavigation from '../../../../../../hoc/withNavigation';
import TableNumberConfirmation, { IProps } from './TableNumberConfirmation.component';


interface IWithOffer {
  offerId: string;
}

const withOfferId = (ownProps: NavigationInjectedProps): IWithOffer => ({
  offerId: ownProps.navigation.getParam('offerId'),
});

interface IWithTableNumber {
  tableNumber: string;
  idTable: string;
}

const withTableNumber = (ownProps: NavigationInjectedProps): IWithTableNumber => ({
  idTable: ownProps.navigation.getParam('idTable'),
  tableNumber: ownProps.navigation.getParam('tableNumber'),
});

export default compose<IProps, {}>(
  withNavigation,
  withProps(withOfferId),
  withProps(withTableNumber),
)(TableNumberConfirmation);
