import React, { PureComponent, ReactNode } from 'react';
import { View } from 'react-native';

import { isDesktop } from '../../lib/responsive';
import theme from '../../theme';

import Rectangle from './Rectangle.component';

export interface IProps {
  activeIndexes: number[];
  labels: string[];
  lastLabel: string;
  maxValue: number;
  values: number[];
}

const CHART_HEIGHT = isDesktop() ? 150 : 100;
const CHART_MIN = 5;

class Chart extends PureComponent<IProps> {
  public render(): ReactNode {
    const { labels, maxValue, values, activeIndexes, lastLabel } = this.props;
    const maxIndex = values.length - 1;

    return (
      <View style={{ marginBottom: 60, marginTop: 15 }}>
        <View
          style={{
            alignItems: 'flex-end',
            borderBottomWidth: 1,
            borderColor: theme.colors.blueLight,
            flexDirection: 'row',
            height: CHART_HEIGHT,
            paddingLeft: '10%',
            paddingRight: '10%',
            width: '100%',
          }}
        >
          {values.map((value: number, index: number) => {
            const color =
              activeIndexes.indexOf(index) !== -1
                ? theme.colors.blueMedium
                : theme.colors.blueLight;

            return (
              <Rectangle
                key={index}
                width={this.computeWidth(values.length)}
                height={this.computeHeight(maxValue, value)}
                backgroundColor={color}
                isFirst={index === 0}
                isLast={index === maxIndex}
                defaultColor={theme.colors.blueLight}
                labelLeft={this.displayLeftLabel(index, maxIndex) ? labels[index] : ''}
                labelRight={index === maxIndex ? lastLabel : ''}
              />
            );
          })}
        </View>
      </View>
    );
  }

  private computeHeight(maxValue: number, value: number): number {
    return Math.min(
      CHART_HEIGHT - CHART_MIN,
      Math.max(CHART_HEIGHT * (value / Math.max(maxValue, 1)), CHART_MIN)
    );
  }

  private computeWidth(numberBars: number): string {
    return `${100.0 / numberBars}%`;
  }

  private displayLeftLabel = (currentIndex: number, maxIndex: number): boolean => {
    if (currentIndex === 0) {
      return true;
    }
    if (currentIndex === maxIndex) {
      return false;
    }
    if (maxIndex < 8) {
      return false;
    }

    if (maxIndex % 2 === 0) {
      return currentIndex % 4 === 0;
    }

    return currentIndex % 3 === 0;
  };
}

export default Chart;
