import React, { PureComponent, ReactNode } from 'react';
import Modal, { Styles as IModalStyles } from 'react-modal';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../../theme';
import Icon from '../../Icon';
import Touchable from '../../Touchable';

export interface IProps {
  hideModal?: () => void;
  isModalVisible: boolean;
  renderContent?: ReactNode;
  title: string;
}

class TriggerModalContent extends PureComponent<IProps> {
  public render(): ReactNode {
    const { renderContent, isModalVisible, hideModal } = this.props;

    return (
      <Modal isOpen={isModalVisible} style={modalStyles} onRequestClose={hideModal}>
        <View>
          <View style={styles.headerContainer}>
            <Text style={styles.title}>{this.props.title}</Text>
            <Touchable onPress={this.props.hideModal} style={styles.crossContainer}>
              <Icon name={'cross'} size={CROSS_SIZE} tintColor={theme.colors.black} />
            </Touchable>
          </View>
          {renderContent}
        </View>
      </Modal>
    );
  }
}

const BORDER_WIDTH = 0;
const MODAL_WIDTH = 360;
const CROSS_SIZE = 20;

interface IStyle {
  crossContainer: ViewStyle;
  headerContainer: ViewStyle;
  icon: ViewStyle;
  title: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  crossContainer: { position: 'absolute', top: 0, right: 0 },
  headerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    bottom: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  title: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.black,
    marginBottom: theme.margins.cardUnit * 2,
  },
});

const modalStyles: IModalStyles = {
  content: {
    borderWidth: BORDER_WIDTH,
    bottom: 0,
    left: 0,
    maxHeight: '80%',
    maxWidth: MODAL_WIDTH,
    padding: theme.margins.cardUnit * 2,
    position: 'relative',
    right: 0,
    top: 0,
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: theme.colors.overlay,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: theme.margins.cardUnit * 2,
  },
};

export default TriggerModalContent;
