import React, { PureComponent, ReactNode } from 'react';

export interface IProps {
  disabled?: boolean;
  onPress?: () => void;
  routeName: string;
  title: string;
}

type IDefaultProps = Required<Pick<IProps, 'disabled'>>;

export interface IState {
  isModalVisible: boolean;
}
class TriggerModal extends PureComponent<IProps, IState> {
  public static defaultProps: IDefaultProps = {
    disabled: false,
  };

  public state: IState = {
    isModalVisible: false,
  };

  public render(): ReactNode {
    const { disabled, onPress } = this.props;
    const buttonStyle = disabled ? {} : divStyle;
    const showModalFunction = disabled ? undefined : onPress;

    return (
      <>
        {/* We use a button here because click event is fired two times
        when using react-modal and Touchable or TouchableOpacity */}
        <div style={buttonStyle} onClick={showModalFunction}>
          {this.props.children}
        </div>
      </>
    );
  }
}

const divStyle = {
  cursor: 'pointer',
};

export default TriggerModal;
