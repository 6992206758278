type IEnvironment =
  | 'clickandcollect'
  | 'development'
  | 'marketingcontent'
  | 'staging'
  | 'production';
type IEnvironmentKey =
  | 'CLICKANDCOLLECT'
  | 'DEVELOPMENT'
  | 'MARKETINGCONTENT'
  | 'STAGING'
  | 'PRODUCTION';

export const ENVIRONMENT: { [key in IEnvironmentKey]: IEnvironment } = {
  CLICKANDCOLLECT: 'clickandcollect',
  DEVELOPMENT: 'development',
  MARKETINGCONTENT: 'marketingcontent',
  PRODUCTION: 'production',
  STAGING: 'staging',
};
