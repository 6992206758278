import Yup from 'yup';

export const PASSWORD_MIN_LENGTH = 8;

export interface ISchemaEmailFront {
  email: string;
}

export const validationEmailFront = Yup.object<ISchemaEmailFront>().shape({
  email: Yup.string().email('signUp.email.errorSyntax'),
});

export interface ISchemaPasswordFront {
  password: string;
}

export const validationPasswordFront = Yup.object<ISchemaPasswordFront>().shape({
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, 'signUp.password.errorPasswordComplexity')
    .matches(/[a-z]/, 'signUp.password.errorPasswordComplexity')
    .matches(/[A-Z]/, 'signUp.password.errorPasswordComplexity')
    .matches(/[0-9]/, 'signUp.password.errorPasswordComplexity'),
});
