import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageSourcePropType, ImageStyle, RegisteredStyle, View } from 'react-native';

import Button from '../../components/Button';
import FullWidthView from '../../components/FullWidthView';
import Title from '../../components/Title';

import styles from './styles';

export interface IProps {
  buttonTitle: string;
  image: ImageSourcePropType;
  imageStyle: RegisteredStyle<ImageStyle>;
  isLoading?: boolean;
  onClose: () => void;
  title: string;
}

class TopUpCallbackContent extends PureComponent<IProps> {
  public render(): ReactNode {
    const { buttonTitle, isLoading, image, imageStyle, title, onClose } = this.props;

    return (
      <>
        <View style={styles.container}>
          <FullWidthView style={styles.imageContainer}>
            <Image style={imageStyle} source={image} />
          </FullWidthView>
          <Title text={title} />
        </View>
        <Button text={buttonTitle} onPress={onClose} style={styles.button} isLoading={isLoading} />
      </>
    );
  }
}

export default TopUpCallbackContent;
