import { IMPORTATION_TYPES, IImportationTypes } from "./ImportationTypes";

export type IDishGroup = 'STARTERS' | 'MEALS' | 'DESSERTS' | 'ACCOMP' | 'COMPLEMENT' | 'FROMAGE' | 'GOUTER' | 'DAIRIES';

export const DISH_GROUP_ID: { [key in IImportationTypes]: {[key in IDishGroup]: string} } = {
  [IMPORTATION_TYPES.OSCAR]: {
    // DESSERTS: 'MenuElementDishGroup:7',
    // MEALS: 'MenuElementDishGroup:2',
    // STARTERS: 'MenuElementDishGroup:4',
    // ACCOMP: 'MenuElementDishGroup:3',
    // FROMAGE: 'MenuElementDishGroup:9',
    // DAIRIES: 'MenuElementDishGroup:8',
    DESSERTS: 'TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==',
    MEALS: 'TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==',
    STARTERS: 'TWVudUVsZW1lbnREaXNoR3JvdXA6NA==',
    ACCOMP: 'TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==',
    COMPLEMENT: '',
    FROMAGE: 'TWVudUVsZW1lbnREaXNoR3JvdXA6OQ==',
    GOUTER: '',
    DAIRIES: 'TWVudUVsZW1lbnREaXNoR3JvdXA6OA==',
},
  [IMPORTATION_TYPES.WINAPRO]: {
    // DESSERTS: 'MenuElementDishGroup:A0000016',
    // MEALS: 'MenuElementDishGroup:A0000013',
    // STARTERS: 'MenuElementDishGroup:A0000012',
    // ACCOMP: 'MenuElementDishGroup:A0000014',
    // COMPLEMENT: 'MenuElementDishGroup:A0000021',
    // FROMAGE: 'MenuElementDishGroup:A0000015',
    // GOUTER: 'MenuElementDishGroup:A0000017',
    DESSERTS: 'TWVudUVsZW1lbnREaXNoR3JvdXA6QTAwMDAwMTY=',
    MEALS: 'TWVudUVsZW1lbnREaXNoR3JvdXA6QTAwMDAwMTM=',
    STARTERS: 'TWVudUVsZW1lbnREaXNoR3JvdXA6QTAwMDAwMTI=',
    ACCOMP: 'TWVudUVsZW1lbnREaXNoR3JvdXA6QTAwMDAwMTQ=',
    COMPLEMENT: 'TWVudUVsZW1lbnREaXNoR3JvdXA6QTAwMDAwMjE=',
    FROMAGE: 'TWVudUVsZW1lbnREaXNoR3JvdXA6QTAwMDAwMTU=',
    GOUTER: 'TWVudUVsZW1lbnREaXNoR3JvdXA6QTAwMDAwMTc=',
    DAIRIES: ''
  },
  [IMPORTATION_TYPES.OTHER]: {
    DESSERTS: '',
    MEALS: '',
    STARTERS: '',
    ACCOMP: '',
    COMPLEMENT: '',
    FROMAGE: '',
    GOUTER: '',
    DAIRIES: '',
  },

};
