import React from 'react';
import queryString from 'query-string';
import { View, Text } from 'react-native';
import { WebView } from 'react-native-webview';
import { generateTokens, IToken, storeEdenredTokens } from '../../../services/edenred';


export interface IProps {
  authenticationURL: string;
  onTokensGenerated: (token: IToken | null) => void;
}

const EdenredAuthentication: React.FC<IProps> = props => {
  const {
    authenticationURL,
    // will receive null if tokens weren't generated
    // if receive null, the user can't use edenred
    onTokensGenerated,
  } = props;

  const getURLParam = (urlString: string, param: string): string | null => {

    // locate only the querystring section and parse it
    const querystring = queryString.parse(urlString.substr(urlString.indexOf('?')));
    return querystring[param] as string | null;
  };

  const redirectURI = getURLParam(authenticationURL, 'redirect_uri');

  // todo - something to think about is the user experience while asking for the tokens, display some kind of loading

  return (
    <WebView
      source={{ uri: authenticationURL }}
      // used to detect url changes
      onNavigationStateChange={async ({ url }) => {
        const encodedHost = url.split('?')[0];

        if (redirectURI === encodedHost) {
          // means the authentication step went well and the user was redirect to our redirect uri
          const authorizationCode = getURLParam(url, 'code');

          if (!authorizationCode) {
            onTokensGenerated(null);
            return;
          }
          const tokens = await generateTokens(authorizationCode);

          if (tokens) {
            await storeEdenredTokens(tokens);
          }
          onTokensGenerated(tokens);
        }
      }}
      startInLoadingState={true}
      renderLoading={() => (
        <View>
          <Text>Loading...</Text>
        </View>
      )}
    />
  );
};

export default EdenredAuthentication;
