import React from 'react';
import {
  GestureResponderEvent,
  RegisteredStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  StyleProp,
} from 'react-native';
import theme from '../../theme';
import Icon, { IIconName } from '../Icon';

export interface IProps {
  iconSize?: number;
  iconName?: IIconName;
  label: string;
  tintColor?: string;
  tagStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  onClick?: (event: GestureResponderEvent) => void;
  CustomComponent?: React.ReactNode;
  active?: boolean;
  testID?: string;
}

const Tag: React.FC<IProps> = ({
  iconName,
  label,
  tagStyle,
  onClick,
  tintColor,
  iconSize = 15,
  style,
  CustomComponent,
  active,
  testID,
}: IProps) => (
  <TouchableOpacity testID={testID} disabled={!onClick ? true : false} onPress={onClick}>
    <View style={[styles.container, active ? styles.containerActive : {}, style]}>
      {iconName ? (
        <Icon size={iconSize} name={iconName} tintColor={tintColor} />
      ) : CustomComponent ? (
        CustomComponent
      ) : (
        null
      )}
      <Text numberOfLines={1} style={[styles.label, active ? styles.labelActive : {}, iconName ? styles.withIcon : {}, tagStyle]}>
        {label}
      </Text>
    </View>
  </TouchableOpacity>
);

interface IStyle {
  container: ViewStyle;
  containerActive: ViewStyle;
  label: TextStyle;
  labelActive: TextStyle;
  withIcon: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderColor: theme.colors.blueGray,
    borderRadius: 12,
    borderWidth: 1,
    flexDirection: 'row',
    height: theme.margins.cardUnit * 3,
    marginBottom: theme.margins.cardUnit,
    marginRight: theme.margins.cardUnit,
    paddingHorizontal: theme.margins.cardUnit,
  },
  containerActive: {
    borderColor: theme.colors.greenLighter,
    backgroundColor: theme.colors.greenLighter,
  },
  label: {
    ...theme.fonts.regularText,
    color: theme.colors.black,
  },
  labelActive: {
    ...theme.fonts.regularText,
    color: theme.colors.textWhite,
  },
  withIcon: {
    marginLeft: theme.margins.cardUnit,
  }
});

export default Tag;
