import React, { CSSProperties, ReactElement } from 'react';
import ReactFontAwesome from 'react-fontawesome';

const Icon = ({
  color,
  name,
}: {
  color: CSSProperties['color'];
  name: string;
}): ReactElement<ReactFontAwesome> => <ReactFontAwesome name={name} style={{ color }} />;

export default Icon;
