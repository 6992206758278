export { default } from './QuantityFooter.component';
export { default as OrderButton } from './OrderButton';

import BundleQuantityFooter, {
  FooterType as BundleFooterType,
} from './BundleQuantityFooter';

import ClickAndCollectQuantityFooter, {
  FooterType as ClickAndCollectFooterType,
} from './ClickAndCollectQuantityFooter';

import ClickAndCollectPriceFooter, {
  FooterType as ClickAndCollectType,
} from './ClickAndCollectPriceFooter';

import TableServiceQuantityFooter, {
  FooterType as TableServiceFooterType,
} from './TableServiceQuantityFooter';

export {
  BundleQuantityFooter,
  BundleFooterType,
  ClickAndCollectQuantityFooter,
  ClickAndCollectFooterType,
  ClickAndCollectPriceFooter,
  ClickAndCollectType,
  TableServiceQuantityFooter,
  TableServiceFooterType,
};
