import React, { PureComponent, ReactNode } from 'react';
import { Platform, StyleProp, StyleSheet, TextInput, TextStyle, View } from 'react-native';
import theme from '../../../../../theme';

export enum CurrencyInputError {
  UnderMinimum = 'minamount',
  OverMaximum = 'maxamount',
  InvalidFormat = 'format',
}

interface IProps {
  testID?: string;
  onChange: (error: CurrencyInputError | null, amount: string) => void;

  /** Amount as it was typed by the user (with trailing zeroes, and either comma or dot as separator) */
  amount: string;

  /** Minimum amount which is allowed */
  minAmount: number;
  maxAmount: number;
}

export default class CurrencyInput extends PureComponent<IProps> {
  public render(): ReactNode {
    let input: StyleProp<TextStyle> = styles.input;
    let backInput: StyleProp<TextStyle> = styles.backInput;
    if (Platform.OS === 'web') {
      input = [input, { width: 250, caretColor: 'rgba(0, 0, 0, 1)' } as StyleProp<TextStyle>];
      backInput = [backInput, { width: 250 }];
    }

    return (
      <View style={styles.inputs}>
        <TextInput
          underlineColorAndroid="transparent"
          value={`${this.props.amount} €`}
          style={backInput}
          editable={false}
        />
        <TextInput
          testID={this.props.testID}
          autoFocus
          underlineColorAndroid="transparent"
          value={this.props.amount}
          style={input}
          onChangeText={(amount: string): void => this.onInputChangeText(amount)}
          keyboardType={Platform.OS === 'web' ? 'default' : 'numeric'}
        />
      </View>
    );
  }

  private onInputChangeText(amount: string): void {
    // Both maxLength and keyboardType=numeric-pad bug on react-native-web are buggy.
    // => emulate decimal input with regexps
    // @see https://github.com/necolas/react-native-web/issues/1103

    // limit chars which can be used on the field
    if (amount !== '' && !/^[1-9]\d{0,2}(?:[,\.]\d{0,2})?$/.test(amount)) {
      return;
    }

    let error = null;
    if (amount === '') {
      error = null;
    } else if (!/^\d+(?:[,\.]\d\d?)?$/.test(amount)) {
      error = CurrencyInputError.InvalidFormat;
    } else {
      const value = parseFloat(amount.replace(',', '.'));
      if (value < this.props.minAmount) {
        error = CurrencyInputError.UnderMinimum;
      } else if (value > this.props.maxAmount) {
        error = CurrencyInputError.OverMaximum;
      }
    }

    this.props.onChange(error, amount);
  }
}

const styles = StyleSheet.create({
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: 150,
  },
  input: {
    alignSelf: 'center',
    flex: 1,
    fontFamily: theme.fonts.notificationTitle.fontFamily,
    fontSize: 42,
    fontWeight: '500',
    position: 'absolute',
    textAlign: 'center',
    width: '90%',
    zIndex: 1,
    color: 'rgba(0, 0, 0, 0)',
  },
  backInput: {
    alignSelf: 'center',
    flex: 1,
    fontFamily: theme.fonts.notificationTitle.fontFamily,
    fontSize: 42,
    fontWeight: '500',
    paddingLeft: 37,
    position: 'absolute',
    textAlign: 'center',
    width: '90%',
    zIndex: -1,
    color: 'black',
  },
});
