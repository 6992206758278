import { StyleSheet } from 'react-native';

import theme from '../../theme'

export const sharedStyles = StyleSheet.create({
  section: {
    marginTop: 15,
    marginBottom: 15,
  },
  sectionTitle: {
    ...theme.fonts.semiBoldText,
    marginBottom: 17,
  },
  sectionTitleWithoutMarginBottom: {
    ...theme.fonts.semiBoldText,
    marginBottom: 5,
  },
  sectionContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  sectionContentColumn: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  sectionContentTextLeft: {
    ...theme.fonts.regularText,
  },
  sectionContentTextLeftBold: {
    ...theme.fonts.slotsItem,
  },
  sectionContentTextRight: {
    ...theme.fonts.slotsItem, // FIXME This allows to use bold.
  },
  amount: {
    marginLeft: theme.margins.cardUnit,
    ...theme.fonts.strongText,
    fontSize: 16,
  },
  seeMoreText: {
    ...theme.fonts.boldText,
    color: theme.colors.blue,
    textDecorationLine: 'underline',
  },
});

export default sharedStyles;
