import { IGeolocationAuthorizedCallBack, IGeolocationRefusedCallBack } from './geolocation.d';

const getCurrentPositionWithPermissionCheck = async (
  onAuthorized: IGeolocationAuthorizedCallBack,
  onRefused: IGeolocationRefusedCallBack
): Promise<void> => {
  navigator.geolocation.getCurrentPosition(onAuthorized, onRefused);
};

export default getCurrentPositionWithPermissionCheck;
