import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  text: string;
}

class HeaderTitle extends PureComponent<IProps> {
  public render(): ReactNode {
    const { text } = this.props;

    return (
      <Text numberOfLines={1} style={styles.text}>
        {text}
      </Text>
    );
  }
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.headerTitle,
    color: theme.colors.textBlack,
  },
});

export default HeaderTitle;
