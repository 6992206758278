import { Action } from 'redux';

export type IActionTypes = 'AUTHENTICATION_SET_ORDER_TOKEN';

export const actionTypes: { [key in IActionTypes]: key } = {
  AUTHENTICATION_SET_ORDER_TOKEN: 'AUTHENTICATION_SET_ORDER_TOKEN',
};

export interface ISetAccessTokenPayload {
    emissionDate: string;
    expirationDate: string;
    expiresIn: number;
    token: string;
  }
  

export interface ISetAccessTokenAction extends Action {
  payload: ISetAccessTokenPayload;
}

export const setOrderToken = (payload: ISetAccessTokenPayload): ISetAccessTokenAction => ({
  payload,
  type: actionTypes.AUTHENTICATION_SET_ORDER_TOKEN,
});
