import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, ViewStyle, Text, TextStyle } from 'react-native';
import LoadingCard from '../../LoadingCard';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import OrderSummarySection from '../OrderSummarySection';

export interface IProps {
  isLoading: boolean;
  comment: string | null;
}

class OrderCommentCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, comment } = this.props;

    return (
      <LoadingCard isLoading={isLoading} style={styles.card} big>
        <OrderSummarySection title={I18n.t('dashboard.eat.clickAndCollect.orderDetails.comment')}>
          <Text style={styles.comment}>{comment}</Text>
          <Text style={styles.information}>
            {I18n.t('dashboard.eat.clickAndCollect.orderDetails.commentInfo')}
          </Text>
        </OrderSummarySection>
      </LoadingCard>
    );
  }
}

export interface IStyle {
  card: ViewStyle;
  separator: ViewStyle;
  comment: TextStyle;
  information: TextStyle;
}

const BORDER_RADIUS = 8;

const styles = StyleSheet.create({
  card: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    marginBottom: theme.margins.cardUnit * 2,
    overflow: 'hidden',
  },
  separator: {
    marginVertical: theme.margins.cardUnit * 2,
  },
  comment: {
    ...theme.fonts.label,
    color: theme.colors.black,
    marginTop: theme.margins.cardUnit,
  },
  information: {
    ...theme.fonts.strongText,
    color: theme.colors.grayMedium,
    marginTop: theme.margins.cardUnit,
  },
});

export default OrderCommentCard;
