import React, { PureComponent, ReactNode } from 'react';
import * as Progress from 'react-native-progress';

import theme from '../../theme';

export interface IProps {
  progress: number;
}

export interface IState {
  fillAmount: number;
}

const BAR_HEIGHT = 4;
const BORDER_WIDTH = 0;
const ANIMATION_DURATION = 750;

class ProgressBar extends PureComponent<IProps, IState> {
  public state: IState = {
    fillAmount: 0,
  };

  public componentDidMount(): void {
    this.setState({ fillAmount: this.props.progress });
  }

  public render(): ReactNode {
    return (
      <Progress.Bar
        progress={this.state.fillAmount}
        animated
        color={theme.colors.blue}
        unfilledColor={theme.colors.blueGray}
        borderWidth={BORDER_WIDTH}
        height={BAR_HEIGHT}
        animationType="timing"
        animationConfig={{ duration: ANIMATION_DURATION }}
      />
    );
  }
}

export default ProgressBar;
