import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import I18n from '../../lib/i18n';
import { setAccessToken } from '../../redux/authentication/actions';
import { setOrderToken } from '../../redux/orderAuthentification/actions';
import { selectTokenExpirationDate } from '../../redux/authentication/selectors';
import store from '../../redux/store';
import Auth from '../../services/authentication';
import { logOut } from '../../services/authentication/logOut';
import { getExpirationTokenInfoFromExp } from '../../services/authentication/utils';
import Toaster from '../../services/toaster';

const EXPIRATION_TIME_KEY = 'exp';

export const updateAccessToken = (token: string): void => {
  const decodeToken = jwtDecode(token) as Record<string, number>;
  const expirationTokenInfo = getExpirationTokenInfoFromExp(decodeToken[EXPIRATION_TIME_KEY]);
  store.dispatch(
    setAccessToken({
      emissionDate: expirationTokenInfo.emissionDate,
      expirationDate: expirationTokenInfo.expirationDate,
      expiresIn: expirationTokenInfo.expireIn,
      token,
    })
  );
};

export const updateOrderToken = (token: string): void => {
  const decodeToken = jwtDecode(token) as Record<string, number>;
  const expirationTokenInfo = getExpirationTokenInfoFromExp(decodeToken[EXPIRATION_TIME_KEY]);
  store.dispatch(
    setOrderToken({
      emissionDate: expirationTokenInfo.emissionDate,
      expirationDate: expirationTokenInfo.expirationDate,
      expiresIn: expirationTokenInfo.expireIn,
      token,
    })
  );
};

export const refreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  // If we don't define function here Auth is undefined and unit test doesn't work.
  fetchAccessToken: async (): Promise<Response> => Auth.refreshToken(),
  handleError: (): void => {
    logOut();
    Toaster.showError(I18n.t('error.refreshToken'));
  },
  handleFetch: updateAccessToken,
  isTokenValidOrUndefined: (): boolean => {
    const expirationDate = selectTokenExpirationDate(store.getState());
    if (!expirationDate) {
      // unauthenticated queries must not attempt to refresh token
      return true;
    }

    return moment(expirationDate).isAfter(moment().toISOString());
  },
});
