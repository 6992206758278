import React, { PureComponent, ReactNode } from 'react';

import LoadingCard from '../../../../components/LoadingCard';
import NotificationSwitchList from '../../../../components/NotificationSwitchList';
import { WebPage } from '../../../../componentsWeb';
import I18n from '../../../../lib/i18n';

class Notifications extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <WebPage title={I18n.t('dashboard.profile.notifications.title')} canGoBack>
        <LoadingCard>
          <NotificationSwitchList />
        </LoadingCard>
      </WebPage>
    );
  }
}

export default Notifications;
