import { ApolloQueryResult } from 'apollo-client';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { OperationVariables } from 'react-apollo';
import { FlatListProps, StyleSheet, View, ViewStyle } from 'react-native';

import { POS_TYPE } from '../../../../../constants';
import I18n from '../../../../../lib/i18n';
import theme from '../../../../../theme';
import { INode, IPos } from '../../../../../types/common';
import { IUserResult } from '../../../../../types/getPointsOfSaleWithMenu';
import { IFilter } from '../../../../../components/FilterDropdown/FilterDropdown.component';
import PageTitle from '../../../../../components/PageTitle';

import PointsOfSaleListByType from './PointsOfSaleListByType.component';

export interface IProps extends Partial<FlatListProps<INode<IPos>>> {
  filters?: IFilter[];
  lastItem?: ReactElement<{}>;
  pointsOfSale: Array<INode<IPos>>;
  refetch?: (variables?: OperationVariables) => Promise<ApolloQueryResult<IUserResult>>;
  withFilters: boolean;
}

export interface IState {
  refreshing: boolean;
}

class PointsOfSaleListContent extends PureComponent<IProps, IState> {
  public render(): ReactNode {
    const { pointsOfSale, ...otherProps } = this.props;

    const selfList = pointsOfSale.filter((pos: INode<IPos>) => pos.node.type === POS_TYPE.SELF);

    const cafeteriaList = pointsOfSale.filter(
      (pos: INode<IPos>) => pos.node.type === POS_TYPE.CAFETERIA
    );

    const brasserieList = pointsOfSale.filter(
      (pos: INode<IPos>) => pos.node.type === POS_TYPE.BRASSERIE
    );

    return (
      <>
        {selfList && selfList.length > 0 && (
          <View style={styles.listContainer}>
            <PageTitle text={I18n.t('dashboard.eat.pageTitle.self')} />
            <PointsOfSaleListByType pointsOfSale={selfList} {...otherProps} />
          </View>
        )}
        {cafeteriaList && cafeteriaList.length > 0 && (
          <View style={styles.listContainer}>
            <PageTitle text={I18n.t('dashboard.eat.pageTitle.cafeteria')} />
            <PointsOfSaleListByType pointsOfSale={cafeteriaList} {...otherProps} />
          </View>
        )}
        {brasserieList && brasserieList.length > 0 && (
          <View style={styles.listContainer}>
            <PageTitle text={I18n.t('dashboard.eat.pageTitle.brasserie')} />
            <PointsOfSaleListByType pointsOfSale={brasserieList} {...otherProps} />
          </View>
        )}
      </>
    );
  }
}

interface IStyle {
  listContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  listContainer: {
    marginTop: theme.margins.cardUnit * 3,
  },
});

export default PointsOfSaleListContent;
