export const hasUpperCase = (password: string): boolean => {
  return password ? /[A-Z]/.test(password) : false;
};

export const hasLowerCase = (password: string): boolean | '' => {
  return password ? /[a-z]/.test(password) : false;
};

export const hasMinimalLength = (password: string, passwordLength: number): boolean => {
  return password ? password.length >= passwordLength : false;
};

export const hasNumber = (password: string): boolean => {
  return password ? /[0-9]/.test(password) : false;
};
