import React, { PureComponent, ReactNode } from 'react';
import { NavigationScreenProps } from 'react-navigation';
import { NetworkStatus } from 'apollo-client';

import AppPage from '../../../components/AppPage';
import BorderedView from '../../../components/BorderedView';
import BottomForm from '../../../components/BottomForm';
import Holding from '../../../components/Holding';
import SignUpIdentityForm from '../../../components/SignUpIdentityForm';
import Stepper from '../../../components/Stepper';
import Title from '../../../components/Title';
import { STATUS } from '../../../components/Stepper';
import { getUserSignUpParams } from '../../../services/navigation/params';
import I18n from '../../../lib/i18n';
import { IDistance } from '../../../types/common';
import { IHolding } from '../../../types/holdingById';

export interface IProps extends NavigationScreenProps {
  distance?: IDistance;
  holding?: IHolding;
  isLoading?: boolean;
  networkStatus: NetworkStatus;
}

class Identity extends PureComponent<IProps> {
  public componentDidUpdate(): void {
    const { holding, isLoading, navigation, networkStatus } = this.props;
    const { badgeNumber, serialNumber, lastName, email } = getUserSignUpParams(
      this.props.navigation
    );
    if (!isLoading && !holding && networkStatus === NetworkStatus.ready) {
      navigation.navigate('signUpNearHoldings', { badgeNumber, serialNumber, lastName, email });
    }
  }
  public render(): ReactNode {
    const { distance, holding } = this.props;
    const { badgeNumber, serialNumber, lastName } = getUserSignUpParams(this.props.navigation);

    return (
      <AppPage hasBlackHeader>
        <BottomForm>
          <Stepper currentStepNumber={2} currentStepStatus={STATUS.ACTIVE} />
          <Title text={I18n.t('signUp.identity.title')} />
          <BorderedView>
            <Holding holding={holding} distance={distance} />
          </BorderedView>
          <SignUpIdentityForm
            holdingId={(holding && holding.id) || ''}
            badgeNumber={badgeNumber || serialNumber}
            lastName={lastName}
          />
        </BottomForm>
      </AppPage>
    );
  }
}

export default Identity;
