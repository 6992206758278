import React, { PureComponent, ReactNode } from 'react';
import { Text } from 'react-native';
import { createIconSetFromFontello } from 'react-native-vector-icons';

// tslint:disable-next-line:no-var-requires
const fontelloConfig = require('../../../config/fonts/config.json');
const Fontello = createIconSetFromFontello(fontelloConfig);

export interface IProps {
  color?: string;
  name: string;
  size?: number;
}

class FontelloIcon extends PureComponent<IProps> {
  public render(): ReactNode {
    const { color, name, size } = this.props;

    return (
      <Text style={{ fontSize: size, color }}>
        <Fontello name={name} />
      </Text>
    );
  }
}

export default FontelloIcon;
