import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';

import { IHoldingSetting } from '../../types/common';

const getHoldingSettingById = loader('../../queries/getHoldingSettingById.gql');

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  holdingSetting: IHoldingSetting | undefined;
}

export interface IWithIdHoldingProps {
  idHoldingSetting?: string;
}

export interface IWithImportationTypeProps {
  importationType?: string;
}

export interface IWithHoldingSetting {
  holdingSetting: IHoldingSetting | undefined;
}

export default graphql(getHoldingSettingById, {
  options: (props: IWithIdHoldingProps): QueryOpts => ({
    variables: {
      idHoldingSetting: props.idHoldingSetting,
    },
  }),
  props: (props: OptionProps<IWithIdHoldingProps>): IGraphQLProps => {
    const holdingSetting = get(props, 'data.settings');

    return {
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      holdingSetting,
    };
  },
});
