import { RouterState } from 'connected-react-router';
import { createBrowserHistory, Location } from 'history';
import { NavigationParams } from 'react-navigation';
import { Reducer } from 'redux';

import { IAppState } from './reducer.web';

export const history = createBrowserHistory();

export type IRouterState = RouterState & { state?: NavigationParams } | null;

export const initialState: IRouterState = null;

const routerReducer: Reducer<IRouterState> = (): IRouterState => null;

export const selectLocation = (state: IAppState): Location => {
  if (!state.router) {
    return {
      hash: '',
      key: '',
      pathname: '/',
      search: '',
      state: {},
    };
  }

  return {
    ...state.router.location,
    state: {},
  };
};

export const isTabActive = (state: IAppState, ownProps: { routeName: string }): boolean => {
  if (!state.router) {
    return false;
  }

  const url = state.router.location.pathname;

  return url === ownProps.routeName || url.indexOf(`${ownProps.routeName}`) === 0;
};

export default routerReducer;
