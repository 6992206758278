import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import theme from '../../theme';
import { IDistance, IHolding } from '../../types/common';
import Holding from '../Holding';
import Touchable from '../Touchable';

export interface IProps {
  distance?: IDistance;
  holding: Pick<IHolding, 'address' | 'foodi' | 'id' | 'name' | 'isBadgeRequired'>;
  onHoldingPress: (
    isHoldingAvailable: boolean,
    holdingId: string,
    holdingName: string,
    isBadgeRequired: boolean | undefined
  ) => void;
  style?: StyleProp<ViewStyle>;
}

class TouchableHolding extends PureComponent<IProps> {
  public render(): ReactNode {
    const { style } = this.props;

    return (
      <Touchable
        testID="TouchableHolding"
        style={[styles.container, style]}
        onPress={this.onItemPress}
        useOpacity
      >
        <Holding holding={this.props.holding} distance={this.props.distance} />
      </Touchable>
    );
  }

  private onItemPress = (): void => {
    this.props.onHoldingPress(
      this.props.holding.foodi,
      this.props.holding.id,
      this.props.holding.name,
      this.props.holding.isBadgeRequired
    );
  };
}

export interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    paddingVertical: theme.margins.unit,
  },
});

export default TouchableHolding;
