import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import Touchable from '../../../components/Touchable';
import I18n from '../../../lib/i18n';
import { formatCreationDate } from '../../../services/offersFormatter';
import { orderStateColor, orderStateTranslationKey } from '../../../services/orderStateTransformer';
import theme from '../../../theme';
import { getOrdersFragment_edges_node_Order as IOrder } from '../../../types/clickandcollect/getOrdersFragment';

import Icon from '../../Icon';

import { BORDER_WIDTH } from './styles';

export interface IProps {
  onPress: () => void;
  order: IOrder;
  testID?: string;
}

const ICON_SIZE = 16;

class OrderItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { order, onPress, testID } = this.props;
    const stateColor = orderStateColor(order.state);
    const withdrawalDate = order?.withdrawRange?.split('/')[1];

    return (
      <Touchable style={styles.container} onPress={onPress} testID={testID}>
        <View style={[styles.border, { backgroundColor: stateColor }]} />
        <View style={styles.detailsContainer}>
          <View style={styles.orderDetails}>
            <Text style={styles.orderId}>{`${I18n.t('dashboard.eat.order')} #${
              order.idWithdrawal
            }`}</Text>
            <Text style={styles.text} numberOfLines={1}>
              {get(order, 'offerTemplateName')}
            </Text>
            <Text style={styles.date} numberOfLines={1}>
              {formatCreationDate(withdrawalDate || order.created)}
            </Text>
          </View>
          <View style={styles.stateContainer}>
            <Text style={[styles.name, { color: stateColor }]}>
              {I18n.t(orderStateTranslationKey(order.state))}
            </Text>
            <Icon name="chevronRight" size={ICON_SIZE} tintColor={stateColor} />
          </View>
        </View>
      </Touchable>
    );
  }
}

export interface IStyle {
  border: ViewStyle;
  container: ViewStyle;
  date: TextStyle;
  detailsContainer: ViewStyle;
  name: TextStyle;
  orderDetails: ViewStyle;
  orderId: TextStyle;
  stateContainer: ViewStyle;
  text: TextStyle;
}

const BORDER_RADIUS = 4;

const styles = StyleSheet.create<IStyle>({
  border: {
    width: BORDER_WIDTH,
  },
  container: {
    ...theme.shadows.card,
    alignItems: 'stretch',
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  date: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
  },
  detailsContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.margins.cardUnit * 2,
  },
  name: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.deprecatedBlack,
    marginRight: theme.margins.cardUnit,
  },
  orderDetails: {
    flex: 1,
    marginRight: theme.margins.cardUnit * 2,
  },
  orderId: {
    ...theme.fonts.strongText,
    color: theme.colors.black,
  },
  stateContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    ...theme.fonts.strongText,
    color: theme.colors.grayMedium,
    marginVertical: theme.margins.cardUnit,
  },
});

export default OrderItem;
