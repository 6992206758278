import React, { PureComponent, ReactNode } from 'react';

import LoadingCard from '../../../../components/LoadingCard';
import VirtualTicketSwitchList from '../../../../components/VirtualTicketSwitchList';
import { WebPage } from '../../../../componentsWeb';
import I18n from '../../../../lib/i18n';

class VirtualTicket extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <WebPage title={I18n.t('dashboard.profile.virtualTicket.title')} canGoBack>
        <LoadingCard>
          <VirtualTicketSwitchList />
        </LoadingCard>
      </WebPage>
    );
  }
}

export default VirtualTicket;
