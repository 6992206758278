import { Location } from 'history';
import mapValues from 'lodash/mapValues';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { connect } from 'react-redux';
import { match, Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import withAuthorization, { withAnonymAuthorization } from '../../src/hoc/withAuthorization';
import withNavigation from '../../src/hoc/withNavigation';
import PagesWithoutNavigation from '../../src/pages';
import { IAppState } from '../../src/redux/reducer.web';
import { selectLocation } from '../../src/redux/router.web';

import WebPagesWithoutNavigation from './pages';

type IPage = Required<RouteProps>['component'];
type IPageOrPages = IPage | IPages;
interface IPages {
  [key: string]: IPageOrPages;
}

const mapWithNavigation = (ListOfPages: IPages): IPages =>
  mapValues<IPageOrPages, IPageOrPages>(
    ListOfPages,
    (PageOrPages: IPageOrPages): IPageOrPages =>
      typeof PageOrPages === 'object' ? mapWithNavigation(PageOrPages) : withNavigation(PageOrPages)
  );

const Pages = mapWithNavigation({
  ...PagesWithoutNavigation,
  ...WebPagesWithoutNavigation,
}) as typeof PagesWithoutNavigation & typeof WebPagesWithoutNavigation;

export interface IProps {
  location: Location;
}

class Scenes extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Switch location={this.props.location}>
        <Route exact path="/landing" component={Pages.Landing} />
        <Route exact path="/authentication-callback" component={Pages.AuthenticationCallback} />
        <Route path="/create-account" component={SignUp} />
        <Route path="/signin" component={Pages.SignIn} />
        <Route path="/loading" component={Pages.Loading} />
        <Route path="/cat" component={Pages.Dashboard.TableService.TemporaryCode} />
        <Route path="/Richelieu" component={Pages.Dashboard.TableService.TemporaryCode} />
        <Route path="/richelieu" component={Pages.Dashboard.TableService.TemporaryCode} />
        <Route path="/table-service/" component={AnonymTableService} />
        <Route component={Dashboard} />
      </Switch>
    );
  }
}

const SignUp = ({ match: { url } }: { match: match<{}> }): ReactElement<Switch> => (
  <Switch>
    <Route exact path={`${url}/account-activated`} component={Pages.AccountActivated} />
    <Route exact path={`${url}/confirmEmailLead`} component={Pages.ConfirmEmailLead} />
    <Route exact path={`${url}/email-to-validate`} component={Pages.EmailToValidatePage} />
    <Route
      exact
      path={`${url}/email-not-activated/:token`}
      component={Pages.SignUp.EmailNotActivated}
    />
    <Route exact path={`${url}/geolocation`} component={Pages.GeolocationPage} />
    <Route exact path={`${url}/holdings`} component={Pages.HoldingChoicePage} />
    <Route exact path={`${url}/holding-not-available`} component={Pages.HoldingNotAvailablePage} />
    <Route
      exact
      path={`${url}/:holdingId/guest-credentials`}
      component={Pages.GuestCredentialsPage}
    />
    <Route exact path={`${url}/:holdingId/user-login`} component={Pages.SignUp.CheckEmail} />
    <Route
      exact
      path={`${url}/:holdingId/existing-guest`}
      component={Pages.SignupExistingGuestPage}
    />
    <Route exact path={`${url}/:holdingId/user-password`} component={Pages.UserPasswordPage} />
  </Switch>
);
const AnonymTableService = withAnonymAuthorization(() => (
  <Switch>
    <Route
      exact
      path="/table-service/:offerId/tables"
      component={Pages.Dashboard.TableService.TableNumber}
    />
    <Route
      exact
      path="/table-service/:offerId/tables/:idTable"
      component={Pages.Dashboard.TableService.TableNumberConfirmation}
    />
    <Route
      exact
      path="/table-service/:offerId/tables/:idTable/offer-articles"
      component={Pages.Dashboard.TableService.OfferArticles}
    />
    <Route
      exact
      path="/table-service/:offerId/tables/:idTable/beverages"
      component={Pages.Dashboard.TableService.Beverages}
    />
    <Route
      exact
      path="/table-service/cart/:orderId"
      component={Pages.Dashboard.ClickAndCollect.CartSummary}
    />
    <Route
      exact
      path="/table-service/order/:orderId"
      component={Pages.Dashboard.ClickAndCollect.OrderDetails}
    />
  </Switch>
));

const Dashboard = withAuthorization(() => (
  <Switch>
    <Route exact path="/" component={(): ReactElement<Redirect> => <Redirect to="/home" />} />
    <Route exact path="/home" component={Pages.Dashboard.Home} />
    <Route
      path="/home/marketing-card/:marketingCardId"
      component={Pages.Dashboard.ModalMarketingCard}
    />
    <Route exact path="/restaurants" component={Pages.Dashboard.Eat} />
    <Route
      exact
      path="/restaurants/offers/:offerId"
      component={Pages.Dashboard.ClickAndCollect.OfferArticles}
    />
    <Route
      path="/restaurants/cartSummary/:orderId"
      component={Pages.Dashboard.ClickAndCollect.CartSummary}
    />
    <Route
      path="/restaurants/orders/:orderId"
      component={Pages.Dashboard.ClickAndCollect.OrderDetails}
    />
    <Route exact path="/restaurants/:posId" component={Pages.Dashboard.Menu} />
    <Route
      exact
      path="/restaurants/:posId/menu/:elementId"
      component={Pages.Dashboard.MenuElementDetails}
    />
    <Route exact path="/restaurants/:posId/contentPage/" component={Pages.Dashboard.ContentPage} />

    <Route exact path="/balance" component={Pages.Dashboard.UserBalance.Balance.WrappedComponent} />
    <Route
      exact
      path="/balance/other-amount"
      component={Pages.Dashboard.UserBalance.TopUp.TopUpOtherAmount}
    />
    <Route path="/balance/payment-callback" component={Pages.Dashboard.UserBalance.TopUpCallback} />
    <Route
      path="/balance/:transactionId"
      component={Pages.Dashboard.UserBalance.TransactionDetails}
    />
    <Route exact path="/profile" component={Pages.Dashboard.Profile.UserProfile} />
    <Route path="/profile/notifications" component={Pages.Dashboard.Profile.Notifications} />
    <Route path="/profile/languages" component={Pages.Dashboard.Profile.Languages} />
    <Route path="/profile/my-informations" component={Pages.Dashboard.Profile.MyInformations} />
    <Route path="/profile/legal-info" component={Pages.Dashboard.Profile.LegalInfo} />
  </Switch>
));

export default connect((state: IAppState) => ({ location: selectLocation(state) }))(Scenes);
