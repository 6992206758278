import { IOfferTemplate } from '../types/common';
import { ArticleFamily, OfferTemplateSalesType } from '../types/clickandcollect/globalTypes';
import { getOffer_offer_Offer as IOffer } from '../types/clickandcollect/getOffer';
import { getTableServiceOffer_offer_Offer as ITableOffer } from '../types/tableService/getTableServiceOffer';
import { getOfferSlots_offer_Offer } from '../types/clickandcollect/getOfferSlots';
import { getOfferTemplates_list_edges_node_OfferTemplate } from '../types/clickandcollect/getOfferTemplates';

export const hasSelectableSlot = ({ slots }: getOfferSlots_offer_Offer): boolean =>
  !!slots && slots.some(slot => slot.selectableAt);

export const isFamilyAvailable = (
  { offerItems }: IOffer | ITableOffer,
  articleFamilies: ArticleFamily[]
): boolean =>
  offerItems.some(
    ({ quantityOverall, inheritedFamily }) =>
      quantityOverall > 0 && articleFamilies.includes(inheritedFamily!)
  );

export const skipBeverage = (offer: IOffer | ITableOffer): boolean =>
  !isFamilyAvailable(offer, [ArticleFamily.BEVERAGE]);

export const hasAvailableOffer = ({ nextOrderableOffer }: IOfferTemplate): boolean =>
  !!nextOrderableOffer && !!nextOrderableOffer.available;

export const hasProductAvailable = ({ nextOrderableOffer }: IOfferTemplate): boolean =>
  !!nextOrderableOffer &&
  nextOrderableOffer.offerItems.some(({ quantityRemaining }) => quantityRemaining > 0);

function hasClickAndCollectRespectedMealHeartRule(offerTemplate: IOfferTemplate) {
  const offerTemplateClickAndCollect = offerTemplate as getOfferTemplates_list_edges_node_OfferTemplate;
  // Bundle doesn't care about the MealHeartRule. So instead we check if the offer is available
  // to make sure that it has all the products needed for the steps
  if (offerTemplateClickAndCollect.salesType === OfferTemplateSalesType.BUNDLE) {
    return (
      !!offerTemplateClickAndCollect.nextOrderableOffer &&
      offerTemplateClickAndCollect.nextOrderableOffer.available
    );
  }

  return (
    !!offerTemplateClickAndCollect.nextOrderableOffer &&
    offerTemplateClickAndCollect.nextOrderableOffer.isMealHeartRuleFullfilled
  );
}

export const isSlotsFullyBooked = ({ nextOrderableOffer }: IOfferTemplate): boolean =>
  !!nextOrderableOffer && !!nextOrderableOffer.fullyBooked;

export const areNextOffersSlotsFullyBooked = ({
  nextOrderableOffers,
}: getOfferTemplates_list_edges_node_OfferTemplate): boolean =>
  !!nextOrderableOffers &&
  !nextOrderableOffers.some(eachNextOrdableOffer => eachNextOrdableOffer.fullyBooked === false && isOrderRangeValid(eachNextOrdableOffer.orderRange));

export const isMealHeartOrderAvailable = ({ nextOrderableOffer }: IOfferTemplate): boolean =>
  !!nextOrderableOffer && !!nextOrderableOffer.mealHeartOrderAvailable;

export const isOrderAvailable = (offerTemplate: IOfferTemplate): boolean =>
  hasAvailableOffer(offerTemplate) &&
  hasProductAvailable(offerTemplate) &&
  !isSlotsFullyBooked(offerTemplate) &&
  hasClickAndCollectRespectedMealHeartRule(offerTemplate) &&
  isMealHeartOrderAvailable(offerTemplate);

export const isOrderRangeValid = (orderRange: string): boolean => {
  const [beginDate, endDate] = orderRange.split('/');
  const currentDate = new Date().toISOString();

  return (
    currentDate >= new Date(beginDate).toISOString() &&
    currentDate <= new Date(endDate).toISOString()
  );
};

/*
  convertDateRangeToLocale

  Returns either the begin or the end date of the dateRange
  converted into a string with the formated date or a string
  with the formated date and time

  Time strings examples:
   dateRange: 2022-01-11T07:30:00.000+00:00/2022-01-11T18:00:00.000+00:00
   beginDate: 2022-01-11T07:30:00.000+00:00
   endDate: 2022-01-11T18:00:00.000+00:00

   beginDate toLocaleDateString(): 11/01/2022
   beginDate toISOString():  2022-01-13T23:01:00.000Z
*/
export const convertDateRangeToLocale = ({
  dateRange,
  isBeginDate = true,
  withTime = false,
}: {
  dateRange: string;
  isBeginDate?: boolean;
  withTime?: boolean;
}) => {
  const [beginDate, endDate] = dateRange.split('/');
  const targetDate = isBeginDate ? new Date(beginDate) : new Date(endDate);

  return withTime ? targetDate.toISOString() : targetDate.toLocaleDateString();
};

/*
    With the addition of "days in advance" offers we can no longer only use the field
    "published" to check if the offer is available or not, we also need to check its order range
     - For normal offer (not days in advance ones) the order range should start and end in the same day;
     - For "days in advance" offers the order range will start when the offer is created and end in 
       the withdraw day;
    So in order to know if the offer is available we need to compare the current day
    with the order range of each offer
  */
const hasAvailableNextOffers = ({
  nextOrderableOffers,
}: getOfferTemplates_list_edges_node_OfferTemplate): boolean =>
  !!nextOrderableOffers &&
  nextOrderableOffers.some(
    ({ published, orderRange }) => published && isOrderRangeValid(orderRange)
  );

export const isNextOffersAvailable = (
  offerTemplate: getOfferTemplates_list_edges_node_OfferTemplate
): boolean =>
  hasAvailableNextOffers(offerTemplate) &&
  hasProductAvailable(offerTemplate) &&
  !areNextOffersSlotsFullyBooked(offerTemplate) &&
  hasClickAndCollectRespectedMealHeartRule(offerTemplate) &&
  isMealHeartOrderAvailable(offerTemplate);

export const convertDateRangeToUTCMiliseconds = ({
  dateRange,
  isBeginDate = true,
}: {
  dateRange: string;
  isBeginDate?: boolean;
}) => {
  const [beginDate, endDate] = dateRange.split('/');
  const targetDate = isBeginDate
    ? new Date(beginDate).toISOString().split('T')[0]
    : new Date(endDate).toISOString().split('T')[0];

  return new Date(targetDate).getTime();
};
