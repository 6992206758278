const baseColors = {
  backgroundGray: '#F1F3F4',
  backgroundGrayArticles: '#F9FAFB',
  blue: '#3B99FC', // TODO : Rename with the Click&Collect Style Guide
  blueGray: '#E9EBEF',
  blueLight: '#CEE5FE',
  blueMedium: '#2363C0',
  darkGrey: '#999999',
  deprecatedBlack: '#242424',
  foodiBlack: '#090909',
  gray: '#6B6B6B',
  gray3: '#666666',
  grayLightAlternative: '#f4f4f5',
  grayExtraLight: '#e8ebef',
  grayLight: '#DEDEDE',
  grayLightMedium: '#B9B9B9',
  grayMedium: '#7B7B7B', // TODO : Rename with the Click&Collect Style Guide
  grayDisabled: '#CCCCCC',
  grey: '#DDDDDD',
  green: '#36A63B',
  greenBlue: '#DFF2EE',
  greenLighter: '#4EAC63',
  greenLight: '#A6D557',
  greenSmoothie: '#CDEBD4',
  greenDry: '#E9F2E7',
  middleGreen: '#509E58',
  orangeLight: '#F19953',
  red: '#E23223', // TODO : deprecated, replace red with styleguide's one
  redError: '#CD3C14',
  redLight: '#FD575D',
  white: '#FFFFFF',
  yellow: '#FAE160',
  yellowVivid: '#FFB800',
};

const otherColors = {
  active: baseColors.blue,
  background: baseColors.white,
  backgroundFooter: baseColors.greenBlue,
  backgroundQuantityFooter: baseColors.blueGray,
  backgroundTab: baseColors.backgroundGray,
  backgroundTabArticles: baseColors.backgroundGrayArticles,
  border: baseColors.grayLight,
  cardBrasserie: baseColors.orangeLight,
  cardCafeteria: baseColors.greenLight,
  cardSelf: baseColors.redLight,
  cardTakeAway: baseColors.yellow,
  disabled: baseColors.grayLight,
  emptyContent: baseColors.gray,
  error: baseColors.red,
  errorMessage: baseColors.red,
  placeholder: baseColors.gray,
  success: baseColors.green,
  textBlack: baseColors.deprecatedBlack,
  textGray: baseColors.grayMedium,
  textRed: baseColors.red,
  textWhite: baseColors.white,
  version: baseColors.grayLight,
  daySelected: baseColors.greenLighter,
};

const styleguideColors = {
  black: '#383838',
  errorDanger: '#F06C6C',
  info: '#48A0FC',
  overlay: 'rgba(56,56,56,0.5)',
  validate: '#61D57B',
  warning: '#FFB800',
  inactive: '#D3D3D3',
};

export default {
  ...baseColors,
  ...otherColors,
  ...styleguideColors,
};
