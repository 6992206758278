import { ApolloQueryResult } from 'apollo-client';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { graphql } from 'react-apollo/graphql';
import { QueryOpts } from 'react-apollo/types';
import { OperationVariables, OptionProps } from 'react-apollo/types';
import { compose, withProps } from 'recompose';

import { getBookingOrders as IOrdersResult } from '../../types/bookingservice/getBookingOrders';
import { getBookingOrders_active_edges_node_Order as IOrder } from '../../types/bookingservice/getBookingOrders';
import moment from 'moment';

const getBookingOrders = loader('../../queries/bookingservice/getBookingOrders.gql');
const getUserInfo = loader('../../queries/getUserInfo.gql');

interface IComponentProps {
  isLoading?: boolean;
}

interface IWithIdGuest {
  idGuest?: string;
  isGuestLoading?: boolean;
}

export interface IGraphQLProps {
  hasError: boolean;
  isOrdersLoading: boolean;
  orders: IOrder[];
  refetchBookingOrders: (variables?: OperationVariables) => Promise<ApolloQueryResult<IOrdersResult>>;
}

interface IWithIsLoading {
  isLoading: boolean;
}

const withIsLoading = (props: IGraphQLProps & IComponentProps & IWithIdGuest): IWithIsLoading => ({
  isLoading: props.isLoading || props.isOrdersLoading || !!props.isGuestLoading,
});

const withBookingOrders = <T extends IComponentProps = {}>(
  Component: React.ComponentType<T & IGraphQLProps & IWithIsLoading>
): React.ComponentType<T> => {
  class ComponentWithBookingOrders extends PureComponent<T & IGraphQLProps & IWithIsLoading> {
    public render(): ReactNode {
      return <Component {...this.props} />;
    }
  }

  return compose<T & IGraphQLProps & IWithIsLoading, T & IComponentProps>(
    graphql(getUserInfo, {
      props: (): IWithIdGuest => ({
      }),
    }),
    graphql(getBookingOrders, {
      options: (): QueryOpts => ({
        fetchPolicy: 'network-only',
        variables: {
          idGuest: '',
          fromDate: moment()
            .startOf('day')
            .toISOString(),
        },
      }),
      props: (props: OptionProps<{}>): IGraphQLProps => ({
        hasError: !!get(props, 'data.error'),
        isOrdersLoading: get(props, 'data.loading'),
        orders: [...(get(props, 'data.active.edges') || [])].map(o => o.node),
        refetchBookingOrders: get(props, 'data.refetch'),
      }),
    }),
    withProps(withIsLoading)
  )(ComponentWithBookingOrders);
};

export default withBookingOrders;
