import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';

import env, { IConfig } from '../environment';
import introspectionQueryResultData from '../types/fragmentTypes';

import errorHandler from './errorHandler';
import links from './links';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
export const cache: InMemoryCache = new InMemoryCache({ fragmentMatcher });

const initializeApolloClient = (config: IConfig = env): void => {
  const httpLink = new HttpLink({
    uri: config.API_GRAPHQL_ENDPOINT,
  });

  const errorLink = onError(errorHandler.onError);

  apolloClient = new ApolloClient<NormalizedCacheObject>({
    // @ts-ignore
    cache,
    // @ts-ignore
    link: ApolloLink.from([errorLink, ...links, httpLink]),
  });
};

const getApolloClient = (): ApolloClient<NormalizedCacheObject> => apolloClient;

export default { getApolloClient, initializeApolloClient };
