import { loader } from 'graphql.macro';
import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { withCookies } from 'react-cookie';
import { NavigationInjectedProps, NavigationParams } from 'react-navigation';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import I18n from '../../lib/i18n';
import { isWeb } from '../../lib/responsive';
import {
  selectOrderToken,
  selectTokenExpirationDate,
} from '../../redux/orderAuthentification/selectors';
import { IAppState } from '../../redux/reducer';
import store from '../../redux/store';
import navigationService from '../../services/navigation/navigation';
import Toaster from '../../services/toaster';
import withNavigation from '../withNavigation';
import withUserTracking from '../withUserTracking';

interface IMapStateToProps {
  token?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectOrderToken(state),
});

interface IProps extends NavigationInjectedProps {
  token?: string;
}

interface IGraphQLProps {
  invalidTokenOrder?: boolean;
  idOrder?: string;
  orderState?: string;
  idOffer?: string;
  loading?: boolean;
}

const anonymCheckOrder = loader('../../queries/tableservice/anonymCheckOrder.gql');

const withOrderStateCheck = graphql(anonymCheckOrder, {
  options: (): QueryOpts => ({
    fetchPolicy: 'network-only',
    variables: {},
  }),
  props: (props: OptionProps<IProps>): IGraphQLProps => {
    if (!props.data || props.data.loading) {
      return { invalidTokenOrder: undefined, loading: true };
    } else {
      return {
        invalidTokenOrder: get(props, 'data.list.totalCount', 0) != 1,
        idOrder: get(props, 'data.list.edges[0].node.id'),
        orderState: get(props, 'data.list.edges[0].node.state'),
        idOffer: get(props, 'data.list.edges[0].node.offer.id'),
        loading: false,
      };
    }
  },
});

const withAnonymAuthorization = <T extends {} = {}>(
  Component: React.ComponentType<T>
): React.ComponentType<T> => {
  class PrivateComponent extends PureComponent<T & IProps & IGraphQLProps & IMapStateToProps> {
    public render(): ReactNode {
      const navigationState = this.props.navigation.state as {
        params: NavigationParams;
        routeName?: string;
      };
      const route = navigationService.getRoute(navigationState.params, navigationState.routeName);
      const expirationToken = selectTokenExpirationDate(store.getState());
      const {
        idOrder,
        orderState,
        invalidTokenOrder,
        token: orderToken,
        loading,
        navigation: { navigate },
      } = this.props;

      const redirect = (path: string, errorCode?: string) => {
        errorCode && Toaster.showError(I18n.t(errorCode));
        setImmediate(() => navigate(path));
        return null;
      };

      if (!orderToken) {
        return redirect('/cat', `dashboard.eat.error.expired`);
      } else if (expirationToken! < new Date().toISOString()) {
        return redirect('/cat', `dashboard.eat.error.expired`);
      }
      if (!loading) {
        if (invalidTokenOrder) {
          return redirect('/cat', `dashboard.eat.error.expired`);
        } else if (orderState == 'CART' && route && route.includes('/table-service/order/')) {
          let error = undefined;
          if (route.includes('?idRefill=')) {
            error = `dashboard.eat.orderState.tableService.Cart`;
          }
          return redirect(`/table-service/cart/${idOrder}`, error);
        } else if (orderState !== 'CART' && route && !route.includes('/table-service/order/')) {
          return redirect(`/table-service/order/${idOrder}`);
        }
      }

      return <Component {...this.props} />;
    }
  }

  return compose<T & IProps, T>(
    connect(mapStateToProps),
    withNavigation,
    withUserTracking,
    /* tslint:disable */
    isWeb() ? withCookies : (Component: ReactNode): ReactNode => Component
  )(PrivateComponent);
};

export default compose<IMapStateToProps & IGraphQLProps, any>(
  withOrderStateCheck,
  withAnonymAuthorization
);
