import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
  StyleProp,
} from 'react-native';
import {
  getBookingTemplateOffer_pos_Pos as IPos,
  getBookingTemplateOffer_pos_Pos_bookingOfferTemplate_nextOrderableOffers as BookingOffer,
} from '../../types/bookingservice/getBookingTemplateOffer';
import theme from '../../theme';
import { uuidToId } from '../../services/base64Id';
import { RFValue } from 'react-native-responsive-fontsize';

export interface IProps {
  offer: BookingOffer;
  offerIndex: number;
  orders: any;
  activeIndex: number;
  editMode: boolean;
  pos: IPos;
}

class BookingHeaderInfo extends PureComponent<IProps> {
  public render(): ReactNode {
    const { offerIndex, orders, offer, activeIndex, editMode, pos } = this.props;
    const now = moment(new Date());
    const offerWithdrawEnd = moment(offer.withdrawRange.split('/')[1]);

    // current offer for selected card
    // current order for selected card (may not exist)
    const currentOrder: any = this.filterOrders(orders, offer);

    const sameDayOrder = this.filterOrdersByHolding(orders, offer, pos);

    // checks if current offer is closed
    const closedOffer = !currentOrder && (offer.isFullyBooked || now >= offerWithdrawEnd);

    // cards info (weekday, day and month)
    const [withdrawStart] = offer.withdrawRange.split('/');
    const withdrawStartDate = moment(new Date(withdrawStart));
    const dayOfWeek = withdrawStartDate.format('ddd').replace('.', '');
    const dayOfMonth = withdrawStartDate.format('DD');
    const month = withdrawStartDate.format('MMMM');

    // cards styles (depending on the state of the offer and order)
    let calendarCard: StyleProp<ViewStyle> = styles.calendarCard;
    let weekDay: StyleProp<TextStyle> = styles.weekDay;
    let monthDay: StyleProp<TextStyle> = styles.monthDay;
    let monthText: StyleProp<TextStyle> = styles.monthText;
    let icon: StyleProp<ImageStyle> = styles.icon;
    let orderYellowDot: StyleProp<ViewStyle> = styles.orderYellowDot;

    // style of unselected cards (activeIndex !== offerIndex)
    // style of selected card with an order (currentOrder)
    // style of selected card with an unpublished offer or with full slots (!currentOffer.published..)
    // style of selected card without an order (slots)
    if (activeIndex !== offerIndex) {
      calendarCard = [calendarCard, { backgroundColor: 'transparent' }];
      weekDay = [weekDay, { color: theme.colors.black }];
      monthDay = [monthDay, { color: theme.colors.black }];
      monthText = [monthText, { color: 'transparent' }];
      icon = [icon, { tintColor: 'transparent' }];
      // when an unselected card have an order, we insert the yellow dot style into the card
      if (currentOrder && !closedOffer) {
        orderYellowDot = [orderYellowDot, { backgroundColor: '#FFD86A' }];
      }
    } else if (currentOrder && !closedOffer) {
      calendarCard = editMode
        ? [calendarCard, { backgroundColor: theme.colors.validate }]
        : [calendarCard, { backgroundColor: '#FED35B' }];
    } else if (!offer.published || !!offer.isFullyBooked || closedOffer || sameDayOrder) {
      calendarCard = [calendarCard, { backgroundColor: theme.colors.inactive }];
    } else {
      calendarCard = [calendarCard, { backgroundColor: theme.colors.validate }];
    }

    return (
      <View>
        <View style={styles.titleContainer}>
          <Text style={monthText}>{month.toUpperCase()}</Text>
        </View>
        <View style={styles.calendarContainer}>
          <View style={calendarCard}>
            <Image style={icon} source={theme.images.check} />
            <Text style={weekDay}>{dayOfWeek.toUpperCase()}</Text>
            <Text style={monthDay}>{dayOfMonth}</Text>
          </View>
          <View style={orderYellowDot} />
        </View>
      </View>
    );
  }

  private getStartEndDatesFromRange(range: string): Date[] {
    const [withdrawStart, withdrawEnd] = range.split('/');
    return [new Date(withdrawStart), new Date(withdrawEnd)];
  }

  // get booking order with the offer info (id and withdrawRange)
  private filterOrders(orders: any, activeOffer: BookingOffer): [] | null {
    const offerId = this.decodeOfferId(activeOffer.id.toString());
    const booking =
      orders &&
      orders.find((obj: any) => {
        return (
          this.getStartEndDatesFromRange(obj.node.withdrawRange)[0].getDate() ===
            this.getStartEndDatesFromRange(activeOffer.withdrawRange)[0].getDate() &&
          obj.node.idOffer.toString() === offerId
        );
      });
    return booking;
  }

  private filterOrdersByHolding(nodes: any, activeOffer: BookingOffer, pos: IPos): any {
    const booking =
      nodes &&
      nodes.find((obj: any) => {
        return (
          this.getStartEndDatesFromRange(obj.node.withdrawRange)[0].getDate() ===
            this.getStartEndDatesFromRange(activeOffer.withdrawRange)[0].getDate() &&
          obj.node.bookingOffer &&
          obj.node.bookingOffer.bookingOfferTemplate.pos.id !== pos.id &&
          obj.node.bookingOffer.bookingOfferTemplate.pos.zone.idHolding === pos.zone.idHolding
        );
      });
    return booking;
  }

  private decodeOfferId(offerId: string) {
    let finalId: string | undefined = '';
    try {
      finalId = uuidToId(offerId);
    } catch (error) {
      finalId = offerId.split(':')[1];
    }
    return finalId;
  }
}

interface IStyle {
  titleContainer: ViewStyle;
  calendarContainer: ViewStyle;
  calendarCard: ViewStyle;
  weekDay: TextStyle;
  monthDay: TextStyle;
  monthText: TextStyle;
  icon: ImageStyle;
  orderYellowDot: ViewStyle;
}

const CARD_HEIGHT = 118;
const CARD_WIDTH = 99;

const styles = StyleSheet.create<IStyle>({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2 * theme.margins.cardUnit,
  },
  calendarContainer: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 2 * theme.margins.unit,
  },
  weekDay: {
    marginTop: RFValue(theme.margins.cardUnit),
    ...theme.fonts.button,
    lineHeight: RFValue(theme.fonts.button.lineHeight ? theme.fonts.button.lineHeight : 20),
    fontSize: RFValue(theme.fonts.button.fontSize ? theme.fonts.button.fontSize : 16),
    color: theme.colors.white,
  },
  monthDay: {
    marginTop: RFValue(theme.margins.cardUnit),
    ...theme.fonts.monthDay,
    lineHeight: RFValue(theme.fonts.monthDay.lineHeight ? theme.fonts.monthDay.lineHeight : 50),
    fontSize: RFValue(theme.fonts.monthDay.fontSize ? theme.fonts.monthDay.fontSize : 40),
    color: theme.colors.white,
  },
  calendarCard: {
    borderRadius: RFValue(8),
    width: RFValue(CARD_WIDTH),
    height: RFValue(CARD_HEIGHT),
    alignItems: 'center',
  },
  monthText: {
    marginTop: 2 * theme.margins.cardUnit,
    ...theme.fonts.regularText,
    color: theme.colors.black,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  icon: {
    height: RFValue(6),
    width: RFValue(8),
    alignSelf: 'flex-end',
    tintColor: theme.colors.white,
    marginRight: 1.5 * theme.margins.cardUnit,
    marginTop: 1.5 * theme.margins.cardUnit,
  },
  orderYellowDot: {
    height: RFValue(10),
    width: RFValue(10),
    backgroundColor: 'transparent',
    borderRadius: RFValue(5),
  },
});

export default BookingHeaderInfo;
