import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { FetchResult } from 'react-apollo';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';
import { IUpdateVirtualTicketResult } from '../../types/updateVirtualTicket';

import VirtualTicketSwitchList, { IProps } from './VirtualTicketSwitchList.component';

const getUserInfo = loader('../../queries/getUserInfo.gql');
const updateVirtualTicket = loader('../../queries/updateVirtualTicket.gql');

export interface IMapStateToProps {
  token?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

export interface IGraphQLProps {
  isLoading?: boolean;
  idGuest: string;
  virtualTicket: boolean;
}

type IUpdateVirtualTicketMutation = (value: boolean) => Promise<FetchResult>;

export type IConnectedProps = IMapStateToProps & IGraphQLProps & WithApolloClient<{}>;

const updateVirtualTicketHandler = (props: IConnectedProps): IUpdateVirtualTicketMutation => async (
  value: boolean
): Promise<FetchResult> => {
  return props.client.mutate<IUpdateVirtualTicketResult>({
    mutation: updateVirtualTicket,
    variables: {
      input: {
        virtualTicket: value,
      },
    },
  });
};

export default compose<IProps, {}>(
  connect(mapStateToProps),
  graphql(getUserInfo, {
    options: (): QueryOpts => ({
      fetchPolicy: 'network-only',
    }),
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      isLoading: props.data!.loading,
      idGuest: get(props, 'data.getUser.guests.edges[0].node.id'),
      virtualTicket: get(props, 'data.getUser.guests.edges[0].node.virtualTicket') || false,
    }),
  }),
  withApollo,
  withHandlers({ updateVirtualTicket: updateVirtualTicketHandler })
)(VirtualTicketSwitchList);
