import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import PdfViewer from '../../../components/PdfViewer';
import { NavigationScreenProps, NavigationStackScreenOptions } from 'react-navigation';

import AppPage from '../../../components/AppPage';
import HeaderTitle from '../../../components/HeaderTitle';
import ModalHeader from '../../../components/ModalHeader';
import { getConfig } from '../../../environment';
import i18n from '../../../lib/i18n';

class TermsOfService extends PureComponent<NavigationScreenProps> {
  public static navigationOptions: NavigationStackScreenOptions = {
    gesturesEnabled: false,
  };

  public render(): ReactNode {
    const env = getConfig();

    return (
      <AppPage
        noPadding
        headerComponent={
          <ModalHeader onClose={this.requestClose} style={styles.headerContainer}>
            <HeaderTitle text={i18n.t('signUp.termsOfService.title')} />
          </ModalHeader>
        }
        isModal
      >
        <PdfViewer
          url= {env.TOS_URL}
        />
      </AppPage>
    );
  }

  private requestClose = (): void => {
    this.props.navigation.goBack();
  };
}

interface IStyle {
  headerContainer: ViewStyle;
}

const MODAL_HEADER_HEIGHT = 49;

const styles = StyleSheet.create<IStyle>({
  headerContainer: {
    height: MODAL_HEADER_HEIGHT,
  }
});

export default TermsOfService;
