
export enum CookieCategory {
  STRICTLY_NECESSARY_COOKIES = 'C0001',
  PERFORMANCE_COOKIES = 'C0002',
  FUNCTIONAL_COOKIES = 'C0003',
  TARGETING_COOKIES = 'C0004',
  SOCIAL_MEDIA_COOKIES = 'C0005',
  CAT = 'C0009'
}

export enum CookieConsent {
  CONSENT_GIVEN = 1,
  CONSENT_NOT_GIVEN = 0,
  CONSENT_NOT_YET_GATHERED = -1 // or sdk not initialized
}

export const startSDK = (): void => { };
export const startListenForConsentChanges = (): void => { };
export const stopListeningForConsentChanges = (): void => { };
export const showOTBanner = (): void => { };
export const getAndStoreConsentStatusForCategory = (): void => { };

export default {
  startSDK,
  setLocale: (): void => { },
  showPreferenceCenterUI: async (): Promise<void> => { },
  showOTBanner,
  stopListeningForConsentChanges,
  getAndStoreConsentStatusForCategory
}
