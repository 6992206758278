import { NavigationInjectedProps, NavigationParams } from 'react-navigation';
import { withProps } from 'recompose';

import navigation from '../../services/navigation';

import {
  IWithElementDetailsNavigation,
  IWithElementDetailsParams,
} from './withMenuElementDetailsNavigation.d';

const navigateToMenuElementDetails = (
  ownProps: NavigationInjectedProps<NavigationParams>,
  params: IWithElementDetailsParams
): boolean => {
  const posId = params.posId;
  const elementId = params.elementId;

  const navigationElement = ownProps.navigation || navigation;

  return navigationElement.navigate('menuElementDetails', { posId, elementId });
};

const withMenuElementDetailsNavigationProps = (
  ownProps: NavigationInjectedProps<NavigationParams>
): IWithElementDetailsNavigation => ({
  navigateToMenuElementDetails: (params: IWithElementDetailsParams): boolean =>
    navigateToMenuElementDetails(ownProps, params),
});

export default withProps(withMenuElementDetailsNavigationProps);
