import { compose, withProps } from 'recompose';

import { getPosTypeTranslated } from '../../../../services/pointOfSaleMapper';
import { IPosType } from '../../../../types/common';

import PosCardType, { IProps } from './PosCardType.component';

interface IWithHeaderProps {
  text: string;
}

const withTypeProps = (ownProps: IExternalProps): IWithHeaderProps => ({
  text: getPosTypeTranslated(ownProps.posType),
});

export interface IExternalProps {
  posType: IPosType;
}
export type IConnectedProps = IWithHeaderProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withProps(withTypeProps))(PosCardType);
