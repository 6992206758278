import I18n from '../../lib/i18n';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import theme from '../../theme';
import LoadingView from '../LoadingView';
import { moderateScale } from '../../services/scaleService';
import { RFValue } from 'react-native-responsive-fontsize';

interface IComponentProps {
  isLoading: boolean;
}

export default class BookingFullContent extends PureComponent<IComponentProps> {
  public render(): ReactNode {
    const { isLoading } = this.props;
    if (!isLoading) {
      return (
        <>
          <View style={styles.container}>
            <View style={styles.cardManger}>
              <View style={styles.cardMangerBody}>
                <Text style={styles.cardTitle}>
                  {I18n.t('dashboard.eat.bookingService.modal.fullyBookedTitle')}
                </Text>
                <Text style={styles.cardDescription}>
                  {I18n.t('dashboard.eat.bookingService.modal.fullyBookedDescription')}
                </Text>
              </View>
            </View>
          </View>
        </>
      );
    }
    return <LoadingView isLoading={isLoading} />;
  }
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  cardManger: {
    width: '100%',
    alignItems: 'center',
  },
  cardMangerBody: {
    marginTop: 2 * theme.margins.unit,
    flexShrink: 1,
    width: '95%',
    height: '55%',
    justifyContent: 'center',
    borderRadius: moderateScale(11),
    backgroundColor: '#F0F0F0',
  },
  cardTitle: {
    color: theme.colors.deprecatedBlack,
    ...theme.fonts.regularText,
    fontSize: RFValue(18),
    lineHeight: RFValue(20),
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0,
    marginTop: theme.margins.cardUnit,
    marginBottom: theme.margins.cardUnit,
  },
  cardDescription: {
    ...theme.fonts.regularText,
    color: theme.colors.black,
    textAlign: 'center',
    fontSize: moderateScale(16),
    letterSpacing: 0,
    margin: theme.margins.cardUnit,
  },
});
