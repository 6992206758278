import { Formik, FormikActions } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { FetchResult } from 'react-apollo';
import { Keyboard } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { resetSignUp } from '../../redux/signUp/actions';
import store from '../../redux/store';

import I18n from '../../lib/i18n';
import Logger from '../../lib/logger';
import { validationPasswordFront } from '../../services/form/validationSchema';
import { IOptinCode } from '../../types/common';

import SignUpPasswordFormikForm, { IValues } from './SignUpPasswordFormikForm';

export interface IProps extends NavigationInjectedProps {
  email?: string;
  guestTokenId?: string;
  optins?: IOptinCode[];
  signUp: (
    email: string,
    password: string,
    guestTokenId: string,
    language: string,
    optins: IOptinCode[]
  ) => Promise<FetchResult>;
}

class SignUpPasswordForm extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        component={SignUpPasswordFormikForm}
        validationSchema={validationPasswordFront}
      />
    );
  }

  private handleSubmit = async (
    values: IValues,
    { setErrors, setSubmitting }: FormikActions<IValues>
  ): Promise<void> => {
    const { password, passwordConfirmation } = values;

    if (!password || !passwordConfirmation) {
      setSubmitting(false);

      return;
    }

    if (password !== passwordConfirmation) {
      setSubmitting(false);

      return setErrors({ passwordConfirmation: 'signUp.password.errorPasswordDifferent' });
    }

    if (!this.props.email || !this.props.guestTokenId || !this.props.optins) {
      setSubmitting(false);

      // TODO: Add setErrors
      return;
    }

    try {
      await this.props.signUp(
        this.props.email,
        password,
        this.props.guestTokenId,
        I18n.currentLocale(),
        this.props.optins
      );

      Keyboard.dismiss();
      this.props.navigation.navigate('signUpConfirmation');
    } catch (error) {
      store.dispatch(resetSignUp());
      Logger.error(error);
    } finally {
      setSubmitting(false);
    }
  };
}

export default SignUpPasswordForm;
