import debounce from 'lodash/debounce';
import React, { PureComponent, ReactNode } from 'react';
import { FetchResult } from 'react-apollo';
import { StyleSheet, ViewStyle } from 'react-native';

import { OPTINS_CODE } from '../../constants';
import I18n from '../../lib/i18n';
import { setAttributesToUa } from '../../lib/airship';
import { createOptinsDictionary, getOptinValueByCode } from '../../services/optins';
import theme from '../../theme';
import { IOptinCode, IOptinsDictionary, IUserOptin } from '../../types/common';
import LoadingView from '../LoadingView';
import Section from '../Section';
import SwitchItem from '../SwitchItem';

export interface IState {
  allOptins: IOptinsDictionary;
  dataFetched: boolean;
}

export interface IProps {
  isLoading?: boolean;
  optins: IUserOptin[];
  updateOptin: (optinName: IOptinCode, value: boolean) => Promise<FetchResult>;
  testID?: string;
}

class NotificationSwitchList extends PureComponent<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState): IState {
    if (!state.dataFetched && !props.isLoading) {
      const allOptins = createOptinsDictionary(props.optins);

      return { allOptins, dataFetched: true };
    }

    return state;
  }

  public state: IState = {
    allOptins: {
      FOODI_MARKETING: false,
      FOODI_TOS: false,
      MAIL_FOODI_MARKETING: false,
      MAIL_LOW_BALANCE: false,
      PUSH_FOODI_MARKETING: false,
      PUSH_LOW_BALANCE: false,
    },
    dataFetched: false,
  };

  private updateOptinValue: (optinName: IOptinCode, value: boolean) => Promise<void> = debounce(
    async (optinName: IOptinCode, value: boolean): Promise<void> => {
      try {
        await this.props.updateOptin(optinName, value);
        setAttributesToUa();
      } catch (error) {
        this.setState(
          (prevState: IState): IState => ({
            ...prevState,
            allOptins: {
              ...prevState.allOptins,
              [optinName]: getOptinValueByCode(this.props.optins, optinName),
            },
          })
        );
      }
    },
    250
  );

  public render(): ReactNode {
    const { testID, isLoading } = this.props;
    return (
      <LoadingView isLoading={isLoading}>
        <Section
          title={I18n.t('dashboard.profile.notifications.newServicesTitle')}
          subtitle={I18n.t('dashboard.profile.notifications.newServicesSubtitle')}
        >
          <SwitchItem
            testID={`${testID}_switch_newServices_mobile`}
            title={I18n.t('dashboard.profile.notifications.mobile')}
            switchValue={this.state.allOptins.PUSH_FOODI_MARKETING}
            onValueChange={async (value: boolean): Promise<void> => {
              this.onOptinValueChange(OPTINS_CODE.PUSH_FOODI_MARKETING, value);
            }}
          />
          <SwitchItem
            testID={`${testID}_switch_newServices_email`}
            title={I18n.t('dashboard.profile.notifications.email')}
            switchValue={this.state.allOptins.MAIL_FOODI_MARKETING}
            onValueChange={(value: boolean): void => {
              this.onOptinValueChange(OPTINS_CODE.MAIL_FOODI_MARKETING, value);
            }}
          />
        </Section>
        <Section
          title={I18n.t('dashboard.profile.notifications.balanceTitle')}
          subtitle={I18n.t('dashboard.profile.notifications.balanceSubtitle')}
          style={styles.section}
        >
          <SwitchItem
            testID={`${testID}_switch_lowBalance_mobile`}
            title={I18n.t('dashboard.profile.notifications.mobile')}
            switchValue={this.state.allOptins.PUSH_LOW_BALANCE}
            onValueChange={(value: boolean): void => {
              this.onOptinValueChange(OPTINS_CODE.PUSH_LOW_BALANCE, value);
            }}
          />
          {/*
            // This component should stay commented as the US FS-1593 stated that it should
            // be easily re-implemented if we wanted in the future
          <SwitchItem
            testID={`${testID}_switch_lowBalance_email`}
            title={`${I18n.t('dashboard.profile.notifications.email')} (${I18n.t(
              'dashboard.profile.notifications.soonAvailable'
            )})`}
            switchValue={this.state.allOptins.MAIL_LOW_BALANCE}
            onValueChange={(value: boolean): void => {
              this.onOptinValueChange(OPTINS_CODE.MAIL_LOW_BALANCE, value);
            }}
          /> */}
        </Section>
      </LoadingView>
    );
  }

  private onOptinValueChange = (optinCode: IOptinCode, value: boolean): void => {
    this.setState(
      (prevState: IState): IState => ({
        ...prevState,
        allOptins: { ...prevState.allOptins, [optinCode]: value },
      }),
      async () => await this.updateOptinValue(optinCode, value)
    );
  };
}

export interface IStyle {
  section: ViewStyle;
}

const styles: IStyle = StyleSheet.create<IStyle>({
  section: {
    marginTop: 3 * theme.margins.cardUnit,
  },
});

export default NotificationSwitchList;
