import queryString from 'query-string';
import { ComponentType } from 'react';
import {
  NavigationInjectedProps,
  NavigationNavigateAction,
  NavigationParams,
} from 'react-navigation';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withProps } from 'recompose';

import navigation from '../../services/navigation';

const navigateWithHistory = (
  ownProps: RouteComponentProps<NavigationParams>,
  routeName: string,
  params?: NavigationParams
): boolean => {
  const previousParams = {
    ...ownProps.match.params,
    ...queryString.parse(ownProps.location.search),
  };

  return navigation.navigate(routeName, { ...previousParams, ...params });
};

const withNavigationProps = (
  ownProps: RouteComponentProps<NavigationParams>
): NavigationInjectedProps => ({
  navigation: {
    // tslint:disable-next-line:no-any
    addListener: ((): void => undefined) as any,
    // tslint:disable-next-line:no-any
    closeDrawer: ((): void => undefined) as any,
    // tslint:disable-next-line:no-any
    dismiss: ((): void => undefined) as any,
    // tslint:disable-next-line:no-any
    dispatch: ((): void => undefined) as any,
    getParam: (param: string): string =>
      ownProps.match.params[param] || queryString.parse(ownProps.location.search)[param],
    goBack: (): boolean => {
      ownProps.history.goBack();

      return true;
    },
    // tslint:disable-next-line:no-any
    isFocused: ((): void => undefined) as any,
    navigate: (
      routeNameOrOptions:
        | string
        | {
            routeName:
              | string
              | {
                  action?: NavigationNavigateAction;
                  key?: string;
                  params?: NavigationParams;
                  routeName: string;
                };
          },
      params?: NavigationParams
    ): boolean => {
      if (typeof routeNameOrOptions === 'object') {
        if (typeof routeNameOrOptions.routeName === 'object') {
          return navigateWithHistory(
            ownProps,
            routeNameOrOptions.routeName.routeName,
            routeNameOrOptions.routeName.params
          );
        }

        return navigateWithHistory(ownProps, routeNameOrOptions.routeName);
      }

      return navigateWithHistory(ownProps, routeNameOrOptions, params);
    },
    // tslint:disable-next-line:no-any
    openDrawer: ((): void => undefined) as any,
    // tslint:disable-next-line:no-any
    pop: ((): void => undefined) as any,
    // tslint:disable-next-line:no-any
    popToTop: ((): void => undefined) as any,
    push: (routeName: string, params?: NavigationParams): boolean =>
      navigateWithHistory(ownProps, routeName, params),
    replace: (routeName: string, params?: NavigationParams): boolean =>
      navigateWithHistory(ownProps, routeName, params),
    // tslint:disable-next-line:no-any
    setParams: ((): void => undefined) as any,
    state: { index: 0, routes: [] },
    // tslint:disable-next-line:no-any
    toggleDrawer: ((): void => undefined) as any,
  },
});

const withNavigation = <T extends object>(
  Component: ComponentType<T & NavigationInjectedProps>
): ComponentType<T> =>
  compose<T & NavigationInjectedProps, T>(
    withRouter,
    withProps(withNavigationProps)
  )(Component);

export default withNavigation;
