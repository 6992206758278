import { Base64 } from 'js-base64';
import MersenneTwister from 'mersenne-twister';
import moment, { Moment } from 'moment';
import { UAParser } from 'ua-parser-js';

import { IE_BROWSER_NAME, VALID_IE_VERSION_LIMIT } from './constants';

const TOKEN_EXPIRATION_OFFSET = 5;

const getExpirationTokenInfoFromExpireIn = (
  expiresIn: number
): { emissionDate: string; expirationDate: string } => {
  const emissionDate: string = moment().toISOString();
  const expirationDate: string = moment(emissionDate)
    .add(expiresIn, 's')
    .subtract(TOKEN_EXPIRATION_OFFSET, 's')
    .toISOString();

  return { emissionDate, expirationDate };
};

const getExpirationTokenInfoFromExp = (
  exp: number
): {
  emissionDate: string;
  expirationDate: string;
  expireIn: number;
} => {
  const momentNowDate: Moment = moment();
  const momentExpirationDate: Moment = moment(exp * 1000);
  const expireIn: number = momentExpirationDate.diff(momentNowDate, 's');
  const emissionDate: string = momentNowDate.toISOString();
  const expirationDate: string = momentExpirationDate
    .subtract(TOKEN_EXPIRATION_OFFSET, 's')
    .toISOString();

  return { emissionDate, expirationDate, expireIn };
};

const isValidIEVersion = (version: string | undefined): boolean => {
  if (!version) {
    return true;
  }

  return Number(version.split('.')[0]) > VALID_IE_VERSION_LIMIT;
};

const isIEBrowser = (name: string | undefined): boolean => {
  if (!name) {
    return true;
  }

  return name === IE_BROWSER_NAME;
};

const generateRandomString = (): string => Base64.encode(new MersenneTwister().random().toString());

const generateAuth0State = (): string | undefined => {
  const parser = new UAParser();
  const browser = parser.getResult().browser;
  const state =
    browser && isIEBrowser(browser.name) && !isValidIEVersion(browser.version)
      ? generateRandomString()
      : undefined;

  return state;
};

export { getExpirationTokenInfoFromExp, getExpirationTokenInfoFromExpireIn, generateAuth0State };
