import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { ActionCreator } from 'redux';

import withNavigation from '../../../hoc/withNavigation';
import withUserInfos from '../../../hoc/withUserInfos';
import { IGraphQLProps as IWithUserInfosConnectedProps } from '../../../hoc/withUserInfos/withUserInfos';
import { IToggleRecipeDisclaimerAction, toggleRecipeDisclaimer } from '../../../redux/menu/actions';
import { getIsRecipeDisclaimerOpened } from '../../../redux/menu/selectors';
import { IAppState } from '../../../redux/reducer';
import { isRecipeDisclaimerAccepted } from '../../../services/optins';
import MenuSection, { IProps } from './MenuSection.component';

export interface IWithHasUserAcceptedRecipeDisclaimerProps {
  hasUserAcceptedRecipeDisclaimer: boolean;
}

export interface IComponentProps {
  isLoading: boolean;
}

export interface IMapStateToProps {
  isModalVisible: boolean;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  isModalVisible: getIsRecipeDisclaimerOpened(state),
});

interface IDispatchProps {
  toggleRecipeDisclaimer: ActionCreator<IToggleRecipeDisclaimerAction>;
}

const mapDispatchToProps: IDispatchProps = {
  toggleRecipeDisclaimer,
};

export interface IWithOnElementClickProps {
  onElementClick: (elementId: string) => void;
}

interface IWithIsLoading {
  isLoading: boolean;
}

export interface IWithHasUserAcceptedRecipeDisclaimerGraphQLProps {
  isRecipeDetailsEnabled?: boolean;
  isRecipeDetailsEnabledLoading: boolean;
}

const withIsLoading = (
  props: IWithHasUserAcceptedRecipeDisclaimerGraphQLProps & IComponentProps
): IWithIsLoading => ({
  isLoading: props.isLoading || props.isRecipeDetailsEnabledLoading,
});

const onElementClickProp = (
  props: IConnectedProps & IWithUserInfosConnectedProps
): IWithOnElementClickProps => ({
  onElementClick: (elementId: string, holdingSettingId?: string, importationType?: string): void => {
    const posId = props.navigation.getParam('posId');

    props.navigation.navigate('menuElementDetails', { posId, elementId, holdingSettingId, importationType });
  },
});

const hasUserAcceptedRecipeDisclaimerProps = (
  props: IConnectedProps & IWithUserInfosConnectedProps
): IWithHasUserAcceptedRecipeDisclaimerProps => ({
  hasUserAcceptedRecipeDisclaimer:
    props.isUserInfosLoading || !props.getUser
      ? false
      : isRecipeDisclaimerAccepted(props.getUser.userOptins),
});

export type IConnectedProps = NavigationInjectedProps &
  IWithHasUserAcceptedRecipeDisclaimerProps &
  IWithOnElementClickProps &
  IMapStateToProps &
  IDispatchProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNavigation,
  withUserInfos,
  withProps(hasUserAcceptedRecipeDisclaimerProps),
  withProps(onElementClickProp),
  withProps(withIsLoading)
)(MenuSection);
