import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { IService } from '../../types/common';

import TabBar, { IProps } from './TabBar.component';

const getServices = loader('../../queries/getServices.gql');

export interface IMapStateToProps {
  services: IService[];
}

export type IConnectedProps = IMapStateToProps & NavigationInjectedProps & WithApolloClient<{}>;

export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export interface IGraphQLProps {
  services: IService[];
}

export default compose<IProps, IContainerProps>(
  // @ts-ignore
  ...[
    withNavigation,
    graphql(getServices, {
      props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
        services: get(props, 'data.getUser.guests.edges[0].node.holding.services') || '',
      }),
    }),
    withApollo,
  ].filter(Boolean)
)(TabBar);
