import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';

import Card from '../../../../components/Card';
import theme from '../../../../theme';

export interface IProps {
  children?: ReactNode[] | ReactNode;
  title: string;
}

const BORDER_RADIUS = 4;

class MenuElementSection extends PureComponent<IProps> {
  public render(): ReactNode {
    const { title, children } = this.props;

    return (
      <Card big style={styles.card}>
        <Text style={styles.text}>{title}</Text>
        {children}
      </Card>
    );
  }
}

export interface IStyle {
  card: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  card: {
    ...theme.shadows.card,
    borderRadius: BORDER_RADIUS,
    marginBottom: 4 * theme.margins.cardUnit,
    overflow: 'hidden',
  },
  text: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.black,
    marginBottom: 2 * theme.margins.cardUnit,
  },
});

export default MenuElementSection;
