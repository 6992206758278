import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { IWithGoToLockerQRCodePage } from './OrderWithdrawalLockerMethodCard.container';
import Touchable from '../../Touchable';
import Icon from '../../Icon';
import LoadingCard from '../../../components/LoadingCard';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import { getOrder2_order_Order as IOrder } from '../../../types/clickandcollect/getOrder2';
import { OrderState } from '../../../types/clickandcollect/globalTypes';


export interface IProps extends IWithGoToLockerQRCodePage{
  isLoading: boolean;
  order: IOrder;
  testID?: string;
}


class OrderWithdrawalLockerMethodCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, order, goToLockerQRCodePage, testID } = this.props;

    const QrScannerSVG = theme.images.qrScannerSVG;

    // the array contains the list of states eligible to allow showing the info about the qr code
    const canDisplayQRCodeRedirectButton = [
      OrderState.READY,
      OrderState.COMPLETED
    ].includes(order.state);

    const OrderReadyMessage = ({ title, subTitle }: { title: string, subTitle: string }): JSX.Element => {
      return (
        <View style={styles.messageReadyContent}>
          <View style={styles.textArea}>
            <Text style={styles.messageTitle}>{title}</Text>
            <Text style={styles.messageSubtitle}>{subTitle}</Text>
          </View>
          <View style={styles.arrowIcon}>
            <Icon  name="chevronRight" size={22} tintColor={'white'} />
          </View>
        </View>
      );
    };

    const OrderNotReadyMessage = ({ message }: { message: string }): JSX.Element => {
      return (
        <Text style={styles.messageNotReady}>{`${message}`}</Text>
      )
    };

    const styles = createStyles({ canDisplayQRCodeRedirectButton });

    return (
      <LoadingCard isLoading={isLoading} noPadding style={styles.card} big>
        <Touchable
          testID={testID}
          disabled={!canDisplayQRCodeRedirectButton}
          onPress={goToLockerQRCodePage}
        >
        <View style={styles.orderWithdrawalMethod}>
          <QrScannerSVG style={styles.qrIcon}/>
          {canDisplayQRCodeRedirectButton ?
            <OrderReadyMessage
              title={I18n.t('dashboard.eat.clickAndCollect.orderDetails.withdrawalMethodOrderReadyTitle')}
              subTitle={I18n.t('dashboard.eat.clickAndCollect.orderDetails.withdrawalMethodOrderReadySubTitle')}
            /> :
            <OrderNotReadyMessage
              message={I18n.t('dashboard.eat.clickAndCollect.orderDetails.withdrawalMethodOrderNotReady')}
            />
          }
        </View>
        </Touchable>
      </LoadingCard>
    );
  }
}

export interface IStyle {
  card: ViewStyle;
  orderWithdrawalMethod: ViewStyle;
  qrIcon: ViewStyle;
  messageNotReady: TextStyle;
  messageReadyContent: ViewStyle;
  textArea: ViewStyle;
  arrowIcon: ViewStyle;
  messageTitle: TextStyle;
  messageSubtitle: TextStyle;
}

const BORDER_RADIUS = 8;

// tslint:disable-next-line:typedef
const createStyles = (props: { canDisplayQRCodeRedirectButton: boolean }) => StyleSheet.create<IStyle>({
  card: {
    ...theme.shadows.card,
    backgroundColor: props.canDisplayQRCodeRedirectButton ? theme.colors.blue : '#EAEAEA',
    borderRadius: BORDER_RADIUS,
    marginBottom: theme.margins.cardUnit * 2,
    overflow: 'hidden',
  },
  orderWithdrawalMethod: {
    padding: theme.margins.cardUnit * 2,
    flex: 2,
    flexDirection: 'row'
  },
  qrIcon: {
    flex: 1
  },
  messageNotReady: {
    flex: 1,
    ...theme.fonts.regularText,
    marginLeft: theme.margins.cardUnit * 2,
    color: props.canDisplayQRCodeRedirectButton ? theme.colors.white : '#627383'
  },
  messageReadyContent: {
    flexDirection: 'row',
    flex: 1,
    marginLeft: theme.margins.cardUnit * 2,
  },
  textArea: {
    flexDirection: 'column',
    flex: 0.9,
  },
  arrowIcon: {
    flex: 0.1,
    justifyContent: 'center'
  },
  messageTitle: {
    ...theme.fonts.boldText,
    color: theme.colors.white
  },
  messageSubtitle: {
    ...theme.fonts.regularText,
    fontStyle: 'italic',
    color: theme.colors.white
  }
});

export default OrderWithdrawalLockerMethodCard;
