import { ENVIRONMENT, getConfig } from '../environment';

class Logger {
  public static error(error: Error): void {
    const env = getConfig();

    if (env.ENV === ENVIRONMENT.DEVELOPMENT) {
      // tslint:disable-next-line:no-console
      console.warn(error.message);
    }
  }

  public static info(...messages: any[]): void {
    const env = getConfig();

    if (env.ENV === ENVIRONMENT.DEVELOPMENT) {
      // tslint:disable-next-line:no-console
      console.info(...messages);
    }
  }
}

export default Logger;
