import React, { PureComponent, ReactNode } from 'react';

import MyInformationSections from '../../../../components/MyInformationSections';
import { WebPage } from '../../../../componentsWeb';
import I18n from '../../../../lib/i18n';

class MyInformations extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <WebPage title={I18n.t('dashboard.profile.myInformations.title')} canGoBack>
        <MyInformationSections />
      </WebPage>
    );
  }
}

export default MyInformations;
