import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import Logger from '../../lib/logger';
import { Tracker } from '../../services/analytics';
import navigation from '../../services/navigation';
import { trackEvent, AIRSHIP_EVENT } from '../../services/notification';

import { actionTypes, IAuthenticateAction, setAuthenticationToken } from './actions';

export const authenticateSaga = function*(action: IAuthenticateAction): SagaIterator {
  const {
    emissionDate,
    expirationDate,
    expiresIn,
    refreshToken,
    signInRedirection,
    token,
    redirect,
  } = action.payload;
  try {
    yield put(
      setAuthenticationToken({
        emissionDate,
        expirationDate,
        expiresIn,
        refreshToken,
        token,
        signInRedirection: redirect ? signInRedirection : undefined,
      })
    );
    yield call(
      navigation.navigate,
      redirect ? redirect : signInRedirection ? signInRedirection : 'dashboard'
    );
    yield call(trackEvent, AIRSHIP_EVENT.CONNECTION);
    yield call(Tracker.trackUser);
    yield call(Tracker.trackNavigation, {
      pathname: 'authenticationCallback',
    });
  } catch (error) {
    Logger.error(error);
  }
};

export const logOutSaga = function*(): SagaIterator {
  yield call(Tracker.resetUserTracking);
};

export const authenticationWatcher = function*(): SagaIterator {
  yield takeLatest(actionTypes.AUTHENTICATE, authenticateSaga);
  yield takeLatest(actionTypes.AUTHENTICATION_LOG_OUT, logOutSaga);
};
