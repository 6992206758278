import { PureComponent, ReactNode } from 'react';
import { NavigationInjectedProps } from 'react-navigation';
import { ActionCreator } from 'redux';
import { getConfig, IConfig } from '../../environment';
import Auth from '../../../src/services/authentication';

import { parseSignInRedirection } from '../../services/navigation/params';

export interface IProps extends NavigationInjectedProps {
  token?: string;
  signInRedirection: string;
  resetSigninRedirection: ActionCreator<{}>;
}

class AuthLoading extends PureComponent<IProps> {
  public componentDidMount(): void {
    const {
      navigation: { navigate },
      signInRedirection,
      token,
      resetSigninRedirection,
    } = this.props;
    const { routeName, params } = parseSignInRedirection(signInRedirection);
    const env = getConfig();

    if (routeName) {
      resetSigninRedirection();
    }

    if (env.DETOX_ENABLED === 'true') {
      Auth.handleAuthentication(this.props.navigation.getParam('signInRedirection'));
      navigate(routeName || 'dashboard', params);
    }
    if (!token) {
      navigate('auth');
    } else {
      navigate(routeName || 'dashboard', params);
    }
  }

  public render(): ReactNode {
    return null;
  }
}

export default AuthLoading;
