import React, { PureComponent, ReactNode } from 'react';
import { NavigationInjectedProps } from 'react-navigation';

import LoadingView from '../../../../../components/LoadingView';
import { WebPage } from '../../../../../componentsWeb';

import I18n from '../../../../../lib/i18n';

import OrderDetailsContent, { IProps as IOrderDetailsContentProps } from './OrderDetailsContent';

export interface IProps extends NavigationInjectedProps, IOrderDetailsContentProps {
  isGuestAnomyn?: boolean
}

class OrderDetails extends PureComponent<IProps> {
  public render(): ReactNode {
    const isReady = this.props.order ? true : false;

    return isReady ? (
      <WebPage title={I18n.t('dashboard.eat.clickAndCollect.orderDetails.title')} hideLogo hideNavigationMenu tabBarHeight={65}>
        <OrderDetailsContent
          image={this.props.image}
          isLoading={this.props.isLoading}
          order={this.props.order}
          showCancelButton={false}
          showEmailButton={this.props.isGuestAnomyn===true}
          isCreditCard={this.props.isCreditCard}
        />
      </WebPage>
    ) : (
      <WebPage tabBarHeight={0} hideLogo>
        <LoadingView isLoading={true} />
      </WebPage>
    );
  }
}

export default OrderDetails;
