import { FormikProps } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import Button from '../../Button';
import FormFormik from '../../FormFormik';
import Paragraph from '../../Paragraph';
import TextInputFormik from '../../TextInputFormik';
import ErrorMessage from '../../ErrorMessage';

export interface IValues {
  email: string;
}

export type IProps = FormikProps<IValues>;

class AlertOpeningFormikForm extends PureComponent<IProps, {}> {
  public render(): ReactNode {
    const { errors, isSubmitting, submitForm, values } = this.props;

    return (
      <FormFormik>
        <View style={styles.textContainer}>
          <Paragraph text={I18n.t('signUp.closeHolding.paragraph')} />
        </View>
        <TextInputFormik
          hasError={!!errors.email}
          name="email"
          placeholder={I18n.t('signUp.email.placeholder')}
          returnKeyType="done"
          type="email"
          error=""
        />
        {!!errors.email && <ErrorMessage text={I18n.t(errors.email)} />}
        <Button
          disabled={!this.canSubmit(values)}
          text={I18n.t('signUp.email.validate')}
          isLoading={isSubmitting}
          onPress={submitForm}
        />
      </FormFormik>
    );
  }

  private canSubmit = (values: IValues): boolean => {
    return !!values.email;
  };
}

const styles = StyleSheet.create({
  textContainer: {
    marginBottom: theme.margins.cardUnit,
  },
});
export default AlertOpeningFormikForm;
