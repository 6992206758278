import React from 'react';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, lifecycle } from 'recompose';

import { Tracker } from './../../services/analytics';

import { IUserForTracking } from './withUserTracking.d';
import { connect } from 'react-redux';
import { IAppState } from '../../redux/reducer';
import { CookieCategory, CookieConsent } from '../../services/oneTrust';
import { getCategoriesConsent } from '../../redux/cookies/selectors';
import { isWeb } from '../../lib/responsive';

export interface IWithUserTrackingResult {
  getUser: IUserForTracking;
}

export type IComposeProps = NavigationInjectedProps &
  WithApolloClient<{}> &
  IWithUserTrackingResult;
type IHocProps<T> = Omit<T, keyof IComposeProps>;

interface IWithLifeCycleProps {
  token?: string,
  cookiesTrackConsent: CookieConsent
}

const withLifeCycle = lifecycle<WithApolloClient<{}> & IWithLifeCycleProps, {}, {}>({

  async componentDidMount(): Promise<void> {
    if (!this.props.token) {
      return;
    }

    Tracker.setConsentToTrackUser(this.props.cookiesTrackConsent === CookieConsent.CONSENT_GIVEN);
    await Tracker.trackUser();
  },

  async componentDidUpdate(): Promise<void> {
    Tracker.setConsentToTrackUser(this.props.cookiesTrackConsent === CookieConsent.CONSENT_GIVEN);
    await Tracker.trackUser();
  },

  shouldComponentUpdate(): boolean {

    return true;
  }
});

const mapStateToProps = (state: IAppState): { cookiesTrackConsent: CookieConsent} => {

  if(!isWeb()) {
    return {
      cookiesTrackConsent: getCategoriesConsent(state)[CookieCategory.PERFORMANCE_COOKIES]
    };
  }

  // @ts-ignore
  if (!window.OnetrustActiveGroups) {
    return {
      cookiesTrackConsent: CookieConsent.CONSENT_NOT_YET_GATHERED
    };
  }

  // @ts-ignore
  const performanceCookiesConsentWeb = typeof window.OnetrustActiveGroups === 'string' &&
  // @ts-ignore
    window.OnetrustActiveGroups.includes(CookieCategory.PERFORMANCE_COOKIES);

  return {
    cookiesTrackConsent: performanceCookiesConsentWeb ? CookieConsent.CONSENT_GIVEN : CookieConsent.CONSENT_NOT_GIVEN
  };
}

const withUserTracking = <T extends IComposeProps>(
  Component: React.ComponentType<T>
): React.ComponentType<IHocProps<T>> => {
  return compose<T & IComposeProps, IHocProps<T>>(
    connect(mapStateToProps),
    withApollo,
    withLifeCycle
  )(Component);
};

export default withUserTracking;
