import get from 'lodash/get';
import { loader } from 'graphql.macro';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import withApollo from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withState, withProps } from 'recompose';
import { ActionCreator } from 'redux';

import PriceFooter, { IProps as IComponentProps } from '../PriceFooter.component';
import withNavigation from '../../../hoc/withNavigation';
import {
  IResetQuantityForOfferAction,
  IResetSlotForOfferAction,
} from '../../../redux/clickAndCollect/actions';
import { IAmount } from '../../../types/common';
import I18n from '../../../lib/i18n';
const getUserInfo = loader('../../../queries/getUserInfo.gql');

export enum FooterType {
  CART = 'CART',
  SLOT = 'SLOT',
}

interface IProps {
  onPress?: () => void;
  footerType: string;
  idOffer: string | undefined;
  price: string | null;
  testID?: string;
}

interface IMapStateToProps {
  totalPrice: IAmount | null;
}

interface IMapDispatchToProps {
  resetQuantityForOffer: ActionCreator<IResetQuantityForOfferAction>;
  resetSlotForOffer: ActionCreator<IResetSlotForOfferAction>;
}

interface ILoadingProps {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

interface IWithExtraProps {
  title: string;
  enabled: boolean;
  totalPrice: IAmount;
  isLoading: boolean;
  testID?: string;
}

type IConnectedProps = IMapStateToProps & IMapDispatchToProps & NavigationInjectedProps;

// const mapDispatchToProps: IMapDispatchToProps = {
//   resetQuantityForOffer,
//   resetSlotForOffer,
// };

const withExtraProps = ({
  idOffer,
  price,
  testID,
}: IProps & ILoadingProps & IConnectedProps): IWithExtraProps => {
  const enabled = idOffer ? true : false;
  const totalPrice: IAmount = { amount: price, currency: 'EUR' };
  const isLoading: boolean = false;
  return {
    title: enabled
      ? I18n.t('dashboard.eat.clickAndCollect.order')
      : I18n.t('dashboard.eat.clickAndCollect.offerUnavailable'),
    enabled,
    totalPrice,
    isLoading,
    testID,
  };
};

export interface IGraphQLProps {
  idGuest: string;
}

export default compose<IComponentProps, IProps>(
  withState('isLoading', 'setLoading', false),
  withState('error', 'setError', null),
  withNavigation,
  graphql(getUserInfo, {
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      idGuest: get(props, 'data.getUser.guests.edges[0].node.id') || '',
    }),
  }),
  withApollo,
  withProps(withExtraProps)
)(PriceFooter);
