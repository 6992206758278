import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View } from 'react-native';

import theme from '../../../theme';

export interface IProps {
  children: ReactNode[] | ReactNode;
  title: string;
}

class OrderSummarySection extends PureComponent<IProps> {
  public render(): ReactNode {
    const { title } = this.props;

    return (
      <View>
        <Text style={styles.title}>{title}</Text>
        {this.props.children}
      </View>
    );
  }
}

export interface IStyle {
  title: TextStyle;
}

const styles = StyleSheet.create({
  title: {
    ...theme.fonts.cardText,
    color: theme.colors.gray,
  },
});

export default OrderSummarySection;
