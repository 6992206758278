import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import I18n from '../../lib/i18n';
import theme from '../../theme';
import Touchable from '../Touchable';

import TabLabel from './TabLabel';

export interface IProps extends NavigationInjectedProps {
  renderPointsOfSaleList: () => ReactElement<{}>;
  renderTakeAwayList: () => ReactElement<{}>;
}
export interface IState {
  index: number;
}

/**
 * We did a custom component on web because react-native-tab-view was not rendering at all
 */
class TabBar extends PureComponent<IProps, IState> {
  public state: IState = {
    index: 0,
  };

  public componentDidMount(): void {
    const indexStr = this.props.navigation.getParam('tab');
    const indexNum = indexStr ? parseInt(indexStr, 10) : 0;
    const index = indexNum in [0, 1] ? indexNum : 0;
    this.setState({ index });
  }

  public render(): ReactNode {
    const { renderPointsOfSaleList, renderTakeAwayList } = this.props;

    return (
      <View>
        <View style={styles.container}>
          <Touchable style={styles.tabContainer} onPress={(): void => this.handleTabSelected(0)}>
            <TabLabel isActive={this.isTabSelected(0)} name={I18n.t('dashboard.eat.tabBar.here')} />
          </Touchable>
          <Touchable style={styles.tabContainer} onPress={(): void => this.handleTabSelected(1)}>
            <TabLabel
              isActive={this.isTabSelected(1)}
              name={I18n.t('dashboard.eat.tabBar.takeAway')}
            />
          </Touchable>
        </View>

        {this.state.index === 0 ? renderPointsOfSaleList() : renderTakeAwayList()}
      </View>
    );
  }

  private handleTabSelected = (indexSelected: number): void => {
    this.setState({ index: indexSelected });
  };

  private isTabSelected = (index: number): boolean => this.state.index === index;
}

interface IStyle {
  container: ViewStyle;
  tabContainer: ViewStyle;
}
const BORDER_RADIUS: number = 4;
const MARGIN_BOTTOM: number = theme.margins.cardUnit * 4;

const styles = StyleSheet.create<IStyle>({
  container: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: MARGIN_BOTTOM,
    paddingVertical: theme.margins.cardUnit * 2,
  },
  tabContainer: {
    alignItems: 'center',
    flex: 1,
    padding: theme.margins.cardUnit,
  },
});

export default TabBar;
