import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';

import FoodiLogo, { IProps } from './FoodiLogo.component';

const getBrand = loader('../../queries/getBrand.gql');

interface IMapStateToProps {
  token: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

export interface IGraphQLProps {
  brand?: string;
  idHolding?: string;
}

export interface IExternalProps {
  brandLess?: boolean;
}
export type IConnectedProps = IGraphQLProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps),
  graphql(getBrand, {
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      brand: get(props, 'data.getUser.guests.edges[0].node.holding.brand'),
      idHolding: get(props, 'data.getUser.guests.edges[0].node.holding.id'),
    }),
    skip: (props: IExternalProps & IMapStateToProps): boolean => props.brandLess || !props.token,
  })
)(FoodiLogo);
