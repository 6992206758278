import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../../hoc/withNavigation';

import SignUpPasswordFormikForm, { IProps } from './SignUpPasswordFormikForm.component';

export type IConnectedProps = NavigationInjectedProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withNavigation)(SignUpPasswordFormikForm);
