import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';

import { IMarketingCardElement } from '../../types/common';

const getMarketingCard = loader('../../queries/getMarketingCard.gql');

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  marketingCard: IMarketingCardElement | undefined;
}

export interface IWithMarketingCardId {
  marketingCardId: string;
}

export default graphql(getMarketingCard, {
  options: (props: IWithMarketingCardId): QueryOpts => ({
    variables: {
      id: props.marketingCardId,
    },
  }),
  props: (props: OptionProps<IWithMarketingCardId>): IGraphQLProps => {
    const marketingCard = get(props, 'data.marketingCard');

    return {
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      marketingCard,
    };
  },
});
