import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';

import CounterButton, { IProps as CounterButtonProps } from './components/CounterButton.component';

export interface IProps extends Omit<CounterButtonProps, 'status'> {
  quantityRenderer: undefined | ((quantity: number) => ReactNode);
  testID: string;
}

class Counter extends PureComponent<IProps> {
  public render(): ReactNode {
    const { value, min, max, quantityRenderer, size, disableCounter, variant, alwaysShowDecrement, testID } = this.props;
    return (
      <View style={styles.counter}>
        <CounterButton
          testID={`${testID}_counterButton_decrement`}
          onCounterPress={this.props.onCounterPress}
          max={max}
          min={min}
          status="DECREMENT"
          value={value}
          size={size}
          variant={variant}
          alwaysShowDecrement={alwaysShowDecrement}
        />
        {quantityRenderer && quantityRenderer(value)}
        <CounterButton
          testID={`${testID}_counterButton_increment`}
          max={max}
          min={min}
          onCounterPress={this.props.onCounterPress}
          status="INCREMENT"
          value={value}
          size={size}
          disableCounter={disableCounter}
          variant={variant}
          alwaysShowDecrement={alwaysShowDecrement}
        />
      </View>
    );
  }
}

export interface IStyle {
  counter: ViewStyle;
  title: TextStyle;
}

const styles = StyleSheet.create({
  counter: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  title: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.validate,
  },
});

export default Counter;
