import React, { PureComponent, ReactNode } from 'react';

import { WebPage } from '../../componentsWeb';

import { IAppProps, IWebProps } from './Page.component';

class Page extends PureComponent<IAppProps | IWebProps> {
  public render(): ReactNode {
    return <WebPage {...this.props} />;
  }
}

export default Page;
