import theme from '../../theme';

export type IStepStatus = 'ACTIVE' | 'DONE' | 'ERROR' | 'INACTIVE';

export const STATUS: { [key in IStepStatus]: key } = {
  ACTIVE: 'ACTIVE',
  DONE: 'DONE',
  ERROR: 'ERROR',
  INACTIVE: 'INACTIVE',
};

const STATUS_COLOR: { [key in IStepStatus]: string } = {
  ACTIVE: theme.colors.blue,
  DONE: theme.colors.success,
  ERROR: theme.colors.error,
  INACTIVE: theme.colors.disabled,
};

export const getStatusColor = (status: IStepStatus): string => STATUS_COLOR[status];

export const getGradientColors = (fromStatus: IStepStatus, toStatus: IStepStatus): string[] => {
  if (fromStatus === 'INACTIVE' || toStatus === 'INACTIVE') {
    return [STATUS_COLOR.INACTIVE, STATUS_COLOR.INACTIVE];
  }

  return [getStatusColor(fromStatus), getStatusColor(toStatus)];
};
