import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationScreenProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../../../src/hoc/withNavigation/withNavigation.web';
import { IDistance } from '../../../../src/types/common';
import { IHolding } from '../../../../src/types/holdingById';

const holdingById = loader('../../../../src/queries/holdingById.gql');

import GuestCredentialsPage, { IProps } from './GuestCredentialsPage.component';

export interface IGraphQLProps {
  distance?: IDistance;
  holding?: IHolding;
  isLoading?: boolean;
}
export type IConnectedProps = NavigationScreenProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, {}>(
  withNavigation,
  graphql(holdingById, {
    options: (props: IConnectedProps): QueryOpts => {
      const holdingId = props.navigation.getParam('holdingId');
      const latitude = props.navigation.getParam('latitude');
      const longitude = props.navigation.getParam('longitude');

      return {
        variables: {
          id: holdingId,
          latitude,
          longitude,
          withDistance: !!(latitude && longitude),
        },
      };
    },
    props: (props: OptionProps<IConnectedProps>): IGraphQLProps => ({
      distance: get(props, 'data.holding.distance'),
      holding: get(props, 'data.holding.holding'),
      isLoading: get(props, 'data.loading'),
    }),
  })
)(GuestCredentialsPage);
