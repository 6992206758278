import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { selectOrderToken } from '../../redux/orderAuthentification/selectors';

import { IAppState } from '../../redux/reducer';

import I18nProvider, { IProps } from './I18nProvider.component';

const getI18nProviderLocale = loader('../../queries/getI18nProviderLocale.gql');

interface IMapStateToProps {
  token: string;
  orderToken: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
  orderToken : selectOrderToken(state)
});

export interface IGraphQLProps {
  isLoading?: boolean;
  userLocale?: string;
}
export type IConnectedProps = IMapStateToProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, {}>(
  connect(mapStateToProps),
  graphql(getI18nProviderLocale, {
    options: (): QueryOpts => ({
      fetchPolicy: 'network-only'
    }),
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      isLoading: get(props, 'data.loading'),
      userLocale: get(props, 'data.getUser.language'),
    }),
    skip: (props: IMapStateToProps): boolean => !props.orderToken && !props.token
  })
)(I18nProvider);
