import get from 'lodash/get';
import React, { useMemo } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import LoadingCard from '../../../components/LoadingCard';
import I18n from '../../../lib/i18n';
import { transformSummaryDate, transformSummaryTime } from '../../../services/offersFormatter';
import {
  getOrderStateMessage,
  orderStateColor,
  orderStateTranslationKey,
} from '../../../services/orderStateTransformer';
import theme from '../../../theme';
import { getOrder2_order_Order as IOrder } from '../../../types/clickandcollect/getOrder2';
import Separator from '../../Separator';
import OrderDetailsSection from './../OrderDetailSection';
import { OfferTemplateWithdrawalType } from '../../../types/clickandcollect/globalTypes';
import PickupPointSection from '../../../components/CartSummaryContent/PickupPointSection/PickupPointSection.component';

export interface IProps {
  isLoading: boolean;
  order: IOrder;
}

const OrderStatusCard: React.FC<IProps> = (props: IProps) => {
  const { isLoading, order } = props;
  const statusColor = order ? orderStateColor(order.state) : undefined;
  const ordersStateMessage = getOrderStateMessage(order);
  let pickupDate = '';

  if (order.withdrawRange) {
    const [_, end] = order!.withdrawRange.split('/');
    pickupDate = transformSummaryDate(end);
  }

  const withdrawalText = useMemo(() => {
    const withdrawRange = get(order, 'withdrawRange');

    if (!order || !withdrawRange) {
      return null;
    }
    const withdrawalType = get(order, 'offer.offerTemplate.withdrawalType');

    const tableNumberText = `- ${I18n.t(
      'dashboard.eat.clickAndCollect.orderDetails.seatClickAndServe.table'
    )} ${get(order, 'tableNumber')}`;

    const withdrawTimeText =
      withdrawalType !== OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT
        ? transformSummaryTime(
            withdrawRange.split('/')[0],
            withdrawRange.split('/')[1]
          ).toLowerCase()
        : '';

    return `${transformSummaryDate(withdrawRange.split('/')[0])} ${
      withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE
        ? tableNumberText
        : withdrawTimeText
    }`;
  }, [order]);

  return (
    <LoadingCard isLoading={isLoading} noPadding style={styles.card} big>
      <View style={styles.orderDetails}>
        <Text style={styles.cardTitle}>
          {`${I18n.t('dashboard.eat.clickAndCollect.orderDetails.order')} #${order.idWithdrawal}`}
        </Text>
        <Text style={[styles.status, { color: statusColor }]}>
          {I18n.t(orderStateTranslationKey(order.state))}
        </Text>
        {!!ordersStateMessage && <Text style={styles.message}>{ordersStateMessage}</Text>}

        {/* [Pickup Point] */}
        {order.offer?.offerTemplate.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP && order.pickupPoint && (
          <View style={styles.orderSections}>
            <OrderDetailsSection
              title={I18n.t('dashboard.eat.clickAndCollect.cartSummary.pickupPoint').toUpperCase()}
            />
            <PickupPointSection
              name={order.pickupPoint?.name}
              withdrawalSchedule={order.pickupPoint?.withdrawalSchedule}
              description={order.pickupPoint?.description}
              pickupDate={pickupDate}
              noTitle
            />
          </View>
        )}
        {/* [Pickup Point] */}

        {!!withdrawalText && order.offer?.offerTemplate.withdrawalType !== OfferTemplateWithdrawalType.CLICK_AND_PICK_UP && (
          <View style={styles.orderSections}>
            <OrderDetailsSection
              title={I18n.t('dashboard.eat.clickAndCollect.orderDetails.receptionDate')}
              details={withdrawalText}
            />
            <View style={styles.separator}>
              <Separator />
            </View>
            <OrderDetailsSection
              title={I18n.t('dashboard.eat.clickAndCollect.orderDetails.receptionPlace')}
              details={get(order, 'withdrawLocation')}
            />
          </View>
        )}
      </View>
    </LoadingCard>
  );
};

export interface IStyle {
  card: ViewStyle;
  cardTitle: TextStyle;
  message: TextStyle;
  orderDetails: ViewStyle;
  orderSections: ViewStyle;
  separator: ViewStyle;
  status: TextStyle;
}

const BORDER_RADIUS = 8;

const styles = StyleSheet.create({
  card: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    marginBottom: theme.margins.cardUnit * 2,
    overflow: 'hidden',
  },
  cardTitle: {
    ...theme.fonts.bigTitle,
    color: theme.colors.deprecatedBlack,
    marginTop: theme.margins.cardUnit * 3,
    textAlign: 'center',
  },
  message: {
    ...theme.fonts.semiBoldText,
    marginTop: theme.margins.cardUnit * 4,
  },
  orderDetails: {
    padding: theme.margins.cardUnit * 2,
    paddingBottom: 0,
  },
  orderSections: {
    marginTop: theme.margins.cardUnit * 4,
  },
  separator: {
    marginVertical: theme.margins.cardUnit * 2,
  },
  status: {
    ...theme.fonts.semiBoldText,
    marginTop: theme.margins.cardUnit,
    textAlign: 'center',
  },
});

export default OrderStatusCard;
