import { compose, withProps } from 'recompose';

import { getPrimaryAction } from '../../../services/pointOfSaleMapper';
import { IPosType } from '../../../types/common';

import PosCardHeader, { IProps } from './PosCardHeader.component';
interface IWithHeaderProps {
  onPress?: () => void;
}

const withHeaderProps = (ownProps: IExternalProps): IWithHeaderProps => {
  const headerProps: IWithHeaderProps = {};

  headerProps.onPress = undefined;

  if (ownProps.posId) {
    const primaryAction = getPrimaryAction(ownProps.posType, ownProps.posId, {
      content: ownProps.content,
    });
    headerProps.onPress = primaryAction && primaryAction.callback;
  }

  return headerProps;
};

export interface IExternalProps {
  content?: string;
  posId?: string;
  posType: IPosType;
}
export type IConnectedProps = IWithHeaderProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withProps(withHeaderProps))(PosCardHeader);
