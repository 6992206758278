import { Action } from 'redux';

export type IActionTypes = 'TOGGLE_DISCLAIMER';

export const actionTypes: { [key in IActionTypes]: key } = {
  TOGGLE_DISCLAIMER: 'TOGGLE_DISCLAIMER',
};

export interface IToggleRecipeDisclaimerPayload {
  isRecipeDisclaimerOpened: boolean;
}

export interface IToggleRecipeDisclaimerAction extends Action {
  payload: IToggleRecipeDisclaimerPayload;
}

export const toggleRecipeDisclaimer = (
  payload: IToggleRecipeDisclaimerPayload
): IToggleRecipeDisclaimerAction => ({
  payload,
  type: actionTypes.TOGGLE_DISCLAIMER,
});
