import queryString, { ParsedQuery } from 'query-string';
import React, { PureComponent, ReactNode } from 'react';
import { NavState, Platform, Dimensions } from 'react-native';
import { WebView } from 'react-native-webview';
import { NavigationInjectedProps } from 'react-navigation';
import { OrderState, PaymentMethod } from '../../../../../types/clickandcollect/globalTypes';

import AppPage from '../../../../../components/AppPage';
import LoadingView from '../../../../../components/LoadingView';
import { getConfig } from '../../../../../environment';
import { IPaymentRequest } from '../../../../../types/common';
import { loader } from 'graphql.macro';
import { cartSummaryData_order_Order as IOrder } from '../../../../../types/clickandcollect/cartSummaryData';
import { resetCart } from '../../../../../redux/clickAndCollect/actions';
import store from '../../../../../redux/store';
import { Tracker } from '../../../../../services/analytics';
import { Event } from '../../../../../services/analytics/tracker';
const confirmOrder = loader(
  'src/components/CartSummaryContent/ConfirmOrderButton/confirmOrder.gql'
);

interface IState {
  isPaymentFinished: boolean;
  paymentFinishedEvent: any;
}
class TopUpPayment extends PureComponent<NavigationInjectedProps, IState> {
  public state: IState = {
    isPaymentFinished: false,
    paymentFinishedEvent: {},
  };

  private queryStringToFormHTML(string: string): string {
    const parsed: ParsedQuery = queryString.parse(string);
    const formHTML: Array<string> = [];

    Object.keys(parsed).map(param => {
      formHTML.push(`<input type="hidden" value="${parsed[param]}" name="${param}" />`);
    });

    return formHTML.join('\n');
  }

  private paymentFinished() {
    const env = getConfig();
    const params = this.state.paymentFinishedEvent.url
      .split(`${env.WEB_BASE_URL}${env.PAYMENT.CLOSE_URL}`)
      .join('');
    const parseURL = queryString.parseUrl(params);
    const idRefill = parseURL.query.idRefill;

    const backRouteName = this.props.navigation.getParam('backRouteName');
    const backRouteParams = this.props.navigation.getParam('backRouteParams');

    this.props.navigation.navigate('topUpSuccessModal', {
      idRefill,
      backRouteName,
      backRouteParams,
    });
  }

  public render(): ReactNode {
    if (this.state.isPaymentFinished) {
      this.paymentFinished();
    }

    const mercanetSource: IPaymentRequest = this.props.navigation.getParam('mercanetSource');

    const HTML = `
<html>
	<head>
		<title>Mercanet Payment</title>
	</head>
	<body>
		<form action="${mercanetSource.uri}" method="POST" id="paymentForm" name="paymentForm">
			${this.queryStringToFormHTML(mercanetSource.body)}
		</form>
	</body>
	<script>
		window.onload = function() {
			document.forms["paymentForm"].submit()
		};
	</script>
</html>
`;

    const INJECTEDJAVASCRIPT = `const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `;

    return (
      <AppPage noPadding safeAreaEdgesToShow={['bottom']}>
        <LoadingView isLoading={this.state.isPaymentFinished}>
          <WebView
            incognito={true}
            source={{ html: HTML }}
            originWhitelist={['*']}
            javaScriptEnabled={true}
            onNavigationStateChange={this.handleNavigationStateChange}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            injectedJavaScript={Platform.OS === 'android' ? INJECTEDJAVASCRIPT : ''}
            style={{ width: Dimensions.get('window').width - 20, alignSelf: 'center' }}
          />
        </LoadingView>
      </AppPage>
    );
  }

  private handleNavigationStateChange = async (event: NavState): Promise<void> => {
    const env = getConfig();

    if (
      event.url &&
      event.url.includes(`${env.PAYMENT.CLOSE_URL}`) &&
      event.url.includes('idRefill') &&
      event.url.includes('idUser') &&
      !event.url.includes('redirect')
    ) {
      this.setState({ isPaymentFinished: true, paymentFinishedEvent: event });

      const isCreditCard = this.props.navigation.getParam('isCreditCard');

      if (isCreditCard) {
        const client = this.props.navigation.getParam('client');
        const orderId = this.props.navigation.getParam('orderId');

        const isCreditCard = this.props.navigation.getParam('isCreditCard');

        if (isCreditCard) {
          const client = this.props.navigation.getParam('client');
          const orderId = this.props.navigation.getParam('orderId');

          await client.mutate<{ confirmOrder: IOrder }>({
            mutation: confirmOrder,
            refetchQueries: ['getOrders'],
            variables: {
              idOrder: orderId,
              newState: OrderState.ON_HOLD,
              paymentMethod: PaymentMethod.CREDIT_CARD,
            },
          });

          Tracker.track(Event.confirmOrder, {
            paymentMethod: PaymentMethod.CREDIT_CARD,
          });

          store.dispatch(resetCart());
          this.props.navigation.navigate('order', { orderId: orderId, isCreditCard: true });
        } else {
          const params = event.url.split(`${env.WEB_BASE_URL}${env.PAYMENT.CLOSE_URL}`).join('');
          const idRefill = get(queryString.parse(params), 'idRefill');

          const backRouteName = this.props.navigation.getParam('backRouteName');
          const backRouteParams = this.props.navigation.getParam('backRouteParams');

          this.props.navigation.navigate('topUpSuccessModal', {
            idRefill,
            backRouteName,
            backRouteParams,
          });
        }
      }
    }
  };
}

export default TopUpPayment;
