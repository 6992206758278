import React, { PureComponent, ReactNode } from 'react';
import Modal, { Styles as IModalStyles } from 'react-modal';

import theme from '../../theme';

export interface IProps {
  isVisible: boolean;
  onBackPress?: () => void;
  children: Element;
}
interface IState {
  openedAt?: number;
}
class BottomSheet extends PureComponent<IProps, IState> {
  public render(): ReactNode {
    const { isVisible, onBackPress, children } = this.props;

    return (
      <Modal
        isOpen={isVisible}
        style={styles}
        ariaHideApp={false}
        onAfterOpen={() => this.setState({ openedAt: Date.now() })}
        onRequestClose={() => {
          if (Date.now() - (this.state.openedAt || 0) > 100 && onBackPress) onBackPress();
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        {children}
      </Modal>
    );
  }
}

const BORDER_WIDTH = 0;
const MODAL_MAX_WIDTH = 360;

const styles: IModalStyles = {
  content: {
    borderWidth: BORDER_WIDTH,
    bottom: 0,
    left: 0,
    maxWidth: MODAL_MAX_WIDTH,
    padding: 0,
    position: 'relative',
    right: 0,
    top: 0,
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: theme.colors.overlay,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: theme.margins.cardUnit * 2,
  },
};

export default BottomSheet;
