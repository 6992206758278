import moment from 'moment';
import { Cookies } from 'react-cookie';

interface ICookieOptions {
  domain: string;
  path: '/';
}

/* Compute Cookies options to dynamically access the window location */
const getCookieOptions = (): ICookieOptions => ({
  // @ts-ignore
  domain: window.location.hostname.replace('app.', ''),
  path: '/',
});

export const deleteCookie = (): void => {
  const cookies = new Cookies();
  cookies.remove('foodi', getCookieOptions());
};

export const createCookie = (): void => {
  const cookies = new Cookies();
  if (!cookies.get('foodi')) {
    cookies.set('foodi', moment().toISOString(), getCookieOptions());
  }
};
