import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import styles from './styles';

export interface IProps {
  big?: boolean;
  noPadding?: boolean;
  style?: StyleProp<ViewStyle>;
}

type IDefaultProps = Required<Pick<IProps, 'big' | 'noPadding'>>;

class Card extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    big: false,
    noPadding: false,
  };

  public render(): ReactNode {
    const style = StyleSheet.flatten([
      styles.card,
      this.props.big ? styles.bigCard : styles.smallCard,
      this.props.noPadding ? { padding: 0 } : {},
      this.props.style,
    ]);

    return <View style={style}>{this.props.children}</View>;
  }
}

export interface IStyle {
  bigCard: ViewStyle;
  card: ViewStyle;
  smallCard: ViewStyle;
}

export default Card;
