import { AnyAction, Reducer } from 'redux';

import { IOptinCode } from '../../types/common';

import { actionTypes } from './actions';
import { actionTypes as authenticationActionTypes } from '../authentication/actions';

export interface ISignUpState {
  email?: string;
  guestTokenId?: string;
  optins?: IOptinCode[];
  token?: string;
}

export const initialState: ISignUpState = {};

const signUpReducer: Reducer<ISignUpState> = (
  state: ISignUpState = initialState,
  action: AnyAction
): ISignUpState => {
  switch (action.type) {
    case actionTypes.SIGN_UP_SET_GUEST_TOKEN_ID:
      return {
        ...state,
        guestTokenId: action.payload.guestTokenId,
      };
    case actionTypes.SIGN_UP_SET_USER_EMAIL:
      return {
        ...state,
        email: action.payload.email,
      };
    case actionTypes.SIGN_UP_SET_OPTINS:
      return {
        ...state,
        optins: action.payload.optins,
      };
    case actionTypes.SIGN_UP_SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case actionTypes.SIGN_UP_RESET:
      return initialState;
    case authenticationActionTypes.AUTHENTICATION_LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default signUpReducer;
