import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { NavState, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import { WebView } from 'react-native-webview';
import { NavigationInjectedProps } from 'react-navigation';

import AppPage from '../../../components/AppPage';
import EmptyContentMessage from '../../../components/EmptyContentMessage';
import LoadingView from '../../../components/LoadingView';
import ModalHeader from '../../../components/ModalHeader';
import { getConfig } from '../../../environment';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';

export interface IProps extends NavigationInjectedProps {
  hasError?: boolean;
  isLoading: boolean;
  url: string;
}

class ModalContest extends PureComponent<IProps> {
  public render(): ReactNode {
    const { hasError, isLoading, url } = this.props;

    return (
      <AppPage headerComponent={<ModalHeader onClose={this.onClose} />} noPadding isModal>
        <LoadingView hasError={hasError} isLoading={isLoading} renderError={this.renderError}>
          <WebView
            source={{ uri: url }}
            renderError={this.renderError}
            onNavigationStateChange={this.handleNavigationStateChange}
          />
        </LoadingView>
      </AppPage>
    );
  }

  private handleNavigationStateChange = (event: NavState): void => {
    const env = getConfig();
    const regExp = new RegExp(`${env.CONTEST.EXIT_PAGE}|${env.CONTEST.SUCCESS_PAGE}`);
    if (event.url && regExp.test(event.url)) {
      this.props.navigation.goBack();
    }
  };

  private onClose = (): void => {
    this.props.navigation.goBack();
  };

  private renderError = (): ReactElement<ViewProps> => (
    <View style={styles.emptyContentContainer}>
      <EmptyContentMessage text={I18n.t('dashboard.modalContest.error')} />
    </View>
  );
}

interface IStyle {
  emptyContentContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  emptyContentContainer: {
    alignItems: 'center',
    paddingHorizontal: theme.margins.unit * 3,
  },
});

export default ModalContest;
