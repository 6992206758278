import React, { PureComponent, ReactNode } from 'react';
import { Text, TextInput, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { NavigationEventSubscription, NavigationInjectedProps } from 'react-navigation';
import I18n from '../../lib/i18n';
import theme from '../../theme';
import styles from './styles';
import { CSI_TABLE_POLL_INTERVAL } from '../../apollo/constants';

export interface IProps extends NavigationInjectedProps {
  value?: string;
  editable: boolean;
  isLoading: boolean;
  isValid: (value: string, incomplete: boolean) => boolean;
  getTableId: (value: string) => string;
  onChange?: (tableNumber: string, valid: boolean, idTable: string | null) => void;
  skipTableGroupValidation?: boolean;
  withCSITableValidation?: boolean;
  startPolling: (value: number) => void;
  stopPolling: () => void;
}
export interface IState {
  value: string;
  error: boolean;
  valid: boolean;
  focus: boolean;
}
class TableNumber extends PureComponent<IProps, IState> {
  state: IState = {
    value: '',
    error: false,
    focus: false,
    valid: false,
  };
  private textInput: React.RefObject<TextInput>;
  private focusListener!: NavigationEventSubscription;

  constructor(props: IProps) {
    super(props);
    this.textInput = React.createRef<TextInput>();

    const value = props.value || '';
    this.state.value = value;
    this.state.error = !props.isValid(value, true);
    this.state.valid = props.isValid(value, false);
  }

  public componentDidMount(): void {
    this.focusListener = this.props.navigation.addListener('didFocus', () => {
      if (this.textInput && this.textInput.current) {
        this.textInput.current.focus();
      }
    });
  }

  public componentWillUnmount(): void {
    this.focusListener && this.focusListener.remove();
  }

  validate(newValue: string) {
    const { isValid, getTableId, onChange = () => {} } = this.props;
    const valid = isValid(newValue, false);
    this.setState({
      value: newValue,
      error: !isValid(newValue, true),
      valid,
    });

    onChange(newValue, valid, getTableId(newValue));
  }

  componentWillReceiveProps(props: IProps) {
    const { value } = props;
    if (this.props.isLoading != props.isLoading) setImmediate(() => this.validate(value || ''));
  }

  public render(): ReactNode {
    const { editable, withCSITableValidation, startPolling, stopPolling } = this.props;
    const { value, error, focus, valid } = this.state;

    const onFocus = (): void => {
      this.setState({ focus: true });
      if (withCSITableValidation) startPolling(CSI_TABLE_POLL_INTERVAL);
    };
    const onBlur = (): void => {
      this.setState({ focus: false });
      if (withCSITableValidation) stopPolling();
    };

    this.validate(this.state.value);

    return (
      <View>
        <View>
          <View style={{ alignSelf: 'center' }}>
            <TextInput
              testID="tableNumber_input"
              style={[
                styles.tableInput,
                !editable && styles.inputDone,
                focus && editable && styles.inputFocus,
                error && styles.inputError,
              ]}
              editable={editable}
              ref={this.textInput}
              maxLength={3}
              autoFocus
              onFocus={onFocus}
              onBlur={onBlur}
              keyboardType="numeric"
              value={value}
              onChangeText={(newValue: string): void => this.validate(newValue)}
            ></TextInput>
            {!editable && <Text style={styles.table}>Table</Text>}

            {error && (
              <View style={styles.errorContainer}>
                <Icon
                  name="close"
                  color={theme.colors.white}
                  size={18}
                  style={{ textAlign: 'center' }}
                />
              </View>
            )}
            {!error && valid && (
              <View style={styles.checkedContainer}>
                <Icon
                  name="check"
                  color={theme.colors.white}
                  size={18}
                  style={{ textAlign: 'center' }}
                />
              </View>
            )}
          </View>
        </View>
        {!error && editable && (
          <Text style={[styles.caption]}>{I18n.t('dashboard.eat.tableService.table.caption')}</Text>
        )}
        {error && (
          <Text style={[styles.caption, styles.error]}>
            {I18n.t('dashboard.eat.tableService.table.error')}
          </Text>
        )}
      </View>
    );
  }
}

export default TableNumber;
