import { compose } from 'react-apollo';
import { NavigationInjectedProps } from 'react-navigation';
import { withProps } from 'recompose';

import withNavigation from '../../../../../hoc/withNavigation';

import ConfirmCancelledOrder from './ConfirmCancelledOrder.component';

interface IWithGoToEatPage {
  goToEatPage: () => void;
}

const withGoToEatPage = ({ navigation }: NavigationInjectedProps): IWithGoToEatPage => ({
  goToEatPage: (): void => {
    navigation.navigate('eat', { tab: 1 });
  },
});

export default compose(withNavigation, withProps(withGoToEatPage))(ConfirmCancelledOrder);
