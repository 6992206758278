import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import { IPosStatus, POS_STATUS } from '../../constants';
import I18n from '../../lib/i18n';
import theme from '../../theme';

export interface IProps {
  status?: IPosStatus;
}

class PosStatus extends PureComponent<IProps> {
  public render(): ReactNode {
    const style = StyleSheet.flatten([styles.text, { color: this.getStatusColor() }]);

    return <Text style={style}>{this.getStatusText()}</Text>;
  }

  private getStatusColor = (): string => {
    switch (this.props.status) {
      case POS_STATUS.OPENED:
        return theme.colors.success;
      case POS_STATUS.CLOSED:
        return theme.colors.errorMessage;
      default:
        return theme.colors.textBlack;
    }
  };

  private getStatusText = (): string => {
    switch (this.props.status) {
      case POS_STATUS.OPENED:
        return I18n.t('pointOfSale.status.opened');
      case POS_STATUS.CLOSED:
        return I18n.t('pointOfSale.status.closedNow');
      default:
        return I18n.t('pointOfSale.status.unavailable');
    }
  };
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.posStatus,
  },
});

export default PosStatus;
