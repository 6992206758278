import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../../../theme';

export interface IProps {
  filterNumber: number;
}

class DropdownFilterCounter extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <View style={styles.circle}>
        <Text style={styles.text}>{this.props.filterNumber}</Text>
      </View>
    );
  }
}

export interface IStyle {
  circle: ViewStyle;
  text: TextStyle;
}

export const CIRCLE_DIAMETER = 32;

const styles = StyleSheet.create<IStyle>({
  circle: {
    alignItems: 'center',
    backgroundColor: theme.colors.active,
    borderRadius: CIRCLE_DIAMETER / 2,
    height: CIRCLE_DIAMETER,
    justifyContent: 'center',
    marginRight: theme.margins.cardUnit,
    width: CIRCLE_DIAMETER,
  },
  text: {
    color: theme.colors.textWhite,
    ...theme.fonts.filterCounter,
  },
});

export default DropdownFilterCounter;
