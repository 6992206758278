import React, { PureComponent, ReactNode } from 'react';
import {
  View,
  RefreshControl,
  ScrollView,
  Image,
  ImageURISource,
  ImageStyle,
  ViewStyle,
  StyleSheet,
} from 'react-native';
import { OperationVariables } from 'react-apollo/types';
import { getOrder2_order_Order as IOrder } from '../../types/clickandcollect/getOrder2';

import OrderCancelCard from './OrderCancelCard';
import OrderEmailCard from './OrderEmailCard';
import OrderStatusCard from './OrderStatusCard';
import OrderSummaryCard from './OrderSummaryCard';
import OnlineHelpButton from '../OnlineHelpButton';
import containerStyle from './styles';
import OrderWithdrawalLockerMethodCard from './OrderWithdrawalLockerMethodCard';
import {
  OfferTemplateWithdrawalType,
  OrderState,
  PaymentMethod,
} from '../../types/clickandcollect/globalTypes';
import { ApolloQueryResult, NetworkStatus } from 'apollo-client';
import { IOrdersResult } from '../../types/getOrders';
import Logger from '../../lib/logger';
import theme from '../../theme';
import { NavigationInjectedProps } from 'react-navigation';
import OrderCommentCard from './OrderCommentCard';
import { isEmpty } from 'lodash';

export interface IProps extends NavigationInjectedProps {
  isCancellable: boolean;
  showCancelButton?: boolean;
  showEmailButton?: boolean;
  isLoading: boolean;
  isSchool: boolean;
  onCancelButtonPress?: () => void;
  onEmailButtonPress?: () => void;
  refetchOrders: (variables?: OperationVariables) => Promise<ApolloQueryResult<IOrdersResult>>;
  order: IOrder;
  navigation: any;
  image: ImageURISource;
  networkStatus: NetworkStatus;
  isCreditCard: boolean;
}

const LOGO_WIDTH = 90;
const LOGO_HEIGHT = 67;
class OrderContent extends PureComponent<IProps> {
  public componentDidMount(): void {
    this.props.navigation.setParams({
      handleRefresh: this.onRefresh,
    });
  }

  public onRefresh = async (): Promise<void> => {
    const { refetchOrders } = this.props;

    if (!refetchOrders) {
      return;
    }

    try {
      await refetchOrders();
    } catch (error) {
      Logger.error(error);
    }
  };

  public render(): ReactNode {
    const {
      isCancellable,
      isLoading,
      networkStatus,
      order,
      image,
      onCancelButtonPress,
      onEmailButtonPress,
      isCreditCard,
    } = this.props;

    const showCancelButton = this.props.showCancelButton !== false;
    const showEmailButton = this.props.showEmailButton === true;

    const canRenderCancelButton = isCancellable && showCancelButton;
    const canRenderEmailButton = order && order.state == 'ACCEPTED' && showEmailButton;

    const canRenderLockerMethodCard =
      order &&
      order.offer?.offerTemplate?.withdrawalType ===
        OfferTemplateWithdrawalType.CONNECTED_LOCKERS &&
      ![OrderState.REFUSED, OrderState.CANCELLED, OrderState.ABANDONED].includes(order.state);

    const isLoadingAndNoOrder = isLoading && !order;

    const handleShowQuotationInfo = () => {
      this.props.navigation.navigate('showQuotationInfo');
    };

    const isSchool = order?.isSchool;

    const customOrder =
      order?.paymentMethod === PaymentMethod.ON_SITE
        ? {
            ...order,
            admission: order?.admission,
            subsidies: order?.subsidies,
            fee: order?.subsidies,
          }
        : order;

    return (
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={networkStatus === NetworkStatus.refetch}
            onRefresh={this.onRefresh}
          />
        }
      >
        {order && (
          <>
            <View style={styles.imageContainer}>
              <Image source={image} style={styles.image} />
            </View>
            <View style={containerStyle.container}>
              <OrderStatusCard isLoading={isLoadingAndNoOrder} order={order} />
              <OrderSummaryCard
                isLoading={isLoadingAndNoOrder}
                order={customOrder}
                isSchool={isSchool}
                isCreditCard={isCreditCard}
                showQuotationInfo={handleShowQuotationInfo}
              />
              {!isEmpty(order.comment?.trim()) && (
                <OrderCommentCard isLoading={isLoadingAndNoOrder} comment={order.comment} />
              )}
              {canRenderLockerMethodCard && (
                <OrderWithdrawalLockerMethodCard
                  testID="orderDetails_lockersButton"
                  isLoading={isLoadingAndNoOrder}
                  order={order}
                />
              )}
              <OnlineHelpButton
                testID="orderDetails_onlineHelpButton"
                onlineHelp={order.offer?.offerTemplate?.onlineHelp || []}
                style={containerStyle.bottomSpacing}
              />
              {canRenderCancelButton && (
                <OrderCancelCard
                  testID="orderDetails_orderCancelButton"
                  isLoading={isLoadingAndNoOrder}
                  order={order}
                  onCancelButtonPress={onCancelButtonPress}
                />
              )}
              {canRenderEmailButton && (
                <OrderEmailCard
                  testID="orderDetails_orderEmailCardButton"
                  isLoading={isLoadingAndNoOrder}
                  order={order}
                  onEmailButtonPress={onEmailButtonPress}
                />
              )}
            </View>
          </>
        )}
      </ScrollView>
    );
  }
}

interface IStyle {
  image: ImageStyle;
  imageContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  image: {
    height: LOGO_HEIGHT,
    resizeMode: 'contain',
    width: LOGO_WIDTH,
  },
  imageContainer: {
    alignSelf: 'center',
    elevation: 1,
    top: theme.margins.cardUnit * 4,
    zIndex: 1,
  },
});

export default OrderContent;
