import React from 'react';
import EdenredAuthentication from '../../../components/CartSummaryContent/EdenredAuthentication/EdenredAuthentication';
import { NavigationInjectedProps } from 'react-navigation';
import { IToken } from '../../../services/edenred';

export interface IProps extends NavigationInjectedProps {
  authenticationURL: string;
  onTokensGenerated: (tokens: IToken | null) => void; // replace by IToken
}

const EdenredAuthenticationPage: React.FC<IProps> = (props: IProps) => {
  const { authenticationURL, onTokensGenerated, navigation } = props;
  const handleOnTokens = (token: IToken | null): void => {
    if (token) {
      onTokensGenerated(token);
    }
    navigation.goBack();
  };
  return (
    <EdenredAuthentication
      authenticationURL={authenticationURL}
      onTokensGenerated={handleOnTokens}
    />
  );
};

export default EdenredAuthenticationPage;
