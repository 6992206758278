import React, { PureComponent, ReactNode } from 'react';

import SignUpEmailForm from '../../../components/SignUpEmailForm';
import SignUpOptinsForm from '../../../components/SignUpOptinsForm';
import I18n from '../../../lib/i18n';

import SignupLayout from '../../../../web/src/components/SignupLayout';

const styles = {
  mainContent: {
    marginTop: '176px',
  },
};

export interface IProps {
  signupToken?: string | undefined;
  title: string;
}

class CheckEmail extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <SignupLayout
        title={I18n.t('signUp.headerTitle')}
        subtitle={this.props.title}
        currentStepNumber={3}
        currentStepStatus="ACTIVE"
      >
        <div style={styles.mainContent}>
          {this.props.signupToken ? <SignUpOptinsForm /> : <SignUpEmailForm />}
        </div>
      </SignupLayout>
    );
  }
}

export default CheckEmail;
