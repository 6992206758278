import { StyleSheet } from 'react-native';

import theme from '../../theme';

import { IPlatformStyle } from './QuantityFooter.component';

const CARD_BORDER_RADIUS = 4;

const containerStyles = StyleSheet.create<IPlatformStyle>({
  card: {
    ...theme.shadows.card,
    borderRadius: CARD_BORDER_RADIUS,
  },
});

export default containerStyles;
