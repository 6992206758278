import React, { PureComponent, ReactNode } from 'react';
import ArticlesList, { DisplayedArticleFamily } from '../../../../../components/ArticlesList';
import {
  TableServiceFooterType,
  TableServiceQuantityFooter
} from '../../../../../components/QuantityFooter';
import { WebPage } from '../../../../../componentsWeb';
import I18n from '../../../../../lib/i18n';
import { isMobile } from '../../../../../lib/responsive';
import { getTableServiceOffer_offer_Offer as IOffer } from '../../../../../types/tableService/getTableServiceOffer';
import { skipBeverage } from '../../../../../services/offer';

export interface IProps {
  isLoading: boolean;
  offer: IOffer;
  offerId: string;
  idTable: string;
  articleFamilyKeys: DisplayedArticleFamily[];
  numColumns?: number;
  showQuantityInFooter?: boolean;
  footerType: TableServiceFooterType;
}

class OfferArticles extends PureComponent<IProps> {
  public render(): ReactNode {
    const { offer, articleFamilyKeys, isLoading, offerId, idTable, footerType } = this.props;

    return (
      <>
        <WebPage 
          title={I18n.t('dashboard.eat.tableService.title')} 
          canGoBack 
          goBackPageName="tableServiceOfferArticles"
          goBackParams={{offerId, idTable}}
          hideLogo 
          hideNavigationMenu
          tabBarHeight={65}
        >
          <ArticlesList articleFamilyKeys={articleFamilyKeys} offer={offer} isLoading={isLoading}/>
          {!isMobile() && offer && <TableServiceQuantityFooter
            idOffer={offerId}
            footerType={footerType as TableServiceFooterType}
            shouldSkipBeverage={skipBeverage(offer)}
          />}
        </WebPage>

        {isMobile() && offer &&
          <div
            style={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }}
          >
            <TableServiceQuantityFooter
              idOffer={offerId}
              footerType={footerType as TableServiceFooterType}
              shouldSkipBeverage={skipBeverage(offer)}
            />
          </div>
        }
      </>
    );
  }
}

export default OfferArticles;
