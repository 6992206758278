import React, { PureComponent, ReactNode } from 'react';
import { ImageSourcePropType, ImageStyle, StyleSheet, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import Card from '../../../../components/Card';
import LoadingView from '../../../../components/LoadingView';
import TopUpCallbackContent from '../../../../components/TopUpCallbackContent';
import { WebPage } from '../../../../componentsWeb';
import I18n from '../../../../lib/i18n';
import { Tracker } from '../../../../services/analytics';
import theme from '../../../../theme';

export interface IProps extends NavigationInjectedProps {
  image: ImageSourcePropType;
  isLoading: boolean;
  isSuccess: boolean;
  title: string;
}

const IMAGE_THUMB_HEIGHT = 147;
const IMAGE_THUMB_WIDTH = 200;

const IMAGE_CUTLERY_HEIGHT = 85;
const IMAGE_CUTLERY_WIDTH = 375;

class TopUpCallback extends PureComponent<IProps> {
  public componentDidMount(): void {
    Tracker.trackNavigation({
      // @ts-ignore
      pathname: window.location.pathname,
      // @ts-ignore
      search: window.location.search,
    });
  }

  public render(): ReactNode {
    const { title, image, isLoading, isSuccess } = this.props;

    const imageStyle = isSuccess ? styles.imageThumb : styles.imageCutlery;

    return (
      <WebPage>
        <LoadingView isLoading={isLoading}>
          <Card style={styles.card} big>
            <TopUpCallbackContent
              title={I18n.t(title)}
              buttonTitle={I18n.t('dashboard.balance.topUp.callbackModal.goBack')}
              image={image}
              imageStyle={imageStyle}
              onClose={this.onPress}
            />
          </Card>
        </LoadingView>
      </WebPage>
    );
  }

  private onPress = (): void => {
    this.props.navigation.navigate('balance');
  };
}

export interface IStyle {
  card: ViewStyle;
  imageCutlery: ImageStyle;
  imageThumb: ImageStyle;
}

const styles = StyleSheet.create<IStyle>({
  card: {
    flex: 1,
    marginBottom: theme.margins.unit,
  },
  imageCutlery: {
    height: IMAGE_CUTLERY_HEIGHT,
    resizeMode: 'contain',
    width: IMAGE_CUTLERY_WIDTH,
  },
  imageThumb: {
    height: IMAGE_THUMB_HEIGHT,
    resizeMode: 'contain',
    width: IMAGE_THUMB_WIDTH,
  },
});

export default TopUpCallback;
