import React, { PureComponent, ReactNode } from 'react';

import SignUpPasswordForm from '../../../../src/components/SignUpPasswordForm';
import I18n from '../../../../src/lib/i18n';

import SignupLayout from '../../components/SignupLayout';

const styles = {
  mainContent: {
    marginTop: '45px',
  },
};

class UserPasswordPage extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <SignupLayout
        title={I18n.t('signUp.headerTitle')}
        subtitle={I18n.t('signUp.password.title')}
        currentStepNumber={3}
        currentStepStatus="ACTIVE"
      >
        <div style={styles.mainContent}>
          <SignUpPasswordForm />
        </div>
      </SignupLayout>
    );
  }
}

export default UserPasswordPage;
