import get from 'lodash/get';

import { PaymentMethod, PaymentTypes } from '../../types/clickandcollect/globalTypes';

import { IAmount } from '../../types/common';
import { IAppState } from '../reducer';

import { IElementQuantity } from './actions';

export const getElementsQuantityByOfferId = (state: IAppState, offerId: string) =>
  state.clickAndCollect.elements[offerId];

export const getSlotByOfferId = (state: IAppState, offerId: string) =>
  state.clickAndCollect.slots[offerId];

export const getElementsTotalQuantityByOfferId = (state: IAppState, offerId: string) =>
  (getElementsQuantityByOfferId(state, offerId) || []).reduce((prev, { quantity }) => prev + quantity, 0);

export const getElementsTotalPriceByOfferId = (state: IAppState, offerId: string): IAmount => {
  const amountToPay =
    state.clickAndCollect.elements[offerId] &&
    state.clickAndCollect.elements[offerId]
      .reduce((sum: number, element: IElementQuantity): number => {
        return sum + (element.quantity * Number(get(element, 'price.amount'))) + (element.container ? (element.quantity * Number(get(element, 'container.price.amount'))) : 0);
      }, 0)
      .toString();
  const currency =
    state.clickAndCollect.elements[offerId] &&
    get(state, `clickAndCollect.elements[${offerId}][0].price.currency`);

  return state.clickAndCollect.elements[offerId]
    ? { amount: amountToPay, currency }
    : { amount: '0', currency };
};

export const getPaymentMethodByOfferId = (state: IAppState, offerId: string | undefined, paymentTypes: PaymentTypes[] | null | undefined, haveBadge?: boolean) => {
  if (!paymentTypes) {
    return PaymentMethod.ON_SITE;
  }

  if (!haveBadge) {
    paymentTypes = paymentTypes.filter(item => item !== PaymentTypes.BADGE);
  }

  const isBadgePaymentAvailable = paymentTypes.some(item => item === PaymentTypes.BADGE);
  const defaultPayment = isBadgePaymentAvailable ? PaymentMethod.BADGE : PaymentMethod[PaymentTypes[paymentTypes[0]]];

  if (!offerId) {
    return defaultPayment;
  }

  const paymentMethod = state.clickAndCollect.paymentMethods[offerId];

  return paymentMethod ? paymentMethod : defaultPayment;
};

export const getPickupPointByOfferId = (state: IAppState, offerId: string) =>
  state.clickAndCollect?.pickupPoints?.[offerId];
