import ContentPage from './ContentPage';
import { ClickAndCollect, TableService, BookingService } from './Eat';
import Eat from './Eat';
import Home from './Home';
import Menu from './Menu';
import MenuElementDetails, {
  AllergensDisclaimer,
  InfoRecipeDisclaimer,
} from './MenuElementDetails';
import ModalContest from './ModalContest';
import ModalMarketingCard from './ModalMarketingCard';
import Profile from './Profile';
import UserBalance from './UserBalance';
import MarketingCardArticle from './Home/CardCollection/MarketingCardList/MarketingCardArticle'
import HelpOnline from './HelpOnline';
import EdenredAuthenticationPage from './EdenredAuthenticationPage';
export default {
  AllergensDisclaimer,
  ClickAndCollect,
  ContentPage,
  Eat,
  Home,
  InfoRecipeDisclaimer,
  Menu,
  MenuElementDetails,
  ModalContest,
  ModalMarketingCard,
  MarketingCardArticle,
  Profile,
  UserBalance,
  TableService,
  BookingService,
  HelpOnline,
  EdenredAuthenticationPage,
};
