import React, { PureComponent, ReactNode } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import I18n from '../../../lib/i18n';
import { cartSummaryAdmissionAndPrice_orderAdmissionAndPrice_Order as IOrderAdmissionAndPrice } from '../../../types/clickandcollect/cartSummaryAdmissionAndPrice';
import Amount from '../../Amount';
import styles from '../styles.shared';
import {
  Currency,
  OfferTemplateWithdrawalType,
  PaymentMethod,
} from '../../../types/clickandcollect/globalTypes';

interface IComponentProps {
  admissionAndPrice: IOrderAdmissionAndPrice;
  paymentMethod: string;
  haveBadge: boolean | undefined;
  withdrawalType: string | null | undefined;
  isSchool?: boolean;
  showQuotationInfo: () => void;
}

export default class AdmissionsSection extends PureComponent<IComponentProps> {
  public render(): ReactNode {
    const {
      admissionAndPrice,
      showQuotationInfo,
      paymentMethod,
      haveBadge,
      isSchool,
      withdrawalType,
    } = this.props;

    const noSubsidies = {
      __typename: 'Amount',
      amount: '-',
      currency: Currency.EUR,
    };

    const subsidies =
      paymentMethod !== PaymentMethod.ON_SITE ||
      withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
        ? admissionAndPrice.subsidies
        : noSubsidies;

    const handleShowQuotationInfo = () => {
      showQuotationInfo();
    };

    if (paymentMethod === PaymentMethod.BADGE && !haveBadge) return null;
    if (paymentMethod === PaymentMethod.ON_SITE && isSchool)
      return (
        <View style={styles.section}>
          <View style={styles.sectionContent}>
            <Text style={styles.sectionContentTextLeft}>
              {I18n.t('dashboard.eat.clickAndCollect.cartSummary.deduction')}
            </Text>
            <Text style={styles.sectionContentTextRight}>
              <Amount amount={noSubsidies} style={styles.amount} />
            </Text>
          </View>

          <TouchableOpacity onPress={handleShowQuotationInfo}>
            <View style={styles.sectionContent}>
              <Text style={styles.seeMoreText}>
                {I18n.t('dashboard.eat.clickAndCollect.cartSummary.seeMore')}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );

    return (
      <View style={styles.section}>
        <View style={styles.sectionContent}>
          <Text style={styles.sectionContentTextLeft}>
            {I18n.t('dashboard.eat.clickAndCollect.cartSummary.admission')}
          </Text>
          <Text style={styles.sectionContentTextRight}>
            {admissionAndPrice && (
              <Amount amount={admissionAndPrice.admission} style={styles.amount} />
            )}
          </Text>
        </View>

        <View style={styles.sectionContent}>
          <Text style={styles.sectionContentTextLeft}>
            {I18n.t('dashboard.eat.clickAndCollect.cartSummary.subsidies')}
          </Text>
          <Text style={styles.sectionContentTextRight}>
            {admissionAndPrice && <Amount amount={subsidies} style={styles.amount} />}
          </Text>
        </View>

        {admissionAndPrice.fee && paymentMethod !== PaymentMethod.BADGE && (
          <View style={styles.sectionContent}>
            <Text style={styles.sectionContentTextLeft}>
              {I18n.t('dashboard.eat.clickAndCollect.cartSummary.fee')}
            </Text>
            <Text style={styles.sectionContentTextRight}>
              <Amount amount={admissionAndPrice.fee} style={styles.amount} />
            </Text>
          </View>
        )}

        <TouchableOpacity onPress={handleShowQuotationInfo}>
          <View style={styles.sectionContent}>
            <Text style={styles.seeMoreText}>
              {I18n.t('dashboard.eat.clickAndCollect.cartSummary.seeMore')}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
