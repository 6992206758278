import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../../theme';

export interface IProps {
  text: string;
  disabled?: boolean;
  fontSize?: string | number;
}

class OrderButton extends PureComponent<IProps> {
  public render(): ReactNode {
    const { disabled, text, fontSize } = this.props;
    const fontSizeStyle = fontSize ? { fontSize } : {};
    const textStyle = disabled
      ? [styles.text, fontSizeStyle]
      : [styles.text, styles.active, fontSizeStyle];

    return (
      <View style={styles.container}>
        <Text style={textStyle}>{text}</Text>
      </View>
    );
  }
}

interface IStyle {
  active: TextStyle;
  container: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  active: {
    color: theme.colors.textWhite,
  },
  container: {
    justifyContent: 'center',
    paddingHorizontal: theme.margins.unit * 2,
  },
  text: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.textGray,
  },
});

export default OrderButton;
