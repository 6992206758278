import React, { PureComponent, ReactNode } from 'react';
import {
  Image,
  ImageBackground,
  ImageStyle,
  ImageURISource,
  RegisteredStyle,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import I18n from '../../../../../lib/i18n';
import { isWeb } from '../../../../../lib/responsive';
import { isDisneyHolding } from '../../../../../services/holding';
import theme from '../../../../../theme';
import { IHoldingBrandModel } from '../../../../../types/common';

import HomeMessageTitle from './HomeMessageTitle';
import { getHomeMessageConfig } from './utils';
export interface IProps {
  brand?: string;
  brandModel?: IHoldingBrandModel;
  idHolding?: string;
}

class WelcomeImage extends PureComponent<IProps> {
  public render(): ReactNode {
    const homeMessageConfig = getHomeMessageConfig();

    return (
      <View style={styles.container}>
        {!isWeb() && this.renderLogo()}
        <ImageBackground source={homeMessageConfig.image} style={styles.imageContainer}>
          <HomeMessageTitle text={I18n.t(homeMessageConfig.message)} />
        </ImageBackground>
      </View>
    );
  }

  private getSource = (): ImageURISource | undefined => {
    const { brandModel } = this.props;
    if (brandModel) {
      return { uri: brandModel.whiteLogo };
    }

    return undefined;
  };

  private getSourceStyle = (): RegisteredStyle<ImageStyle> => {
    const { idHolding } = this.props;

    if (isDisneyHolding(idHolding)) {
      return styles.rightLogoBienvenue;
    }

    return styles.rightLogo;
  };

  private renderLogo = (): ReactNode => {
    const brandSource = this.getSource();
    const brandStyle = this.getSourceStyle();

    return (
      <>
        <View style={styles.leftLogoContainer}>
          <Image style={styles.leftLogo} resizeMode="contain" source={theme.images.logoFullWhite} />
        </View>
        <View style={styles.rightLogoContainer}>
          {brandSource && <Image style={brandStyle} resizeMode="contain" source={brandSource} />}
        </View>
      </>
    );
  };
}

interface IStyle {
  container: ViewStyle;
  imageContainer: ImageStyle;
  leftLogo: ImageStyle;
  leftLogoContainer: ViewStyle;
  rightLogo: ImageStyle;
  rightLogoBienvenue: ImageStyle;
  rightLogoContainer: ViewStyle;
}

const IMAGE_CONTAINER_SIZE = isWeb() ? 290 : 250;
const LEFT_LOGO_HEIGHT = 30;
const LEFT_LOGO_WIDTH = 100;
const RIGHT_LOGO_HEIGHT = 20;
const RIGHT_LOGO_WIDTH = 50;
const RIGHT_BIENVENUE_LOGO_HEIGHT = 26;
const RIGHT_BIENVENUE_LOGO_WIDTH = 79;

const styles = StyleSheet.create<IStyle>({
  container: {
    height: IMAGE_CONTAINER_SIZE,
    marginBottom: theme.margins.cardUnit * 2,
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
    padding: theme.margins.cardUnit * 5,
  },
  leftLogo: {
    height: LEFT_LOGO_HEIGHT,
    width: LEFT_LOGO_WIDTH,
  },
  leftLogoContainer: {
    left: 10,
    position: 'absolute',
    top: 10,
    zIndex: 1,
  },
  rightLogo: {
    height: RIGHT_LOGO_HEIGHT,
    width: RIGHT_LOGO_WIDTH,
  },
  rightLogoBienvenue: {
    height: RIGHT_BIENVENUE_LOGO_HEIGHT,
    width: RIGHT_BIENVENUE_LOGO_WIDTH,
  },
  rightLogoContainer: {
    position: 'absolute',
    right: 10,
    top: 15,
    zIndex: 1,
  },
});

export default WelcomeImage;
