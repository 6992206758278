import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

import theme from '../../theme';

import Button, { IProps as IButtonProps } from '../Button';

class FlatButton extends PureComponent<IButtonProps> {
  public render(): ReactNode {
    const { style, ...rest } = this.props;

    return (
      <Button
        style={[styles.button, style]}
        textStyle={styles.text}
        borderless
        useForeground
        {...rest}
      />
    );
  }
}

export interface IStyle {
  button: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  button: {
    backgroundColor: 'transparent',
  },
  text: {
    color: theme.colors.blue,
  },
});

export default FlatButton;
