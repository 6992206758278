import React from 'react';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OperationVariables } from 'react-apollo';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { compose } from 'recompose';
import { POLL_INTERVAL } from '../../apollo/constants';
import { POS_TYPE } from '../../constants';
import { INode } from '../../types/common';
import { IPos } from '../../types/getPointsOfSaleWithMenu';
import { IPollable } from '../../types/pollable';
import { ApolloQueryResult } from 'apollo-client';
import { OfferTemplateType, OfferTemplateWithdrawalType } from '../../types/clickandcollect/globalTypes';

const getPointsOfSale = loader('./getPointsOfSale.gql');

export interface IWithPointsOfSale {}

export interface IGraphQLProps {
  data: Array<INode<IPos>>;
  isLoading: boolean;
  refetchPOS?: (variables?: OperationVariables) => Promise<ApolloQueryResult<void>>;
}

export type IProps = IGraphQLProps;
export type IComposeProps = IGraphQLProps;
export type IHocProps<T> = Omit<T, keyof IComposeProps>;

const withPointsOfSale = <T extends object>(
  Component: React.ComponentType<T>
): React.ComponentType<IHocProps<T>> =>
  compose<T, IHocProps<T>>(
    graphql(getPointsOfSale, {
      options: (props: IWithPointsOfSale & IPollable): QueryOpts => ({
        pollInterval: props.isPollable ? POLL_INTERVAL : undefined,
        fetchPolicy: 'network-only',
        variables: {
          offerTemplateType: `${OfferTemplateType.CLICK_COLLECT},${OfferTemplateType.TABLE_SERVICE},${OfferTemplateType.BOOKING_SERVICE}`,
          offerTemplateWithdrawalType: `${OfferTemplateWithdrawalType.CONNECTED_LOCKERS},${OfferTemplateWithdrawalType.POS_AT_SITE},${OfferTemplateWithdrawalType.POS_TAKE_AWAY},${OfferTemplateWithdrawalType.TABLE_SERVICE},${OfferTemplateWithdrawalType.POS_CLICK_SERVE},${OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT}`,
          type: `${POS_TYPE.BRASSERIE},${POS_TYPE.CAFETERIA},${POS_TYPE.SELF},${POS_TYPE.OTHER}`,
        },
      }),
      props: (props: OptionProps<IPollable>): IGraphQLProps => ({
        data: get(props, 'data.getUser.currentHoldingView.getPos.edges') || [],
        isLoading: get(props, 'data.loading'),
        refetchPOS: props.data!.refetch,
      }),
    })
  )(Component);
export default withPointsOfSale;