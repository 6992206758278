import { ApolloQueryResult } from 'apollo-client';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import uniqWith from 'lodash/uniqWith';
import { graphql } from 'react-apollo/graphql';
import { OperationVariables, OptionProps, QueryOpts } from 'react-apollo/types';
import { connect } from 'react-redux';
import { compose, mapProps, withProps } from 'recompose';
import { NavigationInjectedProps } from 'react-navigation';

import { POLL_INTERVAL } from '../../../../apollo/constants';
import { getPointsOfSaleActiveFilters } from '../../../../redux/pointsOfSale/selectors';
import { IAppState } from '../../../../redux/reducer';
import { INode, IPosType } from '../../../../types/common';
import { IPos } from '../../../../types/getPointsOfSale';
import { IUserResult } from '../../../../types/getPointsOfSaleWithMenu';
import { IPollable } from '../../../../types/pollable';
import withBookingOrders from '../../../../hoc/withBookingOrders';
import { IFilter } from '../../../../components/FilterDropdown/FilterDropdown.component';
import PointsOfSaleList, { IProps } from './PointsOfSaleList.component';
import withOrders from '../../../../hoc/withOrders';
import withOffers from '../../../../hoc/withOffers';
import { IGraphQLProps as IGraphQLOrderProps, IWithSkipProps } from '../../../../hoc/withOrders/withOrders';
import { OfferTemplateType, OfferTemplateWithdrawalType } from '../../../../types/clickandcollect/globalTypes';
import withNavigation from '../../../../hoc/withNavigation';

const getPointsOfSale = loader('../../../../queries/getPointsOfSale.gql');
export interface IMapStateToProps {
  activeFilters: string[];
  withoutTableServiceOrders?: boolean;
  withdrawalType?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  activeFilters: getPointsOfSaleActiveFilters(state),
  withoutTableServiceOrders: false,
  withdrawalType: 'POS_AT_SITE,POS_CLICK_SERVE,TABLE_SERVICE,INSTANT_CLICK_COLLECT',
});

interface IWithFiltersProps {
  filters: IFilter[];
}

const withFiltersProps = (ownProps: IProps): IWithFiltersProps => ({
  filters: uniqWith(
    reduce(
      ownProps.pointsOfSale,
      (filters: IFilter[], pointOfSale: INode<IPos>): IFilter[] => {
        const label = get(pointOfSale, 'node.zone.name');
        const id = get(pointOfSale, 'node.zone.id');

        if (label && id) {
          filters.push({ id, label });
        }

        return filters;
      },
      []
    ),
    isEqual
  ),
});

const withFilteredPointsOfSale = (
  ownProps: IProps & IConnectedProps
): IProps & IConnectedProps => ({
  ...ownProps,
  pointsOfSale:
    ownProps.activeFilters.length === 0 || !ownProps.withFilters
      ? ownProps.pointsOfSale
      : ownProps.pointsOfSale.filter((pointOfSale: INode<IPos>): boolean =>
        ownProps.activeFilters.includes(get(pointOfSale, 'node.zone.id'))
      ),
});

const withRenameClickAndCollectConflictingProps = (ownProps: IProps & IGraphQLOrderProps): IProps & IGraphQLOrderProps => {

  return {
    ...ownProps,
    isTableServiceOrdersLoading: ownProps.isOrdersLoading,
    tableServiceOrders: ownProps.orders
  };
};

export interface IExternalProps {
  type?: IPosType;
}
export interface IGraphQLProps {
  isLoading: boolean;
  pointsOfSale: Array<INode<IPos>>;
  refetch: (variables?: OperationVariables) => Promise<ApolloQueryResult<IUserResult>>;
}
export type IConnectedProps = IMapStateToProps & IGraphQLProps & IWithFiltersProps & NavigationInjectedProps;
export type IContainerProps = Omit<IProps & IExternalProps & IPollable, keyof IConnectedProps>;

const defaultOperationOptions = {
  options: (props: IMapStateToProps & IExternalProps & IPollable): QueryOpts => ({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    pollInterval: props.isPollable ? POLL_INTERVAL : undefined,
    variables: {
      type: props.type,
      offerTemplateType: `${OfferTemplateType.CLICK_COLLECT},${OfferTemplateType.TABLE_SERVICE},${OfferTemplateType.BOOKING_SERVICE}`,
      offerTemplateWithdrawalType: `${OfferTemplateWithdrawalType.CONNECTED_LOCKERS},${OfferTemplateWithdrawalType.POS_AT_SITE},${OfferTemplateWithdrawalType.POS_TAKE_AWAY},${OfferTemplateWithdrawalType.TABLE_SERVICE},${OfferTemplateWithdrawalType.POS_CLICK_SERVE},${OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT}`,
    },
  }),
  props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
    isLoading: props.data!.loading,
    pointsOfSale:
      get(props, 'data.getUser.guests.edges[0].node.pos.edges') ||
      get(props, 'data.getUser.currentHoldingView.pos.edges') ||
      [],
    refetch: get(props, 'data.refetch'),
  }),
  skip: (props: IWithSkipProps) => !!props.skip,
};

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps),
  withNavigation,
  graphql(getPointsOfSale, defaultOperationOptions),
  withProps(withFiltersProps),
  withOffers,
  withOrders,
  // must be used before withBookingOrders, due to props name collision, like orders and isOrdersLoading
  mapProps(withRenameClickAndCollectConflictingProps),
  withBookingOrders,
  mapProps(withFilteredPointsOfSale),
)(PointsOfSaleList);
