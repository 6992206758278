import React, { PureComponent, ReactNode } from 'react';
import { Animated, ImageProps, ImageURISource, StyleSheet, View, ViewStyle } from 'react-native';

import { ImageComponent } from './ImageComponent/ImageComponent';

export interface IProps extends ImageProps {
  thumbnailSource: ImageURISource;
}

export interface IState {
  imageAnimated: Animated.Value;
  thumbnailAnimated: Animated.Value;
}

class ProgressiveImage extends PureComponent<IProps, IState> {
  public state: IState = {
    imageAnimated: new Animated.Value(0),
    thumbnailAnimated: new Animated.Value(0),
  };
  public render(): ReactNode {
    const { thumbnailSource, source, style, ...props } = this.props;

    const hasSource = Object.keys(source).length !== 0;

    return (
      <View style={[styles.imageContainer, style]}>
        {thumbnailSource.uri && (
          <Animated.View style={[{ opacity: this.state.thumbnailAnimated }, style]}>
            <ImageComponent
              {...props}
              source={thumbnailSource}
              style={style}
              onLoad={this.handleThumbnailLoad}
              blurRadius={1}
            />
          </Animated.View>
        )}

        <Animated.View
          style={[
            styles.imageOverlay,
            { opacity: !hasSource ? new Animated.Value(1) : this.state.imageAnimated },
            style,
          ]}
        >
          <ImageComponent
            {...props}
            source={source}
            style={[styles.imageOverlay, style]}
            onLoad={this.onImageLoad}
          />
        </Animated.View>
      </View>
    );
  }

  // tslint:disable-next-line: typedef
  private handleThumbnailLoad = () => {
    Animated.timing(this.state.thumbnailAnimated, {
      toValue: 1,
    }).start();
  };

  // tslint:disable-next-line: typedef
  private onImageLoad = () => {
    Animated.timing(this.state.imageAnimated, {
      toValue: 1,
    }).start();
    Animated.timing(this.state.thumbnailAnimated, {
      toValue: 0,
    }).start();
  };
}

interface IStyle {
  imageContainer: ViewStyle;
  imageOverlay: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  imageContainer: {},
  imageOverlay: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

export default ProgressiveImage;
