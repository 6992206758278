import find from 'lodash/find';
import some from 'lodash/some';

import { OPTINS_ALLERGEN_CODE, OPTINS_CODE } from '../constants';
import { IOptinCode, IOptinsDictionary, IUserOptin } from '../types/common';

const NOTIFIABLE_OPTINS = [
  OPTINS_CODE.MAIL_FOODI_MARKETING,
  OPTINS_CODE.PUSH_FOODI_MARKETING,
  OPTINS_CODE.MAIL_LOW_BALANCE,
  OPTINS_CODE.PUSH_LOW_BALANCE,
];

const isNotifiable = (optin: IUserOptin): boolean =>
  !!find(
    NOTIFIABLE_OPTINS,
    (notifiableOptinCode: IOptinCode) => notifiableOptinCode === optin.code
  );

export const getNotificationStatusFromOptins = (optins: IUserOptin[]): string => {
  if (some(optins, isNotifiable)) {
    return 'dashboard.profile.notifications.enabled';
  }

  return 'dashboard.profile.notifications.disabled';
};

export const isOptinActivated = (optins: IUserOptin[], optinName: IOptinCode): boolean => {
  let optinActivated = false;

  optins.forEach((userOptin: IUserOptin) => {
    if (userOptin.code === optinName) {
      optinActivated = true;
    }
  });

  return optinActivated;
};

export const isRecipeDisclaimerAccepted = (optins: IUserOptin[]): boolean => {
  return some(optins, { code: OPTINS_ALLERGEN_CODE.FOODI_ALLERGEN_DISCLAIMER });
};

export const getOptinValueByCode = (optins: IUserOptin[], code: string): boolean =>
  !!find(optins, (optin: IUserOptin): boolean => optin.code === code);

export const createOptinsDictionary = (optins: IUserOptin[]): IOptinsDictionary => {
  const dictionary = {} as IOptinsDictionary;

  Object.keys(OPTINS_CODE).forEach((nameOptin: string) => {
    dictionary[nameOptin] = getOptinValueByCode(optins, nameOptin);
  });

  return dictionary;
};
