import { loader } from 'graphql.macro';
import get from 'lodash/get';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { getConfig } from '../../environment';
import withGuestPaymentProfile from '../../hoc/withGuestPaymentProfile';
import withNavigation from '../../hoc/withNavigation';
import I18n from '../../lib/i18n';
import Logger from '../../lib/logger';
import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';
import { Tracker } from '../../services/analytics';
import { submitToUrl } from '../../services/submitToUrl';
import { DISPLAYED_AMOUNTS, formatCurrency } from './common';
import TopUpCard, { IProps, ITopUpButtonConfig } from './TopUpCard.component';

const getPaymentRequest = loader('../../queries/getPaymentRequest.gql');

export interface IMapStateToProps {
  token?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

export type IConnectedProps = IMapStateToProps & NavigationInjectedProps & WithApolloClient<{}>;

const getTopUpButtonConfigs = (props: IConnectedProps): ITopUpButtonConfig[] =>
  DISPLAYED_AMOUNTS.map(
    (amount: string): ITopUpButtonConfig => {
      const env = getConfig();

      return {
        label: formatCurrency(amount),
        onPress: async (): Promise<void> => {
          const result = await props.client.query<{}>({
            fetchPolicy: 'network-only',
            query: getPaymentRequest,
            variables: {
              amount,
              token: props.token,
              userRedirectUrl: `${env.WEB_BASE_URL}/balance/payment-callback/`,
            },
          });

          const method: string = get(result, 'data.mercanetV2.method');
          const url: string = get(result, 'data.mercanetV2.uri');
          const body: string = get(result, 'data.mercanetV2.body');

          try {
            submitToUrl(url, method, body);
          } catch (error) {
            Logger.error(error);
          } finally {
            Tracker.trackNavigation({ pathname: 'topUpPayment' });
          }
        },
      };
    }
  ).concat([
    {
      label: I18n.t('topUp.other'),
      onPress: async (): Promise<void> => {
        props.navigation.navigate('balance/other-amount');
      },
    },
  ]);

interface IWithAmountsProps {
  topUpButtonConfigs: ITopUpButtonConfig[];
}

const withAmountsProps = (props: IConnectedProps): IWithAmountsProps => ({
  topUpButtonConfigs: getTopUpButtonConfigs(props),
});

export default compose<IProps, {}>(
  connect(mapStateToProps),
  withApollo,
  withGuestPaymentProfile,
  withNavigation,
  withProps(withAmountsProps)
)(TopUpCard);
