import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import EmptyContentMessage from '../../../components/EmptyContentMessage';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';

class ModalMarketingCard extends PureComponent {
  public render(): ReactNode {
    return (
      <View style={styles.emptyContentContainer}>
        <EmptyContentMessage text={I18n.t('dashboard.modalContest.error')} />
      </View>
    );
  }
}

interface IStyle {
  emptyContentContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  emptyContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.margins.unit * 3,
  },
});

export default ModalMarketingCard;
