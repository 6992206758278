import { StyleSheet } from 'react-native';

import theme from '../../../../theme';

import { IPlatformStyle } from './MenuElementInformationContent.component';

const styles = StyleSheet.create<IPlatformStyle>({
  button: {},
  container: {},
  disclaimerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  textContainer: { flex: 1, flexBasis: 'auto' },
  title: {
    marginBottom: 2 * theme.margins.cardUnit,
  },
});

export default styles;
