import { ApolloQueryResult } from 'apollo-client';
import { Formik, FormikActions } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { Keyboard } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { ActionCreator } from 'redux';

import Logger from '../../lib/logger';
import { ISetGuestTokenIdAction, ISetGuestTokenIdPayload } from '../../redux/signUp/actions';
import { validateSearchGuestResult } from '../../services/form/validator';
import { VALIDATOR_ERROR_NAME } from '../../services/form/validatorError';
import { ISearchGuestResult } from '../../types/searchGuest';

import SignUpIdentityFormikForm, { IValues } from './SignUpIdentityFormikForm';

export interface IProps extends NavigationInjectedProps {
  badgeNumber?: string;
  lastName?: string;
  holdingId: string;
  searchGuest: (
    badgeNumber: string,
    lastName: string,
    holdingId: string
  ) => Promise<ApolloQueryResult<ISearchGuestResult>>;
  setGuestTokenId: (payload: ISetGuestTokenIdPayload) => ActionCreator<ISetGuestTokenIdAction>;
}

class SignUpIdentityForm extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        initialValues={{
          badgeNumber: this.props.badgeNumber || '',
          lastName: this.props.lastName || '',
        }}
        component={SignUpIdentityFormikForm}
      />
    );
  }

  private handleSubmit = async (
    values: IValues,
    { setErrors, setSubmitting }: FormikActions<IValues>
  ): Promise<void> => {
    if (!values.badgeNumber || !values.lastName) {
      setSubmitting(false);

      return;
    }

    setErrors({ globalError: undefined });

    try {
      const { data } = await this.props.searchGuest(
        values.badgeNumber,
        values.lastName,
        this.props.holdingId
      );
      validateSearchGuestResult(data);

      Keyboard.dismiss();
      if (data.searchGuest!.user && data.searchGuest!.user !== null) {
        this.props.navigation.navigate('signUpGuestExist', {
          guestEmail: data.searchGuest!.user!.email,
        });
      } else {
        this.props.setGuestTokenId({ guestTokenId: data.searchGuest!.guestTokenId });
        this.props.navigation.navigate('signUpEmail', {
          email: this.props.navigation.getParam('email'),
        });
      }
    } catch (error) {
      switch (error.name) {
        case VALIDATOR_ERROR_NAME:
          setErrors(error.toFormikError());
          break;
        default:
          Logger.error(error);
      }
    } finally {
      setSubmitting(false);
    }
  };
}

export default SignUpIdentityForm;
