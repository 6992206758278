import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import withNavigation from '../../hoc/withNavigation';
import { IAppState } from '../../redux/reducer';
import { selectGuestTokenId, selectOptins, selectUserEmail } from '../../redux/signUp/selectors';
import { IOptinCode } from '../../types/common';
import { ISignUpResult } from '../../types/signUp';

import SignUpPasswordForm, { IProps } from './SignUpPasswordForm.component';

const signUp = loader('../../queries/signUp.gql');

export interface IMapStateToProps {
  email?: string;
  guestTokenId?: string;
  optins?: IOptinCode[];
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  email: selectUserEmail(state),
  guestTokenId: selectGuestTokenId(state),
  optins: selectOptins(state),
});

type ISignUpMutation = (
  email: string,
  password: string,
  guestTokenId: string,
  language: string,
  optins: IOptinCode[]
) => Promise<FetchResult>;

const signUpHandler = (props: WithApolloClient<{}>): ISignUpMutation => async (
  email: string,
  password: string,
  guestTokenId: string,
  language: string,
  optins: IOptinCode[]
): Promise<FetchResult> =>
  props.client.mutate<ISignUpResult>({
    mutation: signUp,
    variables: {
      input: { email, password, guestTokenId, language, optins },
    },
  });

export type IConnectedProps = NavigationInjectedProps &
  IMapStateToProps & { signUp: ISignUpMutation } & WithApolloClient<{}>;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(mapStateToProps),
  withNavigation,
  withApollo,
  withHandlers({ signUp: signUpHandler })
)(SignUpPasswordForm);
