import React, { PureComponent, ReactNode } from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableNativeFeedbackProps,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';

const ANDROID_VERSION_LOLLIPOP = 21;

export interface IProps extends TouchableOpacityProps, TouchableNativeFeedbackProps {
  activeOpacity?: number;
  borderless?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  pressColor?: string;
  style?: StyleProp<ViewStyle>;
  useOpacity?: boolean;
}

type IDefaultProps = Required<
  Pick<IProps, 'activeOpacity' | 'borderless' | 'disabled' | 'pressColor' | 'useOpacity'>
>;

class Touchable extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    activeOpacity: 0.7,
    borderless: false,
    disabled: false,
    pressColor: 'rgba(0, 0, 0, .32)',
    useOpacity: false,
  };

  public render(): ReactNode {
    const { style, useOpacity, ...otherProps } = this.props;
    const activeOpacity = this.props.disabled ? 1 : this.props.activeOpacity;

    if (Platform.OS === 'android' && Platform.Version >= ANDROID_VERSION_LOLLIPOP && !useOpacity) {
      return (
        <TouchableNativeFeedback
          {...otherProps}
          delayPressIn={0}
          background={TouchableNativeFeedback.Ripple(this.props.pressColor!, this.props.borderless)}
          style={null}
          onPress={this.onPress}
        >
          <View style={[styles.container, style]}>{this.props.children}</View>
        </TouchableNativeFeedback>
      );
    }

    return (
      <TouchableOpacity
        {...otherProps}
        activeOpacity={activeOpacity}
        style={[styles.container, style]}
        onPress={this.onPress}
      >
        {this.props.children}
      </TouchableOpacity>
    );
  }

  // ReactNative Touchable onPress props has GestureResponderEvent param
  // But we want to call our Touchable onPress props without this param
  private onPress = (): void => {
    if (this.props.onPress && !this.props.disabled) {
      return this.props.onPress();
    }
  };
}

export interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    opacity: 1,
  },
});

export default Touchable;
