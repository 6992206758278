import React, { PureComponent, ReactNode } from 'react';
import {
  ImageURISource,
  View,
  ViewStyle,
} from 'react-native';
import CancelOrderModal from '../../../../../../components/CancelOrderModal';
import EmailOrderModal from '../../../../../../components/EmailOrderModal';
import OrderContent from '../../../../../../components/OrderContent';
import { isWeb } from '../../../../../../lib/responsive';
import { getOrder2_order_Order as IOrder } from '../../../../../../types/clickandcollect/getOrder2';
import platformStyles from './styles';

export interface IProps {
  image: ImageURISource;
  isLoading: boolean;
  order: IOrder;
  showCancelButton?: boolean;
  showEmailButton?: boolean;
  isCreditCard: boolean;
}

export interface IState {
  isCancelModalVisible: boolean;
  isMailModalVisible: boolean;
}

class OrderDetailsContent extends PureComponent<IProps, IState> {
  public state: IState = {
    isCancelModalVisible: false,
    isMailModalVisible: false,
  };

  public render(): ReactNode {
    return (
      <>
        <View style={platformStyles.contentContainer}>          
            <OrderContent
              order={this.props.order}
              isLoading={this.props.isLoading}
              showCancelButton={this.props.showCancelButton}
              showEmailButton={this.props.showEmailButton === true}
              onCancelButtonPress={this.showCancelModal}
              onEmailButtonPress={this.showMailModal}
              image={this.props.image}
              isCreditCard={this.props.isCreditCard}
            />
        </View>
        {/* Call the modal just in case of mobile and not for web because the same modal for web is called directly inside CancelOrderButton.component.web.
        We've done it this way in order to fix the behaviour of modal's appearance for web in mobile resolution */}
        {this.props.order && !isWeb() && (
          <CancelOrderModal
            testID="cancelOrderModal"
            onLeftButtonPress={this.hideCancelModal}
            onRightButtonPress={this.hideCancelModal}
            order={this.props.order}
            isVisible={this.state.isCancelModalVisible}
          />
        )}
        {this.props.order && !isWeb() && (
          <EmailOrderModal
            testID="emailOrderModal"
            onButtonPress={this.hideMailModal}
            order={this.props.order}
            isVisible={this.state.isMailModalVisible}
          />
        )}
      </>
    );
  }

  private hideCancelModal = (): void => {
    this.setState({ isCancelModalVisible: false });
  };

  private showCancelModal = (): void => {
    this.setState({ isCancelModalVisible: true });
  };

  private hideMailModal = (): void => {
    this.setState({ isMailModalVisible: false });
  };

  private showMailModal = (): void => {
    this.setState({ isMailModalVisible: true });
  };
}

export interface IPlatformStyle {
  contentContainer: ViewStyle;
}

export default OrderDetailsContent;
