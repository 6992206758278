import { compose } from 'recompose';

import withBalanceDetails from '../../../../hoc/withBalanceDetails';
import { connect } from 'react-redux';

import BalanceComponent, { IProps } from './Balance.component';
import {
  getHoldingId,
  getBadgeNumber,
  getIsBadgeRequired,
  getIsBalanceDisabled,
  getExternalRefillUrl,
} from '../../../../../src/redux/holding/selectors';
import { IAppState } from '../../../../../src/redux/reducer';

export interface IMapStateToProps {
  idHolding?: string;
  badgeNumber?: string;
  isBadgeRequired?: boolean;
  isBalanceDisabled?: boolean;
  externalRefillUrl?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  idHolding: getHoldingId(state),
  badgeNumber: getBadgeNumber(state),
  isBadgeRequired: getIsBadgeRequired(state),
  isBalanceDisabled: getIsBalanceDisabled(state),
  externalRefillUrl: getExternalRefillUrl(state),
});

export default compose<IProps, IProps>(
  connect(mapStateToProps),
  withBalanceDetails
)(BalanceComponent);
