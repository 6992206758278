import { NavigationInjectedProps } from 'react-navigation';
import { compose, withHandlers } from 'recompose';

import withNavigation from '../../../hoc/withNavigation';

import Transaction, { IProps } from './Transaction.component';

const onPressHandler = (
  props: NavigationInjectedProps & IContainerProps
): (() => void) => (): void => {
  props.navigation.navigate('transactionDetails', { transactionId: props.transaction.id });
};

export type IContainerProps = Omit<IProps, 'onPress'>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withHandlers({ onPress: onPressHandler })
)(Transaction);
