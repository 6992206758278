import { ApolloQueryResult } from 'apollo-client';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { graphql } from 'react-apollo/graphql';
import { QueryOpts } from 'react-apollo/types';
import { OperationVariables, OptionProps } from 'react-apollo/types';
import { compose, withProps } from 'recompose';

import { getOrders as IOrdersResult } from '../../types/clickandcollect/getOrders';
import { getOrdersFragment_edges_node_Order as IOrder } from '../../types/clickandcollect/getOrdersFragment';

const getOrders = loader('../../queries/clickandcollect/getOrders.gql');
const getUserInfo = loader('../../queries/getUserInfo.gql');

interface IComponentProps {
  isLoading?: boolean;
  withoutTableServiceOrders?: boolean;
}

interface IWithIdGuest {
  idGuest?: string;
  isGuestLoading?: boolean;
}

export interface IGraphQLProps {
  hasError: boolean;
  isOrdersLoading: boolean;
  orders: IOrder[];
  refetchOrders: (variables?: OperationVariables) => Promise<ApolloQueryResult<IOrdersResult>>;
}

interface IWithIsLoading {
  isLoading: boolean;
}

const withIsLoading = (props: IGraphQLProps & IComponentProps & IWithIdGuest): IWithIsLoading => ({
  isLoading: props.isLoading || props.isOrdersLoading || !!props.isGuestLoading
});

export interface IWithSkipProps {
  skip?: boolean;
}

const withOrders = <T extends IComponentProps = {}>(
  Component: React.ComponentType<T & IGraphQLProps & IWithIsLoading>
): React.ComponentType<T> => {
  class ComponentWithOrders extends PureComponent<T & IGraphQLProps & IWithIsLoading> {

    public render(): ReactNode {
      return <Component {...this.props} />;
    }
  }

  return compose<T & IGraphQLProps & IWithIsLoading, T & IComponentProps>(
    graphql(getUserInfo, {
      props: (props: OptionProps<{}>): IWithIdGuest => ({
        idGuest: get(props, 'data.getUser.guests.edges[0].node.id') || '',
        isGuestLoading: get(props, 'data.loading'),
      }),
    }),
    graphql(getOrders, {
      options: (props: IWithIdGuest & IComponentProps): QueryOpts => ({
        variables: {
          idGuest: props.idGuest,
          fromDate: moment()
            .subtract(1, 'day')
            .startOf('day')
            .toISOString(),
          offerTemplateTypes: props.withoutTableServiceOrders ? 'CLICK_COLLECT' : 'CLICK_COLLECT,TABLE_SERVICE',
          withdrawalTypes : props.withoutTableServiceOrders ? 'POS_TAKE_AWAY,CONNECTED_LOCKERS,CLICK_AND_PICK_UP' : 'POS_AT_SITE,POS_CLICK_SERVE,TABLE_SERVICE,INSTANT_CLICK_COLLECT'
        },
      }),
      props: (props: OptionProps<{}>): IGraphQLProps => ({
        hasError: !!get(props, 'data.error'),
        isOrdersLoading: get(props, 'data.loading'),
        orders: [
          ...(get(props, 'data.active.edges') || []),
          ...(get(props, 'data.recent.edges') || []),
        ].map(o => o.node),
        refetchOrders: get(props, 'data.refetch'),
      }),
      skip: (props: IWithSkipProps): boolean => !!props.skip,
    }),
    withProps(withIsLoading)
  )(ComponentWithOrders);
};

export default withOrders;
