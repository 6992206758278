import base64 from 'base-64';
import last from 'lodash/last';
import split from 'lodash/split';

export enum Entity {
  HOLDING = 'Holding',
}

export const uuidToId = (uuid: string): string | undefined => {
  const decodedId = base64.decode(uuid);
  if (!decodedId) {
    throw new Error('Cannot decode UUID');
  }

  return last(split(decodedId, ':'));
};

export const idToUuid = (id: string, entity: Entity): string => {
  if (process.env.NODE_ENV === 'development') {
    return `${entity}:${id}`;
  }
  return base64.encode(`${entity}:${id}`);
};
