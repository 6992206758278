import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import I18n from '../../lib/i18n';
import { formatScheduleHours, getScheduleHoursOfDay } from '../../services/schedulesFormatter';
import theme from '../../theme';
import { IPosSchedule } from '../../types/common';

export interface IProps {
  dayOfWeek?: number;
  defaultMessage?: string;
  schedules: IPosSchedule[];
}

type IDefaultProps = Required<Pick<IProps, 'defaultMessage'>>;

class PosSchedule extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    defaultMessage: '',
  };

  public render(): ReactNode {
    return (
      <Text numberOfLines={1} style={styles.text}>
        {this.formatScheduleText()}
      </Text>
    );
  }

  private formatScheduleText = (): string => {
    const { dayOfWeek, defaultMessage, schedules } = this.props;

    if (schedules.length === 0) {
      // There is no schedules defined for the point of sale
      return I18n.t('pointOfSale.schedulesUnavailable');
    }

    const scheduleHours = getScheduleHoursOfDay(schedules, dayOfWeek);
    const formattedHours = formatScheduleHours(scheduleHours);
    if (formattedHours.length < 1) {
      // There is no schedules defined for the point of sale on a specific day
      return defaultMessage! ;
    }

    let scheduleText = `${formattedHours[0]} - ${formattedHours[1]}`;
    for (let i = 2; i < formattedHours.length; i += 2) {
      scheduleText += ` | ${formattedHours[i]} - ${formattedHours[i + 1]}`;
    }

    return scheduleText;
  };
}

export interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create({
  text: {
    color: theme.colors.textBlack,
    ...theme.fonts.schedule,
  },
});

export default PosSchedule;
