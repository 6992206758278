import React, { PureComponent, ReactNode } from 'react';
import { Image, StyleSheet, Text, View, Platform } from 'react-native';
import moment from 'moment';
import { RFValue } from 'react-native-responsive-fontsize';
import I18n from '../../lib/i18n';
import theme from '../../theme';
import LargeButton from '../LargeButton';
import { LARGE_BUTTON_THEME } from '../LargeButton/LargeButton.component';
import LoadingView from '../LoadingView';
import { isWeb } from '../../lib/responsive';
import CancelOrderModal from './../CancelOrderModal';
import { moderateScale } from '../../services/scaleService';
import { transformSummaryTime } from '../../services/offersFormatter';

interface IComponentProps {
  isLoading: boolean;
  order?: any;
  refetch?: () => void;
  editOrder: (editMode: boolean) => void;
  activeOffer?: any;
  testID?: string;
}

interface IState {
  isCancelModalVisible: boolean;
}

export default class BookingConfirmationContent extends PureComponent<IComponentProps> {
  public state: IState = {
    isCancelModalVisible: false,
  };

  public render(): ReactNode {
    // these properties must be passed by the previous component CTA
    const { isLoading, order, activeOffer, testID } = this.props;
    if (!isLoading) {
      const withdrawRange = order.node.withdrawRange;
      const [withdrawStart, withdrawEnd] = withdrawRange.split('/');
      let showChangeButton = false;
      if (activeOffer) {
        const offerWithdrawRange = activeOffer.withdrawRange;
        const offerWithdrawEnd = moment(offerWithdrawRange.split('/')[1]);
        const orderWithdrawEnd = moment(withdrawEnd);
        const now = moment(new Date());
        showChangeButton = now < orderWithdrawEnd && now < offerWithdrawEnd && activeOffer.published;
      }

      const timeSlot = transformSummaryTime(withdrawStart, withdrawEnd);

      return (
        <>
          <>
            <View style={styles.cardManger}>
              <View style={styles.cardMangerBody}>
                <Image style={styles.iconCard} source={theme.images.check} />
                <Text style={styles.reservationText}>
                  {I18n.t('dashboard.eat.bookingService.modal.reservationMessageTitle')}
                </Text>
                <Text style={styles.signature}>
                  {I18n.t('dashboard.eat.bookingService.modal.reservationMessageBody')}
                </Text>
                <Text style={styles.hours}>{timeSlot}</Text>
                <Text style={styles.name}>{order?.node?.offerTemplateName}</Text>
              </View>
            </View>
            <View style={styles.buttonContainer}>
              {showChangeButton && (
                <LargeButton
                  testID={`${testID}_changeReservationButton`}
                  style={styles.leftButton}
                  theme={LARGE_BUTTON_THEME.active}
                  onPress={this.backToEdit}
                  text={I18n.t('dashboard.eat.bookingService.modal.changeReservation')}
                  />
                  )}
              <LargeButton
                testID={`${testID}_cancelReservationButton`}
                style={styles.rightButton}
                theme={LARGE_BUTTON_THEME.disabled}
                onPress={this.showCancelModal}
                text={I18n.t('dashboard.eat.bookingService.modal.cancelReservation')}
              />
            </View>
          </>
          {order && !isWeb() && (
            <CancelOrderModal
              testID={`${testID}_cancelReservationModal`}
              onLeftButtonPress={this.hideCancelModal}
              onRightButtonPress={() => { }}
              order={order}
              isVisible={this.state.isCancelModalVisible}
              isBookingOrder={true}
            />
          )}
        </>
      );
    }
    return <LoadingView isLoading={isLoading} />;
  }

  private backToEdit = (): void => {
    this.props.editOrder(true);
    this.setState({ isCancelModalVisible: false });
  };

  private hideCancelModal = (): void => {
    this.setState({ isCancelModalVisible: false });
  };

  private showCancelModal = (): void => {
    this.setState({ isCancelModalVisible: true });
  };
}

const BORDER_WIDTH = 1;

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  cardManger: {
    position: 'relative',
    width: '100%',
    flex: 10,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardMangerBody: {
    position: 'relative',
    width: '95%',
    borderRadius: moderateScale(11),
    paddingBottom: moderateScale(1.5 * theme.margins.unit),
    backgroundColor: '#fed35b',
  },
  iconCard: {
    tintColor: theme.colors.white,
    alignSelf: 'flex-end',
    height: RFValue(16),
    width: RFValue(16),
    marginRight: 1.5 * theme.margins.cardUnit,
    opacity: 0.9,
    marginTop: 1.5 * theme.margins.cardUnit,
  },
  hours: {
    marginTop: RFValue(theme.margins.cardUnit * 3),
    color: theme.colors.black,
    ...theme.fonts.title,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: RFValue(40),
    lineHeight: RFValue(43),
  },
  name: {
    marginTop: Platform.OS === 'ios' ? 0 : theme.margins.cardUnit,
    color: theme.colors.deprecatedBlack,
    ...theme.fonts.title,
    fontSize: RFValue(18),
    letterSpacing: 0,
    textAlign: 'center',
  },
  signature: {
    marginTop: RFValue(1.5 * theme.margins.cardUnit),
    color: theme.colors.deprecatedBlack,
    ...theme.fonts.signature,
    letterSpacing: 0,
    textAlign: 'center',
    fontSize: RFValue(23),
  },
  reservationText: {
    color: theme.colors.deprecatedBlack,
    ...theme.fonts.regularText,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontSize: RFValue(18),
    marginTop: Platform.OS === 'ios' ? theme.margins.cardUnit : 3 * theme.margins.cardUnit,
  },
  buttonStyle: {
    flex: 1,
    width: '100%',
    position: 'relative',
    bottom: 0,
  },
  leftButton: {
    borderTopColor: theme.colors.border,
    borderTopWidth: BORDER_WIDTH,
    flex: 1,
    paddingTop: theme.margins.cardUnit * 3 - BORDER_WIDTH,
  },
  rightButton: {
    borderTopColor: theme.colors.border,
    borderTopWidth: BORDER_WIDTH,
    flex: 1,
    paddingTop: theme.margins.cardUnit * 3 - BORDER_WIDTH,
  },
});
