import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import Logger from '../../lib/logger';
import { isWeb } from '../../lib/responsive';
import theme from '../../theme';
import { INode } from '../../types/common';
import { ITransaction } from '../../types/getTransactions';
import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';

import TransactionsList, { IProps as ITransactionListProps } from './TransactionsList';

export interface IProps extends ITransactionListProps {
  contentContainerStyle?: StyleProp<ViewStyle>;
  hasError?: boolean;
  hasNextPage?: boolean;
  isLoading?: boolean;
  renderLoading?: boolean;
  onEndReached: () => void;
  refresh?: boolean;
  transactions: Array<INode<ITransaction>>;
  forwardRefresh?: (refresh: () => void) => void;
  testID?: string;
}

class TransactionsCard extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    props.forwardRefresh && props.forwardRefresh(this.onRefresh.bind(this));
  }
  public componentDidUpdate(): void {
    if (this.props.refresh) {
      this.onRefresh();
    }

    return;
  }

  public render(): ReactNode {
    const {
      contentContainerStyle,
      hasNextPage,
      isLoading,
      renderLoading,
      onEndReached,
      refresh,
      transactions,
      ...otherProps
    } = this.props;

    return (
      <LoadingCard
        style={styles.container}
        isLoading={renderLoading || (isLoading && transactions.length === 0)}
        big
        noPadding
      >
        {isWeb() && this.renderHeader()}
        <TransactionsList
          {...otherProps}
          ListHeaderComponent={!isWeb() ? this.renderHeader : null}
          contentContainerStyle={contentContainerStyle}
          onEndReached={onEndReached}
          transactions={transactions}
          isLoading={hasNextPage}
        />
      </LoadingCard>
    );
  }

  private onRefresh = async (): Promise<void> => {
    if (!this.props.refetch) {
      return;
    }

    try {
      await this.props.refetch();
    } catch (error) {
      Logger.error(error);
    }
  };

  private renderHeader(): ReactElement<View> {
    return (
      <View style={styles.titleContainer}>
        <CardTitle text={I18n.t('transactions.cardTitle')} />
      </View>
    );
  }
}

export interface IStyle {
  container: ViewStyle;
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis: 'auto',
    marginBottom: 0,
  },
  titleContainer: {
    marginBottom: theme.margins.cardUnit * 3,
    marginTop: theme.margins.cardUnit * 2,
    paddingHorizontal: theme.margins.cardUnit * 2,
  },
});

export default TransactionsCard;
