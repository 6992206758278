import { ApolloLink, FetchResult, NextLink, Observable, Operation } from 'apollo-link';
import RNVersionNumber from 'react-native-version-number';

import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { selectOrderToken } from '../../redux/orderAuthentification/selectors';
import { getRoute } from '../../services/navigation';

import store from '../../redux/store';

interface ICustomHeaders {
  'App-Version': string | undefined;
  Authorization?: string | undefined;
  'Build-Version': string | undefined;
  'Bundle-Identifier': string | undefined;
}

export const authLink = new ApolloLink((operation: Operation, forward?: NextLink): Observable<
  FetchResult
> | null => {
  if (!forward) {
    return null;
  }
  const path = getRoute() || '';

  let token: string;
  if (path.includes('/table-service')) {
    token = selectOrderToken(store.getState());
  } else {
    token = selectAuthenticationToken(store.getState());
  }

  const headers: ICustomHeaders = {
    'App-Version': RNVersionNumber.appVersion,
    'Build-Version': RNVersionNumber.buildVersion,
    'Bundle-Identifier': RNVersionNumber.bundleIdentifier,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  operation.setContext({
    headers,
  });

  return forward(operation);
});
