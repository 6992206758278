import React, { PureComponent, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';

import { IDefaultProps, IProps } from './shared';

class MarkdownDisplay extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    content: '',
  };

  public render(): ReactNode {
    return <ReactMarkdown source={this.props.content} />;
  }
}

export default MarkdownDisplay;
