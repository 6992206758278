import moment from 'moment';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import {
  ListRenderItemInfo,
  ScrollView,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  TextStyle,
} from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import LoadingView from '../../../../../components/LoadingView';
import I18n from '../../../../../lib/i18n';
import theme from '../../../../../theme';
import BookingFooter from '../../../../../components/BookingFooter';
import {
  getBookingTemplateOffer_pos_Pos as IPos,
  getBookingTemplateOffer_pos_Pos_bookingOfferTemplate_nextOrderableOffers as BookingOffer,
} from '../../../../../types/bookingservice/getBookingTemplateOffer';
import BookingConfirmationContent from '../../../../../components/BookingConfirmationContent/BookingConfirmationContent.component';
import BookingFullContent from '../../../../../components/BookingFullContent/BookingFullContent.component';
import Carousel from 'react-native-snap-carousel';
import BookingHeaderInfo from '../../../../../components/BookingHeaderInfo';
import Touchable from '../../../../../components/Touchable';
import AppPage from '../../../../../components/AppPage';
import BookingInactiveOffer from '../../../../../components/BookingInactiveContent/BookingInactiveContent.component';
import { uuidToId } from '../../../../../services/base64Id';
import BookingExistingOrder from '../../../../../components/BookingExistingOrder/BookingExistingOrder';
import BookingOfferSlots from '../../../../../components/BookingOfferSlots';

export const DEFAULT_OFFERS_COUNT: number = 5;

export interface IProps extends NavigationInjectedProps {
  idPos: string;
  activeIndex: number | undefined;
  isLoading: boolean;
  hasError: boolean;
  offers: BookingOffer[];
  pos: IPos;
  nodes: any;
  withdrawRange: any;
}

class Booking extends PureComponent<IProps> {
  state = {
    activeIndex: 0,
    firstTime: true,
    editMode: false,
    withdrawRange: null,
  };

  carousel?: Carousel<BookingOffer> | null | undefined;

  public componentDidMount(): void {
    const { isLoading, pos, activeIndex, withdrawRange } = this.props;
    if (!isLoading && pos) {
      this.props.navigation.setParams({ posName: pos.name });
    }
    if (activeIndex) {
      this.setState({ activeIndex });
    }
    if (withdrawRange) {
      this.setState({ withdrawRange });
    }
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { isLoading: isLoadingOld } = prevProps;
    const { isLoading: isLoadingNew, pos } = this.props;

    if (isLoadingNew === !isLoadingOld && pos) {
      this.props.navigation.setParams({ posName: pos.name });
      this.setState({ firstTime: false });
    }
  }

  public render(): ReactNode {
    const { offers, isLoading, nodes, pos, navigation } = this.props;
    let finalOffers: BookingOffer[] = [];
    let closedOffer: boolean = false;

    if (!isLoading) {
      // In case the offerTemplate is disabled in the back-office there will be no offers
      if (offers.length === 0) {
        navigation.goBack();
        return null;
      }

      finalOffers = offers?.filter(offer => offer).slice();

      let activeIndex = this.state.activeIndex;

      // If there is an order for the same day in another POS, find correct index
      if (this.state.withdrawRange) {
        activeIndex = finalOffers.findIndex(
          offer => offer.withdrawRange === this.state.withdrawRange
        );
      }

      let activeOffer = finalOffers[activeIndex];
      let restaurantIsFull = !!activeOffer.isFullyBooked;
      let activeOrder = this.filterOrders(nodes, activeOffer);

      const sameDayOrder = this.filterOrdersByHolding(nodes, activeOffer, pos);

      const now = moment(new Date());
      const offerWithdrawEnd = moment(activeOffer.withdrawRange.split('/')[1]);

      const inactiveItems = !activeOrder && (activeOffer.isFullyBooked || now >= offerWithdrawEnd);
      let firstItem: number = activeIndex;

      if (inactiveItems && this.state.firstTime) {
        firstItem = firstItem + 1;
        activeOffer = finalOffers[firstItem];
        restaurantIsFull = !!activeOffer.isFullyBooked;
        activeOrder = this.filterOrders(nodes, activeOffer);
      } else if (inactiveItems) {
        closedOffer = true;
      }

      return (
        <AppPage noPadding style={styles.page}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{I18n.t('dashboard.eat.bookingService.modal.title')}</Text>
          </View>
          <View>
            <View style={styles.carouselContainer}>
              <Carousel
                testID="bookingComponent_Carousel"
                ref={(c: any) => {
                  this.carousel = c;
                }}
                data={finalOffers}
                renderItem={(item: any) => this.renderItem(item, nodes, firstItem, pos)}
                sliderWidth={300}
                itemWidth={100}
                onSnapToItem={(index: any) =>
                  this.setState({ activeIndex: index, editMode: false, withdrawRange: null })
                }
                inactiveSlideScale={0.8}
                firstItem={firstItem}
              />
            </View>
          </View>
          {!activeOrder &&
            !restaurantIsFull &&
            activeOffer.published &&
            !closedOffer &&
            !sameDayOrder &&
            this.renderSlots(activeOffer, isLoading, pos, activeOrder)}
          {this.state.editMode && this.renderSlots(activeOffer, isLoading, pos, activeOrder)}
          {!activeOrder &&
            !restaurantIsFull &&
            activeOffer.published &&
            !closedOffer &&
            sameDayOrder && (
              <BookingExistingOrder
                testID="bookingComponent_existingOrder"
                isLoading={isLoading}
                order={sameDayOrder}
                navigation={navigation}
              />
            )}
          {!activeOffer.published && !activeOrder && (
            <BookingInactiveOffer isLoading={isLoading} closedOffer={false} />
          )}
          {activeOffer.published && closedOffer && (
            <BookingInactiveOffer isLoading={isLoading} closedOffer={closedOffer} />
          )}
          {activeOrder && !closedOffer && !this.state.editMode && (
            <BookingConfirmationContent
              testID="bookingComponent_bookingConfirmationContent"
              isLoading={isLoading}
              order={activeOrder}
              editOrder={this.editBooking.bind(this)}
              activeOffer={activeOffer}
            />
          )}
          {restaurantIsFull && !activeOrder && activeOffer.published && !closedOffer && (
            <BookingFullContent isLoading={isLoading} />
          )}
        </AppPage>
      );
    }
    return <LoadingView isLoading={isLoading} />;
  }

  private editBooking(value: boolean): void {
    this.setState({ editMode: value });
  }

  private renderItem = (
    itemInfo: ListRenderItemInfo<BookingOffer>,
    orders: any,
    firstItem: number,
    pos: IPos
  ): ReactElement<View> => {
    return (
      <Touchable
        testID={`bookingComponent_Carousel_item_${itemInfo.index}`}
        onPress={() => this.carousel?.snapToItem(itemInfo.index)}
      >
        <BookingHeaderInfo
          offer={itemInfo.item}
          offerIndex={itemInfo.index}
          orders={orders}
          activeIndex={firstItem}
          editMode={this.state.editMode}
          pos={pos}
        />
      </Touchable>
    );
  };

  private getStartEndDatesFromRange(range: string): Date[] {
    const [withdrawStart, withdrawEnd] = range.split('/');
    return [new Date(withdrawStart), new Date(withdrawEnd)];
  }

  private filterOrders(nodes: any, activeOffer: BookingOffer): any {
    const offerId = this.decodeOfferId(activeOffer.id.toString());
    const booking =
      nodes &&
      nodes.find((obj: any) => {
        return (
          this.getStartEndDatesFromRange(obj?.node.withdrawRange)[0].getDate() ===
            this.getStartEndDatesFromRange(activeOffer.withdrawRange)[0].getDate() &&
          obj?.node.idOffer.toString() === offerId
        );
      });
    return booking;
  }

  private filterOrdersByHolding(nodes: any, activeOffer: BookingOffer, pos: IPos): any {
    const booking =
      nodes &&
      nodes.find((obj: any) => {
        return (
          this.getStartEndDatesFromRange(obj.node.withdrawRange)[0].getDate() ===
            this.getStartEndDatesFromRange(activeOffer.withdrawRange)[0].getDate() &&
          obj.node.bookingOffer &&
          obj.node.bookingOffer.bookingOfferTemplate.pos.id !== pos.id &&
          obj.node.bookingOffer.bookingOfferTemplate.pos.zone.idHolding === pos.zone.idHolding
        );
      });
    return booking;
  }

  private decodeOfferId(offerId: string) {
    let finalId: string | undefined = '';
    try {
      finalId = uuidToId(offerId);
    } catch (error) {
      finalId = offerId.split(':')[1];
    }
    return finalId;
  }

  private renderSlots = (
    activeOffer: BookingOffer,
    isLoading: boolean,
    pos: IPos,
    activeOrder: any
  ): ReactElement<View> => {
    return (
      <>
        <Text style={styles.subtitle}>{I18n.t('dashboard.eat.bookingService.modal.subtitle')}</Text>
        <BookingOfferSlots 
          offer={activeOffer} 
          maxOrdersPerSlot={activeOffer.maxOrdersPerSlot} 
          isLoading={isLoading} 
          order={activeOrder} 
        />
        <BookingFooter
          testID="bookingComponent_footer"
          idOffer={activeOffer.id}
          posId={pos.id}
          activeIndex={this.state.activeIndex}
          idOrder={activeOrder?.node.id}
          isUpdating={this.state.editMode}
        />
      </>
    );
  };
}

interface IStyle {
  page: ViewStyle;
  subtitle: TextStyle;
  carouselContainer: ViewStyle;
  title: TextStyle;
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  page: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtitle: {
    ...theme.fonts.regularText,
    color: theme.colors.black,
    marginBottom: theme.margins.cardUnit,
    fontStyle: 'italic',
    textAlign: 'center',
  },
  carouselContainer: {
    alignItems: 'center',
  },
  title: {
    ...theme.fonts.regularText,
    color: theme.colors.black,
    marginBottom: theme.margins.cardUnit,
    fontStyle: 'italic',
    textAlign: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2 * theme.margins.cardUnit,
  },
});

export default Booking;
