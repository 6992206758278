import get from 'lodash/get';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import I18n from '../../lib/i18n';
import theme from '../../theme';
import { ITicket } from '../../types/getTicket';
import AdmissionPricesCard from '../AdmissionPricesCard';
import CardTitle from '../CardTitle';
import EmptyContentMessage from '../EmptyContentMessage';
import GuestInfoCard from '../GuestInfoCard';
import TotalTicketPricesCard from '../TotalTicketPricesCard';
import TransactionAmountCard from '../TransactionAmountCard';
import TrayPricesCard from '../TrayPricesCard';

export interface IProps extends NavigationInjectedProps {
  hasError: boolean;
  hasTitle?: boolean;
  isLoading?: boolean;
  ticket?: ITicket;
}

class TransactionDetailsContent extends PureComponent<IProps> {
  public componentDidUpdate(prevProps: IProps): void {
    const prevDate = get(prevProps, 'ticket.date');
    const newDate = get(this.props, 'ticket.date');

    if (prevDate !== newDate) {
      this.props.navigation.setParams({
        transactionTicketDate: moment(newDate).format('DD/MM'),
      });
    }
  }

  public render(): ReactNode {
    const { hasError, hasTitle, isLoading, ticket } = this.props;
    const firstName = get(ticket, 'guest.firstName');
    const lastName = get(ticket, 'guest.lastName');
    const holdingName = get(ticket, 'guest.holding.name');
    const posName = get(ticket, 'pos.name');
    const date = get(this.props, 'ticket.date');
    const admission = get(ticket, 'admission');
    const fee = get(ticket, 'fee');
    const newBalance = get(ticket, 'newBalance');
    const oldBalance = get(ticket, 'oldBalance');
    const refill = get(ticket, 'refill');
    const total = get(ticket, 'total');
    const title = date
      ? `${I18n.t('transactions.title')} ${moment(date).format('DD/MM')}`
      : I18n.t('transactions.title');

    if (hasError) {
      return (
        <View style={styles.emptyContentContainer}>
          <EmptyContentMessage text={I18n.t('transactions.details.emptyContent')} />
        </View>
      );
    }

    return (
      <View>
        {hasTitle && (
          <View style={styles.title}>
            <CardTitle text={title} />
          </View>
        )}
        <GuestInfoCard
          firstName={firstName}
          lastName={lastName}
          isLoading={isLoading}
          holdingName={holdingName}
        />
        <TransactionAmountCard
          amount={Number(total?.amount) !== 0 ? total : refill}
          posName={posName}
          amountDateTime={date}
          isLoading={isLoading}
        />
        <TrayPricesCard isLoading={isLoading} ticket={ticket} />
        <AdmissionPricesCard isLoading={isLoading} admission={admission} fee={fee} />
        <TotalTicketPricesCard
          isLoading={isLoading}
          newBalance={newBalance}
          oldBalance={oldBalance}
          refill={refill}
          total={total}
        />
      </View>
    );
  }
}

interface IStyle {
  emptyContentContainer: ViewStyle;
  title: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  emptyContentContainer: {
    alignItems: 'center',
    paddingHorizontal: theme.margins.unit * 3,
  },
  title: {
    alignItems: 'center',
    marginBottom: theme.margins.cardUnit,
  },
});

export default TransactionDetailsContent;
