import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, View, ViewStyle } from 'react-native';
import {
  NavigationActions,
  NavigationResetAction,
  NavigationScreenProps,
  StackActions,
} from 'react-navigation';

import AppPage from '../../../components/AppPage';
import Button from '../../../components/Button';
import FullWidthView from '../../../components/FullWidthView';
import Paragraph from '../../../components/Paragraph';
import Stepper from '../../../components/Stepper';
import { STATUS } from '../../../components/Stepper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';

class Welcome extends PureComponent<NavigationScreenProps> {
  public render(): ReactNode {
    return (
      <AppPage style={styles.container} hasBlackHeader>
        <View style={styles.headerContainer}>
          <Stepper currentStepNumber={4} currentStepStatus={STATUS.DONE} />
          <Title text={I18n.t('signUp.welcome.title')} />
          <FullWidthView style={styles.imageContainer}>
            <Image style={styles.image} source={theme.images.thumb} />
          </FullWidthView>
        </View>
        <View>
          <SubTitle text={I18n.t('signUp.welcome.subTitle')} />
          <Paragraph text={I18n.t('signUp.welcome.paragraph')} />
          <Button onPress={this.goToSignIn} text={I18n.t('signUp.welcome.signIn')} />
        </View>
      </AppPage>
    );
  }

  private goToSignIn = (): void => {
    const resetAction: NavigationResetAction = StackActions.reset({
      actions: [
        NavigationActions.navigate({ routeName: 'authStack', params: { autoLogIn: true } }),
      ],
      index: 0,
      key: null,
    });
    this.props.navigation.dispatch(resetAction);
  };
}

interface IStyle {
  container: ViewStyle;
  headerContainer: ViewStyle;
  image: ImageStyle;
  imageContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    justifyContent: 'space-between',
  },
  headerContainer: {
    flex: 1,
  },
  image: {
    resizeMode: 'contain',
  },
  imageContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export default Welcome;
