import React, { PureComponent, ReactNode } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View, ViewStyle } from 'react-native';
import theme from '../../theme';

export interface IProps {
  hasError?: boolean;
  isLoading?: boolean;
  renderError?: () => ReactNode;
}

class LoadingView extends PureComponent<IProps> {
  public render(): ReactNode {
    const { hasError, isLoading, renderError, children } = this.props;

    this.forceColorForActivityIndicatorInAndroid();

    if (isLoading) {
      return (
        <View style={styles.loadingContainer} testID="loadingIndicator">
          <ActivityIndicator />
        </View>
      );
    }

    if (hasError) {
      return renderError ? renderError() : null;
    }

    if (children) {
      return <>{children}</>;
    }
    return null;
  }

  /**
   * fix bug https://github.com/facebook/react-native/issues/30056
   * ActivityIndicator is not showing in android in react-native 0.63 because the color internally is
   * null, it's necessary to set the color manually if it's not there
   */
  private forceColorForActivityIndicatorInAndroid(): void {
    if (Platform.OS === 'android') {
      if (!ActivityIndicator.defaultProps) {
        ActivityIndicator.defaultProps = {};
      }
      ActivityIndicator.defaultProps.color =  'gray';
    }
  }
}

interface IStyle {
  loadingContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    marginTop: theme.margins.cardUnit * 2,
  },
});

export default LoadingView;
