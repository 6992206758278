import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, TextStyle, View, ViewStyle, Text } from 'react-native';

import theme from '../../theme';
import { IAmount } from '../../types/common';
import I18n from '../../lib/i18n';
import Amount from '../Amount';
import LoadingView from '../LoadingView';
import Touchable from '../Touchable';
import OrderButton from './OrderButton';
import platformStyles from './styles';
import BottomSheet from '../BottomSheet';
import LargeButton from '../LargeButton';
import { LARGE_BUTTON_THEME } from '../LargeButton/LargeButton.component';
import { ArticleFamily } from '../../types/clickandcollect/globalTypes';

export interface IProps {
  onPress?: () => void;
  totalPrice: IAmount;
  title: string;
  enabled: boolean;
  isLoading: boolean;
  error: any;
  testID?: string;
}

export const BUTTON_HEIGHT = 65;

export interface IState {
  isBottomSheetVisible: boolean;
}

class PriceFooter extends PureComponent<IProps> {
  public state: IState = {
    isBottomSheetVisible: false,
  };

  public componentDidUpdate(prevProps: IProps): void {
    const { error } = this.props;
    if (error && error !== prevProps.error) {
      this.setState({ isBottomSheetVisible: true });
    }
  }

  public render(): ReactNode {
    const { totalPrice, title, enabled, isLoading } = this.props;
    const { isBottomSheetVisible } = this.state;
    const showPrice = totalPrice && Number(totalPrice.amount) > 0;

    const containerStyle = enabled
      ? [platformStyles.card, styles.container, styles.enabled]
      : [platformStyles.card, styles.container, styles.disabled];

    const { error, testID } = this.props;

    return isLoading ? (
      <View style={containerStyle}>
        <LoadingView isLoading={isLoading} />
      </View>
    ) : (
      <Touchable
        onPress={this.props.onPress}
        style={containerStyle}
        disabled={!enabled}
        testID={testID}
      >
        <View style={styles.amountContainer}>
          {showPrice && <Amount amount={totalPrice} style={styles.amount} />}
        </View>
        <OrderButton text={title} disabled={!enabled} />
        {error && (
          <BottomSheet isVisible={isBottomSheetVisible}>
            <View style={{ padding: theme.margins.unit * 2 }}>
              <Text style={styles.title}>{I18n.t('dashboard.eat.error.validation.title')}</Text>
              <Text style={[styles.marginTop, styles.description]}>
                {I18n.t(`dashboard.eat.error.validation.${error.min ? 'min' : 'max'}`, {
                  count: error.max || error.min,
                })}
                <Text style={{ ...theme.fonts.paragraph, fontWeight: '600' }}>
                  {`${error.families
                    .map((family: ArticleFamily) =>
                      I18n.t(
                        `dashboard.eat.clickAndCollect.articles.type.${family.toLowerCase()}`,
                        { count: error.max || error.min }
                      )
                    )
                    .join(', ')}.`}
                </Text>
              </Text>
              <LargeButton
                style={[theme.buttons.LargeButton, styles.marginTop]}
                text={I18n.t('dashboard.eat.clickAndCollect.goBack')}
                theme={LARGE_BUTTON_THEME.success}
                onPress={this.onBackPress}
              />
            </View>
          </BottomSheet>
        )}
      </Touchable>
    );
  }
  private onBackPress = (): void => {
    this.setState({ isBottomSheetVisible: false });
  };
}

interface IStyle {
  enabled: ViewStyle;
  amount: TextStyle;
  title: TextStyle;
  description: TextStyle;
  amountContainer: ViewStyle;
  container: ViewStyle;
  disabled: ViewStyle;
  marginTop: ViewStyle;
}

export interface IPlatformStyle {
  card: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  enabled: {
    backgroundColor: theme.colors.validate,
  },
  amount: {
    ...theme.fonts.mediumTitle,
    alignSelf: 'center',
    color: theme.colors.textWhite,
    marginLeft: 0,
  },
  amountContainer: {
    flexDirection: 'row',
    paddingLeft: theme.margins.unit * 2,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: BUTTON_HEIGHT,
    justifyContent: 'space-between',
  },
  title: {
    color: theme.colors.textBlack,
    ...theme.fonts.semiBoldText,
  },
  description: {
    color: theme.colors.grayMedium,
    paddingLeft: theme.margins.unit,
    ...theme.fonts.regularText,
  },
  marginTop: {
    marginTop: 15,
  },
  disabled: { backgroundColor: theme.colors.backgroundQuantityFooter },
});

export default PriceFooter;
