import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import LargeButton from '../../../components/LargeButton';
import { TextInput } from '../../../components/TextInputFormik';
import I18n from '../../../lib/i18n';
import Logger from '../../../lib/logger';
import { isWeb } from '../../../lib/responsive';
import theme from '../../../theme';
import { getOrder2_order_Order as IOrder } from '../../../types/clickandcollect/getOrder2';
import { LARGE_BUTTON_THEME } from '../../LargeButton/LargeButton.component';

export interface IProps extends NavigationInjectedProps {
  onEmailOrder: (orderId: string, email:string) => Promise<void>;
  onButtonPress: () => void;
  order: IOrder;
}

export interface IState {
  isLoading: boolean;
  error: boolean;
  email: string;
}

class EmailOrderModalContent extends PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: false,
    error: false,
    email: '',
  };

  public render(): ReactNode {
    const { onButtonPress, order, onEmailOrder } = this.props;
    const idOrder = get(order, 'id');

    const sendMail = async () => {
      if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.email)) {
        this.setState({ error: true });
        return;
      }

      this.setState({ isLoading: true, error: false });

      try {
        await onEmailOrder(idOrder, this.state.email);
      } catch (error) {
        Logger.error(error);
      } finally {
        this.setState({ isLoading: false });
        onButtonPress();
      }
    };
    return (
      <>
        <View style={styles.textContainer}>
          <Text style={[styles.title, this.state.error ? { color: theme.colors.error } : {}]}>
            {this.state.error
              ? I18n.t('dashboard.eat.clickAndCollect.orderDetails.emailModalError')
              : I18n.t('dashboard.eat.clickAndCollect.orderDetails.emailModalTitle')}
          </Text>

          <TextInput
            style={styles.input}
            type="text"
            hasError={this.state.error}
            value={this.state.email}
            onChangeText={email => this.setState({ email })}
            inputStyle={styles.inputText}
            autoFocus={true}
            editable={!this.state.isLoading}
            placeholder={I18n.t('dashboard.eat.clickAndCollect.orderDetails.emailModalInput')}
          ></TextInput>
        </View>

        <View style={styles.buttonContainer}>
          <LargeButton
            text={I18n.t('dashboard.eat.clickAndCollect.orderDetails.emailModalConfirm')}
            style={isWeb() ? styles.button : { flex: 1 }}
            theme={LARGE_BUTTON_THEME.success}
            onPress={sendMail}
            isLoading={this.state.isLoading}
            disabled={this.state.isLoading}
          />
        </View>
      </>
    );
  }
}

export interface IPlatformStyle {
  title: TextStyle;
}

const styles = StyleSheet.create({
  input: {
    alignSelf: 'center',
    flex: 1,
    width: '100%',
  },
  inputText: {
    fontSize: 12,
    fontWeight: '500',
    color: theme.colors.textGray,
  },
  buttonContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  button: {
    borderRadius: 6,
    margin: 8,
    height: '20px',
    flex: 1,
  },
  buttonApp: {
    backgroundColor: '#61D57B',
  },
  textContainer: {
    padding: 2 * theme.margins.cardUnit,
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    ...theme.fonts.littleTitle,

    color: '#383838',
    paddingBottom: 2 * theme.margins.cardUnit,
    textAlign: 'center',
  },
});

export default EmailOrderModalContent;
