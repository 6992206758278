import clone from 'lodash/clone';
import React, { PureComponent, ReactNode } from 'react';
import { Collapse } from 'react-collapse';
import { StyleSheet, View, ViewStyle } from 'react-native';
import theme from '../../../theme';
import { getOrdersFragment_edges_node_Order as IOrder } from '../../../types/clickandcollect/getOrdersFragment';
import ToggleSeeMoreButton from '../../ToggleSeeMoreButton';
import OrderItem from '../OrderItem/OrderItem.component';

export interface IProps {
  handleOnTogglePress: (isListOpened: boolean) => void;
  isOpen: boolean;
  onOrderPress: (order: IOrder) => (() => void);
  orders: IOrder[];
}

class OrdersListBody extends PureComponent<IProps> {
  public render(): ReactNode {
    const { handleOnTogglePress, isOpen, onOrderPress, orders } = this.props;

    const ordersCopy = clone(orders);
    const firstOrder = ordersCopy.shift();
    const numberOfOrders = ordersCopy ? ordersCopy.length : 0;

    if (!firstOrder) {
      return null;
    }

    return (
      <>
        <View style={styles.itemContainer} key={firstOrder.id}>
          <OrderItem order={firstOrder} onPress={onOrderPress(firstOrder)} />
        </View>
        <Collapse isOpened={isOpen}>
          {ordersCopy &&
            ordersCopy.map((order: IOrder) => (
              <View style={styles.itemContainer} key={order.id}>
                <OrderItem order={order} onPress={onOrderPress(order)} />
              </View>
            ))}
        </Collapse>
        {numberOfOrders > 1 && (
          <ToggleSeeMoreButton
            isToggledOn={isOpen}
            onPress={(): void => handleOnTogglePress(isOpen)}
          />
        )}
      </>
    );
  }
}

export interface IStyle {
  itemContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  itemContainer: {
    marginBottom: theme.margins.cardUnit * 2,
  },
});

export default OrdersListBody;
