import { loader } from 'graphql.macro';
import get from 'lodash/get';
import React from 'react';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { compose, withProps } from 'recompose';

const getGuestPaymentProfile = loader('./queries/getGuestPaymentProfile.gql');

interface IComponentProps {
  isLoading?: boolean;
}

interface IWithIsLoading {
  isLoading: boolean;
}

interface IGraphQLProps {
  isGuestAnomyn: boolean;
  isGuestRefillAllowedLoading: boolean;
  refillAllowedHolding: boolean | null;
  managementMode?: GuestManagementMode;
  haveBadge?: boolean;
  isSchool?: boolean;
}

export enum GuestManagementMode {
  CASH = 'CASH',
  PRE = 'PRE',
  POST = 'POST',
}

export interface IWithGuestPaymentProfile {
  isGuestAnomyn: boolean;
  isLoading: boolean;
  refillAllowedHolding: boolean | null;
  managementMode: GuestManagementMode;
  haveBadge?: boolean;
  isSchool?: boolean;
}

type IComposeProps = IGraphQLProps & IWithIsLoading;
type IHocProps<T> = Omit<T, keyof IComposeProps>;

const withIsLoading = (props: IGraphQLProps & IComponentProps): IWithIsLoading => ({
  isLoading: props.isLoading || props.isGuestRefillAllowedLoading,
});

const withGuestPaymentProfile = <T extends IComponentProps>(
  Component: React.ComponentType<T>
): React.ComponentType<IHocProps<T>> => {
  return compose<T, IHocProps<T>>(
    graphql(getGuestPaymentProfile, {
      options: (): QueryOpts => ({
        fetchPolicy: 'network-only',
      }),
      props: (props: OptionProps<{}>): IGraphQLProps => ({
        isGuestRefillAllowedLoading: get(props, 'data.loading'),
        isGuestAnomyn: !get(props, 'data.getUser.id'),
        refillAllowedHolding: get(props, 'data.getUser.currentHoldingView.holding.refillAllowed'),
        managementMode: get(props, 'data.getUser.guests.edges[0].node.managementMode'),
        haveBadge:
          !!get(props, 'data.getUser.guests.edges[0].node.supportSerialNumber') ||
          !!get(props, 'data.getUser.guests.edges[0].node.serialNumber'),
        isSchool: get(props, 'data.getUser.currentHoldingView.holding.isSchool'),
      }),
    }),
    withProps(withIsLoading)
  )(Component);
};

export default withGuestPaymentProfile;
