import React, { PureComponent, ReactNode } from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import theme from '../../theme';
import Touchable, { IProps as ITouchableProps } from '../Touchable';

export interface IProps extends ITouchableProps {
  disabled?: boolean;
  isLoading?: boolean;
  noMargin?: boolean;
  style?: StyleProp<ViewStyle>;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  testID?: string;
}

type IDefaultProps = Required<Pick<IProps, 'disabled' | 'isLoading' | 'noMargin'>>;

class Button extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    disabled: false,
    isLoading: false,
    noMargin: false,
  };

  public render(): ReactNode {
    const {
      disabled,
      isLoading,
      style,
      text,
      textStyle,
      children,
      noMargin,
      testID,
      ...rest
    } = this.props;
    const content = text ? <Text style={[styles.text, textStyle]}>{text}</Text> : children;
    const containerStyle = noMargin
      ? styles.button
      : StyleSheet.flatten([styles.button, styles.buttonMargin]);

    if (disabled) {
      return (
        <View testID={testID} style={[containerStyle, styles.buttonDisabled, style]} {...rest}>
          {content}
        </View>
      );
    }

    if (isLoading) {
      return (
        <View style={[containerStyle, style]} {...rest}>
          <ActivityIndicator color={this.getLoaderColor()} />
        </View>
      );
    }

    return (
      <Touchable testID={testID} style={[containerStyle, style]} {...rest}>
        {content}
      </Touchable>
    );
  }

  private getLoaderColor = (): string | undefined => {
    const textStyle = StyleSheet.flatten([styles.text, this.props.textStyle]);

    return textStyle.color;
  };
}

export interface IStyle {
  button: ViewStyle;
  buttonDisabled: ViewStyle;
  buttonMargin: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  button: {
    alignSelf: 'stretch',
    backgroundColor: theme.colors.green,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    height: 50,
    justifyContent: 'center',
    paddingHorizontal: theme.margins.unit,
  },
  buttonDisabled: {
    backgroundColor: theme.colors.disabled,
  },
  buttonMargin: {
    marginBottom: theme.margins.unit,
  },
  text: {
    color: theme.colors.textWhite,
    textAlign: 'center',
    ...theme.fonts.button,
  },
});

export default Button;
