import React, { PureComponent, ReactNode } from 'react';
import { Text, View } from 'react-native';
import I18n from '../../../lib/i18n';
import styles from '../styles.shared';

interface IComponentProps {
  name?: string;
  withdrawalSchedule?: string;
  description?: string | null;
  pickupDate: string;
  noTitle?: boolean;
}

export default class PickupPointSection extends PureComponent<IComponentProps> {
  public render(): ReactNode {
    const { name, withdrawalSchedule, description, pickupDate, noTitle } = this.props;

    return (
      <View style={styles.section}>
        {!noTitle && (
          <Text style={styles.sectionTitleWithoutMarginBottom}>
            {I18n.t('dashboard.eat.clickAndCollect.cartSummary.pickupPoint')}
          </Text>
        )}
        <View style={styles.sectionContentColumn}>
          <Text style={styles.sectionContentTextLeftBold}>{name}</Text>
          <Text style={styles.sectionContentTextLeft}>{withdrawalSchedule}</Text>
          {description && (
            <Text style={styles.sectionContentTextLeft}>{description}</Text>
          )}
          <Text style={styles.sectionContentTextLeft}>{pickupDate}</Text>
        </View>
      </View>
    );
  }
}
