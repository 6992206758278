import React, { PureComponent, ReactNode } from 'react';
import {
  Image,
  ImageStyle,
  ImageURISource,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import theme from '../../theme';

export interface IProps {
  image?: ImageURISource;
  imageContainerStyle?: ViewStyle;
  imageStyle?: ImageStyle;
  isActive?: boolean;
  label: string;
}

class ChoiceItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { image, imageContainerStyle, imageStyle, isActive, label } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.content}>
          {image && (
            <View style={[styles.imageContainer, imageContainerStyle]}>
              <Image style={[styles.image, imageStyle]} resizeMode={'contain'} source={image} />
            </View>
          )}
          <Text style={styles.text} numberOfLines={1}>
            {label}
          </Text>
        </View>
        {isActive && <Icon name="check" color={theme.colors.success} />}
      </View>
    );
  }
}

export interface IStyle {
  container: ViewStyle;
  content: ViewStyle;
  image: ImageStyle;
  imageContainer: ImageStyle;
  text: TextStyle;
}

const ICON_SIZE = 50;

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  content: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    height: ICON_SIZE,
    width: ICON_SIZE,
  },
  imageContainer: {
    marginRight: 2 * theme.margins.cardUnit,
  },
  text: {
    color: theme.colors.textGray,
    paddingVertical: 3 * theme.margins.unit,
    ...theme.fonts.pricesCardItemLabel,
  },
});

export default ChoiceItem;
