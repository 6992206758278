import { Action } from 'redux';

export type IActionTypes = 'HOLDING_SET_HOLDING_INFO';

export const actionTypes: { [key in IActionTypes]: key } = {
  HOLDING_SET_HOLDING_INFO: 'HOLDING_SET_HOLDING_INFO',
};

export interface IHoldingInfo {
  idHolding?: string;
  brandName?: string;
  badgeNumber?: string;
  isBadgeRequired?: boolean;
  isBalanceDisabled?: boolean;
  externalRefillUrl?: string;
}

export interface IHoldingInfoAction extends Action {
  payload: IHoldingInfo;
}

export const setHoldingInfo = (payload: IHoldingInfo): IHoldingInfoAction => ({
  payload,
  type: actionTypes.HOLDING_SET_HOLDING_INFO,
});
