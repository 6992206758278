import { Action } from 'redux';

import { IOptinCode } from '../../types/common';

export type IActionTypes =
  | 'SIGN_UP_SET_GUEST_TOKEN_ID'
  | 'SIGN_UP_SET_USER_EMAIL'
  | 'SIGN_UP_SET_OPTINS'
  | 'SIGN_UP_RESET'
  | 'SIGN_UP_SET_TOKEN';

export const actionTypes: { [key in IActionTypes]: key } = {
  SIGN_UP_RESET: 'SIGN_UP_RESET',
  SIGN_UP_SET_GUEST_TOKEN_ID: 'SIGN_UP_SET_GUEST_TOKEN_ID',
  SIGN_UP_SET_OPTINS: 'SIGN_UP_SET_OPTINS',
  SIGN_UP_SET_TOKEN: 'SIGN_UP_SET_TOKEN',
  SIGN_UP_SET_USER_EMAIL: 'SIGN_UP_SET_USER_EMAIL',
};

export interface ISetGuestTokenIdPayload {
  guestTokenId: string;
}

export interface ISetGuestTokenIdAction extends Action {
  payload: ISetGuestTokenIdPayload;
}

export const setGuestTokenId = (payload: ISetGuestTokenIdPayload): ISetGuestTokenIdAction => ({
  payload,
  type: actionTypes.SIGN_UP_SET_GUEST_TOKEN_ID,
});

export interface ISetUserEmailPayload {
  email: string;
}

export interface ISetUserEmailAction extends Action {
  payload: ISetUserEmailPayload;
}

export const setUserEmail = (payload: ISetUserEmailPayload): ISetUserEmailAction => ({
  payload,
  type: actionTypes.SIGN_UP_SET_USER_EMAIL,
});

export interface ISetOptinsPayload {
  optins: IOptinCode[];
}

export interface ISetOptinsAction extends Action {
  payload: ISetOptinsPayload;
}

export const setOptins = (payload: ISetOptinsPayload): ISetOptinsAction => ({
  payload,
  type: actionTypes.SIGN_UP_SET_OPTINS,
});

export const resetSignUp = (): Action => ({
  type: actionTypes.SIGN_UP_RESET,
});

export interface ISetTokenPayload {
  token?: string;
}

export interface ISetTokenAction extends Action {
  payload: ISetTokenPayload;
}

export const setSignUpToken = (payload: ISetTokenPayload): ISetTokenAction => ({
  payload,
  type: actionTypes.SIGN_UP_SET_TOKEN,
});
