import { AnyAction, Reducer } from 'redux';

import { actionTypes } from './actions';

export interface IOrderAuthenticationState {
  emissionDate?: string;
  expirationDate?: string;
  expiresIn?: number;
  refreshToken?: string;
  token?: string;
  signInRedirection?: string;
}

export const initialState: IOrderAuthenticationState = {};

const orderAuthenticationReducer: Reducer<IOrderAuthenticationState> = (
  state: IOrderAuthenticationState = initialState,
  action: AnyAction
): IOrderAuthenticationState => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_SET_ORDER_TOKEN:
      return {
        ...state,
        emissionDate: action.payload.emissionDate,
        expirationDate: action.payload.expirationDate,
        expiresIn: action.payload.expiresIn,
        token: action.payload.token,
      };
    default:
      return state;
  }
};

export default orderAuthenticationReducer;
