import { NavigationScreenProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IAppState } from '../../../redux/reducer';
import { selectUserEmail } from '../../../redux/signUp/selectors';

import Confirmation, { IProps } from './Confirmation.component';

export interface IMapStateToProps {
  email?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  email: selectUserEmail(state),
});

export type IConnectedProps = NavigationScreenProps & IMapStateToProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(connect(mapStateToProps))(Confirmation);
