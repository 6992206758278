import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, View, ViewStyle } from 'react-native';
import CodePush from 'react-native-code-push';
import {
  NavigationActions,
  NavigationInjectedProps,
  NavigationResetAction,
  StackActions,
} from 'react-navigation';

import AppPage from '../../../components/AppPage';
import FakeInput from '../../../components/FakeInput';
import FullWidthView from '../../../components/FullWidthView';
import GhostButton from '../../../components/GhostButton';
import Paragraph from '../../../components/Paragraph';
import Stepper from '../../../components/Stepper';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';

import { BUTTON_THEME } from '../../../components/GhostButton';
import { STATUS } from '../../../components/Stepper';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';

export interface IProps extends NavigationInjectedProps {
  email?: string;
}

class Confirmation extends PureComponent<IProps> {
  public render(): ReactNode {
    const { email } = this.props;

    return (
      <AppPage style={styles.container} hasBlackHeader>
        <View style={styles.headerContainer}>
          <Stepper currentStepNumber={4} currentStepStatus={STATUS.ACTIVE} />
          <Title text={I18n.t('signUp.confirmation.title')} />
          <FullWidthView style={styles.imageContainer}>
            <Image style={styles.image} source={theme.images.mail} />
          </FullWidthView>
        </View>
        <View>
          <SubTitle text={I18n.t('signUp.confirmation.subTitle')} />
          <Paragraph text={I18n.t('signUp.confirmation.paragraph')} />
          {!!email && <FakeInput value={email} />}
          <GhostButton
            theme={BUTTON_THEME.black}
            text={I18n.t('signUp.confirmation.home')}
            onPress={this.goBackLandingPage}
          />
        </View>
      </AppPage>
    );
  }

  private goBackLandingPage = (): void => {
    const resetAction: NavigationResetAction = StackActions.reset({
      actions: [NavigationActions.navigate({ routeName: 'authStack' })],
      index: 0,
      key: null,
    });
    CodePush.allowRestart();
    this.props.navigation.dispatch(resetAction);
  };
}

export interface IStyle {
  container: ViewStyle;
  headerContainer: ViewStyle;
  image: ImageStyle;
  imageContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    justifyContent: 'space-between',
  },
  headerContainer: {
    flex: 1,
  },
  image: {
    resizeMode: 'contain',
  },
  imageContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export default Confirmation;
