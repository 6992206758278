import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import I18n from '../../../lib/i18n';
import theme from '../../../theme';

export interface IProps {
  dateTime?: string;
}

class BalanceDateTime extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dateTime } = this.props;

    if (!dateTime) {
      return null;
    }

    const momentDate = moment(dateTime);
    const dayOfWeek = momentDate.format('dddd');
    const dayOfMonth = momentDate.format('Do');
    const month = momentDate.format('MMMM');
    const hour = momentDate.format('HH[h]mm');

    return (
      <Text style={styles.text}>
        {I18n.t('balance.dateTime', { dayOfWeek, dayOfMonth, month, hour })}
      </Text>
    );
  }
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.cardText,
    color: theme.colors.textGray,
  },
});

export default BalanceDateTime;
