import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { IWithGuestPaymentProfile } from '../../../hoc/withGuestPaymentProfile';
import I18n from '../../../lib/i18n';
import { isRefillRequired } from '../../../services/guest';
import theme from '../../../theme';
import { cartSummaryData_order_Order as IOrder } from '../../../types/clickandcollect/cartSummaryData';
import { cartSummaryAdmissionAndPrice_orderAdmissionAndPrice_Order as IOrderAdmissionAndPrice } from '../../types/clickandcollect/cartSummaryAdmissionAndPrice';
import { PaymentMethod } from '../../../types/clickandcollect/globalTypes';
import LargeButton from '../../LargeButton';
import { LARGE_BUTTON_THEME } from '../../LargeButton/LargeButton.component';
import { platformStyles } from './styles';

export interface IComponentProps extends IWithGuestPaymentProfile {
  quotationError: boolean;
  refetch: () => void;
  order: IOrder;
  onConfirm: () => Promise<void>;
  onPay: () => Promise<void>;
  onUpdatePress: () => Promise<void>;
  onTopUpPress: () => Promise<void>;
  disabled?: boolean;
  onTopUpCreditCard: () => Promise<void>;
  paymentMethod: PaymentMethod;
  admissionAndPrice: IOrderAdmissionAndPrice;
  comment?: string;
  testID?: string;
  haveLowBalance: boolean;
}
interface IState {
  isLoading: boolean;
}
export default class ConfirmOrderButton extends PureComponent<IComponentProps, IState> {
  state = {
    isLoading: false,
  };
  public render(): ReactNode {
    const {
      order,
      onUpdatePress,
      onTopUpPress,
      onConfirm,
      onPay,
      isGuestAnomyn,
      managementMode,
      quotationError,
      haveBadge,
      refetch,
      disabled = false,
      onTopUpCreditCard,
      paymentMethod,
      admissionAndPrice,
      testID,
      refillAllowedHolding,
      haveLowBalance
    } = this.props;

    const onConfirmButton = () => (
      <LargeButton
        testID={testID}
        text={I18n.t('dashboard.eat.clickAndCollect.cartSummary.confirmButton')}
        onPress={() => !haveLowBalance && this.setState({ isLoading: true }, onConfirm)}
        theme={disabled ? LARGE_BUTTON_THEME.disabled : LARGE_BUTTON_THEME.success}
        isLoading={this.state.isLoading}
        disabled={disabled ? disabled : this.state.isLoading}
      />
    );

    if (paymentMethod === PaymentMethod.EDENRED || paymentMethod === PaymentMethod.ON_SITE) {
      return onConfirmButton();
    }

    if (!admissionAndPrice) {
      return (
        <LargeButton
          testID={testID}
          text={I18n.t('dashboard.eat.clickAndCollect.cartSummary.confirmButton')}
          onPress={() => {}}
          theme={disabled ? LARGE_BUTTON_THEME.disabled : LARGE_BUTTON_THEME.success}
          isLoading={true}
          disabled={disabled ? disabled : this.state.isLoading}
        />
      )
    }

    return (
      <View style={platformStyles.container}>
        {quotationError ? (
          <LargeButton
            testID={testID}
            text={I18n.t('dashboard.eat.clickAndCollect.cartSummary.reloadQuotation')}
            onPress={refetch}
            theme={disabled ? LARGE_BUTTON_THEME.disabled : LARGE_BUTTON_THEME.success}
            disabled={disabled}
          />
        ) : paymentMethod === PaymentMethod.CREDIT_CARD ? (
          <LargeButton
            testID={testID}
            text={I18n.t('dashboard.eat.clickAndCollect.cartSummary.confirmButton')}
            onPress={onTopUpCreditCard}
            theme={disabled ? LARGE_BUTTON_THEME.disabled : LARGE_BUTTON_THEME.success}
            isLoading={this.state.isLoading}
            disabled={disabled ? disabled : this.state.isLoading}
          />
        ) : isRefillRequired(
            { isGuestAnomyn, managementMode, balance: order?.guest?.availableBalance ? (paymentMethod === PaymentMethod.BADGE && haveBadge) ? order!.guest!.availableBalance! : null : null},
            admissionAndPrice.totalPrice!,
            refillAllowedHolding,
            (order?.offer?.offerTemplate as any)?.withdrawalType,
            order?.guest?.holding?.idCashSystem,
          ) ? (
          <View style={styles.requireTopUpContainer}>
            <LargeButton
              testID={`${testID}_cancelButton`}
              text={I18n.t('dashboard.eat.clickAndCollect.cartSummary.cancelButton')}
              theme={disabled ? LARGE_BUTTON_THEME.disabled : LARGE_BUTTON_THEME.default}
              style={leftButtonStyle}
              onPress={onUpdatePress}
              disabled={disabled}
            />
            <LargeButton
              testID={`${testID}_topUpButton`}
              text={I18n.t('dashboard.eat.clickAndCollect.cartSummary.topUpButton')}
              style={styles.topUpButton}
              theme={disabled ? LARGE_BUTTON_THEME.disabled : LARGE_BUTTON_THEME.success}
              onPress={onTopUpPress}
              disabled={disabled}
            />
          </View>
        ) : isGuestAnomyn ? (
          <LargeButton
            testID={testID}
            text={I18n.t('dashboard.eat.clickAndCollect.cartSummary.payButton')}
            onPress={() => this.setState({ isLoading: true }, onPay)}
            theme={disabled ? LARGE_BUTTON_THEME.disabled : LARGE_BUTTON_THEME.success}
            isLoading={this.state.isLoading}
            disabled={disabled ? disabled : this.state.isLoading}
          />
        ) : (
          onConfirmButton()
        )}
      </View>
    );
  }
}

const BORDER_WIDTH = 1;
const styles = StyleSheet.create({
  leftButton: {
    flex: 1,
    paddingTop: theme.margins.cardUnit * 3 - BORDER_WIDTH,
  },
  requireTopUpContainer: {
    flexDirection: 'row',
  },
  topUpButton: {
    backgroundColor: theme.colors.validate,
    flex: 1,
  },
});

export interface IPlatformStyle {
  container: ViewStyle;
  leftButton: ViewStyle;
}

const leftButtonStyle = StyleSheet.flatten([styles.leftButton, platformStyles.leftButton]);
