import { AnyAction, Reducer } from 'redux';

import { actionTypes } from './actions';

export interface IAuthenticationState {
  emissionDate?: string;
  expirationDate?: string;
  expiresIn?: number;
  refreshToken?: string;
  token?: string;
  signInRedirection?: string;
}

export const initialState: IAuthenticationState = {};

const authenticationReducer: Reducer<IAuthenticationState> = (
  state: IAuthenticationState = initialState,
  action: AnyAction
): IAuthenticationState => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_SET_TOKEN:
      return {
        ...state,
        emissionDate: action.payload.emissionDate,
        expirationDate: action.payload.expirationDate,
        expiresIn: action.payload.expiresIn,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        signInRedirection: action.payload.signInRedirection,
      };
    case actionTypes.AUTHENTICATION_SET_ACCESS_TOKEN:
      return {
        ...state,
        emissionDate: action.payload.emissionDate,
        expirationDate: action.payload.expirationDate,
        expiresIn: action.payload.expiresIn,
        token: action.payload.token,
      };
    case actionTypes.AUTHENTICATION_LOG_OUT:
      return initialState;
    case actionTypes.RESET_SIGNIN_REDIRECTION:
      return {
        ...state,
        signInRedirection: undefined,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
