import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import { compose } from 'recompose';

import ProfileCard, { IProps } from './ProfileCard.component';

const getUserProfile = loader('../../queries/getUserProfile.gql');

export type IGraphQLProps = IProps;

export default compose<IProps, {}>(
  graphql(getUserProfile, {
    props: (props: OptionProps<{}>): IGraphQLProps => ({
      firstName: get(props, 'data.getUser.firstName'),
      holdingName: get(props, 'data.getUser.guests.edges[0].node.holding.name'),
      isLoading: props.data!.loading,
      lastName: get(props, 'data.getUser.lastName'),
    }),
  })
)(ProfileCard);
