import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import Card, { IProps as ICardProps } from '../Card';
import LoadingView, { IProps as ILoadingViewProps } from '../LoadingView';

export type IProps = ICardProps & ILoadingViewProps;

class LoadingCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { style, ...otherProps } = this.props;

    const cardStyles: StyleProp<ViewStyle> = this.props.isLoading ? [style, styles.loading] : style;

    return (
      <Card style={cardStyles} {...otherProps}>
        <LoadingView {...otherProps}>{this.props.children}</LoadingView>
      </Card>
    );
  }
}

export interface IStyle {
  loading: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  loading: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default LoadingCard;
