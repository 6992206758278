import React, { PureComponent, ReactNode } from 'react';
import { View } from 'react-native';

import I18n from '../../lib/i18n';
import CheckboxInput from '../CheckboxInput';
import Label from '../Label';
import Link from '../Link';

export interface IValues {
  checkboxNewsletter: boolean;
  checkboxTos: boolean;
}

export interface IProps {
  onPressLink: () => void;
  setFieldValue: (field: string, value: string | boolean) => void;
  values: IValues;
}

class Optins extends PureComponent<IProps, {}> {
  public render(): ReactNode {
    const { setFieldValue, values } = this.props;

    return (
      <View>
        <CheckboxInput
          isChecked={values.checkboxTos}
          testID="checkboxTos"
          onChange={(): void => {
            setFieldValue('checkboxTos', !values.checkboxTos);
          }}
        >
          <Label>
            {I18n.t('signUp.password.tos.start')}
            <Link onPress={this.props.onPressLink} text={I18n.t('signUp.password.tos.link')} />
            {I18n.t('signUp.password.tos.end')}
          </Label>
        </CheckboxInput>
        <CheckboxInput
          text={I18n.t('signUp.password.newsletter')}
          isChecked={values.checkboxNewsletter}
          onChange={(): void => {
            setFieldValue('checkboxNewsletter', !values.checkboxNewsletter);
          }}
        />
      </View>
    );
  }
}

export default Optins;
