import { StyleSheet } from 'react-native';

import theme from '../../../theme';

import { IPlatformStyle } from './InfoRecipeDisclaimerFormikForm.component';

const BORDER_RADIUS = 4;
const BORDER_WIDTH = 1;

export const platformStyles = StyleSheet.create<IPlatformStyle>({
  container: {
    ...theme.shadows.card,
    borderRadius: BORDER_RADIUS,
    overflow: 'hidden',
  },
  contentContainer: {
    padding: theme.margins.cardUnit * 2,
  },
  formContainer: {
    margin: -2 * theme.margins.cardUnit,
  },
  formikContainer: {},
  leftButton: {
    backgroundColor: theme.colors.white,
    borderTopColor: theme.colors.border,
    borderTopWidth: BORDER_WIDTH,
  },
});
