import { get } from 'lodash';
import React from 'react';
import { ScrollView } from 'react-native';
import LoadingCard from '../LoadingCard';
import SlotsList from '../SlotsList';
import { getBookingTemplateOffer_pos_Pos_bookingOfferTemplate_nextOrderableOffers as IOfferBooking } from '../../types/bookingservice/getBookingTemplateOffer';
import { getOfferSlots_offer_Offer as IOffer } from '../../types/clickandcollect/getOfferSlots';

export interface IProps {
  isLoading: boolean;
  offer: IOffer | IOfferBooking;
  slotId: string | null;
  order?: any;
  maxOrdersPerSlot: number;
}
const BookingOfferSlots = (props: IProps): JSX.Element => (
  <ScrollView>
    <LoadingCard isLoading={props.isLoading} big noPadding>
      {get(props, 'offer.slots[0].withdrawRange') ? (
        <SlotsList {...props} maxOrdersPerSlot={props.maxOrdersPerSlot} isBookingOffer={true} />
      ) : null}
    </LoadingCard>
  </ScrollView>
);

export default BookingOfferSlots;
