import { ImageURISource } from 'react-native';
import { compose, withProps } from 'recompose';

import { getPosTypeColor, getPosTypeIcon } from '../../services/pointOfSaleMapper';
import { IPosType } from '../../types/common';

import PointOfSaleIconStripe, { IProps } from './PointOfSaleIconStripe.component';

interface IWithIconProps {
  color: string;
  sourceIcon: ImageURISource;
}

const withIconProps = (ownProps: IExternalProps): IWithIconProps => ({
  color: getPosTypeColor(ownProps.posType),
  sourceIcon: getPosTypeIcon(ownProps.posType),
});

export interface IExternalProps {
  posType: IPosType;
  posId: string;
}
export type IConnectedProps = IWithIconProps;
export type IContainerProps = Omit<IProps & IExternalProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withProps(withIconProps))(PointOfSaleIconStripe);
