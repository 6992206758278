import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';

import withNavigation from '../../../hoc/withNavigation';
import { logOut } from '../../../services/authentication/logOut';

import LogOutButton, { IProps } from './LogOutButton.component';

interface IWithOnPressProps {
  onPress: () => void;
}

const withOnPress = (): IWithOnPressProps => ({
  onPress: (): void => logOut(),
});

export type IConnectedProps = NavigationInjectedProps & IWithOnPressProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withOnPress)
)(LogOutButton);
