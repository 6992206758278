import { FormikProps } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { NavigationInjectedProps } from 'react-navigation';

import I18n from '../../../lib/i18n';
import Button from '../../Button';
import FormFormik from '../../FormFormik';
import Optins from '../../Optins';
import Paragraph from '../../Paragraph';
import TextInputFormik from '../../TextInputFormik';
import ErrorMessage from '../../ErrorMessage';

export interface IValues {
  checkboxNewsletter: boolean;
  checkboxTos: boolean;
  email: string;
}

export type IProps = FormikProps<IValues> & NavigationInjectedProps;

class SignUpEmailFormikForm extends PureComponent<IProps, {}> {
  public render(): ReactNode {
    const { errors, isSubmitting, setFieldValue, submitForm, values } = this.props;

    return (
      <FormFormik testID="SignUpEmail">
        <Paragraph text={I18n.t('signUp.email.paragraph')} />
        <TextInputFormik
          hasError={!!errors.email}
          name="email"
          placeholder={I18n.t('signUp.email.placeholder')}
          returnKeyType="done"
          type="email"
          error=""
          testID="email"
        />
        {!!errors.email && <ErrorMessage text={I18n.t(errors.email)} />}
        <Optins onPressLink={this.onPressLink} values={values} setFieldValue={setFieldValue} />
        <Button
          disabled={!this.canSubmit(values)}
          text={I18n.t('signUp.email.validate')}
          isLoading={isSubmitting}
          onPress={submitForm}
          testID="validateEmail"
        />
      </FormFormik>
    );
  }

  private canSubmit = (values: IValues): boolean => {
    return !!values.email && !!values.checkboxTos;
  };

  private onPressLink = (): void => {
    this.props.navigation.navigate('modalTermsOfService');
  };
}

export default SignUpEmailFormikForm;
