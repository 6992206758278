import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { logOut } from '../../services/authentication/logOut';

import withNavigation from '../../hoc/withNavigation';
import I18n from '../../lib/i18n';
import { selectAuthenticationToken } from '../../redux/authentication/selectors';
import { IAppState } from '../../redux/reducer';
import { getNotificationStatusFromOptins } from '../../services/optins';
import { IUserOptin } from '../../types/common';
import { IProps as IProfileSectionProps } from '../ProfileSection';
import ProfileSections, { IProps } from './ProfileSections.component';
import OneTrust, { CookieCategory, startListenForConsentChanges } from '../../services/oneTrust';

const getUserInfo = loader('../../queries/getUserInfo.gql');

export interface IMapStateToProps {
  token?: string;
}

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  token: selectAuthenticationToken(state),
});

export interface IWithProfileSectionsProps {
  profileSections: IProfileSectionProps[];
}

const withProfileSectionsProps = (
  ownProps: IGraphQLProps & NavigationInjectedProps
): IWithProfileSectionsProps => ({
  profileSections: [
    {
      iconName: 'profile',
      onPress: (): void => {
        ownProps.navigation.navigate('myInformations');
      },
      subtitle: ownProps.email,
      title: I18n.t('dashboard.profile.section.login'),
    },
    {
      iconName: 'preferences',
      onPress: (): void => {
        ownProps.navigation.navigate('notifications');
      },
      subtitle: I18n.t(getNotificationStatusFromOptins(ownProps.userOptins)),
      title: I18n.t('dashboard.profile.section.notifications'),
    },
    {
      iconName: 'cookie',
      onPress: (): void => {
        // display cookies preference but only for mobile
        OneTrust.showPreferenceCenterUI();
      },
      title: I18n.t('dashboard.profile.section.cookiesManagement'),
    },
    {
      iconName: 'question',
      onPress: (): void => {
        ownProps.navigation.navigate('help');
      },
      title: I18n.t('dashboard.profile.section.help'),
    },
    {
      iconName: 'documents',
      onPress: (): void => {
        ownProps.navigation.navigate('legalInfo');
      },
      title: I18n.t('dashboard.profile.section.legalInfo'),
    },
    {
      iconName: 'language',
      onPress: (): void => {
        ownProps.navigation.navigate('languages');
      },
      subtitle: I18n.t('locale.name'),
      title: I18n.t('dashboard.profile.section.language'),
    },
    {
      iconName: 'logout',
      onPress: (): void => {
        logOut();
      },
      title: I18n.t('dashboard.profile.logOut'),
    },
  ],
});

export interface IGraphQLProps {
  email?: string;
  userOptins: IUserOptin[];
  virtualTicket?: boolean;
}

export default compose<IProps, {}>(
  connect(mapStateToProps),
  graphql(getUserInfo, {
    options: (props: IMapStateToProps): QueryOpts => ({
      variables: {
        token: props.token,
      },
    }),
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      email: get(props, 'data.getUser.email'),
      userOptins: get(props, 'data.getUser.userOptins') || [],
      virtualTicket: get(props, 'data.getUser.guests.edges[0].node.virtualTicket') || false,
    }),
  }),
  withNavigation,
  withProps(withProfileSectionsProps)
)(ProfileSections);
