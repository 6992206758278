import { AnyAction, Reducer } from 'redux';

import { actionTypes } from './actions';

export interface IQueriesState {
  [key: string]: string;
}

export const initialState: IQueriesState = {};

const queriesReducer: Reducer<IQueriesState> = (
  state: IQueriesState = initialState,
  action: AnyAction
): IQueriesState => {
  switch (action.type) {
    case actionTypes.QUERIES_SET_DATE_TIME:
      return {
        ...state,
        [action.payload.query]: action.payload.dateTime,
      };
    default:
      return state;
  }
};

export default queriesReducer;
