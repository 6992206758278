import React, { ReactElement } from 'react';
import {
  Image,
  ImageStyle,
  ImageURISource,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import { Chart } from '../../../components/Chart';
import { OpenCloseButton } from '../../../components/OpenCloseButton';
import {
  formatAttendanceForChart,
  getCurrentAttendanceColoredLabel,
} from '../../../services/attendanceFormatter';
import theme from '../../../theme';
import { IAttendance, IPosSchedule } from '../../../types/common';

export interface IAttendanceLabelProps {
  color: string;
  image: ImageURISource;
  reverseLabels: boolean;
  text: string;
}

export const AttendanceLabel = ({
  color,
  image,
  reverseLabels,
  text,
}: IAttendanceLabelProps): ReactElement<{}> => (
  <View style={[styles.labelContainer, { flexDirection: reverseLabels ? 'row-reverse' : 'row' }]}>
    <Text
      style={[
        theme.fonts.label,
        {
          color,
        },
      ]}
    >
      {text}
    </Text>
    <Image style={[styles.image, { tintColor: color }]} source={image} resizeMode="contain" />
  </View>
);

export interface IAttendanceHeaderProps {
  attendance: IAttendance;
  isAttendanceOpen: boolean;
  onAttendancePress: () => void;
  reverseLabels: boolean;
  schedules: IPosSchedule[];
}

export const AttendanceHeader = ({
  attendance,
  isAttendanceOpen,
  onAttendancePress,
  reverseLabels,
  schedules,
}: IAttendanceHeaderProps): ReactElement<{}> => (
  <View style={[styles.container, { flexDirection: reverseLabels ? 'row-reverse' : 'row' }]}>
    <View style={{ marginHorizontal: theme.margins.unit }}>
      <OpenCloseButton isOpen={isAttendanceOpen} onPress={onAttendancePress} />
    </View>
    <AttendanceLabel
      reverseLabels={reverseLabels}
      {...getCurrentAttendanceColoredLabel(attendance, schedules)}
    />
  </View>
);

export interface IAttendanceChartProps {
  attendance: IAttendance;
  isAttendanceOpen: boolean;
  schedules: IPosSchedule[];
}

export const AttendanceChart = ({
  attendance,
  isAttendanceOpen,
  schedules,
}: IAttendanceChartProps): ReactElement<{}> | null => {
  const chartData = formatAttendanceForChart(attendance, schedules);

  return isAttendanceOpen ? <Chart {...chartData} /> : null;
};

export interface IStyle {
  container: ViewStyle;
  image: ImageStyle;
  label: TextStyle;
  labelContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    justifyContent: 'flex-end',
    marginHorizontal: theme.margins.unit,
    marginVertical: theme.margins.unit * 2,
  },
  image: {
    height: 20,
    marginHorizontal: 5,
    width: 20,
  },
  label: {
    color: theme.colors.errorDanger,
  },
  labelContainer: {
    justifyContent: 'center',
    marginHorizontal: 5,
    marginVertical: 2,
  },
});
