import React, { PureComponent, ReactNode } from 'react';
import { ImageStyle, ImageURISource, PixelRatio, StyleSheet, View, ViewStyle } from 'react-native';

import Touchable from '../../Touchable';
const defaultPosImage = require('../../../assets/images/pos-default.jpg');
import { getCloudimageUrl } from '../../../services/cloudimage';
import ProgressiveImage from '../../ProgressiveImage';

import { isWeb } from '../../../lib/responsive';

const BODY_CARD_THUMB_HEIGHT = 15;
const BODY_CARD_HEIGHT = 162;
const BODY_CARD_HEIGHT_NO_BACKGROUND = 50;

export interface IProps {
  onPress?: () => void;
  source?: ImageURISource;
  noBackground?: boolean;
  posId?: string;
}

class PosCardBody extends PureComponent<IProps> {
  public render(): ReactNode {
    const { posId, onPress, source, noBackground } = this.props;
    const multiplier = isWeb() ? 2 : 1;
    const containerHeight =
      typeof noBackground === 'undefined' ? BODY_CARD_HEIGHT : BODY_CARD_HEIGHT_NO_BACKGROUND;
    const Wrapper: typeof Touchable | typeof View = onPress ? Touchable : View;

    const thumbnailSource: ImageURISource = {};
    const imageSource: ImageURISource = {};

    if (source) {
      thumbnailSource.uri = getCloudimageUrl(
        source.uri,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_THUMB_HEIGHT * multiplier))
      );
      imageSource.uri = getCloudimageUrl(
        source.uri,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(containerHeight * multiplier))
      );
    }

    return (
      <Wrapper testID={`cardStatusPos_${posId}`} onPress={onPress} style={[styles.container, {height: containerHeight}]}>
        {!noBackground && <ProgressiveImage
          source={imageSource.uri ? imageSource : defaultPosImage}
          thumbnailSource={thumbnailSource}
          style={styles.image}
        />}
        {this.props.children}
      </Wrapper>
    );
  }
}

export interface IStyle {
  container: ViewStyle;
  image: ImageStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
  image: {
    alignSelf: 'center',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});

export default PosCardBody;
