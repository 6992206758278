type IButtonsKey = 'LargeButton';

const buttons: { [key in IButtonsKey]: object } = {
  LargeButton: {
    borderRadius: 4,
    height: 52,
    paddingVertical: 0,
  },
};

export default buttons;
