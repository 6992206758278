import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import theme from '../../../theme';
import LoadingView from '../../LoadingView';

export interface IProps {
  childrenStyle?: StyleProp<ViewStyle>;
  isLoading: boolean;
  showTitle?: boolean;
  style?: StyleProp<ViewStyle>;
  title: string;
}

export default class SummarySection extends PureComponent<IProps> {
  public render(): ReactNode {
    const { title, showTitle, style, children, childrenStyle, isLoading } = this.props;
    const containerStyle = style ? [styles.container, style] : styles.container;
    const childrenContainerStyle = childrenStyle
      ? [styles.childrenContainter, childrenStyle]
      : styles.childrenContainter;

    return (
      <LoadingView isLoading={isLoading}>
        <View style={containerStyle}>
          {showTitle && <Text style={styles.text}>{title.toUpperCase()}</Text>}
          <View style={childrenContainerStyle}>{children}</View>
        </View>
      </LoadingView>
    );
  }
}

const styles = StyleSheet.create({
  childrenContainter: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    marginVertical: 2 * theme.margins.cardUnit,
  },
  text: {
    ...theme.fonts.cardText,
    color: theme.colors.textGray,
  },
});
