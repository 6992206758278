import withPaymentStatus from '../../../../hoc/withPaymentStatus';

import TopUpCallback, { IProps } from './TopUpCallback.component';
import { compose, withProps } from 'recompose';
import { withNavigation, NavigationInjectedProps } from 'react-navigation';

interface Iback {
  backRouteName: string;
  backRouteParams: Record<string, any>;
}

const withBackScreen = (ownProps: NavigationInjectedProps): Iback => ({
  backRouteName: ownProps.navigation.getParam('backRouteName') || 'balance',
  backRouteParams: { ...(ownProps.navigation.getParam('backRouteParams') || {}), refresh: true },
});

export default compose<IProps, IProps & NavigationInjectedProps & Iback>(
  withNavigation,
  withProps(withBackScreen),
  withPaymentStatus
)(TopUpCallback);
