import { ErrorResponse } from 'apollo-link-error';
import { GraphQLError } from 'graphql/error/GraphQLError';

import { ENVIRONMENT, getConfig } from '../environment';
import I18n from '../lib/i18n';
import Logger from '../lib/logger';
import { logOut } from '../services/authentication/logOut';
import navigator from '../services/navigation';
import Toaster from '../services/toaster';

export enum ErrorType {
  FORBIDDEN = 'FORBIDDEN',
  QUOTATION_ERROR = 'QUOTATION_ERROR',
  ORDER_INVALID = 'ORDER_INVALID',
  AUTH_REQUIRED = 'AUTH_REQUIRED',
  TEMPORARY_CODE_INVALID = 'TEMPORARY_CODE_INVALID',
  TEMPORARY_CODE_EXPIRED = 'TEMPORARY_CODE_EXPIRED',
  TEMPORARY_CODE_OFFER_INVALID = 'TEMPORARY_CODE_OFFER_INVALID',
  TEMPORARY_CODE_OFFER_TEMPLATE_INVALID = 'TEMPORARY_CODE_OFFER_TEMPLATE_INVALID',
  BADGE_NOT_FOUND = 'Badge not found',
}

const ignoreError = (graphQLError: GraphQLError) => {
  return (
    graphQLError.message &&
    [
      ErrorType.ORDER_INVALID.toString(),
      ErrorType.TEMPORARY_CODE_INVALID.toString(),
      ErrorType.TEMPORARY_CODE_EXPIRED.toString(),
      ErrorType.AUTH_REQUIRED.toString(),
    ].includes(graphQLError.message)
  );
};

const onError = (error: ErrorResponse): void => {
  const env = getConfig();
  const isDev = env.ENV.toLowerCase() !== ENVIRONMENT.PRODUCTION.toLowerCase();
  if (error.graphQLErrors) {
    error.graphQLErrors
      .filter(error => !ignoreError(error))
      .forEach((graphQLError: GraphQLError): void => {
        // @todo: cleanup => most error codes dont exist anymore
        switch (graphQLError.message) {
          case ErrorType.QUOTATION_ERROR:
            Logger.error(graphQLError);
            Toaster.showError(
              I18n.t('dashboard.eat.error.quotation') + (isDev ? '\n' + graphQLError.message : '')
            );
            break;
          case 'ALREADY_COMPLETED':
            Logger.error(graphQLError);
            Toaster.showError(
              I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelErrorCompleted') +
                (isDev ? '\n' + graphQLError.message : '')
            );
            break;
          case 'ALREADY_CANCELLED':
            Logger.error(graphQLError);
            Toaster.showError(
              I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelErrorCancelled') +
                (isDev ? '\n' + graphQLError.message : '')
            );
            break;
          case 'ARTICLE_OUT_OF_STOCK':
            Logger.error(graphQLError);
            Toaster.showError(
              I18n.t('dashboard.eat.clickAndCollect.orderDetails.orderArticleOutOfStock') +
                (isDev ? '\n' + graphQLError.message : '')
            );
            break;
          case 'OUT_OF_STOCK':
            // catch the error and do nothing, do not trigger a notification
            Logger.error(graphQLError);
            break;
          case 'NOT_CANCELABLE':
            Logger.error(graphQLError);
            Toaster.showError(
              I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelErrorNotCancelable') +
                (isDev ? '\n' + graphQLError.message : '')
            );
            break;
          case 'NOT_CONFIRMED':
            Logger.error(graphQLError);
            Toaster.showError(
              I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelErrorNotConfirmed') +
                (isDev ? '\n' + graphQLError.message : '')
            );
            break;
          case 'STOCK_TOO_LOW':
            Logger.error(graphQLError);
            Toaster.showError(
              I18n.t('dashboard.eat.clickAndCollect.error') +
                (isDev ? '\n' + graphQLError.message : '')
            );
            break;
          case 'EXPIRED_AUTH':
          case 'INVALID_AUTH':
          case 'FORBIDDEN':
            if (error.operation.operationName !== 'getI18nProviderLocale') {
              navigator.navigate('landing');
            }
            // Get empty response from CS
            // because user has not a valid account
            if (error.response?.data?.mercanetV2 === null) {
              Toaster.showError('You are not allowed to perform this request !');
              navigator.navigate('balance');
            } else {
              logOut();
            }
            break;
          case 'ORDER_IS_NOT_A_CART':
            navigator.navigate('eat', { tab: 1 });
            break;
          case ErrorType.BADGE_NOT_FOUND:
            Logger.error(graphQLError);
            break;
          case 'LOCKER_NOT_FOUND':
          case 'BALANCE_UNAVAILABLE':
            // catch the error and do nothing, do not trigger a notification
            break;
          case 'OTHER':
            // errors that have the code OTHER but need to be detailed in the app should be added here
            const detailedMessage =
              graphQLError?.extensions?.original?.details &&
              graphQLError.extensions.original.details.length > 0 &&
              graphQLError.extensions.original.details[0].message;

            if (
              detailedMessage === 'slot is full' ||
              detailedMessage === 'Offer must be published'
            ) {
              // we don't want to show a toast error in this case so we break the switch case
              break;
            }
            break;
          default:
            Logger.error(graphQLError);
            const isSignUpError = graphQLError?.message === 'BODY_RESOURCE' && (graphQLError?.path?.[0] === 'signupV2' || graphQLError?.path?.[0] === 'addUserHoldingView');
            !isSignUpError ? Toaster.showError(I18n.t('error.gql') + (isDev ? '\n' + graphQLError.message : '')) : null;
        }
      });
  } else if (error.networkError) {
    Toaster.showError(I18n.t('error.network') + (isDev ? '\n' + error.networkError.message : ''));
  }
};

export default { onError };
