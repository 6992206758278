import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import Touchable from '../../Touchable';
import I18n from '../../../lib/i18n';
import { formatCreationHours } from '../../../services/offersFormatter';
import theme from '../../../theme';
import Icon from '../../Icon';
import { IOrderByPos } from '../../../pages/Dashboard/Eat/BookingService/BookingCardGroup/BookingCardGroup.container';

export interface IProps {
  onPress?: () => void;
  ordersGroupedByPos: IOrderByPos[];
  numberOfOrders: number;
  testID?: string;
}

const ICON_SIZE = 20;

class BookingOrderItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { ordersGroupedByPos, numberOfOrders, onPress, testID } = this.props;

    if (ordersGroupedByPos.length === 0) {
      return null;
    }

    return (
      <Touchable style={styles.container} onPress={onPress} testID={`${testID}_touchable`} >
        <View style={styles.detailsContainer}>
          <View style={styles.orderDetails}>
            <View style={styles.row}>
              <Image style={styles.bookingIcon} source={theme.images.booking} />
              <Text style={styles.cardTitle} numberOfLines={1}>
                {`${I18n.t('dashboard.eat.bookingOrderCard.title')}`}
              </Text>
            </View>
            {ordersGroupedByPos.map(pos => (
              <View testID={`${testID}_ordersGroupedByPos_${pos.id}`} key={pos.id}>
                {new Date(pos.withdrawRange.split('/')[0]).getDate() === new Date().getDate() ? (
                  <View>
                    <View style={styles.row}>
                      <Image style={styles.dishIcon} source={theme.images.dishBooking} />
                      <Text style={styles.text} numberOfLines={1}>
                        {pos.name}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.pillStrong} numberOfLines={1}>
                        {formatCreationHours(pos.withdrawRange.split('/')[0])}
                      </Text>
                      <Text style={styles.pill} numberOfLines={1}>
                        {`${I18n.t('dashboard.eat.bookingOrderCard.today')}`}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View>
                    <View style={styles.row}>
                      <Image style={styles.dishIconGray} source={theme.images.dishBooking} />
                      <Text style={styles.textGray} numberOfLines={1}>
                        {pos.name}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.tomorrowMessageBold} numberOfLines={1}>
                        {`${I18n.t('dashboard.eat.bookingOrderCard.tomorrow')}`}
                      </Text>
                      <Text style={styles.tomorrowMessage} numberOfLines={1}>
                        {`${I18n.t('dashboard.eat.bookingOrderCard.today')}`}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            ))}
          </View>
          <View style={styles.stateContainer}>
            <View style={styles.badgeContainer}>
              <Text style={styles.badge} numberOfLines={1}>
                {numberOfOrders}
              </Text>
            </View>
            <Icon name="chevronRight" size={ICON_SIZE} tintColor={theme.colors.black} />
          </View>
        </View>
      </Touchable>
    );
  }
}
export interface IStyle {
  container: ViewStyle;
  pill: TextStyle;
  pillStrong: TextStyle;
  detailsContainer: ViewStyle;
  name: TextStyle;
  orderDetails: ViewStyle;
  orderId: TextStyle;
  badgeContainer: ViewStyle;
  stateContainer: ViewStyle;
  text: TextStyle;
  textGray: TextStyle;
  bookingIcon: ImageStyle;
  dishIcon: ImageStyle;
  dishIconGray: ImageStyle;
  row: ViewStyle;
  cardTitle: TextStyle;
  badge: TextStyle;
  tomorrowMessageBold: TextStyle;
  tomorrowMessage: TextStyle;
}

const BORDER_RADIUS = 4;

const styles = StyleSheet.create<IStyle>({
  container: {
    ...theme.shadows.card,
    alignItems: 'stretch',
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  detailsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.margins.cardUnit * 2,
  },
  orderDetails: {
    flex: 1,
    marginRight: theme.margins.cardUnit * 2,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardTitle: {
    ...theme.fonts.pricesCardItemLabel,
    color: theme.colors.black,
  },
  pill: {
    ...theme.fonts.cardText,
    color: theme.colors.grayMedium,
    backgroundColor: '#e5f9f6',
    borderRadius: BORDER_RADIUS,
    paddingHorizontal: theme.margins.cardUnit,
    paddingVertical: theme.margins.cardUnit * 0.25,
    marginRight: theme.margins.cardUnit,
  },
  pillStrong: {
    ...theme.fonts.cardTextStrong,
    color: theme.colors.grayMedium,
    backgroundColor: '#e5f9f6',
    borderRadius: BORDER_RADIUS,
    paddingHorizontal: theme.margins.cardUnit,
    paddingVertical: theme.margins.cardUnit * 0.25,
    marginLeft: theme.margins.cardUnit * 3,
    marginRight: theme.margins.cardUnit,
  },
  tomorrowMessage: {
    color: '#383838',
    opacity: 0.3,
  },
  tomorrowMessageBold: {
    fontWeight: 'bold',
    color: '#383838',
    opacity: 0.3,
    marginLeft: theme.margins.cardUnit * 3,
  },
  name: {
    ...theme.fonts.semiBoldText,
    color: theme.colors.black,
    marginRight: theme.margins.cardUnit,
  },
  orderId: {
    ...theme.fonts.strongText,
    color: theme.colors.black,
  },
  badgeContainer: {
    backgroundColor: theme.colors.blue,
    borderRadius: 25,
    paddingHorizontal: theme.margins.cardUnit,
    paddingVertical: theme.margins.cardUnit * 0.35,
    marginLeft: theme.margins.cardUnit * 1.1,
  },
  stateContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    ...theme.fonts.stepperItem,
    color: theme.colors.black,
    marginVertical: theme.margins.cardUnit,
    marginRight: theme.margins.cardUnit * 1,
  },
  textGray: {
    ...theme.fonts.stepperItem,
    color: '#383838',
    opacity: 0.3,
    marginVertical: theme.margins.cardUnit,
    marginRight: theme.margins.cardUnit * 1,
  },
  bookingIcon: {
    marginRight: theme.margins.cardUnit,
    position: 'relative',
    tintColor: theme.colors.warning,
  },
  dishIcon: {
    marginRight: theme.margins.cardUnit,
    position: 'relative',
    tintColor: theme.colors.black,
  },
  dishIconGray: {
    marginRight: theme.margins.cardUnit,
    position: 'relative',
    tintColor: '#383838',
    opacity: 0.3,
  },
  badge: {
    ...theme.fonts.cardText,
    color: theme.colors.white,
  },
});

export default BookingOrderItem;
