import { loader } from 'graphql.macro';
import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import BalanceCard from '../../../../../components/BalanceCard';
import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import Icon from '../../../../../components/Icon';
import LoadingView from '../../../../../components/LoadingView';
import TransactionsCard from '../../../../../components/TransactionsCard';
import { WebPage } from '../../../../../componentsWeb';
import { getConfig } from '../../../../../environment';
import withBalanceDetails from '../../../../../hoc/withBalanceDetails';
import I18n from '../../../../../lib/i18n';
import Logger from '../../../../../lib/logger';
import { selectAuthenticationToken } from '../../../../../redux/authentication/selectors';
import { IAppState } from '../../../../../redux/reducer';
import { Tracker } from '../../../../../services/analytics';
import { submitToUrl } from '../../../../../services/submitToUrl';
import theme from '../../../../../theme';
import { IAmount } from '../../../../../types/common';
import CurrencyInput from './CurrencyInput.component';

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 150;
const getPaymentRequest = loader('../../../../../queries/getPaymentRequest.gql');

export interface IState {
  amount: string;
  error: string | null;
}

export interface IProps extends NavigationInjectedProps, WithApolloClient<{}> {
  balance: IAmount;
  balanceDateTime: string;
  isLoading: boolean;
  title: string;
  token: string;
}

class TopUpOtherAmount extends PureComponent<IProps> {
  public state: IState = {
    amount: '',
    error: null,
  };

  public componentDidMount(): void {
    Tracker.trackNavigation({
      // @ts-ignore
      pathname: window.location.pathname,
      // @ts-ignore
      search: window.location.search,
    });
  }

  public render(): ReactNode {
    const { isLoading, balance, balanceDateTime } = this.props;
    const { error, amount } = this.state;

    return (
      <WebPage>
        <LoadingView isLoading={isLoading}>
          <BalanceCard isLoading={isLoading} balance={balance} balanceDateTime={balanceDateTime} />
          <Card big>
            <View style={styles.icon}>
              <a onClick={this.onClose} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Icon name="cross" size={24} />
              </a>
            </View>

            <CurrencyInput
              minAmount={MIN_AMOUNT}
              maxAmount={MAX_AMOUNT}
              amount={amount}
              onChange={(newError, newAmount): void =>
                this.setState({ error: newError, amount: newAmount })
              }
            />
            {!!error && (
              <Text style={styles.error}>
                {I18n.t(`dashboard.balance.topUp.${error}`, {
                  minAmount: MIN_AMOUNT,
                  maxAmount: MAX_AMOUNT,
                })}
              </Text>
            )}
            <View style={styles.button}>
              <Button
                text={I18n.t('dashboard.balance.topUp.callbackModal.validate')}
                onPress={this.submit}
                isLoading={isLoading}
                disabled={!!error || !amount}
              />
            </View>
          </Card>
          <TransactionsCard contentContainerStyle={{ maxHeight: 500 }} />
        </LoadingView>
      </WebPage>
    );
  }

  private onClose = (): void => {
    this.props.navigation.navigate('balance');
  };

  private submit = async (): Promise<void> => {
    const env = getConfig();
    const { client, token } = this.props;
    const result = await client.query<{}>({
      fetchPolicy: 'network-only',
      query: getPaymentRequest,
      variables: {
        amount: this.state.amount,
        token,
        userRedirectUrl: `${env.WEB_BASE_URL}/balance/payment-callback/`,
      },
    });

    const method: string = get(result, 'data.mercanetV2.method');
    const url: string = get(result, 'data.mercanetV2.uri');
    const body: string = get(result, 'data.mercanetV2.body');

    try {
      submitToUrl(url, method, body);
    } catch (error) {
      Logger.error(error);
    } finally {
      Tracker.trackNavigation({ pathname: 'topUpPayment' });
    }
  };
}

const styles = StyleSheet.create({
  icon: {
    height: 24,
    flex: 1,
    width: '100%',
    justifyContent: 'flex-end',
  },
  button: {
    height: 150,
    flex: 1,
  },
  error: {
    fontSize: 14,
    color: theme.colors.error,
    position: 'absolute',
    bottom: 90,
    width: '90%',
    textAlign: 'center',
  },
});

const mapStateToProps = (state: IAppState): {} => ({
  token: selectAuthenticationToken(state),
});

export default compose<IProps, {}>(
  withApollo,
  withBalanceDetails,
  connect(mapStateToProps)
)(TopUpOtherAmount);
