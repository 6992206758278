import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import theme from '../../theme';
import { IAmount } from '../../types/common';

import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';

import Balance from './Balance';
import BalanceDateTime from './BalanceDateTime';

export interface IProps {
  balance?: IAmount;
  balanceDateTime?: string;
  isLoading?: boolean;
  badgeNumber?: string;
}

class BalanceCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { balance, balanceDateTime, isLoading, badgeNumber } = this.props;
    const leftContainerStyle = StyleSheet.flatten([
      styles.leftContainer,
      balanceDateTime ? {} : { paddingVertical: theme.margins.cardUnit },
    ]);
    const updatedBalance = badgeNumber ? balance : ({ ...balance, amount: '-' } as IAmount);

    return (
      <LoadingCard style={styles.card} isLoading={isLoading}>
        <View style={leftContainerStyle}>
          <CardTitle text={I18n.t('balance.cardTitle')} />
          <BalanceDateTime dateTime={balanceDateTime} />
        </View>
        <View style={styles.rightContainer}>
          <Balance balance={updatedBalance} />
        </View>
      </LoadingCard>
    );
  }
}

export interface IStyle {
  card: ViewStyle;
  leftContainer: ViewStyle;
  rightContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  card: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: theme.margins.cardUnit,
  },
  rightContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export default BalanceCard;
