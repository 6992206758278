import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ActionCreator } from 'redux';

import withNavigation from '../../../hoc/withNavigation';
import { IToggleRecipeDisclaimerAction, toggleRecipeDisclaimer } from '../../../redux/menu/actions';

import InfoRecipeDisclaimerFormikForm, { IProps } from './InfoRecipeDisclaimerFormikForm.component';
interface IDispatchProps {
  toggleRecipeDisclaimer: ActionCreator<IToggleRecipeDisclaimerAction>;
}

const mapDispatchToProps: IDispatchProps = {
  toggleRecipeDisclaimer,
};

export type IConnectedProps = NavigationInjectedProps & IDispatchProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    undefined,
    mapDispatchToProps
  ),
  withNavigation
)(InfoRecipeDisclaimerFormikForm);
