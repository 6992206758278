import forEach from 'lodash/forEach';
import moment from 'moment';
import 'moment/locale/fr';
import numbro from 'numbro';
import I18n, { getLanguages } from 'react-native-i18n';

import translations from '../../translations';

import Logger from './logger';

export type ILocale = 'fr' | 'en';

export const DEFAULT_LOCALE: ILocale = 'fr';
export const supportedLanguages = ['fr', 'en'];

export interface INumbroConfig {
  numbroConfig?: numbro.NumbroLanguage;
  numbroLocale: string;
}

export const NUMBRO_LOCALE_CONFIG: { [key in ILocale]: INumbroConfig } = {
  en: { numbroLocale: 'en-US' },
  fr: { numbroLocale: 'fr-FR', numbroConfig: require('numbro/languages/fr-FR') },
};

I18n.fallbacks = true;
I18n.translations = translations;
I18n.defaultLocale = DEFAULT_LOCALE;

export const initializeI18n = async (): Promise<ILocale> => {
  initializeNumbro();

  let locale: ILocale = DEFAULT_LOCALE;
  try {
    const languages = await getLanguages();
    const supportedLanguage = languages.find((language: string) =>
      supportedLanguages.includes(language.substring(0, 2))
    );

    if (supportedLanguage) {
      locale = supportedLanguage.substring(0, 2) as ILocale;
      setAppLocale(locale);
    }
  } catch (error) {
    Logger.error(error);
    setAppLocale(locale);
  }

  return locale;
};

export const setAppLocale = (locale: ILocale): void => {
  I18n.locale = locale;
  moment.locale(locale);
  numbro.setLanguage(NUMBRO_LOCALE_CONFIG[locale].numbroLocale);
};

export const initializeNumbro = (): void => {
  forEach(NUMBRO_LOCALE_CONFIG, (value: INumbroConfig) => {
    if (value.numbroConfig) {
      numbro.registerLanguage(value.numbroConfig);
    }
  });
};

export default I18n;
