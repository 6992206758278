import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import PointOfSaleIconStripe from '../../../components/PointOfSaleIconStripe';
import { IPosType } from '../../../types/common';

import PosCardName from './PosCardName';
import PosCardType from './PosCardType';

export interface IProps {
  name: string;
  onPress?: () => void;
  posType: IPosType;
  style?: StyleProp<ViewStyle>;
  posId: string;
}

class PosCardHeader extends PureComponent<IProps> {
  public render(): ReactNode {
    const { onPress, posId, posType, name, style } = this.props;

    return (
      <PointOfSaleIconStripe posId={posId} posType={posType} onPress={onPress} style={style}>
        <PosCardType posType={posType} />
        <PosCardName text={name} />
      </PointOfSaleIconStripe>
    );
  }
}

export default PosCardHeader;
