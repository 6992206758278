import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { FlatList, ViewStyle } from 'react-native';
import theme from '../../theme';

import LegalInfoSection, { IProps as ILegalInfoSectionProps } from '../LegalInfoSection';
import LoadingCard from '../LoadingCard';
import Separator from '../Separator';

import styles from './styles';

export interface IProps {
  legalInfoSections: ILegalInfoSectionProps[];
  testID?: string;
}

class LegalInfoSections extends PureComponent<IProps> {
  public render(): ReactNode {
    const { legalInfoSections, testID } = this.props;

    return (
      <LoadingCard style={styles.card}>
        <FlatList
          testID={testID}
          data={legalInfoSections}
          keyExtractor={(item: ILegalInfoSectionProps): string => item.title}
          renderItem={this.renderItem}
          ItemSeparatorComponent={(): ReactElement<Separator> => (
            <Separator margin={theme.margins.unit} />
          )}
          ListFooterComponent={(): ReactElement<Separator> => (
            <Separator margin={theme.margins.unit} />
          )}
        />
      </LoadingCard>
    );
  }

  public renderItem = ({
    item,
  }: {
    item: ILegalInfoSectionProps;
  }): ReactElement<LegalInfoSection> => {
    return (
      <LegalInfoSection
        testID={`${this.props.testID}_legalInfoSection_${item.id}`}
        onPress={item.onPress}
        title={item.title}
        subtitle={item.subtitle}
      />
    );
  };
}

export interface IStyle {
  card: ViewStyle;
}

export default LegalInfoSections;
