import React, { PureComponent, ReactNode } from 'react';
import { Image } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import Button from '../../../../src/components/Button';
import Paragraph from '../../../../src/components/Paragraph';
import SubTitle from '../../../../src/components/SubTitle';
import I18n from '../../../../src/lib/i18n';
import theme from '../../../../src/theme';

import SignupLayout from '../../components/SignupLayout';

const styles = {
  mainContent: {
    marginTop: '83px',
  },
  thumbIconStyle: {
    height: 147,
    margin: 'auto',
    marginBottom: '81px',
    width: 200,
  },
};

class ConfirmEmailLead extends PureComponent<NavigationInjectedProps> {
  public render(): ReactNode {
    return (
      <form>
        <SignupLayout title={I18n.t('signUp.headerTitle')}>
          <div style={styles.mainContent}>
            <Image source={theme.images.thumb} style={styles.thumbIconStyle} />
            <SubTitle text={I18n.t('signUp.emailLead.title')} />
            <Paragraph text={I18n.t('signUp.emailLead.paragraph')} />
            <Button text={I18n.t('signUp.emailLead.button')} onPress={this.goToHome} />
          </div>
        </SignupLayout>
      </form>
    );
  }

  private goToHome = (): void => {
    this.props.navigation.navigate('landing');
  };
}

export default ConfirmEmailLead;
