import React, { PureComponent, ReactNode } from 'react';
import { TextInput as RNTextInput, TextInputProps as RNTextInputProps, View } from 'react-native';

import theme from '../../theme';
import { styles } from '../TextInputFormik/TextInputFormik.component';

export type IStatus = 'SUCCESS' | 'DEFAULT';

export const STATE: { [key in IStatus]: key } = {
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
};

export interface IProps extends RNTextInputProps {
  borderState?: IStatus;
  textState?: IStatus;
}

type IDefaultProps = Required<Pick<IProps, 'borderState' | 'textState'>>;

class FakeInput extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    borderState: STATE.DEFAULT,
    textState: STATE.DEFAULT,
  };

  public render(): ReactNode {
    const { borderState, textState, ...inputProps } = this.props;
    const borderStyle = borderState === STATE.SUCCESS ? { borderColor: theme.colors.green } : {};
    const textStyle = textState === STATE.SUCCESS ? { color: theme.colors.green } : {};

    return (
      <View style={[styles.container, styles.fieldContainer, borderStyle]}>
        <RNTextInput
          style={[styles.fieldText, textStyle]}
          underlineColorAndroid="transparent"
          {...inputProps}
          editable={false}
        />
      </View>
    );
  }
}

export default FakeInput;
