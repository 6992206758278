import { Auth0DecodedHash } from 'auth0-js';
import { loader } from 'graphql.macro';

import Client from '../../../apollo/client';
import I18n from '../../../lib/i18n';
import { resetSignUp, setSignUpToken } from '../../../redux/signUp/actions';
import { selectGuestTokenId, selectOptins } from '../../../redux/signUp/selectors';
import store from '../../../redux/store';
import navigation from '../../../services/navigation';
import { IOptinCode } from '../../../types/common';

const signUpMutation = loader('../../../queries/signUp.gql');

const hasBeginSignUpProcess = (guestTokenId?: string): boolean => !!guestTokenId;

export const handleSignUp = async (
  authResult: Auth0DecodedHash,
  onError: (error?: Error) => void
): Promise<void> => {
  const guestTokenId = selectGuestTokenId(store.getState());
  const optins = selectOptins(store.getState());

  if (hasBeginSignUpProcess(guestTokenId)) {
    try {
      await signUpWithToken({ guestTokenId, optins, token: authResult.accessToken });
    } catch (error) {
      store.dispatch(resetSignUp());
      onError(error);
    }

    return;
  }

  // when there is no optins, we need to redirect user to signUp track
  store.dispatch(setSignUpToken({ token: authResult.accessToken }));
  navigation.navigate('signUpV2');
};

export interface ISignUpParameters {
  guestTokenId?: string;
  optins?: IOptinCode[];
  token?: string;
}

export const signUpWithToken = async (params: ISignUpParameters): Promise<void> => {
  await Client.getApolloClient().mutate({
    mutation: signUpMutation,
    variables: {
      input: {
        guestTokenId: params.guestTokenId,
        language: I18n.currentLocale(),
        optins: params.optins,
        token: params.token,
      },
    },
  });
  navigation.navigate('signUpWelcome');

  return;
};

export const signUp = { handleSignUp };
