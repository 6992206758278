import CheckEmail from './CheckEmail';
import CloseHolding from './CloseHolding';
import Confirmation from './Confirmation';
import ConfirmEmailLead from './ConfirmEmailLead';
import EmailNotActivated from './EmailNotActivated';
import Geolocation from './Geolocation';
import GuestExist from './GuestExist';
import Identity from './Identity';
import NearHoldings from './NearHoldings';
import Password from './Password';
import TermsOfService from './TermsOfService';
import Welcome from './Welcome';

export default {
  CheckEmail,
  CloseHolding,
  ConfirmEmailLead,
  Confirmation,
  EmailNotActivated,
  Geolocation,
  GuestExist,
  Identity,
  NearHoldings,
  Password,
  TermsOfService,
  Welcome,
};
