import { capitalize, isEmpty, trimEnd } from "lodash";
import { ICertification, IProductTransparencyPreferences } from "../types/common";
import { IImportationTypes, IMPORTATION_TYPES } from "../constants/ImportationTypes";
import { IElement } from "./menusFormatter";

export const getMenuElementDescription = (menuElement: IElement | null, menuType: IImportationTypes) => {
    if (!menuElement) {
        return null;
    }

    const { description, products } = menuElement;

    if (!isEmpty(description)) {
        return description;
    }

    if (menuType === IMPORTATION_TYPES.WINAPRO) {
        return null;
    }

    if (!products || products.length < 2) {
        return null;
    }

    const productLabels = products.map(product => capitalize(trimEnd(product.label.toLowerCase(), '.')),);

    if (productLabels.length === 0) {
        return null;
    }

    return productLabels.join(' / ');
}

export const filterProducts = (menuElement: IElement | null, preferences?: IProductTransparencyPreferences) => {
    if (!menuElement) {
        return [];
    }

    const products = menuElement.products ?? [];
    const { showIngredients = false, showLabels = false } = preferences ?? {};

    return products.filter(({ certifications = [] }) => (
        showIngredients
        || showLabels && filterCertifications(certifications as ICertification[]).length > 0)
    );
}

export const filterLabels = (menuElement: IElement | null, preferences?: IProductTransparencyPreferences) => {
    if (!menuElement) {
        return [];
    }

    const certifications = menuElement.certifications ?? [];
    const { showLabels = false } = preferences ?? {};

    if (!showLabels) {
        return [];
    }

    return filterCertifications(certifications);
}

const filterCertifications = (certifications: ICertification[]) => {

    if (certifications.includes('INFO_NOT_DISCLOSED') || certifications.includes('INFO_INCOMPLETE')) {
        return [];
    }

    return certifications;
}

export const filterAllergens = (menuElement: IElement | null, preferences?: IProductTransparencyPreferences) => {
    if (!menuElement) {
        return [];
    }

    const { showAllergens = false } = preferences ?? {};
    const allergens = menuElement.allergens ?? [];

    if (!showAllergens) {
        return [];
    }

    return allergens.filter(allergen => allergen !== 'ALLERGEN_FREE');
}

