import { UrbanAirship, EventType } from 'urbanairship-react-native';
import { Platform } from 'react-native';
import { ApolloQueryResult } from 'apollo-client';
import get from 'lodash/get';
import Client from '../apollo/client';
import { loader } from 'graphql.macro';
import { OPTINS_CODE } from '../constants';
import { IUserInfoResult } from '../types/getUserInfo';
import { getOptinValueByCode } from '../services/optins';
import Logger from './logger';
const getUserInfo = loader('../queries/getUserInfo.gql');

export const enableAirship = () => {
  UrbanAirship.setUserNotificationsEnabled(true);
  Logger.info('Airship enabled');
};
export const disableAirship = () => {
  UrbanAirship.setUserNotificationsEnabled(false);
  Logger.info('Airship disabled');
};
export const airshipStatus = async () => {
  try {
    const status = {
      analytics: await UrbanAirship.isAnalyticsEnabled(),
      dataCollection: await UrbanAirship.isDataCollectionEnabled(),
      pushTokenRegistration: await UrbanAirship.isPushTokenRegistrationEnabled(),
      userNotifications: await UrbanAirship.isUserNotificationsEnabled(),
      userNotificationOptedIn: await UrbanAirship.isUserNotificationsOptedIn(),
      ...Platform.select({
        ios: {
          autoBadge: await UrbanAirship.isAutobadgeEnabled(),
        },
        default: {
          autoBadge: 'incompatible',
        },
      }),
      channelId: (await getChannelId()) || 'not available',
    };
  } catch (error) {
    Logger.error(error);
    return false;
  }
};

export const airshipSetup = async () => {
  UrbanAirship.addListener(EventType.PushReceived, notification => {
    Logger.info('Airship notification received :', notification);
  }); // Set TagCommander unique id as current namedUser};
};

export const getChannelId = async () => {
  try {
    const channelId = UrbanAirship.getChannelId(); // debug('Current channel Id', channelId)
    return channelId;
  } catch (error) {
    Logger.error(error);
    return false;
  }
};
export const getAirshipTag = async () => {
  try {
    const tags = await UrbanAirship.getTags();
    return tags;
  } catch (error) {
    Logger.error(error);
    return false;
  }
};
export const airshipDeepLink = (navigation: any) => {
  UrbanAirship.addListener(EventType.DeepLink, event => {
    const deeplink = event.deepLink.replace('app://', '');
    navigation.navigate(deeplink);
  });
};

export const setAttributesToUa = () => {
  Client.getApolloClient()
    .query<IUserInfoResult>({
      fetchPolicy: 'network-only',
      query: getUserInfo,
    })
    .then(async (result: ApolloQueryResult<IUserInfoResult>) => {
      if (result.errors) {
        console.log('Error getting information user');
        return;
      }
      const firstName = get(result, 'data.getUser.firstName');
      const email = get(result, 'data.getUser.email');
      const lastName = get(result, 'data.getUser.lastName');
      const guestId = get(result, 'data.getUser.guests.edges[0].node.id');
      const holdingId = get(result, 'data.getUser.guests.edges[0].node.holding.id');
      const holdingName = get(result, 'data.getUser.guests.edges[0].node.holding.name');
      const userOptins = get(result, 'data.getUser.userOptins');
      const virtualTicket = get(result, 'data.getUser.guests.edges[0].node.virtualTicket');

      const marketingOptin = getOptinValueByCode(userOptins, OPTINS_CODE.FOODI_MARKETING)
        ? '1'
        : '0';
      const lowBalanceEmailOptin = getOptinValueByCode(userOptins, OPTINS_CODE.MAIL_LOW_BALANCE)
        ? '1'
        : '0';

      const lowBalancePushOptin = getOptinValueByCode(userOptins, OPTINS_CODE.PUSH_LOW_BALANCE)
        ? '1'
        : '0';
      const marketingEmailOptin = getOptinValueByCode(userOptins, OPTINS_CODE.MAIL_FOODI_MARKETING)
        ? '1'
        : '0';
      const marketingPushOptin = getOptinValueByCode(userOptins, OPTINS_CODE.PUSH_FOODI_MARKETING)
        ? '1'
        : '0';

      await UrbanAirship.setNamedUser(get(result, 'data.getUser.id'));

      UrbanAirship.editChannelAttributes()
        .setAttribute('email', email)
        .setAttribute('first_name', firstName)
        .setAttribute('last_name', lastName)
        .setAttribute('guest_id', guestId)
        .setAttribute('holding_id', holdingId)
        .setAttribute('holding_name', holdingName)
        .setAttribute('marketing_optin', marketingOptin)
        .setAttribute('low_balance_email_optin', lowBalanceEmailOptin)
        .setAttribute('low_balance_push_optin', lowBalancePushOptin)
        .setAttribute('marketing_email_optin', marketingEmailOptin)
        .setAttribute('marketing_push_optin', marketingPushOptin)
        .setAttribute('virtual_ticket', virtualTicket ? '1' : '0')
        .apply();
    });
};
