import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import I18n from '../../../../lib/i18n';
import theme from '../../../../theme';
import {
  getOrder2_order_Order as IOrder
} from '../../../../types/clickandcollect/getOrder2';
import { IAmount } from '../../../../types/common';
import { Currency, PaymentMethod } from '../../../../types/clickandcollect/globalTypes';
import ValorizationWarning from '../../../CartSummaryContent/ValorizationWarning/ValorizationWarning.component';
import Separator from '../../../Separator';
import OrderSummaryItem from '../../OrderSummaryItem';

export interface IProps {
  order: IOrder;
  isCreditCard: boolean;
  isSchool: boolean;
  showQuotationInfo: () => void;
}

class OrderSummaryQuotation extends PureComponent<IProps> {
  public render(): ReactNode {
    const { order, isCreditCard, isSchool, showQuotationInfo } = this.props;

    const noSubsidies = {
      __typename: 'Amount',
      amount: '-',
      currency: Currency.EUR,
    };

    const paymentMethod = get(order, 'paymentMethod') as PaymentMethod;
    const admission = get(order, 'admission') as IAmount;
    const getSubsidies = get(order, 'subsidies') as IAmount; 
    const fee = get(order, 'fee') as IAmount;
    const subsidies =
      isCreditCard || paymentMethod === PaymentMethod.ON_SITE ? noSubsidies : getSubsidies;

    const handleShowQuotationInfo = () => {
      showQuotationInfo();
    };

    if (paymentMethod === PaymentMethod.ON_SITE && isSchool)
      return (
        <View>
          <OrderSummaryItem
              label={I18n.t('dashboard.eat.clickAndCollect.cartSummary.deduction')}
              amount={noSubsidies}
            />

          <TouchableOpacity onPress={handleShowQuotationInfo}>
            <View style={styles.sectionContent}>
              <Text style={styles.seeMoreText}>
                {I18n.t('dashboard.eat.clickAndCollect.cartSummary.seeMore')}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );


    return (
        admission ? (
          <View>
            <OrderSummaryItem
              label={I18n.t('dashboard.eat.clickAndCollect.cartSummary.admission')}
              amount={admission}
            />
            <OrderSummaryItem
              label={I18n.t('dashboard.eat.clickAndCollect.cartSummary.subsidies')}
              amount={subsidies ? subsidies : noSubsidies}
            />
            {fee && paymentMethod !== PaymentMethod.BADGE && (
              <OrderSummaryItem
                label={I18n.t('dashboard.eat.clickAndCollect.cartSummary.fee')}
                amount={fee}
              />
            )}

            <TouchableOpacity onPress={handleShowQuotationInfo}>
              <View style={styles.sectionContent}>
                <Text style={styles.seeMoreText}>
                  {I18n.t('dashboard.eat.clickAndCollect.cartSummary.seeMore')}
                </Text>
              </View>
            </TouchableOpacity>

            <View style={styles.separator}>
              <Separator />
            </View>
          </View>
        ) : (
          <ValorizationWarning />
        )
    );
  }
}

export interface IStyle {
  separator: ViewStyle;
  seeMoreText: TextStyle;
  sectionContent: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  separator: {
    marginVertical: theme.margins.cardUnit * 2,
  },
  seeMoreText: {
    ...theme.fonts.boldText,
    color: theme.colors.blue,
    textDecorationLine: 'underline',
  },
  sectionContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.margins.cardUnit,
    marginBottom: 10,
  },
});

export default OrderSummaryQuotation;
