import React, { PureComponent, ReactNode } from 'react';
import { NavigationInjectedProps } from 'react-navigation';

import Button from '../../../../src/components/Button';
import FakeInput, { STATE } from '../../../../src/components/FakeInput';
import Paragraph from '../../../../src/components/Paragraph';
import I18n from '../../../../src/lib/i18n';
import Auth from '../../../../src/services/authentication';
import { getUserSignUpParams } from '../../../../src/services/navigation/params';

import SignupLayout from '../../components/SignupLayout';

const styles = {
  mainContent: {
    marginTop: '130px',
  },
};

class SignupExistingGuestPage extends PureComponent<NavigationInjectedProps> {
  public render(): ReactNode {
    return (
      <SignupLayout
        title={I18n.t('signUp.guestExist.title')}
        subtitle={I18n.t('signUp.guestExist.subTitle')}
        currentStepNumber={2}
        currentStepStatus="ERROR"
      >
        <div style={styles.mainContent}>
          <Paragraph text={I18n.t('signUp.guestExist.paragraph')} />
          <FakeInput
            borderState={STATE.SUCCESS}
            textState={STATE.SUCCESS}
            value={this.props.navigation.getParam('guestEmail')}
          />
          <Button
            text={I18n.t('signUp.guestExist.validate')}
            onPress={this.handleAuth0Authentication}
          />
        </div>
      </SignupLayout>
    );
  }

  private handleAuth0Authentication = (): void => {
    const { email } = getUserSignUpParams(this.props.navigation);
    Auth.login({ email });
  };
}

export default SignupExistingGuestPage;
