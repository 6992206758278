import moment, { Moment } from 'moment';
import { ImageSourcePropType } from 'react-native';

import theme from '../../../../../theme';

export type IHomeMessageConfigKey = 'morning' | 'afternoon';

export interface IHomeMessageConfig {
  image: ImageSourcePropType;
  message: string;
}

const HOME_MESSAGE_CONFIG: { [key in IHomeMessageConfigKey]: IHomeMessageConfig } = {
  afternoon: {
    image: theme.images.homeAfternoon,
    message: 'dashboard.home.message.afternoon',
  },
  morning: {
    image: theme.images.homeLunch,
    message: 'dashboard.home.message.morning',
  },
};

const MIN_MORNING_HOUR = '00:00:00';
const MAX_MORNING_HOUR = '12:59:59';

export const getHomeMessageConfig = (): IHomeMessageConfig => {
  const now: Moment = moment();
  const minMorning: Moment = moment(MIN_MORNING_HOUR, 'hh:mm:ss');
  const maxMorning: Moment = moment(MAX_MORNING_HOUR, 'hh:mm:ss');

  if (now.isBetween(minMorning, maxMorning, 'hours', '[]')) {
    return HOME_MESSAGE_CONFIG.morning;
  }

  return HOME_MESSAGE_CONFIG.afternoon;
};
