import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';

export default class ValorizationWarning extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <View style={styles.warningBox}>
        <View style={styles.warningBoxIcon}>
          <Icon name="info-circle" size={22} />
        </View>
        <Text style={styles.warningBoxText}>
          {I18n.t('dashboard.eat.clickAndCollect.cartSummary.admissionWarning')}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  warningBox: {
    flexDirection: 'row',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E8E8E8',
    padding: 1.5 * theme.margins.unit,
    marginBottom: theme.margins.unit * 2,
  },
  warningBoxIcon: {
    paddingRight: theme.margins.unit * 1.5,
  },
  warningBoxText: {
    ...theme.fonts.regularText,
    fontSize: 12,
    color: theme.colors.textGray,
    flex: 1,
  },
});
