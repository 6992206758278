import { StyleSheet } from 'react-native';
import theme from '../../../../../theme';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.background,
    padding: 0,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableInput: {
    fontSize: 42,
    fontFamily: theme.fonts.notificationTitle.fontFamily,
    width: 135,
    height: 135,
    backgroundColor: theme.colors.backgroundTabArticles,
    borderWidth: 2,
    borderColor: theme.colors.blue,
    borderRadius: 7,
    color: theme.colors.textBlack,
    textAlign: 'center',
    margin: 'auto',
    position: 'relative',
  },
  inputDone: {
    borderColor: theme.colors.black,
    ...theme.fonts.tableNumber,
    paddingTop: 24,
  },
  caption: {
    marginTop: 10,
    fontFamily: theme.fonts.button.fontFamily,
  },
  captionEdit: {
    color: theme.colors.blue,
  },
  error: {
    color: theme.colors.error,
  },
  buttonStyle: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  checked: {
    backgroundColor: theme.colors.validate,
    width: 24,
    height: 24,
    fontSize: theme.fonts.smallAmount.fontSize,
    borderRadius: 4,
    textAlign: 'center',
    lineHeight: 24,
    overflow: 'hidden',
    position: 'absolute',
    right: -6,
    top: -6,
  },
  table: {
    position: 'absolute',
    width: 135,
    textAlign: 'center',
    fontSize: theme.fonts.title.fontSize,
    top: 0,
    marginTop: 18,
  },
});
