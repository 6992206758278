import { FormikProps } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import I18n from '../../../lib/i18n';
import Button from '../../Button';
import FormFormik from '../../FormFormik';
import Optins from '../../Optins';

export interface IValues {
  checkboxNewsletter: boolean;
  checkboxTos: boolean;
}

export type IProps = FormikProps<IValues> & NavigationInjectedProps;

const EMAIL_BLOCK_HEIGHT = 112;

class SignUpOptinsFormikForm extends PureComponent<IProps, {}> {
  public render(): ReactNode {
    const { isSubmitting, setFieldValue, submitForm, values } = this.props;

    return (
      <FormFormik>
        <View style={styles.container}>
          <Optins onPressLink={this.onPressLink} values={values} setFieldValue={setFieldValue} />
          <Button
            disabled={!this.canSubmit(values)}
            text={I18n.t('signUp.email.validate')}
            isLoading={isSubmitting}
            onPress={submitForm}
          />
        </View>
      </FormFormik>
    );
  }

  private canSubmit = (values: IValues): boolean => {
    return !!values.checkboxTos;
  };

  private onPressLink = (): void => {
    this.props.navigation.navigate('modalTermsOfService');
  };
}

export interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    marginTop: EMAIL_BLOCK_HEIGHT,
  },
});

export default SignUpOptinsFormikForm;
