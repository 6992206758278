import { ApolloQueryResult, NetworkStatus } from 'apollo-client';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { OperationVariables } from 'react-apollo';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { POLL_INTERVAL } from '../../apollo/constants';
import { TRANSACTIONS_PAGE_SIZE } from '../../components/TransactionsCard/TransactionsCard.container';
import Logger from '../../lib/logger';
import { selectQueryDateTime } from '../../redux/queries/selectors';
import { IAppState } from '../../redux/reducer';
import { IAmount } from '../../types/common';
import { IUserResult } from '../../types/getBalance';
import { IPollable } from '../../types/pollable';

const getBalance = loader('../../queries/getBalance.gql');

interface IComponentProps {
  isLoading?: boolean;
}

export interface IMapStateToProps {
  balanceDateTime?: string;
}

const withBalanceDetailsProps = (state: IAppState): IMapStateToProps => {
  const getBalanceDate = selectQueryDateTime(state, 'getBalance');
  const getGuestForBalanceContentDate = selectQueryDateTime(state, 'getGuestForBalanceContent');

  let latestDate = getBalanceDate || getGuestForBalanceContentDate;
  if (getBalanceDate && getGuestForBalanceContentDate) {
    latestDate = moment(getBalanceDate).isAfter(moment(getGuestForBalanceContentDate))
      ? getBalanceDate
      : getGuestForBalanceContentDate;
  }

  return {
    balanceDateTime: latestDate,
  };
};

export interface IGraphQLProps {
  balance?: IAmount;
  isBalanceLoading: boolean;
  refetch?: (variables?: OperationVariables) => Promise<ApolloQueryResult<IUserResult>>;
  networkStatus?: NetworkStatus;
}

interface IWithIsLoading {
  isLoading: boolean;
}

const withIsLoading = (props: IGraphQLProps & IComponentProps): IWithIsLoading => ({
  isLoading: props.isLoading || props.isBalanceLoading,
});

type IComposeProps = IGraphQLProps & IMapStateToProps;
type IHocProps<T> = Omit<T, keyof IComposeProps>;

const withBalanceDetails = <T extends IComponentProps & IPollable>(
  Component: React.ComponentType<T>
): React.ComponentType<IHocProps<T>> => {
  class PrivateComponent extends PureComponent<T & IComposeProps> {
    public componentDidUpdate(prevProps: T & IComposeProps): void {
      if (this.props.balance && this.props.balanceDateTime !== prevProps.balanceDateTime) {
        this.onRefresh();
      }
    }

    public render(): ReactNode {
      return <Component {...this.props} />;
    }

    private onRefresh = async (): Promise<void> => {
      if (!this.props.refetch) {
        return;
      }

      try {
        await this.props.refetch();
      } catch (error) {
        Logger.error(error);
      }
    };
  }

  return compose<T & IComposeProps, IHocProps<T>>(
    connect(withBalanceDetailsProps),
    graphql(getBalance, {
      options: (props: IMapStateToProps & IPollable): QueryOpts => ({
        pollInterval: props.isPollable ? POLL_INTERVAL : undefined,
        variables: {
          first: TRANSACTIONS_PAGE_SIZE,
        },
      }),
      props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
        balance: get(props, 'data.getUser.guests.edges[0].node.availableBalance'),
        isBalanceLoading: props.data!.loading,
        refetch: props.data!.refetch,
        networkStatus: get(props, 'data.networkStatus'),
      }),
    }),
    withProps(withIsLoading)
  )(PrivateComponent);
};

export default withBalanceDetails;
