import React, { PureComponent, ReactNode } from 'react';
import {
  ImageURISource,
  PixelRatio,
  RegisteredStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import I18n from '../../lib/i18n';

import { isWeb } from '../../lib/responsive';

import { getCloudimageUrl } from '../../services/cloudimage';
import theme from '../../theme';
import ProgressiveImage from '../ProgressiveImage';
import Touchable from '../Touchable';

const BODY_CARD_THUMB_HEIGHT = 15;

export interface IProps {
  description?: string;
  disabled?: boolean;
  footerRenderer?: ReactNode;
  plusInfo?: ReactNode;
  highlightedSubtitle?: string;
  image?: string | null;
  onPress?: () => void;
  sizeDivider: number;
  stock?: number | null;
  title: string;
  unavailableMessage?: string;
  key?: string;
  testID?: string;
}

interface IStockStyle {
  stock: TextStyle;
}

type IDefaultProps = Required<Pick<IProps, 'stock'>>;

class CoverCard extends PureComponent<IProps> {
  public static defaultProps: IDefaultProps = {
    stock: null,
  };

  public static getStockStyle(stock: number): { stock: RegisteredStyle<TextStyle> } {
    return StyleSheet.create<IStockStyle>({
      stock: {
        ...theme.fonts.regularText,
        color: stock > 0 ? theme.colors.validate : theme.colors.errorDanger,
      },
    });
  }

  public render(): ReactNode {
    const {
      description,
      disabled,
      footerRenderer,
      plusInfo,
      image,
      title,
      highlightedSubtitle,
      onPress,
      stock,
      unavailableMessage,
    } = this.props;
    const sizeDivider = this.props.sizeDivider ? this.props.sizeDivider : 1;
    const multiplier = isWeb() ? 2 : 1;

    const thumbnailSource: ImageURISource = {};
    const imageSource: ImageURISource = {};
    if (image) {
      thumbnailSource.uri = getCloudimageUrl(
        image,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_THUMB_HEIGHT * multiplier))
      );
      imageSource.uri = getCloudimageUrl(
        image,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_HEIGHT * multiplier))
      );
    }

    return (
      <Touchable
        style={styles.content}
        disabled={disabled || !!unavailableMessage}
        onPress={onPress}
        testID={this.props.testID}
      >
        {image && (
          <ProgressiveImage
            source={imageSource}
            thumbnailSource={thumbnailSource}
            style={styles.imageContainer}
          >
            {!!unavailableMessage && (
              <View style={styles.opacityFilter}>
                <Text style={styles.unavailableMessage} numberOfLines={4}>
                  {unavailableMessage}
                </Text>
              </View>
            )}
          </ProgressiveImage>
        )}
        {/*this.renderCenteredMessage('1 portion ajoutée', 'quantity')*/}
        {sizeDivider > 1 &&
          typeof stock === 'number' &&
          stock < 1 &&
          this.renderCenteredMessage(I18n.t('dashboard.eat.clickAndCollect.outOfStock'))}
        <View
          style={[
            styles.textContainer,
            (!footerRenderer || !!unavailableMessage) && styles.withoutFooter,
          ]}
        >
          <Text style={styles.title} numberOfLines={1}>
            {title}
          </Text>
          {sizeDivider < 2 && typeof stock === 'number' && (
            <Text style={CoverCard.getStockStyle(stock).stock}>
              {stock > 0
                ? I18n.t('dashboard.eat.clickAndCollect.stock', { stock })
                : I18n.t('dashboard.eat.clickAndCollect.outOfStock')}
            </Text>
          )}
          {!!highlightedSubtitle && (
            <Text style={styles.highlightedSubtitle}>{highlightedSubtitle}</Text>
          )}
          {!!description && (
            <Text style={styles.subtitle} numberOfLines={2}>
              {description}
            </Text>
          )}
        </View>
        <View style={styles.footerView}>
          {plusInfo && <View>{plusInfo}</View>}
          {!unavailableMessage && <View>{footerRenderer}</View>}
        </View>
      </Touchable>
    );
  }

  private renderCenteredMessage = (msg: string): ReactNode => {
    return (
      <View style={styles.centeredMessageView}>
        <View style={styles.centeredMessageContent}>
          <Text style={styles.centeredMessageText}>{msg}</Text>
        </View>
      </View>
    );
  };
}

export interface IStyle {
  centeredMessageContent: ViewStyle;
  centeredMessageText: TextStyle;
  centeredMessageView: ViewStyle;
  content: ViewStyle;
  footerView: ViewStyle;
  highlightedSubtitle: TextStyle;
  imageContainer: ViewStyle;
  opacityFilter: ViewStyle;
  subtitle: TextStyle;
  textContainer: ViewStyle;
  title: TextStyle;
  unavailableMessage: TextStyle;
  withoutFooter: ViewStyle;
}

const BODY_CARD_HEIGHT = 158;
const BORDER_RADIUS = 4;
const TEXT_RADIUS = 10;
const shadowOffset = {
  height: 2,
  width: 0,
};
const OPACITY = 'B3';

const styles = StyleSheet.create<IStyle>({
  centeredMessageContent: {
    alignItems: 'center',
    backgroundColor: '#FFC73F',
    borderRadius: TEXT_RADIUS,
    justifyContent: 'center',
  },
  centeredMessageText: {
    ...theme.fonts.quantityText,
    backgroundColor: '#ffffff00',
    color: 'white',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  centeredMessageView: {
    alignItems: 'center',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  content: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    elevation: 1,
    justifyContent: 'flex-end',
    overflow: 'hidden',
    shadowColor: theme.colors.black,
    shadowOffset,
    shadowOpacity: 0.05,
    shadowRadius: 10,
  },
  footerView: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  highlightedSubtitle: {
    ...theme.fonts.regularText,
    color: theme.colors.validate,
    marginBottom: 2 * theme.margins.cardUnit,
  },
  imageContainer: {
    borderTopEndRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    height: BODY_CARD_HEIGHT,
    overflow: 'hidden',
    width: '100%',
  },
  opacityFilter: {
    alignItems: 'center',
    backgroundColor: `${theme.colors.black}${OPACITY}`,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  subtitle: {
    ...theme.fonts.regularText,
    color: theme.colors.textGray,
  },
  textContainer: {
    paddingHorizontal: 2 * theme.margins.cardUnit,
    paddingTop: 2 * theme.margins.cardUnit,
  },
  title: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.deprecatedBlack,
    marginBottom: theme.margins.cardUnit,
  },
  unavailableMessage: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.white,
    paddingHorizontal: theme.margins.cardUnit * 4,
    textAlign: 'center',
  },
  withoutFooter: {
    paddingBottom: 2 * theme.margins.cardUnit,
  },
});

export default CoverCard;
