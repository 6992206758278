import React, { ReactElement, useState } from 'react';
import {
  ImageURISource,
  Linking,
  PixelRatio,
  StyleSheet,
  View,
  ViewStyle,
  Text,
  TextStyle,
  Dimensions,
  Platform,
  ActivityIndicator
} from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import Icon from 'react-native-vector-icons/FontAwesome';
import moment from 'moment';
import theme from '../../../../../theme';
import AppPage from '../../../../../components/AppPage';
import { isWeb } from '../../../../../lib/responsive';
import getCloudimageUrl from '../../../../../../src/services/cloudimage';
import ProgressiveImage from '../../../../../../src/components/ProgressiveImage';
import {
  ClickAndCollectPriceFooter,
  ClickAndCollectType,
} from '../../../../../../src/components/QuantityFooter';
import I18n from '../../../../../lib/i18n';
import { OfferTemplateSalesType } from '../../../../../types/clickandcollect/globalTypes';
import { IOfferTemplate } from '../../../../../types/common';
import WebView from 'react-native-webview';
import { isOrderAvailable } from '../../../../../services/offer';
import { createHtmlForWebview } from '../../../../../services/webviewServices';

const BODY_CARD_THUMB_HEIGHT = 15;
const BODY_CARD_HEIGHT = 158;
const BORDER_RADIUS = 4;

export interface IProps extends NavigationInjectedProps {
  offerTemplate: IOfferTemplate;
  fullDescription: string;
  onOrderPress: () => void
}

const PlusInfo =({ offerTemplate, fullDescription = '', onOrderPress }: IProps): ReactElement => {
    const [isWebViewLoading, setIsWebViewLoading] = useState(true);

    const offerAvailable = isOrderAvailable(offerTemplate);
    const withdrawRange = offerTemplate.nextOrderableOffer?.orderRange;
    const priceTag = offerTemplate.bundle ? offerTemplate.bundle.price : null;
    const offerId = offerAvailable ? offerTemplate.nextOrderableOffer?.id : undefined;
    const salesType = offerTemplate.salesType;
    const image = offerTemplate.image && offerTemplate.image.path;

    const [withdrawStart, withdrawEnd] = withdrawRange ? withdrawRange.split('/') : '';
    const momentDateStart = moment(withdrawStart);
    const momentDateEnd = moment(withdrawEnd);
    const timeStartHours = momentDateStart.format('HH');
    const timeEndHours = momentDateEnd.format('HH');
    const timeStartMinutes = momentDateStart.format('mm');
    const timeEndMinutes = momentDateEnd.format('mm');

    const multiplier = isWeb() ? 2 : 1;
    const thumbnailSource: ImageURISource = {};
    const imageSource: ImageURISource = {};

    if (image) {
      thumbnailSource.uri = getCloudimageUrl(
        image,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_THUMB_HEIGHT * multiplier))
      );
      imageSource.uri = getCloudimageUrl(
        image,
        'height',
        String(PixelRatio.getPixelSizeForLayoutSize(BODY_CARD_HEIGHT * multiplier))
      );
    }

    const contentDescription = fullDescription
      ? `${fullDescription
          .replace('top: 0; left: 0;', '')
          .replace('<figure', '<div')
          .replace('</figure>', '</div>')}`
      : '';
    const content = createHtmlForWebview(contentDescription);

    return (
      <AppPage noPadding backgroundColor={theme.colors.background}>
        {image && (
          <ProgressiveImage
            source={imageSource}
            thumbnailSource={thumbnailSource}
            style={styles.imageContainer}
          />
        )}
        <View style={styles.tagContainer}>
          <View style={{ flexDirection: 'row' }}>
            {!!priceTag && salesType === OfferTemplateSalesType.BUNDLE && (
              <View style={styles.priceTag}>
                <Text style={styles.priceText} numberOfLines={1}>
                  {priceTag?.toFixed(2).replace('.', ',')} €
                </Text>
              </View>
            )}
          </View>
          {!!withdrawRange && (
            <View style={styles.withdrawTag}>
              <Text style={styles.withdrawText} numberOfLines={1}>
                {I18n.t('dashboard.eat.clickAndCollect.withdraw', {
                  timeStartHours,
                  timeStartMinutes,
                  timeEndHours,
                  timeEndMinutes,
                })}
              </Text>
              <Icon
                name="clock-o"
                style={{ paddingLeft: 10 }}
                size={14}
                color={theme.colors.validate}
              />
            </View>
          )}
        </View>
        {isWebViewLoading && <ActivityIndicator />}
        <WebView
          androidLayerType="hardware"
          source={{ html: isWebViewLoading ? '' : `${content}` }}
          onLoadEnd={(): void => { setIsWebViewLoading(false) }}
          allowsFullscreenVideo={true}
          originWhitelist={['*']}
          scalesPageToFit={false}
          style={styles.webViewContainer}
          onShouldStartLoadWithRequest={(event): boolean => {
            if (Platform.OS === 'android' || event.navigationType === 'click') {
              Linking.openURL(event.url);
              return false;
            }
            return true;
          }}
        />
        <ClickAndCollectPriceFooter
          testID={`plusInfo_ClickAndCollectFooter_${offerTemplate.id}`}
          idOffer={offerId}
          footerType={ClickAndCollectType.CART}
          price={priceTag ? priceTag.toFixed(2) : null}
          onPress={(): void => onOrderPress()}
        />
      </AppPage>
    );
};

export interface IStyle {
  imageContainer: ViewStyle;
  webViewContainer: ViewStyle;
  tagContainer: ViewStyle;
  priceTag: ViewStyle;
  priceText: TextStyle;
  withdrawTag: ViewStyle;
  withdrawText: TextStyle;
  restaurantTag: ViewStyle;
  restaurantText: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  imageContainer: {
    borderTopEndRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    height: BODY_CARD_HEIGHT,
    overflow: 'hidden',
    width: '100%',
  },
  webViewContainer: {
    marginVertical: theme.margins.unit,
    marginHorizontal: theme.margins.unit,
    maxWidth: Dimensions.get('window').width,
  },
  tagContainer: {
    marginTop: theme.margins.unit * 2,
    marginHorizontal: theme.margins.unit * 2,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceTag: {
    paddingVertical: theme.margins.cardUnit / 2,
    paddingHorizontal: theme.margins.cardUnit,
    borderRadius: 100,
    backgroundColor: theme.colors.greenSmoothie,
  },
  priceText: {
    color: theme.colors.black,
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 0,
  },
  withdrawTag: {
    paddingVertical: 2,
    paddingHorizontal: 6,
    backgroundColor: theme.colors.white,
    flexDirection: 'row',
  },
  withdrawText: {
    color: theme.colors.grayMedium,
    fontSize: 12,
    letterSpacing: 0,
  },
  restaurantTag: {
    paddingVertical: theme.margins.cardUnit / 2,
    paddingHorizontal: theme.margins.cardUnit,
    borderRadius: 100,
    backgroundColor: theme.colors.yellowVivid,
    marginRight: theme.margins.unit,
  },
  restaurantText: {
    color: theme.colors.black,
    fontSize: 14,
    letterSpacing: 0,
  },
});

export default PlusInfo;
