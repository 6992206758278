import React, { PureComponent, ReactNode } from 'react';
import { Platform, StatusBar, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import CloseModal from '../CloseModal';
import { ITheme } from '../CloseModal/CloseModal.component';
import { CLOSE_MODAL_SIZE } from '../CloseModal/CloseModal.component';
export interface IProps {
  contentTheme?: ITheme;
  onClose: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}

// https://github.com/facebook/react-native/issues/17638
// https://github.com/facebook/react-native/pull/18534
const majorVersion = Platform.Version && parseInt(Platform.Version.toString(), 10);
const isSafeAreaViewNotWorking = Platform.OS === 'ios' && majorVersion < 11;

class ModalHeader extends PureComponent<IProps> {
  public componentDidMount(): void {
    if (isSafeAreaViewNotWorking) {
      StatusBar.setHidden(true);
    }
  }

  public render(): ReactNode {
    const { style, contentTheme, testID } = this.props;

    const containerStyle = StyleSheet.flatten([styles.container, style]);

    return (
      <View style={containerStyle} testID={testID}>
        {this.props.children}
        <View style={styles.closeButtonContainer}>
          <CloseModal
            onPress={this.onClose}
            contentTheme={contentTheme}
            testID={`${testID}_closeModal`}
          />
        </View>
      </View>
    );
  }

  private onClose = (): void => {
    this.props.onClose();

    if (isSafeAreaViewNotWorking) {
      StatusBar.setHidden(false);
    }
  };
}

interface IStyle {
  closeButtonContainer: ViewStyle;
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  closeButtonContainer: {
    bottom: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: CLOSE_MODAL_SIZE,
    paddingRight: CLOSE_MODAL_SIZE,
  },
});

export default ModalHeader;
