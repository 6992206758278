import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import withNavigation from '../../../../../hoc/withNavigation';
import TableNumber, { IProps } from './TableNumber.component';

interface IWithOffer {
  offerId: string;
  skipTableGroupValidation: boolean;
  withCSITableValidation: boolean;
  onNextNavigateTo?: ({ tableNumber }: { tableNumber: string }) => void;
}

const withOfferId = (ownProps: NavigationInjectedProps): IWithOffer => ({
  offerId: ownProps.navigation.getParam('offerId'),
  skipTableGroupValidation: ownProps.navigation.getParam('skipTableGroupValidation') || false,
  withCSITableValidation: ownProps.navigation.getParam('withCSITableValidation') || false,
  onNextNavigateTo: ownProps.navigation.getParam('onNextNavigateTo'),
});

export default compose<IProps, {}>(withNavigation, withProps(withOfferId))(TableNumber);
