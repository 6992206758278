import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ActionCreator } from 'redux';

import withNavigation from '../../hoc/withNavigation';
import { IAppState } from '../../redux/reducer';
import { ISetOptinsAction, setOptins } from '../../redux/signUp/actions';
import { selectGuestTokenId, selectSignUpToken } from '../../redux/signUp/selectors';

import SignUpOptinsForm, { IProps } from './SignUpOptinsForm.component';

export interface IMapStateToProps {
  guestTokenId?: string;
  signupToken?: string;
}

interface IDispatchProps {
  setOptins: ActionCreator<ISetOptinsAction>;
}

const mapDispatchToProps: IDispatchProps = {
  setOptins,
};

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  guestTokenId: selectGuestTokenId(state),
  signupToken: selectSignUpToken(state),
});

export type IConnectedProps = NavigationInjectedProps & IDispatchProps & WithApolloClient<{}>;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withNavigation,
  withApollo
)(SignUpOptinsForm);
