import React, { PureComponent, ReactNode } from 'react';
import { Image } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import Button from '../../../../src/components/Button';
import Paragraph from '../../../../src/components/Paragraph';
import SubTitle from '../../../../src/components/SubTitle';
import I18n from '../../../../src/lib/i18n';
import Auth from '../../../../src/services/authentication';
import theme from '../../../../src/theme';
import { getUserSignUpParams } from '../../../../src/services/navigation/params';

import SignupLayout from '../../components/SignupLayout';

const styles = {
  mainContent: {
    marginTop: '83px',
  },
  thumbIconStyle: {
    height: 147,
    margin: 'auto',
    marginBottom: '81px',
    width: 200,
  },

  // tslint:disable-next-line:no-any
} as any;

class AccountActivatedPage extends PureComponent<NavigationInjectedProps> {
  public render(): ReactNode {
    return (
      <form>
        <SignupLayout
          title={I18n.t('signUp.headerTitle')}
          subtitle={I18n.t('signUp.welcome.title')}
          currentStepNumber={4}
          currentStepStatus="DONE"
        >
          <div style={styles.mainContent}>
            <Image source={theme.images.thumb} style={styles.thumbIconStyle} />
            <SubTitle text={I18n.t('signUp.welcome.subTitle')} />
            <Paragraph text={I18n.t('signUp.welcome.paragraph')} />
            <Button
              text={I18n.t('signUp.welcome.signIn')}
              onPress={this.handleAuth0Authentication}
            />
          </div>
        </SignupLayout>
      </form>
    );
  }

  private handleAuth0Authentication = (): void => {
    const { email } = getUserSignUpParams(this.props.navigation);
    Auth.login({ email });
  };
}

export default AccountActivatedPage;
