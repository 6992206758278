import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { compose, withProps } from 'recompose';

import { getConfig } from '../../../environment';

import ModalContest, { IProps } from './ModalContest.component';

const getContestToken = loader('../../../queries/getContestToken.gql');

const env = getConfig();

interface IWithUrlProps {
  url: string;
}

const withUrlProps = (ownProps: IContainerProps & IConnectedProps): IWithUrlProps => ({
  url: `${env.CONTEST.BASE_URL}?token=${ownProps.contestToken}`,
});

export interface IGraphQLProps {
  contestToken: string;
  hasError: boolean;
  isLoading: boolean;
}

export type IConnectedProps = IWithUrlProps & IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  graphql(getContestToken, {
    options: (): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        namespace: env.CONTEST.TOKEN_NAMESPACE,
      },
    }),
    props: (props: OptionProps<{}>): IGraphQLProps => {
      return {
        contestToken: get(props, 'data.getScopedToken'),
        hasError: !!get(props, 'data.error'),
        isLoading: get(props, 'data.loading'),
      };
    },
  }),
  withProps(withUrlProps)
)(ModalContest);
