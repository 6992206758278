import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle, ImageStyle } from 'react-native';

import theme from '../../../theme';
import Amount from '../../Amount';
import Icon from '../../Icon';
import { allergensMapper } from '../../../services/allergensMapper';
import { IElement } from '../../../services/menusFormatter';
import I18n from '../../../lib/i18n';
import { IAllergen, IHolding } from '../../../types/common';
import { capitalize } from 'lodash';
import { IImportationTypes } from "../../../constants/ImportationTypes";
import { getMenuElementDescription } from '../../../services/menus';

export interface IProps {
  element: IElement;
  holding?: IHolding;
}

class MenuElement extends PureComponent<IProps> {
  renderAllergens = (allergens: IAllergen[]) =>
    <View style={styles.infoAllergens}>
      {allergens.filter(allergen => allergen !== 'ALLERGEN_FREE').map(allergen => (
        <View style={styles.allergens}>
          <Icon name={allergensMapper[allergen]} />
          <Text style={styles.allergenText}>
            {I18n.t(
              `dashboard.menuElementDetails.informationSection.allergens.${allergensMapper[allergen]}`
            )}
          </Text>
        </View>
      ))}
    </View>;

  public render(): ReactNode {
    const { element, holding } = this.props;
    const {
      showPrice = false,
      showDescription = false,
    } = holding?.settings?.productTransparencyPreferences ?? {};
    const menuType = holding?.importationType as IImportationTypes;
    const description = getMenuElementDescription(element, menuType);

    return (
      <View style={styles.container}>
        <View style={styles.left}>
          <Text style={styles.title} numberOfLines={2}>
            {capitalize(element.label)}
          </Text>
          {showDescription && description !== null && (
            <Text style={styles.description} numberOfLines={2}>
              {description}
            </Text>
          )}
          {showPrice && !!element.price?.amount && <Amount amount={element.price} style={styles.amount} />}
          <View></View>
        </View>
      </View>
    );
  }
}

interface IStyle {
  amount: TextStyle;
  container: ViewStyle;
  description: TextStyle;
  image: ImageStyle;
  left: ViewStyle;
  right: ViewStyle;
  title: TextStyle;
  infoCertifications: ViewStyle;
  infoAllergens: ViewStyle;
  tagLabel: ViewStyle;
  allergens: ViewStyle;
  allergenText: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    ...theme.fonts.regularText,
    marginTop: theme.margins.cardUnit,
  },
  container: {
    flexDirection: 'row',
    paddingVertical: theme.margins.padding,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.darkGrey,
    justifyContent: 'space-between',
  },
  description: {
    ...theme.fonts.label,
    color: theme.colors.grayMedium,
    marginTop: theme.margins.cardUnit,
    marginBottom: theme.margins.cardUnit,
  },
  image: {
    borderRadius: 10,
    height: 80,
    width: '100%',
    color: theme.colors.gray3,
    fontSize: 16,
    marginTop: theme.margins.cardUnit,
    marginBottom: theme.margins.cardUnit,
  },
  left: {
    justifyContent: 'space-between',
  },
  right: {
    width: '30%',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 16,
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  infoAllergens: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: theme.margins.cardUnit,
  },
  tagLabel: {
    backgroundColor: theme.colors.grayExtraLight,
  },
  allergens: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginRight: theme.margins.cardUnit,
    marginTop: theme.margins.cardUnit,
  },
  allergenText: {
    marginLeft: 4,
  },
  infoCertifications: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default MenuElement;
