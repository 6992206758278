import Help from './Help';
import Languages from './Languages';
import MyInformations from './MyInformations';
import Notifications from './Notifications';
import PersonalData from './PersonalData';
import TermsOfService from './TermsOfService';
import UserProfile from './UserProfile';
import VirtualTicket from './VirtualTicket';
import LegalInfo from './LegalInfo';
import TermsOfSales from './TermsOfSales';
import CookiesPolicy from './CookiesPolicy';

export default {
  Help,
  Languages,
  MyInformations,
  Notifications,
  PersonalData,
  TermsOfService,
  UserProfile,
  VirtualTicket,
  LegalInfo,
  TermsOfSales,
  CookiesPolicy,
};
