import { IOptinAllergenCode, IOptinCode } from '../types/common';

export const OPTINS_CODE: { [key in IOptinCode]: key } = {
  FOODI_MARKETING: 'FOODI_MARKETING',
  FOODI_TOS: 'FOODI_TOS',
  MAIL_FOODI_MARKETING: 'MAIL_FOODI_MARKETING',
  MAIL_LOW_BALANCE: 'MAIL_LOW_BALANCE',
  PUSH_FOODI_MARKETING: 'PUSH_FOODI_MARKETING',
  PUSH_LOW_BALANCE: 'PUSH_LOW_BALANCE',
};

export const OPTINS_ALLERGEN_CODE: { [key in IOptinAllergenCode]: key } = {
  FOODI_ALLERGEN_DISCLAIMER: 'FOODI_ALLERGEN_DISCLAIMER',
};
