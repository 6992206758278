import difference from 'lodash/difference';
import union from 'lodash/union';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { ActionCreator } from 'redux';

import { ISetPosFiltersAction, setPosFilters } from '../../redux/pointsOfSale/actions';
import { getPointsOfSaleActiveFilters } from '../../redux/pointsOfSale/selectors';
import { IAppState } from '../../redux/reducer';

import { IFilter } from './FilterDropdown.component';
import FilterDropdown, { IProps } from './FilterDropdownContent.component';

export interface IMapStateToProps {
  activeFilters: string[];
}

export interface IDispatchProps {
  onClearButtonPress: ActionCreator<ISetPosFiltersAction>;
  setFilters: ActionCreator<ISetPosFiltersAction>;
}

export interface IFilters {
  filters: IFilter[];
}

type IOnFilterPress = (filterId: string) => void;

const mapStateToProps = (state: IAppState): IMapStateToProps => ({
  activeFilters: getPointsOfSaleActiveFilters(state),
});

const mapDispatchToProps: IDispatchProps = {
  onClearButtonPress: (): ISetPosFiltersAction => setPosFilters({ activeFilters: [] }),
  setFilters: setPosFilters,
};

const withOnFilterPress = (
  ownProps: IMapStateToProps & IDispatchProps & IFilters
): IOnFilterPress => (filterId: string): void => {
  const prevFilters = ownProps.activeFilters;
  const newFilters =
    prevFilters && prevFilters.includes(filterId)
      ? difference(prevFilters, [filterId])
      : union(prevFilters, [filterId]) || [];

  ownProps.setFilters({ activeFilters: newFilters });
};

export type IConnectedProps = IMapStateToProps &
  IDispatchProps & {
    onFilterPress: IOnFilterPress;
  };
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({ onFilterPress: withOnFilterPress })
)(FilterDropdown);
