import { Formik, FormikActions } from 'formik';
import React, { PureComponent, ReactNode } from 'react';
import { Keyboard } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { ActionCreator } from 'redux';

import { OPTINS_CODE } from '../../constants';
import Logger from '../../lib/logger';
import { ISetOptinsAction } from '../../redux/signUp/actions';
import Auth from '../../services/authentication';
import { VALIDATOR_ERROR_NAME } from '../../services/form/validatorError';
import { IOptinCode } from '../../types/common';

import SignUpOptinsFormikForm, { IValues } from './SignUpOptinsFormikForm';

export interface IProps extends NavigationInjectedProps {
  guestTokenId?: string;
  setOptins: ActionCreator<ISetOptinsAction>;
  signupToken?: string;
}

class SignUpOptinsForm extends PureComponent<IProps> {
  public render(): ReactNode {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        initialValues={{ checkboxTos: false, checkboxNewsletter: false }}
        component={SignUpOptinsFormikForm}
      />
    );
  }

  private handleSubmit = async (
    values: IValues,
    { setErrors, setSubmitting }: FormikActions<IValues>
  ): Promise<void> => {
    const { checkboxTos, checkboxNewsletter } = values;

    if (!checkboxTos) {
      setSubmitting(false);

      return;
    }

    try {
      const optins: IOptinCode[] = [];
      if (checkboxTos) {
        optins.push(OPTINS_CODE.FOODI_TOS);
      }
      if (checkboxNewsletter) {
        optins.push(OPTINS_CODE.FOODI_MARKETING);
      }

      Keyboard.dismiss();
      this.props.setOptins({ optins });

      Auth.signUpWithGuestToken({
        guestTokenId: this.props.guestTokenId,
        optins,
        token: this.props.signupToken,
      });
    } catch (error) {
      switch (error.name) {
        case VALIDATOR_ERROR_NAME:
          setErrors(error.toFormikError());
          break;
        default:
          Logger.error(error);
      }
    } finally {
      setSubmitting(false);
    }
  };
}

export default SignUpOptinsForm;
