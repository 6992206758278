import chunk from 'lodash/chunk';
import first from 'lodash/first';
import last from 'lodash/last';
import moment from 'moment';
import { ImageURISource } from 'react-native';

import I18n from '../lib/i18n';
import theme from '../theme';
import { IAttendance, IAttendanceElement, IChart, IPosSchedule } from '../types/common';

import { getPosStatusFromSchedules } from './schedulesFormatter';

import { POS_STATUS } from './../constants/PosStatus';

enum ServiceCount {
  SINGLE_SERVICE = 1,
  MULTIPLE_SERVICES,
  EMPTY_SERVICE,
}

interface IServiceDatetimes {
  begin: moment.Moment;
  end: moment.Moment;
}

const getServiceCountType = (schedule: IPosSchedule): ServiceCount => {
  if (schedule.hours.length === 0) {
    return ServiceCount.EMPTY_SERVICE;
  }
  if (schedule.hours.length > 2) {
    return ServiceCount.MULTIPLE_SERVICES;
  }

  return ServiceCount.SINGLE_SERVICE;
};

export const getDisplayedInterval = (
  serviceSchedule: IServiceDatetimes,
  localDate: moment.Moment
): IServiceDatetimes => {
  if (serviceSchedule.end.diff(serviceSchedule.begin, 'minutes') <= 60) {
    return {
      begin: serviceSchedule.begin,
      end: moment(serviceSchedule.begin).add(60, 'minutes'),
    };
  }
  if (serviceSchedule.end.diff(serviceSchedule.begin, 'minutes') <= 180) {
    return { ...serviceSchedule };
  }
  if (localDate.isBefore(serviceSchedule.begin)) {
    return {
      begin: serviceSchedule.begin,
      end: moment(serviceSchedule.begin).add(180, 'minutes'),
    };
  } else if (localDate.isAfter(serviceSchedule.end)) {
    return {
      begin: moment(serviceSchedule.end).subtract(180, 'minutes'),
      end: serviceSchedule.end,
    };
  }

  return {
    begin: moment.max(serviceSchedule.begin, moment(localDate).subtract(1, 'hour')),
    end: moment.min(serviceSchedule.end, moment(localDate).add(2, 'hour')),
  };
};

export const getServiceToDisplay = (
  todayPosSchedules: IPosSchedule,
  localDate: moment.Moment
): IServiceDatetimes | undefined => {
  const serviceCountType = getServiceCountType(todayPosSchedules);
  const serviceDatetimes = formatSchedulesToDatetimes(todayPosSchedules, localDate);

  switch (serviceCountType) {
    case ServiceCount.SINGLE_SERVICE:
      return first(serviceDatetimes);
    case ServiceCount.MULTIPLE_SERVICES:
      return (
        serviceDatetimes.find(
          (serviceToDisplay: IServiceDatetimes) =>
            localDate.isBetween(serviceToDisplay.begin, serviceToDisplay.end, 'minutes', '[)') ||
            localDate.isBefore(serviceToDisplay.begin)
        ) || last(serviceDatetimes)
      );
    default:
      return;
  }
};

const filterAttendanceDataByService = (
  attendance: IAttendance,
  localDate: moment.Moment,
  serviceSchedules: IServiceDatetimes | undefined
): IAttendanceElement[] => {
  if (!serviceSchedules) {
    return [];
  }

  const displayedInterval = getDisplayedInterval(serviceSchedules, localDate);

  return attendance.prediction.values.filter((d: IAttendanceElement) =>
    moment(d.time).isBetween(displayedInterval.begin, displayedInterval.end, 'minutes', '[)')
  );
};

const getLocaleTimeFromSchedule = (date: moment.Moment, hour: string): moment.Moment => {
  const HourMinuteSecond = hour.split(':').map((d: string) => parseInt(d, 10));
  const scheduleTime = moment(date);

  scheduleTime.set({
    hour: HourMinuteSecond[0],
    millisecond: 0,
    minute: HourMinuteSecond[1],
    second: HourMinuteSecond[2],
  });

  return scheduleTime;
};

const formatSchedulesToDatetimes = (
  schedule: IPosSchedule,
  localDate: moment.Moment
): IServiceDatetimes[] => {
  return chunk(schedule.hours, 2).map(
    (hours: string[]): IServiceDatetimes => {
      return {
        begin: getLocaleTimeFromSchedule(localDate, hours[0]),
        end: getLocaleTimeFromSchedule(localDate, hours[1]),
      };
    }
  );
};

const formatAttendanceDataToChartData = (
  attendanceData: IAttendanceElement[],
  minWeekCount: number,
  maxWeekCount: number,
  localDate: moment.Moment,
  intervalMinutes: number
): IChart => {
  let lastLabel = '';
  const activeIndexes = [] as number[];
  const chartLabels = [] as string[];
  const chartValues = [] as number[];
  const lastData = last(attendanceData);

  if (lastData) {
    lastLabel = moment(lastData.time)
      .add(intervalMinutes, 'minutes')
      .format('HH[h]mm');
  }
  attendanceData.forEach((prediction: { count: number; time: Date }, index: number) => {
    const predictionTime = moment(prediction.time);

    chartLabels.push(predictionTime.format('HH[h]mm'));
    chartValues.push(prediction.count);
    if (
      localDate.isBetween(
        predictionTime,
        moment(predictionTime).add(intervalMinutes, 'minutes'),
        'minutes',
        '[)'
      )
    ) {
      activeIndexes.push(index);
    }
  });

  return {
    activeIndexes,
    labels: chartLabels,
    lastLabel,
    maxValue: maxWeekCount,
    minValue: minWeekCount,
    values: chartValues,
  };
};

export const getCurrentAttendanceColoredLabel = (
  attendance: IAttendance,
  schedules: IPosSchedule[]
): { color: string; image: ImageURISource; text: string } => {
  if (getPosStatusFromSchedules(schedules) === POS_STATUS.CLOSED) {
    return {
      color: theme.colors.errorDanger,
      image: theme.images.animationRed,
      text: I18n.t('pointOfSale.status.closedNow'),
    };
  }
  if (attendance.prediction.maxWeekCount < 5) {
    return {
      color: theme.colors.validate,
      image: theme.images.animationGreen,
      text: I18n.t('pointOfSale.attendance.lowAnimation'),
    };
  }
  const ratio = (attendance.current.count / attendance.prediction.maxWeekCount) * 100;
  if (ratio < 33) {
    return {
      color: theme.colors.validate,
      image: theme.images.animationGreen,
      text: I18n.t('pointOfSale.attendance.lowAnimation'),
    };
  } else if (ratio >= 33 && ratio < 66) {
    return {
      color: theme.colors.info,
      image: theme.images.animationBlue,
      text: I18n.t('pointOfSale.attendance.mediumAnimation'),
    };
  }

  return {
    color: theme.colors.errorDanger,
    image: theme.images.animationRed,
    text: I18n.t('pointOfSale.attendance.highAnimation'),
  };
};

export const formatAttendanceForChart = (
  attendance: IAttendance,
  schedules: IPosSchedule[]
): IChart => {
  const localDate = moment();

  let todayPosSchedules = schedules.find(
    (schedule: IPosSchedule) => schedule.days.indexOf(localDate.day()) !== -1
  );

  // If no schedule today, display as if it was opened
  if (!todayPosSchedules) {
    todayPosSchedules = {
      days: [localDate.day()],
      hours: ['00:00:00', '24:00:00'],
    };
  }

  // Get service to display
  const serviceToDisplay = getServiceToDisplay(todayPosSchedules, localDate);

  // Get attendance data filtered by schedules time range
  const attendanceData = filterAttendanceDataByService(attendance, localDate, serviceToDisplay);

  return formatAttendanceDataToChartData(
    attendanceData,
    attendance.prediction.minWeekCount,
    attendance.prediction.maxWeekCount,
    localDate,
    attendance.prediction.intervalMinutes
  );
};
