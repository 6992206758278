import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle } from 'react-native';

import { IBrand } from '../../types/common';

import { FOODI_LOGO_ORIENTATION, IFoodiLogoOrientation, selectIcon } from './utils';

export interface IProps {
  brand?: IBrand;
  height?: number;
  idHolding?: string;
  orientation: IFoodiLogoOrientation;
  width?: number;
}

class FoodiLogo extends PureComponent<IProps> {
  public render(): ReactNode {
    const { brand, idHolding, orientation } = this.props;

    return <Image source={selectIcon(orientation, brand, idHolding)} style={this.getLogoStyle()} />;
  }

  private getLogoStyle = (): ImageStyle => ({
    height:
      this.props.height ||
      (this.props.orientation === FOODI_LOGO_ORIENTATION.horizontal
        ? HORIZONTAL_FOODI_LOGO_HEIGHT
        : VERTICAL_FOODI_LOGO_HEIGHT),
    width:
      this.props.width ||
      (this.props.orientation === FOODI_LOGO_ORIENTATION.horizontal
        ? HORIZONTAL_FOODI_LOGO_WIDTH
        : VERTICAL_FOODI_LOGO_WIDTH),
  });
}

export const HORIZONTAL_FOODI_LOGO_HEIGHT = 32;
export const HORIZONTAL_FOODI_LOGO_WIDTH = 128;
export const VERTICAL_FOODI_LOGO_HEIGHT = 99;
export const VERTICAL_FOODI_LOGO_WIDTH = 65;

export default FoodiLogo;
