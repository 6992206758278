import AuthLoading from './AuthLoading';
import Dashboard from './Dashboard';
import Debug from './Debug';
import Landing from './Landing';
import Loading from './Loading';
import SignIn from './SignIn';
import SignUp from './SignUp';

export default {
  AuthLoading,
  Dashboard,
  Debug,
  Landing,
  Loading,
  SignIn,
  SignUp,
};
