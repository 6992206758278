import { NavigationScreenProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../../../src/hoc/withNavigation';

import NearHoldings, { IProps } from './HoldingChoicePage.component';

export type IConnectedProps = NavigationScreenProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

export default compose<IProps, IContainerProps>(withNavigation)(NearHoldings);
