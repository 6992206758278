import React, { PureComponent, ReactNode } from 'react';
import ArticlesList, { DisplayedArticleFamily } from '../../../../../components/ArticlesList';
import {
  ClickAndCollectFooterType,
  ClickAndCollectQuantityFooter,
} from '../../../../../components/QuantityFooter';
import { WebPage } from '../../../../../componentsWeb';
import I18n from '../../../../../lib/i18n';
import { getOffer_offer_Offer as IOffer } from '../../../../../types/clickandcollect/getOffer';

export interface IProps {
  isLoading: boolean;
  offer: IOffer;
  offerId: string;
  articleFamilyKeys: DisplayedArticleFamily[];
  footerType: ClickAndCollectFooterType;
}

class OfferArticles extends PureComponent<IProps> {
  public render(): ReactNode {
    const { offer, articleFamilyKeys, isLoading, offerId, footerType } = this.props;

    return (
      <WebPage title={I18n.t('dashboard.eat.clickAndCollect.title')} canGoBack>
        <ArticlesList articleFamilyKeys={articleFamilyKeys} offer={offer} isLoading={isLoading} />
        {offer && <ClickAndCollectQuantityFooter idOffer={offerId} footerType={footerType} />}
      </WebPage>
    );
  }
}

export default OfferArticles;
