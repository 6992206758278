import moment, { Moment } from 'moment';

export const NUMBER_OF_DAYS_DISPLAYED_IN_MENU = 30;

export const getDisplayedDays = (): string[] => {
  const now: Moment = moment();
  const days: string[] = [];

  for (let i = 0; i < NUMBER_OF_DAYS_DISPLAYED_IN_MENU; i++) {
    days.push(now.format('YYYY-MM-DD'));
    now.add(1, 'd');
  }

  return days;
};
