import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import { cartSummaryData_order_Order_orderItems as IOrderItem, cartSummaryData_order_Order_orderItems_containerPriceWhenAdded as IContainerPrice } from '../../../types/clickandcollect/cartSummaryData';
import Amount from '../../Amount';
import SummarySection from '../SummarySection/SummarySection.component';
import Tag from '../../Tag';

interface IComponentProps {
  idOrder: string;
  orderItem: IOrderItem;
}
const QUANTITY_LABEL_SIZE = 31;
const BORDER_RADIUS = 4;

export default class OrderItemRow extends PureComponent<IComponentProps> {
  public render(): ReactNode {
    const { idOrder, orderItem } = this.props;

    return (
      <View key={`${idOrder}-${orderItem.id}`}>
        <SummarySection
          showTitle={false}
          title={I18n.t('dashboard.eat.clickAndCollect.cartSummary.items')}
          isLoading={false}
          style={styles.sectionContainer}
        >
          <View style={styles.counterContainer}>
            <Text style={styles.title}>{`${orderItem.quantity}`}</Text>
          </View>
          <View style={styles.tagWrapper}>
            <View style={styles.row}>
              <View style={{flexDirection: 'column', flex: 0.8}}>
                <Text numberOfLines={2} style={styles.label}>
                  {orderItem.labelWhenAdded}
                </Text>
                {orderItem.chosenBaking && (
                  <Tag
                    label={I18n.t(`dashboard.eat.tableService.ArticleBaking.${orderItem.chosenBaking}`)}
                    tagStyle={{ marginLeft: 0 }}
                    style={styles.bakingTag}
                  />
                )}
              </View>
              <Amount amount={orderItem.totalPriceWhenAdded} style={[styles.amount, { flex: 0.2 }]} />
            </View> 
            <View style={styles.row}>
              <View style={{flexDirection: 'column', flex: 0.8}}>
                {orderItem.containerLabelWhenAdded && (
                  <Text numberOfLines={2} style={styles.label}>
                    {I18n.t('dashboard.eat.clickAndCollect.cartSummary.container')}* {orderItem.containerLabelWhenAdded}
                  </Text>
                )}
              </View>
              {orderItem.containerPriceWhenAdded && (
                <Amount
                  amount={
                    {
                      currency: orderItem.containerPriceWhenAdded.currency,
                      amount: orderItem.containerPriceWhenAdded.amount * orderItem.quantity,
                    } as IContainerPrice
                  }
                  style={[styles.amount, { flex: 0.2 }]}
                />
              )}
              </View>
          </View>
        </SummarySection>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  bakingTag: {
    marginLeft: 16,
    height: 30,
    borderRadius: 4,
    backgroundColor: theme.colors.backgroundGray,
  },
  tagWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginVertical: 5,
  },
  row: {
    flexDirection: 'row', 
    justifyContent: 'space-between',
  },
  amount: {
    marginLeft: theme.margins.cardUnit,
    ...theme.fonts.strongText,
    fontSize: 16,
    textAlign: 'right',
    marginBottom: theme.margins.cardUnit,
  },
  label: {
    ...theme.fonts.regularText,
    marginLeft: 2 * theme.margins.cardUnit,
    marginBottom: theme.margins.cardUnit,
  },
  sectionContainer: {
    marginTop: 0,
    paddingTop: 0,
  },
  title: {
    ...theme.fonts.mediumTitle,
    color: theme.colors.textWhite,
  },
  counterContainer: {
    alignItems: 'center',
    backgroundColor: theme.colors.validate,
    borderRadius: BORDER_RADIUS,
    height: QUANTITY_LABEL_SIZE,
    justifyContent: 'center',
    width: QUANTITY_LABEL_SIZE,
  },
});
