import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, View, ViewStyle } from 'react-native';

import { isDesktop } from '../../lib/responsive';

import theme from '../../theme';

class SplitPageLayout extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <View style={styles.page}>
        {isDesktop() && <Image style={styles.image} source={theme.images.landing} />}
        <View style={styles.container}>
          <View style={styles.contentContainer}>{this.props.children}</View>
        </View>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  contentContainer: ViewStyle;
  image: ImageStyle;
  page: ViewStyle;
}

const CONTENT_MAX_WIDTH = 320;

const styles: IStyle = {
  container: {
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    flex: 1,
    height: '100%',
    overflow: 'scroll',
  },
  contentContainer: {
    maxWidth: CONTENT_MAX_WIDTH,
    width: '100%',
  },
  image: {
    height: '100%',
    width: '50%',
  },
  page: {
    flexDirection: 'row',
    height: '100vh',
  },
};

export default SplitPageLayout;
