import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps, QueryOpts } from 'react-apollo/types';
import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import withNavigation from '../../hoc/withNavigation/withNavigation';
import {
  checkIncompleteTableNumber,
  checkTableNumber,
  getTables,
  tablesToKey,
} from '../../services/tableNumber';
import { getTableServiceTables_tables_Offer_offerTemplate_TableServiceOfferTemplate_zone_tables as TableGroup } from '../../types/tableService/getTableServiceTables';
import { getCSITableStatus_checkTablesAvailability_tables as CSITable } from '../../types/clickandcollect/getCSITableStatus';
import TableNumber, { IProps } from './TableNumber.component';

interface IWithOffer {
  offerId: string;
  posId: string;
}
interface IWithTableInfos {
  tableId: string;
  tableNumber: string;
  value?: string;
}
interface IWithIsValid {
  isValid: (tableNumber: string, incomplete: boolean) => boolean;
  getTableId: (tableNumber: string) => string | null;
}

interface IGraphQLProps {
  hasError?: boolean;
  isLoading: boolean;
  tables?: number[];
  tableGroup?: TableGroup[];
  csiTables?: CSITable[];
  startPolling?: (value: number) => void;
  stopPolling?: () => void;
}

type IConnectedProps = NavigationInjectedProps &
  IWithOffer &
  IWithTableInfos &
  IWithIsValid &
  IGraphQLProps;
export type IContainerProps = Omit<IProps, keyof IConnectedProps>;

const withOffer = (ownProps: NavigationInjectedProps): IWithOffer => ({
  offerId: ownProps.navigation.getParam('offerId'),
  posId: ownProps.navigation.getParam('posId'),
});

const withTableInfos = (
  ownProps: NavigationInjectedProps & IWithOffer & IGraphQLProps & IProps
): IWithTableInfos => {
  const tableId = ownProps.navigation.getParam('idTable');
  let tableNumber = ownProps.navigation.getParam('tableNumber');
  let value = tableNumber || '';
  if (!tableNumber && !!tableId && !ownProps.isLoading && !ownProps.editable) {
    const table = ownProps.tableGroup!.find((table: TableGroup) => table.id === tableId);
    value = table ? table.idHuman : '';
  }
  return {
    tableId,
    tableNumber,
    value: String(value),
  };
};

const withIsValid = (
  ownProps: IWithOffer &
    IWithTableInfos &
    IGraphQLProps & { skipTableGroupValidation?: boolean; withCSITableValidation?: boolean }
): IWithIsValid => ({
  isValid: (tableNumber: string, incomplete: boolean) => {
    if (ownProps.isLoading) return true;

    if (tableNumber && ownProps.withCSITableValidation && ownProps.csiTables) {
      const isValidNumber = /^0?[\d]{1,3}$/.test(tableNumber);
      const csiTable = ownProps.csiTables.find(
        (csiTable: CSITable) => csiTable.tableNumber === parseInt(tableNumber, 10)
      );
      return isValidNumber && !!csiTable && csiTable.status < 4;
    }

    const incompleteValid = checkIncompleteTableNumber(
      tableNumber,
      ownProps.tables!,
      ownProps.skipTableGroupValidation
    );
    const valid = checkTableNumber(
      tableNumber,
      ownProps.tables!,
      ownProps.skipTableGroupValidation
    );

    return incomplete ? incompleteValid : valid;
  },
  getTableId: (tableNumber: string) => {
    if (ownProps.isLoading) return null;

    const table = ownProps.tableGroup!.find(
      (table: TableGroup) => table.idHuman === parseInt(tableNumber, 10)
    );

    return !!table ? table.id : null;
  },
});

const getTablesQuery = loader('../../queries/tableservice/getTables.gql');

const getCSITableStatusQuery = loader('../../queries/clickandcollect/getCSITableStatus.gql');

export default compose<IProps, IContainerProps>(
  withNavigation,
  withProps(withOffer),

  graphql(getTablesQuery, {
    options: (props: IWithOffer): QueryOpts => ({
      fetchPolicy: 'no-cache',
      variables: {
        idOffer: props.offerId,
      },
    }),
    props: (props: OptionProps<IConnectedProps>): IConnectedProps | IGraphQLProps => ({
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      tables: tablesToKey(get(props, 'data.tables.offerTemplate.zone', [])),
      tableGroup: getTables(get(props, 'data.tables.offerTemplate.zone', [])),
    }),
  }),

  graphql(getCSITableStatusQuery, {
    options: (props: IWithOffer): QueryOpts => ({
      fetchPolicy: 'network-only',
      variables: {
        idPos: props.posId,
      },
    }),
    props: (props: OptionProps<IConnectedProps>): IConnectedProps | IGraphQLProps => ({
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      csiTables: get(props, 'data.checkTablesAvailability.tables', []),
      startPolling: get(props, 'data.startPolling'),
      stopPolling: get(props, 'data.stopPolling'),
    }),
    skip: (props: IWithOffer & { withCSITableValidation: boolean }): boolean =>
      !props.posId || !props.withCSITableValidation,
  }),
  withProps(withTableInfos),
  withProps(withIsValid)
)(TableNumber);
