import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  text?: string;
}

class CardTitle extends PureComponent<IProps> {
  public render(): ReactNode {
    const { text } = this.props;

    return (
      <Text numberOfLines={1} style={styles.text}>
        {text}
      </Text>
    );
  }
}

export interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    color: theme.colors.textBlack,
    ...theme.fonts.titleText,
  },
});

export default CardTitle;
