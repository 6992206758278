import get from 'lodash/get';
import { ApolloError } from 'apollo-client';
import { loader } from 'graphql.macro';
import { FetchResult } from 'react-apollo';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import withApollo, { WithApolloClient } from 'react-apollo/withApollo';
import { NavigationInjectedProps } from 'react-navigation';
import { connect } from 'react-redux';
import { compose, withHandlers, withState, withProps } from 'recompose';
import { ActionCreator } from 'redux';
import withNavigation from '../../../hoc/withNavigation';
import { selectAuthenticationToken } from '../../../redux/authentication/selectors';
import {
  IElementQuantity,
  IRemoveOutOfStockItemsFromCartAction,
  IResetQuantityForOfferAction,
  IResetSlotForOfferAction,
  removeOutOfStockItemsFromCart,
  resetQuantityForOffer,
  resetSlotForOffer,
} from '../../../redux/clickAndCollect/actions';
import {
  getElementsQuantityByOfferId,
  getElementsTotalPriceByOfferId,
  getElementsTotalQuantityByOfferId,
} from '../../../redux/clickAndCollect/selectors';
import { IAppState } from '../../../redux/reducer';
import store from '../../../redux/store';
import { IAmount } from '../../../types/common';
import QuantityFooter, { IProps as IComponentProps } from '../QuantityFooter.component';
import I18n from '../../../lib/i18n';
import { isWeb } from '../../../lib/responsive';
import Toaster from '../../../services/toaster';
import { getBadgeNumber } from '../../../redux/holding/selectors';

// @see https://github.com/kentcdodds/babel-plugin-macros#caveats
const getOffer = loader('../../../queries/clickandcollect/getOffer.gql');
const upsertOrder = loader('../../../queries/clickandcollect/upsertOrder.gql');
const getUserInfo = loader('../../../queries/getUserInfo.gql');

export enum FooterType {
  FOOD = 'FOOD',
  BEVERAGE = 'BEVERAGE',
}

interface IProps {
  footerType: FooterType;
  idOffer: string;
  shouldSkipBeverage: boolean;
  setItems: (items: []) => void;
}

interface IMapStateToProps {
  elementsQuantity?: IElementQuantity[] | null;
  idGuest?: string;
  token: string;
  totalPrice: IAmount | null;
  totalQuantity: number;
  badgeNumber: string | null;
  isExternalCat?: boolean;
}

interface IMapDispatchToProps {
  resetQuantityForOffer: ActionCreator<IResetQuantityForOfferAction>;
  resetSlotForOffer: ActionCreator<IResetSlotForOfferAction>;
  removeOutOfStockItemsFromCart: ActionCreator<IRemoveOutOfStockItemsFromCartAction>;
}

type IUpsertOrderMutation = (idOffer: string, idTable: string | number) => Promise<FetchResult>;

type IConnectedProps = IMapStateToProps &
  IMapDispatchToProps &
  NavigationInjectedProps &
  WithApolloClient<{}> & {
    upsertOrder: IUpsertOrderMutation;
  };

const mapStateToProps = (state: IAppState, ownProps: IProps): IMapStateToProps => ({
  elementsQuantity: getElementsQuantityByOfferId(state, ownProps.idOffer),
  token: selectAuthenticationToken(state),
  totalPrice: getElementsTotalPriceByOfferId(state, ownProps.idOffer),
  totalQuantity: getElementsTotalQuantityByOfferId(state, ownProps.idOffer),
  badgeNumber: !isWeb() ? getBadgeNumber(state) : null,
  isExternalCat: isWeb(),
});

const mapDispatchToProps: IMapDispatchToProps = {
  resetQuantityForOffer,
  removeOutOfStockItemsFromCart,
  resetSlotForOffer,
};

const upsertOrderHandler = ({
  elementsQuantity,
  client,
  idGuest,
}: IConnectedProps & IProps & { idTable: string | number }): IUpsertOrderMutation => async (
  idOffer: string,
  idTable: string | number
): Promise<{}> => {
  const items =
    elementsQuantity &&
    elementsQuantity.map((element: IElementQuantity) => ({
      idOfferItem: element.elementId,
      quantity: element.quantity,
      chosenBaking: element.chosenBaking,
    }));
  return client
    .mutate({
      mutation: upsertOrder,
      variables: {
        input: {
          idGuest,
          idOffer,
          items,
          idTable,
        },
      },
    })
    .catch((error: ApolloError) => {
      client.query({ query: getOffer, variables: { idOffer }, fetchPolicy: 'network-only' });
      const outOfStockError = error.graphQLErrors.find(e => e.message === 'OUT_OF_STOCK');
      if (outOfStockError) {
        store.dispatch(
          removeOutOfStockItemsFromCart({
            offerId: idOffer,
            items: outOfStockError?.extensions.exception.items || [],
          })
        );
      } else {
        store.dispatch(
          resetQuantityForOffer({
            offerId: idOffer,
          })
        );

        store.dispatch(
          resetSlotForOffer({
            offerId: idOffer,
          })
        );
      }
      throw error;
    });
};

interface ILoadingProps {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

interface IWithExtraProps {
  title: string;
  enabled: boolean;
  onPress: () => void;
}

interface INavigation {
  route: string;
  params: object;
}

const cartSummaryNavigation = (res: object): INavigation => {
  return { route: 'tableServiceCartSummary', params: { orderId: get(res, 'data.order.id') } };
};

const beverageNavigation = (params: object): INavigation => {
  return { route: 'tableServiceBeverages', params };
};

const withExtraProps = ({
  idOffer,
  footerType,
  totalQuantity,
  upsertOrder: upsertOrderMutation,
  navigation: { navigate, getParam },
  shouldSkipBeverage,
  setItems,
}: IProps & ILoadingProps & IConnectedProps): IWithExtraProps => {
  const enabled = totalQuantity > 0 || (footerType === FooterType.FOOD && !shouldSkipBeverage);
  const onPress = async (): Promise<void> => {
    const idTable = getParam('idTable');
    const res = await upsertOrderMutation(idOffer, idTable).catch(error => {
      // cart expired and AnomyGuest
      if (error.message.includes('FORBIDDEN') && isWeb()) {
        Toaster.showError(I18n.t(`dashboard.eat.error.expired`));
        navigate('/cat');
      } else if (error.graphQLErrors[0].message === 'OUT_OF_STOCK') {
        setItems(error.graphQLErrors[0].extensions.exception.items);
      }
      return null;
    });
    if (!res) return;
    const { route, params } =
      shouldSkipBeverage || footerType === FooterType.BEVERAGE
        ? cartSummaryNavigation(res)
        : beverageNavigation({ offerId: idOffer, idTable });
    navigate(route, params);
  };
  return {
    title: enabled
      ? I18n.t('dashboard.eat.clickAndCollect.orderButton')
      : I18n.t('dashboard.eat.clickAndCollect.cartButton'),
    onPress,
    enabled,
  };
};

export interface IGraphQLProps {
  idGuest: string;
  isBadgeRequired: boolean;
}

export default compose<IComponentProps, IProps>(
  withState('isLoading', 'setLoading', false),
  withNavigation,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getUserInfo, {
    props: (props: OptionProps<IMapStateToProps>): IGraphQLProps => ({
      idGuest: get(props, 'data.getUser.guests.edges[0].node.id') || '',
      isBadgeRequired: get(props, 'data.getUser.currentHoldingView.holding.isBadgeRequired' || true),
    }),
  }),
  withApollo,
  withHandlers({ upsertOrder: upsertOrderHandler }),
  withProps(withExtraProps)
)(QuantityFooter);
