import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import Icon from '../Icon';
import Touchable from '../Touchable';

const IMAGE_SIZE = 24;

export type ITheme = 'dark' | 'light';
export interface IProps {
  contentTheme?: ITheme;
  onPress: () => void;
  testID?: string;
}

export const THEME_COLORS: { [key in ITheme]: string } = {
  dark: theme.colors.black,
  light: theme.colors.white,
};

export const CLOSE_MODAL_SIZE = IMAGE_SIZE + theme.margins.padding * 2;

type IDefaultProps = Required<Pick<IProps, 'contentTheme'>>;

class CloseModal extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    contentTheme: 'dark',
  };
  public render(): ReactNode {
    const { onPress, contentTheme, testID } = this.props;
    const color = THEME_COLORS[contentTheme!];

    return (
      <Touchable testID={testID} onPress={onPress} useOpacity>
        <View style={styles.container}>
          <Icon name="cross" tintColor={color} size={IMAGE_SIZE} />
        </View>
      </Touchable>
    );
  }
}

interface IStyle {
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    height: CLOSE_MODAL_SIZE,
    justifyContent: 'center',
    width: CLOSE_MODAL_SIZE,
  },
});

export default CloseModal;
