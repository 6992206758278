import { ApolloQueryResult } from 'apollo-client';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { OperationVariables } from 'react-apollo';
import { FlatListProps, StyleSheet, Text, View, ViewStyle } from 'react-native';

import I18n from '../../../../../lib/i18n';
import { INode, IPos } from '../../../../../types/common';
import { IUserResult } from '../../../../../types/getPointsOfSaleWithMenu';
import { IFilter } from '../../../../../components/FilterDropdown/FilterDropdown.component';

import { getOfferTemplates_list_edges_node_OfferTemplate as IOfferTemplate } from '../../../../../types/clickandcollect/getOfferTemplates';
import { getBookingOrders_active_edges_node_Order as IOrder } from '../../../../../types/bookingservice/getBookingOrders';
import { AtSiteTakeAwayType } from '../../../../../types/clickandcollect/globalTypes';

import PointOfSaleCard from '../../../../../components/PointOfSaleCard';
import OffersList from '../../../../../components/OffersList';

import styles from './styles';
import BookingOrderList from '../../../../../components/BookingOrderList';
import { isWeb } from '../../../../../lib/responsive';
import LoadingCard from '../../../../../components/LoadingCard';

export interface IProps extends Partial<FlatListProps<INode<IPos>>> {
  filters?: IFilter[];
  isLoading?: boolean;
  lastItem?: ReactElement<{}>;
  pointsOfSale: Array<INode<IPos>>;
  orders?: IOrder[];
  offerTemplates?: IOfferTemplate[];
  refetch?: (variables?: OperationVariables) => Promise<ApolloQueryResult<IUserResult>>;
  withFilters: boolean;
  tableServiceComponent?: () => ReactElement<LoadingCard>;
  testID?: string;
}

export interface IState {
  refreshing: boolean;
}

const sortByDate = (a: IOrder, b: IOrder): number =>
  new Date(b.updated).getTime() - new Date(a.updated).getTime();

class PointsOfSaleList extends PureComponent<IProps, IState> {
  public state: IState = {
    refreshing: false,
  };

  public render(): ReactNode {
    const {
      filters,
      pointsOfSale,
      withFilters,
      orders,
      offerTemplates,
      ...flatListProps
    } = this.props;
    const pointOfSalesFiltered = pointsOfSale.filter(
      pos => pos.node.atSiteTakeAwayType !== AtSiteTakeAwayType.NONE
    );
    const isFilterDisplay = !!(withFilters && filters && filters.length > 1);
    const contentStyle = isFilterDisplay
      ? styles.contentContainer
      : StyleSheet.flatten([styles.contentContainer, styles.noFilterMargin]);

    return (
      <View>
        {!isWeb() && orders && (
          <>
            <View style={styles.container}>
              <BookingOrderList orders={orders.sort(sortByDate)} testID="bookingOrderList" />
            </View>
            {this.props.tableServiceComponent ? this.props.tableServiceComponent() : null}
          </>
        )}
        <View style={contentStyle} {...flatListProps}>
          {pointOfSalesFiltered.length === 0
            ? this.renderEmptyList()
            : pointOfSalesFiltered.map((eachPointOfSale, index) =>
                this.renderItem({ index, item: eachPointOfSale })
              )}
        </View>
        {!isWeb() && offerTemplates && (
          <View style={styles.container}>
            <OffersList
              offerTemplates={offerTemplates}
              isPollable
              testID="PointOfSaleList_OffersList"
            />
          </View>
        )}
      </View>
    );
  }

  private renderEmptyList = (): ReactElement<View> => (
    <View style={styles.emptyContainer}>
      <Text>{I18n.t('pointOfSale.list.emptyContent')}</Text>
    </View>
  );

  private renderItem = ({
    index,
    item,
  }: {
    index: number;
    item: INode<IPos>;
  }): ReactElement<{}> => {
    const { pointsOfSale, lastItem, numColumns } = this.props;
    const isLastItem = index === pointsOfSale.length - 1;
    const itemComponent =
      isLastItem && lastItem ? lastItem : <PointOfSaleCard pointOfSale={item.node} />;

    if (numColumns && numColumns > 1) {
      return (
        <View
          key={item.node.id}
          style={StyleSheet.flatten([styles.itemContainer, { flexBasis: `${100 / numColumns}%` }])}
        >
          {itemComponent}
        </View>
      );
    }

    return (
      <View key={item.node.id} style={styles.itemContainer}>
        {itemComponent}
      </View>
    );
  };
}

export interface IStyle {
  contentContainer: ViewStyle;
  emptyContainer: ViewStyle;
  itemContainer: ViewStyle;
  noFilterMargin: ViewStyle;
  container: ViewStyle;
  serviceTableContainer: ViewStyle;
}

export default PointsOfSaleList;
