import { StyleSheet } from 'react-native';

import { IStyle } from './ProfileCard.component';

const PROFILE_CARD_HEIGHT = 650;

const styles = StyleSheet.create<IStyle>({
  card: {
    height: PROFILE_CARD_HEIGHT,
  },
});

export default styles;
