import React, { PureComponent, ReactNode } from 'react';

import TransactionDetailsContent from '../../../../components/TransactionDetailsContent';
import { WebPage } from '../../../../componentsWeb';

class TransactionDetails extends PureComponent<{}> {
  public render(): ReactNode {
    return (
      <WebPage>
        <TransactionDetailsContent hasTitle />
      </WebPage>
    );
  }
}

export default TransactionDetails;
