import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

import theme from '../../theme';
import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';

export interface IProps {
  firstName?: string;
  holdingName?: string;
  isLoading?: boolean;
  lastName?: string;
}

class GuestInfoCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { firstName, holdingName, isLoading, lastName } = this.props;
    const fullName = `${firstName} ${lastName}`;

    return (
      <LoadingCard isLoading={isLoading}>
        <CardTitle text={fullName} />
        <Text style={styles.text}>{holdingName}</Text>
      </LoadingCard>
    );
  }
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.cardText,
    color: theme.colors.textBlack,
  },
});

export default GuestInfoCard;
