import React, { PureComponent, ReactNode } from 'react';
import {
  ImageBackground,
  ImageRequireSource,
  ImageStyle,
  ImageURISource,
  StatusBar,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  KeyboardAvoidingView,
  Keyboard,
  EmitterSubscription,
  Platform,
  Dimensions
} from 'react-native';
import SplashScreen from 'react-native-splash-screen';
import { SafeAreaView } from 'react-native-safe-area-context';

import theme from '../../theme';

export interface IProps {
  backgroundColor?: string;
  backgroundImage?: ImageURISource | ImageURISource[] | ImageRequireSource;
  hasBlackHeader?: boolean;
  headerComponent?: ReactNode;
  isModal?: boolean;
  noPadding?: boolean;
  statusBarColor?: string;
  style?: StyleProp<ViewStyle>;
  keyboardAvoidingViewBehavior?: 'height' | 'padding' | 'position';
  safeAreaEdgesToShow: string[];
}

type IDefaultProps = Required<
  Pick<IProps, 'backgroundColor' | 'hasBlackHeader' | 'noPadding' | 'safeAreaEdgesToShow'>
>;

export const DEFAULT_PAGE_HORIZONTAL_PADDING = theme.margins.unit * 3;
export const DEFAULT_PAGE_VERTICAL_PADDING = theme.margins.unit * 2;
const DEFAULT_EDGES = ['left', 'right'];

class AppPage extends PureComponent<IProps, { edges: string[] }> {
  public static defaultProps: IDefaultProps = {
    backgroundColor: theme.colors.background,
    hasBlackHeader: false,
    noPadding: false,
    safeAreaEdgesToShow: ['top'],
  };

  private keyboardWillShowListener!: EmitterSubscription;
  private keyboardWillHideListener!: EmitterSubscription;

  public componentDidMount(): void {
    this.keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', this.keyboardWillShow);
    this.keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', this.keyboardWillHide);
    if (!this.props.backgroundImage) {
      // If no background image, the page is immediately loaded
      this.onPageLoaded();
    }
  }

  public componentWillUnmount(): void {
    this.keyboardWillShowListener.remove();
    this.keyboardWillHideListener.remove();
  }

  public render(): ReactNode {
    const { backgroundColor, hasBlackHeader, keyboardAvoidingViewBehavior } = this.props;

    return (
      <>
        <StatusBar
          backgroundColor={hasBlackHeader ? theme.colors.deprecatedBlack : theme.colors.white}
          barStyle={hasBlackHeader ? 'light-content' : 'dark-content'}
        />

        <KeyboardAvoidingView
          style={styles.container}
          behavior={keyboardAvoidingViewBehavior}
          keyboardVerticalOffset={Platform.OS === 'ios' && Dimensions.get('window').height * 0.1}
        >
          {this.props.backgroundImage ? (
            <ImageBackground
              source={this.props.backgroundImage}
              style={styles.image}
              onLoadEnd={this.onPageLoaded}
            >
              {this.renderContent()}
            </ImageBackground>
          ) : (
            this.renderContent(backgroundColor)
          )}
        </KeyboardAvoidingView>
      </>
    );
  }

  private onPageLoaded = (): void => {
    SplashScreen.hide();
  };

  private keyboardWillShow = (): void => {
    // when we are showing the keyboard we need to remove the bottom edge otherwise it will push the keyboard up
    const edgesWithoutBottom = [...DEFAULT_EDGES, ...this.props.safeAreaEdgesToShow].filter(
      eachEdge => eachEdge !== 'bottom'
    );

    this.setState({ edges: edgesWithoutBottom });
  };

  private keyboardWillHide = (): void => {
    this.setState({ edges: [...DEFAULT_EDGES, ...this.props.safeAreaEdgesToShow] });
  };

  private renderContent = (backgroundColor?: string): ReactNode => {
    const containerStyle = StyleSheet.flatten([
      styles.page,
      {
        paddingHorizontal: this.props.noPadding ? 0 : DEFAULT_PAGE_HORIZONTAL_PADDING,
        paddingVertical: this.props.noPadding ? 0 : DEFAULT_PAGE_VERTICAL_PADDING,
      },
      this.props.style,
    ]);

    const state = this.state || { edges: [...DEFAULT_EDGES, ...this.props.safeAreaEdgesToShow] };

    return (
      <SafeAreaView
        style={[
          styles.safeAreaContainer,
          {
            backgroundColor: this.props.statusBarColor
              ? this.props.statusBarColor
              : backgroundColor,
          },
        ]}
        edges={state.edges}
      >
        <View style={[styles.page, { backgroundColor }]}>
          {this.props.headerComponent}
          <View style={[containerStyle, { backgroundColor }]}>{this.props.children}</View>
        </View>
      </SafeAreaView>
    );
  };
}

export interface IStyle {
  image: ImageStyle;
  page: ViewStyle;
  safeAreaContainer: ViewStyle;
  container: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  image: {
    flex: 1,
  },
  container: {
    backgroundColor: theme.colors.background,
    padding: 0,
    width: '100%',
    height: '100%',
  },
  page: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  safeAreaContainer: {
    flex: 1,
  },
});

export default AppPage;
