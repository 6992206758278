import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { FlatList, StyleSheet, View, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import theme from '../../theme';
import { IAmount } from '../../types/common';
import { ITicket, ITicketElement } from '../../types/getTicket';
import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';
import PricesCardItem from '../PricesCardItem';
import PricesCardTotal from '../PricesCardTotal';

export interface IProps {
  isLoading?: boolean;
  ticket?: ITicket;
  total?: IAmount;
}

const COMMODITY_SUBSIDY_QUANTITY = 1;

class TrayPricesCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isLoading, ticket, total } = this.props;
    const shouldDisplayCard = isLoading || (ticket && ticket.elements.length > 0);

    if (!shouldDisplayCard) {
      return null;
    }

    return (
      <LoadingCard isLoading={isLoading}>
        <View style={styles.titleContainer}>
          <CardTitle text={I18n.t('transactions.trayTitle')} />
        </View>
        <FlatList
          data={(ticket && ticket.elements) || []}
          renderItem={({ item }: { item: ITicketElement }): ReactElement<PricesCardItem> => (
            <PricesCardItem quantity={item.quantity} name={item.label} amount={item.total} />
          )}
          keyExtractor={(item: ITicketElement): string => item.label}
        />
        {ticket &&
          ticket.foodstuff.subsidy &&
          Number(ticket.foodstuff.subsidy) !== 0 && (
            <PricesCardItem
              quantity={COMMODITY_SUBSIDY_QUANTITY}
              name={I18n.t('transactions.commoditySubsidy')}
              amount={{ amount: ticket.foodstuff.subsidy, currency: ticket.foodstuff.currency }}
            />
          )}
        <PricesCardTotal amount={total} />
      </LoadingCard>
    );
  }
}

interface IStyle {
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  titleContainer: {
    marginBottom: theme.margins.unit * 2,
  },
});

export default TrayPricesCard;
