import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import I18n from '../../../../lib/i18n';
import { roundNutritionalValues } from '../../../../services/nutritionalMapper';

import theme from '../../../../theme';
import { INutritionCompositionType } from '../../../../types/common';

import ProgressBar from './../../../../components/ProgressBar';

export interface IProps {
  nutritionalElementName: INutritionCompositionType;
  proportion: number;
  style?: StyleProp<ViewStyle>;
  subtitle?: string;
  subunit?: string;
  subvalue?: number;
  title: string;
  unit?: string;
  value?: number | null;
}

class MenuElementNutritionSection extends PureComponent<IProps> {
  public render(): ReactNode {
    const {
      proportion,
      subtitle,
      subunit,
      subvalue,
      title,
      unit,
      value,
      nutritionalElementName,
    } = this.props;
    const roundedValue = value && roundNutritionalValues(nutritionalElementName, value);
    const roundedSubValue = subvalue && roundNutritionalValues(nutritionalElementName, subvalue);

    const unitTitle =
      value === null ? I18n.t('dashboard.menuElementDetails.noInfo') : `${roundedValue} ${unit}`;
    const unitSubTitle =
      subtitle && subvalue === null
        ? I18n.t('dashboard.menuElementDetails.noInfo')
        : `${roundedSubValue} ${subunit}`;

    return (
      <View style={[styles.container, this.props.style]}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.unitTitle}>{unitTitle}</Text>
        </View>
        <View style={styles.barContainer}>
          <ProgressBar progress={proportion} />
        </View>
        {subtitle && (
          <View style={styles.subtitleContainer}>
            <Text style={styles.subtitle}>{subtitle}</Text>
            <Text style={styles.subtitle}>{unitSubTitle}</Text>
          </View>
        )}
      </View>
    );
  }
}

export interface IStyle {
  barContainer: ViewStyle;
  container: ViewStyle;
  subtitle: TextStyle;
  subtitleContainer: ViewStyle;
  title: TextStyle;
  titleContainer: ViewStyle;
  unitTitle: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  barContainer: {
    marginTop: theme.margins.cardUnit,
  },
  container: {
    marginBottom: 4 * theme.margins.cardUnit,
  },
  subtitle: {
    ...theme.fonts.regularText,
    color: theme.colors.grayMedium,
    marginTop: theme.margins.cardUnit,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    ...theme.fonts.strongText,
    color: theme.colors.black,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  unitTitle: {
    ...theme.fonts.regularText,
    color: theme.colors.black,
  },
});

export default MenuElementNutritionSection;
