import React, { PureComponent, ReactNode } from 'react';
import { Text, TextStyle, View, ViewStyle } from 'react-native';

import Icon, { IIconName } from '../../components/Icon';
import Touchable from '../../components/Touchable';
import { IMedia, media } from '../../lib/responsive';
import theme from '../../theme';

export interface IProps {
  isActive?: boolean;
  name: IIconName;
  onPress?: () => void;
  text: string;
}

class TabBarIcon extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isActive, name, onPress, text } = this.props;
    const color = isActive ? theme.colors.active : theme.colors.textBlack;

    return (
      <View style={media(styles.container)}>
        <Touchable style={media(styles.touchableContainer)} onPress={onPress}>
          <View style={media(styles.icon)}>
            <Icon name={name} focused={isActive} tintColor={color} />
          </View>
          <View style={media(styles.textContainer)}>
            <Text style={[media(styles.text), { color }]}>{text}</Text>
          </View>
        </Touchable>
      </View>
    );
  }
}

interface IStyle {
  container: IMedia<ViewStyle>;
  icon: IMedia<TextStyle>;
  text: IMedia<TextStyle>;
  textContainer: IMedia<ViewStyle>;
  touchableContainer: IMedia<ViewStyle>;
}

const styles: IStyle = {
  container: {
    _desktop: {},
    _mobile: {
      flex: 1,
      flexBasis: 100,
      maxWidth: 100,
    },
    _tablet: {},
  },
  icon: {
    _desktop: {},
    _mobile: {
      height: theme.config.MOBILE_MENU_ICON_HEIGHT,
      justifyContent: 'center',
    },
    _tablet: {},
  },
  text: {
    _desktop: {
      paddingLeft: theme.margins.unit,
      ...theme.fonts.tabBar,
    },
    _mobile: theme.fonts.tabBarMobile,
    _tablet: {
      paddingLeft: theme.margins.unit,
      ...theme.fonts.tabBar,
    },
  },
  textContainer: {
    _desktop: {
      justifyContent: 'center',
    },
    _mobile: {},
    _tablet: {
      justifyContent: 'center',
    },
  },
  touchableContainer: {
    _desktop: {
      flexDirection: 'row',
      paddingVertical: theme.margins.unit,
    },
    _mobile: {
      alignItems: 'center',
      flexDirection: 'column',
    },
    _tablet: {
      flexDirection: 'row',
      justifyContent: 'center',
      paddingHorizontal: theme.margins.unit * 2,
    },
  },
};

export default TabBarIcon;
