import React, { PureComponent, ReactNode } from 'react';
import { NavigationInjectedProps } from 'react-navigation';

import { WebPage } from '../../../componentsWeb';

import CardCollection from './CardCollection';

class Home extends PureComponent<NavigationInjectedProps> {
  public render(): ReactNode {
    return (
      <WebPage>
        <CardCollection />
      </WebPage>
    );
  }
}

export default Home;
