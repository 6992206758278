import React, { PureComponent, ReactNode } from 'react';
import { LayoutChangeEvent, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import { isWeb } from '../../../lib/responsive';
import theme from '../../../theme';

import { CIRCLE_DIAMETER } from './DropdownFilterCounter';
import platformStyles from './styles';

export const ICON_SIZE = 15;

export interface IProps {
  onLayout?: (nativeEvent: LayoutChangeEvent) => void;
  onPress: () => void;
  showFilters: boolean;
}

class FilterDropdownHeader extends PureComponent<IProps> {
  public render(): ReactNode {
    const iconDownName = 'chevron-down';
    const iconUpName = 'chevron-up';

    const containerStyle = StyleSheet.flatten([platformStyles.container, styles.container]);

    return (
      <TouchableOpacity
        onPress={this.props.onPress}
        activeOpacity={isWeb() ? 1 : undefined}
        onLayout={this.props.onLayout}
      >
        <View style={containerStyle}>
          <View style={styles.iconContainer}>
            <Icon
              name={this.props.showFilters ? iconUpName : iconDownName}
              size={ICON_SIZE}
              color={theme.colors.textBlack}
            />
          </View>
          <View style={styles.textContainer}>{this.props.children}</View>
        </View>
      </TouchableOpacity>
    );
  }
}

export interface IPlatformStyle {
  container: ViewStyle;
}

export interface IStyle {
  container: ViewStyle;
  iconContainer: ViewStyle;
  textContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 2 * theme.margins.cardUnit,
  },
  iconContainer: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    marginRight: theme.margins.cardUnit + theme.margins.unit,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  textContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    height: CIRCLE_DIAMETER,
  },
});

export default FilterDropdownHeader;
