import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import theme from '../../theme';
import LoadingView from '../LoadingView';
import Touchable from '../Touchable';
import OrderButton from '../BookingFooter/OrderButton';
import platformStyles from '../BookingFooter/styles';
import BottomSheet from '../BottomSheet';
import I18n from '../../lib/i18n';
import LargeButton from '../LargeButton';
import { LARGE_BUTTON_THEME } from '../LargeButton/LargeButton.component';

export interface IProps {
  title: string;
  enabled: boolean;
  isLoading: boolean;
  onPress: () => void;
  error: any;
  posId: string;
  activeIndex: number;
  navigation: any;
  testID?: string;
}

export const BUTTON_HEIGHT = 65;

export interface IState {
  isBottomSheetVisible: boolean;
  isMutating: boolean;
}

class BookingFooter extends PureComponent<IProps> {
  public state: IState = {
    isBottomSheetVisible: false,
    isMutating: false,
  };

  public componentDidUpdate(prevProps: IProps): void {
    const { error } = this.props;
    if (error && error !== prevProps.error) {
      this.setState({ isBottomSheetVisible: true });
    }
  }

  private async handleOnPress(callback: () => void): Promise<void> {
    this.setState({ isMutating: true });
    await callback();
    this.setState({ isMutating: false });
  }

  public render(): ReactNode {
    const { title, enabled, isLoading, onPress, testID } = this.props;
    const { isBottomSheetVisible, isMutating } = this.state;
    const containerStyle = enabled
      ? [platformStyles.card, styles.container, styles.enabled]
      : [platformStyles.card, styles.container, styles.disabled];

    const { error } = this.props;
    return isLoading ? (
      <View style={containerStyle}>
        <LoadingView isLoading={isLoading} />
      </View>
    ) : (
      <Touchable testID={testID} onPress={() => this.handleOnPress(onPress)} style={containerStyle} disabled={!enabled || isMutating}>
        <OrderButton text={title} disabled={!enabled} />
        {error && (
          <BottomSheet isVisible={isBottomSheetVisible}>
            <View style={{ padding: theme.margins.unit * 2 }}>
              <Text style={styles.title}>
                {I18n.t('dashboard.eat.bookingService.slotFullErrorTitle')}
              </Text>
              <Text style={[styles.marginTop, styles.description]}>
                <Text style={{ ...theme.fonts.paragraph, fontWeight: '600' }}>
                  {I18n.t('dashboard.eat.bookingService.slotFullErrorDescription')}
                </Text>
              </Text>
              <LargeButton
                testID={`${testID}_goBackButton`}
                style={[theme.buttons.LargeButton, styles.marginTop]}
                text={I18n.t('dashboard.eat.bookingService.goBack')}
                theme={LARGE_BUTTON_THEME.neutral}
                onPress={this.onBackPress}
              />
            </View>
          </BottomSheet>
        )}
      </Touchable>
    );
  }
  private onBackPress = (): void => {
    const { posId, activeIndex, navigation } = this.props;
    this.setState({ isBottomSheetVisible: false });
    navigation.replace('bookingService', { posId, activeIndex });
  };
}

interface IStyle {
  enabled: ViewStyle;
  container: ViewStyle;
  disabled: ViewStyle;
  title: TextStyle;
  description: TextStyle;
  marginTop: ViewStyle;
}

export interface IPlatformStyle {
  card: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  enabled: {
    backgroundColor: theme.colors.validate,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: BUTTON_HEIGHT,
    justifyContent: 'flex-end',
  },
  title: {
    color: theme.colors.textBlack,
    paddingHorizontal: theme.margins.unit,
    ...theme.fonts.semiBoldText,
  },
  description: {
    color: theme.colors.grayMedium,
    paddingHorizontal: theme.margins.unit,
    ...theme.fonts.regularText,
  },
  marginTop: {
    marginVertical: 10,
  },
  disabled: { backgroundColor: theme.colors.backgroundQuantityFooter },
});

export default BookingFooter;
