import React, { useState, useEffect, useMemo } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import { useQuery } from 'react-apollo-hooks';
import { loader } from 'graphql.macro';
import AppPage from '../../../../../components/AppPage';
import I18n from '../../../../../lib/i18n';
import theme from '../../../../../theme';
import { ClickAndCollectQuantityFooter, ClickAndCollectFooterType } from '../../../../../components/QuantityFooter';
import { IWithLastSelectedPoint, IWithPickupPoints } from './OrderSelectPickupPointPage.container'
import { getOffer_offer_Offer as IOffer } from '../../../../../types/clickandcollect/getOffer';
import { getOfferTemplatePickupPoints_pickUpPointsWithSelected as IPickupPoint } from '../../../../../types/clickandcollect/getOfferTemplatePickupPoints';
import { getPickupPointByOfferId } from '../../../../../redux/clickAndCollect/selectors';
import { setPickupPoint } from '../../../../../redux/clickAndCollect/actions';
import store from '../../../../../redux/store';
import { IAppState } from '../../../../../redux/reducer';


const PICKUP_POINTS_QUERY = loader(
  '../../../../../queries/clickandcollect/getOfferTemplatePickupPoints.gql'
);
const ONLY_SPACES_REG_EX = /^\s*$/; // Don't search if value doesn't have any letters

export interface IProps extends IWithLastSelectedPoint, IWithPickupPoints  {
  offer: IOffer;
  idGuest: string;
  appState: IAppState;
}

interface IPickupPointCard {
  pickupPoint: IPickupPoint;
  selected: boolean;
  onPress: (item: IPickupPoint) => void;
}

const PickupPointCard = ({pickupPoint, selected, onPress}: IPickupPointCard) => {
  const {name, withdrawalSchedule, description} = pickupPoint;
  const selectedStyleCard = selected ? { borderColor: theme.colors.validate, backgroundColor: theme.colors.validate} : {};
  const selectedStyleText = selected ? { color: theme.colors.white } : {};
  return (
    <TouchableOpacity style={[styles.pickupPointContainer, selectedStyleCard]} onPress={() => onPress(pickupPoint)}>
      <Text style={[styles.pickupPointName, selectedStyleText]}>{name}</Text>
      <Text style={[styles.pickupPointSchedule, selectedStyleText]}>{withdrawalSchedule}</Text>
      <Text style={[styles.pickupPointDescription, selectedStyleText]}>{description}</Text>
    </TouchableOpacity>
  )
}

const OrderSelectPickupPointPage = ({
  offer,
  isLastSelectedPointLoading,
  lastSelectedPoint,
  appState }: IProps
) => {
  const selectedPickupPoint = getPickupPointByOfferId(appState, offer.id)
  const SearchSVG = theme.images.searchSVG;
  const SuccessSVG = theme.images.atomAlerts.successSVG;
  const WarningSVG = theme.images.atomAlerts.warningSVG;

  const stringHasLetters = (value: string) => !ONLY_SPACES_REG_EX.test(value);

  const [pickupPointList, setPickupPointList] = useState<IPickupPoint[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const { data, loading: isPickupPointsLoading, refetch, networkStatus } = useQuery(PICKUP_POINTS_QUERY, {
    variables: {
      search: searchValue,
      idOfferTemplate: offer.offerTemplate.id
    },
    fetchPolicy: 'network-only',
    skip: !offer?.offerTemplate?.id || !searchValue || !stringHasLetters(searchValue) || searchValue.trim().length < 3
  });

  const offerDate = useMemo(() =>
    _.capitalize(moment(offer?.withdrawRange?.split('/')[0]).format('dddd DD/MM'))
    , [offer?.withdrawRange]);

  useEffect(() => {
    if (!!lastSelectedPoint?.id && !selectedPickupPoint) {
      dispatchPickupPoint(lastSelectedPoint)
    }
  }, [lastSelectedPoint]);

  useEffect(() => {
    if (!data) {
      setPickupPointList([]);
      return;
    }

    if (data &&
        data?.pickUpPointsWithSelected?.length !== pickupPointList.length ||
        data?.pickUpPointsWithSelected?.[0]?.id !== pickupPointList?.[0]?.id
       ) {
        setPickupPointList(data?.pickUpPointsWithSelected);
        return;
    }

    if (stringHasLetters(searchValue) && searchValue.trim().length > 3) {
      const timeOut = setTimeout(() => {
        refetch({
          search: searchValue.trim(),
          idOfferTemplate: offer.offerTemplate.id
        });
      }, 500);

      if(networkStatus === 7){
        setPickupPointList(data?.pickUpPointsWithSelected)
      }

      return () => clearTimeout(timeOut)
    }

  }, [searchValue, data?.pickUpPointsWithSelected]);

  useEffect(() => {
    if (pickupPointList?.length === 0 && data?.pickUpPointsWithSelected?.length > 0) {
      setPickupPointList(data?.pickUpPointsWithSelected)
    }
  }, [pickupPointList, lastSelectedPoint?.id, data?.pickUpPointsWithSelected]);

  const dispatchPickupPoint = (pickupPoint: IPickupPoint) => {
    if (!offer.id) {
      return;
    }
    store.dispatch(
      setPickupPoint({
        offerId: offer.id,
        pickupPoint
      })
    );
  }

  const renderItem = ({ item }: { item: IPickupPoint }) => (
    <PickupPointCard
      pickupPoint={item}
      selected={item.numericId === selectedPickupPoint?.numericId}
      onPress={dispatchPickupPoint}
    />
  );

  const renderEmptyComponent = () => <Text style={styles.noResultsText}>{I18n.t('dashboard.eat.clickAndCollect.selectPickupPoint.emptyList')}</Text>;

  const renderListFooterComponent = () => (
    <View style={styles.alertTextContainer}>
      {!selectedPickupPoint?.id ?
        <>
          <WarningSVG />
          <Text style={styles.alertText}>{` ${I18n.t('dashboard.eat.clickAndCollect.selectPickupPoint.noChosenPointAlert')}`}</Text>
        </> :
        <View style={styles.alertContainer}>
          <View style={styles.alertTextContainer}>
            <SuccessSVG />
            <Text style={styles.alertText}>{` ${I18n.t('dashboard.eat.clickAndCollect.selectPickupPoint.chosenPointAlert')} ${selectedPickupPoint.name}`}</Text>
          </View>
          <Text style={styles.alertText}>{`${selectedPickupPoint.withdrawalSchedule}`}</Text>
        </View>
      }
    </View>
  )

  return (
    <AppPage noPadding>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <Text style={styles.title}>{offerDate}</Text>
          <Text style={styles.headerPosName}>{offer.offerTemplate.pos.name}</Text>
        </View>
        {isLastSelectedPointLoading ?
          <ActivityIndicator /> :
          lastSelectedPoint?.numericId &&
            <>
              <View style={styles.lastSelectedContainer}>
                <Text style={styles.title}>{I18n.t('dashboard.eat.clickAndCollect.selectPickupPoint.lastPointSelected')}</Text>
                <PickupPointCard
                  pickupPoint={lastSelectedPoint}
                  selected={lastSelectedPoint?.numericId === selectedPickupPoint?.numericId}
                  onPress={dispatchPickupPoint}
                />
              </View>
              <Text style={styles.title}>{I18n.t('dashboard.eat.clickAndCollect.selectPickupPoint.title')}</Text>
            </>
        }
        <View style={styles.listSection}>
          <View style={styles.searchBarContainer}>
            <TextInput
              style={styles.textInputStyle}
              value={searchValue}
              onChangeText={setSearchValue}
              placeholder={I18n.t('dashboard.eat.clickAndCollect.selectPickupPoint.searchInputPlaceHolder')}
            />
            <SearchSVG />
          </View>
          {isPickupPointsLoading ?
            <ActivityIndicator /> :
            <FlatList
              style={styles.pickupPointListContainer}
              data={pickupPointList}
              renderItem={renderItem}
              keyExtractor={item => item.id}
              ListEmptyComponent={searchValue ? renderEmptyComponent : null}
            />
          }
          {renderListFooterComponent()}
        </View>
      </View>
      <ClickAndCollectQuantityFooter
        withdrawalType={offer?.offerTemplate?.withdrawalType}
        testID="orderSelectPickupPage_clickAndCollectFooter"
        idOffer={offer?.id}
        footerType={ClickAndCollectFooterType.SLOT}
        fontSize={15}
        idPickupPoint={selectedPickupPoint?.id}
      />
    </AppPage>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 18
  },
  headerContainer: {
    alignItems: 'center',
    marginVertical: 20,
  },
  title: {
    ...theme.fonts.slotsTitle,
    fontWeight: '600',
    color: theme.colors.black,
    marginBottom: 5,
  },
  headerPosName: {
    ...theme.fonts.slotsTitle,
    color: theme.colors.black,
  },
  lastSelectedContainer: {
    marginBottom: theme.margins.padding
  },
  listSection: {
    flex: 1,
    marginTop: 5
  },
  pickupPointListContainer: {
    flexGrow: 0
  },
  pickupPointContainer: {
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.colors.darkGrey,
    borderRadius: 8,
    marginTop: theme.margins.cardUnit,
    padding: theme.margins.unit
  },
  pickupPointName: {
    ...theme.fonts.slotsTitle,
    fontWeight: '600',
    color: theme.colors.black,
  },
  pickupPointSchedule: {
    ...theme.fonts.slotsTitle,
    color: theme.colors.black,
  },
  pickupPointDescription: {
    ...theme.fonts.cardText,
    color: theme.colors.black,
  },
  searchBarContainer: {
    height: 45,
    borderWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: theme.colors.darkGrey,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center'
  },
  textInputStyle: {
    width: '92%',
    padding: 0,
    paddingRight: theme.margins.cardUnit
  },
  alertContainer: {
    marginVertical: 3
  },
  alertText: {
    ...theme.fonts.cardText,
    textAlign: 'center',
    marginLeft: 3,
    color: theme.colors.black,
  },
  alertTextContainer: {
    marginTop: theme.margins.cardUnit,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noResultsText: {
    ...theme.fonts.slotsTitle,
    color: theme.colors.black,
    padding: theme.margins.cardUnit,
  }
});

export default OrderSelectPickupPointPage;
