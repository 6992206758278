import { Location } from 'history';
import React, { PureComponent, ReactNode } from 'react';
import { NavigationInjectedProps } from 'react-navigation';

import LoadingView from '../../../../src/components/LoadingView';
import Auth from '../../../../src/services/authentication';

export interface IProps extends NavigationInjectedProps {
  location: Location;
}

class AuthenticationCallback extends PureComponent<IProps> {
  public componentDidMount(): void {
    Auth.handleAuthentication(this.props.navigation.getParam('signInRedirection'));
  }

  public render(): ReactNode {
    return <LoadingView isLoading />;
  }
}

export default AuthenticationCallback;
