import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageURISource } from 'react-native';

import { isDisneyHolding } from '../../services/holding';
import theme from '../../theme';

export interface IProps {
  height: number;
  idHolding: string;
  width: number;
}

class HoldingLogo extends PureComponent<IProps> {
  public render(): ReactNode {
    const { height, width } = this.props;
    const sourceImage = this.getSource();

    if (!sourceImage) {
      return null;
    }

    const imageStyle = { height, width };

    return <Image source={sourceImage} style={imageStyle} />;
  }
  private getSource = (): ImageURISource | undefined => {
    if (!isDisneyHolding(this.props.idHolding)) {
      return undefined;
    }

    return theme.images.brandBlackBienvenue;
  };
}

export default HoldingLogo;
