import { ApolloLink, FetchResult, NextLink, Observable, Operation } from 'apollo-link';
import { GraphQLError } from 'graphql';
import moment from 'moment';

import { setQueryDateTime } from '../../redux/queries/actions';
import store from '../../redux/store';

export const dateTimeLink: ApolloLink = new ApolloLink(
  (operation: Operation, forward?: NextLink): Observable<FetchResult> | null => {
    if (!forward) {
      return null;
    }

    return new Observable<FetchResult>(
      (observer: ZenObservable.SubscriptionObserver<FetchResult>): (() => void) => {
        let subscriber: ZenObservable.Subscription;

        try {
          subscriber = forward(operation).subscribe({
            complete: (): void => {
              observer.complete.bind(observer)();
            },
            error: (networkError: GraphQLError): void => {
              observer.error(networkError);
            },
            next: (result: FetchResult): void => {
              const query = operation.operationName;
              const dateTime = moment().toISOString();
              store.dispatch(setQueryDateTime({ query, dateTime }));

              observer.next(result);
            },
          });
        } catch (error) {
          observer.error(error);
        }

        return (): void => {
          if (subscriber) {
            subscriber.unsubscribe();
          }
        };
      }
    );
  }
);
