import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import { IAmount } from '../../types/common';
import Amount from '../Amount';

export interface IProps {
  amount: IAmount;
  name: string;
  quantity?: number;
}

class PricesCardItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { amount, name, quantity } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.detailsContainer}>
          {quantity && <Text style={styles.quantity}>{quantity}</Text>}
          <Text numberOfLines={1} style={styles.name}>
            {name}
          </Text>
        </View>
        <Amount amount={amount} style={styles.amount} />
      </View>
    );
  }
}

interface IStyle {
  amount: TextStyle;
  container: ViewStyle;
  detailsContainer: ViewStyle;
  name: TextStyle;
  quantity: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  amount: {
    color: theme.colors.textBlack,
    ...theme.fonts.smallAmount,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.margins.cardUnit,
  },
  detailsContainer: {
    flexDirection: 'row',
  },
  name: {
    color: theme.colors.textGray,
    paddingHorizontal: theme.margins.cardUnit,
    ...theme.fonts.pricesCardItemLabel,
  },
  quantity: {
    color: theme.colors.textBlack,
    ...theme.fonts.semiBoldText,
  },
});

export default PricesCardItem;
