import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  id: string;
  isActivated: boolean;
  label: string;
  onPress: (id: string) => void;
}

class FilterItem extends PureComponent<IProps> {
  public render(): ReactNode {
    const { isActivated } = this.props;
    const filterItemStyle = StyleSheet.flatten([
      styles.zone,
      isActivated ? styles.active : styles.notActive,
    ]);
    const textStyle = StyleSheet.flatten([
      styles.text,
      isActivated ? styles.activeText : styles.notActiveText,
    ]);

    return (
      <TouchableOpacity onPress={this.props.onPress.bind(this, this.props.id)}>
        <View style={filterItemStyle}>
          <Text style={textStyle}>{this.props.label}</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

export interface IStyle {
  active: ViewStyle;
  activeText: TextStyle;
  notActive: ViewStyle;
  notActiveText: TextStyle;
  text: TextStyle;
  zone: ViewStyle;
}

const FILTER_BORDER_RADIUS = 20;
const FILTER_BORDER_WIDTH = 2;

const styles = StyleSheet.create<IStyle>({
  active: {
    backgroundColor: theme.colors.active,
  },
  activeText: {
    color: theme.colors.textWhite,
  },
  notActive: {
    backgroundColor: theme.colors.background,
  },
  notActiveText: {
    color: theme.colors.active,
  },
  text: {
    ...theme.fonts.filter,
  },
  zone: {
    alignSelf: 'flex-start',
    borderColor: theme.colors.active,
    borderRadius: FILTER_BORDER_RADIUS,
    borderWidth: FILTER_BORDER_WIDTH,
    marginBottom: theme.margins.cardUnit * 2,
    marginRight: theme.margins.cardUnit,
    paddingHorizontal: theme.margins.cardUnit * 2,
    paddingVertical: theme.margins.cardUnit,
  },
});

export default FilterItem;
