import { NavigationInjectedProps } from 'react-navigation';
import { compose } from 'recompose';

import withNavigation from '../../../../src/hoc/withNavigation/withNavigation.web';

import ConfirmEmailLead from './ConfirmEmailLead.component';

export type IConnectedProps = NavigationInjectedProps;
export type IContainerProps = Omit<NavigationInjectedProps, keyof IConnectedProps>;

export default compose<NavigationInjectedProps, IContainerProps>(withNavigation)(ConfirmEmailLead);
