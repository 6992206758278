import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';

import I18n from '../../lib/i18n';
import theme from '../../theme';

export interface IProps {
  dateTime?: string;
  style?: StyleProp<TextStyle>;
}

class TransactionAmountDateTime extends PureComponent<IProps> {
  public render(): ReactNode {
    const { dateTime, style } = this.props;

    if (!dateTime) {
      return null;
    }

    const momentDate = moment(dateTime);
    const transactionDay = momentDate.format('DD/MM/YYYY');
    const transactionHour = momentDate.format('HH[h]mm');

    return (
      <Text style={[styles.text, style]}>
        {I18n.t('transactions.date', { transactionDay, transactionHour })}
      </Text>
    );
  }
}

interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.cardText,
    color: theme.colors.textGray,
  },
});

export default TransactionAmountDateTime;
