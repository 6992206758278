import React, { PureComponent, ReactNode } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ErrorMessage from '../../components/ErrorMessage';
import I18n from '../../lib/i18n';
import theme from '../../theme';

export interface IProps extends RNTextInputProps {
  containerStyle?: StyleProp<ViewStyle>;
  // This is a translation key return by Yup validationSchema or others validators
  error?: string;
  hasError?: boolean;
  inputStyle?: StyleProp<TextStyle>;
  name?: string;
  style?: StyleProp<ViewStyle>;
  type?: string;
}

class TextInputFormik extends PureComponent<IProps> {
  private input: RNTextInput | null = null;

  public clear(): void {
    if (this.input) {
      this.input.clear();
    }
  }

  public focus(): void {
    if (this.input) {
      this.input.focus();
    }
  }

  public render(): ReactNode {
    const { containerStyle, error, hasError, inputStyle, style, ...inputProps } = this.props;
    const hasInputError = error || hasError;
    const errorInputStyle = hasInputError ? styles.fieldTextError : {};
    const placeHolderColor = hasInputError ? theme.colors.errorMessage : theme.colors.placeholder;
    const errorFieldContainerStyle = hasInputError ? styles.fieldContainerError : {};

    return (
      <View style={[styles.container, style]}>
        <View style={[styles.fieldContainer, containerStyle, errorFieldContainerStyle]}>
          <RNTextInput
            // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/16318#issuecomment-346970646
            // tslint:disable-next-line:no-any
            ref={(input: any): void => {
              this.input = input;
            }}
            style={[styles.fieldText, errorInputStyle, inputStyle]}
            underlineColorAndroid="transparent"
            placeholderTextColor={placeHolderColor}
            blurOnSubmit={false}
            {...inputProps}
          />
        </View>
        {this.renderErrorMessage(error)}
      </View>
    );
  }

  private renderErrorMessage = (errorMessage?: string): ReactNode => {
    if (!errorMessage) {
      return null;
    }

    return (
      <View style={styles.containerError}>
        <ErrorMessage noMargin text={I18n.t(errorMessage)} />
      </View>
    );
  };
}

const INPUT_FIELD_HEIGHT = 50;
const INPUT_BORDER_RADIUS = 4;
const INPUT_BORDER_RADIUS_WIDTH = 1;
const INPUT_PADDING_HORIZONTAL = 14;
const ERROR_PADDING_TOP = 5;
const ERROR_PADDING_LEFT = 15;

export interface IStyle {
  container: ViewStyle;
  containerError: ViewStyle;
  fieldContainer: ViewStyle;
  fieldContainerError: ViewStyle;
  fieldText: TextStyle;
  fieldTextError: TextStyle;
}

export const styles = StyleSheet.create<IStyle>({
  container: {
    marginBottom: theme.margins.unit,
  },
  containerError: {
    paddingLeft: ERROR_PADDING_LEFT,
    paddingTop: ERROR_PADDING_TOP,
  },
  fieldContainer: {
    alignSelf: 'stretch',
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.border,
    borderRadius: INPUT_BORDER_RADIUS,
    borderWidth: INPUT_BORDER_RADIUS_WIDTH,
    height: INPUT_FIELD_HEIGHT,
  },
  fieldContainerError: {
    borderColor: theme.colors.errorMessage,
  },
  fieldText: {
    backgroundColor: 'transparent',
    color: theme.colors.textGray,
    flex: 1,
    paddingHorizontal: INPUT_PADDING_HORIZONTAL,
    ...theme.fonts.input,
  },
  fieldTextError: {
    color: theme.colors.errorMessage,
  },
});

export default TextInputFormik;
