import React, { PureComponent, ReactNode } from 'react';

import theme from '../../theme';

import FontelloIcon from './FontelloIcon';
import { isWeb } from '../../lib/responsive';
import { Image, ImageURISource, TransformsStyle, ViewStyle } from 'react-native';

export type IIconName =
  | 'balance'
  | 'cateringBusiness'
  | 'chevronLeft'
  | 'chevronRight'
  | 'cookie'
  | 'couverts'
  | 'cross'
  | 'documents'
  | 'eat'
  | 'home'
  | 'language'
  | 'minus'
  | 'plus'
  | 'preferences'
  | 'profile'
  | 'question'
  | 'ticket'
  | 'celery'
  | 'egg'
  | 'fish'
  | 'gluten'
  | 'lupin'
  | 'milk'
  | 'mollusc'
  | 'mustard'
  | 'nut'
  | 'peanut'
  | 'sesame'
  | 'shellfish'
  | 'soya'
  | 'sulfite'
  | 'downOpen'
  | 'upOpen'
  | 'sulfite'
  | 'cookie'
  | 'logout';

const DefaultIcon: { [key in IIconName]: string } = {
  balance: 'solde@3x',
  cateringBusiness: 'tray',
  celery: 'celery',
  chevronLeft: 'chevron-left',
  chevronRight: 'chevron-right',
  couverts: 'couverts',
  cross: 'cross@3x',
  documents: 'icon-legal-info_black_24dp',
  eat: 'manger@3x',
  egg: 'egg',
  fish: 'fish',
  gluten: 'wheat',
  home: 'combined-shape@3x',
  language: 'langue@3x',
  lupin: 'lupin',
  milk: 'milk',
  minus: 'minus',
  mollusc: 'mollusc',
  mustard: 'mustard',
  nut: 'nut',
  peanut: 'peanut',
  plus: 'plus',
  preferences: 'preferences@3x',
  profile: 'group-2@3x',
  question: 'question@3x',
  sesame: 'sesame',
  shellfish: 'shellfish',
  soya: 'soya',
  sulfite: 'sulfites',
  ticket: 'ticket@3x',
  cookie: 'cookie',
  logout: 'icon-logout_black_24dp',
  downOpen: 'down-open',
  upOpen: 'up-open'
};

const ActiveIcon: { [key in IIconName]?: string } = {
  balance: 'solde@3x-plein@3x',
  eat: 'manger@3x-plein@3x',
  home: 'home-plein@3x',
  profile: 'profil@3x-plein@3x',
};

export interface IProps {
  focused?: boolean;
  name: IIconName;
  size?: number;
  tintColor?: string | null;
  transformIconStyle?: TransformsStyle;
}

type IDefaultProps = Required<Pick<IProps, 'focused' | 'size' | 'tintColor'>>;

const TAB_ICON_SIZE = 18;

class Icon extends PureComponent<IProps, {}> {
  public static defaultProps: IDefaultProps = {
    focused: false,
    size: TAB_ICON_SIZE,
    tintColor: theme.colors.textBlack,
  };

  public render(): ReactNode {
    const { focused, name, size, tintColor, transformIconStyle } = this.props;
    const activeIcon = ActiveIcon[name];
    const fontName = focused && activeIcon ? activeIcon : DefaultIcon[name];
    const color = tintColor ? tintColor : undefined;

    if (fontName === 'cookie') {
      // this is required, because we have a custom image for cookie, and it's not a font

      let CookieSVG;
      if (isWeb()) {
        CookieSVG = theme.images.cookieSVG as ImageURISource;
        return <Image style={{ height: size, width: size, tintColor: color, ...transformIconStyle }} source={CookieSVG} />
      }

      CookieSVG = theme.images.cookieSVG as React.FunctionComponent<{ style?: ViewStyle; width: number; height: number }>;
      return <CookieSVG width={size!} height={size!} style={transformIconStyle} />;
    }

    return <FontelloIcon name={fontName} size={size} color={color} style={transformIconStyle} />;
  }
}

export default Icon;
