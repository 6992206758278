import React, { PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import { getDisplayValueFromDistance } from '../../services/distanceFormatter';
import theme from '../../theme';
import { IDistance, IHolding } from '../../types/common';

export interface IProps {
  distance?: IDistance;
  holding?: Pick<IHolding, 'address' | 'id' | 'name'>;
  style?: StyleProp<ViewStyle>;
}

class Holding extends PureComponent<IProps> {
  public render(): ReactNode {
    const { holding, style } = this.props;

    if (!holding) {
      return null;
    }

    return (
      <View style={[styles.container, style]}>
        <Text style={styles.name}>{holding.name}</Text>
        <Text style={styles.value}>{this.getHoldingInfo()}</Text>
      </View>
    );
  }

  private getHoldingInfo = (): string => {
    const { distance, holding } = this.props;

    if (distance === null) {
      return '';
    }

    if (distance) {
      return getDisplayValueFromDistance(distance);
    }

    return holding && holding.address ? holding.address.zip : '';
  };
}

export interface IStyle {
  container: ViewStyle;
  name: TextStyle;
  value: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    flexDirection: 'row',
  },
  name: {
    color: theme.colors.textBlack,
    flex: 1,
    ...theme.fonts.titleText,
    fontWeight: 'normal',
  },
  value: {
    color: theme.colors.textGray,
    marginLeft: theme.margins.unit,
    ...theme.fonts.paragraph,
  },
});

export default Holding;
