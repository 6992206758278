import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { FlatList, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import LoadingCard from '../LoadingCard';
import MyInformationSection, {
  IProps as IMyInformationSectionProps,
} from '../MyInformationSection';
import Separator from '../Separator';

import styles from './styles';

export interface IProps extends NavigationInjectedProps {
  isLoading: boolean;
  myInformationSections: IMyInformationSectionProps[];
}

class MyInformationSections extends PureComponent<IProps> {
  public render(): ReactNode {
    const { myInformationSections, isLoading } = this.props;

    return (
      <LoadingCard isLoading={isLoading} style={styles.card}>
        <FlatList
          data={myInformationSections}
          keyExtractor={(item: IMyInformationSectionProps): string => item.title}
          renderItem={this.renderItem}
          ItemSeparatorComponent={(): ReactElement<Separator> => <Separator />}
        />
      </LoadingCard>
    );
  }

  public renderItem = ({
    item,
  }: {
    item: IMyInformationSectionProps;
  }): ReactElement<MyInformationSection> => {
    return <MyInformationSection title={item.title} subtitle={item.subtitle} />;
  };
}

export interface IStyle {
  card: ViewStyle;
}

export default MyInformationSections;
