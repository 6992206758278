import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import I18n from '../../../lib/i18n';
import theme from '../../../theme';
import { cartSummaryAdmissionAndPrice_orderAdmissionAndPrice_Order as IOrderAdmissionAndPrice } from '../../../types/clickandcollect/cartSummaryAdmissionAndPrice';
import Amount from '../../Amount';
import WarningMessage from '../../WarningMessage';
import { IAmount } from '../../../types/common';
import { PaymentMethod } from '../../../types/clickandcollect/globalTypes';

interface IComponentProps {
  admissionAndPrice?: IOrderAdmissionAndPrice;
  requiresTopUp: boolean;
  amount: IAmount | null;
  paymentMethod: PaymentMethod;
  isGuestAnomyn?: boolean;
  isCreditCard?: boolean;
  isOrderInAdvance?: boolean;
  refillLater: boolean;
}

export default class TotalSection extends PureComponent<IComponentProps> {
  public render(): ReactNode {
    const {
      admissionAndPrice,
      isGuestAnomyn,
      requiresTopUp,
      amount,
      isOrderInAdvance,
      paymentMethod,
      refillLater
    } = this.props;

    const shouldShowWarning = paymentMethod === PaymentMethod.BADGE && isOrderInAdvance;

    return (
      <View style={styles.totalContainer}>
        <View style={styles.detailsRow}>
          <Text style={styles.totalText}>
            {I18n.t('dashboard.eat.clickAndCollect.cartSummary.totalAmount')}
          </Text>
          {admissionAndPrice && (
            <Amount amount={admissionAndPrice.totalPrice} style={styles.totalText} />
          )}
        </View>
        {!isGuestAnomyn && amount && (
          <View style={styles.detailsRow}>
            <Text style={[styles.balanceText, (requiresTopUp || refillLater) && styles.warningText]}>
              {I18n.t(
                requiresTopUp
                  ? 'dashboard.eat.clickAndCollect.cartSummary.requireTopUp'
                  : refillLater ? 'dashboard.eat.clickAndCollect.cartSummary.refillLater'
                    : 'dashboard.eat.clickAndCollect.cartSummary.balance'
              )}
            </Text>
            {admissionAndPrice && (
              <Amount
                amount={amount}
                style={[styles.balanceText, (requiresTopUp || refillLater) && styles.warningText]}
              />
            )}
          </View>
        )}
        {shouldShowWarning && (
          <WarningMessage
            warningText={I18n.t('dashboard.eat.clickAndCollect.cartSummary.advanceTotalWarning')}
            iconSize={22}
            textType="normal"
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  balanceText: {
    ...theme.fonts.regularText,
    color: theme.colors.green,
  },
  detailsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalContainer: {
    padding: theme.margins.cardUnit * 2,
    backgroundColor: 'white',
  },
  totalText: {
    ...theme.fonts.bigTitle,
    color: theme.colors.deprecatedBlack,
    marginBottom: theme.margins.cardUnit * 2,
  },
  warningText: {
    color: theme.colors.errorDanger,
  },
});
