import { NavigationInjectedProps } from 'react-navigation';
import { compose, withProps } from 'recompose';
import withNavigation from '../../../hoc/withNavigation';
import EdenredAuthenticationPage from './EdenredAuthenticationPage.component';

export type IContainerProps = Omit<any, keyof NavigationInjectedProps>;

interface IParamsProps {
  authenticationURL: string;
  onTokensGenerated: any;
}

const getNavigationParams = (ownProps: NavigationInjectedProps): IParamsProps => ({
  authenticationURL: ownProps.navigation.getParam('authenticationURL'),
  onTokensGenerated: ownProps.navigation.getParam('onTokensGenerated'),
});

export default compose<any, IContainerProps>(
  withNavigation,
  withProps(getNavigationParams)
)(EdenredAuthenticationPage);
