import { ApolloQueryResult } from 'apollo-client';
import { gql } from 'graphql.macro';
import { Location } from 'history';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import queryString from 'query-string';
import { NavigationParams } from 'react-navigation';
import Route from 'route-parser';

import Client from '../apollo/client';
import { getConfig } from '../environment';

export interface IWithOfferId {
  offerId: string;
}

export interface IOfferWithName {
  name: string;
}

export const getUrlParams = (
  location: Location,
  pattern?: string
): NavigationParams | undefined => {
  if (pattern) {
    const route = new Route(pattern);
    const urlParams = route.match(location.pathname);

    return urlParams || undefined;
  }

  if (location.search && location.search.length > 0) {
    return queryString.parse(location.search);
  }

  return;
};

const getMenuPosLabel = async (params: NavigationParams): Promise<string> => {
  if (!params || !params.posId) {
    return 'menu';
  }

  return Client.getApolloClient()
    .query({
      query: gql`
        query getPointsOfSale($idPos: ID) {
          getUser {
            id
            guests {
              edges {
                node {
                  id
                  pos(id: $idPos) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: { idPos: params.posId },
    })
    .then(
      (result: ApolloQueryResult<{}>): string => {
        const label = get(result, 'data.getUser.guests.edges[0].node.pos.edges[0].node.name');
        return label ? `menu ${label}` : 'menu';
      }
    );
};

const getClickCollectScreenInformation = (
  name: string,
  params?: NavigationParams
): Promise<string> | string => {
  if (!params) {
    return { offerName: 'CC' }[name];
  }

  return Client.getApolloClient()
    .query({
      query: gql`
        query($idOffer: ID!) {
          get(id: $idOffer) {
            ... on OfferTemplate {
              name
              pos {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        idOffer: params.offerId,
      },
    })
    .then(
      (offer: ApolloQueryResult<{}>): string => {
        return {
          offerName: `CC - ${get(offer, 'data.get.name')}`,
          offerPosId: get(offer, 'data.get.pos.id'),
          offerPosName: get(offer, 'data.get.pos.name'),
        }[name];
      }
    );
};

const getClickCollectOfferName = async (params: NavigationParams): Promise<string> =>
  await getClickCollectScreenInformation('offerName', params);

const getClickCollectPosId = async (params: NavigationParams): Promise<string> =>
  await getClickCollectScreenInformation('offerPosId', params);

const getClickCollectPosName = async (params: NavigationParams): Promise<string> =>
  await getClickCollectScreenInformation('offerPosName', params);

const getTopUpSuccessModalSubcategory = (params?: NavigationParams): Promise<string> | string => {
  if (!params) {
    return 'Rechargement';
  }

  return Client.getApolloClient()
    .query({
      query: gql`
        query($idRefill: ID!) {
          mercanetV2Status(idRefill: $idRefill) {
            transactionSuccess
          }
        }
      `,
      variables: {
        idRefill: params.idRefill,
      },
    })
    .then(
      (paymentInfo: ApolloQueryResult<{}>): string => {
        const paymentStatus = get(paymentInfo, 'data.mercanetV2Status.transactionSuccess')
          ? 'OK'
          : 'KO';

        return `Rechargement ${paymentStatus}`;
      }
    );
};

export const screenNameMapper = {
  allergensDisclaimer: {
    screen_category: 'MANGER',
    screen_label: 'allergensDisclaimer',
    screen_name: 'allergensDisclaimer',
    screen_subcategory: 'Disclaimer allergenes',
  },
  authenticationCallback: {
    screen_category: 'AUTHENTICATION',
    screen_label: 'authentcallback',
    screen_name: 'authentcallback',
    screen_subcategory: 'Authentication',
  },
  balance: {
    screen_category: 'SOLDE',
    screen_label: 'balance',
    screen_name: 'balance',
    screen_subcategory: 'Transactions',
  },
  cartSummary: {
    pos_id: '', // getClickCollectPosId,
    pos_name: '', // getClickCollectPosName,
    screen_category: 'CLICK & COLLECT',
    screen_label: 'cartSummary',
    screen_name: 'cartSummary',
    screen_subcategory: '', // getClickCollectOfferName,
  },
  confirmCancelledOrder: {
    pos_id: '', // getClickCollectPosId,
    pos_name: '', // getClickCollectPosName,
    screen_category: 'CLICK & COLLECT',
    screen_label: 'confirmCancelledOrder',
    screen_name: 'confirmCancelledOrder',
    screen_subcategory: '', // getClickCollectOfferName,
  },
  confirmEmailLead: {
    screen_category: 'CREA COMPTE',
    screen_label: 'confirmEmailLead',
    screen_name: 'confirmEmailLead',
    screen_subcategory: 'Crea compte - Restaurant',
  },
  cookiesPolicy: {
    screen_category: 'PROFIL',
    screen_label: 'cookiesPolicy',
    screen_name: 'cookiesPolicy',
    screen_subcategory: 'Documents',
  },
  eat: {
    screen_category: 'MANGER',
    screen_label: 'eat',
    screen_name: 'eat',
    screen_subcategory: 'Liste Restaurant',
  },
  eatTab2: {
    screen_category: 'CLICK & COLLECT',
    screen_label: 'eat',
    screen_name: 'eat',
    screen_subcategory: 'CC',
  },
  help: {
    screen_category: 'PROFIL',
    screen_label: 'help',
    screen_name: 'help',
    screen_subcategory: 'Gestion du Profil',
  },
  home: {
    screen_category: 'ACCUEIL',
    screen_label: 'home',
    screen_name: 'home',
    screen_subcategory: 'Home Page',
  },
  infoRecipeDisclaimer: {
    screen_category: 'MANGER',
    screen_label: 'infoRecipeDisclaimer',
    screen_name: 'infoRecipeDisclaimer',
    screen_subcategory: 'Disclaimer infos recettes',
  },
  landing: {
    screen_category: 'ACCUEIL',
    screen_label: 'landing',
    screen_name: 'landing',
    screen_subcategory: 'Accueil',
  },
  languages: {
    screen_category: 'PROFIL',
    screen_label: 'languages',
    screen_name: 'languages',
    screen_subcategory: 'Gestion du Profil',
  },
  marketingCard: {
    screen_category: 'ACCUEIL',
    screen_label: 'marketingCard',
    screen_name: 'marketingCard',
    screen_subcategory: 'Marketing',
  },
  menu: {
    screen_category: 'MANGER',
    screen_label: 'menu',
    screen_name: 'menu',
    screen_subcategory: getMenuPosLabel,
  },
  menuElementDetails: {
    screen_category: 'MANGER',
    screen_label: 'menuElementDetails',
    screen_name: 'menuElementDetails',
    screen_subcategory: 'Détail recette',
  },
  modalContest: {
    screen_category: 'ACCUEIL',
    screen_label: 'modalContest',
    screen_name: 'modalContest',
    screen_subcategory: 'Jeu-concours',
  },
  modalTermsOfService: {
    screen_category: 'CREA COMPTE',
    screen_label: 'termsOfService',
    screen_name: 'modalTermsOfService',
    screen_subcategory: 'Crea compte - CGU',
  },
  myInformations: {
    screen_category: 'PROFIL',
    screen_label: 'myInformations',
    screen_name: 'myInformations',
    screen_subcategory: 'Gestion du Profil',
  },
  notifications: {
    screen_category: 'PROFIL',
    screen_label: 'notifications',
    screen_name: 'notifications',
    screen_subcategory: 'Gestion du Profil',
  },
  offerArticles: {
    pos_id: getClickCollectPosId,
    pos_name: getClickCollectPosName,
    screen_category: 'CLICK & COLLECT',
    screen_label: 'offerArticles',
    screen_name: 'offerArticles',
    screen_subcategory: getClickCollectOfferName,
  },
  offerBundleStep: {
    pos_id: getClickCollectPosId,
    pos_name: getClickCollectPosName,
    screen_category: 'CLICK & COLLECT',
    screen_label: 'offerArticles',
    screen_name: 'offerArticles',
    screen_subcategory: getClickCollectOfferName,
  },
  order: {
    pos_id: '', // getClickCollectPosId,
    pos_name: '', // getClickCollectPosName,
    screen_category: 'CLICK & COLLECT',
    screen_label: 'order',
    screen_name: 'order',
    screen_subcategory: '', // getClickCollectOfferName,
  },
  personalData: {
    screen_category: 'PROFIL',
    screen_label: 'personalData',
    screen_name: 'personalData',
    screen_subcategory: 'Documents',
  },
  profile: {
    screen_category: 'PROFIL',
    screen_label: 'profile',
    screen_name: 'profile',
    screen_subcategory: 'Gestion du Profil',
  },
  signUpCloseHolding: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpCloseHolding',
    screen_name: 'signUpCloseHolding',
    screen_subcategory: 'Crea compte - Restaurant',
  },
  signUpConfirmation: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpConfirmation',
    screen_name: 'signUpConfirmation',
    screen_subcategory: 'Crea compte - Confirmation',
  },
  signUpEmail: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpEmail',
    screen_name: 'signUpEmail',
    screen_subcategory: 'Crea compte - Email',
  },
  signUpEmailNotActivated: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpEmailNotActivated',
    screen_name: 'signUpEmailNotActivated',
    screen_subcategory: 'Authentification',
  },
  signUpGeolocation: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpGeolocation',
    screen_name: 'signUpGeolocation',
    screen_subcategory: 'Crea compte - Restaurant',
  },
  signUpGuestExist: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpGuestExist',
    screen_name: 'signUpGuestExist',
    screen_subcategory: 'Crea compte - Identifiants',
  },
  signUpIdentity: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpIdentity',
    screen_name: 'signUpIdentity',
    screen_subcategory: 'Crea compte - Identifiants',
  },
  signUpNearHoldings: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpNearHoldings',
    screen_name: 'signUpNearHoldings',
    screen_subcategory: 'Crea compte - Restaurant',
  },
  signUpPassword: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpPassword',
    screen_name: 'signUpPassword',
    screen_subcategory: 'Crea compte - Mdp',
  },
  signUpWelcome: {
    screen_category: 'CREA COMPTE',
    screen_label: 'signUpWelcome',
    screen_name: 'signUpWelcome',
    screen_subcategory: 'Crea compte - Activation',
  },
  termsOfService: {
    screen_category: 'PROFIL',
    screen_label: 'termsOfService',
    screen_name: 'termsOfService',
    screen_subcategory: 'Documents',
  },
  termsOfSales: {
    screen_category: 'PROFIL',
    screen_label: 'termsOfSales',
    screen_name: 'termsOfSales',
    screen_subcategory: 'Documents',
  },
  topUpPayment: {
    screen_category: 'SOLDE',
    screen_label: 'topUpPayment',
    screen_name: 'topUpPayment',
    screen_subcategory: 'Rechargement',
  },
  topUpSuccessModal: {
    screen_category: 'SOLDE',
    screen_label: 'topUpSuccessModal',
    screen_name: 'topUpSuccessModal',
    screen_subcategory: getTopUpSuccessModalSubcategory,
  },
  transactionDetails: {
    screen_category: 'SOLDE',
    screen_label: 'transactionDetails',
    screen_name: 'transactionDetails',
    screen_subcategory: 'Transactions',
  },
  virtualTicket: {
    screen_category: 'PROFIL',
    screen_label: 'virtualTicket',
    screen_name: 'virtualTicket',
    screen_subcategory: 'Gestion du Profil',
  },
};

export interface IScreenParams {
  app_env?: string | null;
  pos_id?: string | null;
  pos_name?: string | null;
  screen_category: string | null;
  screen_label: string | null;
  screen_name: string | null;
  screen_subcategory: string | null;
}

export const getScreenParams = async (
  ScreenName: string,
  params?: NavigationParams
): Promise<IScreenParams | null> => {
  const result = {} as IScreenParams;

  await Promise.all(
    map(
      screenNameMapper[ScreenName],
      async (
        value: string | ((params?: NavigationParams) => Promise<string>),
        key: string
      ): Promise<string> => {
        return (result[key] = typeof value === 'string' ? value : await value(params));
      }
    )
  );

  if (isEmpty(result)) {
    return null;
  }

  return result;
};

// prettier-ignore

export const screenNameWebMapper = [
  {
    key: 'allergensDisclaimer',
    pattern: '^allergensDisclaimer$',
  },
  {
    key: 'infoRecipeDisclaimer',
    pattern: '^infoRecipeDisclaimer$',
  },
  {
    key: 'help',
    pattern: '^help$',
  },
  {
    key: 'termsOfService',
    pattern: '^termsOfService$',
  },
  {
    key: 'personalData',
    pattern: '^personalData$',
  },
  {
    key: 'termsOfSales',
    pattern: '^termsOfSales$',
  },
  {
    key: 'cookiesPolicy',
    pattern: '^cookiesPolicy$',
  },
  {
    key: 'topUpPayment',
    pattern: '^topUpPayment$',
  },
  {
    key: 'modalTermsOfService',
    pattern: '^modalTermsOfService$',
  },
  {
    key: 'balance',
    pattern: '^\/balance-?\/?$',
  },
  {
    getUrlParams,
    key: 'cartSummary',
    pattern: '^\/restaurants\/cartSummary\/[A-Za-z0-9+\/=]+$',
  },
  {
    key: 'confirmEmailLead',
    pattern: '^\/create-account\/confirmEmailLead-?\/?$',
  },
  {
    key: 'eat',
    pattern: '^\/restaurants-?\/?$',
  },
  {
    key: 'home',
    pattern: '^\/home-?\/?$',
  },
  {
    key: 'landing',
    pattern: '^\/landing-?\/?$',
  },
  {
    key: 'authenticationCallback',
    pattern: '^authenticationCallback$',
  },
  {
    key: 'languages',
    pattern: '^\/profile\/languages-?\/?$',
  },
  {
    key: 'marketingCard',
    pattern: '^\/home\/marketing-card\/[A-Za-z0-9+\/=]+',
  },
  {
    key: 'menuElementDetails',
    pattern: '^\/restaurants\/[A-Za-z0-9+\/=]+\/menu\/[A-Za-z0-9+\/=]+',
  },
  {
    getUrlParams,
    key: 'order',
    pattern: '^\/restaurants\/orders\/[A-Za-z0-9+\/=]+',
  },
  {
    getUrlParams: (location: Location): NavigationParams | undefined => getUrlParams(location, '/restaurants/offers/:offerId'),
    key: 'offerArticles',
    pattern: '^\/restaurants\/offers\/[A-Za-z0-9+\/=]+',
  },
  {
    getUrlParams: (location: Location): NavigationParams | undefined => getUrlParams(location, '/restaurants/:posId'),
    key: 'menu',
    pattern: '^\/restaurants\/[A-Za-z0-9+\/=]+$',
  },
  {
    key: 'myInformations',
    pattern: '^\/profile\/my-informations-?\/?$',
  },
  {
    key: 'notifications',
    pattern: '^\/profile\/notifications-?\/?$',
  },
  {
    key: 'profile',
    pattern: '^\/profile(\/)?$',
  },
  {
    key: 'signUpCloseHolding',
    pattern: '^\/create-account\/holding-not-available(\/)?$',
  },
  {
    key: 'signUpConfirmation',
    pattern: '^\/create-account\/email-to-validate(\/)?$',
  },
  {
    key: 'signUpEmail',
    pattern: '^\/create-account\/[A-Za-z0-9+\/=]+\/user-login',
  },
  {
    key: 'signUpEmailNotActivated',
    pattern: '^\/create-account\/email-not-activated(\/)[A-Za-z0-9+\/=]+',
  },
  {
    key: 'signUpGeolocation',
    pattern: '^\/create-account\/geolocation(\/)?$',
  },
  {
    key: 'signUpGuestExist',
    pattern: '^\/create-account\/[A-Za-z0-9+\/=]+\/existing-guest(\/)?$',
  },
  {
    key: 'signUpIdentity',
    pattern: '^\/create-account\/[A-Za-z0-9+\/=]+\/guest-credentials(\/)?$',
  },
  {
    key: 'signUpNearHoldings',
    pattern: '^\/create-account\/holdings(\/)?$',
  },
  {
    key: 'signUpPassword',
    pattern: '^\/create-account\/[A-Za-z0-9+\/=]+\/user-password$',
  },
  {
    key: 'signUpWelcome',
    pattern: '^\/create-account\/account-activated(\/)?$',
  },
  {
    getUrlParams,
    key: 'topUpSuccessModal',
    pattern: '^\/balance\/payment-callback\/$',
  },
  {
    key: 'transactionDetails',
    pattern: '^\/balance\/[A-Za-z0-9+\/=]+',
  },
  {
    key: 'virtualTicket',
    pattern: '^\/profile\/virtual-ticket(\/)?',
  },
  {
    key: 'legalInfo',
    pattern: '^\/profile\/legal-info(\/)?',
  },
] as IScreenNameConfig[];

interface IScreenNameConfig {
  getUrlParams?: (location: Location) => NavigationParams;
  key: string;
  pattern: string;
}

export const getScreenParamsWeb = async (location: Location): Promise<IScreenParams | null> => {
  for (const screenNameWebConfig of screenNameWebMapper) {
    const regExp = new RegExp(screenNameWebConfig.pattern);

    if (regExp.test(location.pathname)) {
      let urlParams;

      if (screenNameWebConfig.getUrlParams) {
        urlParams = screenNameWebConfig.getUrlParams(location);
      }

      const params = await getScreenParams(screenNameWebConfig.key, urlParams);

      return params ? addAppEnvParam(params) : null;
    }
  }

  return null;
};

export const addAppEnvParam = (params: IScreenParams): IScreenParams => {
  return {
    ...params,
    app_env: getConfig().ENV,
  };
};
