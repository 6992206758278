import I18nJs from 'i18n-js';

export const getLanguages = async (): Promise<string[]> => {
  // @ts-ignore
  const { language, languages } = navigator;

  return languages ? languages : language ? [language] : [];
};

export default I18nJs;
