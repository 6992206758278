import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';

export interface IProps {
  style?: ViewStyle;
  text?: string;
  textStyle?: TextStyle;
}

class SubTitle extends PureComponent<IProps> {
  public render(): ReactNode {
    const { style, text, textStyle } = this.props;

    return (
      <View style={[styles.container, style]}>
        <Text style={[styles.text, textStyle]}>{text}</Text>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    marginBottom: theme.margins.unit,
  },
  text: {
    color: theme.colors.textBlack,
    ...theme.fonts.title,
  },
});

export default SubTitle;
