import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle, Text, TextStyle } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import theme from '../../../../../theme';
import { getOneLocker_getOneLocker as ILocker } from '../../../../../types/clickandcollect/getOneLocker';
import I18n from '../../../../../lib/i18n';
import ScreenBrightness from '../../../../../services/screenBrightness';
import Toaster from '../../../../../services/toaster';
import { moderateScale } from '../../../../../services/scaleService';
import OnlineHelpButton from '../../../../../components/OnlineHelpButton';
import { getOrder2_order_Order as IOrder } from '../../../../../types/clickandcollect/getOrder2';

export interface IProps {
  isLoading: boolean;
  orderId: string;
  locker: ILocker;
  order: IOrder;
}


class OrderDetailsQRCode extends PureComponent<IProps> {

  private previousBrightness?: number;

  /**
   * Once component receives the locker in props, set the screen
   * brightness to the maximum in order to make the qr code reading easier and display a
   * brief notification for the user regarding the temporary modification of the qr code brightness
   */
  public async componentDidUpdate(prevProps: IProps): Promise<void> {
    if (this.props.locker.qrCode !== null && (prevProps.locker.qrCode !== this.props.locker.qrCode)) {
      Toaster.showInfo(I18n.t('dashboard.eat.clickAndCollect.orderDetails.qrCodeBrightness'));
      this.previousBrightness = await ScreenBrightness.getBrightness();
      await ScreenBrightness.setBrightnessToMaxValue();
    }
  }

  /**
   * Once the user leaves the page, set screen brightness back to brightness level the user had before
   * entering the page
   */
  public async componentWillUnmount(): Promise<void> {
    if (this.previousBrightness) {
      await ScreenBrightness.setBrightness(this.previousBrightness);
    }
  }

  public render(): ReactNode {
    const IconInformationSVG = theme.images.iconInformationSVG;

    if (!this.props.locker.qrCode) {
      return (
        <View style={styles.contentContainer}>
          <View style={styles.horizontalContainer}>
            <View style={styles.icon}>
              <IconInformationSVG style={styles.informationIcon} />
            </View>
            <View style={styles.errorBox}>
              <Text style={styles.errorText} adjustsFontSizeToFit={true} numberOfLines={6}>{I18n.t('dashboard.eat.clickAndCollect.orderDetails.qrCodeError')}</Text>
            </View>
          </View>
          <OnlineHelpButton
            onlineHelp={this.props.order?.offer?.offerTemplate?.onlineHelp || []}
            style={styles.helpContainer}
          />
        </View>
      )
    }

    return (
      <>
        <View style={styles.contentContainer}>
          <View>
            <Text style={styles.title} adjustsFontSizeToFit={true} numberOfLines={1}> {I18n.t('dashboard.eat.clickAndCollect.orderDetails.subtitleQRCode')}</Text>
            <Text style={styles.description} adjustsFontSizeToFit={true} numberOfLines={2}> {I18n.t('dashboard.eat.clickAndCollect.orderDetails.descriptionQRCode')} </Text>
          </View>
          <View style={styles.imageContainer}>
            {this.props.locker.qrCode ? (
              <QRCode value={this.props.locker.qrCode}
                color='black'
                size={moderateScale(260)}
              />) : null}
          </View>
          <View style={styles.lockerInfo}>
            <View style={styles.box}>
              <Text style={styles.boxTitle}> {I18n.t('dashboard.eat.clickAndCollect.orderDetails.locker')} </Text>
              <Text style={styles.boxInfo}> {this.props.locker.idLocker} </Text>
            </View>
            <View style={styles.box}>
              <Text style={styles.boxTitle}> {I18n.t('dashboard.eat.clickAndCollect.orderDetails.code')} </Text>
              <Text style={styles.boxInfo}> {this.props.locker.code} </Text>
            </View>
          </View>
          <OnlineHelpButton
            onlineHelp={this.props.order?.offer?.offerTemplate?.onlineHelp || []}
            style={styles.helpContainer}
          />
        </View>
      </>
    );
  }
}

interface IStyle {
  icon: ViewStyle;
  errorBox: ViewStyle;
  errorText: TextStyle;
  informationIcon: ViewStyle;
  contentContainer: ViewStyle;
  title: TextStyle;
  description: TextStyle,
  imageContainer: ViewStyle;
  lockerInfo: ViewStyle;
  box: ViewStyle;
  boxTitle: TextStyle;
  boxInfo: TextStyle;
  helpContainer: ViewStyle;
  horizontalContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  icon: {
    width: '15%',
    alignItems: 'flex-end',
    marginTop: '11%',
    paddingRight: '4%',
  },
  errorBox: {
    width: '85%',
    marginTop: '10%',
    paddingRight: '4%',
  },
  errorText: {
    color: '#383838',
    fontFamily: 'Open Sans',
    fontSize: moderateScale(14),
    lineHeight: moderateScale(19),
  },
  informationIcon: {
    flex: 1,
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    backgroundColor: '#F9FAFB'
  },
  title: {
    margin: theme.margins.cardUnit * 2,
    marginBottom: 0,
    marginTop: 30,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: moderateScale(18),
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: moderateScale(24),
    textAlign: 'center',
  },
  description: {
    paddingRight: 10,
    paddingLeft: 10,
    margin: theme.margins.cardUnit * 2,
    marginTop: 2,
    marginBottom: 0,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: moderateScale(14),
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: moderateScale(19),
    textAlign: 'center'
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: 'white',
    padding: theme.margins.cardUnit * 4,
    margin: theme.margins.cardUnit * 2,
    shadowRadius: 10,
    shadowColor: '#EFF1F3',
    shadowOpacity: 1,
  },
  lockerInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.margins.cardUnit * 2,
    marginTop: 0
  },
  box: {
    height: moderateScale(81),
    width: '49%',
    borderRadius: 4,
    backgroundColor: 'white',
    shadowRadius: 10,
    shadowColor: '#EFF1F3',
    shadowOpacity: 1,
  },
  boxTitle: {
    marginTop: 5,
    color: '#383838',
    fontFamily: 'Open Sans',
    fontSize: moderateScale(14),
    fontStyle: 'italic',
    textAlign: 'center',
  },
  boxInfo: {
    color: '#FFB800',
    fontFamily: 'Open Sans',
    fontSize: moderateScale(34),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  helpContainer: {
    marginVertical: 25,
  },
  horizontalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

export default OrderDetailsQRCode;
