import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import { Platform, TextStyle } from 'react-native';

type IFontFamily = 'openSans' | 'kelson';
type IFontWeight = 'regular' | 'bold' | 'light' | 'medium' | 'semiBold';
type IIOSFontConfig = Pick<TextStyle, 'fontFamily' | 'fontStyle' | 'fontWeight'>;
type IAndroidFontConfig = Pick<TextStyle, 'fontFamily'>;

export const FONT_FAMILIES: { [key in IFontFamily]: key } = {
  kelson: 'kelson',
  openSans: 'openSans',
};

export const FONT_WEIGHTS: { [key in IFontWeight]: key } = {
  bold: 'bold',
  light: 'light',
  medium: 'medium',
  regular: 'regular',
  semiBold: 'semiBold',
};

export const fontConfig: { [familyKey in IFontFamily]: { [weightKey in IFontWeight]?: string } } = {
  kelson: {
    light: '200', // KelsonSans Light in mock up
    medium: '500', // KelsonSans Bold in mock up
    regular: '400', // KelsonSans in mock up
  },
  openSans: {
    bold: '700',
    regular: '400',
    semiBold: '600',
  },
};

export const fontMaker = (
  fontFamily: IFontFamily,
  fontWeight: IFontWeight = FONT_WEIGHTS.regular
): IIOSFontConfig | IAndroidFontConfig => {
  if (Platform.OS === 'android') {
    return { fontFamily: `${startCase(fontFamily)}_${snakeCase(fontWeight)}` };
  }

  return {
    fontFamily: startCase(fontFamily),
    fontStyle: 'normal',
    fontWeight: fontConfig[fontFamily][fontWeight],
  };
};

export type IDeprecatedFontKey =
  | 'bigAmount'
  | 'buttonText'
  | 'cardAction'
  | 'cardName'
  | 'cardStrongText'
  | 'cardText'
  | 'cardTextStrong'
  | 'chartLabel'
  | 'dayOfMonth'
  | 'dayOfWeek'
  | 'emptyContent'
  | 'errorMessage'
  | 'filter'
  | 'filterButton'
  | 'filterCounter'
  | 'filterText'
  | 'header'
  | 'headerTitle'
  | 'input'
  | 'label'
  | 'mediumAmount'
  | 'menuPreview'
  | 'menuSectionItem'
  | 'menuSectionTitle'
  | 'monthDay'
  | 'notificationTitle'
  | 'openCloseButton'
  | 'pageTitle'
  | 'paragraph'
  | 'password'
  | 'posStatus'
  | 'pricesCardItemLabel'
  | 'profileSection'
  | 'profileTitle'
  | 'reservationButton'
  | 'reservationTitle'
  | 'schedule'
  | 'seeMoreTitle'
  | 'smallAmount'
  | 'stepperItem'
  | 'strongText'
  | 'tabBar'
  | 'tabBarMobile'
  | 'titleText'
  | 'toast'
  | 'transactionPos'
  | 'welcomeTitle'
  | 'marketingCardsInfoNewsTitle'
  | 'marketingCardsInfoNewsContent'; 

const deprecatedFonts: { [key in IDeprecatedFontKey]: TextStyle } = {
  bigAmount: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 29,
    lineHeight: 37,
  },
  buttonText: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 14,
    lineHeight: 18,
  },
  cardAction: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 14,
    lineHeight: 17,
  },
  cardName: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 18,
    lineHeight: 22,
  },
  cardStrongText: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 16,
    lineHeight: 19,
  },
  cardText: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 12,
    lineHeight: 17,
  },  
  cardTextStrong: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 12,
    lineHeight: 17,
  },
  chartLabel: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 10,
    lineHeight: 14,
  },
  dayOfMonth: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 20,
    lineHeight: 21,
  },
  dayOfWeek: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 14,
    lineHeight: 20,
  },
  emptyContent: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 14,
    lineHeight: 21,
  },
  errorMessage: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 10,
    lineHeight: 14,
  },
  filter: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 11,
    lineHeight: 15,
  },
  filterButton: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 16,
    lineHeight: 22,
  },
  filterCounter: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 16,
    lineHeight: 22,
  },
  filterText: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 16,
    lineHeight: 22,
  },
  header: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 14,
    lineHeight: 19,
  },
  headerTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 16,
    lineHeight: 22,
  },
  input: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 12,
    lineHeight: 17,
  },
  label: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 14,
    lineHeight: 19,
  },
  mediumAmount: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 18,
    lineHeight: 25,
  },
  menuPreview: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 14,
    lineHeight: 26,
  },
  menuSectionItem: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 12,
    lineHeight: 17,
  },
  menuSectionTitle: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 18,
    lineHeight: 22,
  },
  monthDay: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 48,
    lineHeight: 50,
  },
  notificationTitle: {
    ...fontMaker(FONT_FAMILIES.kelson),
    fontSize: 14,
    lineHeight: 18,
  },
  openCloseButton: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 10,
    lineHeight: 14,
  },
  pageTitle: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 18,
    lineHeight: 36,
  },
  paragraph: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 14,
    lineHeight: 21,
  },
  password: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 11,
    lineHeight: 19,
  },
  posStatus: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 15,
    lineHeight: 17,
  },
  pricesCardItemLabel: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 14,
    lineHeight: 19,
  },
  profileSection: {
    ...fontMaker(FONT_FAMILIES.kelson),
    fontSize: 14,
    lineHeight: 18,
  },
  profileTitle: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 22,
    lineHeight: 27,
  },
  reservationButton: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 15,
    lineHeight: 19,
  },
  reservationTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 18,
    lineHeight: 28,
  },
  schedule: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 15,
    lineHeight: 17,
  },
  seeMoreTitle: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 24,
    lineHeight: 26,
  },
  smallAmount: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 14,
    lineHeight: 19,
  },
  stepperItem: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 16,
  },
  strongText: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 14,
    lineHeight: 19,
  },
  tabBar: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 12,
    lineHeight: 17,
  },
  tabBarMobile: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 10,
    lineHeight: 12,
  },
  titleText: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 16,
    lineHeight: 24,
  },
  toast: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 16,
    lineHeight: 21,
  },
  transactionPos: {
    ...fontMaker(FONT_FAMILIES.kelson),
    fontSize: 14,
    lineHeight: 18,
  },
  welcomeTitle: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 20,
    lineHeight: 45,
  },
  marketingCardsInfoNewsTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.medium),
    fontSize: 16,
    lineHeight: 20,
  },
  marketingCardsInfoNewsContent: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 14,
    lineHeight: 20,
  },
};

export type IFontKey =
  | 'onTopOfImage'
  | 'semiBoldText'
  | 'boldText'
  | 'bigItem'
  | 'bigTitle'
  | 'cardNumber'
  | 'title'
  | 'littleTitle'
  | 'mediumText'
  | 'mediumTitle'
  | 'button'
  | 'regularText'
  | 'quantityText'
  | 'slotsItem'
  | 'slotsTitle'
  | 'slotsSubTitle'
  | 'tableNumber'
  | 'signature'
  | 'receiptError'
  | 'warningNormal'
  | 'warningSmall';

const fonts: { [key in IFontKey]: TextStyle } = {
  bigItem: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 24,
    lineHeight: 32,
  },
  bigTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 24,
    lineHeight: 32,
  },
  button: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 14,
    lineHeight: 21,
  },
  cardNumber: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.bold),
    fontSize: 40,
    lineHeight: 50,
  },
  littleTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 18,
    lineHeight: 26,
  },
  mediumText: {
    ...fontMaker(FONT_FAMILIES.kelson),
    fontSize: 14,
    lineHeight: 21,
  },
  mediumTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 18,
    lineHeight: 24,
  },
  onTopOfImage: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 42,
    lineHeight: 56,
  },
  quantityText: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 12,
    lineHeight: 19,
  },
  regularText: {
    ...fontMaker(FONT_FAMILIES.openSans),
    fontSize: 14,
    lineHeight: 19,
  },
  semiBoldText: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.semiBold),
    fontSize: 14,
    lineHeight: 19,
  },
  boldText: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 14,
    lineHeight: 19,
  },
  slotsItem: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.bold),
    fontSize: 14,
    lineHeight: 19,
  },
  slotsSubTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 14,
    fontStyle: 'italic',
    lineHeight: 19,
  },
  signature: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.light),
    fontSize: 23,
    fontStyle: 'italic',
  },
  slotsTitle: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 16,
    lineHeight: 22,
  },
  title: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 18,
    lineHeight: 21,
  },
  tableNumber: {
    ...fontMaker(FONT_FAMILIES.kelson, FONT_WEIGHTS.medium),
    fontSize: 70,
    lineHeight: 102,
  },
  receiptError: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 16,
    lineHeight: 19,
  },
  warningNormal: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 16,
    lineHeight: 22,
  },
  warningSmall: {
    ...fontMaker(FONT_FAMILIES.openSans, FONT_WEIGHTS.regular),
    fontSize: 14,
    lineHeight: 19,
  },
};

export default {
  ...deprecatedFonts,
  ...fonts,
};
