import React, { useCallback } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import I18n from '../../lib/i18n';
import theme from '../../theme';
import { getOrder2_order_Order_offer_offerTemplate_onlineHelp } from '../../types/clickandcollect/getOrder2';
import Touchable from '../Touchable';

export interface IProps extends NavigationInjectedProps {
  onlineHelp: getOrder2_order_Order_offer_offerTemplate_onlineHelp[];
  style?: ViewStyle;
  testID?: string;
}

const OnlineHelpButton = ({ navigation, onlineHelp, style, testID }: IProps) => {
  const html = onlineHelp.find(item => item.language === I18n.currentLocale())?.text;

  const onPress = useCallback(() => {
    navigation.navigate('helpOnline', { html });
  }, [navigation]);

  if (!html || html.length < 1) {
    return null;
  }

  return (
    <View style={style}>
      <Touchable testID={testID} onPress={onPress}>
        <Text style={styles.text}>{I18n.t('dashboard.helpOnline.button')}</Text>
      </Touchable>
    </View>
  )
};

export interface IStyle {
  text: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.blue,
    fontWeight: '600',
    textAlign: 'center',
  },
});

export default OnlineHelpButton;
