import { FormikErrors, FormikValues } from 'formik';

export const VALIDATOR_ERROR_NAME = 'FOODI';

class ValidatorError extends Error {
  public errorKey: string;
  public name: string = VALIDATOR_ERROR_NAME;

  constructor(errorKey: string, message: string) {
    super(message);
    this.errorKey = errorKey;
  }

  public toFormikError = (): FormikErrors<FormikValues> => {
    return { [this.errorKey]: this.message };
  };
}

export default ValidatorError;
