import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import HideWithKeyboard from 'react-native-hide-with-keyboard';
import { NavigationScreenProps } from 'react-navigation';

import AppPage from '../../../components/AppPage';
import HoldingsList from '../../../components/HoldingsList';
import Paragraph from '../../../components/Paragraph';
import Stepper from '../../../components/Stepper';
import { STATUS } from '../../../components/Stepper';
import Title from '../../../components/Title';
import I18n from '../../../lib/i18n';
import { idToUuid, Entity } from '../../../services/base64Id';
import { getUserSignUpParams } from '../../../services/navigation/params';

export type IProps = NavigationScreenProps;

class NearHoldings extends PureComponent<IProps> {
  public componentWillMount(): void {
    const { holding, badgeNumber, serialNumber, lastName, email } = getUserSignUpParams(
      this.props.navigation
    );
    if (holding) {
      this.props.navigation.navigate('signUpIdentity', {
        holdingId: idToUuid(holding, Entity.HOLDING),
        badgeNumber,
        serialNumber,
        lastName,
        email,
      });
    }
  }
  public render(): ReactNode {
    return (
      <AppPage hasBlackHeader>
        <HideWithKeyboard>
          <Stepper currentStepNumber={1} currentStepStatus={STATUS.ACTIVE} />
          <Title text={I18n.t('signUp.nearHolding.title')} />
        </HideWithKeyboard>
        <View style={styles.searchContainer}>
          <HideWithKeyboard>
            <Paragraph text={I18n.t('signUp.nearHolding.paragraph')} />
          </HideWithKeyboard>
          <HoldingsList onHoldingPress={this.onHoldingPress} />
        </View>
      </AppPage>
    );
  }

  private onHoldingPress = (isHoldingAvailable: boolean, holdingId: string): void => {
    const { badgeNumber, serialNumber, lastName, email } = getUserSignUpParams(
      this.props.navigation
    );
    const latitude = this.props.navigation.getParam('latitude');
    const longitude = this.props.navigation.getParam('longitude');

    isHoldingAvailable
      ? this.props.navigation.navigate('signUpIdentity', {
          holdingId,
          latitude,
          longitude,
          badgeNumber,
          serialNumber,
          lastName,
          email,
        })
      : this.props.navigation.navigate('signUpCloseHolding', { holdingId });
  };
}

export interface IStyle {
  searchContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  searchContainer: {
    flex: 1,
  },
});

export default NearHoldings;
