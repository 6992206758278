import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import theme from '../../theme';
import I18n from '../../lib/i18n';
import { FONT_FAMILIES } from '../../theme/fonts';
import Warning from '../../theme/images/warning.svg';
import { startCase } from 'lodash';
interface IProps {
  goToProfile: () => void;
}

export const IncompleteProfileView: React.FC<IProps> = React.memo(({ goToProfile }) => {
  return (
    <View style={styles.incompleteProfile}>
      <View style={styles.titleDiv}>
        <Warning />
        <Text style={styles.incompleteProfileTitle}>
          {I18n.t('dashboard.profile.incompleteProfile.addBadge')}
        </Text>
      </View>
      <TouchableOpacity style={styles.completeProfile} onPress={goToProfile}>
        <Text style={styles.completeProfileTitle}>
          {I18n.t('dashboard.profile.incompleteProfile.completeYourProfile')}
        </Text>
      </TouchableOpacity>
    </View>
  );
});

const styles = StyleSheet.create({
  incompleteProfile: {
    height: 110,
    width: '100%',
    padding: 20,
  },
  titleDiv: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  incompleteProfileTitle: {
    color: theme.colors.textBlack,
    fontFamily: startCase(FONT_FAMILIES.openSans),
    fontSize: 18,
    lineHeight: 22,
    fontWeight: 'normal',
    marginLeft: 4,
  },
  completeProfile: {
    paddingVertical: 4,
  },
  completeProfileTitle: {
    color: '#527EDB',
    ...theme.fonts.littleTitle,
    textDecorationLine: 'underline',
  },
});
