import get from 'lodash/get';
import React, { PureComponent, ReactNode } from 'react';
import { Image, ImageStyle, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import { PaymentMethod } from '../../../types/clickandcollect/globalTypes';

import LargeButton from '../../../components/LargeButton';
import I18n from '../../../lib/i18n';
import Logger from '../../../lib/logger';
import { isWeb } from '../../../lib/responsive';
import theme from '../../../theme';
import { LARGE_BUTTON_THEME } from '../../LargeButton/LargeButton.component';

import { styles as platformStyle } from './styles';

export interface IProps extends NavigationInjectedProps {
  onCancelOrder: (
    orderId: string,
    isBookingOrder: boolean,
    paymentMethod: PaymentMethod
  ) => Promise<void>;
  onLeftButtonPress: () => void;
  onRightButtonPress: () => void;
  order: any;
  isBookingOrder?: boolean;
  testID?: string;
}

export interface IState {
  isLoading: boolean;
}

class CancelOrderModalContent extends PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  public cancelOrder = async (): Promise<void> => {
    const { order, onCancelOrder, onRightButtonPress, isBookingOrder } = this.props;
    this.setState({ isLoading: true });

    let idOrder: string;
    !!isBookingOrder ? (idOrder = order.node.id) : (idOrder = get(order, 'id'));

    try {
      await onCancelOrder(idOrder, !!isBookingOrder, order?.paymentMethod);
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoading: false });
      onRightButtonPress();
    }
  };

  public render(): ReactNode {
    const { onLeftButtonPress, order, isBookingOrder, testID } = this.props;

    const titleStyle = StyleSheet.flatten([styles.title, platformStyle.title]);
    let idOrder: string;
    !!isBookingOrder ? (idOrder = order.node.id) : (idOrder = get(order, 'id'));

    return (
      <>
        {!isBookingOrder && (
          <View style={styles.textContainer}>
            <Text style={titleStyle}>
              {I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelModalTitle')}
            </Text>
            <Text style={styles.text}>
              {I18n.t('dashboard.eat.clickAndCollect.orderDetails.cancelModalSubtitle')}
            </Text>
          </View>
        )}
        {isBookingOrder && (
          <View style={styles.textContainer}>
            <Text style={titleStyle}>
              {I18n.t('dashboard.eat.bookingService.orderDetails.cancelModalTitle')}
            </Text>
            <Text style={styles.text}>
              {I18n.t('dashboard.eat.bookingService.orderDetails.cancelModalSubtitle')}
            </Text>
          </View>
        )}
        {isWeb() && (
          <View style={styles.imageContainer}>
            <Image source={theme.images.orderState.default.KO} style={styles.image} />
          </View>
        )}
        <View style={styles.buttonContainer}>
          <LargeButton
            testID={`${testID}_saveButton`}
            text={I18n.t('dashboard.eat.clickAndCollect.orderDetails.saveButton')}
            theme={LARGE_BUTTON_THEME.default}
            style={styles.leftButton}
            onPress={onLeftButtonPress}
          />
          <LargeButton
            testID={`${testID}_deleteButton`}
            text={I18n.t('dashboard.eat.clickAndCollect.orderDetails.deleteButton')}
            theme={LARGE_BUTTON_THEME.danger}
            style={styles.rightButton}
            onPress={this.cancelOrder}
            isLoading={this.state.isLoading}
          />
        </View>
      </>
    );
  }
}

interface IStyle {
  buttonContainer: ViewStyle;
  image: ImageStyle;
  imageContainer: ViewStyle;
  leftButton: ViewStyle;
  rightButton: ViewStyle;
  text: TextStyle;
  textContainer: ViewStyle;
  title: TextStyle;
}

export interface IPlatformStyle {
  title: TextStyle;
}

const BORDER_WIDTH = 1;
const IMAGE_HEIGHT = 67;
const IMAGE_WIDTH = 90;

const styles = StyleSheet.create<IStyle>({
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  image: {
    height: IMAGE_HEIGHT,
    marginBottom: theme.margins.cardUnit * 6,
    marginTop: theme.margins.cardUnit * 5,
    width: IMAGE_WIDTH,
  },
  imageContainer: {
    alignItems: 'center',
    flex: 1,
  },
  leftButton: {
    borderTopColor: theme.colors.border,
    borderTopWidth: BORDER_WIDTH,
    flex: 1,
    paddingTop: theme.margins.cardUnit * 3 - BORDER_WIDTH,
  },
  rightButton: {
    flex: 1,
  },
  text: {
    ...theme.fonts.regularText,
    color: theme.colors.textGray,
    textAlign: 'center',
  },
  textContainer: {
    padding: 2 * theme.margins.cardUnit,
  },
  title: {
    color: theme.colors.textBlack,
    paddingBottom: 2 * theme.margins.cardUnit,
    textAlign: 'center',
  },
});

export default CancelOrderModalContent;
