import React, { PureComponent, ReactNode } from 'react';
import { NavigationInjectedProps } from 'react-navigation';
import { ActivityIndicator, Dimensions, ScrollView, StyleSheet, Text, View } from 'react-native';
import { WebPage } from '../../../../../componentsWeb';
import { IWithGuestPaymentProfile } from '../../../../../hoc/withGuestPaymentProfile';
import theme from '../../../../../theme';
import I18n from '../../../../../lib/i18n';
import { isMobile } from '../../../../../lib/responsive';
import { cartSummaryData_order_Order as IOrder } from '../../../../../types/clickandcollect/cartSummaryData';
import {
  transformSummaryDate,
  transformSummaryTime,
} from '../../../../../services/offersFormatter';
import { isRefillRequired } from '../../../../../services/guest';
import Separator from '../../../../../components/Separator';
import AdmissionsSection from '../../../../../components/CartSummaryContent/AdmissionsSection/AdmissionsSection.component';
import ConfirmOrderButton from '../../../../../components/CartSummaryContent/ConfirmOrderButton/ConfirmOrderButton.container';
import OrderItemRow from '../../../../../components/CartSummaryContent/OrderItemRow/OrderItemRow';
import TotalSection from '../../../../../components/CartSummaryContent/TotalSection/TotalSection.component';
import WithdrawRangeSection from '../../../../../components/CartSummaryContent/WithdrawRangeSection/WithdrawRangeSection.component';
import ValorizationWarning from '../../../../../components/CartSummaryContent/ValorizationWarning/ValorizationWarning.component';
import { articleFamilyKeys, DisplayedArticleFamily } from '../../../../../components/ArticlesList';
import { cartSummaryAdmissionAndPrice_orderAdmissionAndPrice_Order as IOrderAdmissionAndPrice } from '../../../../../types/clickandcollect/cartSummaryAdmissionAndPrice';
export interface IComponentProps extends NavigationInjectedProps, IWithGuestPaymentProfile {
  isLoading: boolean;
  isAdmissionLoading: boolean;
  order: IOrder;
  quotationError: boolean;
  admissionAndPrice: IOrderAdmissionAndPrice;
  refetch: () => void;
  haveBadge:boolean;
}

export default class CartSummary extends PureComponent<IComponentProps> {
  public render(): ReactNode {
    const {
      order,
      isLoading,
      managementMode,
      quotationError,
      refetch,
      isGuestAnomyn,
      isAdmissionLoading,
      admissionAndPrice,
      haveBadge
    } = this.props;

    if (isLoading || isAdmissionLoading) {
      return <ActivityIndicator style={{ marginTop: Dimensions.get('window').height / 2 }} />;
    }

    if (!order) {
      return null;
    }
    let [receptionDate, receptionHours] = ['', ''];
    if (!isLoading && order.withdrawRange) {
      const [begin, end] = order!.withdrawRange.split('/');
      receptionDate = transformSummaryDate(begin);
      receptionHours = transformSummaryTime(begin, end);
    }

    const requiresTopUp = isRefillRequired(
      { isGuestAnomyn, managementMode, balance: order!.guest!.availableBalance! },
      admissionAndPrice && admissionAndPrice!.totalPrice!
    );

    const valorizationWorked = admissionAndPrice && admissionAndPrice.admission !== null;

    return (
      <>
        <WebPage
          title={I18n.t('dashboard.eat.clickAndCollect.cartSummary.title')}
          canGoBack
          hideLogo
          hideNavigationMenu
          tabBarHeight={isGuestAnomyn ? 152 : 160}
        >
          <View style={styles.container}>
            <ScrollView style={styles.scrollView}>
              {!isAdmissionLoading && !valorizationWorked && <ValorizationWarning />}

              {/* 
                [Articles] 
                Will displaly items, ordered by articleFaimly
              */}

              {order &&
                articleFamilyKeys.map(articleFamilyKey =>
                  this.renderArticlesByType(articleFamilyKey, order)
                )}

              {/* [/Articles] */}

              <Separator />

              {/* [Admission & subventions] */}
              {!isAdmissionLoading && valorizationWorked && (
                <>
                  <AdmissionsSection admissionAndPrice={admissionAndPrice} />
                  <Separator />
                </>
              )}
              {/* [/Admission & subventions] */}

              {/* [Date retrait] */}
              {order!.withdrawRange && (
                <>
                  <Separator />
                  <WithdrawRangeSection
                    receptionDate={receptionDate}
                    receptionHours={receptionHours}
                  />
                </>
              )}
              {/* [/Date retrait] */}
            </ScrollView>

            {/* [Total du panier & rechargement] */}
            {!isMobile() && (
              <>
                <Separator />
                {!isAdmissionLoading && admissionAndPrice && (
                  <TotalSection
                    admissionAndPrice={admissionAndPrice}
                    requiresTopUp={requiresTopUp}
                    isGuestAnomyn={isGuestAnomyn}
                  />
                )}
                {order && admissionAndPrice && (
                  <ConfirmOrderButton
                    order={order}
                    admissionAndPrice={admissionAndPrice}
                    quotationError={quotationError}
                    refetch={refetch}
                    haveBadge={haveBadge}
                  />
                )}
              </>
            )}
            {/* [/Total du panier & rechargement] */}
          </View>
        </WebPage>

        {isMobile() && (
          <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }}>
            <Separator />
            {/* [Total du panier & rechargement] */}
            {!isAdmissionLoading && admissionAndPrice && (
              <TotalSection
                admissionAndPrice={admissionAndPrice}
                requiresTopUp={requiresTopUp}
                isGuestAnomyn={isGuestAnomyn}
              />
            )}
            {/* [/Total du panier & rechargement] */}

            {order && admissionAndPrice && (
              <ConfirmOrderButton
                order={order}
                admissionAndPrice={admissionAndPrice}
                quotationError={quotationError}
                refetch={refetch}
                haveBadge={haveBadge}
                />
            )}
          </div>
        )}
      </>
    );
  }

  // Method that will render all items, that belong to articleFamily, sorting them alphabetically
  private renderArticlesByType = (
    articleFamily: DisplayedArticleFamily,
    order: IOrder
  ): ReactNode | undefined => {
    const filteredOrderItems = order.orderItems
      .filter(orderItem => orderItem.offerItem?.inheritedFamily === articleFamily)
      .sort((a, b) => (a.labelWhenAdded > b.labelWhenAdded ? 1 : -1));

    const articleFamilyTitle = I18n.t(
      `dashboard.eat.clickAndCollect.articles.type.${articleFamily.toLowerCase()}`
    );

    if (filteredOrderItems.length != 0) {
      return (
        <View key={articleFamily}>
          <Text style={styles.titleType}>{articleFamilyTitle}</Text>
          {filteredOrderItems.map(orderItem => (
            <OrderItemRow orderItem={orderItem} idOrder={order.id} key={orderItem.id} />
          ))}
        </View>
      );
    } else {
      return undefined;
    }
  };
}

const styles = StyleSheet.create({
  container: {
    ...theme.shadows.card,
    backgroundColor: theme.colors.background,
    borderRadius: 4,
    marginBottom: theme.margins.cardUnit * 2,
  },
  scrollView: {
    paddingTop: theme.margins.unit,
    marginLeft: 2 * theme.margins.cardUnit,
    marginRight: 2 * theme.margins.cardUnit,
  },
  titleType: {
    width: '100%',
    paddingBottom: 8,
  },
});
