import React, { PureComponent, ReactNode } from 'react';
import { Keyboard, View } from 'react-native';
import { NavigationContainer, NavigationInjectedProps } from 'react-navigation';
import LargeButton from '../../../../../components/LargeButton';
import TableNumberComp from '../../../../../components/TableNumber';
import { WebPage } from '../../../../../componentsWeb';
import I18n from '../../../../../lib/i18n';
import { isMobile } from '../../../../../lib/responsive';
import styles from './styles';

export interface IProps extends NavigationInjectedProps {
  rootNavigator: NavigationContainer;
  offerId: string;
}

interface IState {
  idTable: string;
  valid: boolean;
}

class TableNumber extends PureComponent<IProps> {
  public state: IState = {
    idTable: '',
    valid: false,
  };

  public render(): ReactNode {
    const { idTable, valid } = this.state;

    const {
      navigation: { navigate },
      offerId,
    } = this.props;

    const next = (): void => {
      Keyboard.dismiss();
      setTimeout(() => {
        return navigate(`/table-service/${offerId}/tables/${idTable}/offer-articles`);
      }, 300);
    };

    return (
      <>
        <WebPage title={I18n.t('dashboard.eat.tableService.table.title')} tabBarHeight={72} hideLogo hideNavigationMenu>
          <View style={styles.container}>
            <TableNumberComp
              editable={true}
              onChange={(_, valid, idTable) => this.setState({ valid, idTable })}
            />

            {!isMobile() && (
              <LargeButton
                disabled={!valid}
                onPress={next}
                style={styles.buttonStyle}
                theme="success"
                text={I18n.t('dashboard.eat.tableService.table.valid')}
              />
            )}
          </View>
        </WebPage>
        {isMobile() && (
          <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%' }}>
            <LargeButton
              disabled={!valid}
              onPress={next}
              style={styles.buttonStyle}
              theme="success"
              text={I18n.t('dashboard.eat.tableService.table.valid')}
            />
          </div>
        )}
      </>
    );
  }
}

export default TableNumber;
