import { IAppState } from '../reducer';
import { CookieCategory, CookieConsent } from '../../services/oneTrust';

export type ICategoriesConsent = {
  [key in CookieCategory]: CookieConsent
}

export const getCategoriesConsent = (state: IAppState): ICategoriesConsent => {
  return state.cookies;
};
