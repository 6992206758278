import React, { FunctionComponent } from 'react';
import PdfViewer from '../../../../components/PdfViewer';
import AppPage from '../../../../components/AppPage';
import { getConfig } from '../../../../environment';
import theme from '../../../../theme';

const env = getConfig();

const TermsOfService: FunctionComponent = () => (
  <AppPage noPadding backgroundColor={theme.colors.backgroundTab}>
    <PdfViewer
      url= {env.TOS_URL}
      hasMarginTop
    />
  </AppPage>
)

export default TermsOfService;
