import { ViewStyle } from 'react-native';

import colors from './colors';

export type IShadowKey = 'card';

const shadowOffset = {
  height: 2,
  width: 0,
};

const shadows: { [key in IShadowKey]: ViewStyle } = {
  card: {
    elevation: 1,
    shadowColor: colors.black,
    shadowOffset,
    shadowOpacity: 0.05,
    shadowRadius: 10,
  },
};

export default shadows;
