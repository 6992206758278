import React, { PureComponent, ReactNode } from 'react';
import { View } from 'react-native';

import Stepper from '../../../../src/components/Stepper';
import { IStepStatus } from '../../../../src/components/Stepper/utils';
import Title from '../../../../src/components/Title';
import SignUpSideContent from '../../../../src/componentsWeb/SignUpSideContent';
import { isDesktop, media } from '../../../../src/lib/responsive';

export interface IProps {
  currentStepNumber?: 1 | 2 | 3 | 4;
  currentStepStatus?: IStepStatus;
  subtitle?: string;
  title?: string;
}

export class SignupLayout extends PureComponent<IProps> {
  public render(): ReactNode {
    const { children, title, subtitle, currentStepNumber, currentStepStatus } = this.props;

    // tslint:disable-next-line:no-any
    const mainSectionStyle = media(styles.mainSection) as any;

    return (
      <View style={styles.page}>
        {isDesktop() && <SignUpSideContent />}
        <div style={mainSectionStyle}>
          <div style={styles.signupMainContent}>
            {title && <h1 style={styles.title}>{title}</h1>}
            {currentStepNumber &&
              currentStepStatus && (
                <div style={styles.stepperContainer}>
                  <Stepper
                    currentStepNumber={currentStepNumber}
                    currentStepStatus={currentStepStatus}
                  />
                </div>
              )}
            {subtitle && <Title text={subtitle} />}
            {children}
          </div>
        </div>
      </View>
    );
  }
}

const styles = {
  mainSection: {
    _desktop: {
      boxSizing: 'border-box',
      display: 'inline-block',
      height: '100%',
      overflow: 'auto',
      padding: '70px 15px',
      verticalAlign: 'top',
      width: '50%',
    },
    _mobile: {
      boxSizing: 'border-box',
      display: 'inline-block',
      height: '100%',
      overflow: 'auto',
      overflowX: 'hidden',
      padding: '70px 15px',
      verticalAlign: 'top',
      width: '100%',
    },
    _tablet: {
      boxSizing: 'border-box',
      display: 'inline-block',
      height: '100%',
      overflow: 'auto',
      overflowX: 'hidden',
      padding: '70px 15px',
      verticalAlign: 'top',
      width: '100%',
    },
  },
  page: {
    flexDirection: 'row',
    height: '100vh',
    overflow: 'hidden',
  },
  signupMainContent: {
    margin: '0 auto',
    maxWidth: '320px',
    overflowX: 'hidden',
  },
  stepperContainer: {
    marginBottom: '32px',
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    margin: '18px 0',
    textAlign: 'center',
  },

  // tslint:disable-next-line:no-any
} as any;

export default SignupLayout;
