import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import I18n from '../../lib/i18n';
import { toNumber } from '../../services/amountFormatter';
import theme from '../../theme';
import { IAmount, ISubsidyAmount } from '../../types/common';
import CardTitle from '../CardTitle';
import LoadingCard from '../LoadingCard';
import PricesCardItem from '../PricesCardItem';
import PricesCardTotal from '../PricesCardTotal';

export interface IProps {
  admission?: ISubsidyAmount;
  fee?: ISubsidyAmount;
  isLoading?: boolean;
  total?: IAmount;
}

class AdmissionPricesCard extends PureComponent<IProps> {
  public render(): ReactNode {
    const { admission, isLoading, fee, total } = this.props;

    if (isLoading) {
      return <LoadingCard isLoading={isLoading} />;
    }

    if (
      (!admission || toNumber(admission.amount) === 0) &&
      (!admission || toNumber(admission.subsidy) === 0) &&
      (!fee || toNumber(fee.amount) === 0) &&
      (!fee || toNumber(fee!.subsidy) === 0)
    ) {
      return null;
    }

    return (
      <LoadingCard isLoading={isLoading}>
        <View style={styles.titleContainer}>
          <CardTitle text={I18n.t('transactions.admission.title')} />
        </View>
        {admission && this.displayAdmission(admission)}
        {fee && this.displayFee(fee)}
        <PricesCardTotal amount={total} />
      </LoadingCard>
    );
  }

  private displayAdmission = (admission: ISubsidyAmount): ReactNode => (
    <>
      {toNumber(admission.amount) !== 0 && (
        <PricesCardItem
          name={I18n.t('transactions.admission.admissionAmount')}
          amount={{ amount: admission.amount, currency: admission.currency }}
        />
      )}
      {toNumber(admission.subsidy) !== 0 && (
        <PricesCardItem
          name={I18n.t('transactions.admission.admissionSubsidy')}
          amount={{ amount: admission.subsidy, currency: admission.currency }}
        />
      )}
    </>
  );

  private displayFee = (fee: ISubsidyAmount): ReactNode => (
    <>
      {toNumber(fee.amount) !== 0 && (
        <PricesCardItem
          name={I18n.t('transactions.admission.royaltyAmount')}
          amount={{ amount: fee.amount, currency: fee.currency }}
        />
      )}
      {toNumber(fee.subsidy) !== 0 && (
        <PricesCardItem
          name={I18n.t('transactions.admission.royaltySubsidy')}
          amount={{ amount: fee.subsidy, currency: fee.currency }}
        />
      )}
    </>
  );
}

interface IStyle {
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  titleContainer: {
    marginBottom: theme.margins.unit * 2,
  },
});

export default AdmissionPricesCard;
