import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import theme from '../../theme';
import Icon, { IIconName } from '../Icon';
import Touchable from '../Touchable';
import { isWeb } from '../../lib/responsive';

export interface IProps {
  iconName: IIconName;
  onPress?: () => void;
  subtitle?: string;
  title: string;
  testID?: string;
}

class ProfileSection extends PureComponent<IProps> {

  public componentDidMount(): void {

    if (this.props.iconName === 'cookie' && isWeb() && window) {
       //this is required because we need to force the reload of one trust functions to be able to display the cookies preferences on click
      (window as any).reloadOTBanner();
    }
  }

  public render(): ReactNode {

    return this.props.iconName === 'cookie' && isWeb() ?
      // className used is directly linked with the ability to display the cookies preferences in the web
      <div className="ot-sdk-show-settings-element" style={{ marginLeft: '5px' }}>
        <Touchable onPress={this.props.onPress} style={styles.container}>
          <View style={styles.imageContainer}>
            <Icon name={this.props.iconName} size={ICON_SIZE} />
          </View>
          <View>
            <Text style={styles.title}>{this.props.title}</Text>
            {this.props.subtitle && <Text style={styles.subtitle}>{this.props.subtitle}</Text>}
          </View>
        </Touchable>
      </div>
      :
      <Touchable testID={this.props.testID} onPress={this.props.onPress} style={styles.container}>
        <View style={styles.imageContainer}>
          <Icon name={this.props.iconName} size={ICON_SIZE} />
        </View>
        <View>
          <Text style={styles.title}>{this.props.title}</Text>
          {this.props.subtitle && <Text style={styles.subtitle}>{this.props.subtitle}</Text>}
        </View>
      </Touchable>;
  }
}

const ICON_SIZE = 20;
const SECTION_HEIGHT = 48;

interface IStyle {
  container: ViewStyle;
  imageContainer: ViewStyle;
  subtitle: TextStyle;
  title: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: SECTION_HEIGHT,
  },
  imageContainer: {
    marginHorizontal: theme.margins.unit * 2,
  },
  subtitle: {
    color: theme.colors.textGray,
    ...theme.fonts.cardText,
  },
  title: {
    color: theme.colors.textBlack,
    ...theme.fonts.profileSection,
  },
});

export default ProfileSection;
