import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OperationVariables, OptionProps, QueryOpts } from 'react-apollo/types';
import { ApolloQueryResult, NetworkStatus } from 'apollo-client';
import { getOrder2_order_Order as IOrder } from '../../types/clickandcollect/getOrder2';
import { PaymentMethod } from 'src/types/clickandcollect/globalTypes';
import { NavigationInjectedProps } from 'react-navigation';

const getOrder = loader('../../queries/clickandcollect/getOrder.gql');

export interface IGraphQLProps {
  hasError: boolean;
  isLoading: boolean;
  order: IOrder | undefined;
  refetchOrders: (variables?: OperationVariables) => Promise<ApolloQueryResult<IOrder>>;
  networkStatus: NetworkStatus;
}

export type IConnectedProps = IGraphQLProps;
export type IContainerProps = Omit<{}, keyof IConnectedProps>;

export interface IWithOrderId {
  orderId: string;
  paymentMethod: PaymentMethod;
}

export default graphql(getOrder, {
  // @ts-ignore
  options: (props: NavigationInjectedProps & IWithOrderId): QueryOpts => ({

    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      idOrder: props.orderId,
       paymentMethod: props.paymentMethod
    },
  }),
  props: (props: OptionProps<IWithOrderId>): IGraphQLProps => ({
      hasError: !!get(props, 'data.error'),
      isLoading: get(props, 'data.loading'),
      networkStatus: get(props, 'data.networkStatus'),
      order: get(props, 'data.order', undefined),
      refetchOrders: get(props, 'data.refetch'),
    })
});
