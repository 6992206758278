import { loader } from 'graphql.macro';
import get from 'lodash/get';
import { graphql } from 'react-apollo/graphql';
import { OptionProps } from 'react-apollo/types';
import { compose } from 'recompose';

import HoldingLogo, { IProps } from './HoldingLogo.component';

const getBrand = loader('../../queries/getBrand.gql');

export interface IGraphQLProps {
  idHolding: string;
}

export type IContainerProps = Omit<IProps, keyof IGraphQLProps>;

export default compose<IProps, IContainerProps>(
  graphql(getBrand, {
    props: (props: OptionProps<{}>): IGraphQLProps => ({
      idHolding: get(props, 'data.getUser.guests.edges[0].node.holding.id'),
    }),
  })
)(HoldingLogo);
