import { ImageURISource } from 'react-native';

import { POS_TYPE } from '../../../constants';
import theme from '../../../theme';
import { IPosType } from '../../../types/common';

export const getIconByPosType = (posType?: IPosType): ImageURISource => {
  switch (posType) {
    case POS_TYPE.CAFETERIA:
      return theme.images.cupBlack;
    case POS_TYPE.SELF:
      return theme.images.dishGray;
    case POS_TYPE.BRASSERIE:
      return theme.images.logoBrasserie;
    default:
      return theme.images.euro;
  }
};
