import moment from 'moment';
import { ImageURISource } from 'react-native';

import { ORDER_STATE } from '../constants/OrderState';
import I18n from '../lib/i18n';
import theme from '../theme';

import { OrderState, OfferTemplateWithdrawalType, OfferTemplateType, PaymentMethod } from '../types/clickandcollect/globalTypes';
import { getOrder2_order_Order as IOrder } from '../types/clickandcollect/getOrder2';

export enum OrderStatus {
  CART = 'CART',
  EMPLOYEE_CART = 'EMPLOYEE_CART',
  ON_HOLD = 'ON_HOLD',
  ACCEPTED = 'ACCEPTED',
  PREPARING = 'PREPARING',
  READY = 'READY',
  PROCESSED = 'PROCESSED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  REFUSED = 'REFUSED',
  ABANDONED = 'ABANDONED',
}

export enum OfferType {
  TableServiceOfferTemplate = 'tableService',
  OfferTemplate = 'clickAndCollect',
  SeatClickAndServe = 'seatClickAndServe',
}

export const capitalize = (value: string): string =>
  `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
export const snakeCase = (value: string): string =>
  value
    .toLowerCase()
    .split('_')
    .map(capitalize)
    .join('');

export const orderStateTranslationKey = (state: OrderState): string => {
  return ORDER_STATE[state] ? ORDER_STATE[state].key : '';
};

export const orderStateColor = (state: OrderState): string => {
  return ORDER_STATE[state] ? ORDER_STATE[state].color : theme.colors.errorDanger;
};

export const orderStateLogo = (state: OrderState): ImageURISource => {
  const logoName = ORDER_STATE[state] ? ORDER_STATE[state].logo : 'onHold';

  return theme.images.orderState.extra[logoName];
};

export const isCancellable = (order: IOrder): boolean => {
  if (!order) {
    return false;
  }

  if (order.paymentMethod === PaymentMethod.EDENRED) {
    //TODO: create general function to check for orders inAdvance to be reused through the project

    // to check if the order is 'inAdvance' we used the created date from the order and compare it with the day of the withdrawal (withdrawRange.start)
    // if the day from the creation of the order is before the day of the order, the order is 'inAdvance'
    const isOrderInAdvance =
      moment(order?.created?.split('T')?.[0]) <
      moment(order?.withdrawRange?.split('/')?.[1].split('T')?.[0]);

    if (isOrderInAdvance) {
      return false
    }
  }

  if(
    (order.offer?.offerTemplate as any)?.withdrawalType === OfferTemplateType.TABLE_SERVICE &&
      order.guest?.holding.idCashSystem === 1
  ) {
      return false;
  }

  const isStateCancellable = order.cancelableAt;

  // In SC&S orders we only need to look at the cancelableAt
  if (order.offer?.offerTemplate?.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE) {
    return isStateCancellable;
  }

  const orderCanBeCancelled = order.cancelableUntil
    ? moment() < moment(order.cancelableUntil)
    : !order.orderGroup;

  return isStateCancellable && orderCanBeCancelled;
};

const orderStateMessage = (offerType: OfferType, state: OrderState) =>
  `dashboard.eat.orderState.${offerType}.${snakeCase(state)}`;

export const getOrderStateMessage = (order: IOrder): string => {
  const { offer, state } = order;
  let offerType =
    offer && offer.offerTemplate && offer.offerTemplate.__typename
      ? OfferType[offer.offerTemplate.__typename]
      : OfferType.OfferTemplate;

  // We have a specific message for when a SC&S order is ready
  if (
    OrderStatus[state] === OrderStatus.READY &&
    offer?.offerTemplate?.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE
  ) {
    offerType = OfferType['SeatClickAndServe'];
  }

  const key = offerType && OrderStatus[state] ? orderStateMessage(offerType, state) : '';
  return key ? I18n.t(key) : '';
};
