import React, { PureComponent, ReactNode } from 'react';
import { NavState } from 'react-native';
import { WebView } from 'react-native-webview';
import { NavigationInjectedProps } from 'react-navigation';

import AppPage from '../../../../components/AppPage';
import { getConfig } from '../../../../environment';
import theme from '../../../../theme';

const env = getConfig();

class Help extends PureComponent<NavigationInjectedProps> {
  public render(): ReactNode {
    const uri = `${env.ZENDESK.BASE_URL}`;

    return (
      <AppPage noPadding backgroundColor={theme.colors.backgroundTab}>
        <WebView source={{ uri }} onNavigationStateChange={this.handleNavigationStateChange} />
      </AppPage>
    );
  }

  private handleNavigationStateChange = (event: NavState): void => {
    const regExp = new RegExp(env.ZENDESK.EXIT_URL);
    if (event.url && regExp.test(event.url)) {
      this.props.navigation.goBack();
    }
  };
}

export default Help;
