import { ApolloQueryResult } from 'apollo-client';
import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { OperationVariables } from 'react-apollo';
import {
  SectionList,
  SectionListData,
  SectionListProps,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import I18n from '../../../lib/i18n';
import Logger from '../../../lib/logger';
import { isWeb } from '../../../lib/responsive';
import { transformTransactionsToSectionListData } from '../../../services/transactionFormatter';
import theme from '../../../theme';
import { INode } from '../../../types/common';
import { IGetGuestForBalanceContentResult } from '../../../types/getGuestForBalanceContent';
import { ITransaction } from '../../../types/getTransactions';
import CardTitle from '../../CardTitle';
import EmptyContentMessage from '../../EmptyContentMessage';
import LoadingView from '../../LoadingView';
import Separator from '../../Separator';
import TopUpCard from '../../TopUpCard';
import Transaction from '../Transaction';
import TransactionComponent from '../Transaction/Transaction.component';
import TransactionsSectionTitle from '../TransactionsSectionTitle';

export interface IProps extends Partial<SectionListProps<SectionListData<INode<ITransaction>>>> {
  isLoading?: boolean;
  onEndReached: () => void;
  refetch?: (
    variables?: OperationVariables
  ) => Promise<ApolloQueryResult<IGetGuestForBalanceContentResult>>;
  transactions?: Array<INode<ITransaction>>;
  testID?: string;
}

export interface IState {
  isRefreshing: boolean;
}

class TransactionsList extends PureComponent<IProps> {
  public state: IState = {
    isRefreshing: false,
  };
  public render(): ReactNode {
    const { isLoading, onEndReached, transactions, ...otherProps } = this.props;

    if (!transactions) {
      return <EmptyContentMessage text={I18n.t('transactions.errorMessage')} />;
    }

    return (
      <SectionList
        {...otherProps}
        sections={transformTransactionsToSectionListData(transactions)}
        onRefresh={this.onRefresh}
        refreshing={this.state.isRefreshing}
        ListEmptyComponent={this.renderEmptyList()}
        ListHeaderComponent={!isWeb() ? this.renderListHeaderComponent : null}
        renderItem={this.renderItem}
        renderSectionHeader={this.renderSectionHeader}
        stickySectionHeadersEnabled
        keyExtractor={(item: INode<ITransaction>): string => item.node.id}
        onEndReached={onEndReached}
        ListFooterComponent={(): ReactElement<LoadingView> => <LoadingView isLoading={isLoading} />}
      />
    );
  }

  private onRefresh = async (): Promise<void> => {
    if (!this.props.refetch) {
      return;
    }

    this.setState({ isRefreshing: true });

    try {
      this.props.refetch();
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isRefreshing: false });
    }
  };

  private renderEmptyList = (): ReactElement<EmptyContentMessage> => (
    <EmptyContentMessage text={I18n.t('transactions.emptyMessage')} />
  );

  // tslint:disable-next-line:no-any
  private renderItem = (info: any): ReactElement<TransactionComponent> => {
    const { testID } = this.props;
    if (info.index === info.section.data.length - 1) {
      return (
        <View style={styles.lastItemContainer}>
          <Transaction
            transaction={info.item.node}
            testID={`${testID}_transaction_${info.item.node.id}`}
          />
        </View>
      );
    }

    return (
      <Transaction
        transaction={info.item.node}
        testID={`${testID}_transaction_${info.item.node.id}`}
      />
    );
  };

  private renderListHeaderComponent = (): ReactElement<View> => {
    const { testID } = this.props;
    return (
      <>
        <TopUpCard testID={`${testID}_topUpCard`} />
        <Separator height={theme.margins.cardUnit * 2} color={theme.colors.backgroundGray} />
        <View style={styles.titleContainer}>
          <CardTitle text={I18n.t('transactions.cardTitle')} />
        </View>
      </>
    );
  };

  private renderSectionHeader = ({
    section,
  }: {
    section: SectionListData<INode<ITransaction>>;
  }): ReactElement<TransactionsSectionTitle> => <TransactionsSectionTitle text={section.title} />;
}

interface IStyle {
  lastItemContainer: ViewStyle;
  titleContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  lastItemContainer: {
    marginBottom: theme.margins.cardUnit * 2,
  },
  titleContainer: {
    marginBottom: theme.margins.cardUnit,
    marginTop: theme.margins.cardUnit * 2,
    paddingHorizontal: theme.margins.cardUnit * 2,
  },
});

export default TransactionsList;
