import React, { Fragment, PureComponent, ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';

import BalanceCard from '../../../../components/BalanceCard';
import LoadingView from '../../../../components/LoadingView';
import PointOfSaleCard from '../../../../components/PointOfSaleCard';
import Touchable from '../../../../components/Touchable';
import { isMobile } from '../../../../lib/responsive';
import { isClosedForToday } from '../../../../services/schedulesFormatter';
import theme from '../../../../theme';
import { IMarketingCardElement, IPos } from '../../../../types/common.d';

import HomeMessage from './HomeMessage';
import MarketingItem from './MarketingItem';
import PointOfSaleTomorrow from './PointOfSaleTomorrow';
import PointsOfSaleSeeMore from './PointsOfSaleSeeMore';
import { IProps } from './shared';

const CARD_HEIGHT = 300;
const MAX_POINTS_OF_SALE = 4;

class CardCollection extends PureComponent<IProps & NavigationInjectedProps> {
  protected get containerStyle(): StyleProp<ViewStyle> {
    return isMobile() ? styles.container : [styles.container, styles.containerDesktop];
  }

  protected get elementStyle(): StyleProp<ViewStyle> {
    return isMobile() ? styles.element : [styles.element, styles.elementDesktop];
  }

  public render(): ReactNode {
    const { balance, balanceDateTime, brand, idHolding, isLoading } = this.props;

    return (
      <LoadingView isLoading={isLoading}>
        <Touchable onPress={this.goToBalance}>
          <BalanceCard balance={balance} balanceDateTime={balanceDateTime} isLoading={isLoading} />
        </Touchable>
        <HomeMessage brand={brand} idHolding={idHolding} />
        <View style={this.containerStyle}>
          {this.renderPointsOfSale()}
          {this.renderMarketingCards()}
        </View>
      </LoadingView>
    );
  }

  protected renderMarketingCards(): ReactNode {
    const { marketingCards } = this.props;

    return marketingCards.map((marketingCard: IMarketingCardElement) => (
      <View style={this.elementStyle} key={marketingCard.id}>
        <MarketingItem marketingCardItem={marketingCard} />
      </View>
    ));
  }

  protected renderPointsOfSale(): ReactNode {
    const { pointOfSales } = this.props;

    // There is a special case when there is a single closed point of sale.
    if (pointOfSales.length === 1 && isClosedForToday(pointOfSales[0])) {
      return (
        <View style={this.elementStyle} key={pointOfSales[0].id}>
          <PointOfSaleTomorrow pointOfSale={pointOfSales[0]} />
        </View>
      );
    }

    // General case.
    const posCards: ReactNode[] = pointOfSales.map((pointOfSale: IPos) => (
      <View style={this.elementStyle} key={pointOfSale.id}>
        <PointOfSaleCard pointOfSale={pointOfSale} />
      </View>
    ));

    if (pointOfSales.length > MAX_POINTS_OF_SALE) {
      posCards.length = MAX_POINTS_OF_SALE - 1;
      posCards.push(
        <View style={this.elementStyle} key={'see_more'}>
          <PointsOfSaleSeeMore />
        </View>
      );
    }

    return <Fragment>{posCards}</Fragment>;
  }

  private goToBalance = (): void => {
    this.props.navigation.navigate('balance');
  };
}

export interface IStyle {
  container: ViewStyle;
  containerDesktop: ViewStyle;
  element: ViewStyle;
  elementDesktop: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    marginHorizontal: -theme.margins.unit,
  },
  containerDesktop: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  element: {
    height: CARD_HEIGHT,
    marginBottom: theme.margins.unit * 2,
  },
  elementDesktop: {
    flexBasis: '50%',
  },
});

export default CardCollection;
