import React, { PureComponent, ReactNode } from 'react';
import BottomSheet from '../BottomSheet';
import BakingModalContent, { IProps as IBakingModalContentProps } from './BakingModalContent';

export interface IProps extends IBakingModalContentProps {
  isVisible: boolean;
  offerId: string;
  onBackPress?: () => void;
}

export interface IState {
  isLoading: boolean;
}

class BakingModal extends PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: false,
  };

  public render(): ReactNode {
    const { isVisible, onBackPress, ...otherProps } = this.props;

    return (
      <BottomSheet isVisible={isVisible} onBackPress={onBackPress}>
        <BakingModalContent {...otherProps} />
      </BottomSheet>
    );
  }
}
export default BakingModal;
