import React, { PureComponent, ReactNode } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import { NavigationParams } from 'react-navigation';

import theme from '../../theme';
import Icon from '../Icon';
import Touchable from '../Touchable';

export interface IProps {
  canGoBack?: boolean;
  goBack?: () => void;
  goBackPageName?: string;
  goBackParams?: NavigationParams;
  text?: string;
}

const ICON_SIZE = 24;
class PageTitle extends PureComponent<IProps> {
  public render(): ReactNode {
    const { canGoBack, text } = this.props;

    return (
      <View>
        <Touchable style={styles.container} disabled={!canGoBack} onPress={this.props.goBack}>
          {canGoBack && (
            <View style={styles.iconContainer}>
              <Icon name="chevronLeft" size={ICON_SIZE} tintColor={theme.colors.black} />
            </View>
          )}

          <Text numberOfLines={1} style={styles.title}>
            {text}
          </Text>
        </Touchable>
      </View>
    );
  }
}

interface IStyle {
  container: ViewStyle;
  iconContainer: ViewStyle;
  title: TextStyle;
}

const LEFT_OFFSET = -4;

const styles = StyleSheet.create<IStyle>({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 2 * theme.margins.cardUnit,
    position: 'relative',
  },
  iconContainer: {
    left: LEFT_OFFSET,
    marginRight: theme.margins.cardUnit,
    position: 'relative',
  },
  title: {
    color: theme.colors.textBlack,
    position: 'relative',
    ...theme.fonts.pageTitle,
  },
});

export default PageTitle;
