import React, { PureComponent, ReactNode } from 'react';
import { BackHandler, Image, ImageStyle, StyleSheet, View, ViewStyle } from 'react-native';
import I18n from 'react-native-i18n';
import {
  NavigationActions,
  NavigationResetAction,
  NavigationScreenProps,
  StackActions,
} from 'react-navigation';

import AppPage from '../../../components/AppPage';
import FullWidthView from '../../../components/FullWidthView';
import GhostButton from '../../../components/GhostButton';
import { BUTTON_THEME } from '../../../components/GhostButton';
import Paragraph from '../../../components/Paragraph';
import SubTitle from '../../../components/SubTitle';

import theme from '../../../theme';

class ConfirmEmailLead extends PureComponent<NavigationScreenProps> {
  public componentDidMount(): void {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
  }

  public componentWillUnmount(): void {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
  }
  public render(): ReactNode {
    return (
      <AppPage style={styles.container} hasBlackHeader>
        <View style={styles.headerContainer}>
          <FullWidthView style={styles.imageContainer}>
            <Image style={styles.image} source={theme.images.thumb} />
          </FullWidthView>
        </View>
        <View>
          <SubTitle text={I18n.t('signUp.emailLead.title')} />
          <Paragraph text={I18n.t('signUp.emailLead.paragraph')} />
          <GhostButton
            onPress={this.goToHome}
            text={I18n.t('signUp.emailLead.button')}
            theme={BUTTON_THEME.black}
          />
        </View>
      </AppPage>
    );
  }

  private goToHome = (): void => {
    const resetAction: NavigationResetAction = StackActions.reset({
      actions: [NavigationActions.navigate({ routeName: 'authStack' })],
      index: 0,
      key: null,
    });
    this.props.navigation.dispatch(resetAction);
  };

  private handleBackPress = (): boolean => {
    return true;
  };
}

interface IStyle {
  container: ViewStyle;
  headerContainer: ViewStyle;
  image: ImageStyle;
  imageContainer: ViewStyle;
}

const styles = StyleSheet.create<IStyle>({
  container: {
    justifyContent: 'space-between',
  },
  headerContainer: {
    flex: 1,
  },
  image: {
    resizeMode: 'contain',
  },
  imageContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});

export default ConfirmEmailLead;
