import I18n from '../../../../../lib/i18n';
import React, { PureComponent, ReactNode } from 'react';
import { View, StyleSheet, ViewStyle, Text, TextStyle } from 'react-native';
import { NavigationInjectedProps } from 'react-navigation';
import theme from '../../../../../theme';
import AppPage from '../../../../../components/AppPage';
import Touchable from '../../../../../components/Touchable';
import { IOrderByPos } from './BookingCardGroup.container';

export interface IProps extends NavigationInjectedProps {
  ordersGroupedByPos: IOrderByPos[];
}

class BookingCardGroup extends PureComponent<IProps> {

  public render(): ReactNode {
    const { ordersGroupedByPos } = this.props;
    return (
      <AppPage noPadding style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.cardTitle}>{I18n.t('dashboard.eat.bookingOrderCard.cardTitle')}</Text>
        </View>
        <View style={styles.container}>
          {ordersGroupedByPos.map(pos => (
            <Touchable key={pos.id} onPress={this.goToBooking(pos.id)}>
              <View style={styles.restaurantSlot}>
                <Text numberOfLines={1} style={styles.restaurantText}>
                  {pos.name}
                </Text>
                <View style={styles.badgeContainer}>
                  <Text style={styles.badge} numberOfLines={1}>
                    {pos.count}
                  </Text>
                </View>
              </View>
            </Touchable>
          ))}
        </View>
      </AppPage>
    );
  }
  
  private goToBooking = (posId?: string): (() => void) => (): void => {
    this.props.navigation.replace('bookingService', { posId });
  };
}

interface IStyle {
  page: ViewStyle;
  titleContainer: ViewStyle;
  cardTitle: TextStyle;
  restaurantText: TextStyle;
  restaurantSlot: ViewStyle;
  container: ViewStyle;
  badgeContainer: ViewStyle;
  badge: TextStyle;
}

const styles = StyleSheet.create<IStyle>({
  page: {
    backgroundColor: 'white',
    display: 'flex',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2 * theme.margins.cardUnit,
    marginHorizontal: theme.margins.unit * 2,
  },
  cardTitle: {
    ...theme.fonts.regularText,
    color: theme.colors.black,
    textAlign: 'center',
    fontStyle: 'italic',
    letterSpacing: 0,
    margin: theme.margins.cardUnit,
    paddingVertical: theme.margins.padding,
  },
  restaurantText: {
    ...theme.fonts.headerTitle,
    color: theme.colors.black,
    padding: theme.margins.unit,
  },
  restaurantSlot: {
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    borderWidth: 2,
    borderColor: '#E8E8E8',
    borderRadius: 4,
    marginLeft: 15,
    marginVertical: 10,
  },
  container: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingHorizontal: theme.margins.unit,
    justifyContent: 'flex-start',
  },
  badgeContainer: {
    top: -13,
    position: 'absolute',
    alignSelf: 'flex-end',
    right: -20,
    backgroundColor: theme.colors.blue,
    borderRadius: 25,
    paddingHorizontal: theme.margins.cardUnit,
    paddingVertical: theme.margins.cardUnit * 0.35,
    marginRight: theme.margins.cardUnit,
  },
  badge: {
    ...theme.fonts.cardText,
    color: theme.colors.white,
  },
});

export default BookingCardGroup;
